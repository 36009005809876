import React, { useState, useRef, useEffect } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import { influencerActions } from '_actions';
import { connect } from 'react-redux';

const ManageRecordModal = ({ isOpen, mode, data, handleCloseManageRecordsModal, influencerId, projectId, addInfluencerDocument, updateInfluencerDocument }) => {
    const [isTitleError, setIsTitleError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);
    const [isLinkError, setIsLinkError] = useState(false);

    const titleInput = useRef(null);
    const descriptionInput = useRef(null);
    const linkInput = useRef(null);

    const handleCloseModal = () => {
        handleCloseManageRecordsModal(mode);
        setTitle();
        setDescription();
        setLink();
        handleClearInput();
    };

    const setTitle = () => setIsTitleError(false);
    const setDescription = () => setIsDescriptionError(false);
    const setLink = () => setIsLinkError(false);

    const isFieldValid = () => {
        let isValid = true;
        const title = titleInput.current.value;
        const description = descriptionInput.current.value;
        const link = linkInput.current.value;

        if (title === '') {
            setIsTitleError(true);
            isValid = false;
        }

        if (description === '') {
            setIsDescriptionError(true);
            isValid = false;
        }

        if (link === '') {
            setIsLinkError(true);
            isValid = false;
        }

        return isValid;
    };

    const handleManageRecord = () => {
        const isValid = isFieldValid();

        if (isValid) {
            if (mode === 'edit') {
                handleEditInfluencerRecord();
            } else {
                handleAddInfluencerRecord();
            }
        }
    };

    const handleAddInfluencerRecord = () => {
        const title = titleInput.current.value;
        const description = descriptionInput.current.value;
        const link = linkInput.current.value;

        const document = { title, description, link };

        addInfluencerDocument(projectId, influencerId, document);
        handleCloseModal();
    };

    const handleEditInfluencerRecord = () => {
        const title = titleInput.current.value;
        const description = descriptionInput.current.value;
        const link = linkInput.current.value;

        const document = { title, description, link };

        updateInfluencerDocument(projectId, influencerId, data.id, document);
        handleCloseModal();
    };

    const handleClearInput = () => {
        if (mode === 'add') {
            titleInput.current.value = '';
            descriptionInput.current.value = '';
            linkInput.current.value = '';
        }

        if (mode === 'edit') {
            titleInput.current.value = data.title;
            descriptionInput.current.value = data.description;
            linkInput.current.value = data.link;
        }
    };

    useEffect(() => {
        handleClearInput();
    }, [mode]);

    return (
        <React.Fragment>
            <div className={`drawer-overlay ${isOpen ? 'active' : ''}`} onClick={handleCloseModal}></div>
            <div className={`side-drawer-main ${isOpen ? 'active' : ''}`}>
                <div className='side-drawer-wrapper'>
                    <div className='side-drawer-title'>
                        <h3>{mode.toUpperCase()} RECORD</h3>
                        <span className='side-drawer-close' onClick={handleCloseModal}><i className="icon-lms icon-close"></i></span>
                    </div>
                    <div className='side-drawer-tabs'>
                        <div className='side-drawer-tabs-wrapper'>
                            <div className='control-tabs-box'>
                                <div className={1 === 1 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => {}}>
                                    <p>Record Information</p>
                                </div>
                            </div>
                            <div className='content-tabs-box'>
                                <div className={1 === 1 ? 'tabs-content active' : 'tabs-content'}>
                                    <div className='forms-tab'>
                                        <MDBRow>
                                            <MDBCol className='col-12 form-group-box'>
                                                <div className='input-group-box full-box-'>
                                                    <div className={isTitleError ? 'field-box error-field' : 'field-box'}>
                                                        <label htmlFor='title'>Title <small>*</small></label>
                                                        <input ref={titleInput} type='text' id='title' name='title' defaultValue={mode !== 'add' ? data.title : ''} />
                                                        {isTitleError && <p className='error-field'>This field is required</p>}
                                                    </div>
                                                    <div className={isDescriptionError ? 'field-box error-field' : 'field-box'}>
                                                        <label htmlFor='description'>Description <small>*</small></label>
                                                        <input ref={descriptionInput} type='text' id='description' name='description' defaultValue={mode !== 'add' ? data.description : ''} />
                                                        {isDescriptionError && <p className='error-field'>This field is required</p>}
                                                    </div>
                                                    <div className={isLinkError ? 'field-box error-field' : 'field-box'}>
                                                        <label htmlFor='link'>Document Link <small>*</small></label>
                                                        <input ref={linkInput} type='text' id='link' name='link' defaultValue={mode !== 'add' ? data.link : ''} />
                                                        {isLinkError && <p className='error-field'>This field is required</p>}
                                                    </div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </div>
                            </div>
                            <div className='tab-form-btn-group'>
                                <MDBRow>
                                    <MDBCol className='text-right'>
                                        <span className='drawer-btn cancel-btn' onClick={handleCloseModal}>
                                            Cancel
                                        </span>
                                        <span className='drawer-btn save-btn' onClick={handleManageRecord}>
                                            {mode === 'add' ? 'Save Record' : 'Update Record'}
                                        </span>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

function mapState(state) {
    const { influencers } = state;
    return { influencers };
}

const actionCreators = {
    addInfluencerDocument: influencerActions.addInfluencerDocument,
    updateInfluencerDocument: influencerActions.updateInfluencerDocument
};

const connectedViewManageRecordModal = connect(mapState, actionCreators)(ManageRecordModal);
export { connectedViewManageRecordModal as ManageRecordModal };
