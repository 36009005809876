import React, { useEffect, useState } from 'react';
import { projectActions } from "_actions";
import { connect } from "react-redux";
import SideBarProjectGroup from './SideBarProjectGroup';

// Functional SideBar component
/**
 * Sidebar component that displays a list of projects grouped by categories.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.projects - The projects data.
 * @param {Function} props.getProjects - Function to fetch the projects data.
 * @returns {JSX.Element} The rendered sidebar component.
 * 
 * @example
 * <SideBar projects={projects} getProjects={getProjects} />
 * 
 * @typedef {Object} Project
 * @property {Object} info - Information about the project.
 * @property {string} info.name - The name of the project.
 * 
 * @typedef {Object} Projects
 * @property {boolean} loaded - Indicates if the projects data is loaded.
 * @property {Object} items - The projects items.
 * @property {Project[]} items.owned - The owned projects.
 * @property {Project[]} items.shared - The shared projects.
 */
const _SideBar = ({ projects, getProjects }) => {
    // Local state to control the visibility and status of the sidebar and overlays
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [active, setActive] = useState(-1); // Tracks the active project group or item

    // useEffect hook to fetch projects when the component mounts
    useEffect(() => {
        getProjects(); // Fetch the projects data
    }, [getProjects]);

    // Toggle sidebar and overlay visibility
    const handleToggleSideBar = (activeIndex) => {
        // Toggle the sidebar and overlay visibility
        setToggleSideBar(prevState => !prevState);

        // If an active index is passed, set the active project index
        if (activeIndex !== undefined) {
            setActive(activeIndex);
        }
    };

    // Group projects by their names to display them under categories
    const handleGroupProjects = (data) => {
        return data.reduce((project, arr) => {
            const { name } = arr;

            // Initialize the group if it doesn't exist yet
            project[name] = project[name] ?? [];

            // Check if the project already exists in the group to avoid duplicates
            const found = project[name].some(el => el.info.name === arr.info.name);
            if (!found) {
                project[name].push(arr); // Add the project if not already in the group
            }

            return project;
        }, []); // Return the grouped projects
    };

    // Map over the projects data to add group names based on their category (e.g., 'Primary Homes', 'Propelrr')
    const handleAddGroupProjects = (data) => {
        return data.map((item) => {
            let name = '';
            if (item.info['name'].includes('Primary')) {
                name = 'Primary Homes';
            }
            if (item.info['name'].includes('Maayo')) {
                name = 'Maayo';
            }
            if (item.info['name'].includes('Propelrr')) {
                name = 'Propelrr';
            }
            if (item.info['name'].includes('DCMI')) {
                name = 'DMCI';
            }

            // Return a new item with the updated group name
            return { ...item, name: name };
        });
    };

    // Check if the current project is the active one by comparing the URL path
    const isActive = (projectId) => {
        return window.location.pathname === `/project/${projectId}/leads`; // Compare with the project leads path
    };

    // Define variables for owned and shared projects
    let owned = [];
    let shared = [];

    // If projects are loaded, process them
    if (projects.loaded) {
        const { data } = projects.items;
        if (data) {
            let ownedProjects = [...data.owned];
            let sharedProjects = [...data.shared];

            // Group and categorize owned and shared projects
            ownedProjects = handleAddGroupProjects(ownedProjects);
            sharedProjects = handleAddGroupProjects(sharedProjects);

            // Combine owned and shared projects into a single group
            owned = handleGroupProjects([...ownedProjects, ...sharedProjects]);
        }
    }

    // JSX return for sidebar layout and content
    return (
        <>
            {/* Sidebar overlay that covers the screen when the sidebar is active */}
            <div className={toggleSideBar ? 'sidebar-overlay active' : 'sidebar-overlay'} onClick={handleToggleSideBar}></div>
            {/* Sidebar menu box */}
            <div className={toggleSideBar ? 'sidebar-menu-box active' : 'sidebar-menu-box'}>
                {/* Button to toggle sidebar visibility */}
                <button className='btn-toggle' onClick={handleToggleSideBar}><i className="icon-lms icon-arrow-right"></i></button>
                <div className='sidebar-menu-wrapper'>
                    {/* Sidebar title */}
                    <div className='sidebar-title'>
                        <p>Projects</p>
                    </div>
                    <div className='sidebar-list'>
                        {/* Render each project group (owned or shared) */}
                        <ul className="sidebar-dropdown">
                            {projects.loaded &&
                                Object.keys(owned).map((o, index) => (
                                    <SideBarProjectGroup
                                        key={index}
                                        name={o} // Name of the project group
                                        project={owned[o]} // Projects under this group
                                        active={active} // Current active project index
                                        onHandleToggleSideBar={handleToggleSideBar} // Callback to toggle sidebar
                                    />
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

// Mapping state from Redux store to component props
const mapState = (state) => {
    const { projects, project } = state;
    return { projects, project }; // Returning projects and project data
};

// Action creators for fetching projects
const actionCreators = {
    getProjects: projectActions.getProjects,
    getProject: projectActions.getProject,
};

// Connect the functional component to Redux store
const SideBar = connect(mapState, actionCreators)(_SideBar); 
export { SideBar };
