import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MDBCol, MDBRow } from "mdbreact";
import InputForm from "_pages/Propelrr/components/Form/InputForm";
import { formActions, leadActions, projectActions } from "_actions";
import {
  getSourceData,
  utilitiesService as utility,
  utilitiesService,
} from "_services";

const Calendly = ({ onHandleCalendlyDrawer, isCalendlyDrawer }) => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const projectId = params.id;
    const leadId = params.leadId;

    dispatch(formActions.getDefaultForm(projectId));
    dispatch(projectActions.getAllProjectStatus(projectId));
    dispatch(leadActions.getLead(projectId, leadId));
    calendlySucces = false;
  }, [params.leadId]);

  const [toggleTabState, setToggleTabState] = useState(1);
  const [select, setSelect] = useState("");
  const [callLink, setCallLink] = useState("");
  const [otherAgenda, setOtherAgenda] = useState("");
  const [service, setService] = useState("");
  const [toggleAlertState, setToggleAlertState] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [isCustomselectOpen, setIsCustomselectOpen] = useState(false);
  const [customselectSelectedValue, setCustomselectSelectedValue] =
    useState("Select Agenda");
  const [isMultiselectOpen, setIsMultiselectOpen] = useState(false);
  const [multiselectSelectedValues, setMultiselectSelectedValues] = useState(
    []
  );

  const services = [
    "Social Media Marketing",
    "Web Design And Development",
    "Influencer Marketing",
    "Conversion Rate Optimization",
    "Digital Analytics",
    "UX Design",
    "Mobile App Development",
    "Pay Per Click / Digital Ads",
    "Search Engine Optimization",
    "Content Marketing",
  ];

  const agenda = [
    "Requirements Clarification",
    "Pitch",
    "Proposal Clarification",
    "Others",
  ];

  const fields = useSelector((state) => {
    const { leads } = state;
    const { lead, leadLoaded } = leads;

    if (leadLoaded) {
      let map = [];
      let basicInfo = lead.data.basic_info;

      if (basicInfo) {
        basicInfo.forEach((i) => {
          map[i.label] = utilitiesService.decrypt(i.value);
        });
      }

      return map;
    } else {
      return [];
    }
  });

  useEffect(() => {
    if (isCalendlyDrawer) {
     // console.log("fields: ", fields);
      setEmail(
        fields["Email Address"] !== undefined
          ? fields["Email Address"]
          : fields["Email"]
      );
      setName(
        fields["Full Name"] !== undefined
          ? fields["Full Name"]
          : fields["First Name"]
      );
      setCustomselectSelectedValue("Select Agenda");
      setMultiselectSelectedValues(
        fields["Service"] !== undefined ? fields["Service"].split(',') : []
      );
      setCallLink("");
      setErrors({});
    }
  }, [isCalendlyDrawer]);

  const handleCallLink = (value) => {
    setCallLink(value);
  };

  const handleService = (value) => {
    setService(value);
  };

  const handleOthersAgenda = (value) => {
    setOtherAgenda(value);
  };

  const calendlyLoading = useSelector((state) => {
    const { leads } = state;
    const { calendlyLoading } = leads;

    if (calendlyLoading) {
      return calendlyLoading;
    }

    return false;
  });

  let calendlySucces = useSelector((state) => {
    const { leads } = state;
    const { calendlySuccess } = leads;

    if (calendlySuccess) {
      return calendlySuccess;
    }
    return false;
  });

  useEffect(() => {
    if (calendlySucces) {
      setCallLink("");
      setSelect("");
      setService("");
      toggleAlert();
    }
  }, [calendlySucces]);

  const multiselectRef = useRef();
  const customselectRef = useRef();

  function handleOpenMultiselect() {
    setIsMultiselectOpen(!isMultiselectOpen);
  }

  function handleOpenCustomselect() {
    setIsCustomselectOpen(!isCustomselectOpen);
  }

  const handleMultiselectCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setMultiselectSelectedValues((prevSelectedValues) => [
        ...prevSelectedValues,
        value,
      ]);
    } else {
      setMultiselectSelectedValues((prevSelectedValues) =>
        prevSelectedValues.filter((val) => val !== value)
      );
    }
  };

  const handleCustomselectSelectValue = (value) => {
    setCustomselectSelectedValue(value);
    setIsCustomselectOpen(false);
  };

  useEffect(() => {
    const multiselectOutsideClick = (e) => {
      if (
        isMultiselectOpen &&
        multiselectRef.current &&
        !multiselectRef.current.contains(e.target)
      ) {
        setIsMultiselectOpen(false);
      }
    };
    document.addEventListener("click", multiselectOutsideClick);
    return () => {
      document.removeEventListener("click", multiselectOutsideClick);
    };
  }, [isMultiselectOpen]);

  useEffect(() => {
    const customselectOutsideClick = (e) => {
      if (
        isCustomselectOpen &&
        customselectRef.current &&
        !customselectRef.current.contains(e.target)
      ) {
        setIsCustomselectOpen(false);
      }
    };
    document.addEventListener("click", customselectOutsideClick);
    return () => {
      document.removeEventListener("click", customselectOutsideClick);
    };
  }, [isCustomselectOpen]);

  const createInvitationHandler = () => {
    const agenda =
      customselectSelectedValue === "Others"
        ? otherAgenda
        : customselectSelectedValue;
    // const email = updateEmail
    const serviceUpdated = service;
    //  fields["Service"] !== undefined ? fields["Service"] : service;

    let fieldErrors = {};
    let hasMissingFields = false;

    console.log('email: ', email);

    if (email === undefined || email.length === 0) {
      hasMissingFields = true;
      fieldErrors.email = "Email is required.";
    }

    if (multiselectSelectedValues.length === 0) {
      hasMissingFields = true;
      fieldErrors.service = "Service is required.";
    }

    if (callLink.length === 0) {
      hasMissingFields = true;
      fieldErrors.callLink = "Call Link is required.";
    }

    if(customselectSelectedValue === "Select Agenda") {
      hasMissingFields = true;
      fieldErrors.agenda = "Agenda is required.";
    }

    if (agenda.length === 0) {
      hasMissingFields = true;
      fieldErrors.agenda = "Agenda is required.";
    }

    setErrors(fieldErrors);

    if (!hasMissingFields) {
      let request = {
        name: utilitiesService.encrypt(name),
        email: utilitiesService.encrypt(email),
        services: utilitiesService.encrypt(
          multiselectSelectedValues.join(', ')
        ),
        agenda: utilitiesService.encrypt(agenda),
        call_link: utilitiesService.encrypt(callLink),
        lead_id: params.leadId,
      };

      dispatch(
        leadActions.sendCalendlyInvite(
          request,
          params.id,
          params.leadId,
          hasMissingFields
        )
      );
    }
  };

  const toggleTab = (index) => {
    setToggleTabState(index);
  };

  const toggleAlert = () => {
    setToggleAlertState(!toggleAlertState);
    onHandleCalendlyDrawer();
    setTimeout(() => {
      setToggleAlertState(false);
    }, 5000);
  };

  const handleCancel = () => {
    setMultiselectSelectedValues([]);
    setCustomselectSelectedValue("");
    setCallLink("");
    setErrors({});
    onHandleCalendlyDrawer();
  };

  let activeClass = isCalendlyDrawer ? "active" : "";

  return (
    <React.Fragment>
      <>
        <div
          className={`drawer-overlay ${activeClass}`}
          onClick={onHandleCalendlyDrawer}
        ></div>
        <div className={`side-drawer-main ${activeClass}`}>
          <div className="side-drawer-wrapper">
            <div className="side-drawer-title">
              <h3>Create a Calendly Invitation</h3>
              <span
                className="side-drawer-close"
                onClick={onHandleCalendlyDrawer}
              >
                <i className="icon-lms icon-close"></i>
              </span>
            </div>
            <div className="side-drawer-tabs">
              <div className="side-drawer-tabs-wrapper">
                <div className="control-tabs-box">
                  <div
                    className={
                      toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"
                    }
                    onClick={() => toggleTab(1)}
                  >
                    <p>Details</p>
                  </div>
                  <div className="line-slider"></div>
                </div>
                <div className="content-tabs-box">
                  {!calendlyLoading && isCalendlyDrawer && (
                    <div
                      className={
                        toggleTabState === 1
                          ? "tabs-content active"
                          : "tabs-content"
                      }
                    >
                      <div className="forms-tab">
                        <MDBRow>
                          <MDBCol xl="12" lg="12" className="form-group-box">
                            <div className="input-group-box full-box-">
                              <InputForm
                                label="Name"
                                type="text"
                                id="name"
                                name="name"
                                readOnly={true}
                                defaultValue={name}
                              />
                              <InputForm
                                label="Email Address"
                                type="email"
                                id="email"
                                name="email"
                                readOnly={true}
                                defaultValue={email}
                              />
                              {errors.email && (
                                <p className="error-field">{errors.email}</p>
                              )}
                              {/* <InputForm
                                  label="Services"
                                  type="text"
                                  id="services"
                                  name="services"
                                  defaultValue={service}
                                  onChange={(e) =>
                                    handleService(e.target.value)
                                  }
                                /> */}
                              <div className="field-box">
                                <label>Services</label>
                                <div
                                  className="custom-dropdown-multiselect"
                                  ref={multiselectRef}
                                >
                                  <div
                                    className="multiselect-btn"
                                    onClick={() => handleOpenMultiselect()}
                                  >
                                    <span className="multiselect-text">
                                      {multiselectSelectedValues.join(',')}
                                    </span>
                                    <i
                                      className={
                                        isMultiselectOpen ? "active" : ""
                                      }
                                    ></i>
                                  </div>
                                  <div
                                    className={
                                      isMultiselectOpen
                                        ? "multiselect-dropdown active"
                                        : "multiselect-dropdown"
                                    }
                                  >
                                    <div className="multiselect-list">
                                      {services.map((service) => (
                                        <div
                                          className="checkbox-group"
                                          key={service}
                                        >

                                          <input
                                           checked={multiselectSelectedValues.map(item => item.trim().toLowerCase()).includes(service.trim().toLowerCase())}
                                           type="checkbox"
                                            name={service}
                                            id={`${service}-calendly`}
                                            value={service}
                                            onChange={
                                              handleMultiselectCheckboxChange
                                            }
                                          />
                                          <label
                                            htmlFor={`${service}-calendly`}
                                          >
                                            {service}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                {errors.service && (
                                  <p className="error-field">
                                    {errors.service}
                                  </p>
                                )}
                              </div>
                              <div className="field-box">
                                <label>Agenda</label>
                                <div
                                  className="custom-dropdown-multiselect"
                                  ref={customselectRef}
                                >
                                  <div
                                    className="multiselect-btn"
                                    onClick={() => handleOpenCustomselect()}
                                  >
                                    <span className="multiselect-text">
                                      {customselectSelectedValue}
                                    </span>
                                    <i
                                      className={
                                        isCustomselectOpen ? "active" : ""
                                      }
                                    ></i>
                                  </div>
                                  <div
                                    className={
                                      isCustomselectOpen
                                        ? "multiselect-dropdown active"
                                        : "multiselect-dropdown"
                                    }
                                  >
                                    <div className="multiselect-list">
                                      {agenda.map((option) => (
                                        <div
                                          className="checkbox-group"
                                          key={option}
                                          onClick={() =>
                                            handleCustomselectSelectValue(
                                              option
                                            )
                                          }
                                        >
                                          <span>{option}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                {errors.agenda && (
                                  <p className="error-field">{errors.agenda}</p>
                                )}
                                {/* <select
                                    className="c-select"
                                    value={select}
                                    onChange={(e) =>
                                      handleAgenda(e.target.value)
                                    }
                                  >
                                    <option hidden>Select One</option>
                                    <option>Requirements Clarification</option>
                                    <option>Pitch</option>
                                    <option>Proposal Clarification</option>
                                    <option>Others</option>
                                  </select> */}
                                <p className="error-field"></p>
                              </div>
                              {customselectSelectedValue === "Others" && (
                                <InputForm
                                  label="Other"
                                  type="text"
                                  id="otherAgenda"
                                  name="otherAgenda"
                                  defaultValue={otherAgenda}
                                  onChange={(e) =>
                                    handleOthersAgenda(e.target.value)
                                  }
                                />
                              )}
                              <InputForm
                                label="Call Link"
                                type="text"
                                id="calllink"
                                name="calllink"
                                defaultValue={callLink}
                                error={errors.callLink}
                                placeholder="Zoom Link or Gmeet Link"
                                onChange={(e) => handleCallLink(e.target.value)}
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                  )}
                  {calendlyLoading && (
                    <div className="loading-box">
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="tab-form-btn-group">
                  <MDBRow>
                    <MDBCol className="text-right">
                      <span
                        className="drawer-btn cancel-btn"
                        onClick={handleCancel}
                      >
                        Cancel
                      </span>
                      {/* <button className='drawer-btn save-btn' onClick={() => toggleAlert()}> */}
                      {!calendlyLoading && (
                        <span
                          className="drawer-btn save-btn"
                          onClick={createInvitationHandler}
                        >
                          Create Invitation
                        </span>
                      )}
                      {calendlyLoading && (
                        <span
                          className="drawer-btn cancel-btn c-btn-disabled"
                          onClick={createInvitationHandler}
                          disabled={true}
                        >
                          Creating Invitation ...
                        </span>
                      )}
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            toggleAlertState
              ? "alert alert-success alert-dismissible show"
              : "alert alert-success alert-dismissible fade"
          }
        >
          You have successully sent email with Calendly Booking URL to{" "}
          {fields["Full Name"] !== undefined
            ? fields["Full Name"]
            : fields["First Name"]}
          <button type="button" className="close" aria-label="Close">
            <span inert="true">×</span>
          </button>
        </div>
      </>
    </React.Fragment>
  );
};

export default Calendly;
