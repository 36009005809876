import { httpManagerService as httpManager } from  '_services';

export const fileService = {
    getFiles,
    addFile,
    updateFile,
    deleteFile
}

/**
 * Fetches files associated with a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @returns {Promise} - A promise that resolves to the response of the HTTP request.
 */
function getFiles(projectId, leadId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/lead/${leadId}/file`);
}

/**
 * Adds a file to a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {FormData} formData - The form data containing the file to be added.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function addFile(projectId, leadId, formData) {
    return httpManager.postFormDataOnce(`/api/v2/project/${projectId}/lead/${leadId}/file`, formData);
}

/**
 * Updates a file associated with a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {string} fileId - The ID of the file to be updated.
 * @param {FormData} formData - The form data containing the file information to be updated.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function updateFile(projectId, leadId, fileId, formData) {
    return httpManager.putFormDataOnce(`/api/v2/project/${projectId}/lead/${leadId}/file/${fileId}`, formData);
}

/**
 * Deletes a file associated with a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {string} fileId - The ID of the file to be deleted.
 * @returns {Promise} - A promise that resolves when the file is successfully deleted.
 */
function deleteFile(projectId, leadId, fileId){
    return httpManager.deleteHTTPOnce(`/api/v2/project/${projectId}/lead/${leadId}/file/${fileId}`);
}