import { utilitiesService } from "_services";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { templateActions } from "_actions";

const EmailTemplateControls = ({
  templateType,
  onSearchTerm,
  toggleEmailTemplateType,
}) => {
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchValue, setIsSearchValue] = useState("");
  const searchRef = useRef();
  const searchInputRef = useRef();

  const [openFilter, setOpenFilter] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const template = useSelector((state) => state.template);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (toggleEmailTemplateType == 1) {
      dispatch(templateActions.getTemplateAccountCategories(params.id));
    }

    if (toggleEmailTemplateType == 2) {
      dispatch(templateActions.getTemplateSharedCategories(params.id));
    }
  }, [toggleEmailTemplateType]);

  useEffect(() => {
    if (template.getCategoryLoaded && template.category) {
      setCategories(template.category);
    }
  }, [template.getCategoryLoaded]);

  const handleOpenFilter = (dropdownName) => {
    setOpenFilter((prevOpenDropdown) =>
      prevOpenDropdown === dropdownName ? null : dropdownName
    );

    if (toggleEmailTemplateType == 1) {
      dispatch(templateActions.getTemplateAccountCategories(params.id));
    }

    if (toggleEmailTemplateType == 2) {
      dispatch(templateActions.getTemplateSharedCategories(params.id));
    }
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenSearch = () => {
    setIsSearch(true);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setIsSearchValue("");
    console.log('clear search....');

    const decryptedCategories = selectedCategories.map((encryptedCategory) =>
      utilitiesService.decrypt(encryptedCategory)
    );

    if (toggleEmailTemplateType == 1) {
        dispatch(
          templateActions.getTemplates(
            params.id,
            "",
            1,
            15,
            decryptedCategories
          )
        );
      }
  
      if (toggleEmailTemplateType == 2) {
        dispatch(
          templateActions.getSharedTemplatePaginated(
            params.id,
            "",
            1,
            15,
            decryptedCategories
          )
        );
      }

  };
  const handleCloseSearch = () => {
    setIsSearch(false);
    setIsSearchValue("");
  };

  const handleChangeSearch = (event) => {
    setIsSearchValue(event.target.value);
  };

  const handleSearch = () => {
    console.log("template type: ", isSearchValue);
    onSearchTerm(isSearchValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      handleClearSearch();
    }
  };
  useEffect(() => {
    // Add event listener for key press on window
    window.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter(
            (selectedCategory) => selectedCategory !== category
          )
        : [...prevSelected, category]
    );
  };

  const handleApplyFilter = () => {
    // Dispatch your actions with selectedCategories
    console.log("Selected categories:", selectedCategories);
    // Decrypt selectedCategories
    const decryptedCategories = selectedCategories.map((encryptedCategory) =>
      utilitiesService.decrypt(encryptedCategory)
    );

    if (toggleEmailTemplateType == 1) {
      dispatch(
        templateActions.getTemplates(
          params.id,
          isSearchValue,
          1,
          15,
          decryptedCategories
        )
      );
    }

    if (toggleEmailTemplateType == 2) {
      dispatch(
        templateActions.getSharedTemplatePaginated(
          params.id,
          isSearchValue,
          1,
          15,
          decryptedCategories
        )
      );
    }
    handleCloseFilter();
  };

  const handleClearFilter = () => {
    // Dispatch your actions with selectedCategories
    setSelectedCategories([]);
    console.log("Selected categories:", selectedCategories);
    if (toggleEmailTemplateType == 1) {
      dispatch(
        templateActions.getTemplates(params.id, isSearchValue, 1, 15, "")
      );
    }

    if (toggleEmailTemplateType == 2) {
      dispatch(
        templateActions.getSharedTemplatePaginated(
          params.id,
          isSearchValue,
          1,
          15,
          ""
        )
      );
    }
    handleCloseFilter();
  };

  const isAnyCheckboxChecked = selectedCategories.length > 0;

  return (
    <div className="overview-control-box">
      <div className="col-left">
        <div className="button-group">
          <div
            className={isSearch ? "search-box active" : "search-box"}
            ref={searchRef}
          >
            <div className="input-search">
              <i className="icon-lms icon-search"></i>
              <input
                type="text"
                placeholder="Search Template"
                ref={searchInputRef}
                value={isSearchValue}
                onChange={handleChangeSearch}
                onClick={() => handleOpenSearch()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(); 
                  }
                }}
              />
              <div className="controls">
                <i
                  className="icon-lms icon-close"
                  onClick={() => handleClearSearch()}
                ></i>
                <i
                  className="icon-lms icon-arrow-right"
                  onClick={() => handleSearch()}
                ></i>
              </div>
            </div>
          </div>

          <div className="filter-box filter-normal">
            <div
              className="filter-btn"
              onClick={() => handleOpenFilter("category")}
            >
              <i className="icon-lms icon-filter"></i>
              Filter by category
            </div>
            <div
              className={
                openFilter === "category"
                  ? "filter-dropdown active"
                  : "filter-dropdown"
              }
            >
              <div className="filter-title">
                <p>Filters</p>
                <i
                  className="icon-lms icon-close"
                  onClick={() => handleCloseFilter()}
                ></i>
              </div>
              <div className="filter-list">
                {categories.map((category, index) => (
                  <div key={category.id} className="checkbox-group">
                    <input
                      type="checkbox"
                      name={`category-${index}`}
                      id={`category-${index}`}
                      onChange={() => handleCheckboxChange(category)}
                      checked={selectedCategories.includes(category)}
                    />
                    <label htmlFor={`category-${index}`}>
                      {utilitiesService.decrypt(category)}
                    </label>
                  </div>
                ))}
              </div>
              <div className="filter-control-box">
                <span className="btn-reset" onClick={() => handleClearFilter()}>
                  Clear Filter
                </span>

                <span className={`btn-apply ${!isAnyCheckboxChecked ? 'disabled' : ''}`} onClick={() => handleApplyFilter()}>
                  Apply Filter
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateControls;
