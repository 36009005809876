import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import TopBar from '../../_components/TopBar';
import ProjectDetails from '../../_components/Drawer/ProjectDetails';
import ProjectMembers from '../../_components/Drawer/ProjectMembers';
import { AddInfluencerDrawer } from '_pages/Influencer/components/Drawer/AddInfluencerDrawer';
import { projectActions } from "_actions";
import Button from "_pages/Influencer/components/General/Button";
import { influencerActions } from "_actions/influencer.actions";
import { KpiHeader } from "_pages/Influencer/components/Kpi/KpiHeader";
import { KpiControl } from "_pages/Influencer/components/Kpi/KpiControl";
import { InfluencerTableList } from "_pages/Influencer/components/Table/InfluencerTableList";
import { TablePagination } from '_pages/Influencer/components/Table/TablePagination';

const Influencer = (state) => {
    const { match, getProject, getInfluencerList, getInfluencerSummary } = state;
    const projectId = match.params.id;
    const [activeBox, setActiveBox] = useState('');
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isKpiHeaderExpanded, setIsKpiHeaderExpanded] = useState(true);
    const [isProjectDetailsDrawer, setIsProjectDetailsDrawer] = useState(false);
    const [isProjectMembersDrawer, setIsProjectMembersDrawer] = useState(false);
    const [isAddInfluencerDrawer, setIsAddInfluencerDrawer] = useState(false);
    const [query, setQuery] = useState(null);
    const [pageSize, setPageSize] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [dates, setDates] = useState([]);
    const [resetFilterState, setResetFilterState] = useState(false);
    let kpiTotalHeight = 0;

    const handleFilterKpi = (group, value) => {
        console.log(`clicked here...page: ${currentPage}, page size: ${pageSize}`);

        setSelectedPlatform([group]);
        setSelectedStatus([]);
        setQuery('');
        setCurrentPage(1);
        setActiveBox(value);
        setResetFilterState(true);
        setDates([]);

        if (group === 0) {
            handleReset();
        } else {
            setCurrentPage(1);
            getInfluencerList(projectId, null, pageSize, query, [], [group], [], null, null, null, null);
        }
    };

    const handleReset = () => {
        setSelectedPlatform([]);
        setSelectedStatus([]);
        setQuery('');
        setCurrentPage(1);
        setActiveBox('');
        setResetFilterState(false);
        setDates([]);
    };

    useEffect(() => {
        getProject(projectId);
        getInfluencerList(projectId);
    }, [getProject, getInfluencerList, projectId]);

    const handleApplyFilter = (status, platform, category) => {
        setSelectedStatus(status);
        setSelectedPlatform(platform);
        setSelectedCategories(category);
        setCurrentPage(1);
        setActiveBox('');
        getInfluencerList(projectId, 1, pageSize, query, status, platform, category);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getInfluencerList(projectId, page, pageSize, query, selectedStatus, selectedPlatform, selectedCategories);
    };

    const handleSearch = (updatedQuery) => {
        setQuery(updatedQuery);
        setCurrentPage(1);
        getInfluencerList(projectId, 1, pageSize, updatedQuery, selectedStatus, selectedPlatform, selectedCategories);
    };

    const handleSizeChange = (size) => {
        setPageSize(size);
        getInfluencerList(projectId, currentPage, size, query, selectedStatus, selectedPlatform, selectedCategories);
    };

    const handleDateChange = (dates) => {
        setDates(dates);
        let dateFrom = dates && dates[0] ? moment(dates[0]).format('YYYY-MM-DD') : null;
        let dateTo = dates && dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : null;
        getInfluencerList(projectId, 1, pageSize, query, selectedStatus, selectedPlatform, selectedCategories, null, null, dateFrom, dateTo);
    };

    return (
        <>
            <div className='topbar-menu'>
                <div className='row align-items-center'>
                    <TopBar 
                        onHandleProjectDetailsDrawer={() => setIsProjectDetailsDrawer(!isProjectDetailsDrawer)}
                        onHandleProjectMembersDrawer={() => setIsProjectMembersDrawer(!isProjectMembersDrawer)}
                    />
                    <div className='col'>
                        <div className='top-button-box'>
                            <Button classN="c-btn" text='Add Influencer' icon="icon-lms icon-add-user" onClick={() => setIsAddInfluencerDrawer(!isAddInfluencerDrawer)} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='overview-menu'>
                <KpiControl
                    projectId={projectId}
                    dates={dates}
                    onApplyFilter={handleApplyFilter}
                    onHandlePageChanged={handlePageChange}
                    onHandleSearch={handleSearch}
                    onHandleDateChanged={handleDateChange}
                    onHandleSizeChanged={handleSizeChange}
                    onHandleKpiHeaderHeightChanged={setIsKpiHeaderExpanded}
                    resetFilterState={resetFilterState}
                />
            </div>

            <KpiHeader
                onHandleKpiHeaderHeightChanged={setIsKpiHeaderExpanded}
                clickedBox={activeBox}
                onHandleReset={() => handleApplyFilter([], [], [])}
                projectId={projectId}
                isKpiHeaderExpanded={isKpiHeaderExpanded}
                onHandleKpiHeaderFilter={(group, value, color) => handleFilterKpi(group, value, color)}
                kpiTotalHeight={kpiTotalHeight}
                onApplyFilter={(status, platform, category) => this.handleApplyFilter(status, platform, category)}
            />

            <div className='main-table-section'>
                <InfluencerTableList
                    isKpiHeaderExpanded={isKpiHeaderExpanded}
                    onHandleAddInfluencerDrawer={() => setIsAddInfluencerDrawer(!isAddInfluencerDrawer)}
                />
                <TablePagination 
                    projectId={projectId}
                    dates={dates}
                    onApplyFilter={handleApplyFilter}
                    onHandlePageChanged={handlePageChange}
                    onHandleSearch={handleSearch}
                    onHandleDateChanged={handleDateChange}
                    onHandleSizeChanged={handleSizeChange}
                />
            </div>

            <ProjectDetails 
                isProjectDetailsDrawer={isProjectDetailsDrawer} 
                onHandleProjectDetailsDrawer={() => setIsProjectDetailsDrawer(!isProjectDetailsDrawer)} 
            />

            <ProjectMembers 
                isProjectMembersDrawer={isProjectMembersDrawer} 
                onHandleProjectMembersDrawer={() => setIsProjectMembersDrawer(!isProjectMembersDrawer)} 
            />

            <AddInfluencerDrawer 
                projectId={projectId}
                isAddInfluencerDrawer={isAddInfluencerDrawer} 
                onHandleAddInfluencerDrawer={() => setIsAddInfluencerDrawer(!isAddInfluencerDrawer)} 
            />
        </>
    );
};

const mapState = (state) => ({ projects: state.projects, influencers: state.influencers });

const actionCreators = {
    getProject: projectActions.getProject,
    getInfluencerList: influencerActions.getInfluencerList,
    getInfluencerSummary: influencerActions.getInfluencerSummary,
};

const connectedInfluencer = connect(mapState, actionCreators)(Influencer);
export { connectedInfluencer as Influencer };