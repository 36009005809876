import React, {useEffect, useRef, useState} from 'react';
import Button from "_pages/Influencer/components/General/Button";
import {EditorContent, useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import Focus from '@tiptap/extension-focus';
import suggestion from '../../../Influencer/components/Info/NoteSuggestion';
import {useDispatch, useSelector} from "react-redux";
import DOMPurify from "dompurify";
import {httpManagerService, utilitiesService as utility} from "_services";
import Moment from "react-moment";
import {leadActions} from "_actions";
import {useParams} from "react-router-dom";
import NoteItem from "_pages/Leads/components/LeadInfo/NoteItem";
import NoteEditorBar from "_pages/Leads/components/LeadInfo/NoteEditorBar";
import { alertActions } from "_actions";

const parseMentions = data => {
    const mentions = (data.content || []).flatMap(parseMentions)
    if (data.type === 'mention') {
        mentions.push(parseInt(data.attrs.id))
    }
    return mentions
}


const LeadViewNotesNew = ({ handleDeleteLeadNote, lead }) => {

    const params = useParams();

    const dispatch = useDispatch();

    const notesLoaded = useSelector((state) => state.leads.notesLoaded);

    const isLeadManager = httpManagerService.isCurrentUserLeadManager(lead, params.id);

    const notes = useSelector((state) => {
        const {leads} = state;
        if (leads.notesLoaded) {
            if(leads.notes) {
                return leads.notes.data;
            }
        } else {
            return [];
        }
    });

    const mentions = useSelector((state) => {

        const {projects} = state;
        const {project, projectLoaded} = projects;

        if(projectLoaded) {

            project.data.owner.account.id = project.data.owner.id.toString();
            let members = [project.data.owner.account];

            project.data.members.forEach( m =>
                members.push(m.account)
            );

            return members.map(m => {
                return {id: m.id, label: utility.decrypt(m.first_name) + ' ' + utility.decrypt(m.last_name)}
            });

        } else {
            return [];
        }
    });

    const [noteClicked, setNoteClicked] = useState(false);
    const noteRef = useRef();
    const addNoteRef = useRef(null);

    suggestion.items = ({query}) => {
        return mentions.filter(item => item.label.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5);
    }

    const [isEditorEmpty, setEditorEmpty] = useState(true);

    const editorCreate = useEditor({
        extensions: [
            StarterKit,
            Mention.configure({
                HTMLAttributes: {
                  class: 'mention',
                },
                suggestion,
            }),
            Placeholder.configure({
              placeholder: 'Create Note Thread...',
            }),
        ],
        autofocus: true,
        content: ``,
    });

    useEffect(() => {
        if (editorCreate) {
          setEditorEmpty(editorCreate.isEmpty);
    
          // Add event listener to editor's content
          editorCreate.on('update', () => {
            setEditorEmpty(editorCreate.isEmpty);
          });
        }
    }, [editorCreate]);


    const handleToggleNote = () => {
        if(!noteClicked && !isLeadManager) {
            // restrict creation of notes to lead manager
            dispatch(
                alertActions.error(
                    {
                    error_code: "403",
                    error_message: "You are not assigned as Lead Manager for this Lead",
                    },
                    6000
                )
            );
            return;
        }
        setNoteClicked(!noteClicked);
        editorCreate.commands.focus('end');
    };

    const clearEditorContent = () => {
        const emptyContent = {
          type: 'doc',
          content: [{ type: 'paragraph' }],
        };
    
        editorCreate.commands.setContent(emptyContent);
      };

    const handleCreate = () => {

        const projectId = params.id;
        const leadId = params.leadId;
        const mentions = parseMentions(editorCreate.getJSON());
        const content = editorCreate.getHTML();

        let noteData = {
            name: 'Note',
            description: content,
            mentions: mentions
        };

        let noteType = 'note';

        dispatch(leadActions.addLeadNote(projectId, leadId, noteType, noteData));

        setNoteClicked(!noteClicked);
        editorCreate.commands.focus('end');
        clearEditorContent();
    }


    const mentionClickCreate = () => {
        editorCreate.commands.insertContent(' @');
    }

    const handleToggleDeleteNote = (note) => {
        handleDeleteLeadNote(note);
    }

    return(
        <div className="notes-box tab-content-box">
            <div className='note-group'>
                <div className='inner-title-box' ref={addNoteRef}>
                    <p>
                        NOTES
                    </p>
                    { notesLoaded && notes.length > 0 &&
                    <Button
                        text={noteClicked ? 'Close Note' : 'Add Note'}
                        icon={noteClicked ? 'icon-lms icon-close' : 'icon-lms icon-add'}
                        onClick={ () => handleToggleNote()}
                        classN="plain-btn"
                    />
                    }
                    <div className='note-create-box' ref={noteRef}
                        style={
                            noteClicked
                                ? { height: noteRef.current.scrollHeight }
                                : { height: "0" }
                        }
                        >
                            <div className='note-wrapper'>
                                <div className='custom-basic-editor note-editor'>
                                    <NoteEditorBar editor={editorCreate} />
                                    <EditorContent editor={editorCreate} className='thread-editor-field' />
                                    <div className='editor-btns-group note-editor-btns-group'>
                                        <div className="btn-left">
                                            <span onClick={() => mentionClickCreate()}>
                                                <i className="fas fa-at"></i>Mention
                                            </span>
                                        </div>
                                        <div className="btn-right">
                                            <button className={isEditorEmpty ? 'plain-btn disabled' : 'plain-btn'} onClick={() => handleCreate()}>Create</button>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className='notes-list'>
                {notes.map((note, index)=> {
                    return(<NoteItem
                        isLeadManager={isLeadManager}
                        note={note}
                        onHandleToggleDeleteNoteAction={(note)=> handleToggleDeleteNote(note)}
                    />);
                })}
            </div>

            { notesLoaded && notes.length < 1 &&
                <div className='empty-tab-content'>
                    <div className='empty-content'>
                        <h6>No Available Note</h6>
                        <p>
                            Notes and replies will appear here
                        </p>
                        <span onClick={ () => handleToggleNote()}>
                            <i className="icon-lms icon-add"></i>
                            Add Note
                        </span>
                    </div>
                </div>
            }

            {
                !notesLoaded &&
                <div className='loading-box'>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            }
        </div>
    )
}

export default LeadViewNotesNew;



