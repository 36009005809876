import React, { useState, useEffect, useRef } from 'react';
import ClickStatusInfo from './ClickStatusInfo';
import HoverStatusInfo from './HoverStatusInfo';
import { leadActions } from '_actions';
import { connect } from 'react-redux';
import { abbreviateNumber } from '_services/utilities.service';
import { KpiHeaderFilter } from './KpiHeaderFilter';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper';
import KpiLoader from '../../../../_components/Loader/KpiLoader';

const KpiHeader = ({ page, summaryLoaded, isKpiHeaderExpanded, onHandleKpiHeaderFilter, onHandleReset }) => {
    const kpiRef = useRef(null);
    
    const [visibleKpis, setVisibleKpis] = useState([]);
    const [kpisFiltered, setKpisFiltered] = useState(false);
    const [kpiTotalHeight, setKpiTotalHeight] = useState(200);

    const [grouped, setGrouped] = useState({});
    const [categories, setCategories] = useState([]);
    const [ungrouped, setUngrouped] = useState([]);
    const [custom, setCustom] = useState({});
    const [total, setTotal] = useState(0);

    let activeClass = isKpiHeaderExpanded ? 'active' : '';

    useEffect(() => {
        if (kpiRef.current) {
            setKpiTotalHeight(kpiRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        if (summaryLoaded) {
            const newUngrouped = page.data || [];
            let newTotal = 0;
            let newCategories = [];
            let newGrouped = newUngrouped.reduce((collection, elem) => {
                let key = elem.category;
                if (!newCategories.includes(key) && key !== 'Custom') {
                    newCategories.push(key);
                }
                newTotal += elem.count;
                collection[key] = collection[key] || [];
                collection[key].push(elem);
                return collection;
            }, {});

            let newCustom = {};
            if (newGrouped['Custom']) {
                newCustom = {
                    visits: newGrouped['Custom'].find(e => e.label === 'Total Number of Visits')?.value || 0,
                    average: parseFloat(newGrouped['Custom'].find(e => e.label === 'Average Patient Lead Value')?.value) || 0,
                    matched: newGrouped['Custom'].find(e => e.label === 'Total Matched Patient')?.value || 0,
                    total: parseFloat(newGrouped['Custom'].find(e => e.label === 'Total Patient Lead Value')?.value) || 0,
                };
            }

            setUngrouped(newUngrouped);
            setGrouped(newGrouped);
            setCategories(newCategories);
            setTotal(newTotal);
            setCustom(newCustom);
        }
    }, [summaryLoaded, page]);

    const handleKpiHeaderFilter = (value, key, color) => {
        onHandleKpiHeaderFilter(value, key, color);
    };

    const handleReset = () => {
        onHandleReset();
        setKpisFiltered(false);
    };

    const handleKpiHeaderFilterChanged = (data) => {
        if (visibleKpis !== data) {
            setVisibleKpis(data);
            setKpisFiltered(true);
        }
    };

    return (
        <React.Fragment>
            <div
                className={`overview-kpi-box leads-kpi ${activeClass}`}
                ref={kpiRef}
                style={isKpiHeaderExpanded ? { maxHeight: kpiTotalHeight } : { maxHeight: '0' }}
            >
                <div className="main-box">
                    <div className="status-list-box">
                        <div className="status-list">
                            <Swiper
                                watchSlidesProgress={true}
                                observer={true}
                                observeParents={true}
                                observeSlideChildren={true}
                                resizeObserver={true}
                                slidesPerView={'auto'}
                                spaceBetween={12}
                                freeMode={true}
                                modules={[FreeMode, Navigation]}
                                className="kpiSwiper"
                                navigation={{ nextEl: '.nextSlide', prevEl: '.prevSlide' }}
                                onSlideChange={(swiper) => swiper.update()}
                            >
                                <SwiperSlide>
                                    <div className="box" onClick={() => handleKpiHeaderFilter(ungrouped)}>
                                        <div className="status-title">
                                            <p className="main-title">Total Leads</p>
                                        </div>
                                        <div className="status-result">
                                            <div className="result-box">
                                                <HoverStatusInfo info="Total Leads" />
                                                <span className="result-count">{total}</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                {grouped['Custom'] && (
                                    <SwiperSlide>
                                        <div className="box custom">
                                            <div className="status-title">
                                                <p className="main-title">Patient Lead Value</p>
                                            </div>
                                            <div className="status-result">
                                                <div className="result-box">
                                                    <HoverStatusInfo info="Patient Lead Value" />
                                                    <span className="result-count">
                                                        ₱ {abbreviateNumber(custom.total) || '0.00'}
                                                    </span>
                                                </div>
                                                <div className="result-box">
                                                    <HoverStatusInfo info="Average Patient Lead Value" />
                                                    <span className="result-count">
                                                        ₱ {abbreviateNumber(custom.average) || '0.00'} <small>ave</small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}

                                {categories.map((value, index) => {
                                    let color = index + 1;
                                    return (
                                        <SwiperSlide
                                            key={index}
                                            className={visibleKpis.includes(value) || !kpisFiltered ? '' : 'not-filtered'}
                                        >
                                            <div
                                                className={`box kpi-color-${color}`}
                                                onClick={() => handleKpiHeaderFilter(grouped[value], value, color)}
                                            >
                                                <div className="status-title">
                                                    <p className="main-title">{value}</p>
                                                </div>
                                                <div className="status-result">
                                                    {grouped[value].map((item, idx) => (
                                                        <div className="result-box" key={idx}>
                                                            <HoverStatusInfo info={item.label} />
                                                            <span className="result-count">{item.count}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>

                            <button className="swiper-custom-btn prevSlide">
                                <i className="icon-lms icon-arrow-left"></i>
                            </button>
                            <button className="swiper-custom-btn nextSlide">
                                <i className="icon-lms icon-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                {!summaryLoaded && <KpiLoader />}
            </div>
        </React.Fragment>
    );
};

function mapState(state) {
    const { page, summaryLoaded } = state.summary;
    return { page, summaryLoaded };
}

const actionCreators = {
    getLeadSummary: leadActions.getLeadSummary,
};

const connectedViewKpiHeader = connect(mapState, actionCreators)(KpiHeader);
export { connectedViewKpiHeader as KpiHeader };
