import React, { useState, useEffect, useRef, useMemo } from "react";
import StatusAction from "_pages/Leads/components/Table/Actions/StatusAction";
import PriorityAction from "_pages/Leads/components/Table/Actions/PriorityAction";
import AssigneeAction from "_pages/Leads/components/Table/Actions/AssigneeAction";
import DeleteActionModal from "_pages/Leads/components/Table/Actions/DeleteActionModal";
import { useDispatch, useSelector } from "react-redux";
import { utilitiesService } from "_services";
import { useParams } from "react-router-dom";
import { leadActions } from "_actions";

const TableActions = ({
  isTableActionBox,
  handleCloseTableAction,
  status,
  managers,
  selectedLeadIds = [],
  pageSize,
  batchUpdate,
}) => {
  const [selectedLeads, setSelectedLeads] = useState(false);
  const [lastSelectedCount, setLastSelectedCount] = useState(selectedLeadIds?.length || 0);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [batchAction, setBatchAction] = useState('');
  const [isAlert, setIsAlert] = useState(true);

  const dispatch = useDispatch();
  const param = useParams();

  // Effect to update local state when propSelectedLeadIds changes

  let priority = [
    {
      id: 1,
      value: "1",
      label: "Low",
    },
    {
      id: 2,
      value: "2",
      label: "Medium",
    },
    {
      id: 3,
      value: "3",
      label: "High",
    },
  ];

  let batchLoaded = useSelector((state) => state.leads.batchLoaded);

  // console.log('data: ', data);
  // console.log('managers: ', managers);
  // console.log('status: ', status);
  //   console.log('selected: ', selectedLeads);

  useEffect(() => {
    setIsAlert(batchLoaded)
  }, [batchLoaded]);

  useEffect(() => {
    setSelectedLeads(selectedLeadIds);
  }, [selectedLeadIds]);

  const handleOpenDropdown = (dropdownName) => {
    setBatchAction(dropdownName)
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === dropdownName ? null : dropdownName
    );
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(null);
  };

  const handleSetLeadStatus = (selectedStatus) => {
    const request = {
      lead_ids: selectedLeads,
      field: selectedStatus,
      type: "leadStatus",
    };
    
    dispatch(leadActions.batchSelectLeads(param.id, pageSize, request));
    
    setLastSelectedCount(selectedLeadIds.length);
    return batchUpdate();
  };

  const handleSetLeadManager = (selectedManager) => {
    const request = {
      lead_ids: selectedLeads,
      field: utilitiesService.encrypt(selectedManager),
      type: "leadAssign",
    };

    dispatch(leadActions.batchSelectLeads(param.id, pageSize, request));

    setLastSelectedCount(selectedLeadIds.length);
    return batchUpdate();
  };

  const handleSetLeadPriority = (selectedPriority) => {
    const request = {
      lead_ids: selectedLeads,
      field: selectedPriority,
      type: "leadPriority",
    };

    dispatch(leadActions.batchSelectLeads(param.id, pageSize, request));
    setLastSelectedCount(selectedLeadIds.length);
    return batchUpdate();
  };

  const handleSetLeadDelete = () => {
    setBatchAction("delete");

    const request = {
      lead_ids: selectedLeads,
      field: "",
      type: "leadDelete",
    };

    dispatch(leadActions.batchSelectLeads(param.id, pageSize, request));

    setIsDeleteModal(false);
    setLastSelectedCount(selectedLeadIds.length);
    return batchUpdate();
  };

  const handleLeadManagers = (managers) => {
    return managers.map((item) => {
      const accountId = item.account.id;
      const email = utilitiesService.decrypt(item.account.email);
      const firstName = utilitiesService.decrypt(item.account.first_name);
      const lastName = utilitiesService.decrypt(item.account.last_name);

      // Concatenate first name and last name
      const fullName = `${firstName} ${lastName}`;

      return {
        accountId,
        email,
        fullName,
      };
    });
  };

  const handleLeadStatus = (status) => {
    const groupedData = status.reduce((acc, item) => {
      const { id, label, category } = item;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push({ id, label });
      return acc;
    }, {});

    // Convert grouped data to an array
    return Object.keys(groupedData).map((category) => ({
      category,
      items: groupedData[category],
    }));
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModal(false);
  };

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  const alert = useSelector((state) => {
    const { alert } = state;
    return alert;
  });
  
  const hasSuccessBatchUpdate = useMemo(() => {
    if (!alert || !Object.keys(alert).length) return true;
    if (alert.message === "You are not assigned as the lead manager for this lead.") {
      return false;
    }
    return true;
  }, [alert]);
  
  useEffect(() => {
    if (alert?.message === "You are not assigned as the lead manager for this lead.") {
      setIsAlert(false);
    }
  }, [alert, setIsAlert]);

  const handleSuccessMessage = (batchAction, oldCount) => {
    const count = lastSelectedCount;
    let lead = count == 1 ? 'lead' : 'leads'
    if(batchAction === 'assignee') {
        return "You have successfully assigned manager to " + count + " " + lead;
    }

    if(batchAction === 'priority') {
        return "You have successfully updated priority of " + count + " " + lead;
    }

    if(batchAction === 'status') {
        return "You have successfully updated status of " + count + " " + lead;
    }

    if(batchAction === 'delete') {
        return "You have successfully removed " + count + " " + lead;
    }
  }

  useEffect(() => {
    if (alert?.message === "You are not assigned as the lead manager for this lead.") {
      setIsAlert(false);
    }
  }, [alert, setIsAlert]);

  return (
    <>
      <div
        className={
          isAlert && batchAction && hasSuccessBatchUpdate
            ? "alert alert-success alert-dismissible"
            : "alert alert-success alert-dismissible fade"
        }
      >
        {handleSuccessMessage(batchAction, selectedLeads.length)}
        <button type="button" className="close" aria-label="Close" onClick={handleCloseAlert}>
          <span inert="true">&times;</span>
        </button>
      </div>
      <div
        className={
          isTableActionBox ? "table-actions-box active" : "table-actions-box"
        }
      >
        <div className="b1">
          <p>
            {selectedLeadIds.length}{" "}
            {selectedLeadIds.length == 1 ? "lead selected" : "leads selected"}
          </p>
        </div>
        <div className="b2">
          <div className="cl">
            <div
              className={
                openDropdown === "status" ? "action-box active" : "action-box"
              }
            >
              <div onClick={() => handleOpenDropdown("status")}>
                <i className="icon-lms icon-check-circle ai"></i>
                <span className="as">Status</span>
              </div>
              <StatusAction
                openDropdown={openDropdown}
                handleCloseStatusDropdown={() => handleCloseDropdown()}
                leadStatus={handleLeadStatus(status)}
                onSetLeadStatus={handleSetLeadStatus} // Pass the callback
              />
            </div>
          </div>
          <div className="cl">
            <div
              className={
                openDropdown === "priority" ? "action-box active" : "action-box"
              }
            >
              <div onClick={() => handleOpenDropdown("priority")}>
                <i className="icon-lms icon-list-numbered ai"></i>
                <span className="as">Priority</span>
              </div>
              <PriorityAction
                openDropdown={openDropdown}
                handleClosePriorityDropdown={() => handleCloseDropdown()}
                priorities={priority}
                onSetLeadPriority={handleSetLeadPriority}
              />
            </div>
          </div>
          <div className="cl">
            <div
              className={
                openDropdown === "assignee" ? "action-box active" : "action-box"
              }
            >
              <div onClick={() => handleOpenDropdown("assignee")}>
                <i className="icon-lms icon-user-o ai"></i>
                <span className="as">Assignee</span>
              </div>
              <AssigneeAction
                openDropdown={openDropdown}
                handleCloseAssigneeDropdown={() => handleCloseDropdown()}
                assignees={handleLeadManagers(managers)}
                onSetLeadManager={handleSetLeadManager} // Pass the callback
              />
            </div>
          </div>
          <div className="cl">
            <div className="action-box">
              <div onClick={() => handleOpenDeleteModal()}>
                <i className="icon-lms icon-delete ai"></i>
                <span className="as">Delete</span>
              </div>
            </div>
          </div>
        </div>
        <div className="b3">
          <span onClick={handleCloseTableAction}>
            <i className="icon-lms icon-close"></i>
          </span>
        </div>
      </div>
      <DeleteActionModal
        isOpen={isDeleteModal}
        handleOpenDeleteModal={() => handleOpenDeleteModal()}
        handleCloseDeleteModal={() => handleCloseDeleteModal()}
        handleSetLeadDelete={() => handleSetLeadDelete()}
      />
    </>
  );
};

export default TableActions;
