import React, { useState, useEffect, useRef } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { fileActions } from "_actions";

const LeadFileManageModal = ({
  mode,
  isOpen,
  data,
  leadId,
  projectId,
  handleCloseManageFileModal,
}) => {
  const dispatch = useDispatch();

  // Local state
  const [state, setState] = useState({
    isTitleError: false,
    isDescriptionError: false,
    isLinkError: false,
    isFileError: false,
    toggleTabState: 1,
    file: null, // Added file state
  });

  // Refs for inputs
  const titleInput = useRef(null);
  const descriptionInput = useRef(null);
  const linkInput = useRef(null);
  const fileInput = useRef(null);

  useEffect(() => {
    if (mode === "edit" && data) {
      // Set initial values when mode is edit
      if (titleInput.current) {
        titleInput.current.value = data.name || '';
      }
      if (descriptionInput.current) {
        descriptionInput.current.value = data.description || '';
      }
      if (linkInput.current) {
        linkInput.current.value = data.link || '';
      }
    } else {
      // Clear inputs when adding new record
      clearInputs();
    }
    setState((prevState) => ({ ...prevState, isFileError: false }));
  }, [mode, data]);

  const handleClose = () => {
    handleCloseManageFileModal(mode);
    clearInputs();
  };

  const clearInputs = () => {
    if (titleInput?.current) titleInput.current.value = '';
    if (descriptionInput?.current) descriptionInput.current.value = '';
    if (fileInput?.current) fileInput.current.value = '';
    if (linkInput?.current) linkInput.current.value = '';
  };

  const handleFileChange = (event) => {
    setState({ ...state, file: event.target.files[0], isFileError: false });
  };

  const setTitle = () => setState((prevState) => ({ ...prevState, isTitleError: false }));
  const setDescription = () => setState((prevState) => ({ ...prevState, isDescriptionError: false }));
  const setLink = () => setState((prevState) => ({ ...prevState, isLinkError: false }));

  const isFieldValid = () => {
    let isValid = true;

    const title = titleInput.current.value;
    const description = descriptionInput.current.value;
    const file = fileInput.current.value;

    if (title === "") {
      setState((prevState) => ({ ...prevState, isTitleError: true }));
      isValid = false;
    }

    if (description === "") {
      setState((prevState) => ({ ...prevState, isDescriptionError: true }));
      isValid = false;
    }

    if (mode === "add" && file === "") {
      setState((prevState) => ({ ...prevState, isFileError: true }));
      isValid = false;
    }

    return isValid;
  };

  const handleManageFile = () => {
    const { file } = state;
    const isValid = isFieldValid();
    if (!isValid) return;

    if (mode === "edit") {
      handleEditLeadFile(file);
    } else {
      handleAddLeadFile(file);
    }
  };

  const handleAddLeadFile = (file) => {
    const title = titleInput.current.value;
    const description = descriptionInput.current.value;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", title);
    formData.append("description", description);

    dispatch(fileActions.addFile(projectId, leadId, formData));
    handleClose();
  };

  const handleEditLeadFile = (file) => {
    const title = titleInput.current.value;
    const description = descriptionInput.current.value;
    const link = linkInput.current.value;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", title);
    formData.append("link", link);
    formData.append("description", description);

    dispatch(fileActions.updateFile(projectId, leadId, data.id, formData));
    handleClose();
  };

  const { isTitleError, isDescriptionError, isLinkError, isFileError } = state;

  return (
    <React.Fragment>
      <div
        className={`drawer-overlay ${isOpen ? "active" : ""}`}
        onClick={handleClose}
      ></div>
      <div className={`side-drawer-main ${isOpen ? "active" : ""}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <h3>{String(mode || "").toUpperCase()} FILE</h3>
            <span
              className="side-drawer-close"
              onClick={handleClose}
            >
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="control-tabs-box">
                <div
                  className={state.toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"}
                  onClick={() => setState({ ...state, toggleTabState: 1 })}
                >
                  <p>File Information</p>
                </div>
              </div>
              <div className="content-tabs-box">
                <div className={state.toggleTabState === 1 ? "tabs-content active" : "tabs-content"}>
                  <div className="forms-tab">
                    <MDBRow>
                      <MDBCol className="col-12 form-group-box">
                        <div className="input-group-box full-box-">
                          <div className={`field-box ${isTitleError ? "error-field" : ""}`}>
                            <label htmlFor="title">
                              Title <small>*</small>
                            </label>
                            <input
                              ref={titleInput}
                              type="text"
                              id="title"
                              name="title"
                              defaultValue={mode === "edit" ? data?.name : ""}
                              onChange={setTitle}
                            />
                            {isTitleError && <p className="error-field">This field is required</p>}
                          </div>
                          <div className={`field-box ${isDescriptionError ? "error-field" : ""}`}>
                            <label htmlFor="description">
                              Description <small>*</small>
                            </label>
                            <input
                              ref={descriptionInput}
                              type="text"
                              id="description"
                              name="description"
                              defaultValue={mode === "edit" ? data?.description : ""}
                              onChange={setDescription}
                            />
                            {isDescriptionError && <p className="error-field">This field is required</p>}
                          </div>
                          {mode === "edit" && (
                            <div className={`field-box ${isLinkError ? "error-field" : ""}`}>
                              <label htmlFor="link">
                                Document Link <small>*</small>
                              </label>
                              <input
                                ref={linkInput}
                                type="text"
                                readOnly
                                id="link"
                                name="link"
                                defaultValue={mode === "edit" ? data?.link : ""}
                              />
                              {isLinkError && <p className="error-field">This field is required</p>}
                            </div>
                          )}
                          <div className={`field-box ${isFileError ? "error-field" : ""}`}>
                            <label htmlFor="link">File <small>*</small></label>
                            <div className="custom-input">
                              <input
                                ref={fileInput}
                                type="file"
                                id="link"
                                name="link"
                                onChange={handleFileChange}
                              />
                            </div>
                            {isFileError && <p className="error-field">Please select a file</p>}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
              <div className="tab-form-btn-group">
                <MDBRow>
                  <MDBCol className="text-right">
                    <span className="drawer-btn cancel-btn" onClick={handleClose}>
                      Cancel
                    </span>
                    <span className="drawer-btn save-btn" onClick={handleManageFile}>
                      {mode === "add" ? "Save Record" : "Update Record"}
                    </span>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeadFileManageModal;
