import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { MDBRow } from "mdbreact";

import EmailTemplateControls from "./components/emailtemplate/EmailTemplateControls";
import OwnEmailTemplateTable from "./components/emailtemplate/OwnEmailTemplateTable";
import SharedEmailTemplateTable from "./components/emailtemplate/SharedEmailTemplateTable";

import CreateEmailTemplateDrawer from "./components/emailtemplate/CreateEmailTemplateDrawer";

import DuplicateEmailModal from "./components/emailtemplate/DuplicateEmailModal";
import ShareEmailModal from "./components/emailtemplate/ShareEmailModal";
import DeleteEmailModal from "./components/emailtemplate/DeleteEmailModal";
import { useDispatch } from "react-redux";
import { set } from "firebase/database";
import { templateActions } from "_actions";
import ViewEmailTemplateDrawer from "./components/emailtemplate/ViewEmailTemplateDrawer";

const ManageEmailTemplates = () => {
  const [toggleEmailTemplateType, setToggleEmailTemplateType] = useState(1);
  const [isCreateTemplateDrawer, setIsCreateTemplateDrawer] = useState(false);
  const [isViewTemplateDrawer, setIsViewTemplateDrawer] = useState(false);

  const [isDuplicateModal, setIsDuplicateModal] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [isTemplateEmpty, setIsTemplateEmpty] = useState(true);
  const [template, setTemplate] = useState(undefined);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const dispatch = useDispatch();
  const param = useParams();

  const toggleTabEmailType = (index) => {
    setToggleEmailTemplateType(index);
  };

  const openCreateTemplateDrawer = (template) => {
   // console.log("template edit:", template);
    setTemplate(template);
    setIsCreateTemplateDrawer(true);
  };

  const openViewTemplateDrawer = (template) => {
   // console.log("template view:", template);
    setTemplate(template);
    setIsViewTemplateDrawer(true);
  };

  const closeCreateTemplateDrawer = () => {
    setIsCreateTemplateDrawer(false);
  };

  const closeViewTemplateDrawer = () => {
    setIsViewTemplateDrawer(false);
  };

  const toggleEmptySample = () => {
    setIsTemplateEmpty(false);
  };

  const toggleDuplicateModal = (template) => {
    setTemplate(template);
    setIsDuplicateModal(!isDuplicateModal);
  };
  const toggleShareModal = (template) => {
    setTemplate(template);
    setIsShareModal(!isShareModal);
  };
  const toggleDeleteModal = (template) => {
    setTemplate(template);
    setIsDeleteModal(!isDeleteModal);
  };

  const handleDeleteEmailTemplate = (isDelete) => {
   // console.log("delete template: ", isDelete);
   // console.log("delete template: ", template);

    if (isDelete && template != undefined && selectedTemplates.length == 0) {
      setIsCreateTemplateDrawer(false);
      dispatch(templateActions.removeTemplate(param.id, template.id));
      setTemplate(undefined);
    }

    if (isDelete && selectedTemplates.length > 0) {
     // console.log("templates to delete:", selectedTemplates);
      const request = { templates: selectedTemplates };
      dispatch(templateActions.removeTemplates(param.id, request));
    }
    setSelectedTemplates([]);
    setIsDeleteModal(!isDeleteModal);
  };

  const handleShareEmailTemplate = (isShare) => {
    if (isShare) {
      setIsCreateTemplateDrawer(false);
      if (template.private) {
        dispatch(templateActions.shareTemplate(param.id, template.id));
        setTemplate(undefined);
      }
      if (!template.private) {
        dispatch(templateActions.unshareTemplate(param.id, template.id));
        setTemplate(undefined);
      }
    }
    setIsShareModal(!isShareModal);
  };

  const handleDuplicateEmailTemplate = (isDuplicate, duplicateTemplate) => {
   // console.log("duplicate request: ", duplicateTemplate);
    if (isDuplicate && duplicateTemplate != undefined) {
      setIsCreateTemplateDrawer(false);
      setIsViewTemplateDrawer(false);
      dispatch(
        templateActions.duplicateTemplate(
          param.id,
          duplicateTemplate.id,
          duplicateTemplate
        )
      );
      setTemplate(undefined);
    }
    setToggleEmailTemplateType(1);
    setIsDuplicateModal(!isDuplicateModal);
  };

  const handleBatchDelete = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  const handleSelectTemplates = (checkedTemplates) => {
   // console.log("size: ", checkedTemplates.length);
    setSelectedTemplates(checkedTemplates);
  };

  const handleSearchTemplate = (search) => {
    if (toggleEmailTemplateType == 1) {
     // console.log("type 1: ", search);
      dispatch(templateActions.getTemplates(param.id, search, 1, 15));
    }

    if (toggleEmailTemplateType == 2) {
     // console.log("type 2: ", search);
      dispatch(
        templateActions.getSharedTemplatePaginated(param.id, search, 1, 15)
      );
    }
  };

  return (
    <>
      <div className="topbar-menu">
        <MDBRow className="row align-items-center">
          <div className="col">
            <div className="top-project-info-box">
              <div className="project-name">
                <h1>Manage Email Templates</h1>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="top-button-box">
              <button className="hidden-btn"></button>
            </div>
          </div>
        </MDBRow>
      </div>
      <div className="overview-menu overview-menu-w-breadcrumb">
        <div className="breadcrumb-overview">
          <div className="breadcrumbs">
            <Link className="back" to={`/project/${param.id}/leads`}>
              <span className="icon-s" >
                <i className="icon-lms icon-arrow-left"></i>
              </span>
            </Link>
            <Link className="link" to={`/project/${param.id}/leads`}>
              Propelrr Services<i>/</i>
            </Link>
            <span>Email Templates</span>
          </div>
        </div>
        <EmailTemplateControls
          templateType={toggleEmailTemplateType}
          onSearchTerm={handleSearchTemplate}
          toggleEmailTemplateType={toggleEmailTemplateType}
        />
      </div>
      <div className="manage-template-controls">
        <div className="controls-btn">
          <button
            className={
              toggleEmailTemplateType === 1 ? "c-btn" : "c-btn c-btn-light"
            }
            onClick={() => toggleTabEmailType(1)}
          >
            Own Email Template
          </button>

          <button
            className={
              toggleEmailTemplateType === 2 ? "c-btn" : "c-btn-light c-btn"
            }
            onClick={() => toggleTabEmailType(2)}
          >
            Shared Email Template
          </button>

          {toggleEmailTemplateType == 1 && selectedTemplates.length > 0 && (
            <button className="c-btn" onClick={() => handleBatchDelete()}>
              <i className="icon-lms icon-delete"></i> Batch Delete
            </button>
          )}

          <button className="c-btn" onClick={() => openCreateTemplateDrawer()}>
            <i className="icon-lms icon-add"></i> Create Email Template
          </button>
        </div>
      </div>
      {toggleEmailTemplateType === 1 && (
        <OwnEmailTemplateTable
          openCreateTemplateDrawer={openCreateTemplateDrawer}
          toggleDuplicateModal={toggleDuplicateModal}
          toggleShareModal={toggleShareModal}
          toggleDeleteModal={toggleDeleteModal}
          handleSelectTemplates={handleSelectTemplates}
        />
      )}
      {toggleEmailTemplateType === 2 && (
        <SharedEmailTemplateTable
          toggleDuplicateModal={toggleDuplicateModal}
          openViewTemplateDrawer={openViewTemplateDrawer}
        />
      )}

      <ViewEmailTemplateDrawer
        isViewTemplateDrawer={isViewTemplateDrawer}
        closeViewTemplateDrawer={() => closeViewTemplateDrawer()}
        template={template}
        toggleDuplicateModal={toggleDuplicateModal}
      />

      <CreateEmailTemplateDrawer
        isCreateTemplateDrawer={isCreateTemplateDrawer}
        closeCreateTemplateDrawer={() => closeCreateTemplateDrawer()}
        template={template}
        toggleDuplicateModal={toggleDuplicateModal}
        toggleShareModal={toggleShareModal}
        toggleDeleteModal={toggleDeleteModal}
      />

      <DuplicateEmailModal
        isDuplicateModal={isDuplicateModal}
        toggleDuplicateModal={handleDuplicateEmailTemplate}
        template={template}
      />
      <ShareEmailModal
        isShareModal={isShareModal}
        toggleShareModal={handleShareEmailTemplate}
        template={template}
      />
      <DeleteEmailModal
        isDeleteModal={isDeleteModal}
        toggleDeleteModal={handleDeleteEmailTemplate}
      />
    </>
  );
};

export default ManageEmailTemplates;
