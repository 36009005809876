import { utmConstants } from "_constants";

/**
 * Reducer function to handle UTM-related actions.
 *
 * @param {Object} state - The current state of the UTM reducer.
 * @param {Object} action - The action dispatched to the reducer.
 * @param {string} action.type - The type of action being dispatched.
 * @param {Object} [action.data] - The data payload for the action.
 * @param {Object} [action.res] - The response payload for the action.
 * @param {Object} [action.codex] - The codex payload for the action.
 * @param {Object} [action.presets] - The presets payload for the action.
 * @param {string} [action.error] - The error message for the action.
 *
 * @returns {Object} The new state of the UTM reducer.
 */
export function utm(state = {}, action) {

  switch (action.type) {
    case utmConstants.GET_UTMS_REQUEST:
      return {
        ...state,
        getUtmLoading: true,
        getUtmLoaded: false,
      };
    case utmConstants.GET_UTMS_SUCCESS:
      return {
        ...state,
        getUtmLoading: false,
        getUtmLoaded: true,
        utms: action.data.data,
      };
    case utmConstants.GET_UTMS_FAILURE:
      return {
        ...state,
        getUtmLoading: false,
        getUtmLoaded: true,
        error: action.error,
      };

    case utmConstants.GENERATE_UTM_REQUEST:
      return {
        ...state,
        generateUtmLoading: true,
        generateUtmLoaded: false,
      };
    case utmConstants.GENERATE_UTM_SUCCESS:
      return {
        ...state,
        generateUtmLoading: false,
        generateUtmLoaded: true,
        utm: action.res.data,
      };
    case utmConstants.GENERATE_UTM_FAILURE:
      return {
        ...state,
        generateUtmLoading: false,
        generateUtmLoaded: true,
        error: action.error,
      };

    case utmConstants.GET_UTM_CODEX_REQUEST:
      return {
        ...state,
        getCodexLoading: true,
        getCodexLoaded: false,
      };
    case utmConstants.GET_UTM_CODEX_SUCCESS:
      return {
        ...state,
        getCodexLoading: false,
        getCodexLoaded: true,
        codex: action.codex.data,
      };
    case utmConstants.GET_UTM_CODEX_FAILURE:
      return {
        ...state,
        getCodexLoading: false,
        getCodexLoaded: true,
        error: action.error,
      };


    case utmConstants.GET_PROJECT_PRESETS_REQUEST:
      return {
        ...state,
        getPresetLoading: true,
        getPresetLoaded: false,
      };
    case utmConstants.GET_PROJECT_PRESETS_SUCCESS:
      return {
        ...state,
        getPresetLoading: false,
        getPresetLoaded: true,
        presets: action.presets.data
      };
    case utmConstants.GET_PROJECT_PRESETS_FAILURE:
      return {
        ...state,
        getPresetLoading: false,
        getPresetLoaded: true,
        error: action.error,
      };

    default:
      return {
        ...state
      }
  }

}
