import {leadConstants} from "_constants";

/**
 * Reducer function to handle report-related actions.
 *
 * @param {Object} state - The current state of the reports.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.report] - The report data (if applicable).
 * @param {string} [action.error] - The error message (if applicable).
 * @returns {Object} The new state of the reports.
 */
export function reports(state = {}, action) {
    switch (action.type) {

        case leadConstants.GET_LEAD_REPORT_REQUEST:
            return {
                ...state,
                loadingReport: true
            };
        case leadConstants.GET_LEAD_REPORT_SUCCESS:
            return {
                ...state,
                loadingReport: false,
                report: action.report,
                reportLoaded: true
            };
        case leadConstants.GET_LEAD_REPORT_FAILURE:
            return {
                error: action.error
            };


        case leadConstants.GET_PATIENT_REPORT_REQUEST:
            return {
                ...state,
                loadingReport: true
            };
        case leadConstants.GET_PATIENT_REPORT_SUCCESS:
            return {
                ...state,
                loadingReport: false,
                report: action.report,
                reportLoaded: true
            };
        case leadConstants.GET_PATIENT_REPORT_FAILURE:
            return {
                error: action.error
            };

        default:
            return state

    }
}