import React from 'react';
import { MDBIcon } from 'mdbreact';
import { Link } from 'react-router-dom';

const Slider = ({ activeFormView, stageNum, isVerified }) => {
  // Data structure for the navigation steps
  const uploadCsvSideNavData = [
    {
      title: 'Upload CSV',
      id: 'uploadCsv',
      path: 'import'
    },
    {
      title: 'Map Fields',
      id: 'mapFields',
      path: 'import/map'
    },
    {
      title: 'Results',
      id: 'importResults',
      path: 'import/results'
    }
  ];

  const renderSliderIndicator = (data, index, hasLine) => {
    const { title, id, path } = data;
    const isActiveFormView = id === activeFormView;
    let icon = '';
    let iconClass = '';
    let titleClass = '';
    let linkClass = '';

    if (isVerified) {
      // If the step is verified, mark it as complete
      titleClass = 'mt-2 mb-0 title-view--complete';
      icon = 'check';
      iconClass = 'icon-view--check';
    } else {
      if (index + 1 > stageNum) {
        // Steps beyond the current stage are disabled
        titleClass = 'mt-2 mb-0 title-view--default';
        linkClass = 'disabled';
        icon = 'blank';
        iconClass = 'icon-view--blank';
      } else if (index + 1 < stageNum) {
        // Steps before the current stage are marked as completed
        titleClass = 'mt-2 mb-0 title-view--complete';
        icon = 'check';
        iconClass = 'icon-view--check';
      } else if (isActiveFormView) {
        // The current step is marked as active
        titleClass = 'mt-2 mb-0 title-view--active';
        linkClass = 'disabled';
        icon = 'cogs';
        iconClass = 'icon-view--cogs color-orange settings-icon';
      }
    }

    return (
      <div className='row float-right height-41 width--full' key={index}>
        <div className='row mr-2'>
          <Link className={linkClass} to={path}>
            <p className={titleClass}>{title}</p>
          </Link>
        </div>
        <div className='icon-view__container'>
          <div className='mx-2 round mb-0'>
            <p className={iconClass}>
              <MDBIcon icon={icon} className='icon-view' />
            </p>
          </div>
          {hasLine && <p className='mb-0 line' />} {/* Displays a connecting line if not the last item */}
        </div>
      </div>
    );
  };

  return (
    <div className='width-145 m--auto'>
      {/* Map through each step and render its indicator */}
      {uploadCsvSideNavData.map((data, index) =>
        renderSliderIndicator(data, index, index < uploadCsvSideNavData.length - 1)
      )}
    </div>
  );
};

export default Slider;
