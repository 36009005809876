export let sampleDataGroup = [
    {
        id: "1",
        groups: "Xmas Campaign",
        dates: "10/22/2022 - 11/30/2022",
        labels: [
            "Blog", 
            "Email", 
            "Urgent",
            "Phone",
            "Others"
        ],
        items: [
            {
                destination: {
                    fullUrl: "http://www.propelrr.com/services",
                    shortUrl: "https://lst.io/wccvm",
                    date: "2022-10-22"
                },
                group: "Xmas Campaign",
                label: [
                    "Blog", 
                    "Email", 
                    "Urgent",
                    "Phone",
                    "Others"
                ],
                campaign: "Sale",
                medium: "Email",
                source: "Direct",
                content: "Content",
                term: "Term",
                notes: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laudantium, illum."
            },
        ],
        subRows: [
        ]
    },
    {
        id: "2",
        groups: "Something",
        dates: "10/22/2022 - 11/30/2022",
        labels: [
            "Blog", 
            "Email", 
            "Urgent"
        ],
        items: [
            {
                destination: {
                    fullUrl: "http://www.propelrr.com/services",
                    shortUrl: "https://lst.io/wccvm",
                    date: "2022-10-22"
                },
                group: "Something",
                label: [
                    "Blog", 
                    "Email", 
                    "Urgent"
                ],
                campaign: "Sale",
                medium: "Email",
                source: "Direct",
                content: "Content",
                term: "Term",
                notes: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laudantium, illum."
            },
        ],
        subRows: [
        ]
    },
]