import { httpManagerService as httpManager } from  '_services';

export const calendlyService = {
    getCalendly,
    createCalendly,
    updateCalendly,
    removeCalendly,
}

function getCalendly(projectId, memberId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/member/${memberId}`);
}

/**
 * Fetches Calendly data for a specific project and member.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} memberId - The ID of the member.
 * @returns {Promise<Object>} A promise that resolves to the Calendly data.
 */
function createCalendly(projectId, memberId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/member/${memberId}`, request);
}

/**
 * Updates the Calendly information for a specific project.
 *
 * @param {string} projectId - The ID of the project to update.
 * @param {string} calendlyId - The ID of the Calendly to update.
 * @param {Object} request - The request payload containing the updated Calendly information.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function updateCalendly(projectId, calendlyId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/calendly/${calendlyId}`, request);
}

/**
 * Removes a Calendly integration from a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} calendlyId - The ID of the Calendly integration to be removed.
 * @returns {Promise} A promise that resolves when the Calendly integration is removed.
 */
function removeCalendly(projectId, calendlyId){
    return httpManager.deleteJsonWithRequestingToken(`/api/v2/project/${projectId}/calendly/${calendlyId}`);
}

