/**
 * Converts a 24-hour time string to a 12-hour time string with AM/PM.
 *
 * @param {string} value - The 24-hour time string to convert.
 * @returns {string} The converted 12-hour time string with AM/PM.
 */
export const handleTimeConvert = (value) => {
    let time = value
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value];

    if (time.length > 1) {
        time = time.slice(1); // Remove full string match value

        time[5] = time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12; // Adjust hours

        return time.join("");
    } else if (time[0]) {
        return time + " AM";
    } else {
        return time;
    }
};