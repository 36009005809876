import React, {useEffect, useState} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBBtn } from 'mdbreact'
import {utilitiesService} from "_services";

const DeleteMemberModal = ({ open, deleteData, onHandleCloseDeleteModal, onHandleDeleteMemberConfirm }) => {


    const [data,setData] = useState(deleteData);
    useEffect(() => { setData(deleteData) }, [deleteData])

    let fname = '';
    let lname = '';
    let email = '';

    if(data) {
         fname = utilitiesService.decrypt(data.first_name);
         lname = utilitiesService.decrypt(data.last_name);
         email = utilitiesService.decrypt(data.email);
    }


  return (
    <>
      <div className={open ? 'delete-side-modal-overlay active' : 'delete-side-modal-overlay'} onClick={onHandleCloseDeleteModal}></div>
      <div className={open ? 'delete-side-modal active' : 'delete-side-modal'}>
        <div className='delete-side-modal-box'>
            <div className='box-content'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                <h3>Confirm Deletion</h3>
                <p>Are you sure you want to delete
                    <span></span><strong>{fname} {lname}</strong> (<i>{email}</i>) ?</p>
            </div>
            <div className='modal-btns box-content'>
                <button className='c-btn c-btn-light' onClick={onHandleCloseDeleteModal}>
                  Cancel
                </button>
                <button className='c-btn c-btn-red' onClick={()=> onHandleDeleteMemberConfirm(data)}>
                  Delete
                </button>
            </div>
        </div>
      </div>
    </>
  )
}

export default DeleteMemberModal