import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { influencerActions } from "_actions/influencer.actions";
import { KpiControlAdvanceFilter } from "_pages/Influencer/components/Kpi/KpiControlAdvanceFilter";
import TableControlLoader from '../../../../_components/Loader/TableControlLoader';
import { DateRangePicker } from "rsuite";
import { ranges } from '../../constants/DateRangeConfig';
import Moment from "react-moment";

export const KpiControl = ({
  projectId,
  dates,
  onHandleKpiHeaderHeightChanged,
  onHandlePageChanged,
  onHandleSizeChanged,
  onHandleSearch,
  onHandleDateChanged,
  onApplyFilter,
  resetFilterState,
  onHandleResetFilter,
}) => {
  const dispatch = useDispatch();
  const { influencerListLoaded } = useSelector((state) => state.influencers);
  const { number, first, last, size, totalElements } = useSelector((state) => ({
    number: state.influencers.list?.data?.number || 0,
    first: state.influencers.list?.data?.first || false,
    last: state.influencers.list?.data?.last || false,
    size: state.influencers.list?.data?.size || 10,
    totalElements: state.influencers.list?.data?.totalElements || 0,
  }));

  const [state, setState] = useState({
    searchToggled: false,
    filteredToggled: false,
    toggleKpi: true,
    selectedDates: dates,
    currentPage: number + 1,
    currentSize: size,
    currentQuery: "",
  });

  const searchRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleSearch();
      }
      if (e.key === "Escape") {
        handleClearSearch();
      }
    };

    document.addEventListener("keypress", handleKeyPress, true);
    return () => {
      document.removeEventListener("keypress", handleKeyPress, true);
    };
  }, []);

  useEffect(() => {
    setState((prev) => ({ ...prev, selectedDates: dates }));
  }, [dates]);

  const handleToggleKpi = () => {
    setState((prev) => ({ ...prev, toggleKpi: !prev.toggleKpi }));
    onHandleKpiHeaderHeightChanged(!state.toggleKpi);
  };

  const handlePageChanged = (value, className) => {
    if (className === "active") {
      onHandlePageChanged(value);
    }
  };

  const handleSizeChanged = (e) => {
    const size = parseInt(e.target.value);
    onHandleSizeChanged(size);
  };

  const handleOpenFilter = () => {
    setState((prev) => ({ ...prev, filteredToggled: true }));
  };

  const handleCloseFilter = () => {
    setState((prev) => ({ ...prev, filteredToggled: false }));
  };

  const handleOpenSearch = () => {
    setState((prev) => ({ ...prev, searchToggled: true }));
  };

  const handleSearch = () => {
    if (searchInputRef.current.value !== "") {
      onHandleSearch(searchInputRef.current.value);
    }
  };

  const handleClearSearch = () => {
    searchInputRef.current.value = "";
    onHandleSearch(null);
    setState((prev) => ({ ...prev, searchToggled: false }));
  };

  const handleDateRangeChange = (value) => {
    setState((prev) => ({ ...prev, selectedDates: value }));
    onHandleDateChanged(value);
  };

  const page = number + 1;
  const min = page * size - (size - 1);
  const max = page * size;
  const count = totalElements;
  const prevClass = first ? "" : "active";
  const nextClass = last ? "" : "active";
  const nextPage = page + 1;
  const previousPage = page - 1;
  const defaultPage = parseInt(state.currentPage);
  const activeSize = size || state.currentSize;

  let countText = "Loading Influencers ...";
  if (page) {
    const limit = count < max ? count : max;
    countText = `${min}-${limit} of ${count}`;
  }

  return (
    <div className="overview-control-box">
      <div className="col-left">
        <div className="button-group">
          <KpiControlAdvanceFilter
            projectId={projectId}
            onApplyFilter={onApplyFilter}
            resetFilterState={resetFilterState}
            onHandleResetFilter={onHandleResetFilter}
          />

          <div className="date-range-picker-box">
            <DateRangePicker
              value={state.selectedDates}
              ranges={ranges}
              appearance="subtle"
              format="MMM-dd-yyyy"
              size="lg"
              placeholder="All Time"
              placement="autoVerticalStart"
              cleanable={true}
              onChange={handleDateRangeChange}
            />
          </div>

          <div
            className={state.searchToggled ? "search-box active" : "search-box"}
            ref={searchRef}
          >
            <div className="input-search">
              <i className="icon-lms icon-search"></i>
              <input
                key={state.currentQuery}
                defaultValue={state.currentQuery}
                type="text"
                placeholder="Search Lead"
                ref={searchInputRef}
                onClick={handleOpenSearch}
              />
              <div className="controls">
                <i
                  className="icon-lms icon-close"
                  onClick={handleClearSearch}
                ></i>
                <i
                  className="icon-lms icon-arrow-right"
                  onClick={handleSearch}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-right">
        <div className="button-toggle">
          <p>Data Overview</p>
          <div
            className={state.toggleKpi ? "switch-tggle active" : "switch-tggle"}
            onClick={handleToggleKpi}
          >
            <span className="slider"></span>
            <span className="tggle-txt"></span>
          </div>
        </div>
      </div>
    </div>
  );
};