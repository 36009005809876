import React from 'react';
import Moment from 'react-moment';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { projectActions } from '_actions';
import { utilitiesService as utility } from '_services';

import {Button, ButtonGroup, Icon} from "blackbox-react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdbreact';
import InputForm from '_components/InputForm'

import DeleteConfirmationModal from "_components/Modal/DeleteConfirmationModal";
import { FormValidation } from '_components/FormValidation';

import avatarFemale from '_assets/images/avatar-fem.png';
import avatar from '_assets/images/avatar-male.png';
/**
 * @deprecated This component is no longer used.
 */
class ProjectSettings extends React.Component {
  constructor(props){
    super(props);

    const project = this.props.getProject(this.props.match.params.id);
    const projectMembers = this.props.getAllProjectMembers(this.props.match.params.id);

    this.state = {
      projectDetails: [],
      formErrors: {
        name: ''
      },
      projectNameValid: true,
      formValid: true,
      submitted: false,
      isOpen: false,
      selectedOption: '',
      selectedMember: '',
      selectedOwner: '',
      member: {
        email: ''
      },
      memberToDelete: '',
      projectUpdated: project,
      updatedProject: false,
      updateProjectChange: false,
      isViewStatusVisible: 1,
      projectMembers: projectMembers,
      project: {},
      statusToDelete: {},
      projectModalOpen: false,
      memberModalOpen: false
    };

    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleAddMember = this.handleAddMember.bind(this);
    this.handleDeleteMember = this.handleDeleteMember.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('isActiveModule');
    localStorage.setItem('isActiveModule', 'projects');
  };

  handleGetProject = () => {
    const project = this.props.getProject(this.props.match.params.id);

    if(typeof project != 'undefined'){
      this.setState({
        projectUpdated: project
      });
    }
  };

  handleGetProjectMembers = () => {
    const projectMembers = this.props.getAllProjectMembers(this.props.match.params.id);

    if(typeof projectMembers != 'undefined'){
      this.setState({
        projectMembers: projectMembers
      });
    }
  };


  handleToggleProject = () =>{
    let { projectModalOpen } = this.state;
    this.setState({projectModalOpen: !projectModalOpen})
  };

  handleToggleMember = () =>{
    let { memberModalOpen } = this.state;
    this.setState({memberModalOpen: !memberModalOpen})
  };

  approveModal = (statusToDelete) => {
    let project = this.props.projects.project.data || {};
    let projectMembers = this.props.projects.members ? this.props.projects.members.data : {};

    //console.log('project', project);
    //console.log('projectMembers', projectMembers);

    if (statusToDelete === project) {
      const id = statusToDelete.id;
      const name = statusToDelete.info.name;

      if (this.props.projects.project && this.props.projects.project.data.id && id && name) {
        const projectId = this.props.projects.project.data.id;
        //console.log("id", id);
        //console.log("statusToDelete", statusToDelete);
        //console.log("projectId", projectId);

        const deleteProjectData = {
          name: statusToDelete.info.name,
          description: statusToDelete.info.description
        };

        this.props.deleteProject(deleteProjectData, projectId);
      }
    }
    if (Object.keys(projectMembers).length){
      projectMembers.map((value, index) => {
        if (statusToDelete === value) {
          const id = value.id;
          const email = value.account.email;

          if (this.props.projects.project && this.props.projects.project.data.id && id && email) {
            const projectId = this.props.projects.project.data.id;

            const deleteProjectMemberData = {
              'email': value.account.email
            };

            this.props.deleteProjectMember(deleteProjectMemberData, projectId, id);
            this.setState({
              statusToDelete: {}
            }, () => {
              this.handleGetProject()
            });
          }
        }
        return null;
      });
    }

  };

  handleMemberChange(event) {
    let { name, value } = event.target;
    const { member } = this.state;

    value = utility.encrypt(value);

    this.setState({
      member: {
        ...member,
        [name]: value
      }
    });
  };

  handleProjectChange(event) {
    let { name, value } = event.target;
    const { projectUpdated } = this.state;

    this.setState({
      projectUpdated: {
        ...projectUpdated,
        [name]: value
      },
      updateProjectChange: true
    }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let projectNameValid = this.state.projectNameValid;

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch(fieldName) {
      case 'name':
        if (value === "" || isAllWhitespace) { // check if empty
          projectNameValid = false;
          fieldValidationErrors.name = 'Please enter a project name.';
        } else {
          projectNameValid = (value.length >= 0); // check if valid
          fieldValidationErrors.name = projectNameValid ? '': 'Please enter a project name.';
        }
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      projectNameValid: projectNameValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.projectNameValid
    });
  }


  handleUpdateProject = (event) => {
    event.preventDefault();

    this.validateForm();

    this.setState({ updatedProject: true });
    const { projectUpdated } = this.state;

    this.setState({submitted: true}, () => {
      if (this.state.formValid){
        if(this.state.submitted){
          if (projectUpdated !== undefined) {
            this.props.updateProject(projectUpdated, this.props.match.params.id);
            this.setState({
              updatedProject: false,
              projectNameValid: false,
              formValid: false,
              submitted: false,
              formErrors: {
                name: 'Please enter a project name.'
              },
            });
          }
        }
      }
    });

  };

  errorClass(error) {
    return(error.length === 0 ? 'is-passed' : 'is-failed');
  }

  renderProjectSettings = () => {
    let hasData = false;
    let project = this.props.projects.project.data;
    let projectUpdated = this.state;
    const { submitted, formValid } = this.state;
    const { projectLoaded } = this.props;

    if(typeof this.props.projects.project.data !== 'undefined'){
      hasData = true;
    }

    return (
      <form onSubmit={this.setFormName} noValidate>
        <MDBRow>
            { projectLoaded && hasData ?
              <MDBCol>
                <h3><MDBIcon icon='cog' className='mr-2' /> Project Settings</h3>
                <MDBCol md='12' className='px-0'>
                  <div className='d-flex'>
                    <MDBCol className={`form-group p--0 ${submitted && this.errorClass(this.state.formErrors.name)}`}>
                      <label className='mb-1 font-size-11 font-weight-600'>Project Name <span className='color-red'>*</span></label>
                      <input
                        onChange={this.handleProjectChange}
                        type='text'
                        name='name'
                        placeholder='Enter project name'
                        defaultValue={project.info.name}
                        value={projectUpdated.name}
                      />
                      <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                        <FormValidation fieldName='name' formErrors={this.state.formErrors}/>
                      </div>
                    </MDBCol>
                  </div>
                </MDBCol>
                <br/>
                <MDBCol md='12' className='px-0'>
                  <div className='d-flex'>
                    <MDBCol md='12' className='px-0'>
                      <label className='mb-1 font-size-11 font-weight-600'>Description</label>
                      <textarea
                        onChange={this.handleProjectChange}
                        id='textarea-description'
                        name='description'
                        placeholder='Enter project description'
                        className='form-control'
                        defaultValue={project.info.description}
                        value={projectUpdated.description}
                        rows='5'
                      />
                    </MDBCol>
                  </div>
                </MDBCol>
              </MDBCol>
              :
              <div className='text-center position-center'>
                <div className="loader">Loading...</div>
              </div>
            }
        </MDBRow>
        <MDBRow>
          <MDBCol className='m-t--md'>
            <MDBRow className='mx-1'>
              <MDBCol lg='5' xl='5' className='text-left m-l--n20'>
                <Button
                  flat='true'
                  type="submit"
                  background='light'
                  foreground='red'
                  size={-2}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleToggleProject();
                    this.handlePassData(project)
                  }}>
                    <MDBIcon icon='trash-alt' className='mr-2' />
                    <u>Delete Project</u>
                </Button>
                { this.state.projectModalOpen &&
                  <DeleteConfirmationModal
                    toggle={this.handleToggleProject}
                    modalOpen={this.state.projectModalOpen}
                    statusToDelete={this.state.statusToDelete}
                    onApprove={this.approveModal}
                    target={project.info.name}
                    nameToDelete={project.info.name}
                    deleteNum='single'
                  />
                }
              </MDBCol>
              <MDBCol className='px-0 pr-3'>
                <MDBRow className='float-right'>
                  <p className='font-size-12 color-gray mb-0 mt-2 mr-3'>
                    <Link to={`/project/${project.id}`}>
                    <u className='color-black opacity-70'>Cancel</u>
                  </Link></p>
                   <Button
                    flat='true'
                    type='submit'
                    size={-2}
                    onClick={(e) => {
                      this.handleUpdateProject(e)
                    }}
                  >
                    SAVE CHANGES
                   </Button>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </form>
    );
  };

  handlePassData = (data) => {
    this.setState({
      statusToDelete: data,
      memberToDelete: data
    })

  };

  setFormName = () => {
    let data = [this.state.formName, this.state.description];
    localStorage.setItem('formName', JSON.stringify(data));
    localStorage.removeItem('form')
  };

  renderManageMembers = () => {
    const member = this.state.member;
    return (
      <div>
        <h3><MDBIcon icon='users' className='mr-2' /> List of Members</h3>
        <MDBCol md='12' className='px-0'>
          <MDBCol md='12' className='px-0 py-2'>
            <MDBContainer className='border-radius-5 box-shadow container px-3 py-2'>
              <MDBRow>
                <MDBCol className='py-2'>
                  {this.renderProjectMembers()}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBCol>
      </div>
    );
  };

  handleSelectedChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleSelectedMember = selectedMember => {
    this.setState({ selectedMember });
  };

  handleSelectedOwner = selectedOwner => {
    this.setState({ selectedOwner });
  };

  handleViewAllStatus = () => {
    let { isViewStatusVisible } = this.state
    this.setState({ isViewStatusVisible: isViewStatusVisible === 1 ? 0 : 1 })
  };

  handleMapInput(inputFields, column) {
    return (
      inputFields.map((value) => {
        let col = 12 / column
        return (
          <MDBCol xl={col} lg={col} md={col} className='px-1 mb-2' key={value.label}>
            <InputForm
              hint={value.label}
              onChange={this.handleMemberChange.bind(this)}
              type={value.type}
              name={value.label}
            />
          </MDBCol>
        )
      })
    )
  };

  renderProjectMembers = () => {
    let project;
    let projectMembers;
    let hasData = false;

    if(this.props.projects.project !== undefined)  {
      project = this.props.projects.project.data;
    }

    if(this.props.projects.members !== undefined)  {
      projectMembers = this.props.projects.members.data;
      hasData = true;
    }


    return (
      <div className='project-members'>
        <form onSubmit={this.setFormName} noValidate>
        <table className='bottomBorder w-100 px-2'>
          <tbody>
          { hasData ?
            projectMembers.map((value, index) => {
              return (
                  <tr key={index}>
                    <td>
                      <p className='mb-0 font-size-11 font-weight-600'>
                        <img src={avatar} alt="" className='m-r--sm' width='20' height='20'/>
                      </p>
                    </td>
                    <td className=''>
                      <p className='mb-0 font-size-11 font-weight-600'>
                        {utility.decrypt(value.account.first_name)} &nbsp;
                        {utility.decrypt(value.account.last_name)}
                      </p>
                      <p className='mb-0 font-size-11'>
                        {utility.decrypt(value.account.email)}
                      </p>
                    </td>
                    <td className=''>
                      <p className='mb-0 font-italic font-size-10'>
                        Added on <Moment date={value.date_added} format="MM/DD/YYYY" />
                      </p>
                    </td>
                    <td className=''>
                    </td>
                  </tr>
              )
            }) : null
          }
          </tbody>
        </table>
        </form>

      </div>
    )
  };

  handleAddMember(event) {
    event.preventDefault();

    this.setState({ submitted: true });

    const { member } = this.state;

    if (member.email) {
      this.props.addProjectMember(member, this.props.match.params.id);
      this.setState({
        submitted: false,
        member: {
          email: ''
        }
      }, () => {
        this.handleGetProjectMembers();
      });
    }
  };

  handleDeleteMember(email) {
    this.setState({ submitted: true });

    let { projectMembers } = this.state;

    if(this.props.projects.members !== undefined)  {
      projectMembers = this.props.projects.members.data;
    }

    let matchedMember = {};
    let project_id = 0;
    let member_id = 0;

    if (projectMembers) {
      projectMembers.map((value, index) => {
        if(email === value.account.email){
          matchedMember = {
            'email': email
          };
          project_id = this.props.match.params.id;
          member_id = value.member_id;
        }
        return null;
      });

      this.props.deleteProjectMember(matchedMember, project_id, member_id);
    }

    this.setState({
      submitted: false
    });
  };

  render() {
    let project = {};
    let hasData = false;

    if(typeof this.props.projects.project !== 'undefined')  {
      project = this.props.projects.project.data;
      //console.log('ProjectSettings project', project);
      if(typeof project.info !== 'undefined'){
        hasData = true;
      }
    }

    const { projectLoaded, membersLoaded } = this.props;

    return (
		<MDBContainer fluid className='p-0'>
			<div className='page-title-ctas p-l-md p-r-md'>
				{(projectLoaded && hasData) &&
					<MDBRow className='m--0 align-items-center'>
							<MDBCol className='p--0 m--0'>
								<p className='page-title font-family-kanit  font-size-30 line-height-7 color-gray line-height-6'>{project.info.name}</p>
							</MDBCol>

							<MDBCol>
								<MDBRow className='float-right'>
									<ButtonGroup>
										<Link to={`/project/${project.id}`}>
											<Button
												flat='true'
												background='transparent' foreground='black'
												borderWidth={2} borderColor='dark' size={-1}
												styles={{ 'width': '100%', 'opacity': '0.8' }}>
												<Icon color='black' name='chevron' rotate={180} />
												<span className='color-black font-weight-bold'>Back to Project Overview</span>
											</Button>
										</Link>
										<Link to='/'>
											<Button
											flat='true'
											background='transparent' foreground='black'
											borderWidth={2} borderColor='dark' size={-1}
											styles={{ 'width': '100%', 'opacity': '0.8' }}>
											<Icon color='black' name='chevron' rotate={180} />
											<span className='color-black font-weight-bold'>Back to All Projects</span>
											</Button>
										</Link>
									</ButtonGroup>
								</MDBRow>
							</MDBCol>
					</MDBRow>
				}
			</div>

			<div className="page-filter">
				<MDBRow className='project-setting-wrap'>
					{(projectLoaded && hasData) &&
						<React.Fragment>
							<MDBCol className='project-info__description'>
								<label className="mb-0 font-size-13 font-weight-600 mb-1">DESCRIPTION</label>
								<p className='m-0 font-weight-600'>{project.info.description}</p>
							</MDBCol>
							<MDBCol className='px-0'>
								<div className='border-radius-7 background-color-white'>
									<div className='px-3  text-left '>
										<label className='mb-0 font-size-13 font-weight-600 mb-1'>OWNER</label>
										<div>
											{/* <img src={avatarFemale} alt='' className='width-25 border-radius-50 mr-2' /> */}
											<span className='font-weight-600 font-size-14'>{utility.decrypt(project.owner.account.first_name)} {utility.decrypt(project.owner.account.last_name)}</span>
										</div>
									</div>
								</div>
							</MDBCol>
						</React.Fragment>
					}
					</MDBRow>
				</div>
			<MDBRow className='m--0'>
				{  (projectLoaded && hasData) ?
				<MDBCol lg='12' xl='12' md='12' className='col-md-12 col-lg-12 col-xl-12 p--0 p--0 main-body-container container-fluid false p--sm  sub-body-container rectangle-background'>
					<MDBRow className='main-body-container background-color-white pl-2 pr-0 m--0 p--0'>
						<MDBCol lg='8' xl='7' md='7' className='project-info__overview p--md'>
							{this.renderProjectSettings()}
						</MDBCol>
						<MDBCol lg='4' xl='5' md='5' className='background-color-lead p--md'>
							<MDBRow>
								<MDBCol>
									{ membersLoaded && this.renderManageMembers()}
								</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				</MDBCol> :
				<MDBCol lg='12' xl='12' md='12' className='col-md-12 col-lg-12 col-xl-12 p--0 p--0 main-body-container container-fluid false p--sm  sub-body-container rectangle-background'>
					<MDBContainer fluid className='main-body-container background-color-white width--full'>
						<MDBCol lg='12' className='sub-body-container height--full'>
							<div className='loader-container'>
							<div className="loader dark">Loading...</div>
							</div>
						</MDBCol>
					</MDBContainer>
				</MDBCol>
				}
			</MDBRow>
		</MDBContainer>
    );
  }
}

function mapState(state) {
  const { projects, project } = state;
  const { projectLoaded, adding, deleting, membersLoaded } = state.projects;
  return { projects, project, deleting, adding, projectLoaded, membersLoaded };
}

const actionCreators = {
  getProject: projectActions.getProject,
  getAllProjectMembers: projectActions.getAllProjectMembers,
  updateProject: projectActions.updateProject,
  deleteProject: projectActions.deleteProject,
  addProjectMember: projectActions.addProjectMember,
  deleteProjectMember: projectActions.deleteProjectMember,
};

const connectedViewProjects = connect(mapState, actionCreators)(ProjectSettings);
export { connectedViewProjects as ProjectSettings };