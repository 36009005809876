import { fileConstants } from "_constants";

/**
 * Reducer function to handle file-related actions.
 *
 * @param {Object} state - The current state of the file reducer.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.files] - The files data (if applicable).
 * @param {Object} [action.error] - The error object (if applicable).
 * @returns {Object} The new state of the file reducer.
 */
export function file(state = {}, action) {
  switch (action.type) {
    case fileConstants.GET_ALL_FILE_REQUEST:
    case fileConstants.UPDATE_FILE_REQUEST:
    case fileConstants.ADD_FILE_REQUEST:
    case fileConstants.DELETE_FILE_REQUEST:
      return {
        ...state,
        getFileLoading: true,
        getFileLoaded: false,
      };

    case fileConstants.GET_ALL_FILE_SUCCESS:
    case fileConstants.UPDATE_FILE_SUCCESS:
    case fileConstants.ADD_FILE_SUCCESS:
    case fileConstants.DELETE_FILE_SUCCESS:
      return {
        ...state,
        getFileLoading: false,
        getFileLoaded: true,
        files: action.files,
      };

    case fileConstants.GET_ALL_FILE_FAILURE:
    case fileConstants.UPDATE_FILE_FAILURE:
    case fileConstants.ADD_FILE_FAILURE:
    case fileConstants.DELETE_FILE_FAILURE:
      return {
        ...state,
        getFileLoading: false,
        getFileLoaded: true,
        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
}
