import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { formActions } from '_actions';
import { MDBRow } from 'mdbreact';
import InputButtons from '_pages/Forms/components/InputButtons';
import PresetsButton from '_pages/Forms/components/PresetsButton';
import { FormGroupManage } from '_pages/Forms/FormGroupManage';
import { quickPresets } from '_pages/Forms/constants/quickPresets';
import { inputsManage } from '_pages/Forms/constants/inputsManage';
import { EditFormGroupUpdate } from '_pages/Forms/FormBuilder/components/EditFormGroupUpdate';
import { EditFormFieldUpdate } from '_pages/Forms/FormBuilder/components/EditFormFieldUpdate';
import CreateFormGroup from '_pages/Forms/FormBuilder/components/CreateFormGroup';
import settings from '_assets/images/cogs.svg';

const ManageFields = ({ match, getForm, findField, createFormGroup, createField, reorderGroup, reorderField }) => {
    const projectId = match.params.id;
    const projectData = JSON.parse(localStorage.getItem('project'));
    const projectForms = projectData.forms;
    const basicInfo = projectForms.find(item => item.is_default) || {};
    const formId = basicInfo.id || 0;

    const [formData, setFormData] = useState(projectForms);
    const [formGroupData, setFormGroupData] = useState([]);
    const [fieldData, setFieldData] = useState([]);
    const [activeFormGroup, setActiveFormGroup] = useState('');
    const [activeFormField, setActiveFormField] = useState('');
    const [activeSideBar, setActiveSideBar] = useState('select');
    const [selectedInput, setSelectedInput] = useState(false);

    useEffect(() => {
        getForm(projectId, formId);
    }, [projectId, formId, getForm]);

    const handleGetForm = () => {
        const formId = localStorage.getItem('formId');
        getForm(projectId, formId);
    };

    const handleGetField = (formGroupId, fieldId) => {
        const formId = localStorage.getItem('formId');
        findField(projectId, formId, formGroupId, fieldId);
    };

    const getBlankSelection = () => {
        setActiveSideBar('select');
        setActiveFormGroup('');
        handleGetForm();
    };

    const getEditFormGroup = data => {
        setFormGroupData(data || {});
        setActiveSideBar('editFormGroup');
    };

    const getEditFormField = (data, formGroupId) => {
        setFieldData(data || {});
        setActiveSideBar('editField');
    };

    const handleCreateFormGroup = data => {
        createFormGroup(projectId, formId, data);
        handleGetForm();
        setActiveSideBar('select');
    };

    const handleCreateFormField = (formGroupId, type) => {
        const formId = localStorage.getItem('formId');
        createField(projectId, formId, formGroupId, { type });
        handleGetForm();
        setActiveSideBar('select');
    };

    const renderSelection = () => {
        switch (activeSideBar) {
            case 'select':
                return <div className='form-builder__select text-center'>
                    <p>Select a <b>Form Group</b> or <b>Field</b> to edit</p>
                    <img alt='' src={settings} />
                </div>;
            case 'editFormGroup':
                return <EditFormGroupUpdate projectId={projectId} formId={formId} handleFormUpdate={setFormData} formGroupData={formGroupData} getBlankSelection={getBlankSelection} />;
            case 'editField':
                return <EditFormFieldUpdate projectId={projectId} formId={formId} formGroupId={activeFormGroup} handleFormUpdate={setFormData} handleFieldsUpdate={setFieldData} fieldData={fieldData} getBlankSelection={getBlankSelection} />;
            default:
                return null;
        }
    };

    return (
        <div className='manage-fields'>
            {renderSelection()}
            <FormGroupManage formData={formData} getEditFormGroup={getEditFormGroup} getEditFormField={getEditFormField} handleCreateFormGroup={handleCreateFormGroup} handleCreateFormField={handleCreateFormField} />
        </div>
    );
};

const mapState = state => ({
    formData: state.formData
});

const actionCreators = {
    getForm: formActions.getForm,
    findField: formActions.findField,
    createFormGroup: formActions.createFormGroup,
    createField: formActions.createField,
    reorderGroup: formActions.reorderGroup,
    reorderField: formActions.reorderField
};

const connectedManageFields = connect(mapState, actionCreators)(ManageFields);
export { connectedManageFields as ManageFields };