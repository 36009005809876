import React, {useRef, useState} from "react";
import DOMPurify from "dompurify";
import {httpManagerService, utilitiesService as utility} from "_services";
import Moment from "react-moment";
import NoteEditor from "_pages/Leads/components/LeadInfo/NoteEditor";
import {useParams} from "react-router-dom";
import {alertActions, leadActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import ReplyItem from "_pages/Leads/components/LeadInfo/ReplyItem";


const NoteItem = ({ note, onHandleToggleDeleteNoteAction, isLeadManager }) => {


    const params = useParams();
    const dispatch = useDispatch();



    let description = note.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
    let content = {__html: DOMPurify.sanitize(description)}
    let author =   utility.decrypt(note.owner.first_name) + ' ' + utility.decrypt(note.owner.last_name);
    let initial =   author.charAt(0).toUpperCase();
    let created = note.created;
    let noteId = note.id;
    let replies = note.replies;

    let currentUser = utility.getSessionUser();
    let canManage = parseInt(note.owner.id) === parseInt(currentUser.id);

    const addReplyRef = useRef();
    const noteBtnActionRef = useRef();
    const replyRef = useRef();

    const [editNoteClicked, setEditNoteClicked] = useState(false);
    const [btnActionNoteClicked, setBtnActionNoteClicked] = useState(false);
    const [replyClicked, setReplyClicked] = useState(false);

    const showRestrictionMessage = (restrictionMessage) => {
        if(isLeadManager) return;
        // restrict creation of notes to lead manager
        dispatch(
            alertActions.error(
                {
                error_code: "403",
                error_message: restrictionMessage || "You are not assigned as Lead Manager for this Lead",
                },
                6000
            )
        );
    }
    const handleToggleEditNoteAction = () => {
        showRestrictionMessage();
        if(!isLeadManager) return;
        setEditNoteClicked(!editNoteClicked);
        setBtnActionNoteClicked(!btnActionNoteClicked);
    };


    const handleCancelUpdate = () => {
        setEditNoteClicked(false);
    }

    const parseMentions = (data) => {
        if (!data) return [];
        
        const mentions = [];
        
        // Create a temporary DOM element to parse the HTML string
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = data;  // Set the HTML content to the div
        
        // Find all spans with the 'mention' class
        const mentionElements = tempDiv.querySelectorAll('.mention');
        
        // Extract the 'data-id' and 'data-label' for each mention
        mentionElements.forEach((element) => {
            const mentionId = element.getAttribute('data-id');
            const mentionLabel = element.getAttribute('data-label');
            mentions.push({ id: mentionId, label: mentionLabel });
        });

        return mentions;
    };

    const handleToggleReply = (content) => {
        if(!content){
            setReplyClicked(!replyClicked);
            return;
        }
        const mentions = content ? parseMentions(content.description || "") : [];
        const replies = content.replies;
        // add mentions from replies
        replies.forEach(reply => {
            const replyMentions = parseMentions(reply?.description || "");
            replyMentions.forEach(mention => {
            mentions.push(mention);
            });
        });

        const currentUser = httpManagerService.getUser();
        const decryptedFirstName = utility.decrypt(currentUser.first_name);
        const decryptedLastName = utility.decrypt(currentUser.last_name);
        const fullName = `${decryptedFirstName} ${decryptedLastName}`;
        const isExistingMention = mentions.some((mention) => mention.label === fullName);
        if(isLeadManager || isExistingMention) {
            setReplyClicked(!replyClicked);
            return;
        }
        showRestrictionMessage("Only Lead Managers or mentioned users can reply to this note");
    };


    const handleNoteUpdate = (content, mentions) => {

        const projectId = params.id;
        const leadId = params.leadId;

        let noteData = {
            name: 'Note',
            description: content,
            mentions: mentions
        };

        let noteType = 'note';
        dispatch(leadActions.updateLeadNote(projectId, leadId, noteType, noteId, noteData));
        setEditNoteClicked(false);
    }

    const handleToggleDeleteNoteAction = () => {
        showRestrictionMessage();
        if(!isLeadManager) return;
        onHandleToggleDeleteNoteAction(note);
    }

    const handleNoteReply = (content, mentions) =>  {

        const projectId = params.id;
        const leadId = params.leadId;

        let noteData = {
            leadId: parseInt(leadId),
            lead_id: parseInt(leadId),
            name: 'Reply',
            description: content,
            mentions: mentions
        };

        dispatch(leadActions.addNoteReply(projectId, leadId, noteId, noteData));
        setReplyClicked(false);
    }

    const [isEditorEmpty, setEditorEmpty] = useState(true);

    const renderMember = (memberAccount) => {
        const displayedLoggedInUser = utility.decrypt(memberAccount.first_name);
        if(memberAccount.photo){
        try {
            const decryptedPhoto = utility.decrypt(memberAccount.photo);
            return (<div className='avatar-box'>
            <span>
                <img src={decryptedPhoto} />
            </span>
            </div>);
        } catch (error) {
            console.error("Problem decrypting photo");
        }
        }
        const firstChar = displayedLoggedInUser.charAt(0);
        return <span data-full-name={displayedLoggedInUser}>{firstChar}</span>;
    };

    return (
        <div className='threads'>
            <div className='thread-topic'>
                <div className='thread-creator'>
                    <div className='avatar-box avatar-color-1'>
                        {
                            renderMember(note.owner)
                        }
                        <p>{author}</p>
                    </div>
                    <div className='timestamp'>
                        {/* <span><Moment date={created} fromNow ago/> ago</span> */}
                        <span><Moment date={created} format="MMM D, YYYY h:mm A"/></span>
                        {canManage && <div className='edit-delete-box' ref={noteBtnActionRef}>
                            <button className="fas fa-ellipsis-v btn-ellipsis">
                                <div className='btn-groups'>
                                    <button onClick={() => handleToggleEditNoteAction()}><i
                                        className="icon-lms icon-edit"></i> Edit
                                    </button>
                                    <button
                                        onClick={() => handleToggleDeleteNoteAction()}
                                    ><i className="icon-lms icon-delete"></i> Delete</button>
                                </div>
                            </button>
                        </div>}
                    </div>
                </div>
                <div className='thread-text'>
                    {! editNoteClicked &&
                            <div className="editor-display-text" dangerouslySetInnerHTML={content}></div>
                    }

                    {editNoteClicked &&
                        <NoteEditor
                            confirmButtonName={'Update'}
                            editorContent={description}
                            onConfirmUpdate={(content, mentions) => handleNoteUpdate(content, mentions)}
                            onCancelUpdate={() => handleCancelUpdate()}
                            setEditorEmpty={setEditorEmpty}
                            isEditorEmpty={isEditorEmpty}
                        />
                    }
                </div>
                {replies.length > 0 &&
                    <div className='thread-reply-box'>
                        {replies.map((reply,index) => {
                            return(<ReplyItem
                                isLeadManager={isLeadManager}
                                key={index}
                                reply={reply}
                                onHandleToggleDeleteNoteAction={(reply) => onHandleToggleDeleteNoteAction(reply)}
                            />);
                        })}
                    </div>
                }
                <div className='make-thread-reply' ref={addReplyRef}>
                    { !replyClicked &&
                    <div className='reply-action'>
                        <button className='plain-btn' onClick={() => handleToggleReply(note)}>
                            {/* <i className="icon-lms icon-reply"></i> */}
                            Reply
                        </button>
                    </div>}
                    { replyClicked &&
                    <NoteEditor
                        confirmButtonName={'Reply'}
                        ref={replyRef} editorContent={''}
                        onConfirmUpdate={(content, mentions) => handleNoteReply(content, mentions)}
                        onCancelUpdate={() => handleToggleReply()}
                        setEditorEmpty={setEditorEmpty}
                        isEditorEmpty={isEditorEmpty}
                    />}
                </div>
            </div>
        </div>
    );
};

export default NoteItem;