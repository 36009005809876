import React from 'react'

/**
 * SideInfoLoader component renders a skeleton loader for side information.
 * It displays multiple groups of skeleton boxes to simulate loading content.
 *
 * @returns {JSX.Element} A JSX element representing the skeleton loader.
 */
const SideInfoLoader = () => {
  return (
    <div className={`skeleton-loader skeleton-side-info active`}>
        <div className='skel-grp'>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
                <div className="skeleton skeleton-box"></div>
            </div>
        </div>
        <div className='skel-grp'>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
                <div className="skeleton skeleton-box"></div>
            </div>
        </div>
        <div className='skel-grp'>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
                <div className="skeleton skeleton-box"></div>
            </div>
        </div>
        <div className='skel-grp'>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
            </div>
            <div className='skel-col'>
                <div className="skeleton skeleton-box"></div>
                <div className="skeleton skeleton-box"></div>
            </div>
        </div>
    </div>
  )
}

export default SideInfoLoader