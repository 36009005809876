import React, { useState } from 'react';

/**
 * Input component renders a text input field with various properties.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.id - The id of the input element.
 * @param {boolean} props.required - Whether the input is required.
 * @param {string} props.name - The name of the input element.
 * @param {string} props.type - The type of the input element.
 * @param {string} props.hint - The placeholder text for the input element.
 * @param {string} props.text - The initial text value of the input element.
 * @param {function} props.onChange - The function to call when the input value changes.
 * @param {string} props.inputClass - Additional CSS classes for the input element.
 * @param {string} props.marginTop - CSS class for the top margin of the input container.
 *
 * @returns {JSX.Element} The rendered input component.
 */
const Input = ({ id, required, name, type, hint, text, onChange, inputClass, marginTop }) => {
  const [inputText, setInputText] = useState(text || '');

  // Change value of text input
  const handleChange = (e) => {
    setInputText(e.target.value);
    onChange(e);
  };

  return (
    <div className={marginTop}>
      <input
        id={id}
        required={required}
        name={name}
        onChange={handleChange}
        type={type}
        className={`${inputClass} form-control-sm font-size-13 py-3`}
        placeholder={hint}
        value={inputText}
      />
    </div>
  );
};

export default Input;
