import React from 'react';
import { MDBCol } from 'mdbreact';
import Moment from "react-moment";
import LeadClickedInfo from "_pages/Leads/components/LeadInfo/LeadClickedInfo";

const LeadViewInfoForms = ({ leadData, projectId }) => {
    const handleTextEllipsis = (text, max) => {
        return text.substr(0, max) + (text.length > max ? '...' : '');
    };

    const formData = {
        id: leadData.form_id,
        name: leadData.form_name,
        description: leadData.form_desc,
        created: leadData.created
    };

    let fbForm = leadData.fb_form;
    let urls = leadData.attributions;
    let campaign = leadData.campaign_data;

    if (fbForm) {
        campaign.source_url = 'www.facebook.com';
    }

    return (
        <div className='source-box tab-content-box'>
            {campaign ? (
                <div className='source-group'>
                    <div className='inner-title-box'>
                        <p>SOURCE DATA</p>
                    </div>
                    <div className='source-list-box tab-content-list-box'>
                        <div className='source'>
                            {campaign.source_url && (
                                <div>
                                    <p>URL</p>
                                    <span className='mb-5px'>{campaign.source_url}</span>
                                </div>
                            )}
                            <div>
                                <p>Source</p>
                                <span className='mb-5px'>
                                    {campaign.source || (
                                        <>
                                            NOT AVAILABLE
                                            <i className='q-mark-icon'>?
                                                <span className='info-not-available'>
                                                    Either the data is not available on Google Analytics or the lead has no Google Client ID Data.
                                                </span>
                                            </i>
                                        </>
                                    )}
                                </span>
                            </div>
                            <div>
                                <p>Medium</p>
                                <span className='mb-5px'>
                                    {campaign.medium || (
                                        <>
                                            NOT AVAILABLE
                                            <i className='q-mark-icon'>?
                                                <span className='info-not-available'>
                                                    Either the data is not available on Google Analytics or the lead has no Google Client ID Data.
                                                </span>
                                            </i>
                                        </>
                                    )}
                                </span>
                            </div>
                            <div>
                                <p>Campaign</p>
                                <span className='mb-5px'>
                                    {campaign.campaign || (
                                        <>
                                            NOT AVAILABLE
                                            <i className='q-mark-icon'>?
                                                <span className='info-not-available'>
                                                    Either the data is not available on Google Analytics or the lead has no Google Client ID Data.
                                                </span>
                                            </i>
                                        </>
                                    )}
                                </span>
                            </div>
                            <div>
                                <p>Qualification</p>
                                <span className='mb-5px'>{campaign.qualification || 'NOT AVAILABLE'}</span>
                            </div>
                            <div>
                                <p>Acquisition Date</p>
                                <span>
                                    <Moment date={leadData.created} format='LLL' />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='source-group'>
                    <div className='inner-title-box'>
                        <p>SOURCE DATA</p>
                    </div>
                    <div className='source-list-box tab-content-list-box'>
                        <div className='source'>
                            <div>
                                <p>URL</p>
                                <span className='mb-5px'>
                                    {leadData.source_url || (
                                        <>
                                            https://lms.propelrr.io
                                            <i className='q-mark-icon'>?
                                                <span className='info-not-available'>
                                                    This lead is created via the LMS Platform.
                                                </span>
                                            </i>
                                        </>
                                    )}
                                </span>
                            </div>
                            <div>
                                <p>Source</p>
                                <span className='mb-5px'>NOT AVAILABLE</span>
                            </div>
                            <div>
                                <p>Medium</p>
                                <span className='mb-5px'>NOT AVAILABLE</span>
                            </div>
                            <div>
                                <p>Campaign</p>
                                <span className='mb-5px'>NOT AVAILABLE</span>
                            </div>
                            <div>
                                <p>Qualification</p>
                                <span className='mb-5px'>NOT AVAILABLE</span>
                            </div>
                            <div>
                                <p>Acquisition Date</p>
                                <span>
                                    <Moment date={leadData.created} format='LLL' />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {urls && urls.length > 0 && (
                <div className='source-group'>
                    <div className='inner-title-box'>
                        <p>CLICKED URL</p>
                    </div>
                    <div className='source-list-box tab-content-list-box'>
                        <div className='source'>
                            <ul className='accordion'>
                                {urls.map((url, index) => (
                                    <LeadClickedInfo key={index} url={url} />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {fbForm && (
                <div className='source-group'>
                    <div className='inner-title-box'>
                        <p>FACEBOOK FORM DATA</p>
                    </div>
                    <div className='source-list-box tab-content-list-box'>
                        <div className='source'>
                            {Object.entries(fbForm).map(([key, value]) => (
                                <div key={key}>
                                    <p>{key.replace('_', ' ')}</p>
                                    <span className='mb-5px'>{value}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LeadViewInfoForms;
