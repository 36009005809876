import React from "react";

/**
 * AgentInfoLoader component renders a skeleton loader for agent information.
 * It displays a loading animation with multiple skeleton boxes to indicate
 * that the content is being loaded.
 *
 * @component
 * @example
 * return (
 *   <AgentInfoLoader />
 * )
 */
const AgentInfoLoader = () => {
  return (
    <div className="skeleton-loader skeleton-main-info active">
        <div className="row">
          <div className="col-md-12">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box short"></div>
          </div>
          <div className="col-md-12">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box short"></div>
          </div>
          <div className="col-md-12">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box short"></div>
          </div>
        </div>
    </div>
  );
};

export default AgentInfoLoader;
