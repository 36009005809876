import React, { useEffect, useState } from "react";
import LeadComposeEmailDrawer from "./Email/LeadComposeEmailDrawer";
import EmailModal from "./Email/EmailModal";
import LeadEmailThread from "./Email/LeadEmailThread";
import LeadEmailDraftThread from "./Email/LeadEmailDraftThread";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailActions } from "_actions";
import LeadEmailSingle from "./Email/LeadEmailSingle";
import { httpManagerService as httpManager } from "_services";
import { alertActions } from "_actions";

const LeadEmail = ({ leadEmailDetails, emailLoaded, lead }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [emailHistory, setEmailHistory] = useState([]);
  const [thread, setThread] = useState({});

  const [isComposeEmailDrawer, setIsComposeEmailDrawer] = useState(false);

  const isLeadManager = httpManager.isCurrentUserLeadManager(lead, params.id);

  const emailStatus = useSelector((state) => state.email);

  const [toggleEmailType, setToggleEmailType] = useState(1);
  const [isSaveDraftModal, setIsSaveDraftModal] = useState(false);
  const [isDraftAlert, setIsDraftAlert] = useState(false);
  const [draftEmail, setDraftEmail] = useState({});

  const toggleTabEmailType = (index) => {
    setToggleEmailType(index);
  };

  const openSaveDraftModal = () => {
    setIsSaveDraftModal(true);
    setIsComposeEmailDrawer(false);
    console.log("called close compose email");
  };

  const handleSaveDraftEmail = (draftData) => {
    setDraftEmail(draftData);
  };

  const closeSaveDraftModal = () => {
    setIsSaveDraftModal(false);
    if (draftEmail.id != 0) {
      dispatch(
        emailActions.deleteDraft(params.id, params.leadId, draftEmail.id)
      );
    }
    setDraftEmail({});
  };

  const closeDraftAlert = () => {
    setIsDraftAlert(false);
  };

  const emailSaveDraft = () => {
    setIsSaveDraftModal(false);
    setIsDraftAlert(true);
    
    if (draftEmail.id == 0) {
      dispatch(emailActions.saveDraft(params.id, params.leadId, draftEmail));
    } else {
      dispatch(emailActions.updateDraft(params.id, params.leadId, draftEmail));
    }

    setDraftEmail({});
    setTimeout(() => {
      setIsDraftAlert(false);
    }, 5000);
  };

  useEffect(() => {
    const leadId = params.leadId;

    if (emailStatus.email.lead_id == leadId) {
      if (isComposeEmailDrawer) {
        setIsComposeEmailDrawer(false);
      }

      if (leadEmailDetails) {
        setEmailHistory(leadEmailDetails.email_threads);
      }

      if (
        isEmailOpen &&
        !emailStatus.getEmailReply &&
        emailLoaded &&
        !emailStatus.getEmailNotification &&
        !emailStatus.getUnreadLoaded
      ) {
        setIsEmailOpen(!isEmailOpen);
      }
    }
  }, [emailLoaded, emailStatus.getEmailLoaded]);

  const handleToggleComposeEmailDrawer = () => {
    if (!isLeadManager) {
       dispatch(
        alertActions.error(
          {
            error_code: "403",
            error_message: "You are not assigned as Lead Manager for this Lead",
          },
          6000
        )
      );
      return;
    }
    setIsComposeEmailDrawer(!isComposeEmailDrawer);
  };

  // handle open email
  const [isEmailOpen, setIsEmailOpen] = useState(false);

  const handleReplyDraft = (draft) => {
    setDraftEmail(draft);

    const thread = emailHistory.find((t) => t.thread_id == draft.thread_id);

    handleToggleEmail(thread);
  };

  const handleToggleEmail = (thread) => {
    setThread(thread);
    setIsEmailOpen(!isEmailOpen);

    if (isEmailOpen) {
      dispatch(emailActions.getemails(params.id, params.leadId));
    }
  };

  useEffect(() => {
    if (thread.status === true) {
      const messageIds = thread.thread_messages
        .filter((msg) => {
          return msg.status == 1;
        })
        .map((m) => {
          return m.message_id;
        });

      const request = {
        thread_id: thread.thread_id,
        message_ids: messageIds,
      };

      dispatch(
        emailActions.updateUnreadEmails(params.id, params.leadId, request)
      );
    }
  }, [thread]);

  return (
    <>
      {emailLoaded && (
        <div className="email-box tab-content-box">
          {leadEmailDetails && !isEmailOpen && (
            <>
              <div className="email-control-box">
                <div
                  className={
                    toggleEmailType === 1
                      ? "email-control-btn active"
                      : "email-control-btn"
                  }
                  onClick={() => toggleTabEmailType(1)}
                >
                  <span>Inbox</span>
                </div>
                <div
                  className={
                    toggleEmailType === 2
                      ? "email-control-btn active"
                      : "email-control-btn"
                  }
                  onClick={() => toggleTabEmailType(2)}
                >
                  <span>Drafts</span>
                </div>
              </div>
              <div className="email-type-box">
                <div
                  className={toggleEmailType === 1 ? "et-box active" : "et-box"}
                >
                  <LeadEmailThread
                    isLeadManager={isLeadManager}
                    onHandleToggleComposeEmailDrawer={() =>
                      handleToggleComposeEmailDrawer()
                    }
                    onHandleToggleEmail={handleToggleEmail}
                    threads={emailHistory}
                  />
                </div>
                <div
                  className={toggleEmailType === 2 ? "et-box active" : "et-box"}
                >
                  <LeadEmailDraftThread
                    onHandleToggleComposeEmailDrawer={() =>
                      handleToggleComposeEmailDrawer()
                    }
                    openReplyDraft={handleReplyDraft}
                    openEmailDraft={handleSaveDraftEmail}
                  />
                </div>
              </div>
            </>
          )}

          {isEmailOpen && (
            <LeadEmailSingle
              isLeadManager={isLeadManager}
              onHandleToggleEmail={handleToggleEmail}
              thread={thread}
              openDraft={draftEmail}
              saveDraftEmail={handleSaveDraftEmail}
            />
          )}
        </div>
      )}
      {!emailLoaded && (
        <div className="loading-box">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      {isComposeEmailDrawer && (
        <LeadComposeEmailDrawer
          isComposeEmailDrawer={isComposeEmailDrawer}
          onHandleToggleComposeEmailDrawer={() =>
            handleToggleComposeEmailDrawer()
          }
          leadEmailDetails={leadEmailDetails}
          openSaveDraftModal={() => openSaveDraftModal()}
          saveDraftEmail={handleSaveDraftEmail}
          openDraft={draftEmail}
        />
      )}

      <EmailModal
        isOpen={isSaveDraftModal}
        emailSaveDraft={() => emailSaveDraft()}
        closeSaveDraftModal={() => closeSaveDraftModal()}
      />

      <div
        className={
          isDraftAlert
            ? "alert alert-success alert-dismissible"
            : "alert alert-success alert-dismissible fade"
        }
      >
        <i className="fas fa-check-circle"></i>
        Email reply saved as draft
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => closeDraftAlert()}
        >
          <span inert="true">&times;</span>
        </button>
      </div>
    </>
  );
};

export default LeadEmail;
