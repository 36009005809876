import React, { useState, useEffect, useRef } from 'react';
import { influencerActions } from "_actions";
import { connect } from "react-redux";

const KpiHeaderFilter = ({ data, onKpiHeaderFilterChanged }) => {
    const filterHeaderRef = useRef();
    const [filterHeaderToggled, setFilterHeaderToggled] = useState(false);
    const [checkedFilters, setCheckedFilters] = useState(data);

    const handleOpenFilterHeader = () => {
        setFilterHeaderToggled(!filterHeaderToggled);
    };

    const handleCloseFilterHeader = () => {
        setFilterHeaderToggled(false);
    };

    const handleDropdown = (value) => {
        if (!filterHeaderRef.current.contains(value.target)) {
            setFilterHeaderToggled(false);
        }
    };

    const handleFilterItemChange = (e) => {
        const { value, checked } = e.target;
        let updatedCheckedFilters = [...checkedFilters, value];
        if (!checked) {
            updatedCheckedFilters = updatedCheckedFilters.filter(x => x !== value);
        }
        setCheckedFilters(updatedCheckedFilters);
        onKpiHeaderFilterChanged(updatedCheckedFilters);
    };

    useEffect(() => {
        const handleDocumentClick = (e) => handleDropdown(e);
        document.addEventListener('click', handleDocumentClick, true);
        return () => {
            document.removeEventListener('click', handleDocumentClick, true);
        };
    }, []);

    useEffect(() => {
        if (!arraysAreEqual(checkedFilters, data)) {
            setCheckedFilters(data);
        }
    }, [data]);

    const arraysAreEqual = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    };

    return (
        <div className='filter-box' ref={filterHeaderRef}>
            <div className='filter-btn'>
                <button onClick={handleOpenFilterHeader} className='icon-text-btn'>
                    <i className="fas fa-sliders-h"></i>
                    <span>Filters</span>
                </button>
            </div>
            <div className={filterHeaderToggled ? 'filter-dropdown active' : 'filter-dropdown'}>
                <div className='filter-title'>
                    <p>Filters</p>
                    <i className='fas fa-times' onClick={handleCloseFilterHeader}></i>
                </div>

                <div className='filter-list'>
                    {data.map((d, index) => {
                        let color = index + 1;
                        return (
                            <div key={index} className='checkbox-group'>
                                <input 
                                    value={d} 
                                    type='checkbox' 
                                    onChange={handleFilterItemChange} 
                                    checked={checkedFilters.includes(d)} 
                                    name='selectedCategories' 
                                    id={`checkbox-${index}`} 
                                />
                                <label htmlFor={`checkbox-${index}`}>{d}</label>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const mapState = (state) => {
    const { influencers } = state;
    const { getPlatFormTypeLoaded, getCategoryListLoaded } = influencers;
    return { influencers, getPlatFormTypeLoaded, getCategoryListLoaded };
};

const actionCreators = {
    getPlatformTypes: influencerActions.getPlatformTypes,
    getCategories: influencerActions.getCategories,
};

const connectedViewKpiHeaderFilter = connect(mapState, actionCreators)(KpiHeaderFilter);
export { connectedViewKpiHeaderFilter as KpiHeaderFilter };
