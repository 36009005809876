/**
 * Retrieves an item from sessionStorage and parses it.
 *
 * @param {string} key - The key of the item to retrieve from localStorage.
 * @param {boolean} [shouldSplit=false] - Whether to split the retrieved value by commas.
 * @returns {string|null|string[]} - The parsed value from localStorage. Returns null if the value is "null" or null. Returns an array if shouldSplit is true, otherwise returns the string value.
 */
export const getParsedSessionItem = (key, shouldSplit = false) => {
  const value = localStorage.getItem(key);
  if (value === "null" || value === null) {
    return null;
  }
  return shouldSplit ? value.split(",") : value;
};
