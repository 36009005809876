import { emailActions } from "_actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { utilitiesService } from "_services";
import DOMPurify from "dompurify";

const LeadEmailDraftThread = ({ onHandleToggleComposeEmailDrawer, openReplyDraft, openEmailDraft }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [emailDrafts, setEmailDrafts] = useState([]);

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const email = useSelector((state) => state.email);

  // console.log("email: ", email);

  useEffect(() => {
    dispatch(emailActions.getDrafts(params.id, params.leadId));
  }, []);

  useEffect(() => {
    if (email.getDraftLoaded && Array.isArray(email.drafts)) {
        // Sort drafts by the 'updated' property in descending order
        const sortedDrafts = email.drafts.sort((a, b) => new Date(b.updated) - new Date(a.updated));
        setEmailDrafts(sortedDrafts);
    }
  }, [email.getDraftLoaded, email.drafts]);

  const capitalizeName = (name) => {
    const words = name.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  const handleLoadDraft = (draft) => {
    if(draft.type === 'compose') {
        openEmailDraft(draft);
        onHandleToggleComposeEmailDrawer(); // Open the compose email drawer
    }
    if(draft.type === 'reply') {
        openReplyDraft(draft); // Open the reply email drawer
    }
    
  }

  const sanitizeAndParseHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const doc = new DOMParser().parseFromString(sanitizedHTML, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      {email.getDraftLoaded && (
        <div className="email-list">
          {emailDrafts.map((draft) => (
            <div key={draft.id} className="email-content-box" onClick = {() => handleLoadDraft(draft)}>
              <div className="email-name">
                <p>
                  {capitalizeName(
                    utilitiesService
                      .decrypt(draft.to)
                      .split("@")[0]
                      .replace(".", " ")
                  )}
                </p>
              </div>
              <div className="email-details">
                {
                  utilitiesService.decrypt(draft.subject) ?
                  <p className="email-subject">
                    {utilitiesService.decrypt(draft.subject)}
                  </p>
                  :
                  <p className="email-subject">
                    (No subject)
                  </p>
                }
                <span className="email-msg" 
                dangerouslySetInnerHTML={{
                  __html: sanitizeAndParseHTML(
                    utilitiesService.decrypt(draft.template)
                  ),
                }}
                ></span>
              </div>
              <div className="email-date">
                <p>
                  {" "}
                  {new Date(draft.updated).toLocaleDateString("en-US", options)}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      { email.getDraftLoaded && emailDrafts.length == 0 &&
        <div className='empty-tab-content'>
          <div className='empty-content'>
              <h6>You don't have any drafts</h6>
              <p>
                  Saving a draft allows you to keep a message you aren't ready to send yet.
              </p>
              <span onClick={onHandleToggleComposeEmailDrawer}>
                  <i className="icon-lms icon-edit"></i>
                  Compose Email
              </span>
          </div>
        </div>
      }
      {!email.getDraftLoaded && (
        <div className="loading-box">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="compose-email-control">
        <button
          className="plain-btn"
          onClick={onHandleToggleComposeEmailDrawer}
        >
          <i className="icon-lms icon-edit"></i>
          Compose Email
        </button>
      </div>
    </>
  );
};

export default LeadEmailDraftThread;
