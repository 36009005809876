import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { httpManagerService as httpManager } from  '_services';

/**
 * A higher-order component that wraps a given component and ensures that the user is authenticated
 * before rendering it. If the user is not authenticated, they are redirected to the login page.
 *
 * @param {Object} props - The properties passed to the PrivateRoute component.
 * @param {React.Component} props.component - The component to render if the user is authenticated.
 * @param {...Object} rest - Any additional properties to pass to the Route component.
 * @returns {JSX.Element} A Route component that conditionally renders the given component or redirects to the login page.
 */
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest}
           render = { props => httpManager.isLoginWithUserCredentials() && httpManager.hasValidAccessToken()
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    } />
)
