import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { projectActions } from "_actions";
import { MDBIcon } from "mdbreact";
import { priorityColor, priorityIcon } from '_pages/Leads/constants/BreadCrumbs';

const LeadPriorityDropdown = ({ lead, onHandleUpdateLeadPriority }) => {
  const filterRef = useRef(null);
  const [filterToggled, setFilterToggled] = useState(false);

  useEffect(() => {
    const handleDropdown = (value) => {
      if (!filterRef.current.contains(value.target)) {
        setFilterToggled(false);
      }
    };
    
    document.addEventListener('click', handleDropdown, true);

    return () => {
      document.removeEventListener('click', handleDropdown, true);
    };
  }, []);

  const handleOpenFilter = () => {
    setFilterToggled(true);
  };

  const handleCloseFilter = () => {
    setFilterToggled(false);
  };

  const handleUpdateLeadPriority = (value) => {
    onHandleUpdateLeadPriority(value);
    setFilterToggled(false);
  };

  const items = [
    { id: 1, value: '1', label: 'Low' },
    { id: 2, value: '2', label: 'Medium' },
    { id: 3, value: '3', label: 'High' },
  ];

  const priority = lead.priority;

  return (
    <div className="filter-box" ref={filterRef}>
      <div className="filter-btn priority-btn">
        <span
          className={`${priorityColor[priority.name]}`}
          onClick={handleOpenFilter}
        >
          <i className={`icon-lms icon-${priorityIcon[priority.name]}`}></i>
          {lead.priority.name}
        </span>
      </div>
      <div className={filterToggled ? 'filter-dropdown active' : 'filter-dropdown'}>
        <div className="filter-title">
          <p>Priority</p>
          <i className="icon-lms icon-close" onClick={handleCloseFilter}></i>
        </div>
        <div className="filter-list priority-list">
          <div className="filter-group">
            {items.map((item, index) => (
              <span
                key={`filter-dropdown-${index}`}
                className={`filter-name ${priorityColor[item.label]}`}
                onClick={() => handleUpdateLeadPriority(item.id)}
              >
                <i className={`icon-lms icon-${priorityIcon[item.label]}`}></i>
                {item.label}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  const { project, members, membersLoaded, projectLoaded } = state.projects;
  return { project, members, membersLoaded, projectLoaded };
};

const actionCreators = {
  getProject: projectActions.getProject,
};

const connectedViewLeadPriorityDropdown = connect(
  mapState,
  actionCreators
)(LeadPriorityDropdown);

export { connectedViewLeadPriorityDropdown as LeadPriorityDropdown };
