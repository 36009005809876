import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { projectActions, influencerActions } from "_actions";
import TextLoader from "_components/Loader/TextLoader";

export const InfluencerBreadcrumb = () => {
  const dispatch = useDispatch();
  const { projects, influencers } = useSelector((state) => state);
  const { influencerBdLoaded, influencerBdLoading } = influencers;

  const project = projects.project;
  const influencer = influencers.influencer;

  return (
    <div className="breadcrumbs">
      {project && (
        <Link to={`/project/4/influencers`} className="back">
          <span className="icon-s">
            <i className="icon-lms icon-arrow-left"></i>
          </span>
        </Link>
      )}

      {!project && (
        <Link className="back">
          <span className="icon-s">
            <i className="icon-lms icon-arrow-left"></i>
          </span>
        </Link>
      )}

      {project && (
        <Link to={`/project/${project.data.id}/influencers`} className="link">
          Influencers{influencer && <i>/</i>}
        </Link>
      )}

      {!project && (
        <Link className="link">
          Influencers<i>/</i>
        </Link>
      )}

      {!influencerBdLoading && influencer && influencer.data && <span>{influencer.data.name}</span>}
      {influencerBdLoading && <TextLoader />}
    </div>
  );
};