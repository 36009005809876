import { httpManagerService as httpManager } from  '_services';

export const leadService = {
    getLeads,
    getLeadsPaginated,
    getLeadToPatientConversion,
    getLeadSummary,
    getLeadReport,
    getLeadGoogleSheetReport,
    createLead,
    find,
    updateLead,
    deleteLead,
    getLeadNotes,
    getLeadSession,
    getLeadInterest,
    addLeadNote,
    updateLeadNote,
    deleteLeadNote,
    addNoteReply,
    updateNoteReply,
    deleteNoteReply,
    updateLeadStatus,
    getAllContent,
    createContent,
    findContent,
    updateContent,
    deleteContent,
    enableContent,
    importLeads,
    getAllImportData,
    findImportData,
    getActivityLog,
    getPatientReport,
    getOrigin,
    sendCalendlyInvite,
    batchSelectLeads,
    uploadKalibrrLeads,
    uploadCsvLeads
};

/**
 * Fetches leads for a specific project.
 *
 * @param {string} project_id - The ID of the project to fetch leads for.
 * @returns {Promise} - A promise that resolves to the response of the HTTP request.
 */
function getLeads(project_id){
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead`);
}

/**
 * Fetches paginated leads for a specific project with various filtering and sorting options.
 *
 * @param {number} project_id - The ID of the project.
 * @param {number} [page=1] - The page number to retrieve.
 * @param {number} [size=15] - The number of leads per page.
 * @param {string|null} [query=null] - The search query to filter leads.
 * @param {Array<string>} [status=[]] - The status filters for the leads.
 * @param {Array<string>} [origin=[]] - The origin filters for the leads.
 * @param {Array<string>} [assigned=[]] - The assigned filters for the leads.
 * @param {string|null} [dateFrom=null] - The start date to filter leads.
 * @param {string|null} [dateTo=null] - The end date to filter leads.
 * @param {string|null} [sort=null] - The field to sort the leads by.
 * @param {string|null} [direction=null] - The direction to sort the leads (asc or desc).
 * @returns {Promise} - A promise that resolves to the response of the HTTP request.
 */
function getLeadsPaginated(project_id, page = 1, size = 15, query = null, status = [], origin = [], assigned = [], dateFrom = null, dateTo = null, sort = null, direction = null){
    let parts = [];


    if(query != null && String(query).trim() !== '') {
        parts.push(`query=${query}`);
    }

    if(page != null) {
        parts.push(`page=${page}`);
    }

    parts.push(`size=${size || 15}`);

    if(dateFrom != null) {
        parts.push(`date_from=${dateFrom}`);
    }

    if(dateTo != null) {
        parts.push(`date_to=${dateTo}`);
    }

    if(status) {
        if(status && status.length > 0) {
            if(status != '') {
                status = Array.isArray(status) ? status : [status];
                parts.push(`status=${status.join(',')}`);
            }
        }
    }

    if(origin){
        if(origin.length > 0) {
            if(origin != '') {
                origin = Array.isArray(origin) ? origin : [origin];
                parts.push(`origin=${origin.join(',')}`);
            }
        }
    }

    if(assigned){
        if(assigned.length > 0) {
            if(assigned != '') {
                assigned = Array.isArray(assigned) ? assigned : [assigned];
                parts.push(`assigned=${assigned.join(',')}`);
            }
        }
    }

    parts.push(`sort=${sort || "updated"}`);

    parts.push(`direction=${direction || "DESC"}`);

    let uri = `/api/v2/project/${project_id}/lead?` + parts.join('&');
    return httpManager.getHttpWithRequestingToken(uri);
}

/**
 * Fetches the lead summary for a given project within a specified date range.
 *
 * @param {number} project_id - The ID of the project.
 * @param {string|null} dateFrom - The start date for the summary in YYYY-MM-DD format. Can be null.
 * @param {string|null} dateTo - The end date for the summary in YYYY-MM-DD format. Can be null.
 * @returns {Promise} - A promise that resolves to the lead summary data.
 */
function getLeadSummary(project_id, dateFrom, dateTo) {

    let parts = [];
    if(dateFrom != null) {
        parts.push(`date_from=${dateFrom}`);
    }

    if(dateTo != null) {
        parts.push(`date_from=${dateTo}`);
    }

    let uri = `/api/v2/project/${project_id}/summary?` + parts.join('&');
    return httpManager.getHttpWithRequestingToken(uri);
}

/**
 * Generates a lead report URI and fetches the report data.
 *
 * @param {number} project_id - The ID of the project.
 * @param {Array<string>} status - An array of status strings to filter the report.
 * @param {string|null} dateFrom - The start date for the report filter (format: YYYY-MM-DD).
 * @param {string|null} dateTo - The end date for the report filter (format: YYYY-MM-DD).
 * @returns {Promise} - A promise that resolves with the fetched report data.
 */
function getLeadReport(project_id, status, dateFrom, dateTo) {
    let parts = [];

    if(status && status.length > 0) {
        parts.push(`status=${status.join(',')}`)
    }

    if(dateFrom != null && dateFrom != "Invalid date" && dateFrom != "") {
        parts.push(`date_from=${dateFrom}`);
    }

    if(dateTo != null && dateTo != "Invalid date" && dateTo != "") {
        parts.push(`date_to=${dateTo}`);
    }

    let uri = `/api/v2/project/${project_id}/report?` + parts.join('&');
    return httpManager.fetchHttpOnce(uri);
}

function getLeadGoogleSheetReport(project_id, status, dateFrom, dateTo) {
    let parts = [];

    if(status && status.length > 0) {
        parts.push(`status=${status.join(',')}`)
    }

    if(dateFrom != null && dateFrom != "Invalid date" && dateFrom != "") {
        parts.push(`date_from=${dateFrom}`);
    }

    if(dateTo != null && dateTo != "Invalid date" && dateTo != "") {
        parts.push(`date_from=${dateTo}`);
    }

    let uri = `/api/v2/project/${project_id}/report-googlesheet?` + parts.join('&');
    return httpManager.fetchHttpOnce(uri);
}

/**
 * Fetches the patient report data from the server.
 *
 * @returns {Promise} A promise that resolves to the patient report data.
 */
function getPatientReport() {
    let uri = `/custom/tham/leads-to-patient/data/download`;
    return httpManager.fetchHttpOnce(uri);
}

/**
 * Fetches the lead to patient conversion data.
 *
 * This function sends a GET request to the specified URI to retrieve
 * the lead to patient conversion data in the background.
 *
 * @returns {Promise} A promise that resolves with the response data.
 */
function getLeadToPatientConversion() {
    let uri = `/custom/tham/leads-to-patient/data/generate/background`;
    return httpManager.getHttpWithRequestingToken(uri);
}

/**
 * Creates a new lead in the project.
 * @param {number} project_id - The project ID.
 * @param {Object} lead - The lead data.
 * @returns {Promise} API response promise.
 */
function createLead(project_id, lead) {
    return httpManager.postJsonOnce(`/api/v2/project/${project_id}/lead`, lead);
}

/**
 * Finds a lead by its ID.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @returns {Promise} API response promise containing lead data.
 */
function find(project_id, lead_id) {
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}`);
}

/**
 * Updates an existing lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {Object} lead - The updated lead data.
 * @returns {Promise} API response promise.
 */
function updateLead(project_id, lead_id, lead) {
    return httpManager.putJsonOnce(`/api/v2/project/${project_id}/lead/${lead_id}`, lead);
}

/**
 * Deletes a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {Object} lead - The lead data to be deleted.
 * @returns {Promise} API response promise.
 */
function deleteLead(project_id, lead_id, lead) {
    return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}`, lead);
}

/**
 * Retrieves notes associated with a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} type_name - The type of notes to retrieve.
 * @returns {Promise} API response promise containing notes.
 */
function getLeadNotes(project_id, lead_id, type_name) {
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content/${type_name}`);
}

/**
 * Retrieves session activity for a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @returns {Promise} API response promise containing session data.
 */
function getLeadSession(project_id, lead_id) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${project_id}/lead/${lead_id}/user-activity`);
}

/**
 * Retrieves lead interest data.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @returns {Promise} API response promise containing interest data.
 */
function getLeadInterest(project_id, lead_id) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${project_id}/lead/${lead_id}/interest`);
}

/**
 * Adds a note to a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} note_type - The type of note.
 * @param {Object} note_data - The note content.
 * @returns {Promise} API response promise.
 */
function addLeadNote(project_id, lead_id, note_type, note_data) {
    return httpManager.postJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${note_type}`, note_data);
}

/**
 * Updates a specific note for a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} type_name - The note type.
 * @param {number} content_id - The ID of the note.
 * @param {Object} note_data - The updated note data.
 * @returns {Promise} API response promise.
 */
function updateLeadNote(project_id, lead_id, type_name, content_id, note_data) {
    return httpManager.putJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${type_name}/${content_id}`, note_data);
}

/**
 * Deletes a specific note for a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} type_name - The note type.
 * @param {number} content_id - The ID of the note.
 * @param {Object} note - The note data.
 * @returns {Promise} API response promise.
 */
function deleteLeadNote(project_id, lead_id, type_name, content_id, note) {
    return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content/${type_name}/${content_id}`, note);
}

/**
 * Adds a reply to a lead note.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {number} content_id - The ID of the note.
 * @param {Object} content - The reply content.
 * @returns {Promise} API response promise.
 */
function addNoteReply(project_id, lead_id, content_id, content) {
    return httpManager.postJsonOnce(`/api/v2/project/${project_id}/lead/${lead_id}/content/${content_id}/reply`, content);
}

/**
 * Updates a reply for a lead note.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {number} content_id - The ID of the note.
 * @param {number} reply_id - The ID of the reply.
 * @param {Object} content - The updated reply content.
 * @returns {Promise} API response promise.
 */
function updateNoteReply(project_id, lead_id, content_id, reply_id, content) {
    return httpManager.putJsonOnce(`/api/v2/project/${project_id}/lead/${lead_id}/content/${content_id}/reply/${reply_id}`, content);
}

/**
 * Deletes a reply from a lead note.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {number} content_id - The ID of the note.
 * @param {number} reply_id - The ID of the reply.
 * @returns {Promise} API response promise.
 */
function deleteNoteReply(project_id, lead_id, content_id, reply_id) {
    return httpManager.deleteHTTPOnce(`/api/v2/project/${project_id}/lead/${lead_id}/content/${content_id}/reply/${reply_id}`);
}

/**
 * Updates the status of a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} status - The new status.
 * @returns {Promise} API response promise.
 */
function updateLeadStatus(project_id, lead_id, status) {
    return httpManager.putJsonOnce(`/api/project/${project_id}/lead/${lead_id}/status/${status}`);
}

/**
 * Retrieves all content (tasks, notes, etc.) for a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @returns {Promise} API response promise containing content.
 */
function getAllContent(project_id, lead_id) {
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content`);
}

/**
 * Creates content (task/note) for a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} type - The type of content (task/note).
 * @param {Object} content - The content data.
 * @returns {Promise} API response promise.
 */
function createContent(project_id, lead_id, type, content) {
    return httpManager.postJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${type}`, content);
}

/**
 * Finds specific content by its type and ID.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} type - The content type.
 * @param {number} type_id - The content ID.
 * @returns {Promise} API response promise containing the content.
 */
function findContent(project_id, lead_id, type, type_id) {
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content/${type}/${type_id}`);
}

/**
 * Updates content of a specific type and ID.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} type - The content type.
 * @param {number} type_id - The content ID.
 * @param {Object} content - The updated content data.
 * @returns {Promise} API response promise.
 */
function updateContent(project_id, lead_id, type, type_id, content) {
    return httpManager.putJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${type}/${type_id}`, content);
}

/**
 * Deletes specific content.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} type - The content type.
 * @param {number} type_id - The content ID.
 * @param {Object} content - The content data.
 * @returns {Promise} API response promise.
 */
function deleteContent(project_id, lead_id, type, type_id, content) {
    return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/content/${type}/${type_id}`, content);
}

/**
 * Enables or disables content by status.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @param {string} type - The content type.
 * @param {number} type_id - The content ID.
 * @param {string} status - The new status.
 * @returns {Promise} API response promise.
 */
function enableContent(project_id, lead_id, type, type_id, status) {
    return httpManager.putJsonOnce(`/api/project/${project_id}/lead/${lead_id}/content/${type}/${type_id}/status/${status}`);
}

/**
 * Imports leads into a project.
 * @param {number} project_id - The project ID.
 * @param {number} form_id - The form ID used for import.
 * @param {Object[]} leads - The array of lead objects.
 * @returns {Promise} API response promise.
 */
function importLeads(project_id, form_id, leads) {
    return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/lead/import`, leads);
}

/**
 * Retrieves all imported data for a project.
 * @param {number} project_id - The project ID.
 * @returns {Promise} API response promise containing imported data.
 */
function getAllImportData(project_id) {
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/import`);
}

/**
 * Retrieves details of a specific import operation.
 * @param {number} project_id - The project ID.
 * @param {number} import_id - The import ID.
 * @returns {Promise} API response promise containing import details.
 */
function findImportData(project_id, import_id) {
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/import/${import_id}`);
}

/**
 * Retrieves the activity log of a lead.
 * @param {number} project_id - The project ID.
 * @param {number} lead_id - The lead ID.
 * @returns {Promise} API response promise containing activity log data.
 */
function getActivityLog(project_id, lead_id) {
    return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/lead/${lead_id}/activities`);
}

/**
 * Retrieves the origin sources for a project.
 * @param {number} project_id - The project ID.
 * @returns {Promise} API response promise containing origin sources.
 */
function getOrigin(project_id) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${project_id}/origins`);
}

/**
 * Sends a Calendly invite to a lead.
 * @param {number} projectId - The project ID.
 * @param {number} leadId - The lead ID.
 * @param {Object} calendlyRequest - The invite details.
 * @returns {Promise} API response promise.
 */
function sendCalendlyInvite(projectId, leadId, calendlyRequest) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/lead/${leadId}/calendly/schedule/invite`, calendlyRequest);
}

/**
 * Performs a batch selection of leads.
 * @param {number} projectId - The project ID.
 * @param {Object} batchRequest - The batch selection criteria.
 * @returns {Promise} API response promise.
 */
function batchSelectLeads(projectId, batchRequest) {
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead`, batchRequest);
}

/**
 * Uploads Kalibrr leads using form data.
 * @param {number} project_id - The project ID.
 * @param {FormData} formdata - The form data containing leads.
 * @returns {Promise} API response promise.
 */
function uploadKalibrrLeads(project_id, formdata) {
    return httpManager.postFormDataOnce(`/api/v2/project/${project_id}/leads/kalibrr`, formdata);
}

function uploadCsvLeads(project_id, formdata){
    return httpManager.postFormDataOnce(`/api/v2/project/${project_id}/leads/csv`, formdata);
}
