import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalBody } from 'mdbreact';
import { connect } from "react-redux";
import { influencerActions } from "_actions";

const ConfirmationDeleteModal = ({ 
    isOpen, 
    onHandleCloseConfirmationDeleteModal, 
    influencerId, 
    projectId, 
    deleteInfluencerPlatform, 
    deleteInfluencer, 
    deleteInfluencerDocument, 
    deleteInfluencerRate, 
    data, 
    deleteInfluencerNote, 
    type 
}) => {

    const handleCloseConfirmationDeleteModal = () => {
        onHandleCloseConfirmationDeleteModal();
    };

    const handleDeleteDataConfirm = () => {
        switch (type) {
            case 'influencer':
                deleteInfluencer(projectId, influencerId);
                break;
            case 'platform':
                deleteInfluencerPlatform(projectId, influencerId, data.id);
                break;
            case 'record':
                deleteInfluencerDocument(projectId, influencerId, data.id);
                break;
            case 'note':
                deleteInfluencerNote(projectId, influencerId, data.id);
                break;
            case 'rate':
                deleteInfluencerRate(projectId, influencerId, data.id);
                break;
            default:
                break;
        }
        handleCloseConfirmationDeleteModal();
    };

    let identifier = '';
    switch (type) {
        case 'influencer':
            identifier = `(${data.name})`;
            break;
        case 'platform':
            identifier = `(${data.type.name}) - ${data.link}`;
            break;
        case 'record':
            identifier = `(${data.title}) - ${data.link}`;
            break;
        case 'note':
            identifier = `(${data.title})`;
            break;
        case 'rate':
            identifier = `this rate`;
            break;
        default:
            break;
    }

    return (
        <MDBContainer className='modal-container confirmation-modal-container'>
            <MDBRow>
                <MDBCol>
                    <MDBModal className='confirmation-modal' isOpen={isOpen} centered tabIndex='-1'>
                        <MDBModalBody>
                            <MDBRow className='form-group-row'>
                                <MDBCol className='col-12'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle">
                                        <circle cx="12" cy="12" r="10"/>
                                        <line x1="12" y1="8" x2="12" y2="12"/>
                                        <line x1="12" y1="16" x2="12.01" y2="16"/>
                                    </svg>
                                    <h3>Confirm Deletion</h3>
                                    <p>Are you sure you want to delete this entry <br /><span>{identifier}</span> ?</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className='modal-btns text-center'>
                                    <button className='c-btn c-btn-light' onClick={handleCloseConfirmationDeleteModal}>
                                        Cancel
                                    </button>
                                    <button className='c-btn c-btn-red' onClick={handleDeleteDataConfirm}>
                                        Delete
                                    </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

const mapState = (state) => {
    const { influencers } = state;
    return { influencers };
};

const actionCreators = {
    deleteInfluencerPlatform: influencerActions.deleteInfluencerPlatform,
    deleteInfluencerNote: influencerActions.deleteInfluencerNote,
    deleteInfluencerDocument: influencerActions.deleteInfluencerDocument,
    deleteInfluencerRate: influencerActions.deleteInfluencerRate,
    deleteInfluencer: influencerActions.deleteInfluencer
};
const connectedConfirmationDeleteModal = connect(mapState, actionCreators)(ConfirmationDeleteModal);
export { connectedConfirmationDeleteModal as ConfirmationDeleteModal };