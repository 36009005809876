import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { influencerActions } from '_actions';
import Button from '_pages/Influencer/components/General/Button';
import { utilitiesService } from '_services';
import Moment from 'react-moment';

export const NoteTab = ({ classN, projectId, influencerId, onHandleManageNotesModal, onHandleOpenConfirmationModal }) => {
    const dispatch = useDispatch();
    const getInfluencerNoteLoaded = useSelector(state => state.influencers.getInfluencerNoteLoaded);
    const getInfluencerNoteLoading = useSelector(state => state.influencers.getInfluencerNoteLoading);
    const notes = useSelector(state => state.influencers.notes);

    useEffect(() => {
        dispatch(influencerActions.getInfluencerNotes(projectId, influencerId));
    }, [dispatch, projectId, influencerId]);

    const openAddNotesModal = () => onHandleManageNotesModal('add', null);
    const openEditNotesModal = (note) => onHandleManageNotesModal('edit', note);
    const openDeleteConfirmationModal = (type, note) => onHandleOpenConfirmationModal(type, note);

    return (
        <div className={classN}>
            <div className='inner-title-box'>
                <p>Notes</p>
                <Button
                    text='Add Note'
                    icon='icon-lms icon-add'
                    onClick={openAddNotesModal}
                    classN='plain-btn'
                />
            </div>
            {getInfluencerNoteLoaded && notes.length > 0 ? (
                <div className='notes-list'>
                    {notes.map((note, index) => {
                        const creator = `${utilitiesService.decrypt(note.creator.firstName)} ${utilitiesService.decrypt(note.creator.lastName)}`;
                        return (
                            <div key={index} className='notes'>
                                <div className='note-details'>
                                    <p className='title'>{note.title}</p>
                                    <p className='author'>{creator}</p>
                                    <p className='notes-description'>{note.content}</p>
                                    <span>
                                        Note added: <strong><Moment date={note.created} fromNow ago /> ago</strong>
                                        {note.updated && <> • Last Updated: <strong><Moment date={note.updated} fromNow ago /> ago</strong></>}
                                    </span>
                                </div>
                                <div className='note-btns'>
                                    <button className='c-btn c-btn-sm c-btn-light edit-record' onClick={() => openEditNotesModal(note)}>
                                        <i className='icon-lms icon-edit'></i>Edit
                                    </button>
                                    <button className='c-btn c-btn-sm c-btn-light delete-record' onClick={() => openDeleteConfirmationModal('note', note)}>
                                        <i className='icon-lms icon-delete'></i>Delete
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : getInfluencerNoteLoaded ? (
                <div className='empty-tab-content'>
                    <div className='empty-content'>
                        <h6>Add Your First Note</h6>
                    </div>
                </div>
            ) : getInfluencerNoteLoading ? (
                <div className='loading-box'>
                    <div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
                </div>
            ) : null}
        </div>
    );
};