import React from 'react';
import { connect } from "react-redux";
import { formActions } from '_actions';

import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import InputForm from '_components/InputForm'
import { InputField, InputSelect } from "blackbox-react";
import ReactSlider from 'react-slider';
import DatePicker from 'react-datepicker';

import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal'

import { css, cx } from 'emotion';
import addIcon from '_assets/images/plus.png';

class FormFieldManage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formIndex: {},
			activeFormIndex: '',
			dropIcon: 'caret-square-up',
			switchEnabled: true,
			dropdownDiv: true,
			isFieldSelected: false,
			isExpanded: true,
			isSelectFieldExpanded: false,
			selectedFieldIndex: 0,
			visibleFormGroups: {},
			activeFormField: this.props.activeFormField,
			startDate: new Date(),
			fieldToDelete: '',
			date: '',
			slider: 0,
			dropdownSelect: '',
			checkbox: '',
			radio: ''
		};

		this.editField = this.editField.bind(this);
		this.handleActiveFormField = this.handleActiveFormField.bind(this);
	}

	handleToggle = () =>{
		let { modalOpen } = this.state;
		this.setState({modalOpen: !modalOpen})
	};


	handlePassData = (data) => {
		this.setState({
			fieldToDelete: data
		})
	};

	approveModal = (fieldToDelete) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		let fieldId = fieldToDelete.id;

		const deleteFieldData = {
			label: fieldToDelete.label,
			placeholder: fieldToDelete.placeholder,
			typeId: fieldToDelete.typeId
		};

		this.props.deleteField(projectId, formId, formGroupId, fieldId, deleteFieldData);

		this.setState({
			fieldData: {},
			fieldToDelete: {}
		});
	};

	indexCallback = data => {
		this.props.indexToDelete(data);
	};

	inputCallback = (data, index) => {
		this.props.inputFieldsCallback(data, index);
	};

	getInputIndex = data => {
		let edit = 'edit';
		this.props.inputIndex(data, edit);
		this.setState({ activeFormIndex: data.formIndex });
	};

	deleteInputField = data => {
		this.props.getDeleteInputField(data);
	};

	editForm = data => {
		this.props.getEditForm(data);
	};

	editField = (data, formGroupId) => {
		this.props.getEditFormField(data, formGroupId);
	};

	moveUp = (newIndex, oldIndex) => {
		this.props.moveUp(newIndex, oldIndex)
	};

	moveInputUp = (newIndex, oldIndex, formIndex) => {
		this.props.moveInputUp(newIndex, oldIndex, formIndex)
	};

	getFormFieldButtons = (value) => {
		this.props.getFormFieldButtons(value);
	};

	handleAccordionToggle = (formGroupId) => {
		let isExpanded = this.state.isExpanded;

		isExpanded ?
			this.setState({ isExpanded: false }) :
			this.setState({ isExpanded: true })
	};

	/*--------------------------------*/

	mapTypeId = (typeId, fieldData) => {
		switch (typeId) {
			case 1:
				return this.renderTextInput(fieldData);
			case 2:
				return this.renderTextarea(fieldData);
			case 3:
				return this.renderRadioButton(fieldData);
			case 4:
				return this.renderCheckbox(fieldData);
			case 5:
				return this.renderSelect(fieldData);
			case 6:
				return this.renderSwitch(fieldData);
			case 7:
				return this.renderSlider(fieldData);
			case 8:
				return this.renderDatepicker(fieldData);
			default:
				return null;
		}
	};

	renderTextInput = (fieldData) => {
		return (
			<div className="form-field">
				{ fieldData.hide_label ?
					<div className="mb-2"> </div> :
					<label htmlFor={fieldData.id}>
						{fieldData.label} {fieldData.required && <small className='color-red'> </small>}
					</label>
				}
				<InputForm
					type='text'
					id={fieldData.id}
					name={fieldData.id}
					inputclassName={`${fieldData.read_only && 'disabled'} font-weight-600`}
					hint={fieldData.placeholder}
					onChange={(e) => {
						e.preventDefault();
						e.currentTarget.value = '';
					}}
				/>
				{ fieldData.inline_instruction &&
				<span className='ml-1 font-size-10 color-gray'>{fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}</span>
				}
			</div>
		);
	};

	renderTextarea = (fieldData) => {
		return (
			<div className="form-field">
				{ fieldData.hide_label ?
					<div className="mb-2"> </div> :
					<label htmlFor={fieldData.id}>
						{fieldData.label} {fieldData.required && <small className='color-red'> </small>}
					</label>
				}
				<textarea
					name={fieldData.id}
					value=''
					id='textarea-description'
					disabled={fieldData.read_only || ''}
					className='py-1 px-2 font-size-12'
					rows='7'
					placeholder={fieldData.placeholder}
					onChange={(v) => {}}
				/>
				{ fieldData.limited_character &&
				<div>
           <span className='font-size-12'>
            Maximum number of characters:
            <b className='color-black'> {fieldData.character_limit || 0}</b>
          </span>
				</div>
				}
				{ fieldData.inline_instruction &&
				<span className='ml-1 font-size-10 color-gray'>{fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}</span>
				}
			</div>
		);
	};

	renderRadioButton = (fieldData) => {
		let options = fieldData.option;
		options = options.sort((a, b) => a.id > b.id); //oldest first
		let hasOptions = options.length > 0;

		return (
			<div className="form-field">
				{ fieldData.hide_label ?
					<div className="mb-2"> </div> :
					<label htmlFor={fieldData.id}>
						{fieldData.label} {fieldData.required && <small className='color-red'> </small>}
					</label>
				}
				{ hasOptions ?
					options.map(
						(value, index) => {
							return(
								<div key={index} className="radio-buttons">
									<InputField
										type='radio'
										label={value.option}
										id={`radio${value.id}`}
										name={`radio${fieldData.id}`}
										disabled={fieldData.read_only || ''}
										checked={ this.state.radio === `radio${fieldData.id}` ? 'checked' : index === 0 ? 'checked' : ''}
										onChange={() => {}}
									/>
								</div>
							);
						}) :
					<div className='dotted add-field-background'>
						<p className='p--md color-gray'>
							Select this box to add options to your <br/>
							<MDBIcon icon='dot-circle' className='mr-2 font-size-12' />
							<b>single choice / radio button</b> field.
						</p>
					</div>
				}
				{ fieldData.inline_instruction &&
				<span className='ml-1 font-size-10 color-gray'>{fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}</span>
				}
			</div>
		);
	};

	renderCheckbox = (fieldData) => {
		let options = fieldData.option;
		options = options.sort((a, b) => a.id > b.id); //oldest first
		let hasOptions = options.length > 0;

		return (
			<div className="form-field">
				{ fieldData.hide_label ?
					<div className="mb-2"> </div> :
					<label htmlFor={fieldData.id}>
						{fieldData.label} {fieldData.required && <small className='color-red'> </small>}
					</label>
				}
				{ hasOptions ?
					options.map(
						(value, index) => {
							return(
								<div key={index} className="radio-buttons">
									<InputField
										type='checkbox'
										label={value.option}
										id={`check${value.id}`}
										name={`check${value.id}`}
										disabled={fieldData.read_only || ''}
										checked={ index === 0 ? 'checked' : ''}
										onChange={() => {}}
									/>
								</div>
							);
						}) :
					<div className='dotted add-field-background'>
						<p className='p--md color-gray'>
							Select this box to add options to your <br/>
							<MDBIcon icon='check-square' className='mr-2 font-size-12' />
							<b>multiple choice / checkbox</b> field.
						</p>
					</div>
				}
				{ fieldData.inline_instruction &&
				<span className='ml-1 font-size-10 color-gray'>{fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}</span>
				}
			</div>
		);
	};

	renderSelect = (fieldData) => {
		let options = fieldData.option;
		if(options === null){
			options = [];
		} else{
			options = options.sort((a, b) => a.id > b.id); //oldest first
		}

		let hasOptions = options.length > 0;
		let selectOptions = [];

		if(hasOptions){
			options.map(
				(value, index) => {
					selectOptions.push({
						id: value.id,
						value: value.option,
						label: value.option
					});
					return selectOptions;
				})
		}

		return(
			<div className="form-field">
				{ fieldData.hide_label ?
					<div className="mb-2"> </div> :
					<label htmlFor={fieldData.id}>
						{fieldData.label} {fieldData.required && <small className='color-red'> </small>}
					</label>
				}
				{ hasOptions ?
					<InputSelect
						name={fieldData.label || ''}
						placeholder={fieldData.placeholder}
						value={this.state.dropdownSelect}
						disabled={fieldData.read_only || ''}
						onChange={(v) => this.setState({ dropdownSelect: v })}
						options={selectOptions}
					/>
					:
					<div className='dotted add-field-background'>
						<p className='p--md color-gray'>
							Select this box to add options to your <br/>
							<MDBIcon icon='chevron-circle-down' className='mr-2 font-size-12' />
							<b>dropdown selection</b> field.
						</p>
					</div>
				}
				{ fieldData.inline_instruction &&
				<span className='ml-1 font-size-10 color-gray'>{fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}</span>
				}
			</div>
		);
	};

	renderSwitch = (fieldData) => {
		const switchCss = cx(
			css`
        &:before {
          content: '${fieldData.on_label || 'ON'}';
        }
           
        &:after{
          content: '${fieldData.off_label || 'OFF'}';
        }
      `
		);
		return (
			<div className="form-field">
				{ fieldData.hide_label ?
					<div className="mb-2"> </div> :
					<label htmlFor={fieldData.id}>
						{fieldData.label} {fieldData.required && <small className='color-red'> </small>}
					</label>
				}
				<div className="switch">
					<input
						checked={fieldData.default_value !== 0}
						type='checkbox'
						name={`switch${fieldData.id}`}
						className={`${fieldData.read_only && 'disabled'} switch-checkbox`}
						disabled={fieldData.read_only || ''}
						onChange={(e) => { this.setState({ switchEnabled: !this.state.switchEnabled }) }}
						id={`switch${fieldData.id}`} />
					<label className='switch-labels' htmlFor={`switch${fieldData.id}`}>
						<span className={`switch-text ${switchCss} ${fieldData.read_only && 'disabled'}`}> </span>
						<span className='switch-dot'> </span>
					</label>
				</div>
				{ fieldData.inline_instruction &&
				<span className='ml-1 font-size-10 color-gray'>{fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}</span>
				}
			</div>
		);
	};

	renderSlider = (fieldData) => {
		return (
			<div className="form-field">
				{ fieldData.hide_label ?
					<div className="mb-2"> </div> :
					<label htmlFor={fieldData.id}>
						{fieldData.label} {fieldData.required && <small className='color-red'> </small>}
					</label>
				}
				<div className="slider-container">
					<div className="slider-min">
						<small>{fieldData.min_value || 0}</small>
					</div>
					<div className="slider-input">
						<ReactSlider
							className="horizontal-slider"
							thumbClassName="example-thumb"
							trackClassName="example-track"
							defaultValue={fieldData.default_value || 0}
							disabled={fieldData.read_only}
							value={fieldData.default_value || 0}
							min={fieldData.min_value || 0}
							max={fieldData.max_value || 100}
							renderThumb={(props, state) => <div {...props}>{}</div>}
						/>
					</div>
					<div className="slider-max">
						<small>{fieldData.max_value || 100}</small>
					</div>
				</div>
				<div className="slider-value m--auto">
          <span className='font-size-12'>
            Selected value:
            <b className='color-black'> {fieldData.default_value || 0} {fieldData.on_label || ''}</b>
          </span>
				</div>
				{ fieldData.inline_instruction &&
				<span className='ml-1 font-size-10 color-gray'>{fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}</span>
				}
			</div>
		);
	};

	handleDatePickerChange = (date) => {
		this.setState({
			startDate: date
		});
	};

	renderDatepicker = (fieldData) => {
		let dateFormat =`${fieldData.date_format || 'MMMM d, yyyy'} ${fieldData.time_input ? (fieldData.time_format || 'hh:mm aa') : '' }`;
		let timeFormat = fieldData.time_format || 'hh:mm aa';

		return (
			<div className="form-field">
				{ fieldData.hide_label ?
					<div className="mb-2"> </div> :
					<label htmlFor={fieldData.id}>
						{fieldData.label} {fieldData.required && <small className='color-red'> </small>}
					</label>
				}
				<div>
					<DatePicker
						selected={fieldData.default_date || this.state.startDate}
						onChange={this.handleDatePickerChange}
						showTimeSelect={!!fieldData.time_input}
						timeFormat={timeFormat}
						disabled={fieldData.read_only || ''}
						timeIntervals={30}
						timeCaption="Time"
						dateFormat={dateFormat}
					/>
				</div>
				{ fieldData.inline_instruction &&
				<span className='ml-1 font-size-10 color-gray'>{fieldData.instruction || 'This is a sample inline instruction about this field. Replace this with your own.'}</span>
				}
			</div>
		);
	};

	/*--------------------------------*/

	handleActiveFormField = (activeFormField) => {
		this.props.handleActiveFormField(activeFormField);
	};

	handleFormFieldReorder = (data) => {

		const formGroupId = this.props.formGroupId;
		const formGroupData = this.props.formGroupData;
		let formFields = formGroupData.field;
		let originFormField = data.originFormField;
		let targetFormField = data.targetFormField;


		let searchTargetFormField = formFields.filter((o) => { return o.form_order === targetFormField.form_order });
		targetFormField = searchTargetFormField[0];


		if(typeof targetFormField !== 'undefined'){
			let originOrder = originFormField.form_order;
			let targetOrder = targetFormField.form_order;

			originFormField = {
				id: originFormField.id,
				form_order: targetOrder
			};

			targetFormField = {
				id: targetFormField.id,
				form_order: originOrder
			};

			let reorderRequestData = {
				reorder: [originFormField, targetFormField]
			};
			this.props.handleFormFieldReorder(reorderRequestData, formGroupId);
		}
	};


	handleAddFormField = (index) => {
		this.setState({
			isSelectFieldExpanded: true,
			selectedFieldIndex: index
		});
	};

	renderFormFields = (value, index, totalNum, formGroupId) => {
		let activeFormField = `formField${value.id}`;

		return (
			<MDBRow
				key={index} id={activeFormField}
				onClick={(e) => {
					e.stopPropagation();
					activeFormField = this.state.activeFormField !== activeFormField ||  this.state.activeFormField !== '' ? `formField${value.id}` : '';
					this.setState({
						activeFormField: activeFormField
					}, () => {
						this.handleActiveFormField(activeFormField);
						this.props.getEditFormField(value, formGroupId);
					});
				}}
			>
				<MDBCol xl='12' className='form-builder__add add-form-field'>
					{ this.renderAddFormField(value, index + 1, totalNum) }
				</MDBCol>
				<MDBCol xl='12' id={`inputFieldsArea${value.id}`}>
					<MDBRow className='form-builder__container form-field'>
						<MDBCol xl='11' className='p-l--0 p-r--0'>
							<div onClick={(e) => { e.preventDefault(); this.editField(value, formGroupId); }}>
								{this.mapTypeId(value.type_id, value)}
							</div>
						</MDBCol>

						<MDBCol xl='1' lg='1' className='text-center p--0'>
							{ this.renderFormFieldReorder(value, index, totalNum) }
							{ this.renderFormFieldActions(value, index) }
						</MDBCol>
					</MDBRow>
				</MDBCol>
				<MDBCol xl='12' className='form-builder__add add-form-field'>
					{ index + 1 === totalNum ? this.renderAddFormField(value, index + 2, totalNum) : null }
				</MDBCol>
			</MDBRow>
		);
	};

	handleCreateFormField = (value, index, totalNum) => {
		let formGroupData = this.props.formGroupData;
		this.props.handleActiveFormGroup(`formGroup${formGroupData.id}`);
		this.getFormFieldButtons(value);
		localStorage.setItem('fieldIndex', index);
		localStorage.setItem('fieldTotalNum', totalNum);
	};

	renderAddFormField = (value, index, totalNum, toggle) => {
		let isSelectFieldExpanded = this.state.isSelectFieldExpanded;
		let selectedFieldIndex = this.state.selectedFieldIndex;
		let selectedInput = this.props.selectedInput;

		return (
			<div>
				<div className={ isSelectFieldExpanded && (selectedFieldIndex === index) ? (selectedInput ? 'display-hover' : 'display-visible') : 'display-hover'}>
					<p
						id='add'
						className='font-size-12 mb-0 color-orange'
						onClick={(e) => {
							e.stopPropagation();
							this.props.handleActiveFormField('');
							this.handleAddFormField(index);
							this.handleCreateFormField(value, index, totalNum);
							this.props.handleSelectedInput(false);
						}}
					>
						<img alt='' src={addIcon} className='add-icon-width-18 mr-1' />
						Add Field
					</p>
				</div>
				{/*{ isSelectFieldExpanded && (selectedFieldIndex === index) ?*/}
				{/*  <MDBCol xl='11' lg='11' className={ this.state.isExpanded ? 'px-0 m-t--md m-b--md' : 'display-none'}>*/}
				{/*    <MDBBtn*/}
				{/*      id={`selectFieldToAdd${index}`}*/}
				{/*      onClick={(e) => {*/}
				{/*        e.stopPropagation();*/}
				{/*        this.handleCreateFormField(value, index, totalNum);*/}
				{/*      }}*/}
				{/*      flat*/}
				{/*      className='ml-3 dotted add-field-button add-field-background m-0 mb-1 color-orange shadow-none button-presets'*/}
				{/*    >*/}
				{/*      <img alt='' src={addIcon} className='add-icon-width-18 mr-2'/>*/}
				{/*      Select a Field to Add*/}
				{/*    </MDBBtn>*/}
				{/*  </MDBCol>*/}
				{/*  :*/}
				{/*  <div className={index === value.length - 1 ? 'display-invisible' : 'display-hover'}>*/}
				{/*    <p*/}
				{/*      id='add'*/}
				{/*      className='font-size-12 mb-0 color-orange'*/}
				{/*      onClick={(e) => {*/}
				{/*        e.stopPropagation();*/}
				{/*        this.props.handleActiveFormField('');*/}
				{/*        this.handleAddFormField(index);*/}
				{/*        this.handleCreateFormField(value, index, totalNum);*/}
				{/*      }}*/}
				{/*    >*/}
				{/*      <img alt='' src={addIcon} className='add-icon-width-18 mr-1' />*/}
				{/*      Add Field*/}
				{/*    </p>*/}
				{/*  </div>*/}
				{/*}*/}
			</div>
		);
	};

	renderFormFieldReorder = (value, index, totalNum) => {
		return (
			<MDBRow className='form-group__reorder'>
				<MDBIcon
					icon='caret-up'
					className={index !== 0 || index + 1 === totalNum ? 'icon-move--up hand' : 'icon-move--up disabled color-gray'}
					onClick={(e) => {
						this.handleFormFieldReorder({
							originFormField: value,
							targetFormField: {
								form_order: value.form_order - 1
							},
							direction: 'up'
						});
					}}
				/>

				<MDBIcon
					icon='caret-down'
					className={index === value.length - 1 || index + 1 === totalNum ? 'icon-move--down disabled color-gray' : 'icon-move--down hand'}
					onClick={(e) => {
						this.handleFormFieldReorder({
							originFormField: value,
							targetFormField: {
								form_order: value.form_order + 1
							},
							direction: 'down'
						});
					}}
				/>
			</MDBRow>
		);
	};

	renderFormFieldActions = (value, index) => {
		let actionIconClass = this.state.dropdownDiv ? 'px-0 action-icons' : 'hide';

		return (
			<MDBRow className='form-group__actions'>
				<MDBCol className={actionIconClass}>
					<MDBCol className='px-0'>
						<MDBIcon
							far
							icon='trash-alt'
							className='icon-delete color-red opacity-5 hand'
							onClick={(event) => { event.preventDefault(); this.handleToggle(); this.handlePassData(value) }}
						/>
					</MDBCol>
				</MDBCol>
			</MDBRow>
		);
	};

	render() {
		let formGroupData = this.props.formGroupData;
		let formFieldData = this.props.formFieldData;
		let formFieldKey = this.props.formFieldKey;
		let totalFormFields = this.props.totalFormFields;
		let activeFormField = this.props.activeFormField;
		let hasData = !!formFieldData;
		let formFieldId = `formField${formFieldData.id}`;

		return (
			<div>
				<div className={`form-field-group ${(formFieldId === activeFormField) ? 'active' : ' '}`}>
					{ hasData ? this.renderFormFields(formFieldData, formFieldKey, totalFormFields, formGroupData.id) : null }
				</div>
				<DeleteConfirmationModal
					toggle={this.handleToggle}
					modalOpen={this.state.modalOpen}
					statusToDelete={this.state.fieldToDelete}
					onApprove={this.approveModal}
					target={formFieldData.label}
					nameToDelete={formFieldData.label}
				/>
			</div>
		);
	}
}

function mapState(state) {
	const { creating, forms, deleting, deleted } = state.forms;
	return { creating, forms, deleting, deleted };
}

const actionCreators = {
	deleteField: formActions.deleteField
};

const connectedFormFieldManage = connect(mapState, actionCreators)(FormFieldManage);
export { connectedFormFieldManage as FormFieldManage };
