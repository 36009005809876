import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { leadActions } from "_actions";

const LeadStatusDropdown = ({ page, summaryLoaded, lead, onHandleUpdateLeadStatus, selectedStatus }) => {
  const [filterToggled, setFilterToggled] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const filterRef = useRef(null);

  useEffect(() => {
    const handleDropdown = (value) => {
      if (!filterRef.current.contains(value.target)) {
        setFilterToggled(false);
      }
    };

    document.addEventListener("click", handleDropdown, true);

    return () => {
      document.removeEventListener("click", handleDropdown, true);
    };
  }, []);

  useEffect(() => {
    if (!Array.isArray(checkedItems) || JSON.stringify(checkedItems) !== JSON.stringify(selectedStatus)) {
      setCheckedItems(selectedStatus);
    }
  }, [selectedStatus]);

  const handleOpenFilter = () => {
    setFilterToggled(true);
  };

  const handleCloseFilter = () => {
    setFilterToggled(false);
  };

  const handleUpdateLeadStatus = (value) => {
    onHandleUpdateLeadStatus(value);
    setFilterToggled(false);
  };

  let items = [];
  let categories = [];

  if (summaryLoaded) {
    items = page.data.reduce(function (collection, elem) {
      let key = elem["category"];
      if (!categories.includes(key)) {
        categories.push(key);
      }

      (collection[key] = collection[key] || []).push(elem);
      return collection;
    }, []);
  }

  const status = lead.project_status;
  const category = status.category;
  const statusColor = categories.indexOf(category) + 1;

  return (
    <div className="filter-box" ref={filterRef}>
      <div className="filter-btn status-btn">
        <span
          className={`status-color-${statusColor}`}
          onClick={handleOpenFilter}
        >
          {lead.project_status.name.replace(/\b\w/g, (l) => l.toUpperCase())}
        </span>
      </div>
      <div
        className={filterToggled ? "filter-dropdown active" : "filter-dropdown"}
      >
        <div className="filter-title">
          <p>Status</p>
          <i className="icon-lms icon-close" onClick={handleCloseFilter}></i>
        </div>
        <div className="filter-list status-list">
          {categories.map((value, index) => {
            let color = index + 1;
            return (
              <div className="filter-group" key={index}>
                <span className={`filter-name filter-color-${color}`}>
                  {value}
                </span>
                {items[value].map((item, index) => (
                  <div
                    key={`filter-dropdown-${index}`}
                    className="filter-name-list"
                    onClick={() => handleUpdateLeadStatus(item.id)}
                  >
                    <span>{item.label}</span>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  const { page, summaryLoaded } = state.summary;
  return { page, summaryLoaded };
};

const actionCreators = {
  getLeadSummary: leadActions.getLeadSummary,
};

const connectedViewFilterDropdown = connect(
  mapState,
  actionCreators
)(LeadStatusDropdown);

export { connectedViewFilterDropdown as LeadStatusDropdown };
