import {leadConstants} from "_constants";

/**
 * Reducer function to handle summary-related actions.
 *
 * @param {Object} state - The current state of the summary.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.summary] - The summary data (for GET_SUMMARY_SUCCESS).
 * @param {Object} [action.origin] - The origin data (for GET_ORIGIN_SUCCESS).
 * @param {Object} [action.error] - The error object (for GET_SUMMARY_FAILURE and GET_ORIGIN_FAILURE).
 * @returns {Object} The new state after applying the action.
 */
export function summary(state = {}, action) {
    switch (action.type) {
        /*
       GET LEAD SUMMARY
        */
        case leadConstants.GET_SUMMARY_REQUEST:
            return {
                ...state,
                loadingHeader: true,
                summaryLoaded: false
            };
        case leadConstants.GET_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingHeader: false,
                page: action.summary,
                summaryLoaded: true
            };
        case leadConstants.GET_SUMMARY_FAILURE:
            return {
                ...state,
                error: action.error,
                loadingHeader: false,
                summaryLoaded: true
            };

        case leadConstants.GET_ORIGIN_REQUEST:
            return {
                ...state,
                originLoading: true,
                originLoaded: false
            };
        case leadConstants.GET_ORIGIN_SUCCESS:
            return {
                ...state,
                originLoading: false,
                originLoaded: true,
                origin: action.origin.data
            };
        case leadConstants.GET_ORIGIN_FAILURE:
            return {
                ...state,
                originLoading: false,
                originLoaded: true,
                error: action.error
            };

        default:
            return state

    }
}
