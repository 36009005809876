import React, { useEffect } from "react";
import { connect } from "react-redux";
import { leadActions } from "_actions";
import { utilitiesService as utility } from "_services";
import { MDBIcon } from "mdbreact";
import Moment from "react-moment";

const LeadViewRecentActivities = ({ leads, activityLogLoaded, activityLog, getActivityLog }) => {
  
  // useEffect(() => {
  //   getActivityLog();
  // }, [getActivityLog]);

  const renderActivityLog = ({ name, action, sender, created }, isLastLog) => {
    let origin = '';
    let icon = '';
    let logLabel = '';
    let activityLog = '';
    let needDecrypt = false;
    let hasLink = false;
    let link = '';
    let hasAvatar = false;
    
    // Extract lead data if it exists
    if (leads.leadLoaded) {
      origin = leads.lead.data.origin;
    }

    // Mapping different activity types to relevant labels and actions
    switch (name) {
      case 'LEAD_CREATED':
        logLabel = `Lead generated from `;
        activityLog = origin;
        icon = 'user';
        break;
      case 'LEAD_IMPORTED':
        logLabel = 'Lead Imported to ';
        activityLog = action.replace('Lead imported to ', '');
        icon = 'user';
        break;
      case 'LEAD_SUBMITTED':
        logLabel = 'Lead Submitted to ';
        activityLog = action.replace('Lead submitted to ', '');
        icon = 'user';
        break;
      case 'BECAME_LEAD':
        logLabel = 'Became ';
        activityLog = action.replace('Became', '').replace('Lead', '');
        icon = 'user';
        break;
      case 'OWNER_ASSIGNED':
      case 'RESPONSIBLE_ASSIGNED':
        logLabel = `${name === 'OWNER_ASSIGNED' ? 'Owner assigned to ' : 'Assigned to '}`;
        needDecrypt = true;
        activityLog = action.replace(`${name === 'OWNER_ASSIGNED' ? 'Owner assigned to ' : 'Responsible assigned to '}`, '');
        icon = 'user';
        break;
      case 'LEAD_INFO_EDITED':
        logLabel = 'Edited Lead Info:';
        activityLog = action.replace('Edited Lead info: ', '');
        icon = 'edit';
        break;
      case 'PRIORITY_EDITED':
        logLabel = 'Changed Priority to ';
        activityLog = action.replace('Changed Priority to', '');
        icon = 'edit';
        break;
      case 'STATUS_CHANGED':
        logLabel = 'Changed Status to ';
        activityLog = action.replace('Changed Status to ', '');
        icon = 'user';
        break;
      case 'NOTE_CREATED':
        activityLog = 'New note added';
        icon = 'clipboard';
        break;
      case 'NOTE_DELETED':
        logLabel = 'Deleted Note: ';
        activityLog = action.replace('Deleted note: ', '');
        icon = 'clipboard';
        break;
      case 'FILE_CREATED':
        activityLog = 'New file added';
        icon = 'clipboard';
        break;
      case 'FILE_EDITED':
        activityLog = 'File edited';
        icon = 'clipboard';
        break;
      case 'FILE_DELETED':
        activityLog = 'Removed file';
        icon = 'clipboard';
        break;
      case 'LEAD_SCHEDULE_INVITE':
        activityLog = action;
        icon = 'calendar';
        break;
      case 'LEAD_SCHEDULED':
      case 'LEAD_RESCHEDULED': {
        logLabel = 'Lead scheduled for ';
        activityLog = action.split(';')[0].replace('Lead scheduled for ','');
        icon = 'calendar';
        hasLink = true;
        link = action.split(';')[1].split('=')[1];
        hasAvatar = false;
        break;
      }
      case 'LEAD_CSV_UPDATED': {
        logLabel = 'Lead updated via csv by ';
        activityLog = action.replace('Lead updated via CSV by ','');
        needDecrypt = true;
        icon = 'edit';
        hasLink = false;
        link ='';
        hasAvatar = false;
        break;
      }
      case 'LEAD_CANCELED':
        logLabel = 'Lead scheduled for ';
        activityLog = action.split(';')[0].replace('Lead scheduled for ', '');
        icon = 'calendar';
        break;
      default:
        return null;
    }

    return (
      <div className="activity-box">
        <div className="activity-icon-group">
          <div className="icon-box">
            <MDBIcon far icon={icon} />
          </div>
          {!isLastLog && <div className="line-box" />}
        </div>
        <div className="activity-detail-group">
          <div className="main-details">
            <span>{logLabel}</span>
            {hasLink ? (
              <a href={link} target="_blank" rel="noopener noreferrer">
                {needDecrypt ? utility.decrypt(activityLog) : activityLog}
              </a>
            ) : (
              <span className="highlight-text">{needDecrypt ? utility.decrypt(activityLog) : activityLog}</span>
            )}
          </div>
          <div className="sub-details">
            <span>
              {sender ? (
                <>
                  {utility.decrypt(sender.first_name)} {utility.decrypt(sender.last_name)} &nbsp;
                </>
              ) : (
                'Propelrr LMS System'
              )}
              <MDBIcon icon="circle" />
            </span>
            <span>
              <Moment date={created} format="MMM D, YYYY h:mm A" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const list = activityLogLoaded && activityLog.data ? activityLog.data : [];
  const sortedListByCreateDate = list.slice().sort((a, b) => {
    const dateA = a.created ? new Date(a.created) : new Date(0); // Default to epoch if null/empty
    const dateB = b.created ? new Date(b.created) : new Date(0); 
  
    return dateA - dateB;
  });

  const hasData = sortedListByCreateDate.length > 0;

  return (
    <div className="recent-activity-box">
      {!activityLogLoaded && (
        <div className="loading-box">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {activityLogLoaded && hasData && (
        <ul className="list-unstyled">
          {sortedListByCreateDate.reverse().map((value, index) => {
            const isLastLog = index === 0;
            return (
              <li key={index}>
                {renderActivityLog(value, isLastLog)}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const mapState = (state) => {
  const { leads } = state;
  const { activityLogLoaded, activityLog } = leads;
  return { leads, activityLogLoaded, activityLog };
};

const actionCreators = {
  getActivityLog: leadActions.getActivityLog,
};

const LeadViewRecentActivitiesComponent = connect(mapState, actionCreators)(LeadViewRecentActivities);
export {LeadViewRecentActivitiesComponent as LeadViewRecentActivities};