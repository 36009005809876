import React, { useState, useEffect, useRef } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { influencerActions } from '_actions';
import { connect } from 'react-redux';
import { EditorState } from 'draft-js';
import DOMPurify from 'dompurify';

const ManageRateModal = ({ isOpen, mode, data, influencerId, projectId, addInfluencerRate, updateInfluencerRate, handleCloseManageRatesModal }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isCurrencyError, setCurrencyError] = useState(false);
  const [isMinRateError, setMinRateError] = useState(false);
  const [isMaxRateError, setMaxRateError] = useState(false);
  const [isDescriptionError, setDescriptionError] = useState(false);
  const [toggleTabState, setToggleTabState] = useState(1);

  const currencyInput = useRef(null);
  const minRateInput = useRef(null);
  const maxRateInput = useRef(null);
  const descriptionInput = useRef(null);

  const setCurrency = () => setCurrencyError(false);
  const setMinRate = () => setMinRateError(false);
  const setMaxRate = () => setMaxRateError(false);
  const setDescription = () => setDescriptionError(false);

  const isFieldValid = () => {
    let isValid = true;
    const currency = currencyInput.current.value;
    const minRate = minRateInput.current.value;
    const maxRate = maxRateInput.current.value;
    const description = descriptionInput.current.value;

    if (!currency) {
      setCurrencyError(true);
      isValid = false;
    }
    if (!minRate) {
      setMinRateError(true);
      isValid = false;
    }
    if (!maxRate) {
      setMaxRateError(true);
      isValid = false;
    }
    if (!description) {
      setDescriptionError(true);
      isValid = false;
    }

    return isValid;
  };

  const handleClose = () => {
    handleCloseManageRatesModal(mode);
    resetForm();
  };

  const resetForm = () => {
    currencyInput.current.value = '';
    minRateInput.current.value = '';
    maxRateInput.current.value = '';
    descriptionInput.current.value = '';
  };

  const handleManageRate = () => {
    const isValid = isFieldValid();

    if (isValid) {
      const rate = {
        currency: currencyInput.current.value,
        min_rate: minRateInput.current.value,
        max_rate: maxRateInput.current.value,
        description: descriptionInput.current.value
      };

      if (mode === 'add') {
        addInfluencerRate(projectId, influencerId, rate);
      } else {
        updateInfluencerRate(projectId, influencerId, data.id, rate);
      }

      handleClose();
    }
  };

  const toggleTab = (index) => setToggleTabState(index);

  useEffect(() => {
    if (mode === 'edit' && data) {
      currencyInput.current.value = data.currency;
      minRateInput.current.value = data.minimum;
      maxRateInput.current.value = data.maximum;
      descriptionInput.current.value = data.description;
    } else {
      resetForm();
    }
  }, [mode, data]);

  const activeClass = isOpen ? 'active' : '';

  return (
    <>
      <div className={`drawer-overlay ${activeClass}`} onClick={handleClose}></div>
      <div className={`side-drawer-main ${activeClass}`}>
        <div className='side-drawer-wrapper'>
          <div className='side-drawer-title'>
            <h3>{mode.toUpperCase()} RATE</h3>
            <span className='side-drawer-close' onClick={handleClose}><i className="icon-lms icon-close"></i></span>
          </div>
          <div className='side-drawer-tabs'>
            <div className='side-drawer-tabs-wrapper'>
              <div className='control-tabs-box'>
                <div className={toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => toggleTab(1)}>
                  <p>Rate Information</p>
                </div>
              </div>
              <div className='content-tabs-box'>
                <div className={toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                  <div className='forms-tab'>
                    <MDBRow>
                      <MDBCol className='col-12 form-group-box'>
                        <div className='input-group-box full-box-'>
                          <div className={isCurrencyError ? 'field-box error-field' : 'field-box'}>
                            <label htmlFor={'value'}>Currency <small>*</small></label>
                            <input ref={currencyInput} type='text' id={'value'} name={'value'} onChange={setCurrency} />
                            {isCurrencyError && <p className='error-field'>This field is required</p>}
                          </div>
                          <div className={isMinRateError ? 'field-box error-field' : 'field-box'}>
                            <label htmlFor={'value'}>Minimum Rate <small>*</small></label>
                            <input ref={minRateInput} type='number' id={'value'} name={'value'} onChange={setMinRate} />
                            {isMinRateError && <p className='error-field'>This field is required</p>}
                          </div>
                          <div className={isMaxRateError ? 'field-box error-field' : 'field-box'}>
                            <label htmlFor={'value'}>Maximum Rate <small>*</small></label>
                            <input ref={maxRateInput} type='number' id={'value'} name={'value'} onChange={setMaxRate} />
                            {isMaxRateError && <p className='error-field'>This field is required</p>}
                          </div>
                          <div className={isDescriptionError ? 'field-box error-field' : 'field-box'}>
                            <label htmlFor={'value'}>Description <small>*</small></label>
                            <textarea ref={descriptionInput} id={'value'} name={'value'} onChange={setDescription} />
                            {isDescriptionError && <p className='error-field'>This field is required</p>}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
              <div className='tab-form-btn-group'>
                <MDBRow>
                  <MDBCol className='text-right'>
                    <span className='drawer-btn cancel-btn' onClick={handleClose}>
                      Cancel
                    </span>
                    <span className='drawer-btn save-btn' onClick={handleManageRate}>
                      {mode === 'add' ? 'Add Rate' : 'Update Rate'}
                    </span>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => {
  const { influencers } = state;
  return { influencers };
};

const actionCreators = {
  addInfluencerRate: influencerActions.addInfluencerRate,
  updateInfluencerRate: influencerActions.updateInfluencerRate
};

const connectedManageRateModal = connect(mapState, actionCreators)(ManageRateModal);
export { connectedManageRateModal as ManageRateModal };
