export const environment = {
  production: false,
  // apiUrl: 'https://lmsapi-dev.propelrr.com',
  // apiUrl: 'https://lms-api.propelrr.io',
  // apiUrl: 'https://lms-api-staging.propelrr.io',
  // apiUrl: 'https://lamesa-api.propelrr.io',
  apiUrl: 'https://lms-api.propelrr.io',
  // apiUrl: 'http://localhost:8082',
  // apiUrl: 'http://propelrr-lms-2022.ap-southeast-1.elasticbeanstalk.com',
  // baseUrl: 'https://propelrr-lms-2021.herokuapp.com',
  baseUrl: 'https://lms.propelrr.io',
  clientId: 'propelrr_restengine',
  clientSecret: 'c1On0vGm4jebEtM8wPpDFFTv8DCDAfHsC39FaHPKA4U=',
  genericUsername: 'propelrr_platform',
 // genericPassword: 'pRop3LrR_@viat0r$'
  genericPassword: 'c1On0vGm4jebEtM8wPpDFFTv8DCDAfHsC39FaHPKA4U=',
};