import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import check from '_assets/images/check.png';
import { Link } from 'react-router-dom';
import Slider from '_pages/Leads/components/Slider';
import { Button, ButtonGroup, Icon } from "blackbox-react";
import { connect } from "react-redux";

const ImportResult = ({ importedLeads, imported }) => {
  const [tableData] = useState([
    {
      fileName: 'Lead_Import_Sample.csv',
      importedon: `September 30, 2019 10:19 PM`,
      created: '10',
      updated: '2',
      skipped: '0',
      errored: '0',
      importedBy: 'Sophie O.',
      status: 'Completed'
    }
  ]);

  const renderSlider = () => (
    <div className='width-135'>
      {["Upload CSV", "Map Fields", "Import"].map((text, index) => (
        <div key={index} className='row float-right height-41 ml-1'>
          <div className='row mr-2'>
            <p className={index < 2 ? 'notActive mt-2 mb-0' : 'active mt-2 mb-0'}>{text}</p>
            <p className='border-right-gray mb-0 pr-3'> </p>
          </div>
          <div className='mt-2'>
            <p className='mx-2 round mb-0 background-color-dark-orange'>
              <img alt='' src={check} className='check-icon filter-white' />
            </p>
            {index < 2 && <p className='mb-0 line' />}
          </div>
        </div>
      ))}
    </div>
  );

  const projectId = JSON.parse(localStorage.getItem('projectId'));

  return (
    <MDBContainer fluid className='p--md lead-import'>
      <MDBRow className='m--0'>
        <MDBCol xl='2' lg='4' md='4' className='p-t--0 p-l--0 p-r--sm p-b--sm'>
          <MDBRow className='p--0 m--0'>
            <MDBCol className='p--0'>
              <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
              <p className='font-family-crimson font-size-30 line-height-7 color-orange line-height-6'>Import Leads</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className='m-t--lg p--0'>
            <MDBCol xl='11' lg='9' className='text-center font-size-14 p--0 m--0 m-l--sm'>
              <Slider stageNum='3' activeFormView='importResults' isVerified='true' />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol lg='9' xl='10' md='8' className='px-0'>
          <MDBRow className='d-flex'>
            <MDBCol lg='6' className='my-auto'>
              <MDBRow className='pl-3'>
                <p className='mb-0 font-family-crimson font-size-22 my-auto pr-3'>Import Results</p>
                <div className='progress col-lg-5 col-xl-5 my-auto'>
                  <span className='progress-value text-white font-weight-600 my-auto'>100% complete</span>
                  <div className='progress-bar progress-background' style={{ width: '100%' }} />
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          {imported ? (
            <MDBCol className='sub-body-container background-color-white background-rectangle m-t--md p--0'>
              <MDBRow className='p--md m--0 height--full'>
                <MDBCol className='import-info__overview forms-overview'>
                  <div className='import-info__icon'>
                    <div className='project__link import-result--created'>
                      <div className='icon'>
                        <MDBIcon fas icon='check-double' />
                        <div className='stats'>
                          <div className='label count'><span>{importedLeads.data.length}</span></div>
                          <div className='label'><p>Created</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          ) : (
            <MDBCol className='sub-body-container background-color-white background-rectangle m-t--md p--0'>
              <MDBRow className='sub-body-container height--full'>
                <MDBCol md='auto' lg='12' className='sub-body-container'>
                  <div className='loader-container'>
                    <div className='loader dark'>Loading...</div>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const mapState = (state) => {
  const { importedLeads, imported } = state.leads;
  return { importedLeads, imported };
};

const actionCreators = {};

const connectedImportResult = connect(mapState, actionCreators)(ImportResult);
export { connectedImportResult as ImportResult };