import { calendlyConstants } from "_constants";

/**
 * Reducer function to handle Calendly related actions.
 *
 * @param {Object} state - The current state of the Calendly reducer.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.calendly] - The Calendly data payload.
 * @param {Object} [action.error] - The error payload.
 * @returns {Object} The new state of the Calendly reducer.
 */
export function calendly(state = {}, action) {
  switch (action.type) {
    case calendlyConstants.GET_CALENDLY_REQUEST:
      return {
        ...state,
        getcalendlyLoading: true,
        getcalendlyLoaded: false,
      };
    case calendlyConstants.GET_CALENDLY_SUCCESS:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        calendly: action.calendly.data,
        error: null
      };
    case calendlyConstants.GET_CALENDLY_FAILURE:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        error: action.error,
      };

    case calendlyConstants.UPDATE_CALENDLY_REQUEST:
      return {
        ...state,
        getcalendlyLoading: true,
        getcalendlyLoaded: false,
      };

    case calendlyConstants.UPDATE_CALENDLY_SUCCESS:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        calendly: action.calendly.data,
        error: null
      };
    case calendlyConstants.UPDATE_CALENDLY_FAILURE:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        error: action.error,
      };
    case calendlyConstants.CREATE_CALENDLY_REQUEST:
      return {
        ...state,
        getcalendlyLoading: true,
        getcalendlyLoaded: false,
      };

    case calendlyConstants.CREATE_CALENDLY_SUCCESS:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        calendly: action.calendly.data,
        error: null
      };
    case calendlyConstants.CREATE_CALENDLY_FAILURE:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        error: action.error,
      };
    case calendlyConstants.DELETE_CALENDLY_REQUEST:
      return {
        ...state,
        getcalendlyLoading: true,
        getcalendlyLoaded: false,
      };
    case calendlyConstants.DELETE_CALENDLY_SUCCESS:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        calendly: action.calendly,
        error: null
      };
    case calendlyConstants.DELETE_CALENDLY_FAILURE:
      return {
        ...state,
        getcalendlyLoading: false,
        getcalendlyLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
