import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { influencerActions } from "_actions";
import Button from "_pages/Influencer/components/General/Button";
import {
    abbreviateNumber,
    getInfluencerTypeClassColor,
    getInfluencerTypeClassIcon,
    getInfluencerTypeLabel
} from "_services";

const PlatformTab = ({ projectId, influencerId, onHandleManagePlatformModal, onHandleOpenConfirmationModal, classN }) => {
    const dispatch = useDispatch();
    const { influencerPlatformLoaded, influencerPlatformLoading, platforms } = useSelector(state => state.influencers);

    useEffect(() => {
        dispatch(influencerActions.getPlatformTypes(projectId));
        dispatch(influencerActions.getInfluencerPlatform(projectId, influencerId));
    }, [dispatch, projectId, influencerId]);

    const openAddPlatformModal = () => {
        onHandleManagePlatformModal('add', null);
    };

    const openEditPlatformModal = (platform) => {
        onHandleManagePlatformModal('edit', platform);
    };

    const openDeleteConfirmationModal = (type, platform) => {
        onHandleOpenConfirmationModal(type, platform);
    };

    return (
        <div className={classN}>
            <div className='inner-title-box'>
                <p>Social Media Followers</p>
                {influencerPlatformLoaded && platforms.length > 0 && (
                    <Button text='Add Platform' icon='icon-lms icon-add' onClick={openAddPlatformModal} classN="plain-btn" />
                )}
            </div>

            {influencerPlatformLoaded && (
                <div className='platform-list-box'>
                    {platforms.length > 0 ? (
                        <div className={'platform'}>
                            {platforms.map((platform, index) => {
                                const type = platform.type.code;
                                const name = platform.type.name;
                                const value = abbreviateNumber(platform.value);
                                const link = platform.link;
                                const icon = getInfluencerTypeClassIcon(type);
                                const color = getInfluencerTypeClassColor(type);
                                const updated = platform.updated;

                                return (
                                    <div className={`platform-col ${icon}`} key={index}>
                                        <div className='platform-info'>
                                            <div className='platform-icon'>
                                                <a href={link} target='_blank' rel='noopener noreferrer'>
                                                    <img src={`/${icon}.svg`} alt='logo' />
                                                </a>
                                            </div>
                                            <div className='platform-details'>
                                                <div>
                                                    <p>{name}</p>
                                                    <span>Platform</span>
                                                </div>
                                                <div>
                                                    <p>{value}</p>
                                                    <span>{getInfluencerTypeLabel(type)}</span>
                                                </div>
                                            </div>
                                            <div className='platform-btns'>
                                                <button className='c-btn c-btn-sm c-btn-light edit-record' onClick={() => openEditPlatformModal(platform)}>
                                                    <i className='icon-lms icon-edit'></i>Edit
                                                </button>
                                                <button className='c-btn c-btn-sm c-btn-light delete-record' onClick={() => openDeleteConfirmationModal('platform', platform)}>
                                                    <i className='icon-lms icon-delete'></i>Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className='empty-tab-content'>
                            <div className='empty-content'>
                                <h6>Add Your First Platform</h6>
                                <p>Platforms will appear here</p>
                                <span onClick={openAddPlatformModal}>
                                    <i className='icon-lms icon-add'></i> Add Platform
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {influencerPlatformLoading && (
                <div className='loading-box'>
                    <div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
                </div>
            )}
        </div>
    );
};

export { PlatformTab };
