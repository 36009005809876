import React from 'react';

/**
 * UtmFormLoader is a functional React component that renders a skeleton loader
 * for a UTM form. It displays multiple skeleton elements to simulate the loading
 * state of a form with various input fields and labels.
 *
 * @returns {JSX.Element} A JSX element representing the skeleton loader for the UTM form.
 */
const UtmFormLoader = () => {
  return (
    <div className="skeleton-loader skeleton-utm-form active">
        <div className='utm-item'>
            <div className='skeleton skeleton-box utm-item-title'></div>
            <div className='utm-item-row'>
                <div className='utm-item-col'>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                </div>
                <div className='utm-item-col'>

                </div>
            </div>
        </div>
        <div className='utm-item'>
            <div className='utm-item-row'>
                <div className='utm-item-col'>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                </div>
                <div className='utm-item-col'>

                </div>
            </div>
        </div>
        <div className='utm-item'>
            <div className='utm-item-row'>
                <div className='utm-item-col'>
                    <div className='skeleton skeleton-box utm-item-title'></div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                </div>
                <div className='utm-item-col'>
                    <div className='skeleton skeleton-box utm-item-title'></div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                    <div className='utm-item-group'>
                        <div className='utm-item-group-col'>
                            <div className='skeleton skeleton-box utm-item-label'></div>
                        </div>
                        <div className='utm-item-group-col'>
                        <div className='skeleton skeleton-box utm-item-input'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UtmFormLoader