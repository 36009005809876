import React, {Component, useEffect} from 'react';
import {MDBCol, MDBIcon} from 'mdbreact';
import Moment from "react-moment";
import LeadActivityInfo from "_pages/Leads/components/LeadInfo/LeadActivityInfo";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {leadActions} from "_actions";


const LeadSession = () => {

    const params = useParams();

    const sessionLoaded = useSelector((state) => {
        const {leads} = state;
        return leads.sessionsLoaded;
    });

    const sessions = useSelector((state) => {
        const {leads} = state;

        if(leads.sessionsLoaded) {
           return leads.sessions;
        }

        return [];

    });


    return (

    <>
        <div className='session-box tab-content-box'>

            { !sessionLoaded && 
                <div className='loading-box'>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            }


            { sessionLoaded && sessions.length > 0 &&
                <div className='session-group'>
                    <div className='inner-title-box'>
                        <p>USER SESSIONS</p>
                    </div>
                    <div className='session-list-box'>
                        {sessions.map((item, index) => {
                            return (
                                <div className='session' key={index}>
                                    <p>
                                        <Moment date={item.date} format="LL"/> ({item.session_id})
                                    </p>
                                    <ul className='accordion'>
                                        {item.activities.map((act, index) => {
                                            return <LeadActivityInfo key={index} data={act}/>
                                        })}
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }

            { sessionLoaded && sessions.length < 1 &&
                <div className='empty-tab-content'>
                    <div className='empty-content'>
                        <h6>No Available Session</h6>
                    </div>
                </div>
            }
        </div>
    </>);


}

export default LeadSession;
