import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody, MDBBtn, MDBModalFooter } from 'mdbreact';
import { addButtons } from '_pages/Forms/constants/addFieldButtons';

const AddFieldModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [presets, setPresets] = useState(addButtons);
  const [addInput, setAddInput] = useState(false);
  const [activeBtn, setActiveBtn] = useState(-1);
  const [viewText, setViewText] = useState('VIEW MORE');

  useEffect(() => {
    setModalOpen(props.modalOpen);
  }, [props.modalOpen]);

  const handleCancel = () => {
    props.modalToggle();
  };

  const handleButtons = () => {
    return presets.map((value, index) => (
      <MDBBtn
        key={value.buttonName}
        flat
        className={`${activeBtn === index ? 'dotted-red' : 'dotted'} font-size-10 text-left color-black px-3 py-2 font-weight-bold shadow-none`}
        onClick={() => {
          setAddInput(true);
          setActiveBtn(index);
        }}
      >
        <MDBIcon icon={value.icon} className='mr-2' />
        {value.buttonName}
      </MDBBtn>
    ));
  };

  const scrollDown = () => {
    setViewText(viewText === 'VIEW MORE' ? 'VIEW LESS' : 'VIEW MORE');
    const divButtons = document.getElementById('divButtons');
    if (viewText === 'VIEW MORE') {
      divButtons.scrollTop = divButtons.scrollHeight;
    } else {
      divButtons.scrollTop = 0;
    }
  };

  return (
    <MDBContainer className='confirmation-modal-container'>
      <MDBModal className='confirmation-modal' toggle={props.modalToggle} isOpen={modalOpen} centered>
        <MDBModalHeader className='confirmation-modal-header text-uppercase p-2' toggle={props.modalToggle}>
          <span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray font-size-13'>SELECT FIELD</span>
        </MDBModalHeader>
        <MDBModalBody className='confirmation-modal-body pb-2 mb-2 mt-1'>
          <div id='divButtons' className={addInput ? 'height-89' : 'view-less'}>
            {handleButtons()}
          </div>
          <p onClick={scrollDown} className={addInput ? 'mb-0 font-size-8 text-right position-absolute view-more-pos hand' : 'display-none'}>
            {viewText}
            <MDBIcon icon='angle-down' />
          </p>
          <div className={addInput ? '' : 'display-none'}>
            <div className='mx-2 mt-3'>
              <p className='font-size-16 mb-0'>
                Field Label<span className='color-red'>*</span>
              </p>
              <input className='border-gray mb-0 br-5 px-2 py-2 font-size-13' />
            </div>
            <div className='mx-2 mt-3'>
              <p className='font-size-16 mb-0'>Placeholder</p>
              <input className='border-gray mb-0 br-5 px-2 py-2 font-size-13' />
            </div>
          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <label className={addInput ? 'checkboxcontainer-checkLead ml-0 mt-less-3 float-left' : 'display-none'}>
            <input type='checkbox' name='check-box' />
            <span id='addLeadCheck' className='checkmark' />
            Required
          </label>
          <MDBRow className='justify-content-between mx-1 font-size-10'>
            <MDBBtn onClick={handleCancel} className='height-41 px-5 background-transparent shadow-none border-grey color-black py-2 br-5'>
              CANCEL
            </MDBBtn>
            <MDBBtn onClick={handleCancel} className='disabled height-41 px-5 background-color-orange shadow-none border-grey color-white py-2 br-5'>
              ADD FIELD
            </MDBBtn>
          </MDBRow>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

export default AddFieldModal;
