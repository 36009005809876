import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  leadActions,
  projectActions,
  chatActions,
  emailActions,
  fileActions,
} from "_actions";
import { httpManagerService, utilitiesService as utility } from "_services";
import DeleteConfirmationModal from "_components/Modal/DeleteConfirmationModal";
import TopBar from "../../_components/TopBar";
import ProjectDetails from "../../_components/Drawer/ProjectDetails";
import ProjectMembers from "../../_components/Drawer/ProjectMembers";
import AddLeadDrawer from "./components/Drawer/AddLeadDrawer";
import EditLeadDrawer from "./components/Drawer/EditLeadDrawer";
import { AddLeadNoteModal } from "_pages/Leads/components/AddLeadNoteModal";
import EditLeadNoteModal from "_pages/Leads/components/EditLeadNoteModal";
import DeleteLeadNoteModal from "_pages/Leads/components/DeleteLeadNoteModal";
import { LeadStatusDropdown } from "_pages/Leads/components/LeadInfo/LeadStatusDropdown";
import { BreadcrumbPage } from "../../_components/BreadcrumbPage";
import Calendly from "../../_components/Drawer/Calendly";
import axios from "axios";
import { MDBCol, MDBRow } from "mdbreact";
import { InputSelect } from "blackbox-react";
import {
  colorRoundStatus,
  leadInfoBtn,
  priorityColor,
  priorityIcon,
  priorityText,
} from "_pages/Leads/constants/BreadCrumbs";
import { FormGroupUpdate } from "_pages/Forms/FormGroupUpdate";
import { LeadViewRecentActivities } from "_pages/Leads/components/LeadInfo/LeadViewRecentActivities";
import ActivitySideButton from "_pages/Leads/components/LeadInfo/ActivitySideButton";
import LeadInfoBreadCrumbs from "_pages/Leads/components/LeadInfo/LeadInfoBreadCrumbs";
import LeadViewInfoForms from "_pages/Leads/components/LeadInfo/LeadViewInfoForms";
import LeadViewInfoTask from "_pages/Leads/components/LeadInfo/LeadViewInfoTask";
import avatar from "_assets/images/avatar-lg.png";
import LeadSession from "_pages/Leads/components/LeadInfo/LeadSession";
import LeadInterest from "_pages/Leads/components/LeadInfo/LeadInterest";
import LeadChat from "_pages/Leads/components/LeadInfo/LeadChat";
import LeadEmail from "_pages/Leads/components/LeadInfo/LeadEmail";
import { LeadPriorityDropdown } from "_pages/Leads/components/LeadInfo/LeadPriorityDropdown";
import LeadViewNotesNew from "_pages/Leads/components/LeadInfo/LeadViewNotesNew";
import TextLoader from "../../_components/Loader/TextLoader";
import AgentInfoLoader from "../../_components/Loader/AgentInfoLoader";
import { LeadFile } from "./components/LeadInfo/LeadFile";
import LeadFileManageModal from "./components/LeadInfo/LeadFileManageModal";
import { DeleteLeadFileModal } from "./components/DeleteLeadFileModal";
import LeadOwnerResponsible from "./components/LeadInfo/LeadOwnerResponsible";
import LeadButtonActionGroup from "./components/LeadInfo/LeadButtonActionGroup";
import { handleDisplayActivity } from "./components/LeadInfo/components/LeadInfoActivity";
import { renderBasicInfo } from "./components/LeadInfo/components/LeadInfoBasicInformation";

const LeadInfo = (props) => {
  const {
    match,
    getProject,
    getAllProjectMembers,
    getLeadSummary,
    getLead,
    getActivityLog,
    getLeadNotes,
    getLeadSession,
    getLeadInterest,
    getLeadChat,
    getLeadEmail,
    getLeadFiles,
    getEmailLoaded,
    email,
  } = props;
  // State
  const [state, setState] = useState({
    leadInfoBtn: null,
    isActiveSideBtn: "Activity",
    projectData: {},
    priorityColor: null,
    priorityIcon: null,
    leadData: {},
    priorityText: null,
    colorRoundStatus: null,
    leadDetails: [],
    statusToDelete: {},
    activeDropdown: "",
    deleteLeadModalOpen: false,
    deleteLeadNoteModalOpen: false,
    addLeadNoteModalOpen: false,
    editLeadNoteModalOpen: false,
    isSelectFieldExpanded: false,
    isAgentChanged: false,
    isLoading: false,
    selectedInput: "",
    selectedOwner: "",
    selectedResponsible: "",
    selectedPriority: "",
    activityLog: [],
    leadNotes: [],
    noteToEdit: {},
    selectOptions: [],
    availableAgents: [],
    selectedAgentValue: {},
    assignedAgentId: "UNASSIGNED",
    filterToggled: false,
    isProjectDetailsDrawer: false,
    isProjectMembersDrawer: false,
    isAddLeadDrawer: false,
    isEditLeadDrawer: false,
    isCalendlyDrawer: false,
    selectedNote: {},
    unreadChats: [],
    isManageFileModalOpen: false,
    manageFileMode: "Add",
    manageFileData: {},
    deleteConfirmationType: "",
    deleteConfirmationData: {},
    isDeleteConfirmationModalOpen: false,
  });

  const filterRef = useRef(null);

  useEffect(() => {
    const projectId = match.params.id;
    const leadId = match.params.leadId;

    if (parseInt(projectId) === 1) {
      getOptions(); // You should define this function separately
    }

    // Fetch data after component mounts
    getProject(projectId);
    getAllProjectMembers(projectId);
    getLeadSummary(projectId);
    getLead(projectId, leadId);
    getActivityLog(projectId, leadId);
    getLeadNotes(projectId, leadId, "note");
    getLeadSession(projectId, leadId);
    getLeadInterest(projectId, leadId);
    getLeadChat(projectId, leadId);
    getLeadEmail(projectId, leadId);
    getLeadFiles(projectId, leadId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array means this effect runs once, like componentDidMount

  useEffect(() => {
    if (getEmailLoaded && email) {
      setState((prevState) => ({
        ...prevState,
        isActiveSideBtn: "Email",
      }));
    }
  }, [getEmailLoaded, email]); // Effect runs when `getEmailLoaded` or `email` changes

  // Function to update state (avoids direct use of `setState` for individual updates)
  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const getOptions = async () => {
    const { match } = props;
    const res = await axios.get(
      `https://primaryhomes.com/api/agents/available?lead=${match.params.leadId}`
    );
    const data = res.data;

    const options = data.map((d) => ({
      value: d.agent_id,
      label: d.first_name + " " + d.last_name,
    }));

    options.unshift({
      value: "UNASSIGNED",
      label: "UNASSIGNED",
    });

    setState((prevState) => ({
      ...prevState,
      selectOptions: options,
    }));
    setState((prevState) => ({
      ...prevState,
      availableAgents: data,
    }));
  };

  const handleGetLead = () => {
      const { match, getLead, getActivityLog, getLeadNotes, getLeadSession } =
        props;
  
      const projectId = match.params.id;
      const leadId = match.params.leadId;
  
      getLead(projectId, leadId);
      getActivityLog(projectId, leadId);
  
      getLeadSession(projectId, leadId);
      getLeadNotes(projectId, leadId, "note");
  
      setState((prevState) => ({
        ...prevState,
        deleteLeadModalOpen: false,
        isSelectFieldExpanded: false,
        deleteLeadNoteModalOpen: false,
        statusToDelete: {},
        isLoading: false,
      }));
    };
  

  const handleUnreadMessagesCount = (chats, getChatsLoaded) => {
    let unreadMessages = [];
    const { match } = props;

    if (chats && chats.lead_id == match.params.leadId) {
      if (
        getChatsLoaded &&
        chats.chat_history.some((c) => c.status === "received")
      ) {
        const userId = chats.fb_user_id;
        const chatHistory = chats.chat_history;

        unreadMessages = chatHistory.filter((chat) => {
          return chat.sender_id === userId && chat.status === "received";
        });
      }
    }

    return unreadMessages.length;
  };

  const handleUnreadEmailCount = (email, getEmailLoaded) => {
    let unreadMessages = [];
    const { match } = props;

    if (email && email.lead_id == match.params.leadId) {
      if (
        getEmailLoaded &&
        email.email_threads.some((c) => c.status === true)
      ) {
        const userId = email.lead_email;
        const emailHistory = email.email_threads;

        unreadMessages = emailHistory
          .filter((thread) => {
            return thread.status === true;
          })
          .map((t) => {
            return t.thread_messages;
          })
          .reduce((prev, next) => {
            return prev.concat(next);
          })
          .filter((tm) => {
            return tm.status === 1;
          });
      }
    }
    return unreadMessages.length;
  };

  const handleGetChat = () => {
    const { match, getLeadChat } = props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    getLeadChat(projectId, leadId);
  };

  const handleGetEmail = () => {
    const { match, getLeadEmail } = props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    getLeadEmail(projectId, leadId);
  };

  const handleSideActivityButton = (name) => {
    setState((prevState) => ({
      ...prevState,
      isActiveSideBtn: name,
    }));

    if (name === "Chat") {
      handleGetChat();
    }

    if (name === "Email") {
      handleGetEmail();
    }
  };

  const toggleDeleteLead = () => {
    let { deleteLeadModalOpen } = state;
    setState((prevState) => ({
      ...prevState,
      deleteLeadModalOpen: !deleteLeadModalOpen,
    }));
  };

  const toggleDeleteLeadNote = (note) => {
    let deleteLeadNoteModalOpen = state.deleteLeadNoteModalOpen;
    handlePassData(note);
    setState((prevState) => ({
      ...prevState,
      deleteLeadNoteModalOpen: !deleteLeadNoteModalOpen,
    }));
  };

  const toggleAddLeadNote = () => {
    let { addLeadNoteModalOpen } = state;
    setState((prevState) => ({
      ...prevState,
      addLeadNoteModalOpen: !prevState.addLeadNoteModalOpen,
    }));
  };

  const toggleEditLeadNote = () => {
    let { editLeadNoteModalOpen } = state;
    setState((prevState) => ({
      ...prevState,
      editLeadNoteModalOpen: !editLeadNoteModalOpen,
    }));
  };

  const handleGetIdentifier = (identifier, identifierType, leadData) => {
    let identifierVal = "";

    if (identifierType === "Form Group") {
      leadData.map((val) => {
        if (val.group_id === identifier) {
          identifierVal = identifierVal + " " + utility.decrypt(val.value);
        }
        return null;
      });
    } else if (identifierType === "Group Field") {
      leadData.map((val) => {
        if (val.field_id === identifier) {
          identifierVal = utility.decrypt(val.value);
        }
        return null;
      });
    }

    return identifierVal;
  };

  const handleUpdateLeadPriority = (selectedPriority) => {
    const { match, updateLead } = props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    const user = JSON.parse(localStorage.getItem("user"));
    const userEmail = user && user.data.email;
    updateLead(projectId, leadId, {
      priority: selectedPriority,
      sender: userEmail,
    });
  };

  const renderPriority = (leadDetails) => {
    const { leadLoaded } = props;

    return (
      <div className="avatar-text-box">
        <div className="text">
          <label>PRIORITY</label>
          {leadLoaded && (
            <LeadPriorityDropdown
              lead={leadDetails}
              onHandleUpdateLeadPriority={handleUpdateLeadPriority}
            />
          )}

          {!leadLoaded && <TextLoader />}
        </div>
      </div>
    );
  };

  const renderStatus = (leadDetails) => {
    const { leadLoaded } = props;
    return (
      <React.Fragment>
        <div className="avatar-text-box">
          <div className="text">
            <label>STATUS</label>
            {leadLoaded && (
              <LeadStatusDropdown
                lead={leadDetails}
                onHandleUpdateLeadStatus={handleUpdateLeadStatus}
              />
            )}
            {!leadLoaded && <TextLoader />}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderSidebar = (leadDetails) => {
    return (
      <React.Fragment>
        <MDBRow>
          <LeadOwnerResponsible props={props} leadDetails={leadDetails} />
          <div className="col-box">{renderPriority(leadDetails)}</div>
          <div className="col-box">{renderStatus(leadDetails)}</div>
        </MDBRow>
      </React.Fragment>
    );
  };

  const handleUpdate = (keyValue, keyId) => {
    const projectId = localStorage.getItem("projectId");
    const leadId = props.match.params.id;
    const user = JSON.parse(localStorage.getItem("user"));
    const userEmail = user && user.data.email;

    props.updateLead(projectId, leadId, {
      form_lead: [
        {
          key: keyId,
          value: keyValue,
        },
      ],
      sender: userEmail,
    });

    handleGetLead();
  };

  const handleDeleteLead = () => {
    let leadData = props.leadData;
    let leadDetails = leadData && props.leadData.data;
    toggleDeleteLead();
    handlePassData(leadDetails);
  };

  

  const approveModal = (statusToDelete) => {
    const { match, deleteLead, deleteLeadNote } = props;
    const projectId = match.params.id;
    const leadId = match.params.leadId;

    let leadData = props.leads;
    let leadDetails = leadData && props.lead.data;
    let leadNote = state.statusToDelete;
    const user = JSON.parse(localStorage.getItem("user"));
    const userEmail = user && user.data.email;
    let contentId = leadNote ? leadNote.id : 0;
    let typeName = "note";

    let leadDataId = {
      leadId: leadId,
    };

    if (parseInt(statusToDelete) === parseInt(leadDetails.id)) {
      deleteLead(projectId, leadId, leadDataId);
    } else {
      leadNote = {
        name: leadNote.name,
        sender: userEmail,
      };
      deleteLeadNote(projectId, leadId, typeName, contentId, leadNote);
      setState((prevState) => ({
        ...prevState,
        deleteLeadNoteModalOpen: false,
        statusToDelete: {},
      }));
    }
  };

  const handlePassData = (data) => {
    setState((prevState) => ({
      ...prevState,
      statusToDelete: data,
      memberToDelete: data,
    }));
  };

  const handleUpdateLeadStatus = (status) => {
    const projectId = props.match.params.id;
    const leadId = props.match.params.leadId;
    const user = JSON.parse(localStorage.getItem("user"));
    const userEmail = user && user.data.email;
    props.updateLead(projectId, leadId, {
      project_status_id: status,
      sender: userEmail,
    });

    setState((prevState) => ({
      ...prevState,
      activeDropdown: "",
      isSelectFieldExpanded: false,
      selectedInput: "",
    }));
  };

  const handleDropdown = (value) => {
    let activeDropdown = state.activeDropdown;
    const domNode = ReactDOM.findDOMNode(this);

    if (domNode || activeDropdown !== value) {
      setState((prevState) => ({
        ...prevState,
        activeDropdown: value,
        selectedInput: "",
        isSelectFieldExpanded: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        activeDropdown: "",
        selectedInput: "",
        isSelectFieldExpanded: false,
      }));
    }
  };

  const handleProjectDetailsDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isProjectDetailsDrawer: !state.isProjectDetailsDrawer,
    }));
  };

  const handleProjectMembersDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isProjectMembersDrawer: !state.isProjectMembersDrawer,
    }));
  };

  const handleAddLeadDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isAddLeadDrawer: !state.isAddLeadDrawer,
    }));
  };

  const handleEditLeadDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isEditLeadDrawer: !state.isEditLeadDrawer,
    }));
  };

  const handleCalendlyDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isCalendlyDrawer: !state.isCalendlyDrawer,
    }));
  };

  const handleCloseManageFileModal = () => {
    setState((prevState) => ({
      ...prevState,
      isManageFileModalOpen: false,
      manageFileData: {},
      manageFileMode: "add",
    }));
  };

  const handleCloseConfirmationDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      isDeleteConfirmationModalOpen: false,
    }));
  };

  let { leadLoaded, membersLoaded, lead } = props;
  let leadDetails = lead && lead.data;
  let activityLog = [];
  let leadNotes = [];
  let leadInterest = [];
  let leadFiles = [];
  let { chats, getChatsLoaded, getChatNotification } = props;
  let { user } = props;

  const projectId = match.params.id;

  let calendlyAccount = null;

  if (
    lead &&
    lead.data.responsible != null &&
    lead.data.responsible.calendly_account != null
  ) {
    calendlyAccount = lead.data.responsible.calendly_account;
  }

  if (
    lead?.data?.owner?.calendly_account != null &&
    lead?.data?.responsible == null
  ) {
    calendlyAccount = lead.data.owner.calendly_account;
  }
  
  // Handle user is an admin
  if (
    user?.data?.admin &&
    (lead?.data?.owner?.calendly_account != null || lead?.data?.responsible?.calendly_account != null)
  ) {
    calendlyAccount = lead?.data?.owner?.calendly_account ?? lead?.data?.responsible?.calendly_account;
  }

  const leadId = match.params.leadId;
  const chatCount = handleUnreadMessagesCount(chats, getChatsLoaded);
  const emailCount = handleUnreadEmailCount(email, getEmailLoaded);

  let identifierValue =
    leadLoaded &&
    handleGetIdentifier(
      leadDetails.identifier,
      leadDetails.identifier_type,
      leadDetails.basic_info
    );

  const {
    deleteLeadModalOpen,
    addLeadNoteModalOpen,
    editLeadNoteModalOpen,
    isProjectDetailsDrawer,
    isProjectMembersDrawer,
    isAddLeadDrawer,
    isEditLeadDrawer,
    isCalendlyDrawer,
    isDeleteConfirmationModalOpen,
    deleteConfirmationType,
    deleteConfirmationData,
    isManageFileModalOpen,
    manageFileMode,
    manageFileData,
    deleteLeadNoteModalOpen,
  } = state;

  return (
    <React.Fragment>
      <div className="topbar-menu">
        <MDBRow>
          <TopBar leadLoaded={leadLoaded} />

          {leadLoaded && (
            <MDBCol className="col">
              <AddLeadNoteModal
                toggleAddLeadNote={toggleAddLeadNote}
                modalOpen={addLeadNoteModalOpen}
                leadId={leadId}
                handleGetLead={handleGetLead}
                addLocation="leadInfo"
              />
              <EditLeadNoteModal
                toggleEditLeadNote={toggleEditLeadNote}
                modalOpen={editLeadNoteModalOpen}
                projectId={projectId}
                leadId={leadId}
                projectStatusId={leadDetails.project_status.id}
                handleGetLead={handleGetLead}
                leadNote={state.noteToEdit}
              />
              <MDBRow
                className={`m--0 p--0 ${
                  leadDetails.converted === false ||
                  leadDetails.converted ||
                  "m-b--sm"
                }t`}
              >
                <MDBCol
                  className="my-auto text-left px-0"
                  style={{ display: "none" }}
                >
                  {leadDetails.converted === false || leadDetails.converted || (
                    <div className="info-btn-breadcrumbs leads-btn-breadcrumbs">
                      <LeadInfoBreadCrumbs
                        isInfoBreadCrumbsActive={leadDetails.project_status.name.toLocaleUpperCase()}
                        handleUpdateLeadStatus={handleUpdateLeadStatus}
                        onHandleDropdown={handleDropdown}
                        activeDropdown={state.activeDropdown}
                      />
                    </div>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBCol>
          )}
        </MDBRow>
      </div>

      <section className="breadcrumb-menu">
        <div className="row align-items-center">
          <div className="col">
            <BreadcrumbPage />
          </div>
          <div className="col">
            <LeadButtonActionGroup
              chat={chats}
              chatCount={chatCount}
              leadLoaded={leadLoaded}
              handleEditLeadDrawer={handleEditLeadDrawer}
              handleSideActivityButton={handleSideActivityButton}
              getChatsLoaded={getChatsLoaded}
              email={email}
              emailCount={emailCount}
              getEmailLoaded={getEmailLoaded}
              projectId={projectId}
              calendlyAccount={calendlyAccount}
              handleCalendlyDrawer={handleCalendlyDrawer}
              handleDeleteLead={handleDeleteLead}
            />
          </div>
        </div>
      </section>

      <section className="main-info-section leads-info">
        <MDBRow className="row-100">
          <MDBCol md="7">
            <React.Fragment>
              <div className="top-info-box">{renderSidebar(leadDetails)}</div>
              <React.Fragment>{renderBasicInfo(leadDetails, props, state, setState, handleGetLead,handleUpdate)}</React.Fragment>
            </React.Fragment>
          </MDBCol>
          <MDBCol md="5">
            <div className="activity-side-box box-white-custom">
              <ActivitySideButton
                sideBtnClick={handleSideActivityButton}
                isActiveSideBtn={state.isActiveSideBtn}
                handleDeleteLead={handleDeleteLead}
                unreadMessages={handleUnreadMessagesCount(
                  chats,
                  getChatsLoaded
                )}
                getChatsLoaded={getChatsLoaded}
                hasLeadChat={chats}
                unreadEmails={handleUnreadEmailCount(email, getEmailLoaded)}
                getEmailLoaded={getEmailLoaded}
                hasLeadEmail={email}
                projectId={projectId}
                leadLoaded={leadLoaded}
              />
              <div className="custom-scroll-box">
                {
                  handleDisplayActivity(
                    leadDetails,
                    activityLog,
                    leadNotes,
                    leadInterest,
                    chats,
                    getChatsLoaded,
                    email,
                    getEmailLoaded,
                    getChatNotification,
                    leadFiles,
                    leadLoaded,
                    props,
                    state,
                    toggleEditLeadNote,
                    toggleAddLeadNote,
                    toggleDeleteLeadNote,
                    setState,
                    handlePassData,
                  )
                }
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </section>

      <ProjectDetails
        isProjectDetailsDrawer={isProjectDetailsDrawer}
        onHandleProjectDetailsDrawer={() => handleProjectDetailsDrawer()}
      />

      <ProjectMembers
        isProjectMembersDrawer={isProjectMembersDrawer}
        onHandleProjectMembersDrawer={() => handleProjectMembersDrawer()}
      />

      <AddLeadDrawer
        projectId={projectId}
        isAddLeadDrawer={isAddLeadDrawer}
        onHandleAddLeadDrawer={() => handleAddLeadDrawer()}
      />

      <EditLeadDrawer
        projectId={projectId}
        isEditLeadDrawer={isEditLeadDrawer}
        onHandleEditLeadDrawer={() => handleEditLeadDrawer()}
      />
      <DeleteConfirmationModal
        projectId={projectId}
        leadId={leadId}
        toggle={toggleDeleteLead}
        modalOpen={deleteLeadModalOpen}
        statusToDelete={leadId}
        onApprove={approveModal}
        target={identifierValue}
        nameToDelete={identifierValue}
        deleteNum="single"
      />

      <Calendly
        projectId={projectId}
        isCalendlyDrawer={isCalendlyDrawer}
        onHandleCalendlyDrawer={() => handleCalendlyDrawer()}
      />

      {state.statusToDelete && (
        <DeleteLeadNoteModal
          toggle={toggleDeleteLeadNote}
          modalOpen={deleteLeadNoteModalOpen}
          statusToDelete={state.statusToDelete}
          onApprove={approveModal}
          target={state.statusToDelete}
          nameToDelete={state.statusToDelete.name}
          deleteNum="single"
        />
      )}

      {/* Add & Edit Lead File Modal */}
      <LeadFileManageModal
        isOpen={isManageFileModalOpen}
        mode={manageFileMode}
        data={manageFileData}
        leadId={leadId}
        projectId={projectId}
        handleCloseManageFileModal={(mode) => handleCloseManageFileModal(mode)}
      />

      <DeleteLeadFileModal
        isOpen={isDeleteConfirmationModalOpen}
        leadId={leadId}
        projectId={projectId}
        type={deleteConfirmationType}
        data={deleteConfirmationData}
        toggle={isDeleteConfirmationModalOpen}
        onHandleCloseConfirmationDeleteModal={() =>
          handleCloseConfirmationDeleteModal()
        }
      />
    </React.Fragment>
  );
};

function mapState(state) {
  const { user } = state.authentication;
  const { projects, project, forms, form, leads, adding } = state;
  const { lead, leadLoaded, activityLogLoaded, notes, notesLoaded, deleting } =
    state.leads;
  const { status, statusLoaded, members, membersLoaded } = state.projects;
  const { chats, getChatsLoaded, getChatNotification } = state.chat;
  const { email, getEmailLoaded } = state.email;
  const { files, getfileLoaded } = state.file;
  return {
    deleting,
    adding,
    projects,
    project,
    forms,
    form,
    leads,
    lead,
    leadLoaded,
    status,
    statusLoaded,
    members,
    membersLoaded,
    activityLogLoaded,
    notes,
    notesLoaded,
    chats,
    getChatsLoaded,
    email,
    getEmailLoaded,
    getChatNotification,
    user,
    files,
    getfileLoaded,
  };
}

const actionCreators = {
  getLead: leadActions.getLead,
  updateLead: leadActions.updateLead,
  deleteLead: leadActions.deleteLead,
  getAllProjectStatus: projectActions.getAllProjectStatus,
  getAllProjectMembers: projectActions.getAllProjectMembers,
  getActivityLog: leadActions.getActivityLog,
  getLeadNotes: leadActions.getLeadNotes,
  deleteLeadNote: leadActions.deleteLeadNote,
  getLeadSummary: leadActions.getLeadSummary,
  getProject: projectActions.getProject,
  getLeadSession: leadActions.getLeadSession,
  getLeadInterest: leadActions.getLeadInterest,
  getLeadChat: chatActions.getChats,
  getLeadEmail: emailActions.getemails,
  getLeadFiles: fileActions.getFiles,
};

const connectedViewLeadInfo = connect(mapState, actionCreators)(LeadInfo);
export { connectedViewLeadInfo as LeadInfo };