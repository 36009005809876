import { httpManagerService as httpManager } from  '_services';

export const emailService = {
    getLeadEmailConversations,
    updateUnreadEmails,
    agentEmailReply,
    updateThreadMessages,
    agentNewThreadEmail,
    getEmailDrafts,
    saveDraft,
    updateDraft,
    deleteDraft
}


/**
 * Fetches email conversations for a specific lead within a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @returns {Promise} - A promise that resolves to the email conversations data.
 */
function getLeadEmailConversations(projectId, leadId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/lead/${leadId}/email`);
}

/**
 * Updates the unread emails for a specific lead in a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {Object} request - The request payload containing the update details.
 * @returns {Promise} - A promise that resolves when the update is complete.
 */
function updateUnreadEmails(projectId, leadId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/unread`, request);
}

/**
 * Sends an email reply for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {Object} request - The request payload containing the email details.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function agentEmailReply(projectId, leadId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/reply`, request);
}

/**
 * Sends a request to create a new email thread for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {Object} request - The request payload containing email details.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function agentNewThreadEmail(projectId, leadId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/new`, request);
}

/**
 * Updates the thread messages for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {string} thread - The thread identifier to update.
 * @returns {Promise} - A promise that resolves when the thread messages are updated.
 */
function updateThreadMessages(projectId, leadId, thread){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/thread?thread=${thread}`);
}

/**
 * Fetches email drafts for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @returns {Promise<Object>} A promise that resolves to the email drafts.
 */
function getEmailDrafts(projectId, leadId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/lead/${leadId}/email/draft`);
}

/**
 * Saves an email draft for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {Object} request - The request payload containing the draft details.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function saveDraft(projectId, leadId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/draft`, request);
}

/**
 * Updates the email draft for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {Object} request - The request payload containing the draft details.
 * @returns {Promise} - A promise that resolves when the draft is successfully updated.
 */
function updateDraft(projectId, leadId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/draft`, request);
}

/**
 * Deletes an email draft for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {string} draftId - The ID of the email draft to be deleted.
 * @returns {Promise} - A promise that resolves when the draft is successfully deleted.
 */
function deleteDraft(projectId, leadId, draftId){
    return httpManager.deleteJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/email/draft/${draftId}`);
}