import { httpManagerService as httpManager } from '_services';
import { utilitiesService as utility } from '_services';

export const userService = {
  storeUser,
  getUser,
  register,
  login,
  twoFactorEmailVerify,
  twoFactorAppVerify,
  twoFactorResend,
  logout,
  updatePassword,
  forgotPassword,
  twoFactorEmailLogin,
  twoFactorAppLogin,
  sendAppKey,
  twoFactorEmailVerify1
};

/**
 * Stores user data in the HTTP manager.
 * @param {Object} user - The user object to store.
 */
function storeUser(user) {
  httpManager.storeUser(user);
}

/**
 * Retrieves the current user data.
 * @returns {Promise<Object>} A promise resolving to the user data.
 */
function getUser() {
  return httpManager.getUser();
}

/**
 * Registers a new user with encrypted credentials.
 * @param {Object} user - The user registration data.
 * @returns {Promise<Object>} A promise resolving to the registration response.
 */
function register(user) {
  Object.keys(user).forEach(function (propsId) {
    user[propsId] = utility.encrypt(user[propsId]);
  });

  return httpManager.postJsonDataWithRequestingToken('/api/register', user);
}

/**
 * Logs in a user with encrypted credentials.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<Object>} A promise resolving to the login response.
 */
function login(email, password) {
  email = utility.encrypt(email);
  password = utility.encrypt(password);
  httpManager.setUserCredentials(email, password);
  return httpManager.postJsonDataWithRequestingToken('/api/login', { email, password });
}

/**
 * Verifies two-factor authentication via email.
 * @param {Object} request - The verification request data.
 * @returns {Promise<Object>} A promise resolving to the verification status.
 */
function twoFactorEmailVerify1(request) {
  return httpManager.postJsonOnce(`/api/two-factor/verify`, request);
}

/**
 * Initiates a two-factor authentication login via email.
 * @returns {Promise<Object>} A promise resolving to the 2FA email login response.
 */
function twoFactorEmailLogin() {
  return httpManager.getHttpWithRequestingToken(`/api/login/email-2fa`);
}

/**
 * Initiates a two-factor authentication login via an authentication app.
 * @returns {Promise<Object>} A promise resolving to the 2FA app login response.
 */
function twoFactorAppLogin() {
  return httpManager.getHttpWithRequestingToken(`/api/login/app-2fa`);
}

/**
 * Verifies two-factor authentication via email.
 * @param {Object} request - The verification request data.
 * @returns {Promise<Object>} A promise resolving to the verification status.
 */
function twoFactorEmailVerify(request) {
  return httpManager.postJsonOnce(`/api/two-factor/email/verify`, request);
}

/**
 * Verifies two-factor authentication via an authentication app.
 * @param {Object} request - The verification request data.
 * @returns {Promise<Object>} A promise resolving to the verification status.
 */
function twoFactorAppVerify(request) {
  return httpManager.postJsonOnce(`/api/two-factor/app/verify`, request);
}

/**
 * Resends the two-factor authentication code.
 * @returns {Promise<Object>} A promise resolving to the resend status.
 */
function twoFactorResend() {
  return httpManager.getHttpWithRequestingToken(`/api/two-factor/resend`);
}

/**
 * Sends the two-factor authentication app key.
 * @returns {Promise<Object>} A promise resolving to the app key response.
 */
function sendAppKey() {
  return httpManager.getHttpWithRequestingToken(`/api/two-factor/send/app-code`);
}

/**
 * Logs out the current user by removing credentials and tokens.
 */
function logout() {
  httpManager.removeCredentials();
  httpManager.removeTokens();
  localStorage.clear();
  sessionStorage.clear();
}

/**
 * Updates the user's password.
 * @param {Object} account - The account data containing the new password.
 * @returns {Promise<Object>} A promise resolving to the update response.
 */
function updatePassword(account) {
  return httpManager.putJsonDataWithRequestingToken('/api/profile/password', account);
}

/**
 * Initiates a password reset request.
 * @param {Object} request - The password reset request data.
 * @returns {Promise<Object>} A promise resolving to the password reset response.
 */
function forgotPassword(request) {
  return httpManager.putJsonDataWithRequestingToken('/api/password/forgot', request);
}