import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { projectActions } from '_actions';
import { MDBModal, MDBModalBody, MDBContainer, MDBRow, MDBCol, MDBModalHeader } from 'mdbreact';
import { Button } from "blackbox-react";
import InputForm from "_components/InputForm";
import { Link } from "react-router-dom";

const EditLeadStatusModal = ({ modalOpen, modalToggle, data, updateLeadStatus, handleGetProjectStatus, projects }) => {
  const [leadStatusUpdated, setLeadStatusUpdated] = useState(data);

  useEffect(() => {
    setLeadStatusUpdated(data);
  }, [data]);

  const handleStatusChange = (event) => {
    const { name, value } = event.target;
    setLeadStatusUpdated(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUpdateLeadStatus = (event) => {
    event.preventDefault();
    let projectId = projects.projectId;
    let leadStatus = {
      name: data.name,
      description: data.description
    };
    let leadStatusId = data.id;

    if (leadStatusUpdated) {
      leadStatusUpdated = { ...leadStatus, ...leadStatusUpdated };
      updateLeadStatus(leadStatusUpdated, projectId, leadStatusId);
      modalToggle();
      setLeadStatusUpdated({});
    }
    handleGetProjectStatus();
  };

  const hasData = projects?.items?.data?.info !== undefined;

  return (
    <MDBContainer>
      <form onSubmit={handleUpdateLeadStatus} noValidate>
        <MDBModal isOpen={modalOpen} toggle={modalToggle} className='create-form-modal' centered>
          <MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={modalToggle}>
            <p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>EDIT LEAD STATUS</p>
          </MDBModalHeader>
          <MDBModalBody className='p-t--0 padding-left-18 padding-right-18 padding-bottom-15'>
            {hasData && (
              <div>
                <MDBRow>
                  <MDBCol className='form-group m--sm'>
                    <label className='mb-1 font-size-12 text-black'>Lead Status Name <span className='color-red'>*</span></label>
                    <InputForm
                      onChange={handleStatusChange}
                      type='text'
                      name='name'
                      text={data.name}
                      value={leadStatusUpdated.name}
                      hint='Enter Lead Status name'
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label className='mb-1 pt-2 font-size-12 text-black'>Description</label>
                    <textarea
                      onChange={handleStatusChange}
                      id='leadDesc'
                      name='description'
                      className='form-control font-size-12 color-none'
                      value={leadStatusUpdated.description}
                      placeholder='Enter description here...'
                      rows='5'
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-t--md'>
                  <MDBCol className='d-flex'>
                    <span
                      className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12'
                      onClick={modalToggle}
                    >
                      Cancel
                    </span>
                  </MDBCol>
                  <MDBCol className='text-right' id='nextButton'>
                    <Button
                      flat='true'
                      type='submit'
                      size={-1}
                      background='orange'
                    >
                      <Link to={`/project/${projects.projectId}/manage-lead-status`} className='text-white'>
                        UPDATE LEAD STATUS
                      </Link>
                    </Button>
                  </MDBCol>
                </MDBRow>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
      </form>
    </MDBContainer>
  );
};

const mapState = (state) => {
  const { projects } = state;
  return { projects };
};

const actionCreators = {
  updateLeadStatus: projectActions.updateLeadStatus,
  handleGetProjectStatus: projectActions.handleGetProjectStatus
};

const connectedEditLeadStatusModal = connect(mapState, actionCreators)(EditLeadStatusModal);
export { connectedEditLeadStatusModal as EditLeadStatusModal };