import React from 'react';
import { connect } from "react-redux";
import { formActions } from '_actions';

import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdbreact';
//import InputForm from '_components/InputForm'
import { InputField } from "blackbox-react";
// import ReactSlider from 'react-slider';
// import DatePicker from 'react-datepicker';

import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal'

//import { css, cx } from 'emotion';
import addIcon from '_assets/images/plus.png';

class FormFieldUpdate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formIndex: {},
			activeFormIndex: '',
			dropIcon: 'caret-square-up',
			switchEnabled: true,
			dropdownDiv: true,
			isFieldSelected: false,
			isExpanded: true,
			isSelectFieldExpanded: false,
			selectedFieldIndex: 0,
			visibleFormGroups: {},
			activeFormField: this.props.activeFormField,
			startDate: new Date(),
			fieldToDelete: '',
			date: '',
			slider: 0,
			dropdownSelect: '',
			checkbox: '',
			radio: '',
			updatedVal: '',
			isUpdated: false
		};

		this.editField = this.editField.bind(this);
		this.handleActiveFormField = this.handleActiveFormField.bind(this);
	}

	handleToggle = () =>{
		let { modalOpen } = this.state;
		this.setState({modalOpen: !modalOpen})
	};

	handlePassData = (data) => {
		this.setState({
			fieldToDelete: data
		})
	};

	approveModal = (fieldToDelete) => {
		const projectId = this.props.projectId;
		const formId = this.props.formId;
		const formGroupId = this.props.formGroupId;
		let fieldId = fieldToDelete.id;

		const deleteFieldData = {
			label: fieldToDelete.label,
			placeholder: fieldToDelete.placeholder,
			typeId: fieldToDelete.typeId
		};

		this.props.deleteField(projectId, formId, formGroupId, fieldId, deleteFieldData);

		this.setState({
			fieldData: {},
			fieldToDelete: {}
		});

		this.props.handleGetForm();
	};

	indexCallback = data => {
		this.props.indexToDelete(data);
	};

	inputCallback = (data, index) => {
		this.props.inputFieldsCallback(data, index);
	};

	getInputIndex = data => {
		let edit = 'edit';
		this.props.inputIndex(data, edit);
		this.setState({ activeFormIndex: data.formIndex });
	};

	deleteInputField = data => {
		this.props.getDeleteInputField(data);
	};

	editForm = data => {
		this.props.getEditForm(data);
	};

	editField = (data, formGroupId) => {
		this.props.getEditFormField(data, formGroupId);
	};

	moveUp = (newIndex, oldIndex) => {
		this.props.moveUp(newIndex, oldIndex)
	};

	moveInputUp = (newIndex, oldIndex, formIndex) => {
		this.props.moveInputUp(newIndex, oldIndex, formIndex)
	};

	getFormFieldButtons = (value) => {
		this.props.getFormFieldButtons(value);
	};

	handleAccordionToggle = (formGroupId) => {
		let isExpanded = this.state.isExpanded;

		isExpanded ?
			this.setState({ isExpanded: false }) :
			this.setState({ isExpanded: true })
	};

	/*--------------------------------*/

	mapTypeId = (typeId, fieldData) => {
		switch (typeId) {
			case 1:
				return this.renderTextInput(fieldData);
			case 2:
				return this.renderTextarea(fieldData);
			case 3:
				return this.renderRadioButton(fieldData);
			case 4:
				return this.renderCheckbox(fieldData);
			case 5:
				return this.renderSelect(fieldData);
			case 6:
				return this.renderSwitch(fieldData);
			case 7:
				return this.renderSlider(fieldData);
			case 8:
				return this.renderDatepicker(fieldData);
			default:
				return null;
		}
	};

	renderTextInput = (fieldData) => {
		//console.log('renderTextInput fieldData', fieldData);
		let updatedVal = (this.state.updatedVal && this.state.isUpdated) ? this.state.updatedVal : fieldData.value;
		//console.log('updated_value_state', this.state.updatedVal)
		//console.log('updated_value', updatedVal)
		return (
			<div className="form-field p--0">
				<MDBRow>
					<MDBCol md='8' className='p-l--0'>
						<InputField
							type='text'
							id={fieldData.field_id.toString() || ''}
							name={fieldData.field_id.toString() || ''}
							className={`${fieldData.read_only && 'disabled'} font-weight-600`}
							hint={fieldData.placeholder}
							onChange={(val) => {
								this.setState({
									updatedVal: val,
									isUpdated: true
								})
							}}
							defaultValue={fieldData.value || ''}
						/>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-orange font-weight-bold shadow-none color-white py-2 px-2 br-3 m--0'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdate(updatedVal, fieldData.field_id || '');
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							SAVE
						</MDBBtn>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-gray font-weight-bold shadow-none color-white py-2 px-2 br-3 m-t--0 m-b--0 m-r--0 m-l--sm'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdateLeadField();
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							<span className="color-dark">Cancel</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</div>
		);
	};

	renderTextarea = (fieldData) => {
		//console.log('renderTextarea fieldData', fieldData);
		let updatedVal = (this.state.updatedVal && this.state.isUpdated) ? this.state.updatedVal : fieldData.value;
		return (
			<div className="form-field p--0">
				<MDBRow>
					<MDBCol md='8' className='p-l--0'>
						<textarea
							id={fieldData.field_id.toString() || ''}
							name={fieldData.field_id.toString() || ''}
							//disabled={fieldData.read_only || ''}
							className='py-1 px-2 font-size-12'
							rows='7'
							defaultValue={fieldData.value || ''}
							placeholder={fieldData.placeholder}
							onChange={(event) => {
								this.setState({
									updatedVal: event.target.value,
									isUpdated: true
								})
							}}
						/>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-orange font-weight-bold shadow-none color-white py-2 px-2 br-3 m--0'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdate(updatedVal, fieldData.field_id || '');
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							SAVE
						</MDBBtn>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-gray font-weight-bold shadow-none color-white py-2 px-2 br-3 m-t--0 m-b--0 m-r--0 m-l--sm'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdateLeadField();
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							<span className="color-dark">Cancel</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>

			</div>
		);
	};

	renderRadioButton = (fieldData) => {
		let updatedVal = (this.state.updatedVal && this.state.isUpdated) ? this.state.updatedVal : fieldData.value;

		return (
			<div className="form-field p--0">
				<MDBRow>
					<MDBCol md='8' className='p-l--0'>
						<InputField
							type='text'
							id={fieldData.field_id.toString() || ''}
							name={fieldData.field_id.toString() || ''}
							className={`${fieldData.read_only && 'disabled'} font-weight-600`}
							hint={fieldData.placeholder}
							onChange={(val) => {
								this.setState({
									updatedVal: val,
									isUpdated: true
								})
							}}
							defaultValue={fieldData.value || ''}
						/>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-orange font-weight-bold shadow-none color-white py-2 px-2 br-3 m--0'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdate(updatedVal, fieldData.field_id || '');
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							SAVE
						</MDBBtn>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-gray font-weight-bold shadow-none color-white py-2 px-2 br-3 m-t--0 m-b--0 m-r--0 m-l--sm'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdateLeadField();
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							<span className="color-dark">Cancel</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</div>
		);
	};

	renderCheckbox = (fieldData) => {
		//console.log('renderCheckbox fieldData', fieldData);

		let updatedVal = (this.state.updatedVal && this.state.isUpdated) ? this.state.updatedVal : fieldData.value;

		return (
			<div className="form-field p--0">
				<MDBRow>
					<MDBCol md='8' className='p-l--0'>
						<InputField
							type='text'
							id={fieldData.field_id.toString() || ''}
							name={fieldData.field_id.toString() || ''}
							className={`${fieldData.read_only && 'disabled'} font-weight-600`}
							hint={fieldData.placeholder}
							onChange={(val) => {
								this.setState({
									updatedVal: val,
									isUpdated: true
								})
							}}
							defaultValue={fieldData.value || ''}
						/>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-orange font-weight-bold shadow-none color-white py-2 px-2 br-3 m--0'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdate(updatedVal, fieldData.field_id || '');
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							SAVE
						</MDBBtn>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-gray font-weight-bold shadow-none color-white py-2 px-2 br-3 m-t--0 m-b--0 m-r--0 m-l--sm'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdateLeadField();
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							<span className="color-dark">Cancel</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</div>
		);
	};

	renderSelect = (fieldData) => {
		//console.log('renderSelect fieldData', fieldData);
		let updatedVal = (this.state.updatedVal && this.state.isUpdated) ? this.state.updatedVal : fieldData.value;
		// let options = fieldData.option;
		// if(options === null){
		// 	options = [];
		// } else{
		// 	options = options.sort((a, b) => a.id > b.id ? 1 : -1); //oldest first
		// }

		// let hasOptions = options.length > 0;
		// let selectOptions = [];
		//
		// if(hasOptions){
		// 	options.map(
		// 		(value, index) => {
		// 			selectOptions.push({
		// 				id: value.id,
		// 				value: value.option,
		// 				label: value.option
		// 			});
		// 			return selectOptions;
		// 		})
		// }

		return(
			<div className="form-field p--0">
				<MDBRow>
					<MDBCol md='8' className='p-l--0'>
						<InputField
							type='text'
							id={fieldData.field_id.toString() || ''}
							name={fieldData.field_id.toString() || ''}
							className={`${fieldData.read_only && 'disabled'} font-weight-600`}
							hint={fieldData.placeholder}
							onChange={(val) => {
								this.setState({
									updatedVal: val,
									isUpdated: true
								})
							}}
							defaultValue={fieldData.value || ''}
						/>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-orange font-weight-bold shadow-none color-white py-2 px-2 br-3 m--0'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdate(updatedVal, fieldData.field_id || '');
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							SAVE
						</MDBBtn>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-gray font-weight-bold shadow-none color-white py-2 px-2 br-3 m-t--0 m-b--0 m-r--0 m-l--sm'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdateLeadField();
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							<span className="color-dark">Cancel</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</div>
		);
	};

	renderSwitch = (fieldData) => {
		// const switchCss = cx(
		// 	css`
    //     &:before {
    //       content: '${fieldData.on_label || 'ON'}';
    //     }
    //
    //     &:after{
    //       content: '${fieldData.off_label || 'OFF'}';
    //     }
    //   `
		// );
		let updatedVal = (this.state.updatedVal && this.state.isUpdated) ? this.state.updatedVal : fieldData.value;
		return (
			<div className="form-field p--0">
				<MDBRow>
					<MDBCol md='8' className='p-l--0'>
						<InputField
							type='text'
							id={fieldData.field_id.toString() || ''}
							name={fieldData.field_id.toString() || ''}
							className={`${fieldData.read_only && 'disabled'} font-weight-600`}
							hint={fieldData.placeholder}
							onChange={(val) => {
								this.setState({
									updatedVal: val,
									isUpdated: true
								})
							}}
							defaultValue={fieldData.value || ''}
						/>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-orange font-weight-bold shadow-none color-white py-2 px-2 br-3 m--0'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdate(updatedVal, fieldData.field_id || '');
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							SAVE
						</MDBBtn>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-gray font-weight-bold shadow-none color-white py-2 px-2 br-3 m-t--0 m-b--0 m-r--0 m-l--sm'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdateLeadField();
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							<span className="color-dark">Cancel</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</div>
		);
	};

	renderSlider = (fieldData) => {
		let updatedVal = (this.state.updatedVal && this.state.isUpdated) ? this.state.updatedVal : fieldData.value;
		return (
			<div className="form-field p--0">
				<MDBRow>
					<MDBCol md='8' className='p-l--0'>
						<InputField
							type='text'
							id={fieldData.field_id.toString() || ''}
							name={fieldData.field_id.toString() || ''}
							className={`${fieldData.read_only && 'disabled'} font-weight-600`}
							hint={fieldData.placeholder}
							onChange={(val) => {
								this.setState({
									updatedVal: val,
									isUpdated: true
								})
							}}
							defaultValue={fieldData.value || ''}
						/>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-orange font-weight-bold shadow-none color-white py-2 px-2 br-3 m--0'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdate(updatedVal, fieldData.field_id || '');
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							SAVE
						</MDBBtn>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-gray font-weight-bold shadow-none color-white py-2 px-2 br-3 m-t--0 m-b--0 m-r--0 m-l--sm'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdateLeadField();
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							<span className="color-dark">Cancel</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</div>
		);
	};

	handleDatePickerChange = (date) => {
		this.setState({
			startDate: date
		});
	};

	renderDatepicker = (fieldData) => {
		let updatedVal = (this.state.updatedVal && this.state.isUpdated) ? this.state.updatedVal : fieldData.value;

		return (
			<div className="form-field p--0">
				<MDBRow>
					<MDBCol md='8' className='p-l--0'>
						<InputField
							type='text'
							id={fieldData.field_id.toString() || ''}
							name={fieldData.field_id.toString() || ''}
							className={`${fieldData.read_only && 'disabled'} font-weight-600`}
							hint={fieldData.placeholder}
							onChange={(val) => {
								this.setState({
									updatedVal: val,
									isUpdated: true
								})
							}}
							defaultValue={fieldData.value || ''}
						/>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-orange font-weight-bold shadow-none color-white py-2 px-2 br-3 m--0'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdate(updatedVal, fieldData.field_id || '');
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							SAVE
						</MDBBtn>
					</MDBCol>
					<MDBCol md='2' className='p--0'>
						<MDBBtn
							flat
							type="submit"
							className='background-color-gray font-weight-bold shadow-none color-white py-2 px-2 br-3 m-t--0 m-b--0 m-r--0 m-l--sm'
							onClick={(e) => {
								e.preventDefault();
								this.props.handleUpdateLeadField();
								this.setState({
									updatedVal: '',
									isUpdated: false
								})
							}}
						>
							<span className="color-dark">Cancel</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</div>
		);
	};

	/*--------------------------------*/

	handleActiveFormField = (activeFormField) => {
		this.props.handleActiveFormField(activeFormField);
	};

	handleFormFieldReorder = (data) => {
		const formGroupId = this.props.formGroupId;
		const formGroupData = this.props.formGroupData;
		let formFields = formGroupData.field;
		let originFormField = data.originFormField;
		let targetFormField = data.targetFormField;

		let searchTargetFormField = formFields.filter((o) => { return o.form_order === targetFormField.form_order });
		targetFormField = searchTargetFormField[0];

		if(typeof targetFormField !== 'undefined'){
			let originOrder = originFormField.form_order;
			let targetOrder = targetFormField.form_order;

			originFormField = {
				id: originFormField.id,
				form_order: targetOrder
			};

			targetFormField = {
				id: targetFormField.id,
				form_order: originOrder
			};

			let reorderRequestData = {
				reorder: [originFormField, targetFormField]
			};

			this.props.handleFormFieldReorder(reorderRequestData, formGroupId);
		}
	};


	handleAddFormField = (index) => {
		this.setState({
			isSelectFieldExpanded: true,
			selectedFieldIndex: index
		});
	};

	renderFormFields = (value, index, totalNum, formGroupId) => {
		//console.log('value_update_formfield', value)
		let activeFormField = `formField${value.field_id}`;
		//console.log('active_form_field', activeFormField)
		//console.log('form_field_index', index)

		return (
			<MDBRow key={index} id={activeFormField}>
				<MDBCol xl='12' id={`inputFieldsArea${value.field_id}`} className='p--0'>
					<MDBRow className='preview__container form-field p--0'>
						<MDBCol xl='12' className='p-l--0 p-r--0 m--0'>
							<div onClick={(e) => { e.preventDefault(); this.editField(value, formGroupId); }}>
								{this.mapTypeId(value.type_id, value)}
							</div>
						</MDBCol>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	};

	handleCreateFormField = (value, index, totalNum) => {
		let formGroupData = this.props.formGroupData;
		this.props.handleActiveFormGroup(`formGroup${formGroupData.id}`);
		this.getFormFieldButtons(value);
		localStorage.setItem('fieldIndex', index);
		localStorage.setItem('fieldTotalNum', totalNum);
	};

	renderAddFormField = (value, index, totalNum, toggle) => {
		let isSelectFieldExpanded = this.state.isSelectFieldExpanded;
		let selectedFieldIndex = this.state.selectedFieldIndex;
		let selectedInput = this.props.selectedInput;

		return (
			<div>
				<div className={ isSelectFieldExpanded && (selectedFieldIndex === index) ? (selectedInput ? 'display-hover' : 'display-visible') : 'display-hover'}>
					<p
						id='add'
						className='font-size-12 mb-0 color-orange'
						onClick={(e) => {
							e.stopPropagation();
							this.props.handleActiveFormField('');
							this.handleAddFormField(index);
							this.handleCreateFormField(value, index, totalNum);
							this.props.handleSelectedInput(false);
						}}
					>
						<img alt='' src={addIcon} className='add-icon-width-18 mr-1' />
						Add Field
					</p>
				</div>
				{/*{ isSelectFieldExpanded && (selectedFieldIndex === index) ?*/}
				{/*  <MDBCol xl='11' lg='11' className={ this.state.isExpanded ? 'px-0 m-t--md m-b--md' : 'display-none'}>*/}
				{/*    <MDBBtn*/}
				{/*      id={`selectFieldToAdd${index}`}*/}
				{/*      onClick={(e) => {*/}
				{/*        e.stopPropagation();*/}
				{/*        this.handleCreateFormField(value, index, totalNum);*/}
				{/*      }}*/}
				{/*      flat*/}
				{/*      className='ml-3 dotted add-field-button add-field-background m-0 mb-1 color-orange shadow-none button-presets'*/}
				{/*    >*/}
				{/*      <img alt='' src={addIcon} className='add-icon-width-18 mr-2'/>*/}
				{/*      Select a Field to Add*/}
				{/*    </MDBBtn>*/}
				{/*  </MDBCol>*/}
				{/*  :*/}
				{/*  <div className={index === value.length - 1 ? 'display-invisible' : 'display-hover'}>*/}
				{/*    <p*/}
				{/*      id='add'*/}
				{/*      className='font-size-12 mb-0 color-orange'*/}
				{/*      onClick={(e) => {*/}
				{/*        e.stopPropagation();*/}
				{/*        this.props.handleActiveFormField('');*/}
				{/*        this.handleAddFormField(index);*/}
				{/*        this.handleCreateFormField(value, index, totalNum);*/}
				{/*      }}*/}
				{/*    >*/}
				{/*      <img alt='' src={addIcon} className='add-icon-width-18 mr-1' />*/}
				{/*      Add Field*/}
				{/*    </p>*/}
				{/*  </div>*/}
				{/*}*/}
			</div>
		);
	};

	renderFormFieldReorder = (value, index, totalNum) => {
		return (
			<MDBRow className='form-group__reorder'>
				<MDBIcon
					icon='caret-up'
					className={index !== 0 || index + 1 === totalNum ? 'icon-move--up hand' : 'icon-move--up disabled color-gray'}
					onClick={() => {
						this.handleFormFieldReorder({
							originFormField: value,
							targetFormField: {
								form_order: value.form_order - 1
							},
							direction: 'up'
						});
					}}
				/>
				<MDBIcon
					icon='caret-down'
					className={index === value.length - 1 || index + 1 === totalNum ? 'icon-move--down disabled color-gray' : 'icon-move--down hand'}
					onClick={() => {
						this.handleFormFieldReorder({
							originFormField: value,
							targetFormField: {
								form_order: value.form_order + 1
							},
							direction: 'down'
						});
					}}
				/>
			</MDBRow>
		);
	};

	renderFormFieldActions = (value, index) => {
		let actionIconClass = this.state.dropdownDiv ? 'px-0 action-icons' : 'hide';

		return (
			<MDBRow className='form-group__actions'>
				<MDBCol className={actionIconClass}>
					<MDBCol className='px-0'>
						<MDBIcon
							far
							icon='trash-alt'
							className='icon-delete color-red opacity-5 hand'
							onClick={(event) => { event.preventDefault(); this.handleToggle(); this.handlePassData(value) }}
						/>
					</MDBCol>
				</MDBCol>
			</MDBRow>
		);
	};

	render() {
		let formGroupData = this.props.formGroupData;
		let formFieldData = this.props.formFieldData;
		let formFieldKey = this.props.formFieldKey;
		let totalFormFields = this.props.totalFormFields;
		let activeFormField = this.props.activeFormField;
		let hasData = !!formFieldData;
		let formFieldId = `formField${formFieldData.id}`;
		let formGroupLayout = formGroupData.layout;

		return (
			<MDBCol md={`${12 / formGroupLayout}`} className='p--0'>
				<div className={`form-field-group ${(formFieldId === activeFormField) ? 'active' : ' '}`}>
					{ hasData ? this.renderFormFields(formFieldData, formFieldKey, totalFormFields, formGroupData.id) : null }
				</div>
				<DeleteConfirmationModal
					toggle={this.handleToggle}
					modalOpen={this.state.modalOpen}
					statusToDelete={this.state.fieldToDelete}
					onApprove={this.approveModal}
					target={formFieldData.label}
					nameToDelete={formFieldData.label}
				/>
			</MDBCol>
		);
	}
}

function mapState(state) {
	const { creating, forms, deleting, deleted } = state.forms;
	return { creating, forms, deleting, deleted };
}

const actionCreators = {
	deleteField: formActions.deleteField
};

const connectedFormFieldUpdate = connect(mapState, actionCreators)(FormFieldUpdate);
export { connectedFormFieldUpdate as FormFieldUpdate };
