import {influencerConstants} from '_constants';
import {influencerService} from '_services';
import {alertActions} from './';
import {history} from '_helpers';

export const influencerActions = {
    getInfluencerSummary,
    getInfluencerList,
    createInfluencer,
    getInfluencer,
    updateInfluencer,
    updateInfluencerStatus,
    deleteInfluencer,
    addInfluencerPlatform,
    getInfluencerPlatform,
    updateInfluencerPlatform,
    deleteInfluencerPlatform,
    updateInfluencerClients,
    getInfluencerClients,
    updateInfluencerCategories,
    addInfluencerDocument,
    getInfluencerDocument,
    updateInfluencerDocument,
    deleteInfluencerDocument,
    addInfluencerRate,
    getInfluencerRates,
    updateInfluencerRate,
    deleteInfluencerRate,
    addInfluencerNote,
    getInfluencerNotes,
    updateInfluencerNote,
    deleteInfluencerNote,
    getInfluencerActivities,
    getClients,
    getPlatformTypes,
    getCategories,
    updateInfluencerResponsible,
}

/**
 * Fetches the influencer summary for a specific project within a given date range and dispatches actions based on the result.
 * 
 * @param {string} project_id - The ID of the project for which the influencer summary is being fetched.
 * @param {string|null} [dateFrom=null] - The start date for the summary range (optional).
 * @param {string|null} [dateTo=null] - The end date for the summary range (optional).
 * @returns {Function} A thunk function that dispatches actions for request, success, or failure states.
 */
function getInfluencerSummary(project_id, dateFrom = null, dateTo = null) {
    return dispatch => {
        dispatch(request(project_id));
        influencerService.getInfluencerSummary(project_id, dateFrom, dateTo)
            .then(
                summary => {
                    dispatch(success(summary));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.SUMMARY_REQUEST, project_id}
    }

    function success(summary) {
        return {type: influencerConstants.SUMMARY_SUCCESS, summary}
    }

    function failure(error) {
        return {type: influencerConstants.SUMMARY_FAILURE, error}
    }
}

/**
 * Fetches a paginated and filtered list of influencers for a specific project and dispatches actions based on the result.
 * 
 * @param {string} projectId - The ID of the project for which the influencer list is being fetched.
 * @param {number|null} [page=null] - The page number for pagination (optional).
 * @param {number|null} [size=null] - The number of influencers per page (optional).
 * @param {string|null} [query=null] - A search query to filter influencers by name or other fields (optional).
 * @param {Array<string>} [status=[]] - An array of statuses to filter influencers by (optional).
 * @param {Array<string>} [platform=[]] - An array of platforms to filter influencers by (optional).
 * @param {Array<string>} [category=[]] - An array of categories to filter influencers by (optional).
 * @param {string|null} [sort=null] - The field to sort the influencers by (optional).
 * @param {string|null} [direction=null] - The sort direction (e.g., 'asc' or 'desc') (optional).
 * @param {string|null} [dateFrom=null] - The start date for filtering influencers by date range (optional).
 * @param {string|null} [dateTo=null] - The end date for filtering influencers by date range (optional).
 * @returns {Function} A thunk function that dispatches actions for request, success, or failure states.
 */
function getInfluencerList(projectId, page = null, size = null, query = null, status = [], platform = [], category = [], sort = null, direction = null, dateFrom = null, dateTo = null) {
    return dispatch => {
        dispatch(request(projectId));
        influencerService.getInfluencerList(projectId, page, size, query, status, platform, category, sort, direction, dateFrom, dateTo)
            .then(
                influencers => {
                    dispatch(success(influencers));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.GET_ALL_REQUEST, project_id}
    }

    function success(influencers) {
        return {type: influencerConstants.GET_ALL_SUCCESS, influencers}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_FAILURE, error}
    }
}

/**
 * Creates a new influencer for a specific project and dispatches actions based on the result.
 * On success, it redirects to the newly created influencer's page and shows a success message.
 * 
 * @param {string} project_id - The ID of the project to which the influencer will be added.
 * @param {Object} influencer - The influencer data to be created.
 * @returns {Function} A thunk function that dispatches actions for request, success, or failure states.
 */
function createInfluencer(project_id, influencer) {
    return dispatch => {
        dispatch(request(project_id, influencer));
        influencerService.createInfluencer(project_id, influencer)
            .then(
                influencer => {
                    history.push(`/project/${project_id}/influencers/${influencer.data.id}`);
                    dispatch(success(influencer));
                    dispatch(alertActions.success(`Successfully created influencer.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencer) {
        return {type: influencerConstants.CREATE_REQUEST, project_id, influencer}
    }

    function success(influencer) {
        return {type: influencerConstants.CREATE_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.CREATE_FAILURE, error}
    }
}

/**
 * Fetches an influencer's details based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function getInfluencer(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencer(project_id, influencerId)
            .then(
                influencer => {
                    dispatch(success(influencer));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_REQUEST, project_id, influencerId}
    }

    function success(influencer) {
        return {type: influencerConstants.GET_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.GET_FAILURE, error}
    }
}

/**
 * Updates an influencer's details based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {Object} influencer - The updated influencer data.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function updateInfluencer(project_id, influencerId, influencer) {
    return dispatch => {
        dispatch(request(project_id, influencerId, influencer));
        influencerService.updateInfluencer(project_id, influencerId,  influencer)
            .then(
                influencer => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(influencer));
                    dispatch(alertActions.success(`Successfully updated influencer.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, influencer) {
        return {type: influencerConstants.UPDATE_REQUEST, project_id, influencerId, influencer}
    }

    function success(influencer) {
        return {type: influencerConstants.UPDATE_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_FAILURE, error}
    }
}

/**
 * Updates the status of an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} status - The new status of the influencer.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function updateInfluencerStatus(project_id, influencerId, status) {
    return dispatch => {
        dispatch(request(project_id, influencerId, status));
        influencerService.updateInfluencerStatus(project_id, influencerId, status)
            .then(
                influencer => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(influencer));
                    dispatch(alertActions.success(`Successfully updated influencer status.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, status) {
        return {type: influencerConstants.UPDATE_STATUS_REQUEST, project_id, influencerId, status}
    }

    function success(influencer) {
        return {type: influencerConstants.UPDATE_STATUS_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_STATUS_FAILURE, error}
    }
}

/**
 * Deletes an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function deleteInfluencer(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.deleteInfluencer(project_id, influencerId)
            .then(
                influencers => {
                    dispatch(success(influencers));
                    dispatch(alertActions.success(`Successfully deleted influencer.`));
                    dispatch(getInfluencerList(project_id));
                    history.push(`/project/${project_id}/influencers`);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.DELETE_REQUEST, project_id, influencerId}
    }

    function success(influencer) {
        return {type: influencerConstants.DELETE_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_FAILURE, error}
    }
}

/**
 * Adds a platform to an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} platform - The platform to be added.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function addInfluencerPlatform(project_id, influencerId, platform) {
    return dispatch => {
        dispatch(request(project_id, influencerId, platform));
        influencerService.addInfluencerPlatform(project_id, influencerId,  platform)
            .then(
                platforms => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(platforms));
                    dispatch(alertActions.success(`Successfully added influencer platform.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, platform) {
        return {type: influencerConstants.ADD_PLATFORM_REQUEST, project_id, influencerId, platform}
    }

    function success(platforms) {
        return {type: influencerConstants.ADD_PLATFORM_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.ADD_PLATFORM_FAILURE, error}
    }
}

/**
 * Fetches all platforms associated with an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function getInfluencerPlatform(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerPlatform(project_id, influencerId)
            .then(
                platforms => {
                    dispatch(success(platforms));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_PLATFORM_REQUEST, project_id, influencerId}
    }

    function success(platforms) {
        return {type: influencerConstants.GET_ALL_PLATFORM_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_PLATFORM_FAILURE, error}
    }
}

/**
 * Updates a platform associated with an influencer based on project ID, influencer ID, and platform ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} platformId - The ID of the platform.
 * @param {Object} platform - The updated platform data.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function updateInfluencerPlatform(project_id, influencerId, platformId, platform) {
    return dispatch => {
        dispatch(request(project_id, influencerId, platformId, platform));
        influencerService.updateInfluencerPlatform(project_id, influencerId, platformId,  platform)
            .then(
                platforms => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(platforms));
                    dispatch(alertActions.success(`Successfully updated influencer platform.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, platformId,  platform) {
        return {type: influencerConstants.UPDATE_PLATFORM_REQUEST, project_id, influencerId, platformId,  platform}
    }

    function success(platforms) {
        return {type: influencerConstants.UPDATE_PLATFORM_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_PLATFORM_FAILURE, error}
    }
}

/**
 * Deletes a platform associated with an influencer based on project ID, influencer ID, and platform ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} platformId - The ID of the platform to be deleted.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function deleteInfluencerPlatform(project_id, influencerId, platformId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, platformId));
        influencerService.deleteInfluencerPlatform(project_id, influencerId, platformId)
            .then(
                platforms => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(platforms));
                    dispatch(alertActions.success(`Successfully deleted influencer platform.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, platformId) {
        return {type: influencerConstants.DELETE_PLATFORM_REQUEST, project_id, influencerId, platformId}
    }

    function success(platforms) {
        return {type: influencerConstants.DELETE_PLATFORM_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_PLATFORM_FAILURE, error}
    }
}

/**
 * Updates the clients associated with an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {Array} clients - The list of clients to update.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function updateInfluencerClients(project_id, influencerId, clients) {
    return dispatch => {
        dispatch(request(project_id, influencerId, clients));
        influencerService.updateInfluencerClients(project_id, influencerId,  clients)
            .then(
                clients => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(clients));
                    dispatch(alertActions.success(`Successfully updated influencer clients.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, client) {
        return {type: influencerConstants.UPDATE_CLIENT_REQUEST, project_id, influencerId, clients}
    }

    function success(clients) {
        return {type: influencerConstants.UPDATE_CLIENT_SUCCESS, clients}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_CLIENT_FAILURE, error}
    }
}

/**
 * Fetches all clients associated with an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function getInfluencerClients(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerClients(project_id, influencerId)
            .then(
                clients => {
                    dispatch(success(clients));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_CLIENT_REQUEST, project_id, influencerId}
    }

    function success(clients) {
        return {type: influencerConstants.GET_ALL_CLIENT_SUCCESS, clients}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_CLIENT_FAILURE, error}
    }
}

/**
 * Updates the categories associated with an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {Array} categories - The list of categories to update.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function updateInfluencerCategories(project_id, influencerId, categories) {
    return dispatch => {
        dispatch(request(project_id, influencerId, categories));
        influencerService.updateInfluencerCategories(project_id, influencerId,  categories)
            .then(
                categories => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(getInfluencer(project_id, influencerId));
                    dispatch(success(categories));
                    dispatch(alertActions.success(`Successfully updated influencer categories.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, categories) {
        return {type: influencerConstants.UPDATE_CATEGORIES_REQUEST, project_id, influencerId, categories}
    }

    function success(categories) {
        return {type: influencerConstants.UPDATE_CATEGORIES_SUCCESS, categories}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_CATEGORIES_FAILURE, error}
    }
}

/**
 * Fetches all categories associated with an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function getInfluencerCategories(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerCategories(project_id, influencerId)
            .then(
                categories => {
                    dispatch(success(categories));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_CATEGORIES_REQUEST, project_id, influencerId}
    }

    function success(categories) {
        return {type: influencerConstants.GET_ALL_CATEGORIES_SUCCESS, categories}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_CATEGORIES_FAILURE, error}
    }
}

/**
 * Adds a document associated with an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {Object} document - The document to be added.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function addInfluencerDocument(project_id, influencerId, document) {
    return dispatch => {
        dispatch(request(project_id, influencerId, document));
        influencerService.addInfluencerDocument(project_id, influencerId,  document)
            .then(
                documents => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(documents));
                    dispatch(alertActions.success(`Successfully added influencer document.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, document) {
        return {type: influencerConstants.ADD_DOCUMENT_REQUEST, project_id, influencerId, document}
    }

    function success(documents) {
        return {type: influencerConstants.ADD_DOCUMENT_SUCCESS, documents}
    }

    function failure(error) {
        return {type: influencerConstants.ADD_DOCUMENT_FAILURE, error}
    }
}

/**
 * Fetches all documents associated with an influencer based on project ID and influencer ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function getInfluencerDocument(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerDocument(project_id, influencerId)
            .then(
                documents => {
                    dispatch(success(documents));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_DOCUMENTS_REQUEST, project_id, influencerId}
    }

    function success(documents) {
        return {type: influencerConstants.GET_ALL_DOCUMENTS_SUCCESS, documents}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_DOCUMENTS_FAILURE, error}
    }
}

/**
 * Updates a document associated with an influencer based on project ID, influencer ID, and document ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} documentId - The ID of the document to be updated.
 * @param {Object} document - The updated document data.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function updateInfluencerDocument(project_id, influencerId, documentId, document) {
    return dispatch => {
        dispatch(request(project_id, influencerId, documentId, document));
        influencerService.updateInfluencerDocument(project_id, influencerId, documentId,  document)
            .then(
                documents => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(documents));
                    dispatch(alertActions.success(`Successfully updated influencer document.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, platformId, document) {
        return {type: influencerConstants.UPDATE_DOCUMENT_REQUEST, project_id, influencerId, platformId, document}
    }

    function success(documents) {
        return {type: influencerConstants.UPDATE_DOCUMENT_SUCCESS, documents}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_DOCUMENT_FAILURE, error}
    }
}

/**
 * Deletes a document associated with an influencer based on project ID, influencer ID, and document ID.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} documentId - The ID of the document to be deleted.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function deleteInfluencerDocument(project_id, influencerId, documentId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, documentId));
        influencerService.deleteInfluencerDocument(project_id, influencerId, documentId)
            .then(
                documents => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(alertActions.success(`Successfully deleted influencer document.`))
                    dispatch(success(documents));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, documentId) {
        return {type: influencerConstants.DELETE_DOCUMENT_REQUEST, project_id, influencerId, documentId}
    }

    function success(documents) {
        return {type: influencerConstants.DELETE_DOCUMENT_SUCCESS, documents}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_DOCUMENT_FAILURE, error}
    }
}

/**
 * Adds a rate for an influencer in a given project.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {object} rate - The rate details to be added.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function addInfluencerRate(project_id, influencerId, rate) {
    return dispatch => {
        dispatch(request(project_id, influencerId, rate));
        influencerService.addInfluencerRate(project_id, influencerId,  rate)
            .then(
                rates => {
                    dispatch(success(rates));
                    dispatch(alertActions.success(`Successfully added influencer rate.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, rate) {
        return {type: influencerConstants.ADD_RATE_REQUEST, project_id, influencerId, rate}
    }

    function success(rates) {
        return {type: influencerConstants.ADD_RATE_SUCCESS, rates}
    }

    function failure(error) {
        return {type: influencerConstants.ADD_RATE_FAILURE, error}
    }
}

/**
 * Retrieves rates for an influencer in a given project.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} rateId - The ID of the specific rate.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function getInfluencerRates(project_id, influencerId, rateId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, rateId));
        influencerService.getInfluencerRates(project_id, influencerId, rateId)
            .then(
                rates => {
                    dispatch(success(rates));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, rateId) {
        return {type: influencerConstants.GET_ALL_RATE_REQUEST, project_id, influencerId, rateId}
    }

    function success(rates) {
        return {type: influencerConstants.GET_ALL_RATE_SUCCESS, rates}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_RATE_FAILURE, error}
    }
}

/**
 * Updates the rate for an influencer in a given project.
 * 
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} rateId - The ID of the specific rate.
 * @param {Object} rate - The rate details to be updated.
 * @returns {Function} A redux-thunk function that dispatches actions.
 */
function updateInfluencerRate(project_id, influencerId, rateId, rate) {
    return dispatch => {
        dispatch(request(project_id, influencerId, rateId, rate));
        influencerService.updateInfluencerRate(project_id, influencerId, rateId,  rate)
            .then(
                rates => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(rates));
                    dispatch(alertActions.success(`Successfully updated influencer rate.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, rateId, rate) {
        return {type: influencerConstants.UPDATE_RATE_REQUEST, project_id, influencerId, rateId, rate}
    }

    function success(rates) {
        return {type: influencerConstants.UPDATE_RATE_SUCCESS, rates}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_RATE_FAILURE, error}
    }
}

/**
 * Deletes an influencer rate and updates the state accordingly.
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} rateId - The ID of the rate to delete.
 * @returns {Function} A Redux thunk function that dispatches actions based on the API response.
 */
function deleteInfluencerRate(project_id, influencerId, rateId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, rateId));
        influencerService.deleteInfluencerRate(project_id, influencerId, rateId)
            .then(
                rates => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(rates));
                    dispatch(alertActions.success(`Successfully deleted influencer rate.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.DELETE_RATE_REQUEST, project_id, influencerId, rateId}
    }

    function success(rates) {
        return {type: influencerConstants.DELETE_RATE_SUCCESS, rates}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_RATE_FAILURE, error}
    }
}

/**
 * Adds a note to an influencer.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {Object} note - The note object to be added.
 * @returns {Function} A Redux thunk action.
 */
function addInfluencerNote(project_id, influencerId, note) {
    return dispatch => {
        dispatch(request(project_id, influencerId, note));
        influencerService.addInfluencerNote(project_id, influencerId,  note)
            .then(
                rates => {
                    dispatch(success(rates));
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(alertActions.success(`Successfully added influencer note.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, note) {
        return {type: influencerConstants.ADD_NOTE_REQUEST, project_id, influencerId, note}
    }

    function success(notes) {
        return {type: influencerConstants.ADD_NOTE_SUCCESS, notes}
    }

    function failure(error) {
        return {type: influencerConstants.ADD_NOTE_FAILURE, error}
    }
}

/**
 * Fetches all notes associated with an influencer.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @returns {Function} A Redux thunk action.
 */
function getInfluencerNotes(project_id, influencerId) {
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerNotes(project_id, influencerId)
            .then(
                notes => {
                    dispatch(success(notes));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_NOTES_REQUEST, project_id, influencerId}
    }

    function success(notes) {
        return {type: influencerConstants.GET_ALL_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_NOTES_FAILURE, error}
    }
}

/**
 * Updates the note for an influencer in a specific project.
 * Dispatches various actions based on the success or failure of the update.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} noteId - The ID of the note to be updated.
 * @param {string} note - The new content of the note.
 * @returns {function} - A function that dispatches actions to update the influencer note.
 */
function updateInfluencerNote(project_id, influencerId, noteId, note) {
    return dispatch => {
        dispatch(request(project_id, influencerId, noteId, note));
        influencerService.updateInfluencerNote(project_id, influencerId, noteId,  note)
            .then(
                notes => {
                    dispatch(success(notes));
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(alertActions.success(`Successfully updated influencer note.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, noteId, note) {
        return {type: influencerConstants.UPDATE_NOTE_REQUEST, project_id, influencerId, noteId, note}
    }

    function success(notes) {
        return {type: influencerConstants.UPDATE_NOTE_SUCCESS, notes}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_NOTE_FAILURE, error}
    }
}

/**
 * Deletes a note for an influencer in a specific project.
 * Dispatches various actions based on the success or failure of the deletion.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {string} noteId - The ID of the note to be deleted.
 * @returns {function} - A function that dispatches actions to delete the influencer note.
 */
function deleteInfluencerNote(project_id, influencerId, noteId) {
    return dispatch => {
        dispatch(request(project_id, influencerId, noteId));
        influencerService.deleteInfluencerNote(project_id, influencerId, noteId)
            .then(
                notes => {
                    dispatch(success(notes));
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(alertActions.success(`Successfully deleted influencer note.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, noteId) {
        return {type: influencerConstants.DELETE_NOTE_REQUEST,project_id, influencerId, noteId}
    }

    function success(notes) {
        return {type: influencerConstants.DELETE_NOTE_SUCCESS, notes}
    }

    function failure(error) {
        return {type: influencerConstants.DELETE_NOTE_FAILURE, error}
    }
}

/**
 * Retrieves the activities for a specific influencer in a given project.
 * Dispatches actions based on the success or failure of the request.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @returns {function} - A function that dispatches actions to fetch influencer activities.
 */
function getInfluencerActivities(project_id, influencerId) {
    console.log('get activities');
    return dispatch => {
        dispatch(request(project_id, influencerId));
        influencerService.getInfluencerActivities(project_id, influencerId)
            .then(
                activities => {
                    dispatch(success(activities));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId) {
        return {type: influencerConstants.GET_ALL_ACTIVITIES_REQUEST, project_id, influencerId}
    }

    function success(activities) {
        return {type: influencerConstants.GET_ALL_ACTIVITIES_SUCCESS, activities}
    }

    function failure(error) {
        return {type: influencerConstants.GET_ALL_ACTIVITIES_FAILURE, error}
    }
}

/**
 * Retrieves the list of clients associated with a specific project.
 * Dispatches actions based on the success or failure of the request.
 *
 * @param {string} project_id - The ID of the project.
 * @returns {function} - A function that dispatches actions to fetch the client list.
 */
function getClients(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        influencerService.getClients(project_id)
            .then(
                clients => {
                    dispatch(success(clients));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.GET_CLIENT_LIST_REQUEST, project_id}
    }

    function success(clients) {
        return {type: influencerConstants.GET_CLIENT_LIST_SUCCESS, clients}
    }

    function failure(error) {
        return {type: influencerConstants.GET_CLIENT_LIST_FAILURE, error}
    }
}

/**
 * Retrieves the list of platform types associated with a specific project.
 * Dispatches actions based on the success or failure of the request.
 *
 * @param {string} project_id - The ID of the project.
 * @returns {function} - A function that dispatches actions to fetch platform types.
 */
function getPlatformTypes(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        influencerService.getPlatformTypes(project_id)
            .then(
                platforms => {
                    dispatch(success(platforms));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.GET_PLATFORM_TYPE_REQUEST, project_id}
    }

    function success(platforms) {
        return {type: influencerConstants.GET_PLATFORM_TYPE_SUCCESS, platforms}
    }

    function failure(error) {
        return {type: influencerConstants.GET_PLATFORM_TYPE_FAILURE, error}
    }
}

/**
 * Retrieves the list of categories associated with a specific project.
 * Dispatches actions based on the success or failure of the request.
 *
 * @param {string} project_id - The ID of the project.
 * @returns {function} - A function that dispatches actions to fetch the category list.
 */
function getCategories(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        influencerService.getCategories(project_id)
            .then(
                categories => {
                    dispatch(success(categories));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id) {
        return {type: influencerConstants.CATEGORY_LIST_REQUEST, project_id}
    }

    function success(categories) {
        return {type: influencerConstants.CATEGORY_LIST_SUCCESS, categories}
    }

    function failure(error) {
        return {type: influencerConstants.CATEGORY_LIST_FAILURE, error}
    }
}

/**
 * Updates the responsible person for an influencer in a specific project.
 * Dispatches actions based on the success or failure of the update.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} influencerId - The ID of the influencer.
 * @param {object} influencer - The updated influencer information.
 * @returns {function} - A function that dispatches actions to update the influencer responsible.
 */
function updateInfluencerResponsible(project_id, influencerId, influencer) {
    return dispatch => {
        dispatch(request(project_id, influencerId, influencer));
        influencerService.updateInfluencerResponsible(project_id, influencerId, influencer)
            .then(
                influencer => {
                    dispatch(getInfluencerActivities(project_id, influencerId));
                    dispatch(success(influencer));
                    dispatch(alertActions.success(`Successfully updated responsible.`))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(project_id, influencerId, influencer) {
        return {type: influencerConstants.UPDATE_REQUEST, project_id, influencerId, influencer}
    }

    function success(influencer) {
        return {type: influencerConstants.UPDATE_SUCCESS, influencer}
    }

    function failure(error) {
        return {type: influencerConstants.UPDATE_FAILURE, error}
    }
}