import { userConstants } from "_constants";
import {
  userService,
  leadService,
  utilitiesService as utility,
} from "_services";
import { alertActions } from "./";
import { history } from "_helpers";
import { retrieveToken } from "firebase";

export const userActions = {
  storeUser,
  getUser,
  register,
  login,
  twoFactorEmailVerify,
  twoFactorAppVerify,
  twoFactorResend,
  appKeySend,
  logout,
  updatePassword,
  forgotPassword,
  clearError,
  twoFactorEmailLogin,
  twoFactorAppLogin,
};

/**
 * Stores the user information.
 *
 * @param {Object} user - The user object containing user information.
 */
function storeUser(user) {
  userService.storeUser(user);
}

/**
 * Fetches the user data from the user service.
 * 
 * @function
 * @name getUser
 */
function getUser() {
  userService.getUser();
}

/**
 * Action creator for clearing user errors.
 * 
 * @returns {Object} The action object with type `userConstants.CLEAR_ERROR`.
 */
function clearError() {
  return { type: userConstants.CLEAR_ERROR };
}

/**
 * Registers a new user.
 *
 * @param {Object} user - The user object containing registration details.
 * @returns {Function} A thunk function that dispatches actions based on the registration process.
 *
 * @function
 * @name register
 *
 * @inner
 * @param {Object} user - The user object containing registration details.
 * @returns {Object} An action object with type and user.
 *
 * @inner
 * @param {Object} user - The user object containing registration details.
 * @returns {Object} An action object with type and user.
 *
 * @inner
 * @param {string} error - The error message.
 * @returns {Object} An action object with type and error.
 */
function register(user) {
  return (dispatch) => {
    dispatch(request(user));
    userService.register(user).then(
      (user) => {
        dispatch(success());
        history.push("/login");
        dispatch(alertActions.success("Registration successful"));
      },
      (error) => {
        let errorMessage = "";
        if (error.toString() === "Error: Request failed with status code 500") {
          errorMessage =
            "Registration failed. Please check your credentials and try again.";
        } else {
          errorMessage = error.toString();
        }
        dispatch(failure(errorMessage));
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

/**
 * Logs in a user with the provided email and password.
 *
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @param {string} [redirect=null] - Optional redirect URL after login.
 * @returns {function} A thunk function that dispatches login actions.
 */
function login(email, password, redirect = null) {
  return (dispatch) => {
    dispatch(request({ email }));
    userService.login(email, password).then(
      (user) => {
        // console.log("user: ", user);
        const { two_factor } = user.data;

        dispatch(success(user));
        retrieveToken(user.data.id);

        if (redirect) {
          history.push(`/two-factor/select?redirect=${redirect}`);
        } else {
          history.push("/two-factor/select");
        }
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        // console.log('error: ', response);

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            if (errorMessage === "username not found") {
              errorMessage = "Email not found. Please check your email.";
            }

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

/**
 * Initiates the two-factor authentication process via email.
 *
 * @param {string|null} [redirect=null] - The URL to redirect to after successful login.
 * @returns {Function} A redux-thunk function that dispatches actions based on the login process.
 */
function twoFactorEmailLogin(redirect = null) {
  return (dispatch) => {
    dispatch(request({ "2fa": "email" }));
    userService.twoFactorEmailLogin().then(
      (user) => {
        console.log("user email: ", user);

        dispatch(success(user));
        retrieveToken(user.data.id);

        // console.log('redirect: ', redirect);
        if (redirect) {
          history.push(`/two-factor/email/verify?redirect=${redirect}`);
        } else {
          history.push("/two-factor/email/verify");
        }
      },
      (error) => {
        console.log('error: ', error)
        const { response } = error;
        const { data } = response;
        // console.log('error: ', response);

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            if (errorMessage === "username not found") {
              errorMessage = "Email not found. Please check your email.";
            }

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.TWO_FACTOR_REQUEST, user };
  }
  function success(account) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

/**
 * Initiates the two-factor authentication login process using an app.
 *
 * @param {string|null} [redirect=null] - The URL to redirect to after successful login.
 * @returns {Function} A thunk function that dispatches actions based on the login process.
 */
function twoFactorAppLogin(redirect = null) {
  return (dispatch) => {
    dispatch(request({ "2fa": "app" }));
    userService.twoFactorAppLogin().then(
      (user) => {
        // console.log("user app:", user);

        dispatch(success(user));
        retrieveToken(user.data.id);

        // console.log('redirect: ', redirect);
        if (redirect) {
          history.push(`/two-factor/app/verify?redirect=${redirect}`);
        } else {
          history.push("/two-factor/app/verify");
        }
      },
      (error) => {
        console.log('error: ', error)
        const { response } = error;
        const { data } = response;
        // console.log('error: ', response);

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            if (errorMessage === "username not found") {
              errorMessage = "Email not found. Please check your email.";
            }

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.TWO_FACTOR_REQUEST , user};
  }
  function success(account) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

/**
 * Verifies the user's email for two-factor authentication.
 *
 * @param {Object} twoFactorRequest - The request object for two-factor authentication.
 * @param {string} [email=null] - The email address of the user.
 * @param {string} [redirect=null] - The URL to redirect to after verification.
 * @returns {Function} A function that dispatches actions based on the verification result.
 */
function twoFactorEmailVerify(twoFactorRequest, email = null, redirect = null) {
  return (dispatch) => {
    dispatch(request({ twoFactorRequest }));
    userService.twoFactorEmailVerify(twoFactorRequest).then(
      (user) => {
        dispatch(success(user));
        storeUser(user);

        const { projects } = user.data;
        let project = {};
        if (projects) {
          if (projects.shared.length > 0) {
            project = projects.shared[0];
          }

          if (projects.owned.length > 0) {
            project = projects.owned[0];
          }

          let module = "leads";
          if (project.influencer_enabled) {
            module = "influencers";
          }

          const moduleRemovedLastChar = module.endsWith("s") ? module.slice(0, -1) : module;

          if (redirect) {
            let leadPath = redirect.split("/");
            const notAssignedLeadErrorMessage = `You are not assigned as Lead Manager for this ${moduleRemovedLastChar}`;
            leadService.find(leadPath[2], leadPath[4]).then(
              (lead) => {
                // console.log("authenticated", lead.data);
                if (lead.data.owner.email === email) {
                  //  console.log("owner redirected..");
                  history.push(redirect);
                } else if (lead.data.responsible) {
                  if (lead.data.responsible.email === email) {
                    //   console.log("responsible redirected..");
                    history.push(redirect);
                  } else {
                      dispatch(alertActions.error({
                        error_code: "403",
                        error_message: notAssignedLeadErrorMessage
                      }, 6000));
                    history.push(`/project/${project.id}/${module}`);
                  }
                } else {
                    dispatch(alertActions.error({
                      error_code: "403",
                      error_message: notAssignedLeadErrorMessage
                    }, 6000));
                  history.push(`/project/${project.id}/${module}`).then()
                }
              },
              (error) => {
                const { response } = error;
                const { data } = response;
              }
            );
          } else {
            history.replace("/login");
            if(project.id){
              history.push(`/project/${project.id}/${module}`);
            }else {
              history.push("/profile-settings");
            }
          }
        }

        // console.log(user.data.id);
        // retrieveToken(user.data.id);

        // finally reload the page to get new user data
        window.location.reload();
      },
      (error) => {
        const { response } = error;
        const { data } = response;

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.TWO_FACTOR_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

/**
 * Verifies the two-factor authentication request using the provided two-factor request object.
 * 
 * @param {Object} twoFactorRequest - The request object containing two-factor authentication details.
 * @param {string} [email=null] - The email address of the user (optional).
 * @param {string} [redirect=null] - The URL to redirect to after successful verification (optional).
 * @returns {Function} A Redux thunk action that dispatches the verification request and handles the response.
 * 
 * @function request
 * @param {Object} user - The user object to be dispatched with the request action.
 * @returns {Object} The action object for the request.
 * 
 * @function success
 * @param {Object} user - The user object to be dispatched with the success action.
 * @returns {Object} The action object for the success.
 * 
 * @function failure
 * @param {string} error - The error message to be dispatched with the failure action.
 * @returns {Object} The action object for the failure.
 */
function twoFactorAppVerify(twoFactorRequest, email = null, redirect = null) {
  return (dispatch) => {
    dispatch(request({ twoFactorRequest }));
    userService.twoFactorAppVerify(twoFactorRequest).then(
      (user) => {
        dispatch(success(user));
        storeUser(user);

        const { projects } = user.data;
        let project = {};
        if (projects) {
          if (projects.shared.length > 0) {
            project = projects.shared[0];
          }

          if (projects.owned.length > 0) {
            project = projects.owned[0];
          }

          let module = "leads";
          if (project.influencer_enabled) {
            module = "influencers";
          }

          if (redirect) {
            let leadPath = redirect.split("/");
            leadService.find(leadPath[2], leadPath[4]).then(
              (lead) => {
                // console.log("authenticated", lead.data);
                if (lead.data.owner.email === email) {
                  //  console.log("owner redirected..");
                  history.push(redirect);
                } else if (lead.data.responsible) {
                  if (lead.data.responsible.email === email) {
                    //   console.log("responsible redirected..");
                    history.push(redirect);
                  } else {
                    history.push(`/project/${project.id}/${module}`);
                  }
                } else {
                  history.push(`/project/${project.id}/${module}`);
                }
              },
              (error) => {
                const { response } = error;
                const { data } = response;
              }
            );
          } else {
            history.replace("/login");
            if(project.id){
              history.push(`/project/${project.id}/${module}`);
            }else {
              history.push("/profile-settings");
            }
          }
        }

        // console.log(user.data.id);
        // retrieveToken(user.data.id);

        // finally reload the page to get new user data
        window.location.reload();
      },
      (error) => {
        const { response } = error;
        const { data } = response;

        let errorMessage = "Server Error";

        if ((response.status = 500)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }

        if ((response.status = 400)) {
          if ("error_description" in data) {
            errorMessage = data.error_description;

            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }

          if ("error_message" in data) {
            errorMessage = data.error_message;
            dispatch(failure(errorMessage));
            dispatch(alertActions.error(errorMessage));
          }
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.TWO_FACTOR_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

/**
 * Initiates the process of resending the two-factor authentication code.
 * Dispatches request, success, and failure actions based on the outcome of the service call.
 *
 * @returns {Function} A thunk function that dispatches actions based on the result of the two-factor resend service call.
 */
function twoFactorResend() {
  // console.log('resend called');
  return (dispatch) => {
    dispatch(request());
    userService.twoFactorResend().then(
      (account) => {
        //    console.log('resend code...')
        dispatch(success(account));
        // dispatch(
        //   alertActions.success("Successfully resend verification to your email")
        // );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.TWO_FACTOR_REQUEST };
  }
  function success(account) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

/**
 * Sends an application key request.
 *
 * This function dispatches a request action, then calls the userService.sendAppKey method.
 * If the request is successful, it dispatches a success action with the account data.
 * If the request fails, it dispatches a failure action with the error message and an alert action.
 *
 * @returns {Function} A thunk that dispatches actions based on the result of the sendAppKey request.
 */
function appKeySend() {
  // console.log('resend called');
  return (dispatch) => {
    dispatch(request());
    userService.sendAppKey().then(
      (account) => {
        //    console.log('resend code...')
        dispatch(success(account));
        // dispatch(
        //   alertActions.success("Successfully resend verification to your email")
        // );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.TWO_FACTOR_REQUEST };
  }
  function success(account) {
    return { type: userConstants.TWO_FACTOR_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.TWO_FACTOR_FAILURE, error };
  }
}

/**
 * Logs out the current user by calling the userService.logout method
 * and returns an action object with the type set to userConstants.LOGOUT.
 *
 * @returns {Object} An action object with the type of LOGOUT.
 */
function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

/**
 * Updates the user's password.
 *
 * @param {Object} account - The account object containing the new password.
 * @returns {Function} A thunk function that dispatches actions based on the update password process.
 */
function updatePassword(account) {
  return (dispatch) => {
    dispatch(request(account));
    userService
      .updatePassword(account)
      //this.props.updatePassword(account)
      .then(
        (account) => {
          dispatch(success(account));
          history.push("/login");
          dispatch(alertActions.success("Successfully updated password."));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(account) {
    return { type: userConstants.UPDATE_PASSWORD_REQUEST, account };
  }
  function success(account) {
    return { type: userConstants.UPDATE_PASSWORD_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_PASSWORD_FAILURE, error };
  }
}

/**
 * Initiates the forgot password process for a given account.
 * Dispatches request, success, and failure actions based on the outcome of the userService.forgotPassword call.
 *
 * @param {Object} account - The account object containing the user's email or username.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 */
function forgotPassword(account) {
  return (dispatch) => {
    dispatch(request(account));
    userService
      .forgotPassword(account)
      //this.props.updatePassword(account)
      .then(
        (account) => {
          // console.log("account: ", account.data);
          dispatch(success(account.data));
          // history.push('/login');
          dispatch(
            alertActions.success(
              "Your new password is successfully sent in email provided"
            )
          );
        },
        (error) => {
          const { response } = error;
          const { data } = response;

          let errorMessage = "Server Error";

          if ((response.status = 500)) {
            if ("error_description" in data) {
              errorMessage = data.error_description;
              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
            }

            if ("error_message" in data) {
              errorMessage = data.error_message;
              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
            }
          }

          if ((response.status = 400)) {
            if ("error_description" in data) {
              errorMessage = data.error_description;

              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
            }

            if ("error_message" in data) {
              errorMessage = data.error_message;
              dispatch(failure(errorMessage));
              dispatch(alertActions.error(errorMessage));
            }
          }
        }
      );
  };

  function request(account) {
    return { type: userConstants.UPDATE_PASSWORD_REQUEST, account };
  }
  function success(account) {
    return { type: userConstants.UPDATE_PASSWORD_SUCCESS, account };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_PASSWORD_FAILURE, error };
  }
}