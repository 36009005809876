import React from 'react';  // Add this line
import { userActions } from "_actions";
import logo from '_assets/images/lms-logo.png';
import loginBG from '_assets/images/login-bg.png';
import { FormValidation } from '_components/FormValidation';
import { utilitiesService as utility } from '_services';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

const ForgotPassword = ({ forgotPassword, logout, clearError, error, loading }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({
    email: 'Please enter your email.',
    register: error || ""
  });
  const [emailValid, setEmailValid] = useState(false);
  const [formValid, setFormValid] = useState(false);

  // set error as form errors register
  useEffect(()=>{
    if(error) setFormErrors({
      ...formErrors,
      register: error
    })
  },[error])

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = { ...formErrors };
    let emailValidState = emailValid;
    fieldValidationErrors.register = '';

    let isAllWhitespace = value.replace(/(?!^ +$)^.+$/gm, "") !== '';

    switch (fieldName) {
      case 'email':
        if (value === "" || isAllWhitespace) {
          emailValidState = false;
          fieldValidationErrors.email = 'Please enter your email.';
        } else {
          emailValidState = /^[\w.%+-]+@[\w-]+\.[a-zA-Z]{2,}$/.test(value);
          fieldValidationErrors.email = emailValidState ? '' : 'Please enter your email address in a valid format: sample@domain.com';
        }
        break;
      default:
        break;
    }

    setFormErrors(fieldValidationErrors);
    setEmailValid(emailValidState);
    validateForm(emailValidState);
  };

  const validateForm = (emailIsValid) => {
    setFormValid(emailIsValid);
  };

  const errorClass = (error) => (error.length === 0 ? 'is-passed' : 'is-failed');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);

    validateField(name, value);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    validateForm(emailValid);

    setSubmitted(true);

    if (formValid) {
      if (submitted) {
        logout();
        const request = { email: utility.encrypt(email) };
        forgotPassword(request);
      }
    }
    return false;
  };

  const handleBackToLogin = () => {
    clearError();
  };

  let fieldValidationErrors = { ...formErrors };
  fieldValidationErrors.register = error ? error : '';

  return (
    <MDBContainer fluid className='login-background'>
      <div className='login-bg'>
        <img src={loginBG} alt='Login Background Image' />
      </div>
      <div className='login-panel'>
        <MDBRow>
          <MDBCol className='text-center'>
            <img src={logo} alt='Propelrr Platform' className='login-logo' />
            <h1>Lead Management System</h1>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <form onSubmit={handleSend} noValidate>
              <MDBRow>
                <MDBCol className="text-center">
                  <div className='form-inner-heading'>
                    <h2>Forgot Password?</h2>
                    <p>
                      By entering your email, we’ll send you <br /> your new password
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div className={`form-group ${submitted && errorClass(formErrors.email)}`}>
                    <label className='l-main'>Email</label>
                    <input
                      id='email-input-forgot'
                      name='email'
                      placeholder='yourgivenemail@company.com'
                      type='email'
                      required
                      value={email}
                      autoFocus
                      onChange={handleChange}
                    />
                    <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='email' formErrors={formErrors} />
                    </div>
                    <div className={`form-validation__container ${error ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='register' formErrors={formErrors} />
                    </div>
                  </div>
                  <div className='form-group'>
                    <button type='submit' className={formValid && submitted && !error && loading ? "c-btn c-btn-lg c-btn-disabled" : "c-btn c-btn-lg"}> Send me a new password</button>
                    {formValid && submitted && !error && loading &&
                      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    }
                  </div>
                  <div className='form-group'>
                    <div className='go-login-group'>
                      <p>
                        Go back to <Link to='/login' onClick={handleBackToLogin}>Login</Link>
                      </p>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
};

const mapState = (state) => {
  const { changePassword } = state.authentication;
  const { error, loading } = state.users;
  return { changePassword, error, loading };
};

const actionCreators = {
  forgotPassword: userActions.forgotPassword,
  logout: userActions.logout,
  clearError: userActions.clearError,
};

const connectedForgotPasswordPage = connect(mapState, actionCreators)(ForgotPassword);
export { connectedForgotPasswordPage as ForgotPassword };

