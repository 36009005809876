import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { userActions } from '_actions';
import { history } from '_helpers';
import { utilitiesService as utility } from '_services';

/**
 * Logout component that handles user logout functionality and displays a dropdown menu.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * return (
 *   <Logout />
 * )
 *
 * @description
 * This component renders a dropdown menu that allows the user to log out or navigate to profile settings.
 * It uses session storage to retrieve the logged-in user's information and displays the first letter of the user's first name.
 * The dropdown menu can be toggled by clicking on the displayed letter, and it closes when clicking outside of the menu.
 *
 * @function
 * @name Logout
 *
 * @property {boolean} isDropdownOpen - State to manage the visibility of the dropdown menu.
 * @property {function} setIsDropdownOpen - Function to update the state of the dropdown menu visibility.
 * @property {object} dropdownToggleRef - Reference to the dropdown toggle element.
 *
 * @method
 * @name renderLoggedInUser
 * @description Retrieves and decrypts the logged-in user's first name from session storage, and returns the first letter in uppercase.
 *
 * @method
 * @name handleLogout
 * @description Logs out the user and redirects to the home page.
 *
 * @method
 * @name handleUpdatePassword
 * @description Initiates the password update process for the user.
 *
 * @method
 * @name handleDropdownToggle
 * @description Toggles the visibility of the dropdown menu.
 *
 * @method
 * @name handleDropdown
 * @description Closes the dropdown menu if a click is detected outside of the dropdown toggle element.
 *
 * @hook
 * @name useEffect
 * @description Adds an event listener to handle clicks outside the dropdown menu and cleans up the listener on component unmount.
 */
const Logout = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownToggleRef = useRef(null);

  const renderLoggedInUser = () => {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    if(!loggedInUser || !loggedInUser.data) return "";
    if(loggedInUser.data.photo){
      try {
        const decryptedPhoto = utility.decrypt(loggedInUser.data.photo);
        return <img src={decryptedPhoto} />
      } catch (error) {
        console.error("Problem decrypting photo");
      }
    }
    const displayedLoggedInUser = utility.decrypt(loggedInUser.data.first_name);
    const firstChar = displayedLoggedInUser.charAt(0);
    return <span>{firstChar}</span>;
  };
  

  const handleLogout = () => {
    userActions.logout();
    history.push('/');
  };

  const handleUpdatePassword = () => {
    userActions.updatePassword();
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdown = (value) => {
    if (!dropdownToggleRef.current.contains(value.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDropdown, true);

    return () => {
      document.removeEventListener('click', handleDropdown, true);
    };
  }, []);

  return (
    <React.Fragment>
      <div id="navbarCollapse3" ref={dropdownToggleRef}>
        <div className={isDropdownOpen ? 'dropdown-parent show' : 'dropdown-parent'}>
          <div>
            <div className="avatar-box avatar-color-1">
              <span className='avatar-icon' onClick={handleDropdownToggle}>
                {renderLoggedInUser()}
              </span>
            </div>
          </div>
          <div className={isDropdownOpen ? 'dropdown-menu active-custom' : 'dropdown-menu'}>
            <div>
              <Link to="/profile-settings">Profile Settings</Link>
            </div>
            <div>
              <Link onClick={handleLogout} to="/logout">
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Logout;
