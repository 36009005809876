import { emailConstants } from "_constants";

/**
 * Email reducer function to handle email-related actions and update the state accordingly.
 *
 * @param {Object} state - The current state of the email reducer.
 * @param {Object} action - The action dispatched to the reducer.
 * @param {string} action.type - The type of the action.
 * @param {Object} [action.emails] - The emails data associated with the action.
 * @param {Object} [action.error] - The error object associated with the action.
 * @param {Object} [action.thread] - The thread data associated with the action.
 * @param {Object} [action.drafts] - The drafts data associated with the action.
 * @returns {Object} The new state of the email reducer.
 */
export function email(state = {}, action) {
  switch (action.type) {
    case emailConstants.GET_ALL_EMAIL_REQUEST:
      return {
        ...state,
        getEmailLoading: true,
        getEmailLoaded: false,
        getEmailReply: false,
        getUnreadLoaded: false,
        getEmailNotification: false,
      };
    case emailConstants.GET_ALL_EMAIL_SUCCESS:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        getThreadLoaded: true,
        getEmailReply: false,
        email: action.emails.data,
      };
    case emailConstants.GET_ALL_EMAIL_FAILURE:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        error: action.error,
      };

    case emailConstants.EMAIL_NOTIFICATION_REQUEST:
      return {
        ...state,
        getEmailNotification: true,
      };

    case emailConstants.UPDATE_EMAIL_UNREAD_REQUEST:
      return {
        ...state,
        getEmailLoading: true,
        getEmailLoaded: false,
        getEmailReply: false,
        getUnreadLoaded: false,
      };
    case emailConstants.UPDATE_EMAIL_UNREAD_SUCCESS:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        getThreadLoaded: true,
        getEmailReply: false,
        getUnreadLoaded: true,
        email: action.emails.data,
      };
    case emailConstants.UPDATE_EMAIL_UNREAD_FAILURE:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        getThreadLoaded: true,
        getEmailReply: false,
        error: action.error,
      };

    case emailConstants.EMAIL_REPLY_REQUEST:
      return {
        ...state,
        getEmailLoading: true,
        getEmailLoaded: false,
        getEmailReply: false,
      };
    case emailConstants.EMAIL_REPLY_SUCCESS:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        getThreadLoaded: false,
        getEmailReply: true,
        email: action.emails.data,
      };
    case emailConstants.EMAIL_REPLY_FAILURE:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        error: action.error,
      };
    case emailConstants.EMAIL_LOAD_MESSAGES:
      return {
        ...state,
        thread: action.thread,
      };

    // email drafts
    case emailConstants.EMAIL_ALL_DRAFT_REQUEST:
    case emailConstants.EMAIL_DRAFT_REQUEST:
      return {
        ...state,
        getDraftLoading: true,
        getDraftLoaded: false,
      };
    case emailConstants.EMAIL_ALL_DRAFT_SUCCESS:
      return {
        ...state,
        getDraftLoading: false,
        getDraftLoaded: true,
        drafts: action.drafts.data,
      };
    case emailConstants.EMAIL_DRAFT_SUCCESS:
      return {
        ...state,
        getDraftLoading: false,
        getDraftLoaded: true,
        drafts: action.drafts.data,
      };
    case emailConstants.EMAIL_ALL_DRAFT_FAILURE:
    case emailConstants.EMAIL_DRAFT_FAILURE:
      return {
        ...state,
        getEmailLoading: false,
        getEmailLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
