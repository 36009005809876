import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formActions, leadActions, projectActions } from '_actions';
import {
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow
} from 'mdbreact';
import { Button, ButtonGroup, InputSelect } from "blackbox-react";
import { utilitiesService as utility } from "_services";
import { FormGroupView } from "_pages/Forms/FormGroupView";
import AddFieldModal from "_components/Modal/AddFieldModal";

const ManageLead = (props) => {
    const {
        getAllProjectStatus,
        getAllProjectMembers,
        getForm,
        updateLead,
        projects,
        forms,
        match,
        history
    } = props;

    const projectId = localStorage.getItem('projectId');
    const projectData = JSON.parse(localStorage.getItem('project'));
    const leadId = match.params.id;
    const lead = JSON.parse(localStorage.getItem('lead'));
    const formId = lead.form_id;

    const [state, setState] = useState({
        addField: false,
        projectData: projectData,
        selectedStatus: lead.project_status.id,
        selectedPriority: lead.priority.id,
        selectedOwner: lead.owner,
        selectedResponsible: lead.responsible !== null ? lead.responsible.email : null,
        formGroupData: {},
        formGroupId: 0,
        fieldData: {},
        leadData: lead.basic_info.map(bi => ({ key: bi.field_id, value: bi.value })),
        lead: lead,
        leadUpdated: false,
        dropdownSelectInfo: [],
        isManageLead: true
    });

    useEffect(() => {
        getAllProjectStatus(projectId);
        getAllProjectMembers(projectId);
        getForm(projectId, formId);
        localStorage.setItem('leadId', leadId);
    }, [projectId, formId, leadId, getAllProjectStatus, getAllProjectMembers, getForm]);

    const getEditFormGroup = (data) => {
        setState(prevState => ({ ...prevState, formGroupData: data || {} }));
    };

    const getEditFormField = (data, formGroupId) => {
        setState(prevState => ({ ...prevState, fieldData: data || {}, formGroupId }));
    };

    const handleDropdownSelectData = (info, index) => {
        const selectLeadInfo = [];
        const basicInfo = JSON.parse(localStorage.getItem('lead_info'));

        basicInfo.forEach(bInfo => {
            if (index === bInfo.field_id) {
                selectLeadInfo.push({
                    key: index,
                    value: utility.encrypt(info)
                });
                localStorage.setItem('selectDropdownInfo', JSON.stringify(selectLeadInfo));
            }
        });
    };

    const handleAPIDropdownData = (info, index) => {
        const leadInfoAPI = [];
        const basicInfo = JSON.parse(localStorage.getItem('lead_info'));

        basicInfo.forEach(bInfo => {
            if (index === bInfo.field_id) {
                leadInfoAPI.push({
                    key: index,
                    value: utility.encrypt(info)
                });
                localStorage.setItem('selectAPIDropdownInfo', JSON.stringify(leadInfoAPI));
            }
        });
    };

    const handleUpdate = (event) => {
        const updatedKey = parseInt(event.target.name);
        const updatedValue = event.target.value;

        const leadData = [...state.leadData];
        const lead = { ...state.lead };

        const objIndex = leadData.findIndex(obj => obj.key === updatedKey);
        if (objIndex === -1) {
            leadData.push({ key: updatedKey, value: utility.encrypt(updatedValue) });
        } else {
            leadData[objIndex].value = utility.encrypt(updatedValue);
        }

        const basic_info = lead.basic_info.map(bi => {
            if (bi.field_id === updatedKey) {
                return { ...bi, value: utility.encrypt(updatedValue) };
            }
            return bi;
        });

        lead.basic_info = basic_info;

        setState(prevState => ({ ...prevState, leadData, lead }));
    };

    const handleUpdateLead = () => {
        const leadData = [...state.leadData];
        const selectData = JSON.parse(localStorage.getItem('selectDropdownInfo'));
        const apiData = JSON.parse(localStorage.getItem('selectAPIDropdownInfo'));

        if (selectData) {
            leadData.push(selectData);
        }
        if (apiData) {
            leadData.push(apiData);
        }

        const leadDataToAdd = {
            form_lead: leadData.length === 0 ? null : leadData,
            project_status_id: state.selectedStatus,
            priority: state.selectedPriority,
            owner: state.selectedOwner.email,
            responsible: state.selectedResponsible
        };

        updateLead(projectId, leadId, leadDataToAdd);

        setTimeout(() => {
            history.goBack();
        }, 2000);
    };

    const handleModal = () => {
        setState(prevState => ({ ...prevState, addField: !prevState.addField }));
    };

    const renderProjectStatus = () => {
        const projectStatus = projects.status.data.sort((a, b) => a.id > b.id);
        const selectOptions = projectStatus.map(status => ({
            id: status.id,
            value: status.id,
            label: status.name
        }));

        return (
            <div>
                <label htmlFor='selectStatus' className='mb-0 font-size-14 font-weight-bold'>Status</label>
                <div className='width-inherit background-color-white'>
                    <InputSelect
                        name='selectStatus'
                        defaultValue={state.selectedStatus}
                        onChange={(v) => setState(prevState => ({ ...prevState, selectedStatus: v }))}
                        options={selectOptions}
                    />
                </div>
            </div>
        );
    };

    const renderLeadPriority = () => {
        const selectOptions = [
            { id: 1, value: '1', label: 'Low' },
            { id: 2, value: '2', label: 'Medium' },
            { id: 3, value: '3', label: 'High' }
        ];

        return (
            <div>
                <label htmlFor='selectPriority' className='mb-0 font-size-14 font-weight-bold'>Priority</label>
                <div className='width-inherit background-color-white'>
                    <InputSelect
                        name='selectedPriority'
                        defaultValue={state.selectedPriority}
                        onChange={(v) => setState(prevState => ({ ...prevState, selectedPriority: v }))}
                        options={selectOptions}
                    />
                </div>
            </div>
        );
    };

    const handleGetProjectOwnerAndMembers = () => {
        const projectMembers = projects.members.data.sort((a, b) => a.member_id > b.member_id);
        const selectOptions = [];

        selectOptions.push({
            id: 0,
            value: utility.encrypt('unassigned'),
            label: 'Unassigned'
        });

        selectOptions.push({
            id: projectData.owner.id,
            value: projectData.owner.account.email,
            label: `${utility.decrypt(projectData.owner.account.first_name)} ${utility.decrypt(projectData.owner.account.last_name)}`
        });

        projectMembers.forEach(member => {
            selectOptions.push({
                id: member.id,
                value: member.account.email,
                label: `${utility.decrypt(member.account.first_name)} ${utility.decrypt(member.account.last_name)}`
            });
        });

        return selectOptions;
    };

    const renderLeadOwner = () => {
        const selectOptions = handleGetProjectOwnerAndMembers();
        const leadOwner = state.lead.owner;

        return (
            <div>
                <label htmlFor='selectOwner' className='mb-0 font-size-14 font-weight-bold'>Owner</label>
                <div className='width-inherit background-color-white'>
                    <InputSelect
                        name='selectedOwner'
                        defaultValue={leadOwner ? leadOwner.email : selectOptions[0].value}
                        onChange={(v) => setState(prevState => ({ ...prevState, selectedOwner: v }))}
                        options={selectOptions}
                    />
                </div>
            </div>
        );
    };

    const renderLeadResponsible = () => {
        const selectOptions = handleGetProjectOwnerAndMembers();

        return (
            <div>
                <label htmlFor='selectResponsible' className='mb-0 font-size-14 font-weight-bold'>Responsible</label>
                <div className='width-inherit background-color-white'>
                    <InputSelect
                        name='selectedResponsible'
                        defaultValue={state.selectedResponsible}
                        onChange={(v) => setState(prevState => ({ ...prevState, selectedResponsible: v }))}
                        options={selectOptions}
                    />
                </div>
            </div>
        );
    };

    const renderSideBar = () => {
        return (
            <MDBRow className='render-sidebar align-items-center justify-content-start'>
                <MDBCol>{renderProjectStatus()}</MDBCol>
                <MDBCol>{renderLeadPriority()}</MDBCol>
                <MDBCol>{renderLeadOwner()}</MDBCol>
                <MDBCol>{renderLeadResponsible()}</MDBCol>
            </MDBRow>
        );
    };

    const renderFormGroups = (data) => {
        const totalFormGroups = data.length;
        const hasFormGroups = totalFormGroups > 0;

        if (hasFormGroups) {
            data = data.sort((a, b) => a.form_order > b.form_order);
        }

        return (
            <div className='preview__stage'>
                {hasFormGroups && state.lead && data.map((value) => (
                    <FormGroupView
                        key={value.id}
                        formGroupData={value}
                        formGroupId={value.id}
                        formGroupKey={value.id}
                        getEditFormGroup={getEditFormGroup}
                        getEditFormField={getEditFormField}
                        handleUpdate={handleUpdate}
                        leadInfo={state.lead}
                        dropdownSelect={handleDropdownSelectData}
                        isManageLead={state.isManageLead}
                        APIDropdownSelect={handleAPIDropdownData}
                    />
                ))}
            </div>
        );
    };

    const handleBasicInfo = () => {
        const form = forms.form.data;
        return renderFormGroups(form.group);
    };

    const renderBasicInformation = () => {
        const form = forms.form.data;

        return (
            <MDBCol xl='12' lg='12' className='box-right-rectangle p--0 p-t--sm p-b--sm m--0 height--auto'>
                <div className="basic-info p--0">
                    <h5 className='font-size-20'>
                        <MDBIcon far icon='address-card' /> &nbsp; {form.info.name}
                    </h5>
                    <div className='field-container m-t--sm p--sm border-radius-5'>
                        {handleBasicInfo()}
                        <span className='font-size-10 p-l--md'>Please fill out all fields.</span>
                    </div>
                </div>
            </MDBCol>
        );
    };

    const { membersLoaded, statusLoaded, formLoaded } = props;

    return (
        <MDBContainer fluid className='p--0'>
            <div className='page-title-ctas p-l-md p-r-md'>
                <MDBRow className='m--0 align-items-center'>
                    <MDBCol md='auto' className='p--0'>
                        <p className='page-title font-family-kanit font-size-30 line-height-7 color-gray line-height-6'>
                            Edit Lead
                        </p>
                    </MDBCol>
                    <MDBCol>
                        <MDBRow className='float-right'>
                            <ButtonGroup>
                                <Link to={`/project/${projectId}/lead/${leadId}`}>
                                    <Button className='bordered-btn'>
                                        <i className="fas fa-chevron-left"></i>
                                        Back to Lead Info
                                    </Button>
                                </Link>
                                <Button
                                    className='background-btn'
                                    onClick={handleUpdateLead}>
                                    Update Lead
                                </Button>
                            </ButtonGroup>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>

            <div className="page-filter">
                {(statusLoaded && membersLoaded && formLoaded) && renderSideBar()}
            </div>

            <MDBContainer fluid className={`${(statusLoaded && membersLoaded && formLoaded) || ''} p--0 main-body-container container-fluid false p--sm sub-body-container rectangle-background`}>
                <AddFieldModal
                    modalToggle={handleModal}
                    modalOpen={state.addField}
                />
                {(statusLoaded && membersLoaded && formLoaded) ?
                    <MDBCol className='background-rectangle p-l--sm p-r--md edit-lead-box-'>
                        {renderBasicInformation()}
                    </MDBCol>
                    :
                    <MDBRow className='sub-body-container height--full'>
                        <MDBCol md='auto' lg='12' className='sub-body-container height--full'>
                            <div className='loader-container'>
                                <div className="loader dark">Loading...</div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                }
            </MDBContainer>
        </MDBContainer>
    );
};

const mapState = (state) => {
    const { projects, forms } = state;
    const { statusLoaded, membersLoaded } = state.projects;
    const { formLoaded } = state.forms;
    return { projects, forms, statusLoaded, membersLoaded, formLoaded };
};

const actionCreators = {
    getAllProjectStatus: projectActions.getAllProjectStatus,
    getAllProjectMembers: projectActions.getAllProjectMembers,
    getForm: formActions.getForm,
    updateLead: leadActions.updateLead
};

const connectedManageLead = connect(mapState, actionCreators)(ManageLead);
export { connectedManageLead as ManageLead };