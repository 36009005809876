import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { projectActions } from '_actions';
import { utilitiesService as utility } from '_services';

import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { LeadStatusList } from '_pages/Projects/LeadStatusList';
import { CreateLeadStatusModal } from '_pages/Projects/components/CreateLeadStatusModal';
import { EditLeadStatusModal } from '_pages/Projects/components/EditLeadStatusModal';
import InputForm from '_components/InputForm';

import avatar from '_assets/images/avatar-fem.png';
import { Button, ButtonGroup, Icon } from "blackbox-react";

export const ManageLeadStatus = ({ match }) => {
  const dispatch = useDispatch();
  const { projects, statusLoaded } = useSelector(state => state.projects);
  const [state, setState] = useState({
    isActiveSideBtn: 'ACTIVITY',
    isOpen: false,
    selectedOption: '',
    selectedMember: '',
    selectedOwner: '',
    addedLead: false,
    updatedProject: false,
    updateProjectChange: false,
    isViewStatusVisible: 1,
    projectMembers: {},
    isBreadCrumbActive: '',
    filteredData: '',
    createLead: false,
    editLead: false,
    editLeadStatus: [],
    leadStatusData: [],
    activeSort: 'dateCreated_oldest',
    statusColor: ['teal', 'blue', 'yellow', 'orange', 'red', 'green', 'dark-gray', 'lime', 'cyan', 'indigo', 'brown', 'purple']
  });

  useEffect(() => {
    dispatch(projectActions.getProject(match.params.id));
    dispatch(projectActions.getAllProjectStatus(match.params.id));
    localStorage.removeItem('isActiveModule');
    localStorage.setItem('isActiveModule', 'projects');
  }, [dispatch, match.params.id]);

  const handleGetProjectStatus = () => {
    dispatch(projectActions.getAllProjectStatus(match.params.id));
  };

  const toggleAdd = () => setState(prev => ({ ...prev, createLead: !prev.createLead }));
  const toggleEdit = () => setState(prev => ({ ...prev, editLead: !prev.editLead }));

  const editStatus = (leadStatus) => {
    setState(prev => ({ ...prev, editLead: !prev.editLead, editLeadStatus: leadStatus }));
  };

  const handleTextEllipsis = (text, max) => {
    return text.substr(0, max) + (text.length > max ? '...' : '');
  };

  const handleCheckAllSelectBox = () => {
    const checkAll = document.querySelector(`input[type='checkbox'][name='check-all']`);
    const checkBoxes = document.querySelectorAll(`input[type='checkbox'][name='check-box']`);
    checkAll.addEventListener('change', function (e) {
      for (let checkBox of checkBoxes) {
        checkBox.checked = this.checked;
      }
    });
  };

  const project = JSON.parse(localStorage.getItem('project'));
  const status = projects.status ? projects.status.data : {};

  return (
    <MDBContainer fluid className='p--0'>
      {/* Include all JSX from the original component here */}
      {/* Example: */}
      <div className='page-title-ctas p-l-md p-r-md'>
        {project && (
          <MDBRow className='m--0 align-items-center'>
            <MDBCol className='p-0'>
              <p className='page-title font-family-kanit font-size-30 line-height-7 color-gray line-height-6'>
                Manage Lead Status
              </p>
            </MDBCol>
            <MDBCol>
              <MDBRow className='float-right'>
                <ButtonGroup>
                  <Link to={`/project/${project.id}`}>
                    <Button
                      flat='true'
                      background='transparent'
                      foreground='black'
                      borderWidth={2}
                      borderColor='dark'
                      size={-1}
                      styles={{ width: '100%', opacity: '0.8' }}
                    >
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>Back to Project Overview</span>
                    </Button>
                  </Link>
                  <Link to='/'>
                    <Button
                      flat='true'
                      background='transparent'
                      foreground='black'
                      borderWidth={2}
                      borderColor='dark'
                      size={-1}
                      styles={{ width: '100%', opacity: '0.8' }}
                    >
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>Back to All Projects</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}
      </div>
      {/* Add more JSX as needed */}
    </MDBContainer>
  );
};