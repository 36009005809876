import React, { useState } from 'react';
import { breadCrumbsBtn } from '../../constants/BreadCrumbs';

const LeadInfoBreadCrumbs = ({ isInfoBreadCrumbsActive, onHandleDropdown, activeDropdown, handleUpdateLeadStatus }) => {
  
  // Optional state if you need to manage some dynamic state locally within the component
  const [state] = useState({
    breadCrumbsBtn,
  });

  return (
    <>
      {state.breadCrumbsBtn.map((value) => {
        const btnActiveClass = isInfoBreadCrumbsActive === value.name ? value.newClass : value.defaultClass;
        const dropdownActive = value === activeDropdown ? 'active' : '';

        return (
          <button
            key={value.name}
            className={`${dropdownActive} ${btnActiveClass} btn-arrow-right padding-breadcrumb-info box-shadow-none font-weight-600 custom-dropdown`}
          >
            <label onClick={() => onHandleDropdown(value)}>
              {value.name} <i className="fa fa-chevron-down"></i>
            </label>
            <ul className="dropdown-options">
              {value.dropdownOption.map((doItem) => (
                <li
                  key={doItem.value}
                  onClick={() => handleUpdateLeadStatus(doItem.id)}
                >
                  {doItem.name}
                </li>
              ))}
            </ul>
          </button>
        );
      })}
    </>
  );
};

export default LeadInfoBreadCrumbs;
