import { httpManagerService as httpManager } from  '_services';
export const influencerService = {
    getInfluencerSummary,
    getInfluencerList,
    createInfluencer,
    getInfluencer,
    updateInfluencer,
    deleteInfluencer,
    updateInfluencerStatus,
    addInfluencerPlatform,
    getInfluencerPlatform,
    updateInfluencerPlatform,
    deleteInfluencerPlatform,
    updateInfluencerClients,
    getInfluencerClients,
    updateInfluencerCategories,
    getInfluencerCategories,
    addInfluencerDocument,
    getInfluencerDocument,
    updateInfluencerDocument,
    deleteInfluencerDocument,
    addInfluencerRate,
    getInfluencerRates,
    updateInfluencerRate,
    deleteInfluencerRate,
    addInfluencerNote,
    getInfluencerNotes,
    updateInfluencerNote,
    deleteInfluencerNote,
    getClients,
    getPlatformTypes,
    getCategories,
    getInfluencerActivities,
    updateInfluencerResponsible
}

/**
 * Fetches the summary of influencers for a given project.
 *
 * @param {string} projectId - The ID of the project for which to fetch the influencer summary.
 * @returns {Promise<Object>} A promise that resolves to the influencer summary data.
 */
function getInfluencerSummary(projectId){
    let uri = `/api/project/${projectId}/influencer/summary`;
    return httpManager.getHttpOnce(uri);
}

/**
 * Fetches a list of influencers for a given project with optional filters and pagination.
 *
 * @param {string} projectId - The ID of the project.
 * @param {number} [page=null] - The page number for pagination.
 * @param {number} [size=null] - The number of items per page.
 * @param {string} [query=null] - The search query to filter influencers.
 * @param {Array<string>} [status=[]] - The list of statuses to filter influencers.
 * @param {Array<string>} [platform=[]] - The list of platforms to filter influencers.
 * @param {Array<string>} [category=[]] - The list of categories to filter influencers.
 * @param {string} [sort=null] - The field to sort the results by.
 * @param {string} [direction=null] - The direction of sorting (asc or desc).
 * @param {string} [dateFrom=null] - The start date to filter influencers.
 * @param {string} [dateTo=null] - The end date to filter influencers.
 * @returns {Promise<Object>} - A promise that resolves to the list of influencers.
 */
function getInfluencerList(projectId, page = null, size = null, query= null, status = [], platform = [], category = [], sort = null, direction = null, dateFrom = null, dateTo = null){

    let parts = [];

    if(query != null) {
        parts.push(`query=${query}`);
    }

    if(page != null) {
        parts.push(`page=${page}`);
    }

    if(size != null) {
        parts.push(`size=${size}`);
    }

    if(status.length > 0) {
        parts.push(`status=${status.join(',')}`);
    }

    if(platform.length > 0) {
        parts.push(`platform=${platform.join(',')}`);
    }

    if(category.length > 0) {
        parts.push(`category=${category.join(',')}`);
    }

    if(sort != null) {
        parts.push(`sort=${sort}`);
    }

    if(direction != null) {
        parts.push(`direction=${direction}`);
    }

    if(dateFrom != null) {
        parts.push(`dateFrom=${dateFrom}`);
    }

    if(dateTo != null) {
        parts.push(`dateTo=${dateTo}`);
    }

    let uri = `/api/project/${projectId}/influencer?` + parts.join('&');

    return httpManager.getHttpOnce(uri);
}

/**
 * Creates a new influencer within a project.
 * @param {number} projectId - The ID of the project.
 * @param {Object} influencer - The influencer data.
 * @returns {Promise} API response promise.
 */
function createInfluencer(projectId, influencer) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer`, influencer);
}

/**
 * Retrieves details of a specific influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise containing influencer data.
 */
function getInfluencer(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}`);
}

/**
 * Updates an existing influencer within a project.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {Object} influencer - The updated influencer data.
 * @returns {Promise} API response promise.
 */
function updateInfluencer(projectId, influencerId, influencer) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}`, influencer);
}

/**
 * Updates the status of an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {string} status - The new status of the influencer.
 * @returns {Promise} API response promise.
 */
function updateInfluencerStatus(projectId, influencerId, status) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/status/${status}`);
}

/**
 * Deletes an influencer from a project.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise.
 */
function deleteInfluencer(projectId, influencerId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}`);
}

/**
 * Adds a platform to an influencer's profile.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {Object} platform - The platform data.
 * @returns {Promise} API response promise.
 */
function addInfluencerPlatform(projectId, influencerId, platform) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/platform`, platform);
}

/**
 * Retrieves the platforms associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise containing platform data.
 */
function getInfluencerPlatform(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/platform`);
}

/**
 * Updates an influencer's platform details.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {number} platformId - The ID of the platform.
 * @param {Object} platform - The updated platform data.
 * @returns {Promise} API response promise.
 */
function updateInfluencerPlatform(projectId, influencerId, platformId, platform) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/platform/${platformId}`, platform);
}

/**
 * Deletes a platform from an influencer's profile.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {number} platformId - The ID of the platform.
 * @returns {Promise} API response promise.
 */
function deleteInfluencerPlatform(projectId, influencerId, platformId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}/platform/${platformId}`);
}

/**
 * Updates the clients associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {Array<number>} clients - List of client IDs to associate with the influencer.
 * @returns {Promise} API response promise.
 */
function updateInfluencerClients(projectId, influencerId, clients) {
    let parts = [];
    if (clients.length > 0) {
        parts.push(`ids=${clients.join(',')}`);
    }
    return httpManager.putHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/client?${parts.join('&')}`);
}

/**
 * Retrieves the clients associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise containing client data.
 */
function getInfluencerClients(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/client`);
}

/**
 * Updates the categories associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {Array<number>} categories - List of category IDs to associate with the influencer.
 * @returns {Promise} API response promise.
 */
function updateInfluencerCategories(projectId, influencerId, categories) {
    let parts = [];
    if (categories.length > 0) {
        parts.push(`ids=${categories.join(',')}`);
    }
    return httpManager.putHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/category?${parts.join('&')}`);
}

/**
 * Retrieves the categories associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise containing category data.
 */
function getInfluencerCategories(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/category`);
}

/**
 * Adds a document to an influencer's profile.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {Object} document - The document data.
 * @returns {Promise} API response promise.
 */
function addInfluencerDocument(projectId, influencerId, document) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/document`, document);
}

/**
 * Retrieves all documents associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise containing document data.
 */
function getInfluencerDocument(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/document`);
}

/**
 * Updates a document associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {number} documentId - The ID of the document.
 * @param {Object} document - The updated document data.
 * @returns {Promise} API response promise.
 */
function updateInfluencerDocument(projectId, influencerId, documentId, document) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/document/${documentId}`, document);
}

/**
 * Deletes a document from an influencer's profile.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {number} documentId - The ID of the document.
 * @returns {Promise} API response promise.
 */
function deleteInfluencerDocument(projectId, influencerId, documentId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}/document/${documentId}`);
}

/**
 * Adds a rate for an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {Object} rate - The rate data.
 * @returns {Promise} API response promise.
 */
function addInfluencerRate(projectId, influencerId, rate) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/rate`, rate);
}

/**
 * Retrieves all rates associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise containing rate data.
 */
function getInfluencerRates(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/rate`);
}

/**
 * Updates a rate associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {number} rateId - The ID of the rate.
 * @param {Object} rate - The updated rate data.
 * @returns {Promise} API response promise.
 */
function updateInfluencerRate(projectId, influencerId, rateId, rate) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/rate/${rateId}`, rate);
}

/**
 * Deletes a rate from an influencer's profile.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {number} rateId - The ID of the rate.
 * @returns {Promise} API response promise.
 */
function deleteInfluencerRate(projectId, influencerId, rateId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}/rate/${rateId}`);
}

/**
 * Adds a note to an influencer's profile.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {Object} note - The note data.
 * @returns {Promise} API response promise.
 */
function addInfluencerNote(projectId, influencerId, note) {
    return httpManager.postJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/note`, note);
}

/**
 * Retrieves all notes associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise containing note data.
 */
function getInfluencerNotes(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/note`);
}

/**
 * Updates a note associated with an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {number} noteId - The ID of the note.
 * @param {Object} note - The updated note data.
 * @returns {Promise} API response promise.
 */
function updateInfluencerNote(projectId, influencerId, noteId, note) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/note/${noteId}`, note);
}

/**
 * Deletes a note from an influencer's profile.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {number} noteId - The ID of the note.
 * @returns {Promise} API response promise.
 */
function deleteInfluencerNote(projectId, influencerId, noteId) {
    return httpManager.deleteHTTPOnce(`/api/project/${projectId}/influencer/${influencerId}/note/${noteId}`);
}

/**
 * Retrieves all activities related to an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @returns {Promise} API response promise containing activity data.
 */
function getInfluencerActivities(projectId, influencerId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/${influencerId}/activities`);
}

/**
 * Retrieves a list of clients.
 * @param {number} projectId - The ID of the project.
 * @returns {Promise} API response promise containing client data.
 */
function getClients(projectId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/list/clients`);
}

/**
 * Retrieves a list of available platform types.
 * @param {number} projectId - The ID of the project.
 * @returns {Promise} API response promise containing platform data.
 */
function getPlatformTypes(projectId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/list/platforms`);
}

/**
 * Retrieves a list of available categories.
 * @param {number} projectId - The ID of the project.
 * @returns {Promise} API response promise containing category data.
 */
function getCategories(projectId) {
    return httpManager.getHttpOnce(`/api/project/${projectId}/influencer/list/categories`);
}

/**
 * Updates the responsible party for an influencer.
 * @param {number} projectId - The ID of the project.
 * @param {number} influencerId - The ID of the influencer.
 * @param {Object} influencer - The updated responsible party data.
 * @returns {Promise} API response promise.
 */
function updateInfluencerResponsible(projectId, influencerId, influencer) {
    return httpManager.putJsonOnce(`/api/project/${projectId}/influencer/${influencerId}/responsible`, influencer);
}