import { httpManagerService as httpManager } from '_services';

export const utmService = {
    getUtms,
    generateUtm,
    getCodex,
    getPresets,
};

/**
 * Retrieves UTM records for a given project.
 * @param {string} projectId - The ID of the project.
 * @param {string|null} [query=null] - The search query for filtering results.
 * @param {number} [page=1] - The page number for pagination.
 * @param {number} [size=15] - The number of records per page.
 * @returns {Promise<Object>} A promise resolving to the list of UTMs.
 */
function getUtms(projectId, query = null, page = 1, size = 15) {
    let parts = [];

    if (query !== null && query !== '') {
        parts.push(`query=${query}`);
    }

    if (page != null && page > 0) {
        parts.push(`page=${page}`);
    }

    if (size != null && size > 0) {
        parts.push(`size=${size}`);
    }

    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/utm?` + parts.join('&',));
}

/**
 * Generates a new UTM for a given project.
 * @param {string} projectId - The ID of the project.
 * @param {Object} request - The UTM generation request data.
 * @returns {Promise<Object>} A promise resolving to the generated UTM response.
 */
function generateUtm(projectId, request) {
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/utm`, request);
}

/**
 * Retrieves the codex for a given project's UTM.
 * @param {string} projectId - The ID of the project.
 * @returns {Promise<Object>} A promise resolving to the UTM codex.
 */
function getCodex(projectId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/utm/codex`);
}

/**
 * Retrieves preset UTMs for a given project.
 * @param {string} projectId - The ID of the project.
 * @returns {Promise<Object>} A promise resolving to the list of UTM presets.
 */
function getPresets(projectId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/utm/preset`);
}