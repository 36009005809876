import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { influencerActions, projectActions } from "_actions";
import TopBar from '../../_components/TopBar';
import ProjectDetails from '../../_components/Drawer/ProjectDetails';
import ProjectMembers from '../../_components/Drawer/ProjectMembers';
import Calendly from '../../_components/Drawer/Calendly';
import { InfluencerBreadcrumb } from "_pages/Influencer/components/General/InfluencerBreadcrumb";
import Button from "_pages/Influencer/components/General/Button";
import StatusFilter from "_pages/Propelrr/components/View/StatusFilter";
import HandleByFilter from "_pages/Influencer/components/Info/HandleByFilter";
import { utilitiesService } from "_services";
import { MDBCol, MDBRow } from "mdbreact";
import BasicInfo from "_pages/Influencer/components/Info/BasicInfo";
import { ClientInfo } from "_pages/Influencer/components/Info/ClientInfo";
import ActivitySide from "_pages/Influencer/components/Info/ActivitySide";
import { EditInfluencerModal } from "_pages/Influencer/components/Modals/EditInfluencerModal";
import { ManageNoteModal } from "_pages/Influencer/components/Modals/ManageNoteModal";
import { ManageRecordModal } from "_pages/Influencer/components/Modals/ManageRecordModal";
import { ManageRateModal } from "_pages/Influencer/components/Modals/ManageRateModal";
import { ManageRateModalNew } from "_pages/Influencer/components/Modals/ManageRateModalNew";
import { ManagePlatformModal } from "_pages/Influencer/components/Modals/ManagePlatformModal";
import { AddInfluencerDrawer } from '_pages/Influencer/components/Drawer/AddInfluencerDrawer';
import { ConfirmationDeleteModal } from "_pages/Influencer/components/Modals/ConfirmationDeleteModal";
import CategoryFilter from "_pages/Influencer/components/Info/CategoryFilter";
import TopBarLoader from '../../_components/Loader/TopBarLoader';
import ButtonGroupLoader from "../../_components/Loader/ButtonGroupLoader";
import BreadcrumbLoader from '../../_components/Loader/BreadcrumbLoader';
import TextLoader from '../../_components/Loader/TextLoader';
import EditInfluencerDrawer from '_pages/Influencer/components/Drawer/EditInfluencerDrawer';

export const InfluencerInfo = ({ match }) => {
  const dispatch = useDispatch();
  const { projects, influencers } = useSelector((state) => state);
  const { influencerLoading, influencerLoaded, getCategoryListLoaded } = influencers;

  const [state, setState] = useState({
    isAddInfluencerDrawer: false,
    isEditInfluencerDrawer: false,
    isDeleteModalOpen: false,
    isManagePlatformModalOpen: false,
    isManageRateModalOpen: false,
    isManageRecordModalOpen: false,
    isManageNoteModalOpen: false,
    isDeleteConfirmationModalOpen: false,
    isAddFormDrawer: false,
    isProjectDetailsDrawer: false,
    isProjectMembersDrawer: false,
    isCalendlyDrawer: false,
    deleteConfirmationType: '',
    deleteConfirmationData: {},
    managePlatformData: {},
    managePlatformMode: 'Add',
    manageRateData: {},
    manageRateMode: 'Add',
    manageRecordData: {},
    manageRecordMode: 'Add',
    manageNoteData: {},
    manageNoteMode: 'Add',
  });

  const { id, influencerId } = match.params;

  useEffect(() => {
    dispatch(projectActions.getProject(id));
    dispatch(influencerActions.getInfluencer(id, influencerId));
    dispatch(influencerActions.getCategories(id));
  }, [dispatch, id, influencerId]);

  const handleOpenConfirmDeleteModal = (type, data) => {
    setState((prev) => ({
      ...prev,
      isDeleteConfirmationModalOpen: true,
      deleteConfirmationType: type,
      deleteConfirmationData: data,
    }));
  };

  const handleOpenManagePlatformModal = (mode, data) => {
    setState((prev) => ({
      ...prev,
      isManagePlatformModalOpen: true,
      managePlatformMode: mode,
      managePlatformData: data,
    }));
  };

  const handleOpenManageRecordsModal = (mode, data) => {
    setState((prev) => ({
      ...prev,
      isManageRecordsModalOpen: true,
      manageRecordMode: mode,
      manageRecordData: data,
    }));
  };

  const handleOpenManageNotesModal = (mode, data) => {
    setState((prev) => ({
      ...prev,
      isManageNotesModalOpen: true,
      manageNoteMode: mode,
      manageNoteData: data,
    }));
  };

  const handleOpenManageRatesModal = (mode, data) => {
    setState((prev) => ({
      ...prev,
      isManageRatesModalOpen: true,
      manageRateMode: mode,
      manageRateData: data,
    }));
  };

  const handleCloseEditInfluencerModal = () => {
    setState((prev) => ({ ...prev, isEditInfluencerModalOpen: false }));
  };

  const handleCloseManagePlatformModal = () => {
    setState((prev) => ({
      ...prev,
      isManagePlatformModalOpen: false,
      managePlatformData: {},
      managePlatformMode: 'add',
    }));
  };

  const handleCloseManageRatesModal = () => {
    setState((prev) => ({
      ...prev,
      isManageRatesModalOpen: false,
      manageRateData: {},
      manageRateMode: 'add',
    }));
  };

  const handleCloseManageRecordsModal = () => {
    setState((prev) => ({
      ...prev,
      isManageRecordsModalOpen: false,
      manageRecordData: {},
      manageRecordMode: 'add',
    }));
  };

  const handleCloseManageNotesModal = () => {
    setState((prev) => ({
      ...prev,
      isManageNotesModalOpen: false,
      manageNoteData: {},
      manageNoteMode: 'add',
    }));
  };

  const handleCloseConfirmationDeleteModal = () => {
    setState((prev) => ({ ...prev, isDeleteConfirmationModalOpen: false }));
  };

  const handleStatusChange = (status) => {
    dispatch(influencerActions.updateInfluencerStatus(id, influencerId, status));
  };

  const handleCategoryChange = (categories) => {
    dispatch(influencerActions.updateInfluencerCategories(id, influencerId, categories));
  };

  const handleProjectDetailsDrawer = () => {
    setState((prev) => ({ ...prev, isProjectDetailsDrawer: !prev.isProjectDetailsDrawer }));
  };

  const handleProjectMembersDrawer = () => {
    setState((prev) => ({ ...prev, isProjectMembersDrawer: !prev.isProjectMembersDrawer }));
  };

  const handleCalendlyDrawer = () => {
    setState((prev) => ({ ...prev, isCalendlyDrawer: !prev.isCalendlyDrawer }));
  };

  const handleAddInfluencerDrawer = () => {
    setState((prev) => ({ ...prev, isAddInfluencerDrawer: !prev.isAddInfluencerDrawer }));
  };

  const handleEditInfluencerDrawer = () => {
    setState((prev) => ({ ...prev, isEditInfluencerDrawer: !prev.isEditInfluencerDrawer }));
  };

  const handleCloseEditInfluencerDrawer = () => {
    setState((prev) => ({ ...prev, isEditInfluencerDrawer: false }));
  };

  const influencer = influencerLoaded ? influencers.influencer.data : {};
  const owner = influencerLoaded
    ? `${utilitiesService.decrypt(influencer.owner.firstName)} ${utilitiesService.decrypt(influencer.owner.lastName)}`
    : '';
  const responsible = influencerLoaded
    ? `${utilitiesService.decrypt(influencer.responsible.firstName)} ${utilitiesService.decrypt(influencer.responsible.lastName)}`
    : '';
  const status = influencerLoaded ? influencer.condition.name : '';
  const statusColor = influencerLoaded ? influencer.condition.id : '';
  const categories = influencerLoaded
    ? influencer.categories.length > 0
      ? influencer.categories.sort((a, b) => a.name > b.name).map((c) => c.name).join(', ')
      : 'Select Category'
    : '';
  const categoryIds = influencerLoaded
    ? influencer.categories.sort((a, b) => a.name > b.name).map((c) => c.id)
    : [];
  const name = influencerLoaded ? influencer.name : '';
  const contacted = influencerLoaded ? influencer.contacted : '';
  const contact = influencerLoaded ? utilitiesService.decrypt(influencer.contact) : '';
  const email = influencerLoaded ? utilitiesService.decrypt(influencer.email) : '';
  const handler = influencerLoaded ? influencer.handler : null;
  const validIdType = influencerLoaded
    ? influencer.validIdType
      ? utilitiesService.decrypt(influencer.validIdType) === 'others-radio'
        ? utilitiesService.decrypt(influencer.validIdOthers)
        : utilitiesService.decrypt(influencer.validIdType)
      : null
    : null;
  const validIdNo = influencerLoaded ? (influencer.validIdNo ? utilitiesService.decrypt(influencer.validIdNo) : null) : null;
  const address = influencerLoaded ? (influencer.address ? utilitiesService.decrypt(influencer.address) : null) : null;

  return (
    <React.Fragment>
      <div className='topbar-menu'>
        <div className='row align-items-center'>
          <TopBar
            onHandleProjectDetailsDrawer={handleProjectDetailsDrawer}
            onHandleProjectMembersDrawer={handleProjectMembersDrawer}
          />
          <div className='col'>
            {influencerLoading && <ButtonGroupLoader />}
          </div>
        </div>
      </div>
      <div className='breadcrumb-menu'>
        <div className='row align-items-center'>
          <div className='col'>
            <InfluencerBreadcrumb
              influencerBdLoaded={influencerLoaded}
              influencerBdLoading={influencerLoading}
            />
          </div>
          <div className='col'>
            {influencerLoaded && (
              <div className='button-group'>
                <Button classN='c-btn c-btn-light' text='Edit Influencer' icon='icon-lms icon-edit' onClick={handleEditInfluencerDrawer} />
                <Button classN='c-btn c-btn-light' text='Delete Influencer' icon='icon-lms icon-delete' onClick={() => handleOpenConfirmDeleteModal('influencer', influencer)} />
              </div>
            )}
            {influencerLoading && <ButtonGroupLoader />}
          </div>
        </div>
        <BreadcrumbLoader />
      </div>
      <div className='main-info-section influencers-info'>
        <MDBRow className="row-100">
          <MDBCol md='7'>
            <div className='top-info-box'>
              <div className='row'>
                <div className='col-box'>
                  <div className='avatar-text-box'>
                    <div className='text'>
                      <label>Created By</label>
                      <span>{owner}</span>
                      {influencerLoading && <TextLoader />}
                    </div>
                  </div>
                </div>
                <div className='col-box'>
                  <div className='avatar-text-box'>
                    <div className='text'>
                      <label>Handle By</label>
                      <HandleByFilter responsible={responsible} />
                      {influencerLoading && <TextLoader />}
                    </div>
                  </div>
                </div>
                <div className='col-box'>
                  <div className='avatar-text-box'>
                    <div className='text'>
                      <label>Category</label>
                      <CategoryFilter
                        categories={categories}
                        categoryIds={categoryIds}
                        categoryList={influencers.categoryList || []}
                        onCategoryChanged={handleCategoryChange}
                      />
                      {influencerLoading && <TextLoader />}
                    </div>
                  </div>
                </div>
                <div className='col-box'>
                  <div className='avatar-text-box'>
                    <div className='text'>
                      <label>Status</label>
                      {!influencerLoading && (
                        <StatusFilter
                          status={status}
                          statusColor={statusColor}
                          onStatusChange={handleStatusChange}
                        />
                      )}
                      {influencerLoading && <TextLoader />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='information-box information-influencer-box'>
              <BasicInfo
                influencerBasicInfoLoaded={influencerLoaded}
                influencerBasicInfoLoading={influencerLoading}
                name={name}
                contacted={contacted}
                email={email}
                contact={contact}
                handler={handler}
                validIdType={validIdType}
                validIdNo={validIdNo}
                address={address}
              />
            </div>
          </MDBCol>
          <MDBCol md='5'>
            <ActivitySide
              onHandleManagePlatformModal={handleOpenManagePlatformModal}
              onHandleManageRecordsModal={handleOpenManageRecordsModal}
              onHandleManageNotesModal={handleOpenManageNotesModal}
              onHandleManageRatesModal={handleOpenManageRatesModal}
              onHandleOpenConfirmationModal={handleOpenConfirmDeleteModal}
              influencerBasicInfoLoaded={influencerLoaded}
              influencerBasicInfoLoading={influencerLoading}
            />
          </MDBCol>
        </MDBRow>
      </div>

      <AddInfluencerDrawer
        projectId={id}
        isAddInfluencerDrawer={state.isAddInfluencerDrawer}
        onHandleAddInfluencerDrawer={handleAddInfluencerDrawer}
      />

      <EditInfluencerDrawer
        isEditInfluencerDrawer={state.isEditInfluencerDrawer}
        onHandleEditInfluencerDrawer={handleEditInfluencerDrawer}
        onHandleCloseEditInfluencerDrawer={handleCloseEditInfluencerDrawer}
        influencerId={influencerId}
        projectId={id}
        data={influencer}
      />

      <ManagePlatformModal
        isOpen={state.isManagePlatformModalOpen}
        mode={state.managePlatformMode}
        data={state.managePlatformData}
        influencerId={influencerId}
        projectId={id}
        handleCloseManagePlatformModal={handleCloseManagePlatformModal}
      />

      <ManageRecordModal
        isOpen={state.isManageRecordsModalOpen}
        mode={state.manageRecordMode}
        data={state.manageRecordData}
        influencerId={influencerId}
        projectId={id}
        handleCloseManageRecordsModal={handleCloseManageRecordsModal}
      />

      <ManageNoteModal
        isOpen={state.isManageNotesModalOpen}
        mode={state.manageNoteMode}
        data={state.manageNoteData}
        influencerId={influencerId}
        projectId={id}
        handleCloseManageNotesModal={handleCloseManageNotesModal}
      />

      <ManageRateModal
        isOpen={state.isManageRatesModalOpen}
        mode={state.manageRateMode}
        data={state.manageRateData}
        influencerId={influencerId}
        projectId={id}
        handleCloseManageRatesModal={handleCloseManageRatesModal}
      />

      <ConfirmationDeleteModal
        isOpen={state.isDeleteConfirmationModalOpen}
        influencerId={influencerId}
        projectId={id}
        type={state.deleteConfirmationType}
        data={state.deleteConfirmationData}
        toggle={state.isDeleteConfirmationModalOpen}
        onHandleCloseConfirmationDeleteModal={handleCloseConfirmationDeleteModal}
      />
    </React.Fragment>
  );
};