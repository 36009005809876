import React, { useState } from "react";

/**
 * Component for displaying project details in a drawer.
 *
 * @param {Object} props - The component props.
 * @param {function} props.onHandleProjectDetailsDrawer - Function to handle the drawer state.
 * @param {boolean} props.isProjectDetailsDrawer - Boolean indicating if the drawer is open.
 * @returns {JSX.Element} The ProjectDetails component.
 */
const ProjectDetails = ({
  onHandleProjectDetailsDrawer,
  isProjectDetailsDrawer,
}) => {
  const [toggleTabState, setToggleTabState] = useState(1);

  const toggleTab = (index) => {
    setToggleTabState(index);
  };

  let activeClass = isProjectDetailsDrawer ? "active" : "";

  return (
    <>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={onHandleProjectDetailsDrawer}
      ></div>
      <div className={`side-drawer-main ${activeClass}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <button
              className="side-drawer-close"
              onClick={onHandleProjectDetailsDrawer}
            >
              <i className="fas fa-times"></i>
            </button>
            <h3>Project Details</h3>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="control-tabs-box">
                <div
                  className={
                    toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => toggleTab(1)}
                >
                  <p>Description</p>
                </div>
                <div className="line-slider"></div>
              </div>
              <div className="content-tabs-box">
                <div
                  className={
                    toggleTabState === 1
                      ? "tabs-content active"
                      : "tabs-content"
                  }
                >
                  <div className="description-tab">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolorum officiis sapiente quo voluptas accusamus veritatis
                      distinctio nesciunt! Deleniti rerum veritatis minima quas,
                      et maxime qui mollitia harum fugit, quasi velit? Dolorem
                      earum explicabo a laudantium ad modi obcaecati sunt ipsam!
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
