import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { influencerActions } from "_actions/influencer.actions";

const TablePagination = ({ number, first, last, size, totalElements, onHandlePageChanged, onHandleSizeChanged }) => {
    const searchRef = useRef(null);
    const filterRef = useRef(null);
    const searchInputRef = useRef(null);
    const gotoInputRef = useRef(null);

    const [currentSize, setCurrentSize] = useState(size || 15);
    const [currentPage, setCurrentPage] = useState(number + 1);

    useEffect(() => {
        setCurrentSize(size);
    }, [size]);

    useEffect(() => {
        setCurrentPage(number + 1);
    }, [number]);

    const handlePageChanged = (value, className) => {
        if (className === 'active') {
            setCurrentPage(value);
            onHandlePageChanged(value);
        }
    };

    const handleSizeChanged = (e) => {
        let newSize = parseInt(e.target.value);
        let min = (number + 1) * newSize - (newSize - 1);
        if (min <= totalElements) {
            onHandleSizeChanged(newSize);
        } else {
            onHandleSizeChanged(size);
        }
    };

    const handleKeyDown = (event, value, className) => {
        if (event.key === "Enter" && className === "active") {
            event.preventDefault();
            let min = value * size - (size - 1);
            if (min <= totalElements) {
                setCurrentPage(value);
                onHandlePageChanged(value);
            } else {
                onHandlePageChanged((currentPage * size) > totalElements ? 1 : currentPage);
            }
        }
    };

    let min = (currentPage * size) - (size - 1);
    let max = currentPage * size;
    let count = totalElements;
    let prevClass = first ? '' : 'active';
    let nextClass = last ? '' : 'active';
    let nextPage = currentPage + 1;
    let previousPage = currentPage - 1;
    let activeSize = currentSize || size;

    let countText = 'Loading Influencers ...';
    if (currentPage) {
        let limit = max > count ? count : max;
        countText = `${min}-${limit} of ${count}`;
    }

    return (
        <div className='table-pagination-box'>
            <div className='pagination-box'>
                <div className='influencer-counts'>
                    <span>Show rows: </span>
                    <select value={activeSize} onChange={handleSizeChanged}>
                        <option value={15}>15</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                    <div className='go-to-page'>
                        <span>Go to</span>
                        <input type="number" defaultValue={currentPage} key={currentPage} ref={gotoInputRef}
                            onKeyDown={(e) => handleKeyDown(e, e.target.value, 'active')} />
                    </div>
                    <span>{countText}</span>
                </div>
                <ul>
                    <a onClick={() => handlePageChanged(previousPage, prevClass)} className={prevClass}>
                        <i className="icon-lms icon-arrow-left"></i>
                    </a>
                    <a onClick={() => handlePageChanged(nextPage, nextClass)} className={nextClass}>
                        <i className="icon-lms icon-arrow-right"></i>
                    </a>
                </ul>
            </div>
        </div>
    );
};

const mapState = (state) => {
    const { influencerListLoaded } = state.influencers;
    if (influencerListLoaded) {
        const { list } = state.influencers;
        const { number, first, last, size, totalElements } = list.data;
        return { number, first, last, size, totalElements, influencerListLoaded };
    }
    return { influencerListLoaded };
};

const actionCreators = {
    getInfluencerList: influencerActions.getInfluencerList
};

const connectedViewTablePagination = connect(mapState, actionCreators)(TablePagination);
export { connectedViewTablePagination as TablePagination };
