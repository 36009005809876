import { userConstants } from '_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

/**
 * Reducer function to handle authentication state changes.
 *
 * @param {Object} state - The current state of authentication.
 * @param {Object} action - The action dispatched to the reducer.
 * @param {string} action.type - The type of action being dispatched.
 * @param {Object} [action.user] - The user object, provided on login actions.
 * @returns {Object} The new state of authentication.
 */
export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
