import { chatConstants } from "_constants";
import { chatService } from "_services";
import { alertActions } from "_actions/alert.actions";

export const chatActions = {
  getChats,
  updateUnreadChats,
  agentMessageReply,
};

/**
 * Fetches chat conversations for a given project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {boolean} chatNotif - Flag to indicate if a notification should be dispatched.
 * @returns {Function} A thunk function that dispatches actions based on the chat service response.
 */
function getChats(projectId, leadId, chatNotif) {
  return (dispatch) => {
    if(chatNotif) {
      dispatch(notification());
    }
    dispatch(request(projectId, leadId));
    chatService.getLeadChatConversations(projectId, leadId).then(
      (chats) => {
        // console.log('response: ', chats.data.chat_history.length)
        dispatch(success(chats));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        dispatch(failure({error_code: 'LC001', error_message: 'Chat Conversations not syncing'}));
        dispatch(alertActions.error({error_code: 'LC001', error_message: 'Chat Conversations not syncing'}));
      }
    );
  };

  function request(projectId, leadId) {
    return { type: chatConstants.GET_ALL_CHATS_REQUEST, projectId, leadId };
  }

  function success(chats) {
    return { type: chatConstants.GET_ALL_CHATS_SUCCESS, chats };
  }

  function failure(error) {
    return { type: chatConstants.GET_ALL_CHATS_FAILURE, error };
  }

  function notification() {
    return { type: chatConstants.CHAT_NOTIFICATION_REQUEST};
  }
}

/**
 * Updates the unread chats for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {number} unreadChats - The number of unread chats.
 * @returns {Function} A thunk function that dispatches actions based on the result of the chat service update.
 */
function updateUnreadChats(projectId, leadId, unreadChats) {
  return (dispatch) => {
    dispatch(request(projectId, leadId));
    chatService.updateUnreadChats(projectId, leadId, unreadChats).then(
      (chats) => {
        // console.log('response: ', chats)
        dispatch(success(chats));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("chat data: ", data);
        dispatch(failure({error_code: 'LC001', error_message: 'Chat Conversations not syncing'}));
        dispatch(alertActions.error({error_code: 'LC001', error_message: 'Chat Conversations not syncing'}));
      }
    );
  };

  function request(projectId, leadId) {
    return {
      type: chatConstants.UPDATE_CHATS_UNREAD_REQUEST,
      projectId,
      leadId,
    };
  }

  function success(chats) {
    return { type: chatConstants.UPDATE_CHATS_UNREAD_SUCCESS, chats };
  }

  function failure(error) {
    return { type: chatConstants.UPDATE_CHATS_UNREAD_FAILURE, error };
  }
}


/**
 * Sends a reply message from an agent in a chat.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {Object} replyRequest - The reply request object containing the message details.
 * @returns {Function} A thunk function that dispatches actions based on the success or failure of the reply request.
 */
function agentMessageReply(projectId, leadId, replyRequest) {
  return (dispatch) => {
    dispatch(request(projectId, leadId, replyRequest));
    chatService.agentMessageReply(projectId, leadId, replyRequest).then(
      (chats) => {
      //  console.log('response: ', chats)
        dispatch(success(chats));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
      //  console.log("chat data: ", data);
        dispatch(failure({error_code: 'LC002', error_message: 'Message Reply unable to send. Response required within 24 hours.'}));
        dispatch(alertActions.error({error_code: 'LC002', error_message: 'Message Reply unable to send. Response required within 24 hours.'}));
      }
    );
  };

  function request(projectId, leadId) {
    return {
      type: chatConstants.CHAT_REPLY_REQUEST,
      projectId,
      leadId,
    };
  }

  function success(chats) {
    return { type: chatConstants.CHAT_REPLY_SUCCESS, chats };
  }

  function failure(error) {
    return { type: chatConstants.CHAT_REPLY_FAILURE, error };
  }
}