import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MDBCol, MDBRow } from "mdbreact";
import FormGroupTitle from "_pages/Propelrr/components/Form/FormGroupTitle";
import InputForm from "_components/InputForm";
import { formActions, leadActions, projectActions } from "_actions";
import { utilitiesService as utility, utilitiesService } from "_services";
import { leadData } from "_pages/Leads/constants/LeadData";
import DropDownLists from "_pages/Influencer/constants/DropDownLists";

const AddLeadDrawer = ({ isAddLeadDrawer, onHandleAddLeadDrawer }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const multiselectRef = useRef();
  const customselectRef = useRef();

  const [toggleTabState, setToggleTabState] = useState(1);
  const [fieldData, setFieldData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [sourceData, setSourceData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [isMultiselectOpen, setIsMultiselectOpen] = useState(false);
  const [multiselectSelectedValues, setMultiselectSelectedValues] = useState({});
  const [isCustomselectOpen, setIsCustomselectOpen] = useState(false);
  const [customselectSelectedValue, setCustomselectSelectedValue] = useState("");
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const services = DropDownLists.services;

  const positions = DropDownLists.positions;

  const primaryLeadType = DropDownLists.primaryLeadType;
  

  useEffect(() => {
    const projectId = params.id;
    dispatch(formActions.getDefaultForm(projectId));
    dispatch(projectActions.getAllProjectStatus(projectId));
    
    setMultiselectSelectedValues({});
    setCustomselectSelectedValue("");

    //    Clear the fieldData and fieldErrors when the component is opened
    if (isAddLeadDrawer) {
      setFieldData({});
      setFieldErrors({});
    }
  }, [params.id]);

  let activeClass = isAddLeadDrawer ? "active" : "";

  const formLoaded = useSelector((state) => {
    const { forms } = state;
    const { formLoaded } = forms;
    return formLoaded ? formLoaded : false;
  });

  const form = useSelector((state) => {
    const { forms } = state;
    const { formLoaded, form } = forms;

    if (formLoaded) {
      return form.data;
    }

    return {};
  });

  const group = useSelector((state) => {
    const { forms } = state;
    const { formLoaded, form } = forms;

    if (formLoaded) {
      const { group } = form.data;
      return group
        .filter((g) => g.status)
        .sort((a, b) => a.form_order < b.form_order);
    }
    return [];
  });

  const statusList = useSelector((state) => {
    const { projects } = state;
    const { statusLoaded, status } = projects;

    if (statusLoaded) {
      const list = status.data;
      return list.filter((g) => g.status);
    }

    return [];
  });

  const createLeadLoading = useSelector((state) => {
    const { leads } = state;
    const { createLeadLoading } = leads;

    if (createLeadLoading) {
      return createLeadLoading;
    }
    return false;
  });

  const memberList = useSelector((state) => {
    const { projects } = state;
    const { project, projectLoaded } = projects;

    if (projectLoaded) {
      project.data.owner.account.id = project.data.owner.id.toString();
      let owner = project.data.owner.account;
      owner.id = project.data.owner.id.toString();

      let list = [owner];

      project.data.members.forEach((m) => list.push(m.account));

      return list.map((m) => {
        return {
          id: parseInt(m.id),
          label:
            utility.decrypt(m.first_name) + " " + utility.decrypt(m.last_name),
          email: m.email,
        };
      });
    } else {
      return [];
    }
  });

  function handleOpenMultiselect() {
    setIsMultiselectOpen(!isMultiselectOpen);
  }

  useEffect(() => {
    const customselectOutsideClick = (e) => {
      if (
        isMultiselectOpen &&
        multiselectRef.current &&
        !multiselectRef.current.contains(e.target)
      ) {
        setIsMultiselectOpen(false);
      }
    };
    document.addEventListener("click", customselectOutsideClick);
    return () => {
      document.removeEventListener("click", customselectOutsideClick);
    };
  }, [isMultiselectOpen]);

  useEffect(() => {
    const customselectOutsideClick = (e) => {
      if (
        isCustomselectOpen &&
        customselectRef.current &&
        !customselectRef.current.contains(e.target)
      ) {
        setIsCustomselectOpen(false);
      }
    };
    document.addEventListener("click", customselectOutsideClick);
    return () => {
      document.removeEventListener("click", customselectOutsideClick);
    };
  }, [isCustomselectOpen]);

  const handleMultiselectCheckboxChange = (id, value, checked) => {
    setMultiselectSelectedValues((prevSelectedValues) => {
      const updatedValues = {
        ...prevSelectedValues,
        [id]: checked
          ? [...(prevSelectedValues[id] || []), value]
          : (prevSelectedValues[id] || []).filter((val) => val !== value),
      };
      return updatedValues;
    });

    let multi = utilitiesService.encrypt(
      (multiselectSelectedValues[id] || []).join(',')
    );
    setFieldData((prevFieldData) => ({
      ...prevFieldData,
      [id]: multi,
    }));
  };

  function handleOpenCustomselect() {
    setIsCustomselectOpen(!isCustomselectOpen);
  }

  const handleCustomselectSelectValue = (id, value) => {
    setCustomselectSelectedValue(value);
    setIsCustomselectOpen(false);

    let val = utilitiesService.encrypt(value);
    setFieldData((prevFieldData) => ({
      ...prevFieldData,
      [id]: val,
    }));
  };

  const renderGroupField = (group) => {
    let fields = group.field
      .filter((g) => g.status)
      .sort((a, b) => a.form_order < b.form_order);
    return (
      <React.Fragment>
        <FormGroupTitle label={group.name} />

        {fields.map((f, index) => {
          return <React.Fragment key={index}>{renderField(f)}</React.Fragment>;
        })}
      </React.Fragment>
    );
  };

  const renderStatusInfo = () => {
    return (
      <React.Fragment>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`responsible`}>Assigned To</label>
            <select
              className="c-select"
              name={`responsible`}
              id={`responsible`}
              onChange={handleStatusChange}
            >
              <option value={null}>Unassigned</option>
              {memberList.map((m, i) => {
                return (
                  <option key={i} value={m.email}>
                    {m.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`priority`}>Priority</label>
            <select
              className="c-select"
              name={`priority`}
              id={`priority`}
              onChange={handleStatusChange}
            >
              <option value={3}>High Priority</option>
              <option value={2}>Medium Priority</option>
              <option value={1}>Low Priority</option>
            </select>
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`status`}>Status</label>
            <select
              className="c-select"
              name={`status`}
              id={`status`}
              onChange={handleStatusChange}
            >
              {statusList.map((s, i) => {
                return (
                  <option key={i} value={s.id}>
                    {s.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const handleCloseDrawer = () => {
    onHandleAddLeadDrawer();
    setIsCustomselectOpen(false);
    setIsMultiselectOpen(false);
  };

  const handleCancelDrawer = () => {
    console.log("cancel data");
    setFieldData({});
    setFieldErrors({});
    onHandleAddLeadDrawer();
    setIsCustomselectOpen(false);
    setIsMultiselectOpen(false);
  };

  const renderSourceInfo = () => {
    return (
      <React.Fragment>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`origin`}>Point Of Conversion</label>
            <InputForm
              onChange={handleSourceChange}
              id={`origin`}
              name={`origin`}
              disabled={true}
              value="LMS Form"
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`source`}>Source</label>
            <InputForm
              onChange={handleSourceChange}
              id={`source`}
              name={`source`}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`medium`}>Medium</label>
            <InputForm
              onChange={handleSourceChange}
              id={`medium`}
              name={`medium`}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`channel`}>Channel</label>
            <InputForm
              onChange={handleSourceChange}
              id={`channel`}
              name={`channel`}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`campaign`}>Campaign</label>
            <InputForm
              onChange={handleSourceChange}
              id={`campaign`}
              name={`campaign`}
            />
          </div>
        </div>
        <div className="input-group-box full-box-">
          <div className="field-box">
            <label htmlFor={`clientId`}>Google Client Id</label>
            <InputForm
              onChange={handleSourceChange}
              id={`clientId`}
              name={`clientId`}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderField = (field) => {
    const { type_id, hide_label, id, label, required } = field;
    let input = null;

    switch (type_id) {
      case 1:
        input = renderTextInput(field);
        break;

      case 2:
        input = renderTextField(field);
        break;

      case 5:
        input = renderMultiSelect(field);
        break;

      case 10:
        input = renderSingleSelect(field);
        break;

      default:
        break;
    }

    return (
      <div className="input-group-box full-box-">
        <div className="field-box">
          <label htmlFor={id}>
            {label}
            {required && <small className="color-red">*</small>}
          </label>
          {input}
          {fieldErrors[id] && fieldErrors[id].error && (
            <p className="error-field">{fieldErrors[id].message}</p>
          )}
        </div>
      </div>
    );
  };

  const renderTextInput = (field) => {
    const { id, placeholder, required, label } = field;
    let staticLead = params.id == 5 ? "Service Inquiry" : "Career Inquiry";

    return (
      <React.Fragment>
        {label !== "Date" && label !== "Time" && label !== "Lead Type" && (
          <InputForm
            onChange={handleFieldInputChange}
            hint={placeholder}
            id={id}
            name={id}
            label={label}
          />
        )}
        {label === "Date" && (
          <InputForm
            onChange={handleFieldInputChange}
            hint={placeholder}
            id={id}
            name={id}
            label={label}
            type="date"
          />
        )}
        {label === "Time" && (
          <InputForm
            onChange={handleFieldInputChange}
            hint={placeholder}
            id={id}
            name={id}
            label={label}
            type="time"
          />
        )}
        {label === "Lead Type" && (
          <InputForm
            onChange={handleFieldInputChange}
            hint={placeholder}
            id={id}
            name={id}
            label={label}
            value={staticLead}
            disabled={true}
          />
        )}
      </React.Fragment>
    );
  };

  const renderTextField = (field) => {
    const { id, placeholder, required } = field;

    return (
      <textarea
        onChange={handleFieldInputChange}
        required={required}
        name={id}
        id={id}
        placeholder={placeholder}
        rows="7"
      />
    );
  };

  const renderMultiSelect = (field) => {
    const { id } = field;

    return (
        <div className="custom-dropdown-multiselect" ref={multiselectRef}>
          <div
            className="multiselect-btn"
            onClick={() => handleOpenMultiselect()}
          >
            <span className="multiselect-text">
              {multiselectSelectedValues[id]
                ? multiselectSelectedValues[id].join(',')
                : ''}
            </span>
            <i className={isMultiselectOpen ? "active" : ''}></i>
          </div>
          <div
            className={
              isMultiselectOpen
                ? "multiselect-dropdown active"
                : "multiselect-dropdown"
            }
          >
            <div className="multiselect-list">
              {services.map((service) => (
                <div className="checkbox-group" key={service}>
                  <input
                    checked={
                      multiselectSelectedValues[id]?.includes(service) || false
                    }
                    type="checkbox"
                    name={service}
                    id={`${service}-add`}
                    value={service}
                    onChange={(e) =>
                      handleMultiselectCheckboxChange(
                        id,
                        service,
                        e.target.checked
                      )
                    }
                  />
                  <label htmlFor={`${service}-add`}>{service}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
    );
  };

  const renderSingleSelect = (field) => {
    const { id } = field;
    const singLeSelectList = params.id == 1 ? primaryLeadType : positions;

    return (
        <div className="custom-dropdown-multiselect" ref={customselectRef}>
          <div
            className="multiselect-btn"
            onClick={() => handleOpenCustomselect()}
          >
            <span className="multiselect-text">
              {customselectSelectedValue}
            </span>
            <i className={isCustomselectOpen ? "active" : ""}></i>
          </div>
          <div
            className={
              isCustomselectOpen
                ? "multiselect-dropdown active"
                : "multiselect-dropdown"
            }
          >
            <div className="multiselect-list">
              {singLeSelectList.map((option) => (
                <div
                  className="checkbox-group"
                  key={option}
                  onClick={() => handleCustomselectSelectValue(id,option)}
                >
                  <span>{option}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
    );
  };

  const handleFieldInputChange = (event) => {
    setFieldErrors({});
    const { id, value } = event.target;
    let val = value.trim();

    if (val) {
      val = utilitiesService.encrypt(value);
    }

    setFieldData({ ...fieldData, [id]: val });
  };

  const updateFieldData = (fieldId, value) => {
    setFieldData((prevFieldData) => ({
      ...prevFieldData,
      [fieldId]: utilitiesService.encrypt(value),
    }));
  };

  const handleSourceChange = (event) => {
    const { id, value } = event.target;
    setSourceData({ ...sourceData, [id]: value });
  };

  const handleStatusChange = (event) => {
    const { id, value } = event.target;
    setStatusData({ ...statusData, [id]: value });
  };

  const handleCreateLead = () => {
    setIsCustomselectOpen(false);
    setIsMultiselectOpen(false);

    let requiredFields = group
      .map((g) =>
        g.field.map((f) =>
          f.required && f.status == 1
            ? { id: f.id, label: f.label, required: f.required }
            : null
        )
      )
      .flat()
      .filter((r) => r !== null);

    const projectId = params.id;

    const emailLabels = ["Email", "Email Address"];
    const contactLabels = ["Contact", "Mobile Number"];

    let hasError = false;
    let errors = {};

    let emailValue = null;
    let contactValue = null;

    requiredFields.forEach((field) => {
      if (!fieldData[field.id] && field.required) {
        hasError = true;
        errors[field.id] = { error: true, message: "This field is required." };
      }

      if (fieldData[field.id] && field.required) {
        console.log(
          field.label + ": ",
          utilitiesService.decrypt(fieldData[field.id])
        );

        if (emailLabels.includes(field.label)) {
          emailValue = utilitiesService.decrypt(fieldData[field.id]);
          if (!emailRegex.test(emailValue)) {
            hasError = true;
            errors[field.id] = {
              error: true,
              message: "Invalid email format.",
            };
          }
        } else if (contactLabels.includes(field.label)) {
          contactValue = utilitiesService.decrypt(fieldData[field.id]);
        }
      }
    });

    const invalidEmail = Object.values(errors).some(
      (error) => error.message === "Invalid email format."
    );

  
    // Check for the conditions and update hasError accordingly
    if (!emailValue && !contactValue) {
      hasError = true; // Condition 1
    }

    if (emailValue && !errors[emailValue] && !contactValue) {
      hasError = false; // Condition 4
    }

    if (!emailValue && contactValue) {
      hasError = false; // Condition 5
    }

    if(invalidEmail && !contactValue) {
      hasError = true
    }

    setFieldErrors(errors); 

    console.log("field data: ", fieldData);

    let requestData = [];
    let keys = Object.keys(fieldData);
    keys.forEach((k) => {
      // Check if the id exists in multiselectSelectedValues and it's not empty
      if (multiselectSelectedValues.hasOwnProperty(k)) {
        if (multiselectSelectedValues[k].length > 0) {
          let multiVal = utilitiesService.encrypt(
            multiselectSelectedValues[k].join(',')
          );
          requestData.push({ key: k, value: multiVal });
        }
      } else {
        if (fieldData[k].length > 0) {
          requestData.push({ key: k, value: fieldData[k] });
        }
      }
    });

    if (params.id == 5) {
      const leadType = "Service Inquiry";
      requestData.push({ key: 68, value: utilitiesService.encrypt(leadType) });
    }

    if (params.id == 6) {
      const leadType = "Career Inquiry";
      requestData.push({ key: 79, value: utilitiesService.encrypt(leadType) });
    }

    console.log("request: ", requestData);

    let request = {
      form_lead: requestData,
      email: emailValue ? utilitiesService.encrypt(emailValue) : null,
      project_status_id: statusData.status || statusList[0].id,
      priority: statusData.priority || 2,
      responsible: statusData.responsible || null,
      traffic_source: sourceData.source || null,
      traffic_medium: sourceData.medium || null,
      traffic_channel: sourceData.channel || null,
      traffic_campaign: sourceData.campaign || null,
      origin: "LMS Form",
      ga_client_id: sourceData.clientId || null,
    };

    console.log("request: ", request);

    dispatch(leadActions.createLead(projectId, request, hasError));
  };

  return (
    <React.Fragment>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={onHandleAddLeadDrawer}
      ></div>
      <div className={`side-drawer-main ${activeClass}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <h3>Add New Lead</h3>
            <span className="side-drawer-close" onClick={handleCloseDrawer}>
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="control-tabs-box">
                <div
                  className={
                    toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => setToggleTabState(1)}
                >
                  <p>Lead Information</p>
                </div>
                <div
                  className={
                    toggleTabState === 2 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => setToggleTabState(2)}
                >
                  <p>Status Information</p>
                </div>
                <div
                  className={
                    toggleTabState === 3 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => setToggleTabState(3)}
                >
                  <p>Source Information</p>
                </div>
              </div>
              <div className="content-tabs-box">
                {!createLeadLoading && (
                  <React.Fragment>
                    <div
                      className={
                        toggleTabState === 1
                          ? "tabs-content active"
                          : "tabs-content"
                      }
                    >
                      <div className="forms-tab">
                        <MDBRow>
                          <MDBCol xl="12" lg="12" className="form-group-box">
                            {group.map((g, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {renderGroupField(g)}
                                </React.Fragment>
                              );
                            })}
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                    <div
                      className={
                        toggleTabState === 2
                          ? "tabs-content active"
                          : "tabs-content"
                      }
                    >
                      <div className="forms-tab">
                        <MDBRow>
                          <MDBCol xl="12" lg="12" className="form-group-box">
                            <div className="form-group-title">
                              <label>Status Information</label>
                            </div>
                            {renderStatusInfo()}
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                    <div
                      className={
                        toggleTabState === 3
                          ? "tabs-content active"
                          : "tabs-content"
                      }
                    >
                      <div className="forms-tab">
                        <MDBRow>
                          <MDBCol xl="12" lg="12" className="form-group-box">
                            <div className="form-group-title">
                              <label>Source Information</label>
                            </div>
                            {renderSourceInfo()}
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {createLeadLoading && (
                  <div className="loading-box">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-form-btn-group">
                <MDBRow>
                  <MDBCol className="text-right">
                    <span
                      className="drawer-btn cancel-btn"
                      onClick={handleCancelDrawer}
                    >
                      Cancel
                    </span>
                    {!createLeadLoading && (
                      <span
                        className="drawer-btn save-btn"
                        onClick={handleCreateLead}
                      >
                        Add Lead
                      </span>
                    )}
                    {createLeadLoading && (
                      <span
                        className="drawer-btn cancel-btn c-btn-disabled"
                        disabled={true}
                        onClick={handleCreateLead}
                      >
                        Adding Lead ...
                      </span>
                    )}
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddLeadDrawer;
