import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { projectActions } from '_actions';
import { utilitiesService as utility } from '_services';

import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { Button, ButtonGroup } from "blackbox-react";

import { priorityColor, priorityIcon, priorityText, colorRoundStatus } from '_pages/Leads/constants/BreadCrumbs';
import { ProjectViewRecentActivities } from '_pages/Projects/ProjectViewRecentActivities';

import avatar from '_assets/images/avatar-fem.png';

export const ProjectInfo = (props) => {
  const dispatch = useDispatch();
  const { projects, projectLoaded, activityLogLoaded, membersLoaded, statusLoaded } = useSelector(state => state.projects);
  const { match } = props;

  const [project, setProject] = useState({});
  const [projectMembers, setProjectMembers] = useState([]);
  const [statusColor] = useState(['teal', 'blue', 'yellow', 'orange', 'red', 'green', 'dark-gray', 'lime', 'cyan', 'indigo', 'brown', 'purple']);

  useEffect(() => {
    localStorage.setItem('isActiveModule', 'projects');
    dispatch(projectActions.getProject(match.params.id));
    dispatch(projectActions.getAllProjectMembers(match.params.id));
    dispatch(projectActions.getActivityLog(match.params.id));
    dispatch(projectActions.findProjectStatusCount(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (projects.project) {
      setProject(projects.project.data);
    }
    if (projects.members) {
      setProjectMembers(projects.members.data);
    }
  }, [projects]);

  const handleDisplayFormsOverview = (project, forms) => {
    let projectForms = forms.filter(obj => obj.is_default !== true);
    let hasData = projectForms.length > 0;

    return (
      <MDBCol lg='12' xl='12' md='12' className='project-info__overview forms-overview'>
        <div className="project-info__icon">
          <Link to={`/project/${project.id}/forms`}>
            <div className="project__link link-to--forms">
              <div className="icon">
                <MDBIcon far icon='clipboard' />
                <div className="stats">
                  <div className="label count">
                    <span>{project.forms.length - 1}</span>
                  </div>
                  <div className="label">
                    <p>
                      { (project.forms.length - 1) > 1 ? 'FORMS' :
                      (project.forms.length - 1) === 0 ? 'FORMS' : 'FORM'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <ul className='form-list list--unstyled py-4'>
          { hasData ?
            projectForms.slice(0, 10).map((value, index) => (
              <li key={index} className='display-block'>
                <div className="icon-overview icon-status icon-done">
                  <MDBIcon icon='check' className='' />
                </div>
                <div className="details">
                  <span className="title">
                    {value.name}
                  </span>
                </div>
              </li>
            )) : null }
        </ul>
      </MDBCol>
    );
  };

  const handleDisplayLeadsOverview = (project, status) => {
    let leadCount = status.reduce((acc, value) => acc + (value.count || 0), 0);
    const projectLeads = status.sort((a, b) => a.id > b.id ? 1 : -1);
    let hasData = projectLeads.length > 0;

    return (
      <MDBCol lg='12' xl='12' md='12' className='project-info__overview leads-overview'>
        <div className="project-info__icon">
          {project && project.id &&
            <Link to={`/project/${project.id}/leads`}>
              <div className="project__link link-to--leads">
                <div className="icon">
                  <MDBIcon far icon='paper-plane' />
                  <div className="stats">
                    <div className="label count">
                      <span>{leadCount}</span>
                    </div>
                    <div className='label'>
                      <p>{ leadCount > 1 ? 'LEADS' :
                      leadCount === 0 ? 'LEADS' : 'LEAD'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          }
        </div>
        <ul className='form-list list--unstyled py-4'>
          { hasData ?
            projectLeads.slice(0, 10).map((value, index) => (
              <li key={index} className='lead-status display-block'>
                <div className={`icon-overview icon-leads bg--${statusColor[index]}`}>
                  <span className={`fg--${statusColor[index]}`}>{value.count}</span>
                </div>
                <div className="details">
                  <span className="title">
                    {value.status}
                  </span>
                </div>
              </li>
            )) : null }
        </ul>
      </MDBCol>
    );
  };

  const handleDisplayMembersOverview = (project, members) => {
    let hasData = members.length > 0;

    return (
      <MDBCol lg='12' xl='12' md='12' className='project-info__overview agents-overview'>
        <div className="project-info__icon">
          <Link to={`/project/${project.id}/settings`}>
            <div className="project__link link-to--agents">
              <div className="icon">
                <MDBIcon fas icon='users' />
                <div className="stats">
                  <div className="label count">
                    <span>{members.length}</span>
                  </div>
                  <div className="label">
                    <p>
                      { members.length > 1 ? 'MEMBERS' :
                      members.length === 0 ? 'MEMBERS' : 'MEMBER'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <ul className='form-list list--unstyled py-4'>
          { hasData ?
            members.slice(0, 10).map((value, index) => (
              <li key={index} className='display-block'>
                <div className="icon-overview icon-agents">
                  <img src={avatar} alt=''/>
                </div>
                <div className="details">
                  <span className="title">
                    {utility.decrypt(value.account.first_name)} &nbsp;
                    {utility.decrypt(value.account.last_name)}
                  </span>
                </div>
              </li>
            )) : null }
        </ul>
      </MDBCol>
    );
  };

  const handleDisplayActivity = (project, activityLog) => {
    return <ProjectViewRecentActivities leadRecentDetail={[]} projectData={project} activityLog={activityLog} />;
  };

  const isOwner = (project) => {
    let email = localStorage.getItem('email');
    let owner = project.owner.account.email;
    return email === owner;
  };

  let status = {};
  let forms, members, activityLog = [];

  if (projects.project) {
    forms = projects.project.data.forms;
  }

  if (projects.status) {
    status = projects.status.data;
  }

  if (projects.members) {
    members = projects.members.data;
  }

  if (projects.activityLog) {
    activityLog = projects.activityLog.data;
  }

  return (
    <MDBContainer fluid className='p--0'>
      <div className='page-title-ctas p-l-md p-r-md'>
        {projectLoaded &&
          <MDBRow className='m--0 align-items-center'>
            <MDBCol className='p--0 m--0'>
              <p className='page-title font-family-kanit font-size-30 line-height-7 color-gray line-height-6'>{project.info.name}</p>
            </MDBCol>
            <MDBCol>
              {isOwner(project) &&
                <MDBRow className='float-right'>
                  <ButtonGroup>
                    <Link to={`/project/${project.id}/settings`}>
                      <Button
                        flat='true'
                        background='orange' foreground='white'
                        borderWidth={2} borderColor='orange' size={-1}
                        styles={{ 'width': '100%' }}>
                        <MDBIcon icon='cog' className='mr-2' />
                        <span className='color-white font-weight-bold'>Project Settings</span>
                      </Button>
                    </Link>
                    <Link to={`/project/${project.id}/settings`}>
                      <Button
                        flat='true'
                        background='orange' foreground='white'
                        borderWidth={2} borderColor='orange' size={-1}
                        styles={{ 'width': '100%' }}>
                        <MDBIcon icon='users' className='mr-2' />
                        <span className='color-white font-weight-bold'>Manage Members</span>
                      </Button>
                    </Link>
                  </ButtonGroup>
                </MDBRow>
              }
            </MDBCol>
          </MDBRow>
        }
      </div>
      <div className="page-filter">
        <MDBRow className='text-left project-setting-wrap'>
          {projectLoaded &&
            <>
              <MDBCol className='project-info__description'>
                <label className="mb-0 font-size-13 font-weight-600 mb-1">DESCRIPTION</label>
                <p className='m-0 font-weight-600'>{project.info.description}</p>
              </MDBCol>
              <MDBCol>
                <div className='border-radius-7 background-color-white'>
                  <div className='px-3 text-left'>
                    <label className='mb-0 font-size-13 font-weight-600 mb-1'>OWNER</label>
                    <div>
                      <span className='font-weight-600 font-size-14'>{utility.decrypt(project.owner.account.first_name)} {utility.decrypt(project.owner.account.last_name)}</span>
                    </div>
                  </div>
                </div>
              </MDBCol>
            </>
          }
        </MDBRow>
      </div>
      <MDBRow className='m--0'>
        {projectLoaded ?
          <MDBCol lg='12' xl='12' md='12' className='p--0 main-body-container container-fluid sub-body-container rectangle-background'>
            <MDBRow className='main-body-container background-color-white p--0 m--0'>
              <MDBCol lg='9' xl='9' md='8' className='project-info__overview p--md'>
                <MDBRow>
                  {statusLoaded && handleDisplayLeadsOverview(project, status)}
                  {membersLoaded && handleDisplayMembersOverview(project, members)}
                </MDBRow>
              </MDBCol>
              <MDBCol lg='3' xl='3' md='4' className='background-color-lead border-right'>
                {activityLogLoaded && handleDisplayActivity(project, activityLog)}
              </MDBCol>
            </MDBRow>
          </MDBCol> :
          <MDBCol lg='12' xl='12' md='12' className='p--0 main-body-container container-fluid sub-body-container rectangle-background'>
            <MDBContainer fluid className='main-body-container background-color-white width--full'>
              <MDBCol lg='12' className='sub-body-container height--full'>
                <div className='loader-container'>
                  <div className="loader dark">Loading...</div>
                </div>
              </MDBCol>
            </MDBContainer>
          </MDBCol>
        }
      </MDBRow>
    </MDBContainer>
  );
};