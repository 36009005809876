import React, { useState, useEffect, useRef } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { connect } from 'react-redux';
import { influencerActions } from '_actions';

const ManageNoteModal = ({ isOpen, mode, data, handleCloseManageNotesModal, influencerId, projectId, addInfluencerNote, updateInfluencerNote }) => {
    const [isTitleError, setIsTitleError] = useState(false);
    const [isContentError, setIsContentError] = useState(false);
    const [toggleTabState, setToggleTabState] = useState(1);

    const titleInput = useRef(null);
    const contentInput = useRef(null);

    useEffect(() => {
        if (mode === 'edit') {
            titleInput.current.value = data.title;
            contentInput.current.value = data.content;
        } else {
            titleInput.current.value = '';
            contentInput.current.value = '';
        }
    }, [mode, data]);

    const handleClose = () => {
        handleCloseManageNotesModal(mode);
        clearInputFields();
    };

    const clearInputFields = () => {
        titleInput.current.value = '';
        contentInput.current.value = '';
    };

    const setTitle = () => {
        setIsTitleError(false);
    };

    const setContent = () => {
        setIsContentError(false);
    };

    const isFieldValid = () => {
        let isValid = true;

        const title = titleInput.current.value;
        const content = contentInput.current.value;

        if (title === '') {
            setIsTitleError(true);
            isValid = false;
        }

        if (content === '') {
            setIsContentError(true);
            isValid = false;
        }

        return isValid;
    };

    const handleManageNote = () => {
        if (isFieldValid()) {
            if (mode !== 'add') {
                handleEditInfluencerNote();
            } else {
                handleAddInfluencerNote();
            }
        }
    };

    const handleAddInfluencerNote = () => {
        const title = titleInput.current.value;
        const content = contentInput.current.value;

        const note = {
            title,
            content,
        };

        addInfluencerNote(projectId, influencerId, note);
        handleClose();
    };

    const handleEditInfluencerNote = () => {
        const title = titleInput.current.value;
        const content = contentInput.current.value;

        const note = {
            title,
            content,
        };

        updateInfluencerNote(projectId, influencerId, data.id, note);
        handleClose();
    };

    return (
        <React.Fragment>
            <div className={`drawer-overlay ${isOpen ? 'active' : ''}`} onClick={handleClose}></div>
            <div className={`side-drawer-main ${isOpen ? 'active' : ''}`}>
                <div className="side-drawer-wrapper">
                    <div className="side-drawer-title">
                        <h3>{mode.toUpperCase()} NOTE</h3>
                        <span className="side-drawer-close" onClick={handleClose}>
                            <i className="icon-lms icon-close"></i>
                        </span>
                    </div>
                    <div className="side-drawer-tabs">
                        <div className="side-drawer-tabs-wrapper">
                            <div className="control-tabs-box">
                                <div
                                    className={toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'}
                                    onClick={() => setToggleTabState(1)}
                                >
                                    <p>Note Information</p>
                                </div>
                            </div>
                            <div className="content-tabs-box">
                                <div className={toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                                    <div className="forms-tab">
                                        <MDBRow>
                                            <MDBCol className="col-12 form-group-box">
                                                <div className="input-group-box full-box-">
                                                    <div className={isTitleError ? 'field-box error-field' : 'field-box'}>
                                                        <label htmlFor="title">Title</label>
                                                        <input
                                                            ref={titleInput}
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            defaultValue={mode === 'edit' ? data.title : ''}
                                                            onChange={setTitle}
                                                        />
                                                        {isTitleError && <span>This field is required</span>}
                                                    </div>
                                                    <div className={isContentError ? 'field-box error-field' : 'field-box'}>
                                                        <label htmlFor="content">Content</label>
                                                        <input
                                                            ref={contentInput}
                                                            type="text"
                                                            id="content"
                                                            name="content"
                                                            defaultValue={mode === 'edit' ? data.content : ''}
                                                            onChange={setContent}
                                                        />
                                                        {isContentError && <span>This field is required</span>}
                                                    </div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-form-btn-group">
                                <MDBRow>
                                    <MDBCol className="text-right">
                                        <span className="drawer-btn cancel-btn" onClick={handleClose}>
                                            Cancel
                                        </span>
                                        <span className="drawer-btn save-btn" onClick={handleManageNote}>
                                            {mode === 'add' ? 'Save Note' : 'Update Note'}
                                        </span>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapState = (state) => {
    const { influencers } = state;
    return { influencers };
};

const actionCreators = {
    addInfluencerNote: influencerActions.addInfluencerNote,
    updateInfluencerNote: influencerActions.updateInfluencerNote,
};

const connectedManageNoteModal = connect(mapState, actionCreators)(ManageNoteModal);
export { connectedManageNoteModal as ManageNoteModal };
