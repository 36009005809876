import React from "react";

/**
 * TopBarLoader component renders a skeleton loader for the top bar.
 * It displays a loading animation with three skeleton boxes, one of which is circular.
 *
 * @returns {JSX.Element} The JSX code for the top bar skeleton loader.
 */
const TopBarLoader = () => {
  return (
    <div className="skeleton-loader skeleton-topbar-loader">
        <div className="col-b">
            <div className="skeleton skeleton-box"></div>
            <div className="skeleton skeleton-box circle-skeleton"></div>
            <div className="skeleton skeleton-box"></div>
        </div>
    </div>
  );
};

export default TopBarLoader;
