import { httpManagerService as httpManager } from  '_services';

export const managerService = {
    getManagers,
    getManagerConfig,
    deleteManager,
    updateManagerConfig,
    updateManagerConfigsParam,
    addManagerConfigParam,
    updateManagerConfigParam,
    deleteManagerConfigParam,
    getProjectConfigParam,
    getProjectConfigOperator
}

/**
 * Retrieves a paginated list of managers for a project.
 * @param {number} projectId - The project ID.
 * @param {string|null} query - Optional search query.
 * @param {number} page - The page number (default: 1).
 * @param {number} size - The number of results per page (default: 1).
 * @returns {Promise} API response promise containing managers list.
 */
function getManagers(projectId, query = null, page = 1, size = 1) {
    let params = [];
    
    if (query) {
        params.push(`query=${query}`);
    }
    if (page > 0) {
        params.push(`page=${page}`);
    }
    // if (size > 0) {
    //     params.push(`size=${size}`);
    // }

    params.push(`size=300`);


    const queryString = params.length ? `?${params.join('&')}` : '';
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/managers${queryString}`);
}

/**
 * Retrieves manager configuration details.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @returns {Promise} API response promise containing manager configuration.
 */
function getManagerConfig(projectId, managerId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/managers/${managerId}`);
}

/**
 * Deletes a manager from a project.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @returns {Promise} API response promise.
 */
function deleteManager(projectId, managerId) {
    return httpManager.deleteHTTPOnce(`/api/v2/project/${projectId}/managers/${managerId}`);
}

/**
 * Updates manager configuration.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @param {Object} request - The request payload with updated configuration.
 * @returns {Promise} API response promise.
 */
function updateManagerConfig(projectId, managerId, request) {
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/managers/${managerId}`, request);
}

/**
 * Adds a new configuration parameter to a manager.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @param {Object} request - The configuration parameter details.
 * @returns {Promise} API response promise.
 */
function addManagerConfigParam(projectId, managerId, request) {
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/managers/${managerId}/config`, request);
}

/**
 * Updates a specific configuration parameter of a manager.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @param {number} configId - The configuration parameter ID.
 * @param {Object} request - The updated configuration details.
 * @returns {Promise} API response promise.
 */
function updateManagerConfigParam(projectId, managerId, configId, request) {
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/managers/${managerId}/config/${configId}`, request);
}

/**
 * Updates multiple configuration parameters of a manager.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @param {Object} request - The updated configuration parameters.
 * @returns {Promise} API response promise.
 */
function updateManagerConfigsParam(projectId, managerId, request) {
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/managers/${managerId}/configs`, request);
}

/**
 * Deletes a specific configuration parameter of a manager.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @param {number} configId - The configuration parameter ID.
 * @returns {Promise} API response promise.
 */
function deleteManagerConfigParam(projectId, managerId, configId) {
    return httpManager.deleteHTTPOnce(`/api/v2/project/${projectId}/managers/${managerId}/config/${configId}`);
}

/**
 * Retrieves project configuration parameters for a manager.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @returns {Promise} API response promise containing configuration parameters.
 */
function getProjectConfigParam(projectId, managerId) {
    return httpManager.getHttpOnce(`/api/v2/project/${projectId}/managers/${managerId}/field/parameters`);
}

/**
 * Retrieves project configuration operators for a manager.
 * @param {number} projectId - The project ID.
 * @param {number} managerId - The manager ID.
 * @returns {Promise} API response promise containing configuration operators.
 */
function getProjectConfigOperator(projectId, managerId) {
    return httpManager.getHttpOnce(`/api/v2/project/${projectId}/managers/${managerId}/field/operators`);
}