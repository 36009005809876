import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { leadActions } from "_actions";

const TablePagination = ({
  number,
  first,
  last,
  size,
  totalElements,
  leadsLoaded,
  onHandlePageChanged,
  onHandleSizeChanged,
}) => {
  const projectId = localStorage.getItem("projectId"); // Assume `projectId` is available

  const gotoInputRef = useRef();

  const [currentPage, setCurrentPage] = useState(
    localStorage.getItem(`lead_view_page_${projectId}`) || 1
  );
  const [currentSize, setCurrentSize] = useState(
    localStorage.getItem(`lead_view_size_${projectId}`) || 15
  );
  const [currentQuery, setCurrentQuery] = useState(
    localStorage.getItem(`lead_view_query_${projectId}`) || ""
  );

  useEffect(() => {
    // Saving page, size, and query in sessionStorage whenever they change
    localStorage.setItem(`lead_view_page_${projectId}`, currentPage);
    localStorage.setItem(`lead_view_size_${projectId}`, currentSize);
    localStorage.setItem(`lead_view_query_${projectId}`, currentQuery);
  }, [currentPage, currentSize, currentQuery, projectId]);

  const handlePageChanged = (value, className) => {
    if (className === "active") {
      setCurrentPage(value);
      onHandlePageChanged(value);
    }
  };

  const handleSizeChanged = (e) => {
    const newSize = parseInt(e.options[e.selectedIndex].value);
    const min = (number + 1) * newSize - (newSize - 1);

    if (min <= totalElements) {
      setCurrentSize(newSize);
      onHandleSizeChanged(newSize);
    }

    if (min > totalElements) {
      onHandleSizeChanged(size);
    }
  };

  const handleKeyDown = (event, value, className) => {
    if (event.key === "Enter") {
      event.preventDefault();

      let min = value * size - (size - 1);
      if (min <= totalElements) {
        setCurrentPage(value);
        onHandlePageChanged(value);
      }

      if (min > totalElements) {
        if (currentPage * size > totalElements) {
          onHandlePageChanged(1);
        } else {
          onHandlePageChanged(currentPage);
        }
      }
    }
  };

  let page = number + 1;
  let min = page * size - (size - 1);
  let max = page * size;
  let count = totalElements;
  let prevClass = first ? "" : "active";
  let nextClass = last ? "" : "active";
  let nextPage = page + 1;
  let previousPage = page - 1;
  let countText = "Loading Leads ...";

  if (page) {
    let limit = max;
    if (count < max) {
      limit = count;
    }
    countText = `${min}-${limit} of ${count}`;
  }

  return (
    <div className="table-pagination-box">
      <div className="pagination-box">
        <div className="influencer-counts">
          <span>Show rows: </span>
          <select
            value={currentSize}
            onChange={(e) => handleSizeChanged(e.target)}
          >
            {leadsLoaded && (
              <>
                <option value={15}>15</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </>
            )}
          </select>

          <div className="go-to-page">
            <span>Go to</span>
            <input
              type="number"
              defaultValue={page}
              key={page}
              ref={gotoInputRef}
              onKeyDown={(e) => handleKeyDown(e, e.target.value, "active")}
            />
          </div>

          <span>{countText}</span>
        </div>
        <ul>
          <a
            onClick={() => handlePageChanged(previousPage, prevClass)}
            className={prevClass}
          >
            <i className="icon-lms icon-arrow-left"></i>
          </a>
          <a
            onClick={() => handlePageChanged(nextPage, nextClass)}
            className={nextClass}
          >
            <i className="icon-lms icon-arrow-right"></i>
          </a>
        </ul>
      </div>
    </div>
  );
};

function mapState(state) {
  const { leadsLoaded } = state.leads;
  if (leadsLoaded) {
    if (state.leads.leads) {
      const { number, first, last, size, totalElements, totalPages } = state.leads.leads.data;
      return {
        number,
        first,
        last,
        size,
        totalElements,
        totalPages,
        leadsLoaded,
      };
    }
  }

  return { leadsLoaded };
}

const actionCreators = {
  getLeads: leadActions.getLeadsPaginated,
};

const TablePaginationComponent = connect(mapState, actionCreators)(TablePagination);
export {TablePaginationComponent as TablePagination};