import React, { useState, useEffect } from 'react';
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CSVReader from 'react-csv-reader';
import { Button, ButtonGroup, Icon } from 'blackbox-react';
import Slider from '_pages/Leads/components/Slider';
import { formActions, leadActions, projectActions } from '_actions';

const LeadImport = ({ match, getLeads }) => {
  const projectId = match.params.id;
  const [projectData, setProjectData] = useState(JSON.parse(localStorage.getItem('project')));
  const [importLead, setImportLead] = useState([]);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    getLeads(projectId);
  }, [getLeads, projectId]);

  const handleUploadedFile = (data, name) => {
    setImportLead(data);
    setFileName(name);
    localStorage.setItem('fileName', name);
    localStorage.setItem('importData', JSON.stringify(data));
  };

  const hasData = importLead.length > 0;
  const iconDone = hasData ? 'check' : 'upload';
  const textLabelDone = hasData ? (
    <p>
      <b>File Added!</b> <br /> Your CSV file has been added, you can start <b>mapping your fields</b> by clicking Next.
    </p>
  ) : (
    <p>
      <b>Start Importing</b> <br /> Select a CSV file containing your <br /> list of Leads with the proper headers <br />for easy mapping later on.
    </p>
  );

  return (
    <MDBContainer fluid className='p--md lead-import'>
      <MDBRow className='m--0'>
        <MDBCol lg='3' xl='2' md='4' className='p-t--0 p-l--0 p-r--sm p-b--sm'>
          <MDBRow className='p--0 m--0'>
            <MDBCol>
              <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
              <p className='font-family-crimson font-size-30 color-orange'>Import Leads</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className='m-t--lg p--0'>
            <MDBCol>
              <Slider stageNum='1' activeFormView='uploadCsv' />
            </MDBCol>
          </MDBRow>
          <MDBRow className='p-t--md p-l--md p-r--md p-b--0 back-button'>
            <MDBCol>
              <ButtonGroup>
                <Link to={`/project/${projectId}/leads`}>
                  <Button flat background='transparent' foreground='black' borderWidth={2} borderColor='dark' size={-1}>
                    <Icon color='black' name='chevron' rotate={180} />
                    <span className='color-black font-weight-bold'>View All Leads</span>
                  </Button>
                </Link>
              </ButtonGroup>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol lg='9' xl='10' md='8' className='px-0'>
          <MDBRow className='d-flex'>
            <MDBCol md='3' className='my-auto'>
              <p className='font-family-crimson font-size-22 my-auto pr-3'>Upload CSV</p>
            </MDBCol>
            <MDBCol md='9' className='text-right'>
              <ButtonGroup>
                <Link to={`/project/${projectId}/leads/import/history`}>
                  <Button flat background='transparent' foreground='dark' borderWidth={2} borderColor='dark' size={-2}>
                    <MDBIcon icon='columns' className='mr-2' />
                    <span className='color-dark font-weight-bold'>Import History</span>
                  </Button>
                </Link>
                {hasData ? (
                  <Link to={`/project/${projectId}/leads/import/map`}>
                    <Button flat background='orange' foreground='white' borderWidth={2} borderColor='orange' size={-2}>
                      <span className='color-white font-weight-bold'>Next</span>
                    </Button>
                  </Link>
                ) : (
                  <Button flat disabled background='disabled' borderWidth={2} size={-2}>
                    <span className='color-gray font-weight-bold'>Next</span>
                  </Button>
                )}
              </ButtonGroup>
            </MDBCol>
          </MDBRow>
          <MDBCol className='sub-body-container background-color-white m-t--md'>
            <MDBRow className='sub-body-container height--full'>
              <MDBCol lg='7' className='p--md'>
                <MDBRow className='p-l--md p-r--md p-b--md'>
                  <MDBCol className='bacground-color-import border-radius-7 dotted-import'>
                    <MDBRow className='d-flex padding-70-0'>
                      <div className='m-auto'>
                        <MDBIcon icon='upload' className='font-size-45 font-color-gray opacity-40' />
                        <CSVReader cssclassName='csv-reader-input' label='Select a CSV file' onFileLoaded={handleUploadedFile} />
                      </div>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol lg='5' className='background-color-lead right-rectangle px-0'>
                <div className='generic-message text-center'>
                  {textLabelDone}
                  <MDBIcon icon={iconDone} className='icon--lg' />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects,
  project: state.project,
  forms: state.forms,
});

const actionCreators = {
  getLeads: leadActions.getLeads,
};

const connectedLeadImport = connect(mapStateToProps, actionCreators)(LeadImport);
export { connectedLeadImport as LeadImport };
