import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MDBCol, MDBRow } from "mdbreact";
import FormGroupTitle from "_pages/Propelrr/components/Form/FormGroupTitle";
import InputForm from "_components/InputForm";
import { formActions, leadActions, projectActions } from "_actions";
import { utilitiesService as utility, utilitiesService } from "_services";
import { leadData } from "_pages/Leads/constants/LeadData";
import DropDownLists from "_pages/Influencer/constants/DropDownLists";

const BulkUploadDrawer = ({projectId, pageSize, isBulkUploadDrawer, onHandleBulkUploadDrawer }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const customselectRef = useRef();
  const fileRef = useRef();

  const [toggleTabState, setToggleTabState] = useState(1);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isCustomselectOpen, setIsCustomselectOpen] = useState(false);
  const [customselectSelectedValue, setCustomselectSelectedValue] =
      useState("");
  const [isFileError, setIsFileError] = useState(false);
  const [isPositionError, setIsPositionError] = useState(false);
  const [file, setFile] = useState();
  const [fileErrorMsg, setFileErrorMsg] = useState("");
  const [positionErrorMsg, setPositionErrorMsg] = useState("");
  const [required, setRequired] = useState(true);

  const positions = DropDownLists.positions;

  useEffect(() => {
    setCustomselectSelectedValue("");

    //    Clear the fieldData and fieldErrors when the component is opened
    if (isBulkUploadDrawer) {
      setIsFileError(false);
      setIsPositionError(false);
      setFile();
      setCustomselectSelectedValue('');
      fileRef.current.value = '';
    }
  }, [isBulkUploadDrawer]);

  let activeClass = isBulkUploadDrawer ? "active" : "";

  const createLeadLoading = useSelector((state) => {
    const { leads } = state;
    const { createLeadLoading } = leads;

    if (createLeadLoading) {
      return createLeadLoading;
    }
    return false;
  });

  function handleFileChange(event) {
    setFile(event.target.files[0]);
    setIsFileError(false);
  }

  useEffect(() => {
    const customselectOutsideClick = (e) => {
      if (
          isCustomselectOpen &&
          customselectRef.current &&
          !customselectRef.current.contains(e.target)
      ) {
        setIsCustomselectOpen(false);
      }
    };
    document.addEventListener("click", customselectOutsideClick);
    return () => {
      document.removeEventListener("click", customselectOutsideClick);
    };
  }, [isCustomselectOpen]);

  function handleOpenCustomselect() {
    setIsCustomselectOpen(!isCustomselectOpen);
  }

  const handleCustomselectSelectValue = (value) => {
    setCustomselectSelectedValue(value);
    setIsCustomselectOpen(false);
    setIsPositionError(false);
    setPositionErrorMsg('');
  };

  const handleCloseDrawer = () => {
    onHandleBulkUploadDrawer();
    setIsCustomselectOpen(false);
  };

  const handleCancelDrawer = () => {
    console.log("cancel data");
    setFieldErrors({});
    onHandleBulkUploadDrawer();
    setIsCustomselectOpen(false);
  };

  const handleBulkUpload = () => {
    setIsCustomselectOpen(false);
    console.log("file: ", file);
    console.log("position: ", customselectSelectedValue);

    let hasError = false;

    if (file == undefined) {
      setIsFileError(true);
      setFileErrorMsg("Please select a file");
      hasError = true;
    }


    if (file !== undefined && file.name !== undefined) {
      const ext = file.name.split('.').pop(); // Get the file extension

      // Check if the file extension is neither "csv" nor "xlsx"
      if (ext !== "csv" && ext !== "xlsx") {
        console.log('ext: ', ext);
        setIsFileError(true);
        setFileErrorMsg("Please select a valid file");
        hasError = true;
      }
    }

    if(projectId == 6 && !customselectSelectedValue) {
      setIsPositionError(true);
      setPositionErrorMsg('No Selected Position');
      hasError = true;
    }

    if(!hasError) {
      console.log('call api');
      const projectId = params.id;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("position", customselectSelectedValue);

      onHandleBulkUploadDrawer();
      if(projectId == 6)
        dispatch(leadActions.uploadKalibrrLeads(projectId, formData, pageSize));

      if(projectId == 5)
        dispatch(leadActions.uploadCsvLeads(projectId, formData, pageSize));
    }
  };

  return (
      <React.Fragment>
        <div
            className={`drawer-overlay ${activeClass}`}
            onClick={onHandleBulkUploadDrawer}
        ></div>
        <div className={`side-drawer-main ${activeClass}`}>
          <div className="side-drawer-wrapper">
            <div className="side-drawer-title">
              <h3>Bulk Upload</h3>
              <span className="side-drawer-close" onClick={handleCloseDrawer}>
              <i className="icon-lms icon-close"></i>
            </span>
            </div>
            <div className="side-drawer-tabs">
              <div className="side-drawer-tabs-wrapper">
                <div className="content-tabs-box">
                  {!createLeadLoading && (
                      <React.Fragment>
                        <div
                            className={
                              toggleTabState === 1
                                  ? "tabs-content active"
                                  : "tabs-content"
                            }
                        >
                          <div className="forms-tab">
                            <MDBRow>
                              <MDBCol xl="12" lg="12" className="form-group-box">
                                <React.Fragment>
                                  <div className="input-group-box full-box-">
                                    <div className="field-box">
                                      <label>
                                        {"FILE"}
                                        {required && (
                                            <small className="color-red">*</small>
                                        )}
                                      </label>
                                      <div className="custom-input">
                                        <input
                                            ref={fileRef}
                                            type="file"
                                            id="link"
                                            name="link"
                                            onChange={handleFileChange}
                                        />
                                      </div>

                                      {isFileError && (
                                          <p className="error-field">
                                            {fileErrorMsg}
                                          </p>
                                      )}
                                    </div>
                                  </div>

                                  {projectId == 6 && (<div className="input-group-box full-box-">
                                    <div className="field-box">
                                      <label>
                                        {"POSITION"}
                                        {required && (
                                            <small className="color-red">*</small>
                                        )}
                                      </label>
                                      <div
                                          className="custom-dropdown-multiselect"
                                          ref={customselectRef}
                                      >
                                        <div
                                            className="multiselect-btn"
                                            onClick={() => handleOpenCustomselect()}
                                        >
                                      <span className="multiselect-text">
                                        {customselectSelectedValue}
                                      </span>
                                          <i
                                              className={
                                                isCustomselectOpen ? "active" : ""
                                              }
                                          ></i>
                                        </div>
                                        <div
                                            className={
                                              isCustomselectOpen
                                                  ? "multiselect-dropdown active"
                                                  : "multiselect-dropdown"
                                            }
                                        >
                                          <div className="multiselect-list">
                                            {positions.map((option) => (
                                                <div
                                                    className="checkbox-group"
                                                    key={option}
                                                    onClick={() =>
                                                        handleCustomselectSelectValue(
                                                            option
                                                        )
                                                    }
                                                >
                                                  <span>{option}</span>
                                                </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      {isPositionError && (
                                          <p className="error-field">
                                            {positionErrorMsg}
                                          </p>
                                      )}
                                    </div>
                                  </div>)}
                                </React.Fragment>
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </div>
                      </React.Fragment>
                  )}
                  {createLeadLoading && (
                      <div className="loading-box">
                        <div className="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                  )}
                </div>
                <div className="tab-form-btn-group">
                  <MDBRow>
                    <MDBCol className="text-right">
                    <span
                        className="drawer-btn cancel-btn"
                        onClick={handleCancelDrawer}
                    >
                      Cancel
                    </span>
                      {!createLeadLoading && (
                          <span
                              className="drawer-btn save-btn"
                              onClick={handleBulkUpload}
                          >
                        Upload
                      </span>
                      )}
                      {createLeadLoading && (
                          <span
                              className="drawer-btn cancel-btn c-btn-disabled"
                              disabled={true}
                              onClick={handleBulkUpload}
                          >
                        Adding Lead ...
                      </span>
                      )}
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
  );
};

export default BulkUploadDrawer;
