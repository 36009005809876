import React, { useState, useEffect } from 'react';

/**
 * InputForm component renders an input field with validation and error display.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.value] - The initial value of the input field.
 * @param {boolean} [props.disabled] - Whether the input field is disabled.
 * @param {string} [props.id] - The id of the input field.
 * @param {boolean} [props.required] - Whether the input field is required.
 * @param {string} [props.name] - The name of the input field.
 * @param {function} props.onChange - The function to call when the input value changes.
 * @param {string} [props.type] - The type of the input field.
 * @param {string} [props.inputClass] - The CSS class for the input field.
 * @param {string} [props.hint] - The placeholder text for the input field.
 * @param {boolean} [props.readonly] - Whether the input field is read-only.
 * @param {function} [props.onKeyPress] - The function to call when a key is pressed in the input field.
 * @param {string} [props.label] - The label of the input field used for validation.
 *
 * @returns {JSX.Element} The rendered input field component.
 */
const InputForm = (props) => {
  const [value, setValue] = useState(props.value || '');
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value, value]);
  

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    props.onChange(e);

    // Optional validation can be called here as needed
    validateField(newValue);
  };

  const validateField = (value) => {
    const { label } = props;

    // Reset error when the value is valid
    setShowError(false);
    setError('');

    if ((label === 'Email' || label === 'Email Address') && (!value || value.trim() === '')) {
      setError('Please enter a valid email');
      setShowError(true);
    }

    if (label === 'Full Name' && (!value || value.trim() === '')) {
      setError('Please enter full name');
      setShowError(true);
    }
  };

  return (
    <div>
      <input
        disabled={props.disabled}
        id={props.id}
        required={props.required}
        name={props.name}
        onChange={handleChange}
        type={props.type}
        className={`${props.inputClass} c-input`}
        placeholder={props.hint}
        value={value}
        readOnly={props.readonly}
        onKeyDown={props.onKeyPress}
      />
      
      {showError && error && (
        <div className="form-validation__container display-block">
          <div className="form-errors">{error}</div>
        </div>
      )}
    </div>
  );
};

export default InputForm;
