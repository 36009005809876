import React from "react";

export const renderAgentInfo = (state) => {
    return (
      <div>
        {state.selectedAgentValue !== undefined && (
          <div
            className="agentInformation"
            style={{
              display:
                Object.keys(state.selectedAgentValue).length > 0
                  ? "block"
                  : "none",
            }}
          >
            <div
              className="input-wrap p--sm"
              style={{ paddingTop: "0px !important" }}
            >
              <label className="m--0">Agent Id</label>
              <div>
                <span className="font-size-14">
                  {state.selectedAgentValue.agent_id}
                </span>
              </div>
            </div>
            <div className="input-wrap p--sm">
              <label className="m--0">Agent Name</label>
              <div>
                <span className="font-size-14">
                  {state.selectedAgentValue.first_name}{" "}
                  {state.selectedAgentValue.last_name}
                </span>
              </div>
            </div>
            <div className="input-wrap p--sm">
              <label className="m--0">Agent Contact</label>
              <div>
                <span className="font-size-14">
                  {state.selectedAgentValue.contact}
                </span>
              </div>
            </div>
            <div className="input-wrap p--sm">
              <label className="m--0">Agent Email</label>
              <div>
                <span className="font-size-14">
                  {state.selectedAgentValue.email}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };