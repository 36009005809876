import { userConstants } from '_constants';

/**
 * Reducer function to handle registration state changes.
 *
 * @param {Object} state - The current state of the registration.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action being dispatched.
 * @returns {Object} The new state of the registration.
 */
export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return {};
    case userConstants.REGISTER_FAILURE:
      return {};
    default:
      return state
  }
}
