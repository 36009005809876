import {influencerConstants} from "_constants";

/**
 * Reducer function to handle influencer-related actions and update the state accordingly.
 *
 * @param {Object} state - The current state of the influencers.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action being dispatched.
 * @param {Object} [action.summary] - The summary data for SUMMARY_SUCCESS action.
 * @param {Object} [action.influencers] - The list of influencers for GET_ALL_SUCCESS action.
 * @param {Object} [action.influencer] - The influencer data for CREATE_SUCCESS, GET_SUCCESS, UPDATE_SUCCESS, and UPDATE_STATUS_SUCCESS actions.
 * @param {Object} [action.platforms] - The platforms data for ADD_PLATFORM_SUCCESS, GET_ALL_PLATFORM_SUCCESS, UPDATE_PLATFORM_SUCCESS, and DELETE_PLATFORM_SUCCESS actions.
 * @param {Object} [action.clients] - The clients data for UPDATE_CLIENT_SUCCESS and GET_ALL_CLIENT_SUCCESS actions.
 * @param {Object} [action.categories] - The categories data for UPDATE_CATEGORIES_SUCCESS and GET_ALL_CATEGORIES_SUCCESS actions.
 * @param {Object} [action.documents] - The documents data for ADD_DOCUMENT_SUCCESS, GET_ALL_DOCUMENTS_SUCCESS, UPDATE_DOCUMENT_SUCCESS, and DELETE_DOCUMENT_SUCCESS actions.
 * @param {Object} [action.rates] - The rates data for ADD_RATE_SUCCESS, GET_ALL_RATE_SUCCESS, UPDATE_RATE_SUCCESS, and DELETE_RATE_SUCCESS actions.
 * @param {Object} [action.notes] - The notes data for ADD_NOTE_SUCCESS, GET_ALL_NOTES_SUCCESS, UPDATE_NOTE_SUCCESS, and DELETE_NOTE_SUCCESS actions.
 * @param {Object} [action.activities] - The activities data for GET_ALL_ACTIVITIES_SUCCESS action.
 * @param {Object} [action.clientList] - The client list data for GET_CLIENT_LIST_SUCCESS action.
 * @param {Object} [action.platformTypes] - The platform types data for GET_PLATFORM_TYPE_SUCCESS action.
 * @param {Object} [action.categoryList] - The category list data for CATEGORY_LIST_SUCCESS action.
 * @param {Object} [action.error] - The error data for FAILURE actions.
 * @returns {Object} The updated state based on the action type.
 */
export function influencers(state = {}, action) {

    switch (action.type) {

        case influencerConstants.SUMMARY_REQUEST:
            return {
                ...state,
                isSummaryLoading: true,
                isSummaryLoaded: false
            }
        case influencerConstants.SUMMARY_SUCCESS:
            return {
                ...state,
                isSummaryLoading: false,
                isSummaryLoaded: true,
                summary: action.summary.data,
            }
        case influencerConstants.SUMMARY_FAILURE:
            return {
                ...state,
                error: action.error,
                isSummaryLoading: false,
                isSummaryLoaded: true,
            }

        case influencerConstants.GET_ALL_REQUEST:
            return {
                ...state,
                influencerLoaded:false,
                influencer: undefined,
                influencerListLoading: true,
                influencerListLoaded: false
            }
        case influencerConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                influencerListLoading: false,
                influencerListLoaded: true,
                list: action.influencers,
            }
        case influencerConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                influencerListLoading: false,
                influencerListLoaded: true,

            }

        case influencerConstants.CREATE_REQUEST:
            return {
                ...state,
                createInfluencerLoading: true,
                createInfluencerLoaded: false,
            }
        case influencerConstants.CREATE_SUCCESS:
            return {
                createInfluencerLoading: false,
                createInfluencerLoaded: true,
                influencer: action.influencer,
                ...state,
            }
        case influencerConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error,
                createInfluencerLoading: false,
                createInfluencerLoaded: true,
            }

        case influencerConstants.GET_REQUEST:
            return {
                ...state,
                influencerLoading: true,
                influencerLoaded: false
            }
        case influencerConstants.GET_SUCCESS:
            return {
                ...state,
                influencer: action.influencer,
                influencerLoading: false,
                influencerLoaded: true
            }
        case influencerConstants.GET_FAILURE:
            return {
                ...state,
                influencerLoading: false,
                influencerLoaded: true,
                error: action.error,
            }

        case influencerConstants.UPDATE_REQUEST:
            return {
                ...state,
                updateInfluencerLoading: true,
                updateInfluencerLoaded: false
            }
        case influencerConstants.UPDATE_SUCCESS:
            return {
                ...state,
                influencer: action.influencer,
                updateInfluencerLoading: false,
                updateInfluencerLoaded: true
            }
        case influencerConstants.UPDATE_FAILURE:
            return {
                ...state,
                updateInfluencerLoading: false,
                updateInfluencerLoaded: true,
                error: action.error
            }

        case influencerConstants.UPDATE_STATUS_REQUEST:
            return {
                ...state,
                updateInfluencerStatusLoading: true,
                updateInfluencerStatusLoaded: false
            }
        case influencerConstants.UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                updateInfluencerStatusLoading: false,
                updateInfluencerStatusLoaded: true,
                influencer: action.influencer
            }
        case influencerConstants.UPDATE_STATUS_FAILURE:
            return {
                ...state,
                updateInfluencerStatusLoading: false,
                updateInfluencerStatusLoaded: true,
                error: action.error
            }

        case influencerConstants.DELETE_REQUEST:
            return {
                ...state,
                deleteInfluencerLoading: true,
                deleteInfluencerLoaded: false,

            }
        case influencerConstants.DELETE_SUCCESS:
            return {
                ...state,
                deleteInfluencerLoading: false,
                deleteInfluencerLoaded: true,
                influencers: action.influencers
            }
        case influencerConstants.DELETE_FAILURE:
            return {
                ...state,
                deleteInfluencerLoading: false,
                deleteInfluencerLoaded: true,
                error: action.error
            }

        case influencerConstants.ADD_PLATFORM_REQUEST:
            return {
                ...state,
                addInfluencerPlatformLoading: true,
                addInfluencerPlatformLoaded: false,
            }
        case influencerConstants.ADD_PLATFORM_SUCCESS:
            return {
                ...state,
                addInfluencerPlatformLoading: false,
                addInfluencerPlatformLoaded: true,
                platforms: action.platforms.data
            }
        case influencerConstants.ADD_PLATFORM_FAILURE:
            return {
                ...state,
                addInfluencerPlatformLoading: false,
                addInfluencerPlatformLoaded: true,
                error: action.error
            }

        case influencerConstants.GET_ALL_PLATFORM_REQUEST:

            return {
                ...state,
                influencerPlatformLoading: true,
                influencerPlatformLoaded: false,

            }
        case influencerConstants.GET_ALL_PLATFORM_SUCCESS:
            return {
                ...state,
                influencerPlatformLoading: false,
                influencerPlatformLoaded: true,
                platforms: action.platforms.data
            }
        case influencerConstants.GET_ALL_PLATFORM_FAILURE:
            return {
                ...state,
                influencerPlatformLoading: false,
                influencerPlatformLoaded: true,
            }

        case influencerConstants.UPDATE_PLATFORM_REQUEST:
            return {
                ...state,
                updateInfluencerPlatformLoading: true,
                updateInfluencerPlatformLoaded: false,
            }
        case influencerConstants.UPDATE_PLATFORM_SUCCESS:
            return {
                ...state,
                updateInfluencerPlatformLoading: false,
                updateInfluencerPlatformLoaded: true,
                platforms: action.platforms.data
            }
        case influencerConstants.UPDATE_PLATFORM_FAILURE:
            return {
                ...state,
                updateInfluencerPlatformLoading: false,
                updateInfluencerPlatformLoaded: true,
                error: action.error
            }

        case influencerConstants.DELETE_PLATFORM_REQUEST:
            return {
                ...state,
                deleteInfluencerPlatformLoading: true,
                deleteInfluencerPlatformLoaded: false,
            }
        case influencerConstants.DELETE_PLATFORM_SUCCESS:
            return {
                ...state,
                deleteInfluencerPlatformLoading: false,
                deleteInfluencerPlatformLoaded: true,
                platforms: action.platforms.data
            }
        case influencerConstants.DELETE_PLATFORM_FAILURE:
            return {
                ...state,
                deleteInfluencerPlatformLoading: false,
                deleteInfluencerPlatformLoaded: true,
                error: action.error
            }

        case influencerConstants.UPDATE_CLIENT_REQUEST:
            return {
                ...state,
                updateInfluencerClientLoading: true,
                updateInfluencerClientLoaded: false,
            }
        case influencerConstants.UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                updateInfluencerClientLoading: false,
                updateInfluencerClientLoaded: true,
                clients: action.clients.data
            }
        case influencerConstants.UPDATE_CLIENT_FAILURE:
            return {
                ...state,
                updateInfluencerClientLoading: false,
                updateInfluencerClientLoaded: true,
                error: action.error
            }


        case influencerConstants.GET_ALL_CLIENT_REQUEST:
            return {
                ...state,
                getInfluencerClientLoading: true,
                getInfluencerClientLoaded: false,
            }
        case influencerConstants.GET_ALL_CLIENT_SUCCESS:
            return {
                ...state,
                getInfluencerClientLoading: false,
                getInfluencerClientLoaded: true,
                clients: action.clients.data
            }
        case influencerConstants.GET_ALL_CLIENT_FAILURE:
            return {
                ...state,
                getInfluencerClientLoading: false,
                getInfluencerClientLoaded: true,
                error: action.error
            }

        case influencerConstants.UPDATE_CATEGORIES_REQUEST:
            return {
                ...state,
                updateInfluencerCategoriesLoading: true,
                updateInfluencerCategoriesLoaded: false,
            }
        case influencerConstants.UPDATE_CATEGORIES_SUCCESS:
            return {
                ...state,
                updateInfluencerCategoriesLoading: false,
                updateInfluencerCategoriesLoaded: true,
                categories: action.categories
            }
        case influencerConstants.UPDATE_CATEGORIES_FAILURE:
            return {
                ...state,
                updateInfluencerCategoriesLoading: false,
                updateInfluencerCategoriesLoaded: true,
                error: action.error

            }

        case influencerConstants.GET_ALL_CATEGORIES_REQUEST:
            return {
                ...state,
                getInfluencerCategoriesLoading: true,
                getInfluencerCategoriesLoaded: false,

            }
        case influencerConstants.GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                getInfluencerCategoriesLoading: false,
                getInfluencerCategoriesLoaded: true,
                categories: action.categories
            }
        case influencerConstants.GET_ALL_CATEGORIES_FAILURE:
            return {
                ...state,
                getInfluencerCategoriesLoading: false,
                getInfluencerCategoriesLoaded: true,
                error: action.error
            }

        case influencerConstants.ADD_DOCUMENT_REQUEST:
            return {
                ...state,
                addInfluencerDocumentLoading: true,
                addInfluencerDocumentLoaded: false,
            }
        case influencerConstants.ADD_DOCUMENT_SUCCESS:
            return {
                ...state,
                addInfluencerDocumentLoading: false,
                addInfluencerDocumentLoaded: true,
                documents: action.documents.data,
            }
        case influencerConstants.ADD_DOCUMENT_FAILURE:
            return {
                ...state,
                addInfluencerDocumentLoading: false,
                addInfluencerDocumentLoaded: true,
                error: action.error
            }

        case influencerConstants.GET_ALL_DOCUMENTS_REQUEST:
            return {
                ...state,
                getInfluencerDocumentLoading: true,
                getInfluencerDocumentLoaded: false,
            }
        case influencerConstants.GET_ALL_DOCUMENTS_SUCCESS:
            return {
                ...state,
                getInfluencerDocumentLoading: false,
                getInfluencerDocumentLoaded: true,
                documents: action.documents.data
            }
        case influencerConstants.GET_ALL_DOCUMENTS_FAILURE:
            return {
                ...state,
                getInfluencerDocumentLoading: false,
                getInfluencerDocumentLoaded: true,
                error: action.error
            }

        case influencerConstants.UPDATE_DOCUMENT_REQUEST:
            return {
                ...state,
                updateInfluencerDocumentLoading: true,
                updateInfluencerDocumentLoaded: false,
            }
        case influencerConstants.UPDATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                updateInfluencerDocumentLoading: false,
                updateInfluencerDocumentLoaded: true,
                documents: action.documents.data
            }
        case influencerConstants.UPDATE_DOCUMENT_FAILURE:
            return {
                ...state,
                updateInfluencerDocumentLoading: false,
                updateInfluencerDocumentLoaded: true,
                error: action.error
            }

        case influencerConstants.DELETE_DOCUMENT_REQUEST:
            return {
                ...state,
                deleteInfluencerDocumentLoading: true,
                deleteInfluencerDocumentLoaded: false,
            }
        case influencerConstants.DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                deleteInfluencerDocumentLoading: false,
                deleteInfluencerDocumentLoaded: true,
                documents: action.documents.data
            }
        case influencerConstants.DELETE_DOCUMENT_FAILURE:
            return {
                ...state,
                deleteInfluencerDocumentLoading: false,
                deleteInfluencerDocumentLoaded: true,
                error: action.error
            }

        case influencerConstants.ADD_RATE_REQUEST:
            return {
                ...state,
                addInfluencerRateLoading: true,
                addInfluencerRateLoaded: false,
            }
        case influencerConstants.ADD_RATE_SUCCESS:
            return {
                ...state,
                addInfluencerRateLoading: false,
                addInfluencerRateLoaded: true,
                rates: action.rates.data
            }
        case influencerConstants.ADD_RATE_FAILURE:
            return {
                ...state,
                addInfluencerRateLoading: false,
                addInfluencerRateLoaded: true,
                error: action.error
            }

        case influencerConstants.GET_ALL_RATE_REQUEST:
            return {
                ...state,
                getInfluencerRateLoading: true,
                getInfluencerRateLoaded: false,
            }
        case influencerConstants.GET_ALL_RATE_SUCCESS:
            return {
                ...state,
                getInfluencerRateLoading: false,
                getInfluencerRateLoaded: true,
                rates: action.rates.data
            }
        case influencerConstants.GET_ALL_RATE_FAILURE:
            return {
                ...state,
                getInfluencerRateLoading: false,
                getInfluencerRateLoaded: true,
                error: action.error
            }

        case influencerConstants.UPDATE_RATE_REQUEST:
            return {
                ...state,
                updateInfluencerRateLoading: true,
                updateInfluencerRateLoaded: false,
            }
        case influencerConstants.UPDATE_RATE_SUCCESS:
            return {
                ...state,
                updateInfluencerRateLoading: false,
                updateInfluencerRateLoaded: true,
                rates: action.rates.data
            }
        case influencerConstants.UPDATE_RATE_FAILURE:
            return {
                ...state,
                updateInfluencerRateLoading: false,
                updateInfluencerRateLoaded: true,
                error: action.error
            }

        case influencerConstants.DELETE_RATE_REQUEST:
            return {
                ...state,
                deleteInfluencerRateLoading: true,
                deleteInfluencerRateLoaded: false,
            }
        case influencerConstants.DELETE_RATE_SUCCESS:
            return {
                ...state,
                deleteInfluencerRateLoading: false,
                deleteInfluencerRateLoaded: true,
                rates: action.rates.data
            }
        case influencerConstants.DELETE_RATE_FAILURE:
            return {
                ...state,
                deleteInfluencerRateLoading: false,
                deleteInfluencerRateLoaded: true,
                error: action.error
            }

        case influencerConstants.ADD_NOTE_REQUEST:
            return {
                ...state,
                addInfluencerNoteLoading: true,
                addInfluencerNoteLoaded: false,
            }
        case influencerConstants.ADD_NOTE_SUCCESS:
            return {
                ...state,
                addInfluencerNoteLoading: false,
                addInfluencerNoteLoaded: true,
                notes: action.notes.data
            }
        case influencerConstants.ADD_NOTE_FAILURE:
            return {
                ...state,
                addInfluencerNoteLoading: false,
                addInfluencerNoteLoaded: true,
                error: action.error
            }

        case influencerConstants.GET_ALL_NOTES_REQUEST:
            return {
                ...state,
                getInfluencerNoteLoading: true,
                getInfluencerNoteLoaded: false,
            }
        case influencerConstants.GET_ALL_NOTES_SUCCESS:
            return {
                ...state,
                getInfluencerNoteLoading: false,
                getInfluencerNoteLoaded: true,
                notes: action.notes.data

            }
        case influencerConstants.GET_ALL_NOTES_FAILURE:
            return {
                ...state,
                getInfluencerNoteLoading: false,
                getInfluencerNoteLoaded: true,
                error: action.error
            }

        case influencerConstants.UPDATE_NOTE_REQUEST:
            return {
                ...state,
                updateInfluencerNoteLoading: true,
                updateInfluencerNoteLoaded: false,
            }
        case influencerConstants.UPDATE_NOTE_SUCCESS:
            return {
                ...state,
                updateInfluencerNoteLoading: false,
                updateInfluencerNoteLoaded: true,
                notes: action.notes.data
            }
        case influencerConstants.UPDATE_NOTE_FAILURE:
            return {
                ...state,
                updateInfluencerNoteLoading: false,
                updateInfluencerNoteLoaded: true,
                error: action.error
            }

        case influencerConstants.DELETE_NOTE_REQUEST:
            return {
                ...state,
                deleteInfluencerNoteLoading: true,
                deleteInfluencerNoteLoaded: false,
            }
        case influencerConstants.DELETE_NOTE_SUCCESS:
            return {
                ...state,
                deleteInfluencerNoteLoading: false,
                deleteInfluencerNoteLoaded: true,
                notes: action.notes.data
            }
        case influencerConstants.DELETE_NOTE_FAILURE:
            return {
                ...state,
                deleteInfluencerNoteLoading: false,
                deleteInfluencerNoteLoaded: true,
                error: action.error
            }

        case influencerConstants.GET_ALL_ACTIVITIES_REQUEST:
            return {
                ...state,
                getInfluencerActivitiesLoading: true,
                getInfluencerActivitiesLoaded: false,
            }
        case influencerConstants.GET_ALL_ACTIVITIES_SUCCESS:
            return {
                ...state,
                getInfluencerActivitiesLoading: false,
                getInfluencerActivitiesLoaded: true,
                activities: action.activities.data
            }
        case influencerConstants.GET_ALL_ACTIVITIES_FAILURE:
            return {
                ...state,
                getInfluencerActivitiesLoading: false,
                getInfluencerActivitiesLoaded: true,
                error: action.error
            }

        case influencerConstants.GET_CLIENT_LIST_REQUEST:
            return {
                ...state,
                getClientListLoading: true,
                getClientListLoaded: false,
            }
        case influencerConstants.GET_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                getClientListLoading: false,
                getClientListLoaded: true,
                clientList: action.clients.data
            }
        case influencerConstants.GET_CLIENT_LIST_FAILURE:
            return {
                ...state,
                getClientListLoading: false,
                getClientListLoaded: true,
                error: action.error
            }

        case influencerConstants.GET_PLATFORM_TYPE_REQUEST:
            return {
                ...state,
                getPlatFormTypeLoading: true,
                getPlatFormTypeLoaded: false,
            }
        case influencerConstants.GET_PLATFORM_TYPE_SUCCESS:
            return {
                ...state,
                getPlatFormTypeLoading: false,
                getPlatFormTypeLoaded: true,
                platformTypes: action.platforms.data
            }
        case influencerConstants.GET_PLATFORM_TYPE_FAILURE:
            return {
                ...state,
                getPlatFormTypeLoading: false,
                getPlatFormTypeLoaded: true,
                error: action.error
            }

        case influencerConstants.CATEGORY_LIST_REQUEST:
            return {
                ...state,
                getCategoryListLoading: true,
                getCategoryListLoaded: false,
            }
        case influencerConstants.CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                getCategoryListLoading: false,
                getCategoryListLoaded: true,
                categoryList: action.categories.data
            }
        case influencerConstants.CATEGORY_LIST_FAILURE:
            return {
                ...state,
                getCategoryListLoading: false,
                getCategoryListLoaded: true,
                error: action.error
            }

        default:
            return state

    }

}