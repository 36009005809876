import { templateConstants } from "_constants";
import { templateService } from "_services";
import { alertActions } from "_actions/alert.actions";

export const templateActions = {
  getTemplates,
  createTemplate,
  updateTemplate,
  removeTemplate,
  removeTemplates,
  shareTemplate,
  unshareTemplate,
  duplicateTemplate,
  getSharedTemplates,
  getSharedTemplatePaginated,
  getTemplatesDropdown,
  getTemplateAccountCategories,
  getTemplateSharedCategories
};

/**
 * Fetches templates based on the provided parameters and dispatches appropriate actions.
 *
 * @param {string} projectId - The ID of the project to fetch templates for.
 * @param {string|null} [query=null] - The search query to filter templates.
 * @param {number} [page=1] - The page number for pagination.
 * @param {number} [size=15] - The number of templates per page.
 * @param {string} [cat=''] - The category to filter templates.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 */
function getTemplates(projectId,  query = null, page = 1, size = 15, cat = '') {
  return (dispatch) => {
    dispatch(request());

    templateService.getTemplates(projectId,  query, page, size, cat).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        // error: "Internal Server Error"
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(data));
        dispatch(alertActions.error(data));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Fetches the templates dropdown for a given project and dispatches the appropriate actions.
 *
 * @param {string} projectId - The ID of the project for which to fetch the templates dropdown.
 * @returns {Function} A thunk function that dispatches actions based on the API call result.
 */
function getTemplatesDropdown(projectId) {
  return (dispatch) => {
    dispatch(request());

    templateService.getTemplatesDropdown(projectId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        // error: "Internal Server Error"
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(data));
        dispatch(alertActions.error(data));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_DROPDOWN_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Creates a new template for a given project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {Object} templateRequest - The request object containing template details.
 * @returns {Function} A thunk function that dispatches actions based on the API call result.
 */
function createTemplate(projectId, templateRequest) {
  return (dispatch) => {
    dispatch(request());

    templateService.createTemplate(projectId, templateRequest).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is created successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Updates a template for a given project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} templateId - The ID of the template to be updated.
 * @param {Object} templateRequest - The request object containing template data.
 * @returns {Function} A thunk function that dispatches actions based on the update result.
 */
function updateTemplate(projectId, templateId, templateRequest) {
  return (dispatch) => {
    dispatch(request());

    templateService.updateTemplate(projectId, templateId, templateRequest).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is updated successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Removes a template from a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} templateId - The ID of the template to be removed.
 * @returns {Function} A thunk function that dispatches actions based on the result of the template removal.
 */
function removeTemplate(projectId, templateId) {
  return (dispatch) => {
    dispatch(request());

    templateService.removeTemplate(projectId, templateId).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is deleted successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Removes templates from a project.
 *
 * @param {string} projectId - The ID of the project from which templates are to be removed.
 * @param {Object} templateRequest - The request object containing templates to be removed.
 * @param {Array} templateRequest.templates - The list of templates to be removed.
 * @returns {Function} A thunk function that dispatches actions based on the result of the template removal.
 */
function removeTemplates(projectId, templateRequest) {
  return (dispatch) => {
    dispatch(request());

    templateService.removeTemplates(projectId, templateRequest).then(
      (data) => {
        dispatch(success(data));
        //// console.log('deletes: ', templateRequest);
        dispatch(alertActions.success(templateRequest.templates.length + " template/s deleted successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        //// console.log("error: ", data);
        let errResponse = data.error_message;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Shares a template with the specified project.
 *
 * @param {string} projectId - The ID of the project to share the template with.
 * @param {string} templateId - The ID of the template to be shared.
 * @returns {Function} A thunk function that dispatches actions based on the result of the template sharing operation.
 */
function shareTemplate(projectId, templateId) {
  return (dispatch) => {
    dispatch(request());

    templateService.shareTemplate(projectId, templateId).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is shared successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        //// console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Unshare a template from a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} templateId - The ID of the template to unshare.
 * @returns {function} A redux-thunk function that dispatches actions based on the result of the unshare operation.
 */
function unshareTemplate(projectId, templateId) {
  return (dispatch) => {
    dispatch(request());

    templateService.unshareTemplate(projectId, templateId).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template sharing is removed successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Duplicates a template for a given project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} templateId - The ID of the template to duplicate.
 * @param {Object} dupRequest - The request payload for duplicating the template.
 * @returns {Function} A redux-thunk function that dispatches actions based on the API call result.
 */
function duplicateTemplate(projectId, templateId, dupRequest) {
  return (dispatch) => {
    dispatch(request());

    templateService.duplicateTemplate(projectId, templateId, dupRequest).then(
      (data) => {
        dispatch(success(data));
        dispatch(alertActions.success("Template is duplicated successfully."));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Fetches shared templates for a given project and dispatches appropriate actions based on the result.
 *
 * @param {string} projectId - The ID of the project to fetch shared templates for.
 * @returns {Function} A thunk function that dispatches actions based on the result of the API call.
 */
function getSharedTemplates(projectId) {
  return (dispatch) => {
    dispatch(request());

    templateService.getSharedTemplates(projectId).then(
      (data) => {
       // console.log('data: ', data);
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
       // console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: templateConstants.GET_SHARED_TEMPLATES_REQUEST };
  }

  function success(shared) {
    return { type: templateConstants.GET_SHARED_TEMPLATES_SUCCESS, shared };
  }

  function failure(error) {
    return { type: templateConstants.GET_SHARED_TEMPLATES_FAILURE, error };
  }
}

/**
 * Fetches paginated shared templates for a given project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string|null} [query=null] - The search query to filter templates.
 * @param {number} [page=1] - The page number to fetch.
 * @param {number} [size=15] - The number of templates per page.
 * @param {string} [cat=''] - The category to filter templates.
 * @returns {function} - A redux-thunk function that dispatches actions based on the API call result.
 */
function getSharedTemplatePaginated(projectId,  query = null, page = 1, size = 15, cat = '') {
  return dispatch => {
      dispatch(request(projectId,  query, page, size));
      templateService.getSharedTemplatePaginated(projectId,  query, page, size, cat)
          .then(
              (data) => {
                  //// console.log('paginated templates: ', data);
                  dispatch(success(data));
              },
              error => {
                  const {response} = error;
                  const {data} = response;
                  dispatch(failure(data));
              }
          );
  };

  function request() {
    return { type: templateConstants.GET_SHARED_TEMPLATES_REQUEST };
  }

  function success(shared) {
    return { type: templateConstants.GET_SHARED_TEMPLATES_SUCCESS, shared };
  }

  function failure(error) {
    return { type: templateConstants.GET_SHARED_TEMPLATES_FAILURE, error };
  }
}

/**
 * Fetches the account categories for a given project template.
 * Dispatches request, success, and failure actions based on the API response.
 *
 * @param {string} projectId - The ID of the project to fetch account categories for.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 */
function getTemplateAccountCategories(projectId) {
    return dispatch => {
        dispatch(request(projectId));
        templateService.getAccountCategories(projectId)
            .then(
                (data) => {
                 // console.log('category templates: ', data);
                    dispatch(success(data));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                }
            );
    };
  
    function request() {
      return { type: templateConstants.GET_TEMPLATES_CATEGORY_REQUEST };
    }
  
    function success(template) {
      return { type: templateConstants.GET_TEMPLATES_CATEGORY_SUCCESS, template };
    }
  
    function failure(error) {
      return { type: templateConstants.GET_TEMPLATES_CATEGORY_FAILURE, error };
    }
}

/**
 * Fetches shared categories for a given project and dispatches appropriate actions.
 *
 * @param {string} projectId - The ID of the project to fetch shared categories for.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 */
function getTemplateSharedCategories(projectId) {
  return dispatch => {
      dispatch(request(projectId));
      templateService.getSharedCategories(projectId)
          .then(
              (data) => {
                 // console.log('category templates: ', data);
                  dispatch(success(data));
              },
              error => {
                  const {response} = error;
                  const {data} = response;
                  dispatch(failure(data));
              }
          );
  };

  function request() {
    return { type: templateConstants.GET_TEMPLATES_CATEGORY_REQUEST };
  }

  function success(template) {
    return { type: templateConstants.GET_TEMPLATES_CATEGORY_SUCCESS, template };
  }

  function failure(error) {
    return { type: templateConstants.GET_TEMPLATES_CATEGORY_FAILURE, error };
  }
}