import React from 'react';

/**
 * ParameterDrawerLoader is a functional React component that renders a skeleton loader.
 * This loader is used to indicate loading state for a parameter drawer.
 *
 * @returns {JSX.Element} A JSX element representing the skeleton loader.
 */
const ParameterDrawerLoader = () => {
  return (
    <div className="skeleton-loader skeleton-parameter-drawer active">
        <div className='skeleton-groups'>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
            <div className='skeleton skeleton-box'></div>
        </div>
    </div>
  )
}

export default ParameterDrawerLoader