import { httpManagerService as httpManager } from '_services';

export const profileService = {
    getProfile,
    updateProfile,
    updatePassword,
    getTemplates,
    getTemplate,
    createTemplate,
    updateTemplate,
    removeTemplate,
    removeTemplates
};

/**
 * Fetches the user's profile information.
 * @returns {Promise<Object>} A promise resolving to the user's profile data.
 */
function getProfile() {
    return httpManager.getHttpWithRequestingToken(`/api/profile`);
}

/**
 * Updates the user's profile information.
 * @param {Object} request - The updated profile data.
 * @returns {Promise<Object>} A promise resolving to the updated profile response.
 */
function updateProfile(request) {
    return httpManager.putJsonOnce(`/api/profile`, request);
}

/**
 * Updates the user's password.
 * @param {Object} request - The password update payload.
 * @returns {Promise<Object>} A promise resolving to the password update response.
 */
function updatePassword(request) {
    return httpManager.putJsonOnce(`/api/profile/password`, request);
}

/**
 * Retrieves a list of all email templates.
 * @returns {Promise<Array>} A promise resolving to an array of email templates.
 */
function getTemplates() {
    return httpManager.getHttpWithRequestingToken(`/api/v2/profile/email/template`);
}

/**
 * Fetches a specific email template by its ID.
 * @param {number} templateId - The ID of the email template.
 * @returns {Promise<Object>} A promise resolving to the email template data.
 */
function getTemplate(templateId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/profile/email/template/${templateId}`);
}

/**
 * Creates a new email template.
 * @param {Object} request - The email template data.
 * @returns {Promise<Object>} A promise resolving to the created email template.
 */
function createTemplate(request) {
    return httpManager.postJsonOnce(`/api/v2/profile/email/template`, request);
}

/**
 * Updates an existing email template.
 * @param {number} templateId - The ID of the email template to update.
 * @param {Object} request - The updated template data.
 * @returns {Promise<Object>} A promise resolving to the updated template.
 */
function updateTemplate(templateId, request) {
    return httpManager.putJsonOnce(`/api/v2/profile/email/template/${templateId}`, request);
}

/**
 * Deletes an email template by its ID.
 * @param {number} templateId - The ID of the email template to delete.
 * @returns {Promise<Object>} A promise resolving to the delete response.
 */
function removeTemplate(templateId) {
    return httpManager.deleteJsonOnce(`/api/v2/profile/email/template/${templateId}`);
}

/**
 * Deletes multiple email templates in bulk.
 * @param {Object} request - The request payload containing IDs of templates to delete.
 * @returns {Promise<Object>} A promise resolving to the bulk delete response.
 */
function removeTemplates(request) {
    return httpManager.deleteJsonOnce(`/api/v2/profile/email/templates`, request);
}
