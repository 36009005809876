import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { influencerActions } from "_actions/influencer.actions";
import KpiStatusInfo from "_pages/Propelrr/components/Kpi/KpiStatusInfo";
import KpiResultInfo from "_pages/Propelrr/components/Kpi/KpiResultInfo";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from "swiper";
import KpiLoader from '../../../../_components/Loader/KpiLoader';

const KpiHeader = ({ summary, isSummaryLoaded, isKpiHeaderExpanded, clickedBox, getInfluencerSummary, projectId, onHandleKpiHeaderHeightChanged, onHandleReset, onHandleKpiHeaderFilter }) => {

    const kpiRef = useRef(null);
    const [toggleKpi, setToggleKpi] = useState(true);
    const [kpiTotalHeight, setKpiTotalHeight] = useState(500);

    useEffect(() => {
        getInfluencerSummary(projectId);
        if (kpiRef.current) {
            setKpiTotalHeight(kpiRef.current.clientHeight);
        }
    }, [projectId, getInfluencerSummary]);

    const handleToggleKpi = () => {
        setToggleKpi(prevState => !prevState);
        onHandleKpiHeaderHeightChanged(!toggleKpi);
    };

    const handleReset = () => onHandleReset();

    const handleKpiHeaderFilter = (group, value, color) => onHandleKpiHeaderFilter(group, value, color);

    let grouped = [];
    let ids = [];
    let categories = [];
    let ungrouped = [];
    let total = 0;

    if (isSummaryLoaded) {
        ungrouped = summary;
        grouped = ungrouped.reduce(function (collection, elem) {
            let key = elem['category'];
            if (!categories.includes(key)) {
                categories.push(key);
                ids[key] = elem.id;
            }
            (collection[key] = collection[key] || []).push(elem);
            return collection;
        }, []);

        total = 0;
        ids['Total Summary'] = 0;
        grouped['Total Summary'].forEach(c => {
            total = total + c.count;
        });
    }

    const activeClass = isKpiHeaderExpanded ? 'active' : '';

    return (
        <div className={`overview-kpi-box influencer-kpi ${activeClass}`} ref={kpiRef}
            style={isKpiHeaderExpanded ? { maxHeight: kpiTotalHeight } : { maxHeight: "0" }}>
            <div className='main-box'>
                <div className='status-list-box'>
                    <div className='status-list'>
                        <Swiper
                            watchSlidesProgress={true}
                            observer={true}
                            observeParents={true}
                            observeSlideChildren={true}
                            resizeObserver={true}
                            slidesPerView={"auto"}
                            spaceBetween={12}
                            freeMode={true}
                            modules={[FreeMode, Navigation]}
                            className="kpiSwiper"
                            navigation={{ nextEl: ".nextSlide", prevEl: ".prevSlide" }}
                            onSlideChange={(swiper) => swiper.update()}
                        >
                            <SwiperSlide>
                                <div className='box' onClick={handleReset}>
                                    <KpiStatusInfo
                                        title='Total Influencers'
                                        description='Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, autem.'
                                    />
                                    <div className='status-result'>
                                        <KpiResultInfo
                                            total={total}
                                            info='Total Influencers'
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            {categories.map((value, index) => {
                                let color = index + 1;
                                let platform = ["total", "facebook", "instagram", "twitter", "youtube", "tiktok", "kumu", "others"];
                                let platformClass = platform[index % platform.length];

                                return (
                                    <SwiperSlide key={index}>
                                        <div className={clickedBox === value ? `box kpi-color-${platformClass} active` : `box kpi-color-${platformClass}`} onClick={() => handleKpiHeaderFilter(ids[value], value, color)}>
                                            <KpiStatusInfo
                                                title={value}
                                                description={value}
                                            />
                                            <div className='status-result'>
                                                {grouped[value].map((item, index) => (
                                                    <KpiResultInfo key={index}
                                                        total={item.count}
                                                        info={item.label}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <button className='swiper-custom-btn prevSlide'><i className="icon-lms icon-arrow-left"></i></button>
                        <button className='swiper-custom-btn nextSlide'><i className="icon-lms icon-arrow-right"></i></button>
                    </div>
                </div>
            </div>
            {!isSummaryLoaded && <KpiLoader />}
        </div>
    );
};

function mapState(state) {
    const { summary, isSummaryLoaded } = state.influencers;
    return { summary, isSummaryLoaded };
}

const actionCreators = {
    getInfluencerSummary: influencerActions.getInfluencerSummary,
};

const connectedViewKpiHeader = connect(mapState, actionCreators)(KpiHeader);

export { connectedViewKpiHeader as KpiHeader };
