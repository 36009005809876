import React from "react";

/**
 * TextLoader component renders a skeleton loader for text content.
 * 
 * @component
 * @example
 * return (
 *   <TextLoader />
 * )
 */
const TextLoader = () => {
  return (
    <div className={`skeleton-loader text-loader`}>
        <div className="skeleton skeleton-box"></div>
    </div>
  );
};

export default TextLoader;
