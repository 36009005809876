import { httpManagerService as httpManager } from  '_services';

export const chatService = {
    getLeadChatConversations,
    updateUnreadChats,
    agentMessageReply

}

/**
 * Fetches chat conversations for a specific lead within a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @returns {Promise} - A promise that resolves to the chat conversations.
 */
function getLeadChatConversations(projectId, leadId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/lead/${leadId}/chat`);
}

/**
 * Updates the unread chat messages for a specific project and lead.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {Object} request - The request payload containing the update details.
 * @returns {Promise} - A promise that resolves when the update is complete.
 */
function updateUnreadChats(projectId, leadId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/chat/unread`, request);
}

/**
 * Sends a message reply from an agent in a chat.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} leadId - The ID of the lead.
 * @param {Object} request - The request payload containing the message details.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function agentMessageReply(projectId, leadId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/lead/${leadId}/chat`, request);
}