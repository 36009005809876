import React from 'react'

/**
 * SideInfoButtonLoader is a functional component that renders a skeleton loader
 * for side information buttons. It displays four skeleton boxes to indicate
 * loading state.
 *
 * @returns {JSX.Element} A div containing four skeleton loader boxes.
 */
const SideInfoButtonLoader = () => {
  return (
    <div className={`skeleton-loader skeleton-side-info-btns`}>
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
    </div>
  )
}

export default SideInfoButtonLoader