import { emailActions, profileActions } from "_actions";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { utilitiesService, utilitiesService as utility } from "_services";
import avatar from "../../../../../_assets/images/avatar-sm.png";
import parse from "html-react-parser";
import StarterKit from "@tiptap/starter-kit";
import { EditorContent, useEditor } from "@tiptap/react";
import MenuBarReply from "./MenuBarReply";
import Link from "@tiptap/extension-link";

const LeadEmailSingle = ({ onHandleToggleEmail, openDraft, saveDraftEmail, thread, isLeadManager }) => {
  // console.log("thread: ", thread);
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const updatedLeadEmail = useSelector((state) => state.email);
  const profile = useSelector((state) => state.profile);

  const params = useParams();
  const [isReply, setIsReply] = useState(false);
  const [messageReply, setMessageReply] = useState("");
  const [leadThread, setleadThread] = useState(thread);
  const [editorContent, setEditorContent] = useState("");
  const [signature, setSignature] = useState("");
  const [threadLoaded, setThreadLoaded] = useState(
    updatedLeadEmail.getEmailLoaded
  );
  const [leadEmail, setLeadEmail] = useState(updatedLeadEmail.email);

  const [isExpanded, setIsExpanded] = useState(false);

  const [fullScreenEditor, setFullScreenEditor] = useState(false);

  const toggleFullScreen = () => {
    setFullScreenEditor(!fullScreenEditor);
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    dispatch(profileActions.getProfile());
  }, []);

  useEffect(() => {
    if (profile.getProfileLoaded) {
      //  setMessageReply(renderSignature);

      renderSignature();
    }
  }, [Object.keys(profile).length]);

  useEffect(() => {
    setLeadEmail(updatedLeadEmail.email);

    if (!threadLoaded) {
      console.log("update thread....");
      dispatch(
        emailActions.updateThreadMessages(
          params.id,
          params.leadId,
          thread.thread_id
        )
      );
    }

    //  console.log("current state: ", updatedLeadEmails);
    if (leadEmail !== undefined && threadLoaded) {
      const updatedThread = leadEmail.email_threads.find(
        (tm) => tm.thread_id === thread.thread_id
      );
      console.log("updating thread: ", updatedThread);

      if (updatedThread != undefined && updatedThread.status == true) {
        updateUnreadMessages(updatedThread);
        console.log("updated Lead: ", updatedThread);
      }
      setleadThread(updatedThread);
    }
  }, [threadLoaded]);

  useEffect(() => {
    console.log("thread loaded: ", threadLoaded);
    console.log("thread status: ", leadThread.status);

    if (threadLoaded) {
      if (leadThread.status == true) {
      }

      const chatLogElement = messagesEndRef.current;
      const currentScrollTop = chatLogElement.scrollTop;
      const targetScrollTop =
        chatLogElement.scrollHeight - chatLogElement.clientHeight;
      const scrollDiff = targetScrollTop - currentScrollTop;
      let startTime;

      function scroll(timestamp) {
        if (!startTime) {
          startTime = timestamp;
        }

        const elapsedTime = timestamp - startTime;
        const progress = elapsedTime / 600;

        chatLogElement.scrollTop = currentScrollTop + scrollDiff * progress;

        if (progress < 1) {
          window.requestAnimationFrame(scroll);
        }
      }

      window.requestAnimationFrame(scroll);
    }
  }, [leadThread.thread_messages.length]);

  const renderSignature = () => {
    if (profile.data != null && editor != null) {
      const signature = "<p><p><p>" + utility.decrypt(profile.data.signature);
      editor.commands.setContent(signature);
    }
  };

  const handleReplyToggle = () => {
    if (Object.keys(openDraft).length == 0) {
      renderSignature();
    }

    setIsReply(!isReply);
    editor.commands.focus('start');
  };

  const handleDiscardDraft = () => {

    if(openDraft && Object.keys(openDraft).length > 0) {
      dispatch(emailActions.deleteDraft(params.id, params.leadId, openDraft.id));
    } 

    setIsReply(!isReply);
    saveDraftEmail({});

  }

  const updateUnreadMessages = (thread) => {
    const messageIds = thread.thread_messages
      .filter((msg) => {
        return msg.status == 1;
      })
      .map((m) => {
        return m.message_id;
      });

    const request = {
      thread_id: thread.thread_id,
      message_ids: messageIds,
    };

    if (messageIds.length > 0) {
      dispatch(
        emailActions.updateUnreadEmails(params.id, params.leadId, request)
      );
    }
  };

  const handleReplyEmail = () => {
    const messageId =
      leadThread.thread_messages[thread.thread_messages.length - 1].message_id;

    const subject = leadThread.subject;
    let message = messageReply;

    // console.log('leadthread: ', leadThread)

    const request = {
      thread_id: leadThread.thread_id,
      subject: subject,
      message: utility.encrypt(editor.getHTML()),
      message_id: messageId,
    };

    console.log('reply message: ', editorContent);

    dispatch(emailActions.sendEmailReply(params.id, params.leadId, request));

    if(Object.keys(openDraft).length > 0) {
      console.log('deleting draft in single email');
      dispatch(emailActions.deleteDraft(params.id, params.leadId, openDraft.id));
    }

    setIsReply(!isReply);
    saveDraftEmail({});
  };

  const handleSaveEditorContent = (editorContent) => {
    // Handle the updated editor content in the parent component
    // console.log("Updated Editor Content in parent:", editorContent);
    // You can save it in the state or dispatch an action, etc.
    setEditorContent(editorContent);
  };

  const renderAttachment = (attachments, msgBody) => {
    attachments.forEach((element) => {
      msgBody = msgBody.replaceAll(element.content_id, element.attachment);
    });

    return msgBody;
  };

  const renderExpander = (msgBody) => {
    const ellipsesExpanded =
      '<div onClick={toggleExpanded} className="toggle-content"><i className="fas fa-ellipsis-h"></i></div>';
    const expanderP1 = ' {isExpanded && <div className="gmail_quote">';
    const expanderP2 =
      '</div> } <br clear="all"><div><br></div><span className="gmail_signature_prefix">-- </span><br>';

    if (msgBody.includes("gmail_quote")) {
      msgBody = msgBody.replace("<br>", "<br> " + ellipsesExpanded + " ");
      msgBody = msgBody.replace('<div className="gmail_quote">', expanderP1);
      msgBody = msgBody.replace(
        '</div><br clear="all"><div><br></div><span className="gmail_signature_prefix">-- </span><br>',
        expanderP2
      );
      return msgBody;
    }

    return msgBody;
  };

  const editor = useEditor({
    extensions: [StarterKit, Link],
    autofocus: true,
    content: editorContent,
    onUpdate({ editor }) {
      setEditorContent(editor.getHTML());
    },
  });

  useEffect(() => {
    
    if (openDraft && Object.keys(openDraft).length > 0 && editor != null) {
      editor.commands.setContent(utilitiesService.decrypt(openDraft.template));
      handleReplyToggle();
    }
  }, [openDraft, editor]);

  const renderMessages = () => {
    //  console.log("render thread messages: ", leadThread.thread_messages.length);
    return leadThread.thread_messages.map((msg) => {
      const decryptFrom = utility
        .decrypt(msg.email_from)
        .replace("info@propelrr.com", "hello@propelrr.com");
      const decryptTo = utility.decrypt(msg.email_to);
      let decryptMsg = utility.decrypt(msg.message);
      decryptMsg = renderAttachment(msg.attachments, decryptMsg);
      //  decryptMsg = renderExpander(decryptMsg)
      const date = new Date(msg.created).toLocaleString("en-US", {
        timeZone: "Asia/Manila",
      });
      return (
        <div className="reply-email-box" key={msg.message_id}>
          <div className="col-box">
            <div className="avatar-icon">
              <img src={avatar} />
            </div>
          </div>
          <div className="col-box">
            <div className="reply-details">
              <div className="reply-top">
                {decryptFrom.includes("<") && (
                  <div>
                    <p>
                      {decryptFrom.split("<")[0]}
                      <span>
                        &lt;{decryptFrom.split("<")[1].slice(0, -1)}&gt;
                      </span>
                    </p>
                    <p>to {decryptTo.split("<")[0]}</p>
                  </div>
                )}
                {!decryptFrom.includes("<") && (
                  <div>
                    <p>{decryptFrom}</p>
                    <p>to {decryptTo}</p>
                  </div>
                )}
                <div>
                  <p>{date}</p>
                </div>
              </div>
              <div className="reply-bottom">
                {/* <pre>{decryptMsg}</pre> */}
                <div>{parse(decryptMsg)}</div>
                {/* <div onClick={toggleExpanded} className="toggle-content"><i className="fas fa-ellipsis-h"></i></div>
                {isExpanded && <div>{parse(decryptMsg)}</div>} */}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const handleSaveDraftReply = () => {
    const messageId =
      leadThread.thread_messages[thread.thread_messages.length - 1].message_id;

    const subject = leadThread.subject;
    let message = messageReply;

    const request = {
      thread_id: leadThread.thread_id,
      subject: subject,
      template: utility.encrypt(editor.getHTML()),
      message_id: messageId,
      to: leadThread.email_from,
      from: leadThread.email_to,
      type: 'reply'
    };

    if(openDraft && Object.keys(openDraft).length > 0) {
      let updateReplyThread = openDraft;
      console.log('existing draft: ', openDraft);
      updateReplyThread.template = utility.encrypt(editor.getHTML());

      dispatch(emailActions.updateDraft(params.id, params.leadId, updateReplyThread));

    } else {
      dispatch(emailActions.saveDraft(params.id, params.leadId, request));
    }

    setIsReply(!isReply);
    setFullScreenEditor(false);
  };

  return (
    <>
      {threadLoaded && (
        <div className="email-single" ref={messagesEndRef}>
          {renderMessages()}
          {/* <div ref={messagesEndRef} /> */}
        </div>
      )}
      <div
        className={
          fullScreenEditor
            ? "reply-email-control active"
            : "reply-email-control"
        }
      >
        {!isReply && (
          <div className="toggle-group">
            <button
              className="plain-btn cancel-btn"
              onClick={onHandleToggleEmail}
            >
              {/* <i className="icon-lms icon-arrow-left"></i> */}
              Back
            </button>
            <button 
            disabled={!isLeadManager}
            className={`plain-btn ${!isLeadManager ? "btn-text-disabled" : ""}`} 
            onClick={handleReplyToggle}>
              {/* <i className="icon-lms icon-reply"></i> */}
              Reply
            </button>
          </div>
        )}
        {isReply && (
          <div className="compose-reply">
            <div className="input-box">
              {/* <textarea placeholder="Reply" onChange={e => setMessageReply(e.target.value)}/> */}
              <div className="custom-basic-editor email-editor">
                <MenuBarReply
                  editor={editor}
                  onSaveEditorContent={handleSaveEditorContent}
                  toggleFullScreen={toggleFullScreen}
                  fullScreenEditor={fullScreenEditor}
                />
                <EditorContent
                  editor={editor}
                  className="thread-editor-field"
                />
              </div>
            </div>
            <div className="button-group">
              <button
                className="plain-btn cancel-btn"
                onClick={handleSaveDraftReply}
              >
                {/* <i className="icon-lms icon-arrow-left"></i> */}
                Save as Draft
              </button>
              <div className="two-btn">
              <button
                className="plain-btn cancel-btn"
                onClick={handleDiscardDraft}
              >
                {/* <i className="icon-lms icon-arrow-left"></i> */}
                Discard
              </button>
              <button 
                disabled={!isLeadManager}
                className={`plain-btn ${!isLeadManager ? "btn-text-disabled" : ""}`} 
                onClick={handleReplyEmail}>
                {/* <i className="icon-lms icon-reply"></i> */}
                Send Reply
              </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {!threadLoaded && (
        <div className="loading-box">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadEmailSingle;
