import { httpManagerService as httpManager } from  '_services';

export const formService = {
  getForms,
  getForm,
  getDefaultForm,
  createForm,
  updateForm,
  deleteForm,
  deleteMultipleForm,
  updateFormStatus,
  getFormMessage,
  updateFormMessage,
  sendTestEmailMessage,
  findEmailTemplate,
  selectEmailTemplate,
  getAllFormGroup,
  createFormGroup,
  findFormGroup,
  updateFormGroup,
  deleteFormGroup,
  enableFormGroup,
  reorderGroup,
  getAllField,
  getAllFields,
  createField,
  findField,
  updateField,
  deleteField,
  enableField,
  reorderField,
  getAllCustomField,
  createCustomField,
  findCustomField,
  updateCustomField,
  deleteCustomField,
  enableCustomField,
  reorderCustomField,
  findAllField,
  updateIdentifier,
  updateSubIdentifier,
  getAllFieldOption,
  createFieldOption,
  findFieldOption,
  updateFieldOption,
  deleteFieldOption,
  getAllPreset,
  createPreset,
  findPreset,
  updatePreset,
  getAllTemplate,
  createTemplate,
  findTemplate,
  updateTemplate,
  getFormAccessKey,
  getFormIntegration,
  updateFormIntegration
};

/**
 * Fetches forms associated with a specific project.
 *
 * @param {string} project_id - The ID of the project to retrieve forms for.
 * @returns {Promise} - A promise that resolves to the response of the HTTP request.
 */
function getForms(project_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form`);
}

/**
 * Fetches a form for a given project.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @returns {Promise} - A promise that resolves to the form data.
 */
function getForm(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}`);
}

/**
 * Fetches the default form for a given project.
 *
 * @param {string} project_id - The ID of the project.
 * @returns {Promise<Object>} A promise that resolves to the default form data.
 */
function getDefaultForm(project_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/default`);
}

/**
 * Creates a new form for a specific project.
 *
 * @param {Object} form - The form data to be created.
 * @param {number|string} project_id - The ID of the project to which the form belongs.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function createForm(form, project_id) { //create --
  return httpManager.postJsonOnce(`/api/project/${project_id}/form`, form);
}

/**
 * Updates a form with the given form data.
 *
 * @param {Object} form - The form data to update.
 * @param {string} form_id - The ID of the form to update.
 * @param {string} project_id - The ID of the project the form belongs to.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
function updateForm(form, form_id, project_id) { //update --
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}`, form);
}

/**
 * Deletes a form from a project.
 *
 * @param {Object} form - The form object to be deleted.
 * @param {string} project_id - The ID of the project containing the form.
 * @param {string} form_id - The ID of the form to be deleted.
 * @returns {Promise} - A promise that resolves when the form is successfully deleted.
 */
function deleteForm(form, project_id, form_id) { //delete --
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}`, form);
}

/**
 * Deletes multiple forms for a given project.
 *
 * @param {Object} form - The form data to be deleted.
 * @param {string} project_id - The ID of the project.
 * @returns {Promise} - A promise that resolves when the forms are deleted.
 */
function deleteMultipleForm(form, project_id) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/delete-selected`, form);
}

/**
 * Updates the status of a form within a project.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} status - The new status of the form (e.g., enabled or disabled).
 * @returns {Promise} - A promise that resolves when the status update is complete.
 */
function updateFormStatus(project_id, form_id, status) { // added to update the status of the form if it is enabled or disable --
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/status/${status}`);
}

/**
 * Fetches the message for a specific form within a project.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @returns {Promise} - A promise that resolves to the form message.
 */
function getFormMessage(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/message`);
}

/**
 * Updates the message for a specific form within a project.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {Object} message - The message content to update.
 * @returns {Promise} - A promise that resolves once the message is updated.
 */
function updateFormMessage(project_id, form_id, message) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/message`, message);
}

/**
 * Sends a test email message for a specific form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @returns {Promise} - A promise that resolves once the test email is sent.
 */
function sendTestEmailMessage(project_id, form_id) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/message/send-email`);
}

/**
 * Finds a specific email template layout for a form message.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} layout_id - The ID of the layout template.
 * @returns {Promise} - A promise that resolves to the layout template details.
 */
function findEmailTemplate(project_id, form_id, layout_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/message/layout/${layout_id}`);
}

/**
 * Selects a specific email template layout for a form message.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} layout_id - The ID of the layout template.
 * @returns {Promise} - A promise that resolves once the layout is selected.
 */
function selectEmailTemplate(project_id, form_id, layout_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/message/layout/${layout_id}`);
}

/**
 * Retrieves all form groups within a specific form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @returns {Promise} - A promise that resolves to an array of form groups.
 */
function getAllFormGroup(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group`);
}

/**
 * Creates a new group within a form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {Object} group - The group details to create.
 * @returns {Promise} - A promise that resolves once the group is created.
 */
function createFormGroup(project_id, form_id, group) {
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/group`, group);
}

/**
 * Finds a specific form group by ID.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the group.
 * @returns {Promise} - A promise that resolves to the group details.
 */
function findFormGroup(project_id, form_id, group_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}`);
}

/**
 * Updates a specific form group by ID.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the group.
 * @param {Object} group - The updated group details.
 * @returns {Promise} - A promise that resolves once the group is updated.
 */
function updateFormGroup(project_id, form_id, group_id, group) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}`, group);
}

/**
 * Deletes a specific form group by ID.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the group.
 * @param {Object} group - Additional details for the deletion.
 * @returns {Promise} - A promise that resolves once the group is deleted.
 */
function deleteFormGroup(project_id, form_id, group_id, group) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}`, group);
}

/**
 * Updates the status of a form group to enable or disable it.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the form group.
 * @param {boolean} status - The status to set (true for enable, false for disable).
 * @returns {Promise} - A promise that resolves when the status is updated.
 */
function enableFormGroup(project_id, form_id, group_id, status) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/status/${status}`)
}

/**
 * Updates the order of form groups within the form builder.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {Object} reorder - The reorder details.
 * @returns {Promise} - A promise that resolves when the order is updated.
 */
function reorderGroup(project_id, form_id, reorder) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/reorder`, reorder)
}

/**
 * Retrieves all fields in a specific form group.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the form group.
 * @returns {Promise} - A promise that resolves to the list of fields.
 */
function getAllField(project_id, form_id, group_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field`);
}

/**
 * Retrieves all fields in a form, regardless of groups.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @returns {Promise} - A promise that resolves to the list of fields.
 */
function getAllFields(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/field`);
}

/**
 * Creates a new field within a form group.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the form group.
 * @param {Object} field - The field data.
 * @returns {Promise} - A promise that resolves when the field is created.
 */
function createField(project_id, form_id, group_id, field) {
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field`, field);
}

/**
 * Retrieves a specific field within a form group.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the form group.
 * @param {string} field_id - The ID of the field.
 * @returns {Promise} - A promise that resolves to the field data.
 */
function findField(project_id, form_id, group_id, field_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}`);
}

/**
 * Updates a specific field within a form group.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the form group.
 * @param {string} field_id - The ID of the field.
 * @param {Object} field - The updated field data.
 * @returns {Promise} - A promise that resolves when the field is updated.
 */
function updateField(project_id, form_id, group_id, field_id, field) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}`, field);
}

/**
 * Deletes a specific field within a form group.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the form group.
 * @param {string} field_id - The ID of the field.
 * @param {Object} field - The field data to delete.
 * @returns {Promise} - A promise that resolves when the field is deleted.
 */
function deleteField(project_id, form_id, group_id, field_id, field) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}`, field);
}

/**
 * Updates the status of a field within a form group (enable or disable).
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the form group.
 * @param {string} field_id - The ID of the field.
 * @param {boolean} status - The status to set (true for enable, false for disable).
 * @returns {Promise} - A promise that resolves when the status is updated.
 */
function enableField(project_id, form_id, group_id, field_id, status) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/status/${status}`);
}

/**
 * Updates the order of fields within a specific group in the form builder.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the group containing the fields.
 * @param {Object} reorder - The new order details.
 * @returns {Promise} - A promise resolving when the update is complete.
 */
function reorderField(project_id, form_id, group_id, reorder) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/reorder`, reorder);
}

/**
 * Retrieves all custom fields within a specified form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @returns {Promise} - A promise resolving to the list of custom fields.
 */
function getAllCustomField(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/custom-field`);
}

/**
 * Creates a new custom field within a specified form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {Object} field - The custom field details.
 * @returns {Promise} - A promise resolving when the field is created.
 */
function createCustomField(project_id, form_id, field) {
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/custom-field`, field);
}

/**
 * Retrieves a specific custom field within a form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} field_id - The ID of the custom field.
 * @returns {Promise} - A promise resolving to the custom field details.
 */
function findCustomField(project_id, form_id, field_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/custom-field/${field_id}`);
}

/**
 * Updates an existing custom field within a form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} field_id - The ID of the custom field.
 * @param {Object} field - The updated custom field details.
 * @returns {Promise} - A promise resolving when the field is updated.
 */
function updateCustomField(project_id, form_id, field_id, field) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/custom-field/${field_id}`, field);
}

/**
 * Deletes a specific custom field from a form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} field_id - The ID of the custom field.
 * @param {Object} field - The field details for deletion.
 * @returns {Promise} - A promise resolving when the field is deleted.
 */
function deleteCustomField(project_id, form_id, field_id, field) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}/custom-field/${field_id}`, field);
}

/**
 * Updates the status of a custom field in a form (enabled or disabled).
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} group_id - The ID of the group containing the field.
 * @param {string} field_id - The ID of the custom field.
 * @param {boolean} status - The new status of the field.
 * @returns {Promise} - A promise resolving when the status update is complete.
 */
function enableCustomField(project_id, form_id, group_id, field_id, status) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/custom-field/${field_id}/status/${status}`);
}

/**
 * Updates the order of custom fields within a form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {Object} reorder - The new order details.
 * @returns {Promise} - A promise resolving when the update is complete.
 */
function reorderCustomField(project_id, form_id, reorder) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/custom-field/reorder`, reorder);
}

/**
 * Retrieves all fields within a form, excluding those within a group.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @returns {Promise} - A promise resolving to the list of fields.
 */
function findAllField(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/field`);
}

/**
 * Updates the identifier of a form within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {Object} identifier - The identifier object containing the new identifier value.
 * @returns {Promise} API response promise.
 */
function updateIdentifier(project_id, form_id, identifier) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/identifier/${identifier.identifier}`, identifier);
}

/**
 * Updates the sub-identifier of a form within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {Object} subidentifier - The sub-identifier object containing the new sub-identifier value.
 * @returns {Promise} API response promise.
 */
function updateSubIdentifier(project_id, form_id, subidentifier) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/sub-identifier/${subidentifier.sub_identifier}`, subidentifier);
}

/**
 * Retrieves all options for a specific field in a group within a form.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {number} group_id - The ID of the group containing the field.
 * @param {number} field_id - The ID of the field.
 * @returns {Promise} API response promise containing field options.
 */
function getAllFieldOption(project_id, form_id, group_id, field_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option`);
}

/**
 * Creates a new option for a specific field in a group within a form.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {number} group_id - The ID of the group containing the field.
 * @param {number} field_id - The ID of the field.
 * @param {Object} option - The field option data.
 * @returns {Promise} API response promise.
 */
function createFieldOption(project_id, form_id, group_id, field_id, option) {
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option`, option);
}

/**
 * Retrieves a specific option of a field within a group.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {number} group_id - The ID of the group containing the field.
 * @param {number} field_id - The ID of the field.
 * @param {number} option_id - The ID of the option to retrieve.
 * @returns {Promise} API response promise containing the option data.
 */
function findFieldOption(project_id, form_id, group_id, field_id, option_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option/${option_id}`);
}

/**
 * Updates an option for a specific field in a group within a form.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {number} group_id - The ID of the group containing the field.
 * @param {number} field_id - The ID of the field.
 * @param {number} option_id - The ID of the option to update.
 * @param {Object} option - The updated field option data.
 * @returns {Promise} API response promise.
 */
function updateFieldOption(project_id, form_id, group_id, field_id, option_id, option) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option/${option_id}`, option);
}

/**
 * Deletes a specific field option within a group.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {number} group_id - The ID of the group containing the field.
 * @param {number} field_id - The ID of the field.
 * @param {number} option_id - The ID of the option to delete.
 * @param {Object} option - The field option data to be deleted.
 * @returns {Promise} API response promise.
 */
function deleteFieldOption(project_id, form_id, group_id, field_id, option_id, option) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/form/${form_id}/group/${group_id}/field/${field_id}/option/${option_id}`, option);
}

/**
 * Retrieves all presets available in a project.
 * @param {number} project_id - The ID of the project.
 * @returns {Promise} API response promise containing preset data.
 */
function getAllPreset(project_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/preset`);
}

/**
 * Creates a new form group from a preset within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {number} preset_id - The ID of the preset.
 * @returns {Promise} API response promise.
 */
function createPreset(project_id, form_id, preset_id) {
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/${form_id}/preset/${preset_id}`);
}

/**
 * Retrieves details of a specific preset within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} preset_id - The ID of the preset to retrieve.
 * @returns {Promise} API response promise containing preset data.
 */
function findPreset(project_id, preset_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/preset/${preset_id}`);
}

/**
 * Updates an existing preset within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} preset_id - The ID of the preset to update.
 * @param {Object} preset - The updated preset data.
 * @returns {Promise} API response promise.
 */
function updatePreset(project_id, preset_id, preset) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/preset/${preset_id}`, preset);
}

/**
 * Retrieves all templates available in a project.
 * @param {number} project_id - The ID of the project.
 * @returns {Promise} API response promise containing template data.
 */
function getAllTemplate(project_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/template`);
}

/**
 * Creates a new form from a template within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} template_id - The ID of the template.
 * @returns {Promise} API response promise.
 */
function createTemplate(project_id, template_id) {
  return httpManager.postJsonOnce(`/api/project/${project_id}/form/template/${template_id}`);
}

/**
 * Retrieves details of a specific template within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} template_id - The ID of the template to retrieve.
 * @returns {Promise} API response promise containing template data.
 */
function findTemplate(project_id, template_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/template/${template_id}`);
}

/**
 * Updates an existing template within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} template_id - The ID of the template to update.
 * @param {Object} template - The updated template data.
 * @returns {Promise} API response promise.
 */
function updateTemplate(project_id, template_id, template) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/template/${template_id}`, template);
}

/**
 * Retrieves the access key for a specific form within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @returns {Promise} API response promise containing the form access key.
 */
function getFormAccessKey(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/accesskey`);
}

/**
 * Retrieves the integration settings for a specific form within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @returns {Promise} API response promise containing the form integration settings.
 */
function getFormIntegration(project_id, form_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/form/${form_id}/integration`);
}

/**
 * Updates the integration settings for a specific form within a project.
 * @param {number} project_id - The ID of the project.
 * @param {number} form_id - The ID of the form.
 * @param {Object} integration - The updated integration settings.
 * @returns {Promise} API response promise.
 */
function updateFormIntegration(project_id, form_id, integration) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/form/${form_id}/integration`, integration);
}
