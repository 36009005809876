import { utilitiesService as utility } from "_services";
import { InputSelect } from "blackbox-react";
import React from "react";
import AgentInfoLoader from "../../../../../_components/Loader/AgentInfoLoader";
import Moment from "react-moment";
import { renderFormGroups } from "./LeadInfoFormGroups";
import { handleTimeConvert } from "../../../../../_helpers/time";
import MainInfoLoader from "../../../../../_components/Loader/MainInfoLoader";
import axios from "axios";
import { environment as env } from "_environments/environment";
import { renderAgentInfo } from "./LeadInfoAgentInfo";
import { dispatch } from "rxjs/internal/observable/pairs";

 // Lead Details
 export const renderBasicInfo = (leadData, props, state, setState, handleGetLead, handleUpdate) => {
  let { leadLoaded, membersLoaded } = props;

  let basicInfo = [];

  const handleAgentChange = (e) => {
    var agent = state.availableAgents.filter((a) => {
      return a.agent_id === e;
    });

    setState((prevState) => ({
      ...prevState,
      selectedAgentValue: agent[0],
      selectedInput: "",
      isSelectFieldExpanded: false,
      isAgentChanged: true,
    }));
  };

  const handleAgentAssign = async (proceed) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (proceed) {
      var data = {
        agent_id:
          state.selectedAgentValue !== undefined &&
          utility.encrypt(state.selectedAgentValue.agent_id),
        agent_name:
          state.selectedAgentValue !== undefined &&
          utility.encrypt(
            state.selectedAgentValue.first_name +
              " " +
              state.selectedAgentValue.last_name
          ),
        agent_contact:
          state.selectedAgentValue !== undefined &&
          utility.encrypt(state.selectedAgentValue.contact),
        agent_email:
          state.selectedAgentValue !== undefined &&
          utility.encrypt(state.selectedAgentValue.email),
      };

      const { match } = props;
      const leadId = match.params.leadId;
      const res = await axios.put(
        `${env.apiUrl}/custom/phi/agent/1/lead/${leadId}/assign`,
        data
      );

      handleGetLead();
    }

    setState((prevState) => ({
      ...prevState,
      isAgentChanged: false,
    }));
  };

  const cancelAgent = () => {
    setState((prevState) => ({
      ...prevState,
      selectedAgentValue: "",
      selectedInput: "",
      isSelectFieldExpanded: false,
      isAgentChanged: false,
    }));
  };

  let cancelViewingStatus = false;

  if (leadLoaded) {
    basicInfo = leadData.basic_info;
    basicInfo = basicInfo.sort((a, b) => (a.id > b.id ? 1 : -1));
    cancelViewingStatus = leadData.project_status.id !== 15;
  }

  let hasAgent = false;

  const projectId = localStorage.getItem("projectId");

  let initialAgentFilter = basicInfo.filter((filteredField) => {
    hasAgent = true;
    return filteredField.field_id === 15;
  });

  const propertyInfo = basicInfo.filter((filteredField) => {
    return filteredField.field_id > 22 && filteredField.field_id < 31;
  });

  const agentStaticInfo = basicInfo.filter((filteredField) => {
    return filteredField.field_id > 14 && filteredField.field_id < 19;
  });

  const leadInitialInfo = basicInfo.filter((filteredField) => {
    return filteredField.field_id > 0 && filteredField.field_id < 15;
  });

  const leadMessageRaw = basicInfo.filter((filteredField) => {
    return filteredField.field_id === 19;
  });

  let leadMessage = null;

  if (leadMessageRaw.length > 0) {
    leadMessage = leadMessageRaw[0];
  }

  const leadTypeInfo = basicInfo.filter((filteredField) => {
    return filteredField.field_id === 57;
  });

  let leadType = null;

  if (leadTypeInfo.length > 0) {
    leadType = leadTypeInfo[0];
  }

  let leadInfoForm = {
    group: [],
  };

  let initialAgentSelected =
    initialAgentFilter.length > 0
      ? initialAgentFilter[0].value !== null
        ? utility.decrypt(initialAgentFilter[0].value)
        : "Unassigned"
      : "Unassigned";

  basicInfo.map((basicVal) => {
    if (leadInfoForm.group.length === 0) {
      leadInfoForm.group.push({
        group_id: basicVal.group_id,
        field: [
          {
            field_id: basicVal.field_id,
            label: basicVal.label,
            type_id: basicVal.type_id,
            value: utility.decrypt(basicVal.value),
            sub_type: basicVal.sub_type,
            verified: basicVal.verify_type === "email" && leadData.verified,
          },
        ],
      });
    } else if (leadInfoForm.group.length > 0) {
      leadInfoForm.group.map((leadVal, index) => {
        if (leadVal.group_id === basicVal.group_id) {
          leadInfoForm.group[index].field.push({
            field_id: basicVal.field_id,
            label: basicVal.label,
            type_id: basicVal.type_id,
            value:
              basicVal.value !== null ? utility.decrypt(basicVal.value) : "",
            sub_type: basicVal.sub_type,
            verified: basicVal.verify_type === "email" && leadData.verified,
          });
        }
        return null;
      });

      const found = leadInfoForm.group.some(
        (el) => el.group_id === basicVal.group_id
      );
      if (!found) {
        leadInfoForm.group.push({
          group_id: basicVal.group_id,
          field: [
            {
              field_id: basicVal.field_id,
              label: basicVal.label,
              type_id: basicVal.type_id,
              value:
                basicVal.value !== null
                  ? utility.decrypt(basicVal.value)
                  : "",
              sub_type: basicVal.sub_type,
              verified: basicVal.verify_type === "email" && leadData.verified,
            },
          ],
        });
      }
    }
    return null;
  });
  return (
    <React.Fragment>
      <div
        className={
          projectId == 1 ? `information-box w-agent-box` : `information-box`
        }
      >
        <div
          className={
            projectId == 5 || projectId == 6
              ? "basic-info-box box-white-custom custom-info-box"
              : "basic-info-box box-white-custom"
          }
        >
          <div className="title-box">
            <i className="icon-lms icon-user"></i>
            <span>Lead Information</span>
          </div>

          <div className="custom-scroll-box">
            {leadLoaded ? (
              <React.Fragment>
                {projectId == 1 ? (
                  <div className="row info-group">
                    {leadType && (
                      <div className="col-md-6">
                        <label>{leadType.label}</label>
                        <span>
                          {leadType.value.length > 0
                            ? utility.decrypt(leadType.value)
                            : ""}
                        </span>
                      </div>
                    )}
                    {propertyInfo.map((data) => {
                      if (data.field_id === 29) {
                        return (
                          <div className="col-md-6" key={data.id}>
                            <label>{data.label}</label>
                            <span>
                              {handleTimeConvert(utility.decrypt(data.value))}
                            </span>
                          </div>
                        );
                      } else if (data.field_id === 28) {
                        return (
                          <div className="col-md-6" key={data.id}>
                            <label>{data.label}</label>
                            <span>
                              <Moment
                                date={utility.decrypt(data.value)}
                                format="LL"
                              />
                            </span>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-md-6" key={data.id}>
                            <label>{data.label}</label>
                            <span>{utility.decrypt(data.value)}</span>
                          </div>
                        );
                      }
                    })}
                    {leadMessage && (
                      <div className="col-md-6">
                        <label>{leadMessage.label}</label>
                        <span>
                          {leadMessage.value.length > 0
                            ? utility.decrypt(leadMessage.value)
                            : ""}
                        </span>
                      </div>
                    )}
                    {leadInitialInfo.map((data) => {
                      return (
                        <div className="col-md-6" key={data.id}>
                          <label>{data.label}</label>
                          <span>{utility.decrypt(data.value)}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <React.Fragment>
                    {renderFormGroups(leadInfoForm.group, state, setState, handleUpdate, props, leadData, dispatch)}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <MainInfoLoader />
            )}
          </div>
        </div>
        {projectId == 1 && (
          <div className="agent-info-box box-white-custom">
            <React.Fragment>
              <div className="title-box">
                <i className="icon-lms icon-agent"></i>
                <span>Agent Information</span>
              </div>
              <div className="agent-infos custom-scroll-box">
                {leadLoaded ? (
                  hasAgent && (
                    <InputSelect
                      name="selectedAgent"
                      defaultValue={
                        cancelViewingStatus
                          ? initialAgentSelected
                          : "Unassigned"
                      }
                      options={state.selectOptions}
                      onChange={handleAgentChange.bind(this)}
                    />
                  )
                ) : (
                  <AgentInfoLoader />
                )}

                <div
                  className={`${
                    state.isAgentChanged ? "show-me" : "hide-detail"
                  } agent-information-wrapper`}
                >
                  <div className="agent-changed">{renderAgentInfo(state)}</div>
                  {cancelViewingStatus && (
                    <div className="agent-static">
                      {agentStaticInfo.map((data) => {
                        return (
                          <React.Fragment key={data.id}>
                            {data.value !== null && (
                              <div
                                key={data.id}
                                className="input-wrap p--sm width--full"
                              >
                                <label>{data.label}</label>
                                <div>
                                  <span>{utility.decrypt(data.value)}</span>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                  <div className="btns-to-change">
                    <span className="cancel" onClick={cancelAgent}>
                      Cancel
                    </span>
                    <span
                      className="assign"
                      onClick={handleAgentAssign.bind(true)}
                    >
                      Assign{" "}
                    </span>
                  </div>
                  <div
                    className={`${state.isLoading ? "lds-ellipsis" : "none"}`}
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};