import React, { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import {projectActions, utmActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";

const LoadPresetDrawer = ( { isLoadPresetDrawer, onHandleCloseLoadPresetDrawer, onHandleLoadPresetValue } ) => {

    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        dispatch(utmActions.getPresets(params.id));
    }, [params.id]);

    const getPresetLoaded = useSelector(state => {
        const {utm} = state;
        return utm.getPresetLoaded;
    });

    const presets = useSelector(state => {
        const {utm} = state;
        if(getPresetLoaded) {
            console.log(utm);
            return utm.presets;
        }
        return [];
    });

    const [toggleTabState, setToggleTabState] = useState(1);

    const toggleTab = (index) => {
        setToggleTabState(index);
    }

    let activeClass = isLoadPresetDrawer ? 'active' : '';

    // toggle search preset
    const [isSearchToggle, setIsSearchToggle] = useState(false);
    const handleToggleSearch = () => {
        setIsSearchToggle(!isSearchToggle);
    }

    const [isRename, setIsRename] = useState(false);
    const [selectedPreset, setSelectedPreset] = useState({});
    const handleRenamePreset = (presetId) => {
        setIsRename(!isRename);
        setSelectedPreset(presetId);
    }


    function handleLoadPreset(preset) {
        onHandleCloseLoadPresetDrawer();
        onHandleLoadPresetValue(preset);
    }

    return (
        <>
            <div className={`drawer-overlay ${activeClass}`} onClick={onHandleCloseLoadPresetDrawer}></div>
            <div className={`side-drawer-main ${activeClass} load-preset-drawer`}>
                <div className='side-drawer-wrapper'>
                    <div className='side-drawer-title'>
                        <button className='side-drawer-close' onClick={onHandleCloseLoadPresetDrawer}><i className="fas fa-times"></i></button>
                        <h3>Load Preset</h3>
                    </div>
                    <div className='side-drawer-tabs'>
                        <div className='side-drawer-tabs-wrapper'>
                            <div className='control-tabs-box'>
                                <div className={toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'} onClick={() => toggleTab(1)}>
                                    <p>
                                        Presets
                                    </p>
                                </div>
                                <div className='line-slider'></div>
                            </div>
                            <div className='content-tabs-box'>
                                <div className={toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                                    <div className='utm-load-preset-tab'>
                                        {
                                            getPresetLoaded && presets.length > 0 &&
                                            <>
                                                <div className='load-preset-top'>
                                                    <div className='preset-top-controls'>
                                                        <div className='preset-col-1'>
                                                            <div className="checkbox-group">
                                                                <label htmlFor="presetName">PRESET NAME</label>
                                                            </div>
                                                        </div>
                                                        <div className='preset-col-1'>
                                                            <div className='btn-group'>
                                                                <span className='ctrl-btn preset-search' onClick={handleToggleSearch}>
                                                                    <i className="fas fa-search"></i>
                                                                    Search
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        isSearchToggle &&
                                                        <div className='preset-top-search'>
                                                            <div className='load-preset-search'>
                                                                <i className="fas fa-search"></i>
                                                                <input type="text" placeholder="Search a Preset" />
                                                                <i className="fas fa-times" onClick={handleToggleSearch}></i>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='load-preset-bottom'>
                                                    <div className='preset-item-list'>
                                                        {
                                                            presets.map((preset, id) => {
                                                                return (<div className='preset-item' key={id}>
                                                                    <div className="checkbox-group">
                                                                            <label
                                                                                htmlFor={preset.name}
                                                                            >
                                                                                {preset.name}
                                                                                <div className='preset-info'>
                                                                                    <p className='preset-info-title'><strong>{preset.name}</strong></p>
                                                                                    <div className='preset-info-list'>
                                                                                        <div className='list-outer'>
                                                                                            <p>Source<span>{preset.utm_source}</span></p>
                                                                                        </div>
                                                                                        <div className='list-outer'>
                                                                                            <p>Medium<span>{preset.utm_medium}</span></p>
                                                                                        </div>
                                                                                        <div className='list-outer'>
                                                                                            <p>Campaign Objective<span>{preset.campaign_objective}</span></p>
                                                                                        </div>
                                                                                        <div className='list-outer'>
                                                                                            <p>Campaign Audience Description<span>{preset.campaign_audience_description}</span></p>
                                                                                        </div>
                                                                                        <div className='list-outer'>
                                                                                            <p>Campaign Name</p>
                                                                                            <div className='list-inner'>
                                                                                                <p>Objective<span>{preset.campaign_name_objective}</span></p>
                                                                                            </div>
                                                                                            <div className='list-inner'>
                                                                                                <p>Channel<span>{preset.campaign_name_channel}</span></p>
                                                                                            </div>
                                                                                            <div className='list-inner'>
                                                                                                <p>Content/Keyword Type<span>{preset.campaign_name_content}</span></p>
                                                                                            </div>
                                                                                            <div className='list-inner'>
                                                                                                <p>Publishing Schedule<span>{preset.campaign_name_schedule}</span></p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='list-outer'>
                                                                                            <p>Content Ad Format<span>{preset.utm_content}</span></p>
                                                                                        </div>
                                                                                        <div className='list-outer'>
                                                                                            <p>Content Ad Version<span>{preset.ad_version}</span></p>
                                                                                        </div>
                                                                                        <div className='list-outer'>
                                                                                            <p>Term<span>{preset.utm_term}</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                    </div>
                                                                    {
                                                                        isRename && selectedPreset === id &&
                                                                        <div className='input-box'>
                                                                            <input type="text" value={preset.name} name='rename' />
                                                                            <button className='background-btn'>Save</button>
                                                                        </div>
                                                                    }
                                                                    <div className='btn-group'>
                                                                        <button className='background-btn' onClick={() => handleLoadPreset(preset)}>
                                                                            LOAD
                                                                        </button>
                                                                        <button className='background-btn gray-bg icon-only' onClick={() => handleRenamePreset(id)}>
                                                                            <i className="fas fa-pen"></i>
                                                                        </button>
                                                                        <button className='background-btn red-bg icon-only'>
                                                                            <i className="fas fa-trash-alt"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>);
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            getPresetLoaded && presets.length === 0 &&
                                            <div className='empty-preset'>
                                                <p>
                                                    No Available Preset.
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default LoadPresetDrawer

