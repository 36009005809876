import React from "react";

/**
 * TableMainLoader component renders a skeleton loader for a table.
 * It displays a loading animation to indicate that table data is being loaded.
 *
 * @component
 * @example
 * return (
 *   <TableMainLoader />
 * )
 */
const TableMainLoader = () => {
  return (
    <div className="skeleton-loader skeleton-table-main">
      <div className="skeleton skeleton-box"></div>
    </div>
  );
};

export default TableMainLoader;
