import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { ref, onValue } from "firebase/database";
import { db } from "firebase";

import { formActions, leadActions, projectActions } from "_actions";
import { utilitiesService as utility } from "_services";
import { MDBRow } from "mdbreact";
import { Button } from "blackbox-react";
import TopBar from "../../_components/TopBar";
import ProjectDetails from "../../_components/Drawer/ProjectDetails";
import ProjectMembers from "../../_components/Drawer/ProjectMembers";
import { KpiHeader } from "./components/Kpi/KpiHeader";
import { LeadViewList } from "./components/Table/LeadViewList";
import { KpiControl } from "./components/Kpi/KpiControl";
import AddLeadDrawer from "./components/Drawer/AddLeadDrawer";
import DefaultTableHeader from "_pages/Leads/components/Table/DefaultTableHeader";
import { TablePagination } from "_pages/Leads/components/Table/TablePagination";
import TableActions from "_pages/Leads/components/Table/TableActions";

import ManageDecksDrawer from "../Projects/components/members/ManageDecksDrawer";
import HandleDeckDrawer from "../Projects/components/members/HandleDeckDrawer";

import moment from "moment";
import ButtonGroupLoader from "../../_components/Loader/ButtonGroupLoader";
import TableMainLoader from "../../_components/Loader/TableMainLoader";
import BulkUploadDrawer from "./components/Drawer/BulkUploadDrawer";
import { getParsedSessionItem } from "../../_helpers/utils";

export const Leads = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: projectId } = useParams();

  const {
    projects,
    project,
    leads,
    leadsLoading,
    leadsLoaded,
    leadData,
    reports,
    authentication,
    summary,
  } = useSelector((state) => ({
    projects: state.projects,
    project: state.projects.project,
    leads: state.leads,
    leadsLoading: state.leads.leadsLoading,
    leadsLoaded: state.leads.leadsLoaded,
    leadData: state.leads.leadData,
    reports: state.reports,
    authentication: state.authentication,
    summary: state.summary,
  }));

  const [state, setState] = useState({
    exportData: false,
    isTableActionBox: false,
    isManageDecksDrawer: false,
    isHandleDecksDrawer: false,
    isDeckDeleteConfirmationModal: false,
    isProjectDetailsDrawer: false,
    isProjectMembersDrawer: false,
    isKpiHeaderExpanded: true,
    isAddLeadDrawer: false,
    leadSyncStatus: "DONE",
    leadSyncData: {},
    selectedCategory: null,
    selectedColor: null,
    pageSize: getParsedSessionItem(`lead_view_size_${projectId}`, 15),
    currentPage: getParsedSessionItem(`lead_view_page_${projectId}`, 1),
    selectedStatus: getParsedSessionItem(`lead_view_status_${projectId}`, []),
    dates: getParsedSessionItem(`lead_view_dates_${projectId}`, []),
    sortField: "updated",
    sortDirection: "",
    query: getParsedSessionItem(`lead_view_query_${projectId}`, null),
    assigned: getParsedSessionItem(`lead_view_assigned_${projectId}`, null),
    origin: getParsedSessionItem(`lead_view_origin_${projectId}`, null),
    deck: {},
    selectedLeadIds: [],
    headerCheckboxChecked: false,
    isBulkUploadDrawer: false,
  });

  useEffect(() => {
    if (parseInt(projectId) === 3) {
      const thamRef = ref(db, "THAM");
      onValue(thamRef, (snapshot) => {
        const data = snapshot.val();
        setState((prev) => ({
          ...prev,
          leadSyncStatus: data.status,
          leadSyncData: data,
        }));
      });
    }

    dispatch(projectActions.getProject(projectId));
    dispatch(leadActions.getOrigin(projectId));
    handleUpdateList();
  }, [projectId, dispatch]);

  useEffect(() => {
    if (state.headerCheckboxChecked && state.selectedLeadIds.length === 0) {
      setState((prev) => ({ ...prev, isTableActionBox: false }));
    }
  }, [state.headerCheckboxChecked, state.selectedLeadIds]);

  const handleUpdateList = () => {
    const { currentPage, pageSize, dates, query, selectedStatus, assigned, origin, sortField, sortDirection } = state;

    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    dispatch(leadActions.getLeadSummary(projectId, dateFrom, dateTo));
    dispatch(
      leadActions.getLeadsPaginated(
        projectId,
        currentPage,
        pageSize,
        query,
        selectedStatus,
        origin,
        assigned,
        dateFrom,
        dateTo,
        sortField,
        sortDirection
      )
    );
  };

  const handleSortChange = (field, direction) => {
    const { dates, selectedStatus, query, origin, assigned, pageSize } = state;

    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    localStorage.setItem(`lead_view_sortField_${projectId}`, field);
    localStorage.setItem(`lead_view_sortDirection_${projectId}`, direction);
    localStorage.setItem(`lead_view_page_${projectId}`, 1);

    setState((prev) => ({
      ...prev,
      sortField: field,
      sortDirection: direction,
      currentPage: 1,
    }));

    dispatch(
      leadActions.getLeadsPaginated(
        projectId,
        1,
        pageSize,
        query,
        selectedStatus,
        origin,
        assigned,
        dateFrom,
        dateTo,
        field,
        direction
      )
    );
  };

  const handlePageChange = (pageNum) => {
    const { dates, selectedStatus, sortField, sortDirection, query, pageSize, origin, assigned } = state;

    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    localStorage.setItem(`lead_view_page_${projectId}`, pageNum);

    setState((prev) => ({ ...prev, currentPage: pageNum }));

    dispatch(
      leadActions.getLeadsPaginated(
        projectId,
        pageNum,
        pageSize,
        query,
        selectedStatus,
        origin,
        assigned,
        dateFrom,
        dateTo,
        sortField,
        sortDirection
      )
    );
  };

  const handleDateChange = (dates) => {
    const { selectedStatus, sortField, sortDirection, query, pageSize, origin, assigned } = state;

    localStorage.setItem(`lead_view_page_${projectId}`, 1);
    localStorage.setItem(`lead_view_dates_${projectId}`, dates);

    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    setState((prev) => ({ ...prev, dates, currentPage: 1 }));

    dispatch(
      leadActions.getLeadsPaginated(
        projectId,
        1,
        pageSize,
        query,
        selectedStatus,
        origin,
        assigned,
        dateFrom,
        dateTo,
        sortField,
        sortDirection
      )
    );
    dispatch(leadActions.getLeadSummary(projectId, dateFrom, dateTo));
  };

  const handleStatusChange = (statuses, origin, assigned) => {
    const { dates, sortField, sortDirection, query, pageSize } = state;

    localStorage.setItem(`lead_view_page_${projectId}`, 1);
    localStorage.setItem(`lead_view_status_${projectId}`, statuses);
    localStorage.setItem(`lead_view_origin_${projectId}`, origin);
    localStorage.setItem(`lead_view_assigned_${projectId}`, assigned);

    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    setState((prev) => ({
      ...prev,
      selectedStatus: statuses,
      origin,
      assigned,
      currentPage: 1,
    }));

    dispatch(
      leadActions.getLeadsPaginated(
        projectId,
        1,
        pageSize,
        query,
        statuses,
        origin,
        assigned,
        dateFrom,
        dateTo,
        sortField,
        sortDirection
      )
    );
  };

  const handleSearch = (query) => {
    const { dates, sortField, sortDirection, selectedStatus, origin, assigned, pageSize } = state;

    localStorage.setItem(`lead_view_query_${projectId}`, query);
    localStorage.setItem(`lead_view_page_${projectId}`, 1);

    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    setState((prev) => ({ ...prev, query, currentPage: 1 }));

    dispatch(
      leadActions.getLeadsPaginated(
        projectId,
        1,
        pageSize,
        query,
        selectedStatus,
        origin,
        assigned,
        dateFrom,
        dateTo,
        sortField,
        sortDirection
      )
    );
  };

  const handleSizeChange = (size) => {
    const { dates, selectedStatus, sortField, sortDirection, query, origin, assigned, currentPage } = state;

    localStorage.setItem(`lead_view_size_${projectId}`, size);

    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
      dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
    }

    setState((prev) => ({ ...prev, pageSize: size }));

    dispatch(
      leadActions.getLeadsPaginated(
        projectId,
        currentPage,
        size,
        query,
        selectedStatus,
        origin,
        assigned,
        dateFrom,
        dateTo,
        sortField,
        sortDirection
      )
    );
  };

  const handleReset = () => {
    localStorage.removeItem(`lead_view_page_${projectId}`);
    localStorage.removeItem(`lead_view_size_${projectId}`);
    localStorage.removeItem(`lead_view_query_${projectId}`);
    localStorage.removeItem(`lead_view_status_${projectId}`);
    localStorage.removeItem(`lead_view_dates_${projectId}`);
    localStorage.removeItem(`lead_view_assigned_${projectId}`);
    localStorage.removeItem(`lead_view_origin_${projectId}`);
    localStorage.removeItem(`lead_view_sortField_${projectId}`);
    localStorage.removeItem(`lead_view_sortDirection_${projectId}`);

    setState((prev) => ({
      ...prev,
      query: null,
      selectedStatus: [],
      currentPage: 1,
      pageSize: 15,
      dates: null,
      selectedCategory: null,
      sortField: null,
      sortDirection: null,
      origin: [],
      assigned: [],
    }));

    dispatch(
      leadActions.getLeadsPaginated(projectId, 1, 15, null, [], [], [], null, null, null, null)
    );
    dispatch(leadActions.getLeadSummary(projectId, null, null));
  };

  const handleKpiHeaderChanged = (isExpanded) => {
    setState((prev) => ({ ...prev, isKpiHeaderExpanded: isExpanded }));
  };

  const handleProjectDetailsDrawer = () => {
    setState((prev) => ({ ...prev, isProjectDetailsDrawer: !prev.isProjectDetailsDrawer }));
  };

  const handleProjectMembersDrawer = () => {
    setState((prev) => ({ ...prev, isProjectMembersDrawer: !prev.isProjectMembersDrawer }));
  };

  const handleAddLeadDrawer = () => {
    setState((prev) => ({ ...prev, isAddLeadDrawer: !prev.isAddLeadDrawer }));
  };

  const handleBulkUploadDrawer = () => {
    setState((prev) => ({ ...prev, isBulkUploadDrawer: !prev.isBulkUploadDrawer }));
  };

  const handleManageDecksDrawer = () => {
    setState((prev) => ({ ...prev, isManageDecksDrawer: !prev.isManageDecksDrawer }));
  };

  const handleDecksDrawer = (deckItem) => {
    deckItem = deckItem ?? {};

    if (deckItem.id === state.deck?.id) {
      setState((prev) => ({
        ...prev,
        isHandleDecksDrawer: !prev.isHandleDecksDrawer,
        deck: prev.deck === deckItem ? {} : deckItem,
      }));
    } else {
      setState((prev) => ({ ...prev, isHandleDecksDrawer: true, deck: deckItem }));
    }
  };

  const handleCloseDeckDrawer = () => {
    setState((prev) => ({ ...prev, isHandleDecksDrawer: false }));
  };

  const handleOpenTableAction = (selectedLeadIds, allLeadsSelected) => {
    setState((prev) => ({
      ...prev,
      isTableActionBox: selectedLeadIds.length > 0,
      selectedLeadIds,
      headerCheckboxChecked: allLeadsSelected ? true : false,
    }));
  };

  const handleMasterCheckboxChange = () => {
    const allLeadIds = leads.leads?.data?.content?.map((lead) => lead.id) || [];
    setState((prev) => ({
      ...prev,
      headerCheckboxChecked: !prev.headerCheckboxChecked,
      selectedLeadIds: prev.headerCheckboxChecked ? [] : allLeadIds,
    }));
  };

  const handleCloseTableAction = () => {
    setState((prev) => ({
      ...prev,
      isTableActionBox: false,
      headerCheckboxChecked: false,
      selectedLeadIds: [],
    }));
  };

  const handleBatchUpdate = () => {
    setState((prev) => ({ ...prev, isTableActionBox: false, headerCheckboxChecked: false }));
  };

  const handleDownloadReport = () => {
    const { dates, selectedStatus } = state;

    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      try {
        dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
        dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
      } catch (error) {
        
      }
    }

    dispatch(leadActions.getLeadReport(projectId, selectedStatus, dateFrom, dateTo));
  };

  const handleDownloadGoogleSheetReport = () => {
    const { dates, selectedStatus } = state;
    let dateFrom = null;
    let dateTo = null;

    if (dates) {
      try {
        dateFrom = moment(new Date(dates[0])).format("YYYY-MM-DD");
        dateTo = moment(new Date(dates[1])).format("YYYY-MM-DD");
      } catch (error) {
        
      }
    }
    dispatch(leadActions.getLeadGoogleSheetReport(projectId, selectedStatus, dateFrom, dateTo));
  }

  const handleDownloadPatientReport = () => {
    dispatch(leadActions.getPatientReport());
  };

  const handleSyncData = () => {
    dispatch(leadActions.getLeadToPatientConversion());
  };

  const {
    isKpiHeaderExpanded,
    isProjectDetailsDrawer,
    isProjectMembersDrawer,
    isAddLeadDrawer,
    isManageDecksDrawer,
    isHandleDecksDrawer,
    isTableActionBox,
    deck,
    headerCheckboxChecked,
    selectedLeadIds,
    pageSize,
    isBulkUploadDrawer,
    leadSyncStatus,
    leadSyncData,
    selectedStatus,
    selectedCategory,
    selectedColor,
    dates,
    sortField,
    sortDirection,
    query,
  } = state;

  const list = leadsLoaded ? leads.leads?.data?.content : [];
  const loadingReport = reports.loadingReport || leadSyncStatus === "PROCESSING" ? "active" : "";
  const deckAdmin = project && authentication && project.data.owner.account.email === authentication.user.data.email;

  const handleKpiHeaderFilter = (items, key, color) => {
    setState({ ...state, selectedCategory: key, selectedColor: color });
    let selectedStatus = [];
    items.forEach((i) => {
      selectedStatus.push(i.id);
    });
    handleStatusChange(selectedStatus, [], []);
  };

  return (
    <React.Fragment>
      <div className={`download-box-indicator ${loadingReport}`}>
        <MDBRow className="m--0 align-items-center">
          {leadSyncStatus === "PROCESSING" ? (
            <div className="download-data">
              <div className="download-info">
                <span>{leadSyncData.currentStepDescription}</span>
                {leadSyncData.totalItem && (
                  <React.Fragment>
                    (<span>{leadSyncData.processedItem} / </span>
                    <span>{leadSyncData.totalItem}</span>)
                  </React.Fragment>
                )}
              </div>
              <div>
                <div className="spinner"></div>
              </div>
            </div>
          ) : (
            <div className="download-data">
              <div className="download-info">
                <span>Exporting Leads ...</span>
              </div>
              <div>
                <div className="spinner"></div>
              </div>
            </div>
          )}
        </MDBRow>
      </div>
      <div className="topbar-menu">
        <MDBRow className="row align-items-center">
          <TopBar
            leadLoaded={leadsLoaded}
            onHandleProjectDetailsDrawer={handleProjectDetailsDrawer}
            onHandleProjectMembersDrawer={handleProjectMembersDrawer}
          />

          <div className="col">
            {leadsLoading && <ButtonGroupLoader />}
            {leadsLoaded && (
              <div className="top-button-box">
                {parseInt(projectId) === 3 && leadSyncStatus !== "PROCESSING" && (
                  <React.Fragment>
                    <Button
                      className="c-btn c-btn-light"
                      enabled={reports.loadingReport}
                      onClick={handleSyncData}
                    >
                      <i className="icon-lms icon-sync"></i>Sync Patient
                    </Button>
                    <Button
                      className="c-btn c-btn-light"
                      enabled={reports.loadingReport}
                      onClick={handleDownloadPatientReport}
                    >
                      <i className="icon-lms icon-download"></i>Download Patient Report
                    </Button>
                  </React.Fragment>
                )}
                {projectId == 5 && deckAdmin && (
                  <button className="c-btn c-btn-light" onClick={handleManageDecksDrawer}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-presentation"
                      width="17"
                      height="17"
                      viewBox="0 0 21 21"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 4l18 0" />
                      <path d="M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-10" />
                      <path d="M12 16l0 4" />
                      <path d="M9 20l6 0" />
                      <path d="M8 12l3 -3l2 2l3 -3" />
                    </svg>
                    Manage Decks
                  </button>
                )}
                {projectId == 6 && (
                  <Button
                    className="c-btn c-btn-light"
                    enabled={reports.loadingReport}
                    onClick={handleBulkUploadDrawer}
                  >
                    <i className="icon-lms icon-export"></i>Bulk Upload
                  </Button>
                )}
                {projectId == 5 && <Button
                    className="c-btn c-btn-light"
                    enabled={reports.loadingReport}
                    onClick={handleBulkUploadDrawer}
                >
                  <i className="icon-lms icon-export"></i>Bulk Upload
                </Button> }
                {projectId == 5 && (
                  <Link
                    className="c-btn c-btn-light"
                    to={`/project/${projectId}/manage-email-templates`}
                  >
                    <i className="icon-lms icon-envelope-o"></i>Manage Email Templates
                  </Link>
                )}
                <Button
                  className="c-btn c-btn-light"
                  enabled={reports.loadingReport}
                  onClick={handleDownloadReport}
                >
                  <i className="icon-lms icon-export"></i>CSV Export
                </Button>
                {projectId == 5 && <Button
                    className="c-btn c-btn-light"
                    enabled={reports.loadingReport}
                    onClick={() => handleDownloadGoogleSheetReport()}
                >
                  <i className="icon-lms icon-export"></i>Google Sheet Export
                </Button>}
                <Button className="c-btn" onClick={handleAddLeadDrawer}>
                  <i className="icon-lms icon-add"></i>Add Lead
                </Button>
              </div>
            )}
          </div>
        </MDBRow>
      </div>

      <div className="overview-menu">
        <KpiControl
          projectId={projectId}
          selectedStatus={selectedStatus}
          selectedCategory={selectedCategory}
          selectedColor={selectedColor}
          dates={dates}
          onHandlePageChanged={handlePageChange}
          onHandleDateChanged={handleDateChange}
          onHandleStatusChanged={handleStatusChange}
          onHandleSearch={handleSearch}
          onHandleSizeChanged={handleSizeChange}
          onHandleKpiHeaderFilter={handleKpiHeaderFilter}
          onHandleKpiHeaderHeightChanged={handleKpiHeaderChanged}
        />
      </div>

      <KpiHeader
        onHandleKpiHeaderHeightChanged={handleKpiHeaderChanged}
        onHandleKpiHeaderFilter={handleKpiHeaderFilter}
        onHandleReset={handleReset}
        isKpiHeaderExpanded={isKpiHeaderExpanded}
      />

      <div className="main-table-section">
        <div className="table-view table-leads">
          <div className={`table-scroll ${isKpiHeaderExpanded ? "active" : ""}`}>
            {leadsLoading && <TableMainLoader />}
            {leadsLoaded && list && list.length > 0 && (
              <table id="table-form">
                <thead>
                  <DefaultTableHeader
                    projectId={projectId}
                    field={sortField}
                    direction={sortDirection}
                    onHandleSortChange={handleSortChange}
                    onHandleOpenTableAction={handleOpenTableAction}
                    headerCheckboxChecked={headerCheckboxChecked}
                    onMasterCheckboxChange={handleMasterCheckboxChange}
                  />
                </thead>
                <tbody>
                  <LeadViewList
                    projectId={projectId}
                    onHandleOpenTableAction={handleOpenTableAction}
                    headerCheckboxChecked={headerCheckboxChecked}
                    selectedLeadIds={selectedLeadIds}
                    isTableActionBox={isTableActionBox}
                  />
                </tbody>
              </table>
            )}

            {leadsLoaded && list.length === 0 && (
              <div className="table-empty-box">
                <div>
                  <h3>No Leads Found</h3>
                  <p>
                    Leads Information will appear here <br />
                    Luckily, you can add lead manually
                  </p>
                  <span onClick={handleAddLeadDrawer}>
                    <i className="icon-lms icon-add"></i>
                    Add Lead
                  </span>
                </div>
              </div>
            )}
          </div>
          <TableActions
            isTableActionBox={isTableActionBox}
            handleCloseTableAction={handleCloseTableAction}
            managers={project ? project.data.members : []}
            status={summary && summary.page ? summary.page.data : []}
            selectedLeadIds={selectedLeadIds}
            pageSize={pageSize}
            batchUpdate={handleBatchUpdate}
          />
        </div>
        <TablePagination
          projectId={projectId}
          onHandlePageChanged={handlePageChange}
          onHandleSizeChanged={handleSizeChange}
        />
      </div>

      {/* Drawers */}
      <ProjectDetails
        isProjectDetailsDrawer={isProjectDetailsDrawer}
        data={project}
        onHandleProjectDetailsDrawer={handleProjectDetailsDrawer}
      />

      <ProjectMembers
        isProjectMembersDrawer={isProjectMembersDrawer}
        onHandleProjectMembersDrawer={handleProjectMembersDrawer}
      />

      <AddLeadDrawer
        projectId={projectId}
        isAddLeadDrawer={isAddLeadDrawer}
        onHandleAddLeadDrawer={handleAddLeadDrawer}
      />

      <BulkUploadDrawer
        projectId={projectId}
        pageSize={pageSize}
        isBulkUploadDrawer={isBulkUploadDrawer}
        onHandleBulkUploadDrawer={handleBulkUploadDrawer}
      />

      <ManageDecksDrawer
        isManageDecksDrawer={isManageDecksDrawer}
        isHandleDecksDrawer={isHandleDecksDrawer}
        onHandleManageDecksDrawer={handleManageDecksDrawer}
        onHandleCloseManageDecksDrawer={() =>
          setState((prev) => ({ ...prev, isManageDecksDrawer: false, isHandleDecksDrawer: false }))
        }
        onHandleDecksDrawer={handleDecksDrawer}
        deck={deck}
      />

      <HandleDeckDrawer
        isHandleDecksDrawer={isHandleDecksDrawer}
        onHandleDecksDrawer={handleDecksDrawer}
        onHandleCloseDrawer={handleCloseDeckDrawer}
        deck={deck}
      />
    </React.Fragment>
  );
};