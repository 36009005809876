import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalBody } from 'mdbreact';
import { useSelector } from 'react-redux';

function DeleteConfirmationModal({ modalOpen, statusToDelete, deleteNum, onApprove, toggle, nameToDelete }) {
  const [deleting, setDeleting] = useState(false);

  // Select alert from Redux store
  const alertMessage = useSelector((state) => state.alert.message);

  // Effect to monitor changes in alertMessage and modalOpen
  useEffect(() => {
    if (alertMessage && deleting) {
      setDeleting(false);  // Reset deleting state when alert message is available
      toggle(deleteNum);   // Close the modal
    }
  }, [alertMessage, deleting, deleteNum, toggle]);

  // Effect to handle modal open/close
  useEffect(() => {
    if (!modalOpen) {
      setDeleting(false); // Reset deleting state when modal is closed
    }
  }, [modalOpen]);

  const handleOnApprove = () => {
    setDeleting(true);
    onApprove(statusToDelete);
  };

  let name = '';
  if (statusToDelete) {
    name = statusToDelete.name;
  }

  return (
    <MDBContainer className="modal-container confirmation-modal-container">
      <MDBRow>
        <MDBCol>
          <MDBModal backdrop={false} className="confirmation-modal" toggle={toggle} isOpen={modalOpen} centered>
            <MDBModalBody>
              <MDBRow className="form-group-row">
                <MDBCol className="col-12">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle">
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="8" x2="12" y2="12" />
                    <line x1="12" y1="16" x2="12.01" y2="16" />
                  </svg>
                  <h3>Confirm Deletion</h3>
                  <p>
                    Are you sure you want to delete lead
                    <br />
                    {deleteNum === 'bulk' ? (
                      <span className="bulk">
                        &nbsp;{statusToDelete.length > 1 ? 'these' : 'this'} {statusToDelete.length} {statusToDelete.length > 1 ? 'items' : 'item'}
                      </span>
                    ) : (
                      <span className="single"> {nameToDelete || name}</span>
                    )}
                    ?
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="modal-btns text-center">
                  {!deleting && (
                    <button className="c-btn c-btn-light" onClick={toggle}>
                      Cancel
                    </button>
                  )}
                  {!deleting && (
                    <button className="c-btn c-btn-red" onClick={handleOnApprove}>
                      Delete
                    </button>
                  )}
                  {deleting && <span>Deleting please wait...</span>}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default DeleteConfirmationModal;
