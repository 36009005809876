import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { influencerActions } from "_actions";
import Button from "_pages/Influencer/components/General/Button";
import { getInfluencerTypeClassColor, getInfluencerTypeClassIcon } from "_services";

export const RecordTab = ({ classN, projectId, influencerId, onHandleManageRecordsModal, onHandleOpenConfirmationModal }) => {
  const dispatch = useDispatch();
  const { influencers } = useSelector((state) => state);
  const { getInfluencerDocumentLoading, getInfluencerDocumentLoaded, documents } = influencers;

  useEffect(() => {
    dispatch(influencerActions.getInfluencerDocument(projectId, influencerId));
  }, [dispatch, projectId, influencerId]);

  const openAddRecordModal = () => {
    onHandleManageRecordsModal("add", null);
  };

  const openEditRecordModal = (record) => {
    onHandleManageRecordsModal("edit", record);
  };

  const openDeleteConfirmationModal = (type, record) => {
    onHandleOpenConfirmationModal(type, record);
  };

  const records = getInfluencerDocumentLoaded ? documents : [];
  const hasRecords = records.length > 0;

  return (
    <div className={classN}>
      <div className="inner-title-box">
        <p>Document Links</p>
        {getInfluencerDocumentLoaded && hasRecords && (
          <Button
            text="Add Record"
            icon="icon-lms icon-add"
            onClick={openAddRecordModal}
            classN="plain-btn"
          />
        )}
      </div>

      {getInfluencerDocumentLoaded && hasRecords && (
        <div className="records-list">
          {records.map((record, index) => (
            <div className="record-col" key={index}>
              <div className="record">
                <div className="record-details">
                  <div className="record-info">
                    <span className="description">Title</span>
                    <p className="title">{record.title}</p>
                  </div>
                  <div className="record-info">
                    <span className="description">Description</span>
                    <p className="title">{record.description}</p>
                  </div>
                  <div className="record-info">
                    <span className="description">Document Link</span>
                    <p className="title">
                      <a href={record.link} target="_blank" rel="noopener noreferrer">
                        {record.link}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="record-btns">
                  <button
                    className="c-btn c-btn-sm c-btn-light edit-record"
                    onClick={() => openEditRecordModal(record)}
                  >
                    <i className="icon-lms icon-edit"></i>Edit
                  </button>
                  <button
                    className="c-btn c-btn-sm c-btn-light delete-record"
                    onClick={() => openDeleteConfirmationModal("record", record)}
                  >
                    <i className="icon-lms icon-delete"></i>Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {getInfluencerDocumentLoaded && !hasRecords && (
        <div className="empty-tab-content">
          <div className="empty-content">
            <h6>Add Your First Record</h6>
            <p>Records will appear here</p>
            <span onClick={openAddRecordModal}>
              <i className="icon-lms icon-add"></i>
              Add Record
            </span>
          </div>
        </div>
      )}

      {getInfluencerDocumentLoading && (
        <div className="loading-box">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
};