import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { projectActions } from '_actions';
import { MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import { priorityColor, statusBackgroundColor, responsibleColor, lastContactColor, priorityIcon } from '_pages/Leads/constants/BreadCrumbs';
import Moment from "react-moment";
import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal';

const LeadStatusList = ({ leadData, projectId, sort, deleteLeadStatus, createProjectDefaultStatus, createProjectConversionStatus, handleGetProjectStatus }) => {
  const [statusToDelete, setStatusToDelete] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [statusColor] = useState(['teal', 'blue', 'yellow', 'orange', 'red', 'green', 'dark-gray', 'lime', 'cyan', 'indigo', 'brown', 'purple']);

  const handleDefaultStatus = (status_id, status) => {
    if (leadData) {
      const project_id = projectId;
      const defaultStatus = { status };

      createProjectDefaultStatus(project_id, status_id, defaultStatus);

      setTimeout(() => {
        window.history.go();
      }, 2000);
    }
    renderFilteredTableBody();
  };

  const handleConversionSales = (status_id, sales) => {
    if (leadData) {
      const project_id = projectId;
      const conversionSales = { sales };

      createProjectConversionStatus(project_id, status_id, conversionSales);

      setTimeout(() => {
        window.history.go();
      }, 2000);
    }
    renderFilteredTableBody();
  };

  const handleToggle = () => setModalOpen(!modalOpen);

  const approveModal = (statusToDelete) => {
    const project = JSON.parse(localStorage.getItem('project'));
    const projectId = project.id;
    const { id: statusId, name: statusName } = statusToDelete;

    if (statusId && statusName) {
      deleteLeadStatus(statusToDelete, projectId, statusId);
      setStatusToDelete({}, handleGetProjectStatus);
    }
  };

  const sortView = (data) => {
    switch (sort) {
      case 'leadStatus_az':
        return data.sort((a, b) => a.name.localeCompare(b.name));
      case 'leadStatus_za':
        return data.sort((a, b) => b.name.localeCompare(a.name));
      case 'description_az':
        return data.sort((a, b) => a.description.localeCompare(b.description));
      case 'description_za':
        return data.sort((a, b) => b.description.localeCompare(a.description));
      case 'dateCreated_latest':
        return data.sort((a, b) => b.id - a.id);
      case 'dateCreated_oldest':
        return data.sort((a, b) => a.id - b.id);
      default:
        return data;
    }
  };

  const handlePassData = (data) => setStatusToDelete(data);

  const renderFilteredTableBody = () => {
    let sortedLeadData = leadData ? sortView(leadData) : [];
    return sortedLeadData.map((value, index) => (
      <tr key={index}>
        <td className='border-table-right'>
          <div className='row d-flex pl-4 pr-3'>
            <div className={`bg--${statusColor[index]} lead-round mr-2`}></div>
            <span className='font-bold-black'>{value.name}</span>
          </div>
        </td>
        <td className='border-table-right'>
          <div className='col px-0 font-size-11'>
            {value.description}
          </div>
        </td>
        <td className=''>
          <div className='col px-3'>
            <p className='font-size-12 opacity-60 mb-0'>
              <Moment date={value.created} format="MM/DD/YYYY" />
            </p>
          </div>
        </td>
        <td>
          <MDBDropdown>
            <MDBDropdownToggle flat className='p-0 shadow-none m-0 lead-menu'>
              <MDBIcon icon='ellipsis-v' className='opacity-40 px-3 py-2 font-color-black' />
            </MDBDropdownToggle>
            <MDBDropdownMenu className='border-radius-3 border-unset box-shadow min-width-unset top-negative-3 py-0'>
              <MDBDropdownItem
                className='font-size-11 p-2 px-3'
                onClick={(event) => {
                  event.preventDefault();
                  handleDefaultStatus(value.id, value.id);
                }}>
                <MDBIcon far icon='paper-plane' className='mr-2'/>
                Set as Default Status
              </MDBDropdownItem>
              <MDBDropdownItem divider className='m-0' />
              <MDBDropdownItem
                className='font-size-11 p-2 px-3'
                onClick={(event) => {
                  event.preventDefault();
                  handleConversionSales(value.id, value.id);
                }}>
                <MDBIcon far icon='paper-plane' className='mr-2' />
                Set as Conversion to Sales
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </td>
      </tr>
    ));
  };

  return (
    <tbody>
      { renderFilteredTableBody() }
      <tr>
        <td colSpan='3'>
          <DeleteConfirmationModal 
            toggle={handleToggle} 
            modalOpen={modalOpen} 
            statusToDelete={statusToDelete} 
            onApprove={approveModal} 
            target={statusToDelete && statusToDelete.name}
          />
        </td>
      </tr>
    </tbody>
  );
};

const mapState = (state) => {
  const { projects, project } = state;
  return { projects, project };
};

const actionCreators = {
  deleteLeadStatus: projectActions.deleteLeadStatus,
  createProjectDefaultStatus: projectActions.createProjectDefaultStatus,
  createProjectConversionStatus: projectActions.createProjectConversionStatus
};

const connectedLeadStatusList = connect(mapState, actionCreators)(LeadStatusList);
export { connectedLeadStatusList as LeadStatusList };