import {managerService} from "_services";
import {alertActions} from "_actions/alert.actions";
import {managerConstants} from "_constants";
import { projectActions } from "./project.actions";

export const managerActions = {
    getManagers,
    getManagerConfig,
    deleteManager,
    updateManagerConfig,
    addManagerConfigParam,
    updateManagerConfigParam,
    deleteManagerConfigParam,
    getProjectConfigParam,
    updateManagerConfigsParam,
    getProjectConfigOperator
}

/**
 * Fetches the list of managers for a given project with optional query parameters.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string|null} [query=null] - Optional search query for filtering managers.
 * @param {number} [page=1] - The page number for pagination.
 * @param {number} [size=15] - The number of managers per page.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getManagers(projectId, query = null, page = 1, size = 15) {
    return dispatch => {
        dispatch(request(projectId, query, page, size));
        managerService.getManagers(projectId, query, page, size)
            .then(
                managers => {
                    console.log('updated managers')
                    dispatch(success(managers));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, query = null, page = 1, size = 15) {
        return {type: managerConstants.GET_ALL_MANAGERS_REQUEST, projectId, query, page, size}
    }

    function success(managers) {
        return {type: managerConstants.GET_ALL_MANAGERS_SUCCESS, managers}
    }

    function failure(error) {
        return {type: managerConstants.GET_ALL_MANAGERS_FAILURE, error}
    }
}

/**
 * Fetches the configuration details of a specific manager within a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getManagerConfig(projectId, managerId) {
    return dispatch => {
        dispatch(request(projectId, managerId));
        managerService.getManagerConfig(projectId, managerId)
            .then(
                config => {
                    dispatch(success(config));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId) {
        return {type: managerConstants.GET_MANAGER_CONFIG_REQUEST, projectId, managerId}
    }

    function success(config) {
        return {type: managerConstants.GET_MANAGER_CONFIG_SUCCESS, config}
    }

    function failure(error) {
        return {type: managerConstants.GET_MANAGER_CONFIG_FAILURE, error}
    }
}

/**
 * Deletes a manager from a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager to be removed.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function deleteManager(projectId, managerId) {
    return dispatch => {
        dispatch(request(projectId, managerId));
        managerService.deleteManager(projectId, managerId)
            .then(
                message => {
                    dispatch(getManagers(projectId));
                    dispatch(projectActions.getProject(projectId));
                    dispatch(success(message));
                    dispatch(alertActions.success(`Successfully removed user from the project.`));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId) {
        return {type: managerConstants.DELETE_MANAGER_REQUEST, projectId, managerId}
    }

    function success(message) {
        return {type: managerConstants.DELETE_MANAGER_SUCCESS, message}
    }

    function failure(error) {
        return {type: managerConstants.DELETE_MANAGER_FAILURE, error}
    }
}

/**
 * Updates the configuration of a manager in a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager whose config is being updated.
 * @param {Object} body - The updated configuration data.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updateManagerConfig(projectId, managerId, body) {
    return dispatch => {
        dispatch(request(projectId, managerId, body));
        managerService.updateManagerConfig(projectId, managerId, body)
            .then(
                config => {
                    dispatch(getManagers(projectId));
                    dispatch(success(config));
                    dispatch(alertActions.success(`Successfully updated manager config.`));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, body) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_REQUEST, projectId, managerId, body}
    }

    function success(config) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_SUCCESS, config}
    }

    function failure(error) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_FAILURE, error}
    }
}

/**
 * Adds a configuration parameter for a manager in a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager whose configuration is being updated.
 * @param {Object} body - The configuration parameter to be added.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function addManagerConfigParam(projectId, managerId, body) {
    return dispatch => {
        dispatch(request(projectId, managerId, body));
        managerService.addManagerConfigParam(projectId, managerId, body)
            .then(
                param => {
                    dispatch(success(param));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, body) {
        return {type: managerConstants.ADD_MANAGER_CONFIG_PARAM_REQUEST, projectId, managerId, body}
    }

    function success(param) {
        return {type: managerConstants.ADD_MANAGER_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.ADD_MANAGER_CONFIG_PARAM_FAILURE, error}
    }
}

/**
 * Updates a configuration parameter for a manager in a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager whose configuration parameter is being updated.
 * @param {string} paramId - The ID of the configuration parameter to be updated.
 * @param {Object} body - The updated configuration parameter data.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updateManagerConfigParam(projectId, managerId, paramId, body) {
    return dispatch => {
        dispatch(request(projectId, managerId, paramId, body));
        managerService.updateManagerConfigParam(projectId, managerId, paramId, body)
            .then(
                param => {
                    dispatch(success(param));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, paramId, body) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_REQUEST, projectId, managerId, paramId, body}
    }

    function success(param) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_FAILURE, error}
    }
}

/**
 * Updates multiple configuration parameters for a manager in a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager whose configuration parameters are being updated.
 * @param {Object} body - The updated configuration parameters data.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updateManagerConfigsParam(projectId, managerId, body) {
    return dispatch => {
        dispatch(request(projectId, managerId, body));
        managerService.updateManagerConfigsParam(projectId, managerId, body)
            .then(
                param => {
                    dispatch(getManagers(projectId));
                    dispatch(success(param));
                    dispatch(alertActions.success(`Successfully updated manager config.`));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, body) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_REQUEST, projectId, managerId, body}
    }

    function success(param) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.UPDATE_MANAGER_CONFIG_PARAM_FAILURE, error}
    }
}

/**
 * Deletes a specific configuration parameter for a manager in a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager whose configuration parameter is being deleted.
 * @param {string} paramId - The ID of the configuration parameter to be deleted.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function deleteManagerConfigParam(projectId, managerId, paramId) {
    return dispatch => {
        dispatch(request(projectId, managerId, paramId));
        managerService.deleteManagerConfigParam(projectId, managerId, paramId)
            .then(
                param => {
                    dispatch(success(param));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId, paramId) {
        return {type: managerConstants.DELETE_MANAGER_CONFIG_PARAM_REQUEST, projectId, managerId, paramId}
    }

    function success(param) {
        return {type: managerConstants.DELETE_MANAGER_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.DELETE_MANAGER_CONFIG_PARAM_FAILURE, error}
    }
}

/**
 * Retrieves configuration parameters for a specific manager within a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager whose configuration parameters are being fetched.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getProjectConfigParam(projectId, managerId) {
    return dispatch => {
        dispatch(request(projectId, managerId));
        managerService.getProjectConfigParam(projectId, managerId)
            .then(
                param => {
                    dispatch(success(param));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId) {
        return {type: managerConstants.GET_PROJECT_CONFIG_PARAM_REQUEST, projectId, managerId}
    }

    function success(param) {
        return {type: managerConstants.GET_PROJECT_CONFIG_PARAM_SUCCESS, param}
    }

    function failure(error) {
        return {type: managerConstants.GET_PROJECT_CONFIG_PARAM_FAILURE, error}
    }
}

/**
 * Retrieves configuration operators for a specific manager within a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} managerId - The ID of the manager whose configuration operators are being fetched.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getProjectConfigOperator(projectId, managerId) {
    return dispatch => {
        dispatch(request(projectId, managerId));
        managerService.getProjectConfigOperator(projectId, managerId)
            .then(
                operator => {
                    dispatch(success(operator));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, managerId) {
        return {type: managerConstants.GET_PROJECT_CONFIG_OPERATOR_REQUEST, projectId, managerId}
    }

    function success(operator) {
        return {type: managerConstants.GET_PROJECT_CONFIG_OPERATOR_SUCCESS, operator}
    }

    function failure(error) {
        return {type: managerConstants.GET_PROJECT_CONFIG_OPERATOR_FAILURE, error}
    }
}