import React from "react";

/**
 * BreadcrumbLoader component renders a skeleton loader for breadcrumb navigation.
 * It displays a series of skeleton boxes to indicate loading state.
 *
 * @component
 * @example
 * return (
 *   <BreadcrumbLoader />
 * )
 */
const BreadcrumbLoader = () => {
  return (
    <div className="skeleton-loader skeleton-breadcrumb">
        <div className="col-b">
            <div className="skeleton skeleton-box"></div>
        </div>
        <div className="col-b">
            <div className="skeleton skeleton-box btn-skeleton"></div>
            <div className="skeleton skeleton-box btn-skeleton"></div>
            <div className="skeleton skeleton-box btn-skeleton"></div>
        </div>
    </div>
  );
};

export default BreadcrumbLoader;
