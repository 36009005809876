import React, { useState } from 'react';
import { ButtonGroup, Button } from "blackbox-react";
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';

const DeleteConfirmationModal = ({ modalOpen, toggle, statusToDelete, nameToDelete, deleteNum, onApprove }) => {
  const [deleting, setDeleting] = useState(false);

  const handleOnApprove = () => {
    // Ensuring safe access to `statusToDelete`
    const status = statusToDelete;
    setTimeout(() => {
      onApprove(status);  // Call the onApprove function passed from props
      setDeleting(false);  // Reset deleting state
    }, 2000);
  };

  return (
    <MDBContainer className='modal-container confirmation-modal-container'>
      <MDBRow>
        <MDBCol>
          <MDBModal backdrop={false} className='confirmation-modal' toggle={toggle} isOpen={modalOpen} centered>
            <MDBModalBody>
              <MDBRow className='form-group-row'>
                <MDBCol className='col-12'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle">
                    <circle cx="12" cy="12" r="10"/>
                    <line x1="12" y1="8" x2="12" y2="12"/>
                    <line x1="12" y1="16" x2="12.01" y2="16"/>
                  </svg>
                  <h3>Confirm Deletion</h3>
                  <p>
                    Are you sure you want to delete 
                    { deleteNum === 'bulk' ? (
                      <span className='bulk'>
                        {statusToDelete?.length > 1 ? 'these' : 'this'} {statusToDelete?.length} {statusToDelete?.length > 1 ? 'items' : 'item'}
                      </span>
                    ) : (
                      <span className='single'> {nameToDelete || statusToDelete?.name}</span>
                    )}
                    ?
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className='modal-btns text-center'>
                  <button onClick={toggle} className='c-btn c-btn-light'>
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      setDeleting(true);  // Set deleting to true
                      handleOnApprove();  // Call approve handler
                    }}
                    className='c-btn c-btn-red'
                  >
                    Delete
                  </button>
                  {deleting && <span>Deleting please wait...</span>}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default DeleteConfirmationModal;
