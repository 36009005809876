import React, { useState } from 'react';
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact';
import Input from '_components/Input';
import logo from '_assets/images/logo.png';
import { Link } from 'react-router-dom';
import { userActions } from '_actions';
import { connect } from 'react-redux';
import { utilitiesService as utility } from '_services';
import { FormValidation } from '_components/FormValidation';
import { Button } from 'blackbox-react';

const _UpdatePassword = ({ updatePassword }) => {
  const [user, setUser] = useState({
    email: localStorage.getItem('email'),
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [formValid, setFormValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({
    oldPassword: 'Please enter your current password.',
    newPassword: 'Please enter your new password.',
    confirmPassword: 'Please enter your new password again.'
  });

  const [oldPasswordValid, setOldPasswordValid] = useState(false);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const encryptedValue = utility.encrypt(value);

    setUser((prevUser) => ({
      ...prevUser,
      [name]: encryptedValue
    }));

    validateField(name, encryptedValue);
  };

  const validateField = (fieldName, value) => {
    let decryptedValue = utility.decrypt(value);
    let fieldValidationErrors = { ...formErrors };

    let oldPasswordValid = oldPasswordValid;
    let newPasswordValid = newPasswordValid;
    let confirmPasswordValid = confirmPasswordValid;

    let isAllWhitespace = decryptedValue.replace(/(?!^ +$)^.+$/gm, "") !== '';

    switch (fieldName) {
      case 'oldPassword':
        if (decryptedValue === "" || isAllWhitespace) {
          oldPasswordValid = false;
          fieldValidationErrors.oldPassword = 'Please enter your old password.';
        } else {
          oldPasswordValid = decryptedValue.length >= 6;
          fieldValidationErrors.oldPassword = oldPasswordValid ? '' : 'Your Current password is incorrect. Try again.';
        }
        break;
      case 'newPassword':
        if (decryptedValue === "" || isAllWhitespace) {
          newPasswordValid = false;
          fieldValidationErrors.newPassword = 'Please enter your new password.';
        }
        if (decryptedValue === '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$') {
          newPasswordValid = false;
          fieldValidationErrors.newPassword = 'Your password should have the following: 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character';
        } else {
          newPasswordValid = (user.oldPassword !== decryptedValue);
          fieldValidationErrors.newPassword = newPasswordValid ? '' : 'Your new password is the same as your previous password. Please create a new password.';
        }
        break;
      case 'confirmPassword':
        if (decryptedValue === "" || isAllWhitespace) {
          confirmPasswordValid = false;
          fieldValidationErrors.confirmPassword = 'Please enter your new password again.';
        }
        if (decryptedValue === '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$') {
          confirmPasswordValid = false;
          fieldValidationErrors.confirmPassword = 'Your password should have the following: 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character';
        } else {
          confirmPasswordValid = (user.newPassword === decryptedValue);
          fieldValidationErrors.confirmPassword = confirmPasswordValid ? '' : 'Your passwords do not match, please enter the same password you entered before.';
        }
        break;
      default:
        break;
    }

    setFormErrors(fieldValidationErrors);
    setOldPasswordValid(oldPasswordValid);
    setNewPasswordValid(newPasswordValid);
    setConfirmPasswordValid(confirmPasswordValid);
    validateForm();
  };

  const validateForm = () => {
    setFormValid(oldPasswordValid && newPasswordValid && confirmPasswordValid);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    validateForm();
    setSubmitted(true);

    if (formValid) {
      const account = {
        email: user.email,
        old_password: user.oldPassword,
        new_password: user.newPassword,
        confirm_password: user.confirmPassword
      };

      updatePassword(account);
    }
  };

  const errorClass = (error) => (error.length === 0 ? 'is-passed' : 'is-failed');

  return (
    <MDBContainer fluid className='update-password-background'>
      <div className='update-password-panel'>
        <MDBRow>
          <MDBCol>
            <img src={logo} alt='Propelrr Platform' className='login-logo' style={{ width: '250' }} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <form onSubmit={handleUpdate}>
              <MDBRow>
                <MDBCol>
                  <div className={`form-group ${submitted && errorClass(formErrors.oldPassword)}`}>
                    <Input
                      id='old-password-input-forgot'
                      inputclassName={user.class ? 'custom-form-control' : 'custom-form-control'}
                      hint='Enter Old Password'
                      name='oldPassword'
                      type='password'
                      required={true}
                      value={user.oldPassword}
                      onChange={handleChange}
                    />
                    <div className={`form-validation__container ${!formValid && submitted ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='oldPassword' formErrors={formErrors} />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div className={`form-group ${submitted && errorClass(formErrors.newPassword)}`}>
                    <Input
                      id='new-password-input-forgot'
                      inputclassName={user.class ? 'custom-form-control' : 'custom-form-control'}
                      hint='Enter New Password'
                      name='newPassword'
                      type='password'
                      required={true}
                      value={user.newPassword}
                      onChange={handleChange}
                    />
                    <div className={`form-validation__container ${!formValid && submitted ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='newPassword' formErrors={formErrors} />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div className={`form-group ${submitted && errorClass(formErrors.confirmPassword)}`}>
                    <Input
                      id='confirm-password-input-forgot'
                      inputclassName={user.class ? 'custom-form-control' : 'custom-form-control'}
                      hint='Enter your password again'
                      name='confirmPassword'
                      type='password'
                      value={user.confirmPassword}
                      required={true}
                      onChange={handleChange}
                    />
                    <div className={`form-validation__container ${!formValid && submitted ? 'display-block' : 'display-none'}`}>
                      <FormValidation fieldName='confirmPassword' formErrors={formErrors} />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div className="form-group">
                    <Button type='submit' className='background-btn'>
                      Submit
                    </Button>
                    <Link to='/' className='bordered-btn'>
                      Cancel
                    </Link>
                  </div>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
};

const mapState = (state) => {
  const { updatingPassword } = state.users;
  return { updatingPassword };
};

const actionCreators = {
  updatePassword: userActions.updatePassword
};

const UpdatePassword = connect(mapState, actionCreators)(_UpdatePassword); 
export {UpdatePassword};