import { httpManagerService as httpManager } from '_services';

export const projectService = {
  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  updateStatus,
  getAllProjectMembers,
  addProjectMember,
  deleteProjectMember,
  getAllProjectStatus,
  createLeadStatus,
  findProjectStatus,
  findProjectStatusCount,
  updateLeadStatus,
  deleteLeadStatus,
  enableProjectStatus,
  createProjectDefaultStatus,
  createProjectConversionStatus,
  getActivityLog
};

/**
 * Fetches all projects.
 * @returns {Promise<Array>} A promise resolving to an array of projects.
 */
function getProjects() {
  return httpManager.getHttpWithRequestingToken('/api/project');
}

/**
 * Retrieves a specific project by its ID.
 * @param {number} id - The project ID.
 * @returns {Promise<Object>} A promise resolving to the project data.
 */
function getProject(id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${id}`);
}

/**
 * Creates a new project.
 * @param {Object} project - The project data.
 * @returns {Promise<Object>} A promise resolving to the created project.
 */
function createProject(project) {
  return httpManager.postJsonOnce('/api/project', project);
}

/**
 * Updates an existing project.
 * @param {Object} project - The updated project data.
 * @param {number} id - The project ID.
 * @returns {Promise<Object>} A promise resolving to the updated project.
 */
function updateProject(project, id) {
  return httpManager.putJsonOnce(`/api/project/${id}`, project);
}

/**
 * Deletes a project.
 * @param {Object} project - The project data.
 * @param {number} id - The project ID.
 * @returns {Promise<Object>} A promise resolving to the deletion response.
 */
function deleteProject(project, id) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${id}`, project);
}

/**
 * Updates the status of a project (enable/disable).
 * @param {number} project_id - The project ID.
 * @param {string} status - The new status.
 * @returns {Promise<Object>} A promise resolving to the updated status response.
 */
function updateStatus(project_id, status) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/status/${status}`);
}

/**
 * Retrieves all members of a specific project.
 * @param {number} id - The project ID.
 * @returns {Promise<Array>} A promise resolving to an array of project members.
 */
function getAllProjectMembers(id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${id}/member`);
}

/**
 * Adds a new member to a project.
 * @param {Object} member - The member data.
 * @param {number} id - The project ID.
 * @returns {Promise<Object>} A promise resolving to the added member response.
 */
function addProjectMember(member, id) {
  return httpManager.putJsonOnce(`/api/project/${id}/member`, member);
}

/**
 * Removes a member from a project.
 * @param {Object} member - The member data.
 * @param {number} project_id - The project ID.
 * @param {number} member_id - The member ID.
 * @returns {Promise<Object>} A promise resolving to the deletion response.
 */
function deleteProjectMember(member, project_id, member_id) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/member`, member);
}

/**
 * Retrieves all statuses within a project.
 * @param {number} id - The project ID.
 * @returns {Promise<Array>} A promise resolving to an array of project statuses.
 */
function getAllProjectStatus(id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${id}/project-status`);
}

/**
 * Creates a new project status.
 * @param {Object} status - The status data.
 * @param {number} id - The project ID.
 * @returns {Promise<Object>} A promise resolving to the created project status.
 */
function createLeadStatus(status, id) {
  return httpManager.postJsonOnce(`/api/project/${id}/project-status`, status);
}

/**
 * Retrieves a specific project status.
 * @param {number} project_id - The project ID.
 * @param {number} status_id - The status ID.
 * @returns {Promise<Object>} A promise resolving to the project status data.
 */
function findProjectStatus(project_id, status_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/project-status/${status_id}`);
}

/**
 * Retrieves the count of project statuses.
 * @param {number} project_id - The project ID.
 * @returns {Promise<number>} A promise resolving to the count of project statuses.
 */
function findProjectStatusCount(project_id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${project_id}/project-status/count`);
}

/**
 * Updates a specific project status.
 * @param {Object} lead_status - The status data.
 * @param {number} project_id - The project ID.
 * @param {number} status_id - The status ID.
 * @returns {Promise<Object>} A promise resolving to the updated status.
 */
function updateLeadStatus(lead_status, project_id, status_id) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/project-status/${status_id}`, lead_status);
}

/**
 * Deletes a specific project status.
 * @param {Object} lead_status - The status data.
 * @param {number} project_id - The project ID.
 * @param {number} status_id - The status ID.
 * @returns {Promise<Object>} A promise resolving to the deletion response.
 */
function deleteLeadStatus(lead_status, project_id, status_id) {
  return httpManager.deleteJsonWithRequestingToken(`/api/project/${project_id}/project-status/${status_id}`, lead_status);
}

/**
 * Enables or disables a project status.
 * @param {number} project_id - The project ID.
 * @param {number} status_id - The status ID.
 * @param {string} status - The new status.
 * @returns {Promise<Object>} A promise resolving to the status update response.
 */
function enableProjectStatus(project_id, status_id, status) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/project-status/${status_id}/status/${status}`);
}

/**
 * Creates a default project status.
 * @param {number} project_id - The project ID.
 * @param {number} default_status_id - The default status ID.
 * @param {Object} status - The status data.
 * @returns {Promise<Object>} A promise resolving to the created default status.
 */
function createProjectDefaultStatus(project_id, default_status_id, status) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/default-status/${default_status_id}`, status);
}

/**
 * Creates a conversion status for a project.
 * @param {number} project_id - The project ID.
 * @param {number} conversion_status_id - The conversion status ID.
 * @param {Object} status - The status data.
 * @returns {Promise<Object>} A promise resolving to the created conversion status.
 */
function createProjectConversionStatus(project_id, conversion_status_id, status) {
  return httpManager.putJsonOnce(`/api/project/${project_id}/conversion-status/${conversion_status_id}`, status);
}

/**
 * Retrieves the activity log for a project.
 * @param {number} id - The project ID.
 * @returns {Promise<Array>} A promise resolving to an array of activity log entries.
 */
function getActivityLog(id) {
  return httpManager.getHttpWithRequestingToken(`/api/project/${id}/activities`);
}
