import { templateConstants } from "_constants";

/**
 * Reducer function to handle template-related actions.
 *
 * @param {Object} state - The current state of the template.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.template] - The template data associated with the action.
 * @param {Object} [action.shared] - The shared template data associated with the action.
 * @param {Object} [action.error] - The error data associated with the action.
 * @returns {Object} The new state of the template.
 */
export function template(state = {}, action) {
  switch (action.type) {
    // email templates
    case templateConstants.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        getTemplateLoading: true,
        getTemplateLoaded: false,
      };
    case templateConstants.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        getTemplateLoading: false,
        getTemplateLoaded: true,
        data: action.template.data,
      };
    case templateConstants.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        getTemplateLoading: false,
        getTemplateLoaded: true,
        error: action.error,
      };

    // shared template
    case templateConstants.GET_SHARED_TEMPLATES_REQUEST:
      return {
        ...state,
        getSharedTemplateLoading: true,
        getSharedTemplateLoaded: false,
      };

    case templateConstants.GET_SHARED_TEMPLATES_SUCCESS:
      return {
        ...state,
        getSharedTemplateLoading: false,
        getSharedTemplateLoaded: true,
        shared: action.shared.data,
      };
    case templateConstants.GET_SHARED_TEMPLATES_FAILURE:
      return {
        ...state,
        getSharedTemplateLoading: false,
        getSharedTemplateLoaded: true,
        error: action.error,
      };
    case templateConstants.GET_TEMPLATES_DROPDOWN_SUCCESS:
      return {
        ...state,
        getTemplateLoading: false,
        getTemplateLoaded: true,
        dropdown: action.template.data,
      };

    // get categories
    case templateConstants.GET_TEMPLATES_CATEGORY_REQUEST:
      return {
        ...state,
        getCategoryLoading: true,
        getCategoryLoaded: false,
      };
    case templateConstants.GET_TEMPLATES_CATEGORY_SUCCESS:
      return {
        ...state,
        getCategoryLoading: false,
        getCategoryLoaded: true,
        category: action.template.data,
      };
    case templateConstants.GET_TEMPLATES_CATEGORY_FAILURE:
      return {
        ...state,
        getCategoryLoading: false,
        getCategoryLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
