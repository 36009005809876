import { profileConstants } from "_constants";
import { profileService } from "_services";
import { alertActions } from "_actions/alert.actions";
import { userActions } from "./user.actions";
import { history } from "_helpers";

export const profileActions = {
  getProfile,
  updateProfile,
  updatePassword,
  getTemplates,
  createTemplate,
  updateTemplate,
  removeTemplate,
  removeTemplates,
};

/**
 * Fetches the user profile information.
 *
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getProfile() {
  return (dispatch) => {
    // dispatch(request());
    profileService.getProfile().then(
      (profile) => {
        dispatch(success(profile));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", error);
        dispatch(
          failure({
            error_code: "PR001",
            error_message: "Profile Not Found",
          })
        );
        dispatch(
          alertActions.error({
            error_code: "PR001",
            error_message: "Profile Not Found",
          })
        );
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_PROFILE_REQUEST };
  }

  function success(profile) {
    return { type: profileConstants.GET_PROFILE_SUCCESS, profile };
  }

  function failure(error) {
    return { type: profileConstants.GET_PROFILE_FAILURE, error };
  }
}

/**
 * Updates the user profile information.
 *
 * @param {Object} updateRequest - The profile update request data.
 * @param {boolean} hasError - Indicates if there is an error in the request.
 * @param {string} toggleError - The error message to display if an error occurs.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updateProfile(updateRequest, hasError, toggleError) {
  return (dispatch) => {
    if (!hasError) {
      dispatch(request(updateRequest));

      profileService.updateProfile(updateRequest).then(
        (data) => {
          dispatch(success(data));
          dispatch(alertActions.success("Profile Successfully Updated"));

          // update the photo if exists
          if (updateRequest.photo) {
            const currentUserData = JSON.parse(localStorage.getItem("user") || "{}");
            if (!currentUserData || !currentUserData.data) return;
            currentUserData.data["photo"] = updateRequest.photo;
            userActions.storeUser(currentUserData);
          }
        },
        (error) => {
          const { response } = error;
          const { data } = response;
          dispatch(
            failure({
              error_code: "PR001",
              error_message: "Profile Not Found",
            })
          );
          dispatch(
            alertActions.error({
              error_code: "PR001",
              error_message: "Profile Not Found",
            })
          );
        }
      );
    } else {
      let data = {};
      console.log("alert error: ", toggleError);
      data.error_message = toggleError;
      dispatch(alertActions.error(data));
    }
  };

  function request() {
    return { type: profileConstants.UPDATE_PROFILE_REQUEST };
  }

  function success(profile) {
    return { type: profileConstants.UPDATE_PROFILE_SUCCESS, profile };
  }

  function failure(error) {
    return { type: profileConstants.UPDATE_PROFILE_FAILURE, error };
  }
}

/**
 * Updates the user's password.
 *
 * @param {Object} passwordRequest - The password update request data.
 * @param {boolean} hasError - Indicates if there is an error in the request.
 * @param {string} toggleError - The error message to display if an error occurs.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updatePassword(passwordRequest, hasError, toggleError) {
  return (dispatch) => {
    if (!hasError) {
      dispatch(request(passwordRequest));

      profileService.updatePassword(passwordRequest).then(
        (data) => {
          dispatch(success(data));
          dispatch(
            alertActions.success(
              "Password successfully updated, you will be redirected to Login page."
            )
          );
          setTimeout(() => {
            dispatch(userActions.logout());
            history.push("/logout");
          }, 2000);
        },
        (error) => {
          const { response } = error;
          const { data } = response;
          console.log("error: ", data);
          let errResponse = {};
          if (data.error) {
            errResponse = {
              error_code: "PR002",
              error_message: "Password Not Match",
            };
          } else {
            errResponse = data;
          }

          dispatch(failure(data));
          dispatch(alertActions.error(data));
        }
      );
    } else {
      let data = {};
      console.log("alert error: ", toggleError);
      data.error_message = toggleError;
      dispatch(alertActions.error(data));
    }
  };

  function request() {
    return { type: profileConstants.UPDATE_PROFILE_REQUEST };
  }

  function success(profile) {
    return { type: profileConstants.UPDATE_PROFILE_SUCCESS, profile };
  }

  function failure(error) {
    return { type: profileConstants.UPDATE_PROFILE_FAILURE, error };
  }
}

/**
 * Fetches the list of templates.
 *
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getTemplates() {
  return (dispatch) => {
    dispatch(request());

    profileService.getTemplates().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(data));
        dispatch(alertActions.error(data));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Creates a new template.
 *
 * @param {Object} templateRequest - The template data to be created.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function createTemplate(templateRequest) {
  return (dispatch) => {
    dispatch(request());

    profileService.createTemplate(templateRequest).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Updates an existing template.
 *
 * @param {string} templateId - The ID of the template to be updated.
 * @param {Object} templateRequest - The updated template data.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updateTemplate(templateId, templateRequest) {
  return (dispatch) => {
    dispatch(request());

    profileService.updateTemplate(templateId, templateRequest).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Removes a template by its ID.
 *
 * @param {string} templateId - The ID of the template to be removed.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function removeTemplate(templateId) {
  return (dispatch) => {
    dispatch(request());

    profileService.removeTemplate(templateId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}

/**
 * Removes multiple templates based on the provided request data.
 *
 * @param {Object} templateRequest - The request data containing the templates to be removed.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function removeTemplates(templateRequest) {
  return (dispatch) => {
    dispatch(request());

    profileService.removeTemplates(templateRequest).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        const { response } = error;
        const { data } = response;
        console.log("error: ", data);
        let errResponse = data.error;

        dispatch(failure(errResponse));
        dispatch(alertActions.error(errResponse));
      }
    );
  };

  function request() {
    return { type: profileConstants.GET_TEMPLATES_REQUEST };
  }

  function success(template) {
    return { type: profileConstants.GET_TEMPLATES_SUCCESS, template };
  }

  function failure(error) {
    return { type: profileConstants.GET_TEMPLATES_FAILURE, error };
  }
}