import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { utilitiesService as utility } from '_services';
import { projectActions, formActions, leadActions } from '_actions';
import { MDBContainer, MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import { InputSelect, Button, ButtonGroup, Icon } from "blackbox-react";
import AddFieldModal from '_components/Modal/AddFieldModal';
import { FormGroupView } from '_pages/Forms/FormGroupView';
import { connect } from "react-redux";

const AddLead = ({ 
  getAllProjectStatus, 
  getAllProjectMembers, 
  getForm, 
  createLead, 
  projects, 
  forms, 
  membersLoaded, 
  statusLoaded, 
  formLoaded 
}) => {
  const { id: projectId } = useParams();
  const formRef = useRef();
  
  const [state, setState] = useState({
    addField: false,
    projectData: JSON.parse(localStorage.getItem('project')),
    selectedStatus: '',
    selectedPriority: '',
    selectedOwner: '',
    selectedResponsible: '',
    formGroupData: {},
    formGroupId: 0,
    fieldData: {},
    leadData: [],
    leadUpdated: false,
    requiredFields: []
  });

  useEffect(() => {
    const projectData = JSON.parse(localStorage.getItem('project'));
    getAllProjectStatus(projectId);
    getAllProjectMembers(projectId);

    const projectForms = projectData.forms;
    const basicInfo = projectForms.find(item => item.is_default) || {};
    const formId = basicInfo.id || 0;

    getForm(projectId, formId);
  }, [projectId, getAllProjectStatus, getAllProjectMembers, getForm]);

  useEffect(() => {
    if (formLoaded) {
      const formGroups = forms.form.data.group;
      const formFields = [];
      formGroups.forEach(group => {
        group.field.forEach(field => formFields.push(field.id));
      });
      localStorage.setItem('formFieldKeys', JSON.stringify(formFields));
    }
  }, [formLoaded, forms]);

  const handleUpdate = (event) => {
    const updatedKey = event.target.name;
    const updatedValue = event.target.value;
    let leadData = [...state.leadData];

    leadData[updatedKey] = null;
    if (updatedValue) {
      leadData[updatedKey] = { key: parseInt(updatedKey), value: utility.encrypt(updatedValue) };
    }

    setState(prevState => ({ ...prevState, leadData }));
  };

  const handleCreateLead = () => {
    const projectData = JSON.parse(localStorage.getItem('project'));
    const projectForms = projectData.forms;
    const basicInfo = projectForms.find(item => item.is_default);
    const formId = basicInfo.id;
    let leadData = [...state.leadData];

    let requiredFields = [];
    const form = forms.form.data;
    form.group.forEach((g) => {
      g.field.forEach((i) => {
        if (i.required) {
          requiredFields.push(i.id);
        }
      });
    });

    let hasError = false;
    requiredFields.forEach((rf) => {
      if (!leadData[rf]) {
        hasError = true;
      }
    });

    let leadInfo = leadData.filter(d => d != null);
    const projectStatus = projects.status.data.sort((a, b) => a.id > b.id);
    const defaultStatus = projectStatus[0].id;
    const defaultPriority = '2';
    const defaultOwner = 'Unassigned';
    const defaultResponsible = 'Unassigned';

    const { selectedStatus, selectedPriority, selectedOwner, selectedResponsible } = state;

    const leadDataToAdd = {
      form_lead: leadInfo,
      project_status_id: selectedStatus || defaultStatus,
      priority: selectedPriority || defaultPriority,
      owner: selectedOwner || defaultOwner,
      responsible: selectedResponsible || defaultResponsible
    };

    createLead(projectId, formId, leadDataToAdd, hasError);
  };

  const handleModal = () => setState(prevState => ({ ...prevState, addField: !prevState.addField }));

  const renderLeadPhoto = () => (
    <div className="box-right-rectangle size-100 br-75 flex-center mx-auto mb-4">
      <div className="text-center">
        <MDBIcon icon="paper-plane" className="font-size-30 color-orange" />
      </div>
    </div>
  );

  const renderProjectStatus = () => {
    const projectStatus = projects.status.data.sort((a, b) => a.id > b.id);
    const hasOptions = projectStatus.length > 0;
    const selectOptions = projectStatus.map((value) => ({
      id: value.id,
      value: value.id,
      label: value.name
    }));

    return (
      <div>
        <label htmlFor="selectStatus" className="mb-0 font-size-14 font-weight-bold">Status</label>
        <div className="width-inherit background-color-white">
          {hasOptions && (
            <InputSelect
              name="selectStatus"
              defaultValue={selectOptions[0].value}
              onChange={(v) => setState(prevState => ({ ...prevState, selectedStatus: v }))}
              options={selectOptions}
            />
          )}
        </div>
      </div>
    );
  };

  const renderLeadPriority = () => {
    const selectOptions = [
      { id: 1, value: '1', label: 'Low' },
      { id: 2, value: '2', label: 'Medium' },
      { id: 3, value: '3', label: 'High' }
    ];

    return (
      <div>
        <label htmlFor="selectPriority" className="mb-0 font-size-14 font-weight-bold">Priority</label>
        <div className="width-inherit background-color-white">
          <InputSelect
            name="selectedPriority"
            defaultValue={selectOptions[1].value}
            onChange={(v) => setState(prevState => ({ ...prevState, selectedPriority: v }))}
            options={selectOptions}
          />
        </div>
      </div>
    );
  };

  const handleGetProjectOwnerAndMembers = () => {
    const projectData = state.projectData;
    const projectOwner = projectData.owner;
    let projectMembers = projects.members.data.sort((a, b) => a.member_id > b.member_id);

    let selectOptions = [
      { id: 0, value: 'unassigned', label: 'Unassigned' },
      {
        id: projectOwner.id,
        value: projectOwner.account.email,
        label: `${utility.decrypt(projectOwner.account.first_name)} ${utility.decrypt(projectOwner.account.last_name)}`
      }
    ];

    projectMembers.forEach((value) => {
      const projectMemberName = `${utility.decrypt(value.account.first_name)} ${utility.decrypt(value.account.last_name)}`;
      selectOptions.push({
        id: value.id,
        value: value.account.email,
        label: projectMemberName
      });
    });

    return selectOptions;
  };

  const renderLeadOwner = () => {
    const selectOptions = handleGetProjectOwnerAndMembers();
    const hasOptions = selectOptions.length > 0;

    return (
      <div>
        <label htmlFor="selectOwner" className="mb-0 font-size-14 font-weight-bold">Owner</label>
        <div className="width-inherit background-color-white">
          {hasOptions && (
            <InputSelect
              name="selectedOwner"
              defaultValue={selectOptions[0].value}
              onChange={(v) => setState(prevState => ({ ...prevState, selectedOwner: v }))}
              options={selectOptions}
            />
          )}
        </div>
      </div>
    );
  };

  const renderLeadResponsible = () => {
    const selectOptions = handleGetProjectOwnerAndMembers();
    const hasOptions = selectOptions.length > 0;

    return (
      <div>
        <label htmlFor="selectResponsible" className="mb-0 font-size-14 font-weight-bold">Responsible</label>
        <div className="width-inherit background-color-white">
          {hasOptions && (
            <InputSelect
              name="selectedResponsible"
              defaultValue={selectOptions[0].value}
              onChange={(v) => setState(prevState => ({ ...prevState, selectedResponsible: v }))}
              options={selectOptions}
            />
          )}
        </div>
      </div>
    );
  };

  const renderSideBar = () => (
    <MDBCol xl="2" lg="2" className="p--md m--0">
      <div className="p--md">
        {renderLeadPhoto()}
        {renderProjectStatus()}
        {renderLeadPriority()}
        <hr className="width-inherit" />
        {renderLeadOwner()}
        {renderLeadResponsible()}
      </div>
    </MDBCol>
  );

  const renderFormGroups = (data) => {
    const totalFormGroups = data.length || 0;
    const hasFormGroups = totalFormGroups > 0;

    return (
      <form id="manage-form" ref={formRef}>
        <div className="preview__stage" onClick={(e) => {
          e.stopPropagation();
          setState(prevState => ({
            ...prevState,
            activeFormGroup: '',
            activeFormField: '',
            activeSideBar: 'select'
          }));
        }}>
          {hasFormGroups && data.map((value, index) => (
            <FormGroupView
              key={index}
              formGroupData={value}
              formGroupId={value.id}
              formGroupKey={index}
              totalFormGroups={totalFormGroups}
              handleUpdate={handleUpdate}
              activeFormGroup={state.activeFormGroup}
              activeFormField={state.activeFormField}
              projectId={localStorage.getItem('projectId')}
              formId={projectId}
              selectedInput={state.selectedInput}
            />
          ))}
        </div>
      </form>
    );
  };

  const renderBasicInformation = () => (
    <MDBCol xl="10" lg="10" className="box-right-rectangle p--0 p-t--sm p-b--sm m--0 height--auto">
      <div className="basic-info p-t--0">
        <h5 className="font-size-20">
          <MDBIcon far icon="address-card" /> &nbsp; BASIC INFORMATION
        </h5>
        <div className="field-container m-t--sm p--sm border-radius-5">
          {renderFormGroups(forms.form.data.group)}
          <span className="font-size-10 p-l--md">Please fill out all fields.</span>
        </div>
      </div>
    </MDBCol>
  );

  const renderCustomFields = () => {
    const projectId = projectId;

    return (
      <React.Fragment>
        <MDBCol xl="2" lg="2" className="p--0 p-t--sm p-b--sm m--0 pull-right"></MDBCol>
        <MDBCol xl="10" lg="10" className="p--0 p-t--sm p-b--sm m

--0">
          <div className="p--md p-b--md">
            <div className="p--md border-radius-5 background-color-white">
              <h5 className="font-size-20">
                <MDBIcon far icon="address-card" /> &nbsp; CUSTOM FIELDS
              </h5>
              <div className="border-radius-5 p--sm">
                {renderFormGroups(forms.form.data.group)}
              </div>
            </div>
          </div>
        </MDBCol>
      </React.Fragment>
    );
  };

  return (
    <MDBContainer fluid>
      <MDBRow>
        {renderSideBar()}
        {renderBasicInformation()}
        {renderCustomFields()}
      </MDBRow>
    </MDBContainer>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects,
  forms: state.forms,
  membersLoaded: state.projects.membersLoaded,
  statusLoaded: state.projects.statusLoaded,
  formLoaded: state.forms.formLoaded
});

const actionCreators = {
  getAllProjectStatus: projectActions.getAllProjectStatus,
  getAllProjectMembers: projectActions.getAllProjectMembers,
  getForm: formActions.getForm,
  createLead: leadActions.createLead
};

const connectedAddLead = connect(mapStateToProps, actionCreators)(AddLead);
export { connectedAddLead as AddLead };