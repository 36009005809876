import { leadConstants } from "_constants";

const initialState = {
  leads: [],
  selectedLeadIds: [], // New property to store selected lead ids
  // other state properties...
};

/**
 * Reducer function to handle lead-related actions and update the state accordingly.
 *
 * @param {Object} state - The current state of the leads.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action being dispatched.
 * @param {Object} [action.leads] - The leads data returned from the API.
 * @param {Object} [action.lead] - A specific lead data returned from the API.
 * @param {Object} [action.importedLeads] - The imported leads data.
 * @param {Object} [action.sessions] - The sessions data returned from the API.
 * @param {Object} [action.interest] - The interest data returned from the API.
 * @param {Object} [action.notes] - The notes data returned from the API.
 * @param {Object} [action.note] - A specific note data returned from the API.
 * @param {Object} [action.status] - The status data returned from the API.
 * @param {Object} [action.content] - The content data returned from the API.
 * @param {Object} [action.lead_import] - The lead import data returned from the API.
 * @param {Object} [action.import_data] - The specific lead import data returned from the API.
 * @param {Object} [action.activity_log] - The activity log data returned from the API.
 * @param {Object} [action.error] - The error object returned from the API.
 * @param {string} [action.leadId] - The ID of the lead being selected or deselected.
 * @returns {Object} The updated state based on the action type.
 */
export function leads(state = {}, action) {
  switch (action.type) {
      /*
       * LEADS
       * */

      // GET ALL LEADS
    case leadConstants.GET_ALL_REQUEST:
    case leadConstants.BULK_UPLOAD_REQUEST:
      return {
        ...state,
        leadsLoading: true,
        leadsLoaded: false,
        loading: true,
        batchLoaded: false,
      };
    case leadConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.leads,
        leadsLoading: false,
        leadsLoaded: true,
      };
    case leadConstants.GET_ALL_FAILURE:
    case leadConstants.BULK_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        leadsLoading: false,
        leadsLoaded: true,
        error: action.error,
      };

      // GET ALL LEADS
    case leadConstants.SYNC_PATIENTS_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case leadConstants.SYNC_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case leadConstants.SYNC_PATIENTS_ERROR:
      return {
        ...state,
        error: action.error,
      };

      // CREATE LEAD
    case leadConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
        createLeadLoading: true,
        createLeadLoaded: false,
        createLeadSuccess: false,
      };
    case leadConstants.CREATE_SUCCESS:
      return {
        ...state,
        createLeadLoading: false,
        createLeadLoaded: true,
        createLeadSuccess: true,
      };
    case leadConstants.CREATE_FAILURE:
      return {
        ...state,
        createLeadLoading: false,
        createLeadLoaded: true,
        createLeadSuccess: false,
      };

      // CREATE LEAD FROM IMPORT
    case leadConstants.CREATE_LEAD_IMPORT_REQUEST:
      return {
        creating: true,
      };
    case leadConstants.CREATE_LEAD_IMPORT_SUCCESS:
      return {
        importedLeads: action.importedLeads,
        imported: true,
      };
    case leadConstants.CREATE_LEAD_IMPORT_FAILURE:
      return {};

      // GET SPECIFIC LEAD
    case leadConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        leadLoaded: false,
      };
    case leadConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        lead: action.lead,
        leadLoaded: true,
      };
    case leadConstants.GET_FAILURE:
      return {
        ...state,
        leadLoaded: true,
        error: action.error,
      };

      // UPDATE LEAD
    case leadConstants.UPDATE_REQUEST:
      return {
        ...state,
        updateLeadLoading: true,
        updateLeadLoaded: false,
        updateLeadSuccess: false,
        leadLoaded: false,
        loading: true,
      };
    case leadConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateLeadLoading: false,
        updateLeadLoaded: true,
        updateLeadSuccess: true,
        lead: action.lead,
        leadLoaded: true,
      };
    case leadConstants.UPDATE_FAILURE:
      return {
        ...state,
        updateLeadLoading: false,
        updateLeadLoaded: true,
        leadLoaded: true,
        error: action.error,
      };

      // DELETE LEAD
    case leadConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case leadConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: action.leads,
      };
    case leadConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case leadConstants.GET_ALL_SESSION_REQUEST:
      return {
        ...state,
        sessionsLoaded: false,
        sessionsLoading: true,
      };

    case leadConstants.GET_AL_SESSION_SUCCESS:
      return {
        ...state,
        sessions: action.sessions.data,
        sessionsLoaded: true,
        sessionsLoading: false,
      };

    case leadConstants.GET_ALL_SESSION_FAILURE:
      return {
        ...state,
        sessionsLoaded: true,
        error: action.error,
        sessionsLoading: false,
      };

    case leadConstants.GET_ALL_INTEREST_REQUEST:
      return {
        ...state,
        interestLoaded: false,
        interestLoading: true,
      };

    case leadConstants.GET_AL_INTEREST_SUCCESS:
      return {
        ...state,
        interest: action.interest.data,
        interestLoaded: true,
        interestLoading: false,
      };

    case leadConstants.GET_ALL_INTEREST_FAILURE:
      return {
        ...state,
        interestLoaded: true,
        error: action.error,
        interestLoading: false,
      };

      // GET ALL LEADS
    case leadConstants.GET_ALL_NOTES_REQUEST:
      return {
        ...state,
        notesLoaded: false,
        notesLoading: true,
      };
    case leadConstants.GET_ALL_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.notes,
        notesLoaded: true,
        notesLoading: false,
      };
    case leadConstants.GET_ALL_NOTES_FAILURE:
      return {
        ...state,
        notesLoaded: true,
        error: action.error,
        notesLoading: false,
      };

      // ADD NOTE
    case leadConstants.CREATE_NOTE_REQUEST:
      return {
        ...state,
        notesLoaded: false,
        addNotesLoaded: false,
        addNotesLoading: true,
      };
    case leadConstants.CREATE_NOTE_SUCCESS:
      return {
        ...state,
        note: action.note,
        addNotesLoaded: true,
        addNotesLoading: false,
        notesLoading: true,
        notesLoaded: false,
      };
    case leadConstants.CREATE_NOTE_FAILURE:
      return {
        addNotesLoaded: true,
        addNotesLoading: true,
      };

      // EDIT NOTE
    case leadConstants.UPDATE_NOTE_REQUEST:
      return {
        ...state,
        notesLoading: true,
        notesLoaded: false,
      };
    case leadConstants.UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        note: action.note,
        notesLoading: false,
        notesLoaded: true,
      };
    case leadConstants.UPDATE_NOTE_FAILURE:
      return {
        notesLoading: false,
        notesLoaded: true,
      };

      // DELETE NOTE
    case leadConstants.DELETE_NOTE_REQUEST:
      return {
        ...state,
        notesLoading: true,
        notesLoaded: false,
      };
    case leadConstants.DELETE_NOTE_SUCCESS:
      return {
        ...state,
        notesLoading: false,
        notesLoaded: true,
      };
    case leadConstants.DELETE_NOTE_FAILURE:
      return {
        ...state,
        notesLoading: false,
        notesLoaded: true,
        error: action.error,
      };

    case leadConstants.CREATE_NOTE_REPLY_REQUEST:
      return {
        ...state,
        notesLoading: true,
        notesLoaded: false,
      };

    case leadConstants.CREATE_NOTE_REPLY_SUCCESS:
      return {
        ...state,
        notes: action.notes,
        notesLoaded: true,
        notesLoading: false,
      };

    case leadConstants.CREATE_NOTE_REPLY_FAILURE:
      return {
        ...state,
        notesLoading: true,
        notesLoaded: false,
        error: action.error,
      };

      // UPDATE STATUS
    case leadConstants.UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadConstants.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        items: action.status,
      };
    case leadConstants.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      // GET ALL LEAD CONTENT
    case leadConstants.GET_ALL_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadConstants.GET_ALL_CONTENT_SUCCESS:
      return {
        ...state,
        items: action.content,
      };
    case leadConstants.GET_ALL_CONTENT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      // CREATE LEAD CONTENT
    case leadConstants.CREATE_CONTENT_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case leadConstants.CREATE_CONTENT_SUCCESS:
      return {
        ...state,
        creating: false,
      };
    case leadConstants.CREATE_CONTENT_FAILURE:
      return {};

      // GET SPECIFIC LEAD
    case leadConstants.GET_CONTENT_REQUEST:
      return {
        ...state,
        creating: true,
        loading: true,
      };
    case leadConstants.GET_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.content,
      };
    case leadConstants.GET_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      // UPDATE LEAD CONTENT
    case leadConstants.UPDATE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadConstants.UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        items: action.content,
      };
    case leadConstants.UPDATE_CONTENT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      // DELETE LEAD CONTENT
    case leadConstants.DELETE_CONTENT_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case leadConstants.DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        items: action.content,
      };
    case leadConstants.DELETE_CONTENT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      // UPDATE LEAD CONTENT STATUS
    case leadConstants.UPDATE_CONTENT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadConstants.UPDATE_CONTENT_STATUS_SUCCESS:
      return {
        ...state,
        items: action.status,
      };
    case leadConstants.UPDATE_CONTENT_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      // GET ALL LEADS
    case leadConstants.GET_ALL_IMPORT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        leadImportsLoaded: false,
      };
    case leadConstants.GET_ALL_IMPORT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.lead_import,
        leadImportsLoaded: true,
      };
    case leadConstants.GET_ALL_IMPORT_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      // GET SPECIFIC LEAD IMPORT
    case leadConstants.GET_IMPORT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadConstants.GET_IMPORT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.import_data,
        leadLoaded: true,
      };
    case leadConstants.GET_IMPORT_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      /*
          GET LEAD ACTIVITY LOGS
           */
    case leadConstants.GET_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        activityLogLoaded: false,
      };
    case leadConstants.GET_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        activityLog: action.activity_log,
        activityLogLoaded: true,
      };
    case leadConstants.GET_LOG_FAILURE:
      return {
        activityLogLoaded: true,
        error: action.error,
      };
    default:
      return state;

      // CALENDLY
    case leadConstants.CALENDLY_INVITE_REQUEST:
      return {
        ...state,
        calendlyLoading: true,
        calendlydLoaded: false,
        calendlySuccess: false,
        loading: true,
      };
    case leadConstants.CALENDLY_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        calendlyLoading: false,
        calendlydLoaded: true,
        calendlySuccess: true,
      };
    case leadConstants.CALENDLY_INVITE_FAILURE:
      return {
        ...state,
        calendlyLoading: false,
        calendlydLoaded: true,
        error: action.error,
      };


      // BULK UPDATE
    case leadConstants.BULK_UPDATE_REQUEST:
      return {
        ...state,
        batchLoading: true,
        batchLoaded: false,
        leadsLoading: true,
        leadsLoaded: false,
        loading: true,
      };
    case leadConstants.BULK_UPDATE_SUCCESS:
      return {
        ...state,
        batchLoading: false,
        batchLoaded: true,
        loading: false,
        leadsLoading: false,
        leadsLoaded: true,
        leads: action.leads,
      };
    case leadConstants.BULK_UPDATE_FAILURE:
      return {
        ...state,
        batchLoading: false,
        batchLoaded: true,
        error: action.error,
        leadsLoading: false,
        loading: false,
        leadsLoaded: true,
      };

      // SELECT LEADS
      // SELECT LEAD
    case leadConstants.SELECT_LEAD:
      return {
        ...state,
        selectedLeadIds: [...state.selectedLeadIds, action.leadId],
      };

      // DESELECT LEAD
    case leadConstants.DESELECT_LEAD:
      return {
        ...state,
        selectedLeadIds: state.selectedLeadIds.filter(
            (id) => id !== action.leadId
        ),
      };
    case leadConstants.BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        leadsLoading: false,
        leadsLoaded: true,
        batchLoaded: true,
        leads: action.leads,
      };
  }
}
