import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { projectActions } from '_actions';
import { utilitiesService as utility } from '_services';
import { MDBIcon } from 'mdbreact';
import avatar from '_assets/images/avatar-fem.png';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

const ProjectViewRecentActivities = ({ activityLog, projectData }) => {
  const [activities, setActivities] = useState([]);
  
  useEffect(() => {
    if (activityLog && activityLog.length > 0) {
      setActivities(activityLog.sort((a, b) => a.created < b.created));
    }
  }, [activityLog]);

  const renderActivityLog = (activity, isLastLog, projectId) => {
    let icon = '';
    let logLabel = '';
    let hasLink = false;
    let link = '';
    let hasAvatar = false;
    let activityLog = '';

    switch (activity.name) {
      case 'PROJECT_CREATED':
        logLabel = 'Project created:';
        activityLog = activity.action.replace('Created project: ', '');
        icon = 'edit';
        break;
      case 'PROJECT_INFO_EDITED':
        logLabel = 'Project updated:';
        activityLog = activity.action.replace('Edited project: ', '');
        icon = 'edit';
        break;
      case 'OWNER_CREATED':
        logLabel = 'Project owner updated:';
        activityLog = activity.action.replace('Edited owner: ', '');
        icon = 'user';
        break;
      case 'MEMBER_ADDED':
        logLabel = 'Member added:';
        activityLog = activity.action.replace('Added member: ', '');
        icon = 'user';
        hasAvatar = true;
        break;
      case 'MEMBER_DELETED':
        logLabel = 'Member removed:';
        activityLog = activity.action.replace('Removed member: ', '');
        icon = 'edit';
        hasAvatar = true;
        break;
      case 'STATUS_CREATED':
        logLabel = 'Status created:';
        activityLog = activity.action.replace('Created project status: ', '');
        icon = 'clipboard';
        hasLink = true;
        link = `/project/${projectId}/manage-lead-status/${activity.action_id}`;
        break;
      case 'STATUS_EDITED':
        logLabel = 'Status edited:';
        activityLog = activity.action.replace('Edited project status: ', '');
        icon = 'clipboard';
        hasLink = true;
        link = `/project/${projectId}/manage-lead-status/${activity.action_id}`;
        break;
      case 'STATUS_DELETED':
        logLabel = 'Status deleted:';
        activityLog = activity.action.replace('Deleted project status: ', '');
        icon = 'clipboard';
        break;
      case 'DEFAULT_STATUS':
        logLabel = 'Default status:';
        activityLog = activity.action.replace('Set default status: ', '');
        icon = 'clipboard';
        break;
      case 'CONVERSION_STATUS':
        logLabel = 'Conversion status:';
        activityLog = activity.action.replace('Set conversion status: ', '');
        icon = 'clipboard';
        break;
      case 'FORM_CREATED':
        logLabel = 'Form created:';
        activityLog = activity.action.replace('Created form: ', '');
        icon = 'clipboard';
        hasLink = true;
        link = `/project/${projectId}/form/${activity.action_id}/form-builder`;
        break;
      case 'FORM_EDITED':
        logLabel = 'Form updated:';
        activityLog = activity.action.replace('Edited project: ', '');
        icon = 'clipboard';
        hasLink = true;
        link = `/project/${projectId}/form/${activity.action_id}/form-builder`;
        break;
      case 'FORM_DELETED':
        logLabel = 'Form deleted:';
        activityLog = activity.action.replace('Removed form: ', '');
        icon = 'clipboard';
        break;
      case 'LEAD_CREATED':
        logLabel = 'New lead added:';
        activityLog = activity.action.replace('Lead submitted to', '');
        icon = 'user';
        hasLink = true;
        link = `/project/${projectId}/lead/${activity.action_id}`;
        hasAvatar = true;
        break;
      case 'LEAD_DELETED':
        logLabel = 'Lead deleted:';
        activityLog = activity.action.replace('Removed lead: ', '');
        icon = 'user';
        hasAvatar = true;
        break;
      default:
        return null;
    }

    return (
      <div className="row height-48 m-0">
        <div className="mt-2 position-relative px-0">
          <p className="background-slider-color mb-0 mx-2 round-lead">
            <MDBIcon far icon={icon} className="font-color-orange position-absolute font-size-11 top-7 left-16" />
          </p>
          {!isLastLog && <p className="mb-0 line-lead" />}
        </div>
        <div className="col px-0 pt-1 line-height-15">
          <div>
            <span className="mb-0 font-size-11 font-weight-600">{logLabel} </span>
            {hasAvatar && <img src={avatar} alt="" className="width-20 border-radius-50 mr-1" />}
            {hasLink ? (
              <Link to={link}>
                <span className="font-size-11 font-color-orange">{activityLog}</span>
              </Link>
            ) : (
              <span className="font-size-11 font-color-orange">{activityLog}</span>
            )}
          </div>
          <div className="d-flex opacity-50">
            <span className="mb-0 font-size-11 font-weight-600 my-auto">
              {utility.decrypt(activity.sender.first_name)} {utility.decrypt(activity.sender.last_name)} &nbsp;
              <MDBIcon icon="circle" className="font-size-4 my-auto" />
            </span>
            <span className="font-size-11 ml-1 my-auto">
              <Moment className="font-bold-black" date={activity.created} fromNow ago /> ago
            </span>
          </div>
        </div>
      </div>
    );
  };

  const projectId = projectData.id;
  const hasData = activities && activities.length > 0;

  return (
    <div>
      <p className="font-weight-600 font-size-14 pt-3 m-l--sm m-t--sm">RECENT ACTIVITIES</p>
      <ul className="list-unstyled">
        {hasData &&
          activities.reverse().slice(0, 12).map((value, index) => {
            const isLastLog = index + 1 === activities.length || index + 1 === 12;
            return (
              <li key={index}>
                {renderActivityLog(value, isLastLog, projectId)}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

function mapState(state) {
  const { projects, project, activities } = state;
  return { projects, project, activities };
}

const actionCreators = {
  getProject: projectActions.getProject,
  getProjects: projectActions.getProjects,
  getProjectActivities: projectActions.getActivityLog,
};

const connectedViewProjects = connect(mapState, actionCreators)(ProjectViewRecentActivities);
export { connectedViewProjects as ProjectViewRecentActivities };