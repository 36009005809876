import React from "react";
import {
  defaultConfig,
  phiConfig,
  maayoConfig,
  demoConfig,
  propelrrServiceConfig,
  propelrrCareerConfig,
  dmciLeasingConfig,
} from "_pages/Leads/constants/TableHeaderConfig";

const DefaultTableHeader = ({ 
  projectId, 
  onMasterCheckboxChange, 
  headerCheckboxChecked, 
  selectedLeadIds, 
  field, 
  direction, 
  onHandleSortChange 
}) => {
  const handleSortChange = (colField, dir, active) => {
    if (!active) {
      onHandleSortChange(colField, dir);
    }
  };

  const renderSortAction = (col) => {
    let isDescSelected = false;
    let isAscSelected = false;

    if (field === col.field) {
      if (direction === "ASC") {
        isAscSelected = true;
      } else {
        isDescSelected = true;
      }
    }

    if (col.sort) {
      return (
        <div className="sort-icons">
          <i
            className={isAscSelected ? "fas fa-sort-up active" : "fas fa-sort-up"}
            onClick={() => handleSortChange(col.field, "ASC", isAscSelected)}
          ></i>
          <i
            className={isDescSelected ? "fas fa-sort-down active" : "fas fa-sort-down"}
            onClick={() => handleSortChange(col.field, "DESC", isDescSelected)}
          ></i>
        </div>
      );
    }
    return null;
  };

  let config = defaultConfig;

  switch (parseInt(projectId)) {
    case 1:
      config = phiConfig;
      break;
    case 3:
      config = maayoConfig;
      break;
    case 5:
      config = propelrrServiceConfig;
      break;
    case 6:
      config = propelrrCareerConfig;
      break;
    case 7:
      config = demoConfig;
      break;
    case 8:
      config = dmciLeasingConfig;
      break;
    default:
      config = defaultConfig;
      break;
  }

  return (
    <tr>
      {config.map((col, index) => (
        <th key={`lead-header-${index}`} width={`${col.width}`}>
          <div className="th-w-sort">
            {index === 0 && (
              <div className="checkbox-group">
                <input
                  type="checkbox"
                  name="table-checkbox-heading"
                  id="table-checkbox-heading"
                  checked={headerCheckboxChecked}
                  onChange={onMasterCheckboxChange}
                />
                <label htmlFor="table-checkbox-heading"></label>
              </div>
            )}
            <span>{col.title}</span>
            {renderSortAction(col)}
          </div>
        </th>
      ))}
    </tr>
  );
};

export default DefaultTableHeader;
