import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import check from '_assets/images/check.png';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { connect } from "react-redux";
import Slider from '_pages/Leads/components/Slider';
import { utilitiesService as utility } from '_services';
import { projectActions, formActions, leadActions } from '_actions';
import { Button, ButtonGroup, Icon } from "blackbox-react";

const LeadMapField = ({ getAllFields, importLeads, fields, formLoaded }) => {
  const { id: projectId } = useParams();
  const projectData = JSON.parse(localStorage.getItem('project'));
  const importData = JSON.parse(localStorage.getItem('importData')) || [];
  const fileName = localStorage.getItem('fileName');
  const basicInfo = projectData && projectData.forms ? projectData.forms.find(item => item.is_default) || {} : {};
  const formId = basicInfo.id || 0;

  useEffect(() => {
    getAllFields(projectId, formId);
  }, [getAllFields, projectId, formId]);

  const [state, setState] = useState({
    importData,
    isClearable: true,
    mappingComplete: false,
    dataToImport: {},
    mappedFields: [],
    isSelected: [],
    basicInfoOptions: [],
    basicInfoOptionsRequired: [],
    basicInfoOptionsTrash: [],
    basicInfoOptionsRequiredSelected: []
  });

  useEffect(() => {
    if (formLoaded && fields.data) {
      const sortedFields = [...fields.data].sort((a, b) => a.id - b.id);
      const options = sortedFields.map(val => ({
        id: val.id,
        value: val.label,
        label: val.label,
        required: val.required
      }));
      const requiredOptions = options.filter(opt => opt.required);
      const mappedFields = sortedFields.map((_, index) => ({ data_index: index, field_id: 0 }));
      setState(prevState => ({
        ...prevState,
        basicInfoOptions: options,
        basicInfoOptionsRequired: requiredOptions,
        mappedFields,
        isSelected: new Array(sortedFields.length).fill(false)
      }));
    }
  }, [formLoaded, fields]);

  const handleImportLeads = () => {
    importLeads(projectId, formId, state.dataToImport);
  };

  return (
    <MDBContainer fluid className='p--md lead-import'>
      <MDBRow className='m--0'>
        <MDBCol lg='3' xl='2' md='4' className='p--0'>
          <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
          <p className='font-family-crimson font-size-30 line-height-7 color-orange'>Import Leads</p>
          <Slider stageNum='2' activeFormView='mapFields' />
        </MDBCol>
        <MDBCol lg='9' xl='10' md='8' className='px-0'>
          <MDBRow>
            <MDBCol md='3' className='my-auto'><p className='mb-0 font-size-22'>Map Fields</p></MDBCol>
            <MDBCol md='9' className='text-right'>
              <ButtonGroup>
                <Link to={`/project/${projectId}/leads/import/history`}>
                  <Button flat background='transparent' foreground='dark' borderWidth={2} borderColor='dark' size={-2}>
                    <MDBIcon icon='columns' className='mr-2' /> Import History
                  </Button>
                </Link>
                <Link to={`/project/${projectId}/leads/import/map`}>
                  <Button flat background='transparent' foreground='dark' borderWidth={2} borderColor='dark' size={-2} onClick={() => window.location.reload()}>
                    <MDBIcon icon='redo' className='mr-2' /> Reset Mapping
                  </Button>
                </Link>
                {state.mappingComplete ? (
                  <Button flat background='orange' foreground='white' borderWidth={2} borderColor='orange' size={-2} onClick={handleImportLeads}>
                    Import Leads
                  </Button>
                ) : (
                  <Button flat disabled background='disabled' borderWidth={2} size={-2}>
                    Import Leads
                  </Button>
                )}
              </ButtonGroup>
            </MDBCol>
          </MDBRow>
          <MDBCol className='sub-body-container background-color-white'>
            {importData[0]?.map((header, index) => (
              <MDBRow key={index} className={`csv__row ${index % 2 === 0 ? 'color-light-white' : ''}`}>
                <MDBCol md='3' className='border-table-right'><div className='p--md'>{header}</div></MDBCol>
                <MDBCol md='4' className='border-table-right'><div className='p--md'>{importData[1]?.[index]}</div></MDBCol>
                <MDBCol md='4' className='border-table-right'>
                  <Select
                    placeholder='Select a lead field'
                    isClearable
                    options={state.basicInfoOptions}
                    onChange={(v) => {
                      const updatedFields = [...state.mappedFields];
                      updatedFields[index].field_id = v?.id || 0;
                      setState(prev => ({ ...prev, mappedFields: updatedFields }));
                    }}
                  />
                </MDBCol>
              </MDBRow>
            ))}
          </MDBCol>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const mapState = (state) => ({
  fields: state.project?.fields || {},
  formLoaded: state.form?.loaded || false
});

const actionCreators = {
  getAllFields: projectActions.getAllFields || (() => {}),
  importLeads: leadActions.importLeads
};

const connectedLeadMapField = connect(mapState, actionCreators)(LeadMapField);
export { connectedLeadMapField as LeadMapField };
