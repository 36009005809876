import LeadChat from "_pages/Leads/components/LeadInfo/LeadChat";
import LeadEmail from "_pages/Leads/components/LeadInfo/LeadEmail";
import LeadInterest from "_pages/Leads/components/LeadInfo/LeadInterest";
import LeadSession from "_pages/Leads/components/LeadInfo/LeadSession";
import LeadViewInfoForms from "_pages/Leads/components/LeadInfo/LeadViewInfoForms";
import LeadViewInfoTask from "_pages/Leads/components/LeadInfo/LeadViewInfoTask";
import LeadViewNotesNew from "_pages/Leads/components/LeadInfo/LeadViewNotesNew";
import { LeadViewRecentActivities } from "_pages/Leads/components/LeadInfo/LeadViewRecentActivities";
import React from "react";
import SideInfoLoader from "../../../../../_components/Loader/SideInfoLoader";
import { LeadFile } from "../../../components/LeadInfo/LeadFile";

export const handleDisplayActivity = (
  lead,
  activityLog,
  leadNotes,
  leadInterest,
  leadChats,
  getChatsLoaded,
  leadEmails,
  getEmailLoaded,
  getChatNotification,
  leadFiles,
  leadLoaded,
  props,
  state,
  toggleEditLeadNote,
  toggleAddLeadNote,
  toggleDeleteLeadNote,
  setState,
  handlePassData,
) => {
  if (!leadLoaded) return <SideInfoLoader />;

  const handleDeleteLeadNote = (leadNote) => {
    toggleDeleteLeadNote();
    handlePassData(leadNote);
  };

  const handleEditLeadNote = (leadNote) => {
    setState((prevState) => ({
      ...prevState,
      noteToEdit: leadNote ? leadNote : {},
    }));
  };

  const handleOpenManageFileModal = (mode, data) => {
    console.log('open lead file modal.....');
    this.setState({
      isManageFileModalOpen: true,
      manageFileMode: mode,
      manageFileData: data,
    });
  };

  const handleOpenConfirmDeleteModal = (type, data) => {
    setState((prevState) => ({
      ...prevState,
      isDeleteConfirmationModalOpen: true,
      deleteConfirmationType: type,
      deleteConfirmationData: data,
    }));
  };


  const { match } = props;
  const projectId = match.params.id;

  let { isActiveSideBtn } = state;
  switch (isActiveSideBtn) {
    case "Activity":
      return <LeadViewRecentActivities leadData={lead} />;
    case "Source":
      return (
        <LeadViewInfoForms
          leadFormsDetails={[]}
          leadData={lead}
          projectId={projectId}
        />
      );
    case "Sessions":
      return <LeadSession />;
    case "Interest":
      return <LeadInterest />;
    case "Tasks":
      return <LeadViewInfoTask />;
    case "Notes":
      return (
        <React.Fragment>
          <LeadViewNotesNew
            toggleAddLeadNote={toggleAddLeadNote}
            toggleEditLeadNote={toggleEditLeadNote}
            toggleDeleteLeadNote={toggleDeleteLeadNote}
            handleEditLeadNote={handleEditLeadNote}
            leadNotes={leadNotes}
            handleDeleteLeadNote={handleDeleteLeadNote}
            lead={lead}
          />
        </React.Fragment>
      );
    case "Chat":
      return (
        <LeadChat
          leadChats={leadChats}
          chatLoaded={getChatsLoaded}
          chatNotif={getChatNotification}
          lead={lead}
        />
      );
    case "Email":
      return (
        <LeadEmail
          leadEmailDetails={leadEmails}
          emailLoaded={getEmailLoaded}
          lead={lead}
        />
      );
    case "Document":
      return (
        <LeadFile
          classN={
            isActiveSideBtn === "Document"
              ? "tab-content-box records-box active"
              : "tab-content-box records-box"
          }
          leadFiles={leadFiles}
          fileLoaded={handleOpenManageFileModal}
          lead={lead}
          projectId={projectId}
          onHandleManageFileModal={(mode, data) =>
            handleOpenManageFileModal(mode, data)
          }
          onHandleOpenConfirmationModal={(type, data) =>
            handleOpenConfirmDeleteModal(type, data)
          }
        />
      );
    default:
      break;
  }
};
