import {managerConstants} from "_constants";

/**
 * Reducer function to handle manager-related actions and update the state accordingly.
 *
 * @param {Object} state - The current state of the manager.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.managers] - The managers data (if applicable).
 * @param {Object} [action.config] - The manager configuration data (if applicable).
 * @param {Object} [action.param] - The parameter data (if applicable).
 * @param {Object} [action.error] - The error object (if applicable).
 * @returns {Object} The updated state based on the action type.
 */
export function managers(state = {}, action) {

    switch (action.type) {
        case managerConstants.GET_ALL_MANAGERS_REQUEST:
            return {
                ...state,
                getManagersLoading: true,
                getManagersLoaded: false,
            }
        case managerConstants.GET_ALL_MANAGERS_SUCCESS:
            return {
                ...state,
                getManagersLoading: false,
                getManagersLoaded: true,
                managers: action.managers.data
            }
        case managerConstants.GET_ALL_MANAGERS_FAILURE:
            return {
                ...state,
                getManagersLoading: false,
                getManagersLoaded: true,
                error: action.error
            }

        case managerConstants.GET_MANAGER_CONFIG_REQUEST:
            return {
                ...state,
                getManagersConfigLoading: true,
                getManagersConfigLoaded: false,
            }
        case managerConstants.GET_MANAGER_CONFIG_SUCCESS:
            return {
                ...state,
                getManagersConfigLoading: false,
                getManagersConfigLoaded: true,
                config: action.config.data,
            }
        case managerConstants.GET_MANAGER_CONFIG_FAILURE:
            return {
                ...state,
                getManagersConfigLoading: false,
                getManagersConfigLoaded: true,
                error: action.error
            }

        case managerConstants.DELETE_MANAGER_REQUEST:
            return {
                ...state,
                deleteManagerLoading: true,
                deleteManagerLoaded: false,
                deleteManagerSuccess: false,
            }
        case managerConstants.DELETE_MANAGER_SUCCESS:
            return {
                ...state,
                deleteManagerLoading: false,
                deleteManagerLoaded: true,
                deleteManagerSuccess: true,
            }
        case managerConstants.DELETE_MANAGER_FAILURE:
            return {
                ...state,
                deleteManagerLoading: false,
                deleteManagerLoaded: true,
                deleteManagerSuccess: false,
                error: action.error
            }

        case managerConstants.UPDATE_MANAGER_CONFIG_REQUEST:
            return {
                ...state,
            }
        case managerConstants.UPDATE_MANAGER_CONFIG_SUCCESS:
            return {
                ...state,
            }
        case managerConstants.UPDATE_MANAGER_CONFIG_FAILURE:
            return {
                ...state,
                error: action.error
            }

        case managerConstants.ADD_MANAGER_CONFIG_PARAM_REQUEST:
            return {
                ...state,
            }
        case managerConstants.ADD_MANAGER_CONFIG_PARAM_SUCCESS:
            return {
                ...state,
            }
        case managerConstants.ADD_MANAGER_CONFIG_PARAM_FAILURE:
            return {
                ...state,
                error: action.error
            }

        case managerConstants.UPDATE_MANAGER_CONFIG_PARAM_REQUEST:
            return {
                ...state,
                updateManagerConfigLoading: true,
                updateManagerConfigLoaded: false,
            }
        case managerConstants.UPDATE_MANAGER_CONFIG_PARAM_SUCCESS:
            return {
                ...state,
                updateManagerConfigLoading: false,
                updateManagerConfigLoaded: true,
                param: action.param.data
            }
        case managerConstants.UPDATE_MANAGER_CONFIG_PARAM_FAILURE:
            return {
                ...state,
                updateManagerConfigLoading: false,
                updateManagerConfigLoaded: true,
                error: action.error
            }

        case managerConstants.DELETE_MANAGER_CONFIG_PARAM_REQUEST:
            return {
                ...state,
            }
        case managerConstants.DELETE_MANAGER_CONFIG_PARAM_SUCCESS:
            return {
                ...state,
            }
        case managerConstants.DELETE_MANAGER_CONFIG_PARAM_FAILURE:
            return {
                ...state,
                error: action.error
            }


        case managerConstants.GET_PROJECT_CONFIG_PARAM_REQUEST:
            return {
                ...state,
                getProjectConfigParamsLoading: true,
                getProjectConfigParamsLoaded: false,

            }
        case managerConstants.GET_PROJECT_CONFIG_PARAM_SUCCESS:
            return {
                ...state,
                parameters: action.param.data,
                getProjectConfigParamsLoading: false,
                getProjectConfigParamsLoaded: true,
            }
        case managerConstants.GET_PROJECT_CONFIG_PARAM_FAILURE:
            return {
                ...state,
                error: action.error,
                getProjectConfigParamsLoading: false,
                getProjectConfigParamsLoaded: true,
            }

        case managerConstants.GET_PROJECT_CONFIG_OPERATOR_REQUEST:
            return {
                ...state,
            }
        case managerConstants.GET_PROJECT_CONFIG_OPERATOR_SUCCESS:
            return {
                ...state,
            }
        case managerConstants.GET_PROJECT_CONFIG_OPERATOR_FAILURE:
            return {
                ...state,
                error: action.error
            }

        default :
            return {
                ...state
            }
    }
}