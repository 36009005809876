import { httpManagerService as httpManager } from  '_services';

export const deckService = {
    getDecks,
    createDeck,
    updateDeck,
    removeDeck,
    removeDecks,
    removeDeck
}


/**
 * Fetches the decks for a given project.
 *
 * @param {string} projectId - The ID of the project for which to fetch decks.
 * @returns {Promise<Object>} A promise that resolves to the response from the API.
 */
function getDecks(projectId){
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/deck`);
}

/**
 * Creates a new deck for the specified project.
 *
 * @param {string} projectId - The ID of the project to which the deck belongs.
 * @param {Object} request - The request payload containing the deck details.
 * @returns {Promise<Object>} - A promise that resolves to the response of the deck creation.
 */
function createDeck(projectId, request){
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/deck`, request);
}

/**
 * Updates the deck for a given project.
 *
 * @param {string} projectId - The ID of the project to update the deck for.
 * @param {Object} request - The request payload containing the deck update information.
 * @returns {Promise} - A promise that resolves with the response of the update operation.
 */
function updateDeck(projectId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/deck`, request);
}

/**
 * Removes a deck from a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} deckId - The ID of the deck to be removed.
 * @returns {Promise} - A promise that resolves when the deck is successfully removed.
 */
function removeDeck(projectId, deckId){
    return httpManager.deleteJsonWithRequestingToken(`/api/v2/project/${projectId}/deck/${deckId}`);
}

/**
 * Removes multiple decks from a project.
 *
 * @param {string} projectId - The ID of the project from which decks are to be removed.
 * @param {Object} request - The request payload containing the details of the decks to be removed.
 * @returns {Promise} - A promise that resolves when the decks are successfully removed.
 */
function removeDecks(projectId, request){
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/deck/batch`, request);
}