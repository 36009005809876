import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { formActions } from '_actions';

import { MDBCol, MDBRow, MDBIcon, MDBBtn } from 'mdbreact';
import { Button, InputSelect } from "blackbox-react";
import { css, cx } from "emotion";

import { FormValidation } from '_components/FormValidation';
import DeleteConfirmationModal from '_components/Modal/DeleteConfirmationModal';

import addIcon from '_assets/images/plus.png';

const EditFormFieldUpdate = (props) => {
	const [fieldData, setFieldData] = useState(props.fieldData);
	const [fieldToDelete, setFieldToDelete] = useState('');
	const [formErrors, setFormErrors] = useState({ label: '' });
	const [fieldLabelValid, setFieldLabelValid] = useState(true);
	const [formValid, setFormValid] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		setFieldData(props.fieldData);
	}, [props.fieldData]);

	const handleToggle = () => {
		setModalOpen(!modalOpen);
	};

	const handlePassData = (data) => {
		setFieldToDelete(data);
	};

	const approveModal = (fieldToDelete) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;
		let fieldId = fieldToDelete.id;

		const deleteFieldData = {
			label: fieldToDelete.label,
			placeholder: fieldToDelete.placeholder,
			typeId: fieldToDelete.typeId
		};

		props.deleteField(projectId, formId, formGroupId, fieldId, deleteFieldData);

		setFieldToDelete({});
		props.handleFormUpdate();
		props.getBlankSelection();
	};

	const renderFieldType = (typeId) => {
		switch (typeId) {
			case 1:
				return 'Text Input';
			case 2:
				return 'Multiline Text Input';
			case 3:
				return 'Single Choice';
			case 4:
				return 'Multiple Choice';
			case 5:
				return 'Dropdown Selection';
			case 6:
				return 'Switch';
			case 7:
				return 'Slider';
			case 8:
				return 'Date and Time';
			case 9:
				return 'API Dropdown Selection';
			default:
				return null;
		}
	};

	const handleToggleUpdate = (event, value) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;
		let { name } = event.target;

		if (value === "on") {
			value = true;
		} else if (value === "off") {
			value = false;
		}

		setFieldData({
			...fieldData,
			[name]: !value
		}, () => {
			props.handleFormUpdate();
			props.updateField(projectId, formId, formGroupId, fieldData.id, fieldData);
		});
	};

	const handleToggleNumUpdate = (event, value) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;
		let { name } = event.target;

		setFieldData({
			...fieldData,
			[name]: (value === 0) ? 1 : 0
		}, () => {
			props.handleFormUpdate();
			props.updateField(projectId, formId, formGroupId, fieldData.id, fieldData);
		});
	};

	const validateField = (fieldName, value) => {
		let fieldValidationErrors = formErrors;
		let _fieldLabelValid = fieldLabelValid;

		let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

		switch (fieldName) {
			case 'label':
				if (value === "" || isAllWhitespace) {
					_fieldLabelValid = false;
					fieldValidationErrors.label = 'Please enter a label for your field.';
				} else {
					_fieldLabelValid = (value.length >= 0);
					fieldValidationErrors.label = _fieldLabelValid ? '' : 'Please enter a label for your field.';
				}
				break;
			default:
				break;
		}

		setFormErrors(fieldValidationErrors);
		setFieldLabelValid(_fieldLabelValid);
		validateForm();
	};

	const validateForm = () => {
		setFormValid(fieldLabelValid);
	};

	const handleFieldUpdate = (event) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;
		let { name, value } = event.target;

		if (name === 'character_limit') {
			value = Math.abs(value);
			value = value.toString().replace('-', '');

			event.currentTarget.value = parseInt(value);
		}

		setFieldData({
			...fieldData,
			[name]: value
		}, () => {
			if (name === 'label') {
				validateField(name, value);
			}

			if (formValid) {
				props.handleFormUpdate();
				props.updateField(projectId, formId, formGroupId, fieldData.id, fieldData);
			}
		});
	};

	const handleSelectUpdate = (name, value) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;

		setFieldData({
			...fieldData,
			[name]: value
		}, () => {
			props.handleFormUpdate();
			props.updateField(projectId, formId, formGroupId, fieldData.id, fieldData);
		});
	};

	const handleAddFieldOptions = (data) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;
		const fieldId = fieldData.id;
		const fieldOptions = fieldData.option;

		localStorage.removeItem('option');

		let blankFieldOption = { option: 'Option' };
		props.createFieldOption(projectId, formId, formGroupId, fieldId, blankFieldOption);

		setTimeout(() => {
			let activeOption = JSON.parse(localStorage.getItem('option'));

			setFieldData({
				...fieldData,
				option: [...fieldOptions, activeOption]
			});
		}, 2000);
	};

	const handleUpdateFieldOption = (event, option) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;
		const fieldId = fieldData.id;
		let value = event.target.value;

		let updatedOption = { 'option': value };
		let localOption = JSON.parse(localStorage.getItem('option'));
		let optionId = option ? option.id : localOption.id;

		if (optionId !== 'undefined') {
			if (typeof option !== 'undefined') {
				props.updateFieldOption(projectId, formId, formGroupId, fieldId, optionId, updatedOption);
			}
		}

		props.handleFormUpdate();
	};

	const handleRemoveFieldOption = (event, option) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;
		const fieldId = fieldData.id;
		const fieldOptions = fieldData.option;
		let updatedOptions = [];

		let localOption = JSON.parse(localStorage.getItem('option'));

		let optionData = option ? option : localOption;
		let optionId = option ? option.id : localOption.id;

		if (optionId !== 'undefined') {
			if (typeof option !== 'undefined') {
				props.deleteFieldOption(projectId, formId, formGroupId, fieldId, optionId, optionData);
				updatedOptions = fieldOptions.filter(x => {
					return x.id !== option.id;
				});

				setFieldData({
					...fieldData,
					option: []
				}, () => {
					setFieldData({
						...fieldData,
						option: updatedOptions
					});
				});
			}
		}
	};

	const handleSetActiveOption = (event, option) => {
		const projectId = props.projectId;
		const formId = props.formId;
		const formGroupId = props.formGroupId;
		const fieldId = fieldData.id;

		if (option && typeof option !== 'undefined') {
			props.findFieldOption(projectId, formId, formGroupId, fieldId, option.id);
		}
	};

	const handleMapOptions = (options) => {
		return options.map((option, index) => {
			return (
				<MDBRow key={index}>
					<MDBCol lg='11' xl='11'>
						<input
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13 font-weight-600'
							type='text'
							placeholder=''
							name={option ? option.id || '' : ''}
							defaultValue={option ? option.option : 'Option'}
							onClick={(event) => {
								handleSetActiveOption(event, option);
							}}
							onChange={(event) => {
								handleUpdateFieldOption(event, option);
							}}
						/>
					</MDBCol>
					<MDBCol lg='1' xl='1' className='p--0'>
						<MDBIcon
							far
							icon='times-circle'
							className='icon-remove color-red opacity-5 hand'
							onClick={(event) => {
								handleSetActiveOption(event, option);
								handleRemoveFieldOption(event, option);
							}}
						/>
					</MDBCol>
				</MDBRow>
			);
		});
	};

	const renderEditFieldSettings = (fieldData) => {
		return (
			<MDBRow className='px-2'>
				<MDBCol lg='4' xl='4' className='my-auto'>
					<p className='mb-0 text-center font-size-10 font-weight-600'>Field Settings: </p>
				</MDBCol>
				<MDBCol className='px-0 my-auto'>
					<MDBRow className='my-auto font-size-11'>
						<div className='mx-2 my-auto'>
							<label className='checkboxcontainer-edit ml-0 mt-2'>
								<input
									type='checkbox'
									name='required'
									checked={fieldData.required || ''}
									onChange={(event) => { handleToggleUpdate(event, fieldData.required || '') }}
								/>
								<span className='checkmark'> </span>
								<span>Required</span>
							</label>
						</div>
						<div className='mx-2 my-auto'>
							<label className='checkboxcontainer-edit ml-0 mt-2'>
								<input
									type='checkbox'
									name='read_only'
									checked={fieldData.read_only || ''}
									onChange={(event) => { handleToggleUpdate(event, fieldData.read_only || '') }}
								/>
								<span className='checkmark'> </span>
								<span>Read Only</span>
							</label>
						</div>
						<div className='mx-2 my-auto'>
							<label className='checkboxcontainer-edit ml-0 mt-2'>
								<input
									type='checkbox'
									name='hide_label'
									checked={fieldData.hide_label || ''}
									onChange={(event) => { handleToggleUpdate(event, fieldData.hide_label || '') }}
								/>
								<span className='checkmark'> </span>
								<span>Hide Label</span>
							</label>
						</div>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	};

	const renderEditFieldCommonFields = (fieldData) => {
		return (
			<MDBRow className='m-b--0'>
				<MDBCol>
					<MDBCol className={`form-group p--0 ${errorClass(formErrors.label)}`}>
						<label htmlFor='label' className='mb-2 font-size-14 font-weight-600'>Label</label>
						<input
							name='label'
							type='text'
							defaultValue={fieldData.label}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter label here'
							onChange={handleFieldUpdate}
						/>
						<div className={`form-validation__container ${(!formValid) ? 'display-block' : 'display-none'}`}>
							<FormValidation fieldName='label' formErrors={formErrors} />
						</div>
					</MDBCol>
					{(fieldData.type_id === 1 || fieldData.type_id === 2) &&
						<MDBCol className='p--0'>
							<label htmlFor='placeholder' className='mb-2 font-size-14 font-weight-600'>Placeholder</label>
							<input
								type='text'
								name='placeholder'
								defaultValue={fieldData.placeholder}
								className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
								placeholder='Enter placeholder here'
								onChange={handleFieldUpdate}
							/>
						</MDBCol>
					}
				</MDBCol>
			</MDBRow>
		);
	};

	const renderEditFieldTypeFields = (fieldData) => {
		switch (fieldData.type_id) {
			case 1:
				return renderTextInputFields(fieldData);
			case 2:
				return renderTextareaFields(fieldData);
			case 3:
				return renderRadioButtonFields(fieldData);
			case 4:
				return renderCheckboxFields(fieldData);
			case 5:
				return renderSelectFields(fieldData);
			case 6:
				return renderSwitchFields(fieldData);
			case 7:
				return renderSliderFields(fieldData);
			case 8:
				return renderDatepickerFields(fieldData);
			default:
				return null;
		}
	};

	const renderFieldWithOptions = (fieldData) => {
		let options = fieldData.option || '';
		let hasOptions = options.length > 0;

		return (
			<MDBCol>
				<p className='font-size-14 font-weight-600 ml-1 m-b--0'>Options</p>
				{hasOptions &&
					<MDBCol className='pl-1 pr-3 form-field__options m-t--sm m-b--sm'>
						{handleMapOptions(options)}
					</MDBCol>
				}
				<MDBBtn
					onClick={(e) => {
						e.stopPropagation();
						handleAddFieldOptions(fieldData);
					}}
					flat
					className='p--sm dotted add-field-button add-field-background color-orange shadow-none button-presets'
				>
					<img alt='' src={addIcon} className='add-icon-width-18 mr-2' />
					Add option
				</MDBBtn>
			</MDBCol>
		);
	};

	const renderTextInputFields = (fieldData) => {
		return null;
	};

	const renderTextareaFields = (fieldData) => {
		return (
			<MDBRow>
				<MDBCol id='inline' className='mb-2 mt-1'>
					<MDBRow className='ml-2 mb-2'>
						<div className='switch mr-2 my-auto height-20'>
							<input
								checked={fieldData.limited_character || ''}
								type='checkbox'
								name='limited_character'
								className='switch-checkbox'
								id='limited_character'
								onChange={(event) => {
									handleToggleUpdate(event, fieldData.limited_character || '');
								}}
							/>
						</div>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	};

	const renderRadioButtonFields = (fieldData) => {
		return (
			<MDBRow className=''>
				{renderFieldWithOptions(fieldData)}
			</MDBRow>
		);
	};

	const renderCheckboxFields = (fieldData) => {
		return (
			<MDBRow className=''>
				{renderFieldWithOptions(fieldData)}
			</MDBRow>
		);
	};

	const renderSelectFields = (fieldData) => {
		return (
			<MDBRow className=''>
				{renderFieldWithOptions(fieldData)}
			</MDBRow>
		);
	};

	const renderSwitchFields = (fieldData) => {
		const switchCss = cx(
			css`
        &:before {
          content: '${fieldData.on_label || 'ON'}';
        }
           
        &:after{
          content: '${fieldData.off_label || 'OFF'}';
        }
      `
		);
		return (
			<MDBRow className='m-b--sm'>
				<MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>ON Label</p>
						<input
							name='on_label'
							type='text'
							defaultValue={fieldData.on_label || 'ON'}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter ON label here'
							onChange={handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>OFF Label</p>
						<input
							type='text'
							name='off_label'
							defaultValue={fieldData.off_label || 'OFF'}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter placeholder here'
							onChange={handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol id='inline' className='pl-1 pr-3 custom-control custom-switch mb-2 mt-1'>
						<p className='mb-2 font-size-14 font-weight-600'>Default Switch</p>
						<MDBRow className='ml-2 mb-2'>
							<div className='switch mr-2 my-auto height-20'>
								<input
									checked={fieldData.default_value !== 0}
									type='checkbox'
									name='default_value'
									className='switch-checkbox'
									id='default_value'
									onChange={(event) => {
										handleToggleNumUpdate(event, fieldData.default_value || 0);
									}}
								/>
								<label className='switch-labels' htmlFor='default_value'>
									<span className={`switch-text ${switchCss}`} />
									<span className='switch-dot' />
								</label>
							</div>
							<label className='mb-0 mt-1 font-size-10'>Set if ON or OFF by default</label>
						</MDBRow>
					</MDBCol>
				</MDBCol>
			</MDBRow>
		);
	};

	const renderSliderFields = (fieldData) => {
		return (
			<MDBRow className='m-b--sm'>
				<MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Minimum Value</p>
						<input
							name='min_value'
							type='number'
							defaultValue={fieldData.min_value || 0}
							min={0}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter minimum value here'
							onChange={handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Maximum Value</p>
						<input
							type='number'
							name='max_value'
							defaultValue={fieldData.max_value || 100}
							min={0}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter maximum value here'
							onChange={handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Default Value</p>
						<input
							type='number'
							name='default_value'
							min={0}
							defaultValue={fieldData.default_value || 0}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='Enter default value here'
							onChange={handleFieldUpdate}
						/>
					</MDBCol>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Unit</p>
						<input
							name='on_label'
							type='text'
							defaultValue={fieldData.on_label || ''}
							className='border-gray mb-2 br-5 padding-3 px-2 font-size-13'
							placeholder='e.g. cm, kg, PHP'
							onChange={handleFieldUpdate}
						/>
					</MDBCol>
				</MDBCol>
			</MDBRow>
		);
	};

	const renderDatepickerFields = (fieldData) => {
		let textAreaClass = fieldData.time_input ? 'visible' : 'display-none';
		let dateFormatOptions = [
			{
				id: 1,
				value: 'MM/dd/yyyy',
				label: 'MM/DD/YYYY'
			},
			{
				id: 2,
				value: 'dd/MM/yyyy',
				label: 'DD/MM/YYYY'
			},
			{
				id: 3,
				value: 'MMMM d, yyyy',
				label: 'Month DD, YYYY'
			},
			{
				id: 4,
				value: 'd MMMM yyyy',
				label: 'DD Month YYYY'
			},
		];
		let timeFormatOptions = [
			{
				id: 1,
				value: 'hh:mm aa',
				label: '12-hr'
			},
			{
				id: 2,
				value: 'HH:mm aa',
				label: '24-hr'
			}
		];
		return (
			<MDBRow className='m-b--sm'>
				<MDBCol lg='12'>
					<MDBCol className='pl-1 pr-3'>
						<p className='mb-2 font-size-14 font-weight-600'>Date Format</p>
						<InputSelect
							name='date_format'
							defaultValue={fieldData.date_format || 'MMMM d, yyyy'}
							onChange={(value) => { handleSelectUpdate('date_format', value) }}
							options={dateFormatOptions}
						/>
					</MDBCol>
				</MDBCol>

				<MDBCol lg='12' id='inline' className='mb-2 mt-1'>
					<MDBRow className='ml-2 mb-2'>
						<div className='switch mr-2 my-auto height-20'>
							<input
								checked={fieldData.time_input || ''}
								type='checkbox'
								name='time_input'
								className='switch-checkbox'
								id='time_input'
								onChange={(event) => {
									handleToggleUpdate(event, fieldData.time_input || '');
								}}
							/>
							<label className='switch-labels' htmlFor='time_input'>
								<span className='switch-text' />
								<span className='switch-dot' />
							</label>
						</div>
						<label className='mb-0 mt-1 ml-0 font-size-10'>Enable Time Input?</label>
					</MDBRow>
					<MDBRow className={`${textAreaClass} 'ml-3 mb-2 br-5 ml-0 mt-3 mr-1'`}>
						<MDBCol className='ml-0 pl-1 pr-3 mr-3'>
							<p className='mb-2 font-size-14 font-weight-600'>Time Format</p>
							<InputSelect
								name='time_format'
								defaultValue={fieldData.time_format || 'hh:mm aa'}
								onChange={(value) => { handleSelectUpdate('time_format', value) }}
								options={timeFormatOptions}
							/>
						</MDBCol>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	};

	const errorClass = (error) => {
		return (error.length === 0 ? 'is-passed' : 'is-failed');
	};

	const { deleted } = props;
	if (deleted) {
		props.getBlankSelection();
	}

	return (
		<div>
			<MDBRow className='justify-content-between m-t--md m-l--md m-r--md'>
				<p className='font-size-12 m-b--0 font-weight-600'>EDIT FIELD</p>
				<p className='font-size-12 m-b--0'>
					TYPE: <span>{renderFieldType(fieldData.type_id)}</span>
				</p>
			</MDBRow>
			<hr className='m-t--md m-b--sm' />
			{renderEditFieldSettings(fieldData)}
			<hr className='m-t--sm m-b--md' />
			<div className='form-field-settings p-l--md p-r--md'>
				{renderEditFieldCommonFields(fieldData)}
				{renderEditFieldTypeFields(fieldData)}
			</div>
			<MDBCol className='position-bottom'>
				<MDBRow className='m--0'>
					<MDBCol lg='5' xl='5' className='text-left m-l--n20 m-b--sm'>
						<Button
							flat='true'
							type="submit"
							background='transparent'
							foreground='red'
							size={-2}
							onClick={(event) => { event.preventDefault(); handleToggle(); handlePassData(fieldData) }}>
							<MDBIcon icon='trash-alt' className='mr-2' />
							<u>Delete Field</u>
						</Button>
						<DeleteConfirmationModal
							toggle={handleToggle}
							modalOpen={modalOpen}
							statusToDelete={fieldToDelete}
							onApprove={approveModal}
							target={fieldData.label || ''}
						/>
					</MDBCol>
				</MDBRow>
			</MDBCol>
		</div>
	);
};

function mapState(state) {
	const { creating, forms, deleting, deleted, field_option } = state.forms;
	return { creating, forms, deleting, deleted, field_option };
}

const actionCreators = {
	createField: formActions.createField,
	updateField: formActions.updateField,
	createFieldOption: formActions.createFieldOption,
	updateFieldOption: formActions.updateFieldOption,
	getAllFields: formActions.getAllFields,
	findField: formActions.findField,
	getAllFieldOption: formActions.getAllFieldOption,
	findFieldOption: formActions.findFieldOption,
	deleteFieldOption: formActions.deleteFieldOption,
	deleteField: formActions.deleteField
};

const connectedEditFormFieldUpdate = connect(mapState, actionCreators)(EditFormFieldUpdate);
export { connectedEditFormFieldUpdate as EditFormFieldUpdate };
