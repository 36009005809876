import React, {useState} from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'

const HandleDeckDeleteModal = ({isOpen, handleOpenDeleteModal, handleCloseDeleteModal, handleDeleteDeck}) => {

  return (
    <MDBContainer className="modal-container confirmation-modal-container">
      <MDBRow>
        <MDBCol>
          <MDBModal
            className="confirmation-modal"
            isOpen={isOpen}
            centered
            tabIndex="-1"
          >
            <MDBModalBody>
              <MDBRow className="form-group-row">
                <MDBCol className="col-12">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-alert-circle"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="8" x2="12" y2="12" />
                    <line x1="12" y1="16" x2="12.01" y2="16" />
                  </svg>
                  <h3>Confirm Deletion</h3>
                  <p>
                    Are you sure you want to delete this deck?
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="modal-btns text-center">
                  <button
                    className="c-btn c-btn-light"
                    onClick={handleCloseDeleteModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="c-btn c-btn-red"
                    onClick={handleOpenDeleteModal}
                  >
                    Delete
                  </button>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default HandleDeckDeleteModal