import { fileService } from "_services";
import { fileConstants } from "_constants";
import { alertActions } from "./alert.actions";


export const fileActions = {
    getFiles,
    addFile,
    updateFile,
    deleteFile,
  };

  /**
   * Fetches all files associated with a specific project and lead.
   * Dispatches the appropriate actions based on whether the request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project to fetch files for.
   * @param {string} leadId - The ID of the lead to fetch files for.
   * @returns {function} - A function that dispatches actions for request, success, or failure results.
   */
  function getFiles(projectId, leadId) {

    return (dispatch) => {
      dispatch(request());
      fileService.getFiles(projectId, leadId).then(
        (files) => {
          dispatch(success(files));
          // dispatch(
          //   alertActions.success("Successfully resend verification to your email")
          // );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: fileConstants.GET_ALL_FILE_REQUEST };
    }
    function success(files) {
      return { type: fileConstants.GET_ALL_FILE_SUCCESS, files };
    }
    function failure(error) {
      return { type: fileConstants.GET_ALL_FILE_FAILURE, error };
    }
  }

  /**
   * Adds a new file for a specific project and lead.
   * Dispatches the appropriate actions based on whether the request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project to add a file to.
   * @param {string} leadId - The ID of the lead to associate the file with.
   * @param {Object} formData - The data of the file to be added (usually including file data).
   * @returns {function} - A function that dispatches actions for request, success, or failure results.
   */
  function addFile(projectId, leadId, formData) {
    return (dispatch) => {
      dispatch(request());
      fileService.addFile(projectId, leadId, formData).then(
        (files) => {
          dispatch(success(files));
          dispatch(
            alertActions.success("New File successfully added.")
          );
        },
        (error) => {
          console.log('error: ', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: fileConstants.ADD_FILE_REQUEST };
    }
    function success(files) {
      return { type: fileConstants.ADD_FILE_SUCCESS, files };
    }
    function failure(error) {
      return { type: fileConstants.ADD_FILE_FAILURE, error };
    }
  }
  
  /**
   * Updates an existing file for a specific project, lead, and file.
   * Dispatches the appropriate actions based on whether the update request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project to update the file for.
   * @param {string} leadId - The ID of the lead associated with the file.
   * @param {string} fileId - The ID of the file to be updated.
   * @param {Object} formData - The updated file data (e.g., file content or metadata).
   * @returns {function} - A function that dispatches actions for request, success, or failure results.
   */
  function updateFile(projectId, leadId, fileId, formData) {
    return (dispatch) => {
      dispatch(request());
      fileService.updateFile(projectId, leadId, fileId, formData).then(
        (files) => {
          dispatch(success(files));
          dispatch(
            alertActions.success("File successfully updated.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: fileConstants.UPDATE_FILE_REQUEST };
    }
    function success(files) {
      return { type: fileConstants.UPDATE_FILE_SUCCESS, files };
    }
    function failure(error) {
      return { type: fileConstants.UPDATE_FILE_FAILURE, error };
    }
  }

  /**
   * Deletes a file for a specific project, lead, and file.
   * Dispatches the appropriate actions based on whether the delete request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project from which the file is being deleted.
   * @param {string} leadId - The ID of the lead associated with the file to be deleted.
   * @param {string} fileId - The ID of the file to be deleted.
   * @returns {function} - A function that dispatches actions for request, success, or failure results.
   */
  function deleteFile(projectId, leadId, fileId) {

    return (dispatch) => {
      dispatch(request());
      fileService.deleteFile(projectId, leadId, fileId).then(
        (files) => {
          dispatch(success(files));
          dispatch(
            alertActions.success("Delete File successful.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: fileConstants.DELETE_FILE_REQUEST };
    }
    function success(files) {
      return { type: fileConstants.DELETE_FILE_SUCCESS, files };
    }
    function failure(error) {
      return { type: fileConstants.DELETE_FILE_FAILURE, error };
    }
  }