import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { influencerActions } from '_actions';
import { utilitiesService } from '_services';
import Moment from 'react-moment';

export  const ActivitiesTab = ({ classN, projectId, influencerId }) => {
  const dispatch = useDispatch();
  const activities = useSelector(state => state.influencers.activities);
  const getInfluencerActivitiesLoaded = useSelector(state => state.influencers.getInfluencerActivitiesLoaded);
  const getInfluencerActivitiesLoading = useSelector(state => state.influencers.getInfluencerActivitiesLoading);

  useEffect(() => {
    dispatch(influencerActions.getInfluencerActivities(projectId, influencerId));
  }, [dispatch, projectId, influencerId]);

  return (
    <div className={classN}>
      {getInfluencerActivitiesLoaded && (
        <ul className="list-unstyled">
          {activities.map((activity, index) => {
            let action = activity.action.replace(
              '%value',
              `<span className="highlight-text">${activity.value}</span>`
            );
            const actionHtml = { __html: `<span>${action}</span>` };
            const actor = `${utilitiesService.decrypt(activity.creator.firstName)} ${utilitiesService.decrypt(activity.creator.lastName)}`;

            return (
              <li key={index}>
                <div className="activity-box">
                  <div className='activity-icon-group'>
                    <div className='icon-box'>
                      <i className="far fa-user"></i>
                    </div>
                    <div className='line-box'></div>
                  </div>
                  <div className="activity-detail-group">
                    <div className='main-details' dangerouslySetInnerHTML={actionHtml} />
                    <div className="sub-details">
                      <span>
                        <span>{actor}</span>
                        <i data-test="fa" className="fa fa-circle"></i>
                      </span>
                      <span>
                        <Moment date={activity.created} fromNow ago /> ago
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {getInfluencerActivitiesLoading && (
        <div className="loading-box">
          <div className='loader-box'>
            <div className="loader-custom">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};