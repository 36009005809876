import React from "react";
import ButtonGroupLoader from "../../../../_components/Loader/ButtonGroupLoader";
import { CircularLoader } from "../../../../_components/CircularLoader";


const LeadButtonActionGroup = ({ 
    chats,
    chatCount,
    leadLoaded,
    handleEditLeadDrawer,
    handleSideActivityButton,
    getChatsLoaded,
    email,
    emailCount,
    getEmailLoaded,
    projectId,
    calendlyAccount,
    handleCalendlyDrawer,
    handleDeleteLead
}) => {

  // show loader if lead is not loaded
  if (!leadLoaded) return <ButtonGroupLoader />;

  // return the component
  return (
    <div className="button-group">
      <div className="btn-chat-email-group">
        {chats && (
          <button onClick={() => handleSideActivityButton("Chat")}>
            <i className="fas fa-comment-alt"></i>
            <span className={chatCount == 0 ? "empty" : ""}>
              {!getChatsLoaded && <CircularLoader />}
              {getChatsLoaded && chatCount > 9 ? "9+" : chatCount}
            </span>
          </button>
        )}
        {email && (
          <button onClick={() => handleSideActivityButton("Email")}>
            <i className="fas fa-envelope"></i>
            <span className={emailCount == 0 ? "empty" : ""}>
              {!getEmailLoaded && <CircularLoader />}
              {getEmailLoaded && emailCount > 9 ? "9+" : emailCount}
            </span>
          </button>
        )}
      </div>
      <button
        className="c-btn c-btn-light"
        onClick={() => handleEditLeadDrawer()}
      >
        <i className="icon-lms icon-edit"></i>
        Edit Lead
      </button>
      <button
        className="c-btn c-btn-light"
        onClick={() => handleDeleteLead()}
      >
        <i className="icon-lms icon-delete"></i>
        Delete Lead
      </button>
      {projectId == 5 && (
        <button
          disabled={calendlyAccount == null}
          className={calendlyAccount == null ? "c-btn c-btn-disabled" : "c-btn"}
          onClick={() => handleCalendlyDrawer()}
        >
          <i className="icon-lms icon-calendly"></i>
          Send Calendly Invite
        </button>
      )}
    </div>
  );
};

export default LeadButtonActionGroup;