
import {alertActions} from "_actions/alert.actions";
import {utmConstants} from "_constants";
import {utmService} from "_services/utm.service";
import {history} from "_helpers";

export const utmActions = {
    getUtms,
    generateUtm,
    getCodex,
    getPresets,
}

/**
 * Fetches UTM data for a given project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string|null} [query=null] - The query string for filtering UTM data.
 * @param {number} [page=1] - The page number for pagination.
 * @param {number} [size=15] - The number of items per page.
 * @returns {Function} A redux-thunk function that dispatches actions based on the UTM data fetching process.
 */
function getUtms(projectId,  query = null, page = 1, size = 15) {
    return dispatch => {
        dispatch(request(projectId,  query, page, size));
        utmService.getUtms(projectId,  query, page, size)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId,  query = null, page = 1, size = 15) {
        return {type: utmConstants.GET_UTMS_REQUEST, projectId, query, page, size}
    }

    function success(data) {
        return {type: utmConstants.GET_UTMS_SUCCESS, data}
    }

    function failure(error) {
        return {type: utmConstants.GET_UTMS_FAILURE, error}
    }
}

/**
 * Generates UTM for a given project.
 *
 * @param {string} projectId - The ID of the project for which the UTM is being generated.
 * @param {Object} req - The request object containing necessary data for UTM generation.
 * @returns {Function} A thunk function that dispatches actions based on the UTM generation process.
 *
 * @dispatches {Object} request - Action to indicate the UTM generation request has started.
 * @dispatches {Object} success - Action to indicate the UTM generation was successful.
 * @dispatches {Object} failure - Action to indicate the UTM generation failed.
 * @dispatches {Object} alertActions.success - Action to show a success alert.
 * @dispatches {Object} alertActions.error - Action to show an error alert.
 * @dispatches {Function} getUtms - Function to fetch the list of UTMs for the project.
 */
function generateUtm(projectId,  req) {
    return dispatch => {
        dispatch(request(projectId, req));
        utmService.generateUtm(projectId,  req)
            .then(
                res => {
                    dispatch(success(res));
                    history.push(`/project/${projectId}/utm`);
                    dispatch(alertActions.success("successfully generated utm"));
                    dispatch(getUtms(projectId));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId, req)   {
        return {type: utmConstants.GENERATE_UTM_REQUEST, projectId, req}
    }

    function success(res) {
        return {type: utmConstants.GENERATE_UTM_SUCCESS, res}
    }

    function failure(error) {
        return {type: utmConstants.GENERATE_UTM_FAILURE, error}
    }
}

/**
 * Fetches the codex for a given project and dispatches appropriate actions based on the result.
 *
 * @param {Object} project - The project for which to fetch the codex.
 * @returns {Function} A thunk function that dispatches actions based on the result of the codex fetch.
 */
function getCodex(project) {
    return dispatch => {
        dispatch(request(project));
        utmService.getCodex(project)
            .then(
                codex => {
                    dispatch(success(codex));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project) {
        return {type: utmConstants.GET_UTM_CODEX_REQUEST, project}
    }

    function success(codex) {
        return {type: utmConstants.GET_UTM_CODEX_SUCCESS, codex}
    }

    function failure(error) {
        return {type: utmConstants.GET_UTM_CODEX_FAILURE, error}
    }
}

/**
 * Fetches presets for a given project and dispatches appropriate actions based on the result.
 *
 * @param {string} projectId - The ID of the project to fetch presets for.
 * @returns {Function} A thunk function that dispatches actions based on the result of the API call.
 */
function getPresets(projectId) {
    return dispatch => {
        dispatch(request(projectId));
        utmService.getPresets(projectId)
            .then(
                presets => {
                    dispatch(success(presets));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(projectId) {
        return {type: utmConstants.GET_PROJECT_PRESETS_REQUEST, projectId}
    }

    function success(presets) {
        return {type: utmConstants.GET_PROJECT_PRESETS_SUCCESS, presets}
    }

    function failure(error) {
        return {type: utmConstants.GET_PROJECT_PRESETS_FAILURE, error}
    }
}
