import React, { useState, useRef, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import { influencerActions } from "_actions";
import { connect } from "react-redux";

const ManagePlatformModal = ({ 
  influencers, 
  isOpen, 
  mode, 
  data, 
  handleCloseManagePlatformModal, 
  addInfluencerPlatform, 
  updateInfluencerPlatform, 
  influencerId, 
  projectId 
}) => {

  const [isTypeError, setIsTypeError] = useState(false);
  const [isLinkError, setIsLinkError] = useState(false);
  const [isValueError, setIsValueError] = useState(false);
  const [isOtherError, setIsOtherError] = useState(false);
  const [isOthers, setIsOthers] = useState(false);
  const [toggleTabState, setToggleTabState] = useState(1);

  const typeInput = useRef();
  const linkInput = useRef();
  const valueInput = useRef();
  const othersInput = useRef();

  useEffect(() => {
    if (mode === 'edit') {
      linkInput.current.value = data.link;
      valueInput.current.value = data.value;
    } else {
      handleClearInput();
    }
  }, [mode, data]);

  const handleClose = () => {
    handleCloseManagePlatformModal(mode);
    handleClearInput();
  };

  const handleClearInput = () => {
    if (mode === 'add') {
      typeInput.current.value = 'Select Platform';
      linkInput.current.value = '';
      valueInput.current.value = '';
      othersInput.current.value = '';
    }
    if (mode === 'edit') {
      linkInput.current.value = data.link;
      valueInput.current.value = data.value;
    }
  };

  const setType = (value) => {
    setIsTypeError(false);
    setIsOthers(value === "platform-others");
  };

  const setLink = () => {
    setIsLinkError(false);
  };

  const setValue = () => {
    setIsValueError(false);
  };

  const setAlt = () => {
    setIsOtherError(false);
  };

  const isFieldValid = () => {
    let isValid = true;

    let type = "";
    if(typeInput.current){
      type = typeInput.current.value;
      if (type === 'Select Platform' || type === '') {
        setIsTypeError(true);
        isValid = false;
      }
    }
    

    if (type === 'platform-others') {
      const others = othersInput.current.value;
      if (others === '') {
        setIsOtherError(true);
        isValid = false;
      }
    }

    const link = linkInput.current.value;
    const value = valueInput.current.value;
    
    if (link === '') {
      setIsLinkError(true);
      isValid = false;
    }

    if (value === '') {
      setIsValueError(true);
      isValid = false;
    }

    return isValid;
  };

  const handleManagePlatform = () => {
    if (isFieldValid()) {
      if (mode === 'edit') {
        handleEditInfluencerPlatform();
      } else {
        handleAddInfluencerPlatform();
      }
    }
  };

  const handleAddInfluencerPlatform = () => {
    const type = typeInput.current ? typeInput.current.value : "";
    const link = linkInput.current.value;
    const value = valueInput.current.value;
    const others = othersInput.current.value;

    let platform = { type, link, value, alt: '' };

    if (type === 'platform-others') {
      platform.alt = others;
    }

    addInfluencerPlatform(projectId, influencerId, platform);
    handleClose();
  };

  const handleEditInfluencerPlatform = () => {
    const link = linkInput.current.value;
    const value = valueInput.current.value;

    const platform = { link, value };

    updateInfluencerPlatform(projectId, influencerId, data.id, platform);
    handleClose();
  };

  const toggleTab = (index) => {
    setToggleTabState(index);
  };

  const types = influencers.getPlatFormTypeLoaded ? influencers.platformTypes : [];

  return (
    <React.Fragment>
      <div className={`drawer-overlay ${isOpen ? 'active' : ''}`} onClick={handleClose}></div>
      <div className={`side-drawer-main ${isOpen ? 'active' : ''}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <h3>{mode.toUpperCase()} SOCIAL MEDIA PLATFORM</h3>
            <span className="side-drawer-close" onClick={handleClose}>
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="control-tabs-box">
                <div
                  className={toggleTabState === 1 ? 'tabs-btn active' : 'tabs-btn'}
                  onClick={() => toggleTab(1)}
                >
                  <p>Platform Information</p>
                </div>
              </div>
              <div className="content-tabs-box">
                <div className={toggleTabState === 1 ? 'tabs-content active' : 'tabs-content'}>
                  <div className="forms-tab">
                    <MDBRow>
                      <MDBCol className="col-12 form-group-box">
                        <div className="input-group-box full-box-">
                          {mode === 'edit' ? null : (
                            <div className="field-box">
                              <label htmlFor="platform_type">
                                Social Media Platform <small>*</small>
                              </label>
                              <select
                                className="c-select"
                                ref={typeInput}
                                id="platform_type"
                                name="platform_name"
                                onChange={(e) => setType(e.target.value)}
                              >
                                <option hidden>Select Platform</option>
                                {types.map((type, index) => (
                                  <option value={type.code} key={index}>
                                    {type.name}
                                  </option>
                                ))}
                              </select>
                              {isTypeError && <p className="error-field">This field is required</p>}
                              <div className={isOthers ? 'other-platform active' : 'other-platform'}>
                                <label htmlFor="alt">Others</label>
                                <input ref={othersInput} id="alt" name="alt" onChange={setAlt} type="text" />
                                {isOtherError && <p className="error-field">This field is required</p>}
                              </div>
                            </div>
                          )}
                          <div className={isLinkError ? 'field-box error-field' : 'field-box'}>
                            <label htmlFor="link">
                              Social Media Url <small>*</small>
                            </label>
                            <input
                              ref={linkInput}
                              id="link"
                              name="link"
                              defaultValue={mode === 'edit' ? data.link : ''}
                              onChange={setLink}
                              type="text"
                            />
                            {isLinkError && <p className="error-field">This field is required</p>}
                          </div>
                          <div className={isValueError ? 'field-box error-field' : 'field-box'}>
                            <label htmlFor="value">
                              Followers / Subscribers <small>*</small>
                            </label>
                            <input
                              ref={valueInput}
                              id="value"
                              name="value"
                              defaultValue={mode === 'edit' ? data.value : ''}
                              onKeyPress={(e) => {
                                const charCode = e.which ? e.which : e.keyCode;
                                if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={setValue}
                              type="number"
                            />
                            {isValueError && <p className="error-field">This field is required</p>}
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </div>
              <div className="tab-form-btn-group">
                <MDBRow>
                  <MDBCol className="text-right">
                    <span className="drawer-btn cancel-btn" onClick={handleClose}>
                      Cancel
                    </span>
                    <span className="drawer-btn save-btn" onClick={handleManagePlatform}>
                      {mode === 'add' ? 'Save Platform' : 'Update Platform'}
                    </span>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapState = (state) => {
  const { influencers } = state;
  return { influencers };
};

const actionCreators = {
  addInfluencerPlatform: influencerActions.addInfluencerPlatform,
  updateInfluencerPlatform: influencerActions.updateInfluencerPlatform,
  getPlatformTypes: influencerActions.getPlatformTypes,
};

const connectedManagePlatformModal = connect(mapState, actionCreators)(ManagePlatformModal);
export { connectedManagePlatformModal as ManagePlatformModal };