import React, { useState, useEffect } from "react";
import { influencerActions, projectActions } from "_actions";
import { connect } from "react-redux";
import { MDBCol, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBRow } from "mdbreact";
import FormGroupTitle from "_pages/Propelrr/components/Form/FormGroupTitle";
import InputForm from "_pages/Propelrr/components/Form/InputForm";
import { utilitiesService } from "_services";

const EditInfluencerModal = ({
    isOpen, 
    data, 
    influencerLoaded, 
    projectId, 
    influencerId, 
    updateInfluencer, 
    onHandleCloseEditInfluencerModal
}) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        contact: "",
        contacted: "",
        handlerName: "",
        handlerEmail: "",
        handlerContact: "",
    });

    useEffect(() => {
        if (influencerLoaded) {
            setFormData({
                name: data.name || "",
                email: utilitiesService.decrypt(data.email) || "",
                contact: utilitiesService.decrypt(data.contact) || "",
                contacted: data.contacted ? new Date(data.contacted).toISOString().substring(0, 10) : "",
                handlerName: data.handler ? utilitiesService.decrypt(data.handler.name) : "",
                handlerEmail: data.handler ? utilitiesService.decrypt(data.handler.email) : "",
                handlerContact: data.handler ? utilitiesService.decrypt(data.handler.contact) : "",
            });
        }
    }, [influencerLoaded, data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleUpdateInfluencer = () => {
        const influencer = {
            name: formData.name || data.name,
            contacted: formData.contacted || data.contacted,
            email: formData.email ? utilitiesService.encrypt(formData.email) : data.email,
            contact: formData.contact ? utilitiesService.encrypt(formData.contact) : data.contact,
            handler_name: formData.handlerName ? utilitiesService.encrypt(formData.handlerName) : data.handler.name,
            handler_email: formData.handlerEmail ? utilitiesService.encrypt(formData.handlerEmail) : data.handler.email,
            handler_contact: formData.handlerContact ? utilitiesService.encrypt(formData.handlerContact) : data.handler.contact,
        };

        updateInfluencer(projectId, influencerId, influencer);
        onHandleCloseEditInfluencerModal();
        setFormData({
            name: "",
            email: "",
            contact: "",
            contacted: "",
            handlerName: "",
            handlerEmail: "",
            handlerContact: "",
        });
    };

    return (
        <MDBContainer className='modal-container modal-form-container modal-form-influencer'>
            <MDBRow>
                <MDBCol>
                    <MDBModal isOpen={isOpen} centered tabIndex='-1'>
                        <MDBModalHeader className='confirmation-modal-header text-uppercase p-2'>
                            <span className='pl-1 mx-2 letter-spacing-1 font-weight-600 font-color-gray'>EDIT INFLUENCER</span>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <form className="form-add-influencer">
                                <MDBRow>
                                    <MDBCol xl='12' lg='12' className='form-group-box'>
                                        <FormGroupTitle label="Influencer Information" />
                                        <div className='input-group-box half-box-'>
                                            <InputForm defaultValue={formData.name} label='Influencer Name' type='text' name='name' onChange={handleChange} />
                                            <InputForm defaultValue={formData.email} label='Email Address' type='email' name='email' onChange={handleChange} />
                                            <InputForm defaultValue={formData.contact} label='Phone Number' type='tel' name='contact' onChange={handleChange} />
                                            <InputForm defaultValue={formData.contacted} label='Date Contacted' type='date' name='contacted' onChange={handleChange} />
                                        </div>
                                        <div className='input-group-box full-box-'>
                                            <InputForm defaultValue={formData.handlerName} label='Handler Name' type='text' name='handlerName' onChange={handleChange} />
                                            <InputForm defaultValue={formData.handlerEmail} label='Email Address' type='email' name='handlerEmail' onChange={handleChange} />
                                            <InputForm defaultValue={formData.handlerContact} label='Phone Number' type='tel' name='handlerContact' onChange={handleChange} />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </form>
                            <MDBRow>
                                <MDBCol className='text-center'>
                                    <button className='modal-btn save-btn-modal' onClick={handleUpdateInfluencer}>Save Influencer</button>
                                    <button className='modal-btn cancel-btn-modal' onClick={onHandleCloseEditInfluencerModal}>Cancel</button>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

function mapState(state) {
    const { influencers } = state;
    return { influencers, influencerLoaded: influencers.influencerLoaded };
}

const actionCreators = {
    getProject: projectActions.getProject,
    updateInfluencer: influencerActions.updateInfluencer
};

const connectedEditInfluencerModal = connect(mapState, actionCreators)(EditInfluencerModal);
export { connectedEditInfluencerModal as EditInfluencerModal };