import { publicConstants } from '_constants';
import { publicService } from '_services';
import { alertActions } from './';
import { environment } from "_environments/environment";

export const publicActions = {
    googleConnectOauth,
    googleConnectAuthorize,
    updateConnection,
    disconnectGmail,
    generateForm,
    addLead
};

/**
 * Initiates the OAuth connection process for Google.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} redirect - The URL to redirect to after authorization.
 * @returns {function} A thunk function that dispatches actions based on the OAuth connection process.
 */
function googleConnectOauth(project_id, form_id, redirect){
    //const redirect = googleConnectAuthorize(project_id, form_id);
    return dispatch => {
        dispatch(request(project_id, form_id, redirect));
        publicService.googleConnectOauth(project_id, form_id, redirect)
            .then(
                connect_oauth => {
                    window.location.href = connect_oauth.data.url;
                  dispatch(success(connect_oauth));
                },
                error => {
                    dispatch( failure(error.toString()));
                }
            );
    };

    function request(connect_oauth) {
        return { type: publicConstants.GET_OAUTH_CONNECT_REQUEST, connect_oauth } }
    function success(project_id, form_id, connect_oauth) {
      return { type: publicConstants.GET_OAUTH_CONNECT_SUCCESS, connect_oauth }
    }
    function failure(error) { return { type: publicConstants.GET_OAUTH_CONNECT_FAILURE, error } }
}

/*function googleConnectAuthorize( state){
    return dispatch => {
        dispatch(request( state));
        publicService.googleConnectAuthorize( state)
            .then(
                connect_oauth => {
                    dispatch(success(connect_oauth));
                    //gmailConnectWindow.close();
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(connect_oauth) { return { type: publicConstants.GET_OAUTH_AUTHORIZE_REQUEST, connect_oauth } }
    function success(connect_oauth) { return { type: publicConstants.GET_OAUTH_AUTHORIZE_SUCCESS, connect_oauth } }
    function failure(error) { return { type: publicConstants.GET_OAUTH_AUTHORIZE_FAILURE, error } }
}*/

/**
 * Initiates the Google Connect authorization process.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} state - The state parameter for the authorization request.
 * @returns {Function} A thunk function that dispatches actions based on the authorization process.
 */
function googleConnectAuthorize(project_id, form_id, state) {
    return dispatch => {
        dispatch(request(project_id, form_id, state));
        publicService.googleConnectAuthorize(project_id, form_id, state)
            .then(
                connect_oauth => {
                    dispatch(success(connect_oauth));
                    let projectId = localStorage.getItem('projectId');
                    let form = JSON.parse(localStorage.getItem('form'));
                    let formId = form.form_id;
                    let pathName = window.location.pathname;
                    if (pathName.includes('email-connection/success')) {
                        window.location.href = `${environment.baseUrl}/project/${projectId}/form/${formId}/email-connection`;
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(connect_oauth) {return {type: publicConstants.GET_OAUTH_AUTHORIZE_REQUEST, connect_oauth}}
    function success(connect_oauth) {return {type: publicConstants.GET_OAUTH_AUTHORIZE_SUCCESS, connect_oauth}}
    function failure(error) {return {type: publicConstants.GET_OAUTH_AUTHORIZE_FAILURE, error}}
}

/**
 * Disconnects a Gmail account associated with a specific project and form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {string} connectionId - The ID of the Gmail connection to be disconnected.
 * @returns {Function} A redux-thunk function that dispatches actions based on the result of the disconnect operation.
 */
function disconnectGmail(project_id, form_id, connectionId){
  return dispatch => {
    dispatch(request(project_id, form_id, connectionId));
    publicService.disconnectGmail(project_id, form_id, connectionId)
      .then(
        connection => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(connectionId) { return { type: publicConstants.DISCONNECT_GMAIL_REQUEST, connectionId } }
  function success() { return { type: publicConstants.DISCONNECT_GMAIL_SUCCESS } }
  function failure(error) { return { type: publicConstants.DISCONNECT_GMAIL_FAILURE, error } }
}

/**
 * Updates the connection for a given project and form.
 *
 * @param {string} project_id - The ID of the project.
 * @param {string} form_id - The ID of the form.
 * @param {Object} connection - The connection object to be updated.
 * @returns {Function} A thunk function that dispatches actions based on the update connection process.
 */
function updateConnection(project_id, form_id, connection){
    return dispatch => {
        dispatch(request(project_id, form_id, connection));
        publicService.updateConnection(project_id, form_id, connection)
            .then(
              connection => {
                    dispatch(success(connection));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(connection) { return { type: publicConstants.UPDATE_CONNECTION_REQUEST, connection } }
    function success(connection) { return { type: publicConstants.UPDATE_CONNECTION_SUCCESS, connection } }
    function failure(error) { return { type: publicConstants.UPDATE_CONNECTION_FAILURE, error } }
}

/**
 * Generates a form based on the provided access key.
 * Dispatches request, success, and failure actions accordingly.
 *
 * @param {string} access_key - The access key used to generate the form.
 * @returns {Function} A thunk function that handles the asynchronous form generation.
 */
function generateForm(access_key){
    return dispatch => {
        dispatch(request(access_key));
        publicService.generateForm(access_key)
            .then(
                generate_form => {
                    dispatch(success(generate_form));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(generate_form) { return { type: publicConstants.GET_PUBLIC_FORM_REQUEST, generate_form } }
    function success(generate_form) { return { type: publicConstants.GET_PUBLIC_FORM_SUCCESS, generate_form } }
    function failure(error) { return { type: publicConstants.GET_PUBLIC_FORM_FAILURE, error } }
}

/**
 * Adds a lead using the provided access key.
 *
 * This function dispatches a request action, then calls the publicService.addLead method.
 * If the call is successful, it dispatches a success action with the added lead.
 * If the call fails, it dispatches a failure action with the error message and an alert action.
 *
 * @param {string} access_key - The access key to add the lead.
 * @returns {Function} A thunk function that accepts a dispatch function as an argument.
 */
function addLead(access_key){
    return dispatch => {
        dispatch(request(access_key));
        publicService.addLead(access_key)
            .then(
                add_lead => {
                    dispatch(success(add_lead));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(add_lead) { return { type: publicConstants.ADD_LEAD_PUBLIC_FORM_REQUEST, add_lead } }
    function success(add_lead) { return { type: publicConstants.ADD_LEAD_PUBLIC_FORM_SUCCESS, add_lead } }
    function failure(error) { return { type: publicConstants.ADD_LEAD_PUBLIC_FORM_FAILURE, error } }
}