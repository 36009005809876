import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {formActions} from '_actions';
import { utilitiesService as utility } from '_services';

import {MDBBtn, MDBCol, MDBContainer, MDBRow} from 'mdbreact';
import {Button, ButtonGroup, Icon} from "blackbox-react";
import * as ReactQuill from 'react-quill';

import Slider from '_pages/Forms/FormBuilder/components/Slider';

import 'react-quill/dist/quill.snow.css';

ReactQuill.modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
  ],
  clipboard: {
    matchVisual: false,
  }
};

ReactQuill.formats = [
  'bold',
  'italic',
  'underline',
  'indent',
];

class FormSuccessMessage extends Component {
  constructor(props) {
    super(props);

    const projectId = JSON.parse(localStorage.getItem('projectId'));
    let form = JSON.parse(localStorage.getItem('form'));
    let formId = form.form_id;
    let formMessage = JSON.parse(localStorage.getItem('formMessage'));
    let defaultSuccessFormMessage = {
      subject: "We have received your submission",
      body: "<p>Thank you for your submission</p>",
      testEmail: form.email_connection.gmail ? utility.decrypt(form.email_connection.gmail) : ''
    };

    if(formMessage === null){
      formMessage = '';
    }

    if(formMessage === ''){
      this.props.updateFormMessage(projectId, formId, defaultSuccessFormMessage);
    }

    this.state = {
      formMessage: formMessage !== '' ? formMessage : defaultSuccessFormMessage,
      isOpen: false,
      tbDomain: '',
      isDomainValid: false
    };
  }

  getFormData = () => {
    return JSON.parse(localStorage.getItem('form'));
  };

  getFormMessageData = () => {
    let formMessageData = JSON.parse(localStorage.getItem('formMessage'));
    return formMessageData || {};
  };

  componentDidMount = () => {
    this.handleGetFormMessageData();

    localStorage.removeItem('isActiveModule');
    localStorage.setItem('isActiveModule', 'forms');
  };

  handleFieldUpdate = (event, formMessage) => {
    const projectId = JSON.parse(localStorage.getItem('projectId'));
    let form = JSON.parse(localStorage.getItem('form'));
    let formId = form.form_id;
    let { name, value } = event.target;

    this.setState({
      formMessage: {
        ...formMessage,
        [name]: value
      }
    }, () => {
      this.props.updateFormMessage(projectId, formId, this.state.formMessage);
      this.handleGetFormMessageData();
    });
  };

  handleGetFormMessageData = () => {
    let projectId = JSON.parse(localStorage.getItem('projectId'));
    let form = JSON.parse(localStorage.getItem('form'));
    let formId = form.form_id;

    this.props.getFormMessage(projectId, formId);

    return JSON.parse(localStorage.getItem('formMessage'));
  };

  handleQuillChange = (event, value) => {
    const projectId = JSON.parse(localStorage.getItem('projectId'));
    let form = JSON.parse(localStorage.getItem('form'));
    let formId = form.form_id;
    const { formMessage } = this.state;
    //let { name, value } = event;

    this.setState({
      formMessage: {
        ...formMessage,
        body: event,
      }
    }, () => {
      this.props.updateFormMessage(projectId, formId, this.state.formMessage);
      this.handleGetFormMessageData();
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSendTestEmail = (e) => {
    let projectId = JSON.parse(localStorage.getItem('projectId'));
    let form = JSON.parse(localStorage.getItem('form'));
    let formId = form.form_id;

    this.props.sendTestEmailMessage(projectId, formId);
  };

  render() {
    let projectId = JSON.parse(localStorage.getItem('projectId'));
    let form = JSON.parse(localStorage.getItem('form'));
    let formId = form.form_id;
    let { formData } = this.props;
    let { formMessage } = this.state.formMessage;

    let hasFormData = false;
    let hasMessageData = false;

    if(typeof formData !== 'undefined'){
      hasFormData = true;
    } else{
      formData = this.getFormData();
      hasFormData = true;
    }

    if(typeof formMessage !== 'undefined'){
      hasMessageData = true;
    } else{
      formMessage = this.getFormMessageData();
      hasMessageData = true;
    }

    return (
      <MDBContainer fluid className='pt-3 pr-3'>
        <MDBRow className='ml-2 mr-4'>
          <MDBCol lg='3' xl='2' md='4'>
            <MDBCol className='p-0 mt-4'>
              <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
              <p className='font-family-crimson font-size-30 line-height-7 color-orange line-height-6'>Forms</p>
            </MDBCol>
            <MDBCol xl='11' lg='9' className='text-center font-size-14 pl-0 mt-5'>
              <Slider activeFormView='successMessage' stageNum='3'/>
            </MDBCol>
            <ButtonGroup>
              <Link to={`/project/${projectId}/forms`} className='button-end'>
                <Button
                  flat='true'
                  background='transparent' foreground='black'
                  borderWidth={2} borderColor='dark' size={-1}
                  styles={{ 'width': '100%', 'opacity': '0.8' }}>
                  <Icon color='black' name='chevron' rotate={180} />
                  <span className='color-black font-weight-bold'>View All Forms</span>
                </Button>
              </Link>
            </ButtonGroup>
          </MDBCol>
          <MDBCol lg='9' xl='10' md='8' className='px-0'>
            <MDBRow className='d-flex pb-2'>
              <MDBCol lg='7' className='my-auto'>
                <MDBRow className='pl-3'>
                  <p className='font-family-crimson font-size-22 letter-spacing-1 my-auto pr-3'>{ hasFormData ? formData.info.name : null }</p>
                  <div className='progress col-lg-3 col-xl-3 my-auto'>
                    <span className='progress-value text-white font-weight-600 my-auto'>75% complete</span>
                    <div className='progress-bar progress-background' style={{ width: '76%' }} />
                  </div>
                </MDBRow>
              </MDBCol>
              <MDBCol lg='5' className='text-right pr-2'>
                <ButtonGroup>
                  <Link to={`/project/${projectId}/form/${formId}/email-connection`}>
                    <Button
                      flat='true'
                      background='transparent' foreground='black'
                      borderWidth={2} borderColor='dark' size={-2}
                      styles={{ 'opacity': '0.5' }}>
                      <Icon color='black' name='chevron' rotate={180} />
                      <span className='color-black font-weight-bold'>Back</span>
                    </Button>
                  </Link>
                  <Link to={`/project/${projectId}/form/${formId}/integration`}>
                    <Button
                      flat='true'
                      background='orange' foreground='white'
                      borderWidth={2} borderColor='orange' size={-2}>
                      <span className='color-white font-weight-bold'>Next</span>
                    </Button>
                  </Link>
                </ButtonGroup>
              </MDBCol>
            </MDBRow>
            <MDBCol className='background-rectangle mt-2 px-0 mb-3'>
              <MDBCol>
                <MDBRow className='pl-0'>
                  <MDBCol lg='7' xl='7' className='py-2'>
                    <p className='mt-3 mb-0 mx-4 font-size-14 font-weight-600'>SUCCESS MESSAGE</p>
                    <hr className='my-3' />
                    { hasMessageData &&
                    <MDBCol>
                      <MDBRow className='p-t--sm p-l--md p-r--md'>
                        <MDBCol md='12' className='px-0'>
                          <p className='mb-0 font-weight-bold font-size-13 pb-2'>Subject <span className='color-red'>*</span></p>
                          <input
                            name='subject'
                            placeholder='Enter subject'
                            //onChange={this.handleChange.bind(this)}
                            type='text'
                            defaultValue={ formMessage.subject || 'We have received your submissions' }
                            onChange={(event) => { this.handleFieldUpdate(event, formMessage) }}
                            // name='tbSubject'
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className='p--md'>
                        <MDBCol md='12' className='m-t--sm p--0 pb-2 pt-4'>
                          <p className='mb-0 font-weight-bold font-size-13 pb-2'>Message <span className='color-red'>*</span></p>
                          <ReactQuill
                            name='body'
                            modules={ReactQuill.modules}
                            formats={ReactQuill.formats}
                            id='quill-message'
                            className='height-210'
                            defaultValue={ formMessage.body || 'Thank you for your submission.' }
                            onChange={(event) => { this.handleQuillChange(event, formMessage.body) }}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className='p--md'>
                        <MDBCol md='12' className='m-t--md p--0 pb-2 pt-4'>
                          <p className='mb-0 font-weight-bold font-size-13 pb-2'>Send test email to: <span className='color-red'>*</span></p>
                          <MDBRow className='d-flex'>
                            <div className='col m-auto'>
                              <input
                                type='text'
                                name='testEmail'
                                autoFocus={true}
                                defaultValue={ formMessage.testEmail || ((hasFormData && formData.email_connection.gmail) ? utility.decrypt(formData.email_connection.gmail) : '' ) }
                                placeholder='email@domain.com'
                                onChange={(event) => { this.handleFieldUpdate(event, formMessage) }}
                                /*defaultValue={ formMessage && formMessage.testEmail || 'email@domain.com'}*/
                              />
                            </div>
                            <div md='3' className='text-right pr-2'>
                              <MDBBtn
                                flat className='shadow-none text-white background-color-orange py-2 px-3 br-3' onClick={(e) => { this.handleSendTestEmail(e); }}>SEND TEST EMAIL</MDBBtn>
                            </div>
                          </MDBRow>

                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    }
                  </MDBCol>
                  <MDBCol lg='5' xl='5' className='box-right-rectangle px-0 d-flex'>
                    {/*<MDBRow className='m-auto'>*/}
                    {/*  <MDBCol md='12' className='d-flex'>*/}
                    {/*    <div className='m-auto opacity-30'>*/}
                    {/*      <MDBIcon far icon={iconDone} size='2x' />*/}
                    {/*    </div>*/}
                    {/*  </MDBCol>*/}
                    {/*  <MDBCol md='12' className='d-flex'>*/}
                    {/*    <div className='m-auto opacity-30 pt-2'>*/}
                    {/*      <p className='mb-0 font-size-14 font-weight-600'>{textLabeldone}</p>*/}
                    {/*    </div>*/}
                    {/*  </MDBCol>*/}
                    {/*</MDBRow>*/}
                    <div className='generic-message text-center'>
                      <p>
                        Compose the <b>Success Message</b> email <br />
                        that will be automatically sent to your users <br/>
                        after they submit the form.
                      </p>
                      <Icon name='sms' className='icon--lg'/>
                      <Icon name='sms' className='icon--md' />
                      <Icon name='sms' className='icon--sm' />
                    </div>
                  </MDBCol>

                  {/*<MDBCol lg='5' xl='5' className='box-right-rectangle px-0'>*/}
                  {/*  <p className='mt-3 mb-0 mx-4 font-size-10 font-weight-600'>TEMPLATES</p>*/}
                  {/*  <hr />*/}
                  {/*  <p className='mt-3 mb-0 mx-4 font-size-12 font-weight-600'>Quick Presets</p>*/}
                  {/*  <MDBCol className='pl-4 pr-5'>*/}
                  {/*    <MDBRow>*/}
                  {/*      <MDBCol md='6' className='py-3'>*/}
                  {/*        <div className='background-color-white box-shadow container px-0 height-180 position-absolute rotated-3 width-85-percent opacity-50'></div>*/}
                  {/*        <MDBContainer className='background-color-white box-shadow container height-180 pt-1 padding-10 z-index-1'>*/}
                  {/*          <div className='col height-45 my-2 border-dotted d-flex px-0'>*/}
                  {/*            <div className='border m-auto pink-rectangle'></div>*/}
                  {/*          </div>*/}
                  {/*          <div className='col height-45 my-2 border-dotted d-flex px-0'>*/}
                  {/*            <div className='border m-auto pink-rectangle'></div>*/}
                  {/*          </div>*/}
                  {/*          <div className='col height-45 my-2 border-dotted d-flex px-0'>*/}
                  {/*            <div className='border m-auto pink-rectangle'></div>*/}
                  {/*          </div>*/}
                  {/*        </MDBContainer>*/}
                  {/*        <div className='d-flex pt-2'>*/}
                  {/*          <p className='mb-0 mx-auto font-size-13' >3 Single Line</p>*/}
                  {/*        </div>*/}
                  {/*      </MDBCol>*/}
                  {/*      <MDBCol md='6' className='py-3'>*/}
                  {/*        <div className='background-color-white box-shadow container px-0 height-180 position-absolute rotated-3 width-85-percent opacity-50'></div>*/}
                  {/*        <MDBContainer className='background-color-white box-shadow container height-180 pt-1 padding-10 z-index-1'>*/}
                  {/*          <div className='col height-45 my-2 border-dotted d-flex px-0'>*/}
                  {/*            <div className='border m-auto pink-rectangle'></div>*/}
                  {/*          </div>*/}
                  {/*          <div className='row my-2'>*/}
                  {/*            <div className='col pr-1'>*/}
                  {/*              <div className='col height-45 border-dotted d-flex px-0'>*/}
                  {/*                <div className='border m-auto pink-rectangle'></div>*/}
                  {/*              </div>*/}
                  {/*            </div>*/}
                  {/*            <div className='col pl-1'>*/}
                  {/*              <div className='col height-45 border-dotted d-flex px-0'>*/}
                  {/*                <div className='border m-auto pink-rectangle'></div>*/}
                  {/*              </div>*/}
                  {/*            </div>*/}
                  {/*          </div>*/}
                  {/*          <div className='col height-45 my-2 border-dotted d-flex px-0'>*/}
                  {/*            <div className='border m-auto pink-rectangle'></div>*/}
                  {/*          </div>*/}
                  {/*        </MDBContainer>*/}
                  {/*        <div className='d-flex pt-2'>*/}
                  {/*          <p className='mb-0 mx-auto font-size-13' >3 Single Line</p>*/}
                  {/*        </div>*/}
                  {/*      </MDBCol>*/}
                  {/*      <MDBCol md='6' className='pb-3'>*/}
                  {/*        <div className='background-color-pink box-shadow container px-0 height-180 position-absolute rotated-3 width-85-percent opacity-50'></div>*/}
                  {/*        <MDBContainer className='background-color-orange box-shadow container height-180 pt-1 padding-10 z-index-1 opacity-60'>*/}
                  {/*          <div className='col height-45 my-2 border-dotted-white px-0 opacity-100 height-95-percent d-flex'>*/}
                  {/*            <div className='m-auto'>*/}
                  {/*              <div className='d-flex'>*/}
                  {/*                <p className='mb-0 m-auto font-size-13  opacity-100 text-white  font-weight-600' >Blank</p>*/}
                  {/*              </div>*/}
                  {/*              <div className='d-flex'>*/}
                  {/*                <p className='mb-0 m-auto font-size-13  opacity-100 text-white  font-weight-600' >Template</p>*/}
                  {/*              </div>*/}
                  {/*            </div>*/}
                  {/*          </div>*/}
                  {/*        </MDBContainer>*/}
                  {/*        <div className='d-flex py-2'>*/}
                  {/*          <p className='mb-0 mx-auto font-size-13' >Blank Template</p>*/}
                  {/*        </div>*/}
                  {/*      </MDBCol>*/}
                  {/*      /!*<MDBCol>*!/*/}
                  {/*      /!*  <Link to='/integration'>*!/*/}
                  {/*      /!*    <MDBBtn flat className='button-bottom font-size-12 right-0 background-color-orange font-weight-bold shadow-none color-white py-2 px-4 br-5'>SAVE CHANGE</MDBBtn>*!/*/}
                  {/*      /!*  </Link>*!/*/}
                  {/*      /!*</MDBCol>*!/*/}
                  {/*    </MDBRow>*/}
                  {/*  </MDBCol>*/}
                  {/*</MDBCol>*/}
                </MDBRow>
              </MDBCol>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

function mapState(state) {
  const { creating, forms, email } = state;
  return { creating, forms, email };
}

const actionCreators = {
  getFormMessage: formActions.getFormMessage,
  updateFormMessage: formActions.updateFormMessage,
  sendTestEmailMessage: formActions.sendTestEmailMessage
};

const connectedFormSuccessMessage = connect(mapState, actionCreators)(FormSuccessMessage);
export { connectedFormSuccessMessage as FormSuccessMessage};
