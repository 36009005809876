import React, { useState, useEffect, useRef } from 'react';
import { FilterDropdown } from './FilterDropdown';
import { leadActions } from "_actions";
import { connect } from "react-redux";
import { DateRangePicker } from "rsuite";
import { ranges } from '../../constants/DateRangeConfig';

const KpiControl = ({ 
  projectId, 
  selectedStatus, 
  totalElements, 
  size, 
  number, 
  first, 
  last, 
  selectedCategory, 
  selectedColor, 
  onHandleKpiHeaderHeightChanged, 
  onHandleStatusChanged, 
  onHandlePageChanged, 
  onHandleSizeChanged, 
  onHandleSearch, 
  onHandleDateChanged 
}) => {

  const [searchToggled, setSearchToggled] = useState(!!localStorage.getItem(`lead_view_query_${projectId}`));
  const [currentPage, setCurrentPage] = useState(Number(localStorage.getItem(`lead_view_page_${projectId}`)) || 1);
  const [currentSize, setCurrentSize] = useState(Number(localStorage.getItem(`lead_view_size_${projectId}`)) || 15);
  const [currentQuery, setCurrentQuery] = useState(localStorage.getItem(`lead_view_query_${projectId}`) || '');
  const [currentDate, setCurrentDate] = useState(() => {
    const dates = localStorage.getItem(`lead_view_dates_${projectId}`);
    return dates && dates.split(',') && dates.split(',').length && dates !== "null" ? dates.split(',').map(d => new Date(d)) : [];
  });
  const [toggleKpi, setToggleKpi] = useState(true);

  const searchInputRef = useRef(null);

  const handleToggleKpi = () => {
    setToggleKpi(!toggleKpi);
    onHandleKpiHeaderHeightChanged(!toggleKpi);
  };

  const handleOpenSearch = () => {
    setSearchToggled(true);
  };

  const handleSearch = () => {
    if (searchInputRef.current && searchInputRef.current.value !== '') {
      onHandleSearch(searchInputRef.current.value.toLowerCase());
    }
  };

  const handleClearSearch = () => {
    searchInputRef.current.value = '';
    onHandleSearch(null);
    setSearchToggled(false);
  };

  const handleDateRangeChange = value => {
    setCurrentDate(value);
    onHandleDateChanged(value);
  };

  const handleApplyFilter = (status, origin, assigned) => {
    localStorage.setItem(`lead_view_page_${projectId}`, 1);
    onHandleStatusChanged(status, origin, assigned);
  };

  const handlePageChanged = (value, className) => {
    if (className === 'active') {
      setCurrentPage(value);
      onHandlePageChanged(value);
    }
  };

  const handleSizeChanged = e => {
    const size = parseInt(e.options[e.selectedIndex].value);
    onHandleSizeChanged(size);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }

    if (e.key === "Escape") {
      handleClearSearch();
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress, true);
    return () => {
      document.removeEventListener('keypress', handleKeyPress, true);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem(`lead_view_page_${projectId}`)) {
      setCurrentPage(Number(localStorage.getItem(`lead_view_page_${projectId}`)));
    }
  }, [projectId]);

  const page = number + 1;
  const min = (page * size) - (size - 1);
  const max = page * size;
  const count = totalElements;
  const prevClass = first ? '' : 'active';
  const nextClass = last ? '' : 'active';
  const nextPage = page + 1;
  const previousPage = page - 1;
  const defaultPage = parseInt(currentPage);
  const activeSize = size ? size : currentSize;
  let countText = 'Loading Leads ...';
  if (page) {
    let limit = max;
    if (count < max) {
      limit = count;
    }
    countText = `${min}-${limit} of ${count}`;
  }

  return (
    <div className='overview-control-box'>
      <div className='col-left'>
        <div className='button-group'>
          <FilterDropdown
            projectId={projectId}
            selectedStatus={selectedStatus}
            onApplyFilter={handleApplyFilter}
          />
          <div className='date-range-picker-box'>
            <DateRangePicker
              ranges={ranges}
              appearance="subtle"
              format="MMM-dd-yyyy"
              size="lg"
              placeholder="All Time"
              placement="autoVerticalStart"
              cleanable={true}
              value={currentDate}
              onChange={handleDateRangeChange}
            />
          </div>

          <div className={searchToggled ? 'search-box active' : 'search-box'}>
            <div className='input-search'>
              <i className="icon-lms icon-search"></i>
              <input
                key={currentQuery}
                defaultValue={currentQuery && currentQuery !== "null" ? currentQuery : ""}
                type='text'
                placeholder='Search Lead'
                ref={searchInputRef}
                onClick={handleOpenSearch}
              />
              <div className='controls'>
                <i className="icon-lms icon-close" onClick={handleClearSearch}></i>
                <i className="icon-lms icon-arrow-right" onClick={handleSearch}></i>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='col-right'>
        <div className='button-toggle'>
          <p>Data Overview</p>
          <div
            className={toggleKpi ? "switch-tggle active" : "switch-tggle"}
            onClick={handleToggleKpi}
          >
            <span className="slider"></span>
            <span className='tggle-txt'></span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  const { leadsLoaded } = state.leads;
  if (leadsLoaded) {
    if (state.leads.leads) {
      const { number, first, last, size, totalElements, totalPages } = state.leads.leads.data;
      return { number, first, last, size, totalElements, totalPages, leadsLoaded };
    }
  }
  return { leadsLoaded };
};

const actionCreators = {
  getLeads: leadActions.getLeadsPaginated,
};

const connectedViewKpiControl = connect(mapState, actionCreators)(KpiControl);
export { connectedViewKpiControl as KpiControl };
