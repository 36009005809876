import React, { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { projectActions } from '_actions';
import { MDBModal, MDBModalBody, MDBContainer, MDBRow, MDBCol, MDBModalHeader } from 'mdbreact';
import { Button } from "blackbox-react";
import InputForm from "_components/InputForm";
import { FormValidation } from '_components/FormValidation';

const CreateLeadStatusModal = ({ modalOpen, modalToggle, createLeadStatus, handleGetProjectStatus, projectId }) => {
  const [status, setStatus] = useState({
    statusName: '',
    description: '',
  });
  const [formErrors, setFormErrors] = useState({
    statusName: 'Please enter a lead status name.',
  });
  const [statusNameValid, setStatusNameValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      // Reset form state when the modal opens
      setStatus({
        statusName: '',
        description: '',
      });
      setFormErrors({
        statusName: 'Please enter a lead status name.',
      });
      setStatusNameValid(false);
      setFormValid(false);
      setSubmitted(false);
    }
  }, [modalOpen]);

  const validateField = useCallback((fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let statusNameValid = statusNameValid;
    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch(fieldName) {
      case 'statusName':
        if (value === "" || isAllWhitespace) {
          statusNameValid = false;
          fieldValidationErrors.statusName = 'Please enter a lead status name.';
        } else {
          statusNameValid = (value.length >= 0);
          fieldValidationErrors.statusName = statusNameValid ? '' : 'Please enter a lead status name.';
        }
        break;
      default:
        break;
    }

    setFormErrors(fieldValidationErrors);
    setStatusNameValid(statusNameValid);
    validateForm(statusNameValid);
  }, [formErrors, statusNameValid]);

  const validateForm = (statusNameValid) => {
    setFormValid(statusNameValid);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStatus((prevStatus) => ({
      ...prevStatus,
      [name]: value
    }), () => {
      validateField(name, value);
    });
  };

  const handleCreateLeadStatus = (event) => {
    event.preventDefault();
    const { statusName, description } = status;

    validateForm();

    setSubmitted(true);
    if (formValid) {
      createLeadStatus({
        name: statusName,
        description: description,
      }, projectId);

      if (modalOpen) {
        modalToggle();
      }

      setStatus({
        statusName: '',
        description: '',
      });
      setFormErrors({
        statusName: 'Please enter a lead status name.',
      });
      setStatusNameValid(false);
      setFormValid(false);
      setSubmitted(false);

      handleGetProjectStatus();
    }
  };

  const errorClass = (error) => (error.length === 0 ? 'is-passed' : 'is-failed');

  return (
    <MDBContainer>
      <form onSubmit={handleCreateLeadStatus} noValidate>
        <MDBModal isOpen={modalOpen} toggle={modalToggle} className='create-form-modal' centered>
          <MDBModalHeader className='padding-left-18 padding-right-18 pb-3 pt-3' toggle={modalToggle}>
            <p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>CREATE LEAD STATUS</p>
          </MDBModalHeader>
          <MDBModalBody className='p-t--0 padding-left-18 padding-right-18 padding-bottom-15'>
            <MDBRow>
              <MDBCol className={`form-group m--sm ${submitted && errorClass(formErrors.statusName)}`}>
                <label className='mb-1 font-size-12 text-black'>Lead Status Name <span className='color-red'>*</span></label>
                <InputForm
                  onChange={handleChange}
                  type='text'
                  name='statusName'
                  value={status.statusName}
                  id='FormName'
                  hint='Enter Lead Status name'
                />
                <div className={`form-validation__container ${(!formValid && submitted) ? 'display-block' : 'display-none'}`}>
                  <FormValidation fieldName='statusName' formErrors={formErrors} />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label className='mb-1 pt-2 font-size-12 text-black'>Description</label>
                <textarea
                  name='description'
                  value={status.description}
                  id='leadDisc'
                  className='form-control font-size-12 color-none'
                  rows='5'
                  placeholder='Enter description here...'
                  onChange={handleChange}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className='p-t--md'>
              <MDBCol className='d-flex'>
                <span
                  className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12'
                  onClick={modalToggle}
                >
                  Cancel
                </span>
              </MDBCol>
              <MDBCol className='text-right' id='nextButton'>
                <Button
                  flat='true'
                  type='submit'
                  size={-1}
                  background='orange'
                  onClick={handleCreateLeadStatus}
                >
                  CREATE LEAD STATUS
                </Button>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </form>
    </MDBContainer>
  );
};

function mapState(state) {
  const { projects, project, creating } = state;
  return { creating, projects, project };
}

const actionCreators = {
  getProject: projectActions.getProject,
  createLeadStatus: projectActions.createLeadStatus
};

const connectedViewProjects = connect(mapState, actionCreators)(CreateLeadStatusModal);
export { connectedViewProjects as CreateLeadStatusModal };