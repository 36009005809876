import React, { useState } from 'react';

// components
import ActivityBtn from './ActivityBtn';
import SocialMedia from './SocialMedia';
import RecentActivitySide from './RecentActivitySide';
import RecordsSide from './RecordsSide';
import NotesSide from './NotesSide';
import RatesSide from './RatesSide';

const ActivitySide = ( { socialMediaModalAdd, socialMediaModalEdit, recordsModalAdd, recordsModalEdit, notesModalAdd, notesModalEdit, ratesModalAdd, ratesModalEdit, confirmationDelete } ) => {

  const [toggleTabState, setToggleTabState] = useState(1);

  const toggleTab = (index) => {
    setToggleTabState(index);
  }

  return (
    <>
        <div className='activity-side-box box-white-custom'>
          <div className='activity-control-box'>
              <ActivityBtn
                  ToggleTabClick={() => toggleTab(1)}
                  classN={toggleTabState === 1 ? 'activity-btns active' : 'activity-btns'}
                  name='Platform'
                  icon='fas fa-globe'
              />
              <ActivityBtn
                  ToggleTabClick={() => toggleTab(2)}
                  classN={toggleTabState === 2 ? 'activity-btns active' : 'activity-btns'}
                  name='Activity'
                  icon='far fa-clock'
              />
              <ActivityBtn
                  ToggleTabClick={() => toggleTab(3)}
                  classN={toggleTabState === 3 ? 'activity-btns active' : 'activity-btns'}
                  name='Records'
                  icon='fas fa-clipboard'
              />
              <ActivityBtn
                  ToggleTabClick={() => toggleTab(4)}
                  classN={toggleTabState === 4 ? 'activity-btns active' : 'activity-btns'}
                  name='Notes'
                  icon='far fa-file-alt'
              />
              <ActivityBtn
                  ToggleTabClick={() => toggleTab(5)}
                  classN={toggleTabState === 5 ? 'activity-btns active' : 'activity-btns'}
                  name='Rates'
                  icon='fas fa-money-check'
              />
          </div>

            <div className='custom-scroll-box'>
                <SocialMedia 
                  classN={toggleTabState === 1 ? 'tab-box social-media-box active' : 'tab-box social-media-box'}
                  socialMediaModalAdd={socialMediaModalAdd}
                  socialMediaModalEdit={socialMediaModalEdit}
                  confirmationDelete={confirmationDelete}
                />
                <RecentActivitySide 
                  classN={toggleTabState === 2 ? 'tab-box recent-activity-box active' : 'tab-box recent-activity-box'}
                />
                <RecordsSide 
                  classN={toggleTabState === 3 ? 'tab-box records-box active' : 'tab-box records-box'}
                  recordsModalAdd={recordsModalAdd}
                  recordsModalEdit={recordsModalEdit}
                  confirmationDelete={confirmationDelete}
                />
                <NotesSide 
                  classN={toggleTabState === 4 ? 'tab-box notes-box active' : 'tab-box notes-box'}
                  notesModalAdd={notesModalAdd}
                  confirmationDelete={confirmationDelete}
                  notesModalEdit={notesModalEdit}
                />
                <RatesSide 
                  classN={toggleTabState === 5 ? 'tab-box notes-box active' : 'tab-box notes-box'}
                  ratesModalAdd={ratesModalAdd}
                  confirmationDelete={confirmationDelete}
                  ratesModalEdit={ratesModalEdit}
                />
                {/* loader */}
                <div className="loading-box">
                    <div className='loader-box'>
                        <div className="loader-custom">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ActivitySide

