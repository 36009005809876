import {leadConstants} from '_constants';
import {httpManagerService, leadService} from '_services';
import {alertActions} from './';
import {history} from '_helpers';

export const leadActions = {
    getLeads,
    getLeadsPaginated,
    getLeadReport,
    getLeadGoogleSheetReport,
    getPatientReport,
    getLeadToPatientConversion,
    getLeadSummary,
    createLead,
    getLead,
    updateLead,
    deleteLead,
    getLeadNotes,
    getLeadSession,
    getLeadInterest,
    addLeadNote,
    updateLeadNote,
    deleteLeadNote,
    addNoteReply,
    updateNoteReply,
    deleteNoteReply,
    updateLeadStatus,
    getAllContent,
    createContent,
    findContent,
    updateContent,
    deleteContent,
    enableContent,
    importLeads,
    getAllImportData,
    findImportData,
    getActivityLog,
    getOrigin,
    sendCalendlyInvite,
    selectLead,
    deselectLead,
    batchSelectLeads,
    uploadKalibrrLeads,
    uploadCsvLeads

};

function getLeads(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        leadService.getLeads(project_id)
            .then(
                leads => {
                    dispatch(success(leads));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.GET_ALL_REQUEST, project_id}
    }

    function success(leads) {
        return {type: leadConstants.GET_ALL_SUCCESS, leads}
    }

    function failure(error) {
        return {type: leadConstants.GET_ALL_FAILURE, error}
    }
}

function getOrigin(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        leadService.getOrigin(project_id)
            .then(
                origin => {
                    dispatch(success(origin));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.GET_ORIGIN_REQUEST, project_id}
    }

    function success(origin) {
        return {type: leadConstants.GET_ORIGIN_SUCCESS, origin}
    }

    function failure(error) {
        return {type: leadConstants.GET_ORIGIN_FAILURE, error}
    }
}


function getLeadToPatientConversion() {
    return dispatch => {
        dispatch(request());
        leadService.getLeadToPatientConversion()
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request() {
        return {type: leadConstants.SYNC_PATIENTS_REQUEST}
    }

    function success() {
        return {type: leadConstants.SYNC_PATIENTS_SUCCESS}
    }

    function failure(error) {
        return {type: leadConstants.SYNC_PATIENTS_ERROR, error}
    }
}


function getLeadsPaginated(project_id, page = 1, size = 15, query = "", status = [], origin = [], assigned = [], dateFrom = null, dateTo = null, sort = null, direction = null) {
    console.log('call pagination: project: ' + project_id + ', size: ' + size + ",page: " + page);
    return dispatch => {
        dispatch(request(project_id));
        leadService.getLeadsPaginated(project_id, page, size, query, status, origin, assigned, dateFrom, dateTo, sort, direction)
            .then(
                leads => {
                    dispatch(success(leads));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.GET_ALL_REQUEST, project_id}
    }

    function success(leads) {
        return {type: leadConstants.GET_ALL_SUCCESS, leads}
    }

    function failure(error) {
        return {type: leadConstants.GET_ALL_FAILURE, error}
    }
}

function getLeadSummary(project_id, dateFrom = null, dateTo = null) {
    console.log('lead summary: ', project_id);
    return dispatch => {
        dispatch(request(project_id));
        leadService.getLeadSummary(project_id, dateFrom, dateTo)
            .then(
                summary => {
                    dispatch(success(summary));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.GET_SUMMARY_REQUEST, project_id}
    }

    function success(summary) {
        return {type: leadConstants.GET_SUMMARY_SUCCESS, summary}
    }

    function failure(error) {
        return {type: leadConstants.GET_SUMMARY_FAILURE, error}
    }
}


function getLeadReport(project_id, status, dateFrom = null, dateTo = null) {

    return dispatch => {
        dispatch(request(project_id));

        leadService.getLeadReport(project_id, status, dateFrom, dateTo)
            .then(
                report => {
                    dispatch(success(report))
                    return report.blob();
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            ).then(blob => URL.createObjectURL(blob))
            .then(uri => {
                let link = document.createElement("a");
                link.href = uri;
                link.download = "export-leads-" + new Date().getTime() + ".csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    function request(project_id) {
        return {type: leadConstants.GET_LEAD_REPORT_REQUEST, project_id}
    }

    function success(report) {
        return {type: leadConstants.GET_LEAD_REPORT_SUCCESS, report}
    }

    function failure(error) {
        return {type: leadConstants.GET_LEAD_REPORT_FAILURE, error}
    }
}

function getLeadGoogleSheetReport(project_id, status, dateFrom = null, dateTo = null) {

    return dispatch => {
        dispatch(request(project_id));

        leadService.getLeadGoogleSheetReport(project_id, status, dateFrom, dateTo)
            .then(
                confirmation => {
                    dispatch(success(confirmation));
                    // Display the confirmation message to the user
                    dispatch(alertActions.success(`Successfully uploaded to google sheet`));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.GET_LEAD_REPORT_REQUEST, project_id}
    }

    function success(report) {
        return {type: leadConstants.GET_LEAD_REPORT_SUCCESS, report}
    }

    function failure(error) {
        return {type: leadConstants.GET_LEAD_REPORT_FAILURE, error}
    }
}


function getPatientReport() {
    return dispatch => {
        dispatch(request());
        leadService.getPatientReport()
            .then(
                report => {
                    dispatch(success(report))
                    return report.blob();
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            ).then(blob => URL.createObjectURL(blob))
            .then(uri => {
                let link = document.createElement("a");
                link.href = uri;
                link.download = "export-lead-to-patient-" + new Date().getTime() + ".csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    function request() {
        return {type: leadConstants.GET_PATIENT_REPORT_REQUEST}
    }

    function success(report) {
        return {type: leadConstants.GET_PATIENT_REPORT_SUCCESS, report}
    }

    function failure(error) {
        return {type: leadConstants.GET_PATIENT_REPORT_FAILURE, error}
    }
}


function createLead(project_id,  lead, hasError) {
    return dispatch => {
        dispatch(request(project_id, lead));

        if (!hasError) {
            leadService.createLead(project_id, lead)
                .then(
                    leads => {
                        dispatch(success(leads));
                        let l = leads.data;
                        history.push(`/project/${project_id}/leads/${l.id}`);
                        dispatch(alertActions.success(`Successfully created lead.`));
                    },
                    error => {
                        let errorMessage = '';
                        if (error.toString() === 'Error: Request failed with status code 500') {
                            errorMessage = 'Please fill out all required fields.'
                        } else {
                            errorMessage = error.toString();
                        }
                        const {response} = error;
                        const {data} = response;

                        dispatch(failure(data));
                        dispatch(alertActions.error(data));
                    }
                );
        } else {
            let data = {};
            data.error_message = 'Please fill out all required fields.'
            dispatch(failure(data));
            dispatch(alertActions.error(data));
        }

    };

    function request(lead) {
        return {type: leadConstants.CREATE_REQUEST, lead}
    }

    function success(leads) {
        return {type: leadConstants.CREATE_SUCCESS, leads}
    }

    function failure(error) {
        return {type: leadConstants.CREATE_FAILURE, error}
    }
}

function getLead(project_id, lead_id) {
    return dispatch => {
        dispatch(request(project_id, lead_id));
        leadService.find(project_id, lead_id)
            .then(
                lead => {
                    const hasAccessToLead = httpManagerService.isCurrentUserLeadManager(lead.data, project_id);
                    if(!hasAccessToLead) {
                        history.push('/project/' + project_id + '/leads');
                        dispatch(alertActions.error({
                            error_code: 'error',
                            error_message: 'You are not assigned as Lead Manager for this Lead.',
                        }));
                        return;
                    }
                    dispatch(success(lead));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    history.push(`/project/${project_id}/leads`);
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(lead_id) {
        return {type: leadConstants.GET_REQUEST, lead_id}
    }

    function success(lead) {
        return {type: leadConstants.GET_SUCCESS, lead}
    }

    function failure(error) {

        return {type: leadConstants.GET_FAILURE, error}
    }
}

function updateLead(project_id, lead_id, leadData, hasError) {
    return dispatch => {

        if(!hasError) {
            dispatch(request(project_id, lead_id, leadData));
            leadService.updateLead(project_id, lead_id, leadData)
                .then(
                    lead => {
                        // console.log('update status success.....');
                        dispatch(getActivityLog(project_id, lead_id));
                        dispatch(success(lead));
                        dispatch(alertActions.success('Lead updated successfully'));
                    },
                    error => {
                        const {response} = error;
                        const {data} = response;

                        dispatch(failure(data));
                        dispatch(alertActions.error(data));
                    }
                );

        } else {
            let data = {};
            data.error_message = 'Please fill out all required fields.'
            //dispatch(failure(data));
            dispatch(alertActions.error(data));
        }
    };

    function request(project_id, lead_id, leadData) {
        return {type: leadConstants.UPDATE_REQUEST, project_id, lead_id, leadData}
    }

    function success(lead) {
        return {type: leadConstants.UPDATE_SUCCESS, lead}
    }

    function failure(error) {
        return {type: leadConstants.UPDATE_FAILURE, error}
    }
}

function deleteLead(project_id, lead_id, lead_data_id) {
    return dispatch => {
        dispatch(request(project_id, lead_id, lead_data_id));
        leadService.deleteLead(project_id, lead_id, lead_data_id)
            .then(
                lead => {
                    // console.log('triggered');
                    dispatch(success());
                    dispatch(alertActions.success(`Lead deleted`));
                    history.push(`/project/${project_id}/leads`);
                    document.body.classList.remove('overflow-y-scroll');
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, lead_id) {
        //console.log('request triggered');
        return {type: leadConstants.DELETE_REQUEST, project_id, lead_id}
    }

    function success() {
        //console.log('success triggered');
        return {type: leadConstants.DELETE_SUCCESS}
    }

    function failure(error) {
        //console.log('error triggered');
        return {type: leadConstants.DELETE_FAILURE, error}
    }
}

function getLeadNotes(project_id, lead_id, type_name) {
    return dispatch => {
        dispatch(request(project_id, lead_id, type_name));
        leadService.getLeadNotes(project_id, lead_id, type_name)
            .then(
                notes => {
                    dispatch(success(notes));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, lead_id, type_name) {
        return {type: leadConstants.GET_ALL_NOTES_REQUEST, project_id, lead_id, type_name}
    }

    function success(notes) {
        return {type: leadConstants.GET_ALL_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: leadConstants.GET_ALL_NOTES_FAILURE, error}
    }
}

function getLeadSession(project_id, lead_id) {
    return dispatch => {
        dispatch(request(project_id, lead_id));
        leadService.getLeadSession(project_id, lead_id)
            .then(
                sessions => {
                    dispatch(success(sessions));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, lead_id) {
        return {type: leadConstants.GET_ALL_SESSION_REQUEST, project_id, lead_id}
    }

    function success(sessions) {
        return {type: leadConstants.GET_AL_SESSION_SUCCESS, sessions}
    }

    function failure(error) {
        return {type: leadConstants.GET_ALL_SESSION_FAILURE, error}
    }
}

function getLeadInterest(project_id, lead_id) {
    return dispatch => {
        dispatch(request(project_id, lead_id));
        leadService.getLeadInterest(project_id, lead_id)
            .then(
                interest => {
                    dispatch(success(interest));
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, lead_id) {
        return {type: leadConstants.GET_ALL_INTEREST_REQUEST, project_id, lead_id}
    }

    function success(interest) {
        return {type: leadConstants.GET_AL_INTEREST_SUCCESS, interest}
    }

    function failure(error) {
        return {type: leadConstants.GET_ALL_INTEREST_FAILURE, error}
    }
}

function addLeadNote(project_id, lead_id, note_type, note_data) {
    return dispatch => {
        dispatch(request(project_id, lead_id, note_type, note_data));
        leadService.addLeadNote(project_id, lead_id, note_type, note_data)
            .then(
                note => {
                    dispatch(getLeadNotes(project_id, lead_id, note_type));
                    dispatch(success(note));
                    dispatch(alertActions.success(`Note added.`));
                    dispatch(getActivityLog(project_id, lead_id));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(getLeadNotes(project_id, lead_id, note_type));
                    dispatch(alertActions.error(
                        {
                            error_code: data.status || 'error',
                            error_message: data.message || 'You are not allowed to create note.',
                        },
                        3000
                    ));
                    dispatch(success(data.message || 'You are not allowed to create note.'));
                    dispatch(getActivityLog(project_id, lead_id));
                }
            );
    };

    function request(project_id, lead_id, note_type, note_data) {
        return {type: leadConstants.CREATE_NOTE_REQUEST, project_id, lead_id, note_type, note_data}
    }

    function success(note) {
        return {type: leadConstants.CREATE_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: leadConstants.CREATE_NOTE_FAILURE, error}
    }
}

function deleteLeadNote(project_id, lead_id, type_name, content_id, note) {
    return dispatch => {
        dispatch(request(project_id, lead_id, type_name, content_id, note));
        leadService.deleteLeadNote(project_id, lead_id, type_name, content_id, note)
            .then(
                note => {
                    dispatch(success(note));
                    dispatch(getActivityLog(project_id, lead_id));
                    dispatch(getLeadNotes(project_id, lead_id, type_name));
                    dispatch(alertActions.success('Note deleted'));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, lead_id, type_name, content_id, note) {
        return {type: leadConstants.DELETE_NOTE_REQUEST, project_id, lead_id, type_name, content_id, note}
    }

    function success(note) {
        return {
            type: leadConstants.DELETE_NOTE_SUCCESS, note
        }
    }

    function failure(error) {
        return {type: leadConstants.DELETE_NOTE_FAILURE, error}
    }
}

function updateLeadNote(project_id, lead_id, type_name, content_id, note_data) {
    return dispatch => {
        dispatch(request(project_id, lead_id, type_name, content_id, note_data));
        leadService.updateLeadNote(project_id, lead_id, type_name, content_id, note_data)
            .then(
                note => {
                    dispatch(success(note));
                    dispatch(getLeadNotes(project_id, lead_id, type_name));
                    dispatch(alertActions.success('Note updated.'));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, lead_id, type_name, content_id, note_data) {
        return {type: leadConstants.UPDATE_NOTE_REQUEST, project_id, lead_id, type_name, content_id, note_data}
    }

    function success(note) {
        return {type: leadConstants.UPDATE_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: leadConstants.UPDATE_NOTE_FAILURE, error}
    }
}



function addNoteReply(project_id, lead_id, content_id,  content){
    return dispatch => {
        dispatch(request(project_id, lead_id, content_id,  content));
        leadService.addNoteReply(project_id, lead_id, content_id,  content)
            .then(
                notes => {
                    dispatch(success(notes));
                    dispatch(alertActions.success(`Reply added.`));
                    dispatch(getActivityLog(project_id, lead_id));

                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, lead_id, content_id,  content) {
        return {type: leadConstants.CREATE_NOTE_REPLY_REQUEST, project_id, lead_id, content_id,  content}
    }

    function success(notes) {
        return {type: leadConstants.CREATE_NOTE_REPLY_SUCCESS, notes}
    }

    function failure(error) {
        return {type: leadConstants.CREATE_NOTE_REPLY_FAILURE, error}
    }
}

function updateNoteReply(project_id, lead_id, content_id, reply_id,content){

}

function deleteNoteReply(project_id, lead_id, content_id, reply_id){

}

function updateLeadStatus(project_id, lead_id, status) {
    return dispatch => {
        dispatch(request(project_id, lead_id, status));
        leadService.updateLeadStatus(project_id, lead_id, status)
            .then(
                status => {
                    dispatch(success());
                    dispatch(alertActions.success(`Successfully changed status.`))
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(status) {
        return {type: leadConstants.UPDATE_STATUS_REQUEST, status}
    }

    function success(status) {
        return {type: leadConstants.UPDATE_STATUS_SUCCESS, status}
    }

    function failure(error) {
        return {type: leadConstants.UPDATE_STATUS_FAILURE, error}
    }
}

function getAllContent(project_id, lead_id) {
    return dispatch => {
        dispatch(request(project_id, lead_id));
        leadService.getAllContent(project_id, lead_id)
            .then(
                content => {
                    dispatch(success(content));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(content) {
        return {type: leadConstants.GET_ALL_CONTENT_REQUEST, content}
    }

    function success(content) {
        return {type: leadConstants.GET_ALL_CONTENT_SUCCESS, content}
    }

    function failure(error) {
        return {type: leadConstants.GET_ALL_CONTENT_FAILURE, error}
    }
}

function createContent(project_id, lead_id, type, content) {
    return dispatch => {
        dispatch(request(project_id, lead_id, type, content));
        leadService.createContent(project_id, lead_id, type, content)
            .then(
                content => {
                    dispatch(success(content));
                    dispatch(alertActions.success(`Successfully created content.`))
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(content) {
        return {type: leadConstants.CREATE_CONTENT_REQUEST, content}
    }

    function success(content) {
        return {type: leadConstants.CREATE_CONTENT_SUCCESS, content}
    }

    function failure(error) {
        return {type: leadConstants.CREATE_CONTENT_FAILURE, error}
    }
}

function findContent(project_id, lead_id, type, type_id) {
    return dispatch => {
        dispatch(request(project_id, lead_id, type, type_id));
        leadService.findContent(project_id, lead_id, type, type_id)
            .then(
                content => {
                    dispatch(success(content));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(content) {
        return {type: leadConstants.GET_REQUEST, content}
    }

    function success(content) {
        return {type: leadConstants.GET_SUCCESS, content}
    }

    function failure(error) {
        return {type: leadConstants.GET_FAILURE, error}
    }
}

function updateContent(project_id, lead_id, type, type_id, content) {
    return dispatch => {
        dispatch(request(project_id, lead_id, type, type_id, content));
        leadService.updateContent(project_id, lead_id, type, type_id, content)
            .then(
                content => {
                    dispatch(success());
                    dispatch(alertActions.success('Content updated successfully'));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(content) {
        return {type: leadConstants.UPDATE_CONTENT_REQUEST, content}
    }

    function success(content) {
        return {type: leadConstants.UPDATE_CONTENT_SUCCESS, content}
    }

    function failure(error) {
        return {type: leadConstants.UPDATE_CONTENT_FAILURE, error}
    }
}

function deleteContent(project_id, lead_id, type, type_id, content) {
    return dispatch => {
        dispatch(request(project_id, lead_id, type, type_id, content));
        leadService.deleteContent(project_id, lead_id, type, type_id, content)
            .then(
                content => {
                    dispatch(success());
                    dispatch(alertActions.success(`${type} deleted`));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(content) {
        return {type: leadConstants.DELETE_CONTENT_REQUEST, content}
    }

    function success(content) {
        return {type: leadConstants.DELETE_CONTENT_SUCCESS, content}
    }

    function failure(error) {
        return {type: leadConstants.DELETE_CONTENT_FAILURE, error}
    }
}

function enableContent(project_id, lead_id, type, type_id, status) {
    return dispatch => {
        dispatch(request(project_id, lead_id, type, type_id, status));
        leadService.enableContent(project_id, lead_id, type, type_id, status)
            .then(
                status => {
                    dispatch(success());
                    dispatch(alertActions.success(`Successfully changed status.`))
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(status) {
        return {type: leadConstants.UPDATE_CONTENT_STATUS_REQUEST, status}
    }

    function success(status) {
        return {type: leadConstants.UPDATE_CONTENT_STATUS_SUCCESS, status}
    }

    function failure(error) {
        return {type: leadConstants.UPDATE_CONTENT_STATUS_FAILURE, error}
    }
}

function importLeads(project_id, form_id, leads) {
    return dispatch => {
        dispatch(request(project_id, form_id, leads));
        leadService.importLeads(project_id, form_id, leads)
            .then(
                importedLeads => {
                    dispatch(success(importedLeads));
                    history.push(`/project/${project_id}/leads/import/results`);
                    dispatch(alertActions.success('Successfully imported leads'))
                },
                error => {
                    let errorMessage = '';
                    if (error.toString() === 'Error: Request failed with status code 500') {
                        errorMessage = 'Import failed. Go to Manage Fields to review your fields.'
                    } else {
                        errorMessage = error.toString();
                    }
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, form_id, leads) {
        return {type: leadConstants.CREATE_LEAD_IMPORT_REQUEST, project_id, form_id, leads}
    }

    function success(importedLeads) {
        return {type: leadConstants.CREATE_LEAD_IMPORT_SUCCESS, importedLeads}
    }

    function failure(error) {
        return {type: leadConstants.CREATE_LEAD_IMPORT_FAILURE, error}
    }
}

function getAllImportData(project_id) {
    return dispatch => {
        dispatch(request(project_id));
        leadService.getAllImportData(project_id)
            .then(
                lead_import => {
                    dispatch(success(lead_import));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.GET_ALL_IMPORT_DATA_REQUEST, project_id}
    }

    function success(lead_import) {
        localStorage.setItem('AllLeadImport', JSON.stringify(lead_import.data));
        // ============================= //
        // let leadImports = JSON.parse(localStorage.getItem('AllLeadImport'));
        // ============================= //
        return {type: leadConstants.GET_ALL_IMPORT_DATA_SUCCESS, lead_import}
    }

    function failure(error) {
        return {type: leadConstants.GET_ALL_IMPORT_DATA_FAILURE, error}
    }
}

function findImportData(project_id, import_id) {
    return dispatch => {
        dispatch(request(project_id, import_id));
        leadService.getAllImportData(project_id, import_id)
            .then(
                import_data => {
                    dispatch(success(import_data));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.GET_IMPORT_DATA_REQUEST, project_id}
    }

    function success(import_data) {
        return {type: leadConstants.GET_IMPORT_DATA_SUCCESS, import_data}
    }

    function failure(error) {
        return {type: leadConstants.GET_IMPORT_DATA_FAILURE, error}
    }
}

function getActivityLog(project_id, lead_id) {
    return dispatch => {
        dispatch(request(project_id, lead_id));
        leadService.getActivityLog(project_id, lead_id)
            .then(
                activity_log => {
                    dispatch(success(activity_log));
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id, lead_id) {
        return {type: leadConstants.GET_LOG_REQUEST, project_id, lead_id}
    }

    function success(activity_log) {
        return {type: leadConstants.GET_LOG_SUCCESS, activity_log}
    }

    function failure(error) {
        return {type: leadConstants.GET_LOG_FAILURE, error}
    }
}


function sendCalendlyInvite(calendlyRequest, project_id, lead_id, hasError) {
    //console.log('calendly invite');
    return dispatch => {
        dispatch(request(calendlyRequest));
        leadService.sendCalendlyInvite(project_id, lead_id, calendlyRequest)
            .then(
                data => {
                    // getLead(project_id, lead_id)
                    dispatch(getActivityLog(project_id, lead_id));
                    dispatch(success());
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    //console.log('error:', data)
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(calendlyRequest) {
        return {type: leadConstants.CALENDLY_INVITE_REQUEST, calendlyRequest}
    }

    function success() {
        return {type: leadConstants.CALENDLY_INVITE_SUCCESS}
    }

    function failure(error) {
        return {type: leadConstants.CALENDLY_INVITE_FAILURE, error}
    }
};

function batchSelectLeads( project_id, pageSize, batchRequest,) {
    //console.log('calendly invite');
    return dispatch => {
        dispatch(request(batchRequest));
        leadService.batchSelectLeads(project_id, batchRequest)
            .then(
                data => {
                    leadService.getLeadsPaginated(project_id, 1, pageSize, null, null, null, null, null, null, 'updated', null)
                        .then(
                            leads => {
                                dispatch(success(leads));
                            },
                            error => {
                                const {response} = error;
                                const {data} = response;

                                dispatch(failure(data));
                                dispatch(alertActions.error(data));
                            }
                        );
                },
                error => {
                    const {response} = error;
                    const {data} = response;

                    //console.log('error:', data)
                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request() {
        return {type: leadConstants.BULK_UPDATE_REQUEST}
    }

    function success(leads) {
        return {type: leadConstants.BULK_UPDATE_SUCCESS, leads}
    }

    function failure(error) {
        return {type: leadConstants.BULK_UPDATE_FAILURE, error}
    }
};


function selectLead (leadId) {
    return { type: leadConstants.SELECT_LEAD, leadId };
};

function deselectLead (leadId) {
    return { type: leadConstants.DESELECT_LEAD, leadId };
};

function uploadKalibrrLeads(project_id, formdata, pageSize) {
    return dispatch => {
        dispatch(request(project_id));
        leadService.uploadKalibrrLeads(project_id, formdata)
            .then(
                data => {
                    dispatch(alertActions.success(`Successfully uploaded.`));
                    leadService.getLeadsPaginated(project_id, 1, pageSize, null, null, null, null, null, null, 'updated', null)
                        .then(
                            leads => {
                                dispatch(success(leads));
                            },
                            error => {
                                const {response} = error;
                                const {data} = response;

                                dispatch(failure(data));
                                dispatch(alertActions.error(data));
                            }
                        );
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    console.log('error: ', error)

                    dispatch(failure(data));
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.BULK_UPDATE_REQUEST, project_id}
    }

    function success(leads) {
        return {type: leadConstants.BULK_UPLOAD_SUCCESS, leads}
    }

    function failure(error) {
        return {type: leadConstants.BULK_UPLOAD_FAILURE, error}
    }
}

function uploadCsvLeads(project_id, formdata, pageSize) {
    return dispatch => {
        dispatch(request(project_id));
        dispatch(requestSummary(project_id))
        leadService.uploadCsvLeads(project_id, formdata)
            .then(
                data => {
                    dispatch(alertActions.success(`Successfully uploaded.`));
                    leadService.getLeadsPaginated(project_id, 1, pageSize, null, null, null, null, null, null, 'updated', null)
                        .then(
                            leads => {
                                leadService.getLeadSummary(project_id, null, null)
                                    .then(
                                        summary => {
                                            dispatch(leadSummary(summary));
                                        },
                                        error => {
                                            const {response} = error;
                                            const {data} = response;

                                            dispatch(failureSummary(data));
                                            dispatch(alertActions.error(data));
                                        }
                                    );
                                dispatch(success(leads));
                            },
                            error => {
                                const {response} = error;
                                const {data} = response;

                                dispatch(failure(data));
                                dispatch(failureSummary(data))
                                dispatch(alertActions.error(data));
                            }
                        );
                },
                error => {
                    const {response} = error;
                    const {data} = response;
                    console.log('csv update error: ', error)

                    dispatch(failure(data));
                    dispatch(failureSummary(data))
                    dispatch(alertActions.error(data));
                }
            );
    };

    function request(project_id) {
        return {type: leadConstants.BULK_UPDATE_REQUEST, project_id}
    }

    function success(leads) {
        return {type: leadConstants.BULK_UPLOAD_SUCCESS, leads}
    }

    function requestSummary(project_id) {
        return {type: leadConstants.GET_SUMMARY_REQUEST, project_id}
    }

    function leadSummary(summary) {
        return {type: leadConstants.GET_SUMMARY_SUCCESS, summary}
    }

    function failureSummary(error) {
        return {type: leadConstants.GET_SUMMARY_FAILURE, error}
    }

    function failure(error) {
        return {type: leadConstants.BULK_UPLOAD_FAILURE, error}
    }

}
