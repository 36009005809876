import { projectConstants } from '_constants';
import { projectService } from '_services';
import { alertActions, managerActions } from './';
import { history } from '_helpers';

export const projectActions = {
  storeProject,
  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  updateStatus,
  getAllProjectMembers,
  addProjectMember,
  deleteProjectMember,
  getAllProjectStatus,
  createLeadStatus,
  findProjectStatus,
  findProjectStatusCount,
  updateLeadStatus,
  deleteLeadStatus,
  enableProjectStatus,
  createProjectDefaultStatus,
  createProjectConversionStatus,
  getActivityLog
};

/**
 * Stores the given project data.
 * @param {Object} project - The project data to store.
 */
function storeProject(project) {
  projectService.storeProject(project);
}

/**
 * Fetches all projects and updates the state accordingly.
 * Dispatches request, success, or failure actions based on the API response.
 * Removes stored project-related session data on success.
 * 
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getProjects() {
  return dispatch => {
    dispatch(request());
    projectService.getProjects()
      .then(
        projects => {
          dispatch(success(projects));
          localStorage.removeItem('project');
          localStorage.removeItem('projectId');
          localStorage.removeItem('projectStatus');
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request has started.
   * @returns {Object} Action object with type `GETALL_REQUEST`.
   */
  function request() {
    return { type: projectConstants.GETALL_REQUEST };
  }

  /**
   * Creates an action for a successful project fetch.
   * Stores the project data in session storage.
   * 
   * @param {Object} projects - The retrieved projects data.
   * @returns {Object} Action object with type `GETALL_SUCCESS` and projects data.
   */
  function success(projects) {
    localStorage.setItem('projects', JSON.stringify(projects.data));
    return { type: projectConstants.GETALL_SUCCESS, projects };
  }

  /**
   * Creates an action for a failed project fetch.
   * 
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `GETALL_FAILURE` and error message.
   */
  function failure(error) {
    return { type: projectConstants.GETALL_FAILURE, error };
  }
}


/**
 * Fetches a specific project by its ID and updates the state accordingly.
 * Dispatches request, success, or failure actions based on the API response.
 * 
 * @param {number|string} id - The ID of the project to fetch.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getProject(id) {
  return dispatch => {
    dispatch(request(id));
    projectService.getProject(id)
      .then(
        project => {
          dispatch(success(project));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(id, error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request for a project has started.
   * 
   * @param {number|string} id - The ID of the requested project.
   * @returns {Object} Action object with type `GET_REQUEST` and project ID.
   */
  function request(id) {
    return { type: projectConstants.GET_REQUEST, id };
  }

  /**
   * Creates an action for a successful project fetch.
   * Stores the project data and ID in session storage.
   * 
   * @param {Object} project - The retrieved project data.
   * @returns {Object} Action object with type `GET_SUCCESS` and project data.
   */
  function success(project) {
    localStorage.setItem('project', JSON.stringify(project.data));
    localStorage.setItem('projectId', parseInt(id));
    return { type: projectConstants.GET_SUCCESS, project };
  }

  /**
   * Creates an action for a failed project fetch.
   * 
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `GET_FAILURE` and error message.
   */
  function failure(error) {
    return { type: projectConstants.GET_FAILURE, error };
  }
}


/**
 * Creates a new project and updates the state accordingly.
 * Dispatches request, success, or failure actions based on the API response.
 * 
 * @param {Object} project - The project data to be created.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function createProject(project) {
  return dispatch => {
    dispatch(request(project));
    projectService.createProject(project)
      .then(
        project => {
          dispatch(success(project));
          dispatch(projectActions.getProjects());
          dispatch(alertActions.success('Created project successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request to create a project has started.
   * 
   * @param {Object} project - The project data being created.
   * @returns {Object} Action object with type `CREATE_REQUEST` and project data.
   */
  function request(project) {
    return { type: projectConstants.CREATE_REQUEST, project };
  }

  /**
   * Creates an action for a successful project creation.
   * 
   * @param {Object} project - The created project data.
   * @returns {Object} Action object with type `CREATE_SUCCESS` and project data.
   */
  function success(project) {
    return { type: projectConstants.CREATE_SUCCESS, project };
  }

  /**
   * Creates an action for a failed project creation.
   * 
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `CREATE_FAILURE` and error message.
   */
  function failure(error) {
    return { type: projectConstants.CREATE_FAILURE, error };
  }
}


/**
 * Updates an existing project and dispatches relevant actions.
 * Dispatches request, success, or failure actions based on the API response.
 *
 * @param {Object} project - The updated project data.
 * @param {number|string} id - The ID of the project to be updated.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updateProject(project, id) {
  return dispatch => {
    dispatch(request(project, id));
    projectService.updateProject(project, id)
      .then(
        project => {
          dispatch(success(project));
          dispatch(alertActions.success('Project updated successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request to update a project has started.
   *
   * @param {Object} project - The project data being updated.
   * @returns {Object} Action object with type `UPDATE_REQUEST` and project data.
   */
  function request(project) {
    return { type: projectConstants.UPDATE_REQUEST, project };
  }

  /**
   * Creates an action for a successful project update.
   *
   * @param {Object} project - The updated project data.
   * @returns {Object} Action object with type `UPDATE_SUCCESS` and updated project data.
   */
  function success(project) {
    return { type: projectConstants.UPDATE_SUCCESS, project };
  }

  /**
   * Creates an action for a failed project update.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `UPDATE_FAILURE` and error message.
   */
  function failure(error) {
    return { type: projectConstants.UPDATE_FAILURE, error };
  }
}

/**
 * Deletes a project and dispatches relevant actions.
 * Dispatches request, success, or failure actions based on the API response.
 * On success, redirects to the home page.
 *
 * @param {Object} project - The project data to be deleted.
 * @param {number|string} id - The ID of the project to be deleted.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function deleteProject(project, id) {
  return dispatch => {
    dispatch(request(project, id));
    projectService.deleteProject(project, id)
      .then(
        project => {
          dispatch(success());
          history.push('/');
          dispatch(alertActions.success(`Project deleted`));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request to delete a project has started.
   *
   * @param {Object} project - The project data being deleted.
   * @returns {Object} Action object with type `DELETE_REQUEST` and project data.
   */
  function request(project) {
    return { type: projectConstants.DELETE_REQUEST, project };
  }

  /**
   * Creates an action for a successful project deletion.
   *
   * @returns {Object} Action object with type `DELETE_SUCCESS`.
   */
  function success() {
    return { type: projectConstants.DELETE_SUCCESS };
  }

  /**
   * Creates an action for a failed project deletion.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `DELETE_FAILURE` and error message.
   */
  function failure(error) {
    return { type: projectConstants.DELETE_FAILURE, error };
  }
}

/**
 * Updates the status of a project and dispatches relevant actions.
 * Dispatches request, success, or failure actions based on the API response.
 *
 * @param {number|string} project_id - The ID of the project to update.
 * @param {string} status - The new status of the project.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updateStatus(project_id, status) {
  return dispatch => {
      dispatch(request(project_id, status));
      projectService.updateStatus(project_id, status)
      .then(
          status => {
              dispatch(success(status));
              dispatch(alertActions.success(`Successfully changed status.`));
          },
          error => {
              dispatch(failure(error.toString()));
              dispatch(alertActions.error(error.toString()));
          }
      );
  };

  /**
   * Creates an action to indicate the request to update a project's status has started.
   *
   * @param {string} status - The new status being set.
   * @returns {Object} Action object with type `UPDATE_STATUS_REQUEST` and status.
   */
  function request(status) {
      return { type: projectConstants.UPDATE_STATUS_REQUEST, status };
  }

  /**
   * Creates an action for a successful status update.
   *
   * @param {string} status - The updated status of the project.
   * @returns {Object} Action object with type `UPDATE_STATUS_SUCCESS` and updated status.
   */
  function success(status) {
      return { type: projectConstants.UPDATE_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed status update.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `UPDATE_STATUS_FAILURE` and error message.
   */
  function failure(error) {
      return { type: projectConstants.UPDATE_STATUS_FAILURE, error };
  }
}


/**
 * Fetches all members of a given project and dispatches relevant actions.
 * Dispatches request, success, or failure actions based on the API response.
 *
 * @param {number|string} project_id - The ID of the project whose members are being fetched.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getAllProjectMembers(project_id) {
  return dispatch => {
      dispatch(request(project_id));
      projectService.getAllProjectMembers(project_id)
          .then(
              members => {
                  dispatch(success(members));
              },
              error => {
                  dispatch(failure(project_id, error.toString()));
                  dispatch(alertActions.error(project_id, error.toString()));
              }
          );
  };

  /**
   * Creates an action to indicate the request to fetch project members has started.
   *
   * @param {number|string} project_id - The ID of the project.
   * @returns {Object} Action object with type `GET_ALL_MEMBER_REQUEST` and project ID.
   */
  function request(project_id) {
      return { type: projectConstants.GET_ALL_MEMBER_REQUEST, project_id };
  }

  /**
   * Creates an action for a successful fetch of project members.
   *
   * @param {Array} members - The list of project members.
   * @returns {Object} Action object with type `GET_ALL_MEMBER_SUCCESS` and members data.
   */
  function success(members) {
      return { type: projectConstants.GET_ALL_MEMBER_SUCCESS, members };
  }

  /**
   * Creates an action for a failed attempt to fetch project members.
   *
   * @param {number|string} id - The project ID.
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `GET_ALL_MEMBER_FAILURE`, project ID, and error message.
   */
  function failure(id, error) {
      return { type: projectConstants.GET_ALL_MEMBER_FAILURE, id, error };
  }
}

/**
 * Adds a new member to a project and dispatches relevant actions.
 * Also fetches updated project details and managers list upon success.
 *
 * @param {Object} member - The member data to be added.
 * @param {number|string} id - The project ID to which the member is being added.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function addProjectMember(member, id) {
  return dispatch => {
    dispatch(request(member, id));
    projectService.addProjectMember(member, id)
      .then(
        member => {
          dispatch(success(member));
          dispatch(managerActions.getManagers(id));
          dispatch(getProject(id));
          dispatch(alertActions.success('Member added successfully'));
        },
        error => {
          const { response } = error;
          const { data } = response;

          let errorMessage = '';
          if (data.error === 'Internal Server Error') {
            errorMessage = {
              error_code: "PM001",
              error_message: "Adding member failed.",
            };
          } else {
            errorMessage = data;
          }
          console.log('error: ', response);
          dispatch(failure(errorMessage));
          dispatch(alertActions.error(errorMessage));
        }
      );
  };

  /**
   * Creates an action to indicate the request to add a project member has started.
   *
   * @param {Object} member - The member data being added.
   * @returns {Object} Action object with type `ADD_MEMBER_REQUEST` and member data.
   */
  function request(member) {
    return { type: projectConstants.ADD_MEMBER_REQUEST, member };
  }

  /**
   * Creates an action for successfully adding a project member.
   *
   * @param {Object} member - The newly added member data.
   * @returns {Object} Action object with type `ADD_MEMBER_SUCCESS` and member data.
   */
  function success(member) {
    return { type: projectConstants.ADD_MEMBER_SUCCESS, member };
  }

  /**
   * Creates an action for a failed attempt to add a project member.
   *
   * @param {Object|string} error - The error message or error object.
   * @returns {Object} Action object with type `ADD_MEMBER_FAILURE` and error data.
   */
  function failure(error) {
    return { type: projectConstants.ADD_MEMBER_FAILURE, error };
  }
}

/**
 * Deletes a member from a project and dispatches relevant actions.
 * Displays success or error messages based on the operation result.
 *
 * @param {Object} member - The member data to be removed.
 * @param {number|string} project_id - The ID of the project.
 * @param {number|string} member_id - The ID of the member to be removed.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function deleteProjectMember(member, project_id, member_id) {
  return dispatch => {
    dispatch(request(member, project_id, member_id));
    projectService.deleteProjectMember(member, project_id, member_id)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(`Member removed from project`));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request to delete a project member has started.
   *
   * @param {Object} member - The member data being removed.
   * @returns {Object} Action object with type `DELETE_MEMBER_REQUEST` and member data.
   */
  function request(member) {
    return { type: projectConstants.DELETE_MEMBER_REQUEST, member };
  }

  /**
   * Creates an action for successfully deleting a project member.
   *
   * @returns {Object} Action object with type `DELETE_MEMBER_SUCCESS`.
   */
  function success() {
    return { type: projectConstants.DELETE_MEMBER_SUCCESS };
  }

  /**
   * Creates an action for a failed attempt to delete a project member.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `DELETE_MEMBER_FAILURE` and error data.
   */
  function failure(error) {
    return { type: projectConstants.DELETE_MEMBER_FAILURE, error };
  }
}

/**
 * Fetches all statuses for a given project and dispatches relevant actions.
 * Stores the retrieved project status in session storage.
 *
 * @param {number|string} project_id - The ID of the project.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getAllProjectStatus(project_id) {
  return dispatch => {
      dispatch(request(project_id));
      projectService.getAllProjectStatus(project_id)
          .then(
              status => {
                  dispatch(success(status));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(project_id, error.toString()));
              }
          );
  };

  /**
   * Creates an action to indicate the request to fetch project statuses has started.
   *
   * @param {number|string} project_id - The ID of the project.
   * @returns {Object} Action object with type `GET_ALL_STATUS_REQUEST` and project ID.
   */
  function request(project_id) {
      return { type: projectConstants.GET_ALL_STATUS_REQUEST, project_id };
  }

  /**
   * Creates an action for successfully retrieving project statuses.
   * Stores the statuses in session storage.
   *
   * @param {Object} status - The retrieved project status data.
   * @returns {Object} Action object with type `GET_ALL_STATUS_SUCCESS` and status data.
   */
  function success(status) {
      localStorage.setItem('projectStatus', JSON.stringify(status.data));
      return { type: projectConstants.GET_ALL_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed attempt to fetch project statuses.
   *
   * @param {number|string} project_id - The ID of the project.
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `GET_ALL_STATUS_FAILURE`, project ID, and error data.
   */
  function failure(project_id, error) {
      return { type: projectConstants.GET_ALL_STATUS_FAILURE, project_id, error };
  }
}

/**
 * Creates a new lead status for a given project and dispatches relevant actions.
 *
 * @param {Object} status - The lead status data to be created.
 * @param {number|string} id - The ID of the project.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function createLeadStatus(status, id) {
  return dispatch => {
    dispatch(request(status, id));
    projectService.createLeadStatus(status, id)
      .then(
        status => {
          dispatch(success(status));
          dispatch(alertActions.success('Lead status added successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request to create a lead status has started.
   *
   * @param {Object} status - The lead status data.
   * @returns {Object} Action object with type `CREATE_STATUS_REQUEST` and status data.
   */
  function request(status) {
    return { type: projectConstants.CREATE_STATUS_REQUEST, status };
  }

  /**
   * Creates an action for successfully creating a lead status.
   *
   * @param {Object} status - The created lead status data.
   * @returns {Object} Action object with type `CREATE_STATUS_SUCCESS` and status data.
   */
  function success(status) {
    return { type: projectConstants.CREATE_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed attempt to create a lead status.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `CREATE_STATUS_FAILURE` and error data.
   */
  function failure(error) {
    return { type: projectConstants.CREATE_STATUS_FAILURE, error };
  }
}

/**
 * Finds a specific project status by project ID and status ID.
 *
 * @param {number|string} project_id - The ID of the project.
 * @param {number|string} status_id - The ID of the status to find.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function findProjectStatus(project_id, status_id) {
  return dispatch => {
      dispatch(request(project_id, status_id));
      projectService.findProjectStatus(project_id, status_id)
          .then(
              status => {
                  dispatch(success(status));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(status_id, error.toString()));
              }
          );
  };

  /**
   * Creates an action to indicate the request to find a project status has started.
   *
   * @param {Object} status - The project status being requested.
   * @returns {Object} Action object with type `GET_STATUS_REQUEST` and status data.
   */
  function request(status) {
      return { type: projectConstants.GET_STATUS_REQUEST, status };
  }

  /**
   * Creates an action for successfully retrieving a project status.
   *
   * @param {Object} status - The retrieved project status data.
   * @returns {Object} Action object with type `GET_STATUS_SUCCESS` and status data.
   */
  function success(status) {
      return { type: projectConstants.GET_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed attempt to retrieve a project status.
   *
   * @param {number|string} status_id - The ID of the status that failed to retrieve.
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `GET_STATUS_FAILURE`, status_id, and error data.
   */
  function failure(status_id, error) {
      return { type: projectConstants.GET_STATUS_FAILURE, status_id, error };
  }
}

/**
 * Retrieves the count of project statuses for a given project ID.
 *
 * @param {number|string} project_id - The ID of the project.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function findProjectStatusCount(project_id) {
  return dispatch => {
      dispatch(request(project_id));
      projectService.findProjectStatusCount(project_id)
          .then(
              status_count => {
                  dispatch(success(status_count));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              }
          );
  };

  /**
   * Creates an action to indicate the request to find the project status count has started.
   *
   * @param {number|string} project_id - The ID of the project.
   * @returns {Object} Action object with type `GET_STATUS_COUNT_REQUEST` and project_id.
   */
  function request(project_id) {
      return { type: projectConstants.GET_STATUS_COUNT_REQUEST, project_id };
  }

  /**
   * Creates an action for successfully retrieving the project status count.
   *
   * @param {Object} status_count - The retrieved project status count data.
   * @returns {Object} Action object with type `GET_STATUS_COUNT_SUCCESS` and status count data.
   */
  function success(status_count) {
      localStorage.setItem('statusCount', JSON.stringify(status_count));
      return { type: projectConstants.GET_STATUS_COUNT_SUCCESS, status_count };
  }

  /**
   * Creates an action for a failed attempt to retrieve the project status count.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `GET_STATUS_COUNT_FAILURE` and error data.
   */
  function failure(error) {
      return { type: projectConstants.GET_STATUS_COUNT_FAILURE, error };
  }
}

/**
 * Updates the lead status for a given project and status ID.
 *
 * @param {Object} lead_status - The updated lead status data.
 * @param {number|string} project_id - The ID of the project.
 * @param {number|string} status_id - The ID of the status to update.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function updateLeadStatus(lead_status, project_id, status_id) {
  return dispatch => {
    dispatch(request(lead_status, project_id, status_id));
    projectService.updateLeadStatus(lead_status, project_id, status_id)
      .then(
        status => {
          dispatch(success());
          dispatch(alertActions.success('Project Status updated successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request to update the project status has started.
   *
   * @param {Object} status - The lead status being updated.
   * @returns {Object} Action object with type `UPDATE_PROJECT_STATUS_REQUEST` and status data.
   */
  function request(status) {
    return { type: projectConstants.UPDATE_PROJECT_STATUS_REQUEST, status };
  }

  /**
   * Creates an action for successfully updating the project status.
   *
   * @param {Object} status - The updated project status data.
   * @returns {Object} Action object with type `UPDATE_PROJECT_STATUS_SUCCESS` and status data.
   */
  function success(status) {
    return { type: projectConstants.UPDATE_PROJECT_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed attempt to update the project status.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `UPDATE_PROJECT_STATUS_FAILURE` and error data.
   */
  function failure(error) {
    return { type: projectConstants.UPDATE_PROJECT_STATUS_FAILURE, error };
  }
}


/**
 * Deletes a lead status from a project.
 *
 * @param {Object} lead_status - The lead status data to be deleted.
 * @param {number|string} project_id - The ID of the project.
 * @param {number|string} status_id - The ID of the status to delete.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function deleteLeadStatus(lead_status, project_id, status_id) {
  return dispatch => {
    dispatch(request(lead_status, project_id, status_id));
    projectService.deleteLeadStatus(lead_status, project_id, status_id)
      .then(
        status => {
          dispatch(success());
          dispatch(alertActions.success(`Project status deleted from project`));
          dispatch(projectActions.getAllProjectStatus(project_id));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  /**
   * Creates an action to indicate the request to delete a project status has started.
   *
   * @param {Object} status - The lead status being deleted.
   * @returns {Object} Action object with type `DELETE_STATUS_REQUEST` and status data.
   */
  function request(status) {
    return { type: projectConstants.DELETE_STATUS_REQUEST, status };
  }

  /**
   * Creates an action for successfully deleting the project status.
   *
   * @param {Object} status - The deleted project status data.
   * @returns {Object} Action object with type `DELETE_STATUS_SUCCESS` and status data.
   */
  function success(status) {
    return { type: projectConstants.DELETE_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed attempt to delete the project status.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `DELETE_STATUS_FAILURE` and error data.
   */
  function failure(error) {
    return { type: projectConstants.DELETE_STATUS_FAILURE, error };
  }
}

/**
 * Enables a project status.
 *
 * @param {number|string} project_id - The ID of the project.
 * @param {number|string} status_id - The ID of the status to enable.
 * @param {Object} status - The status data to be enabled.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function enableProjectStatus(project_id, status_id, status) {
  return dispatch => {
      dispatch(request(project_id, status_id, status));
      projectService.enableProjectStatus(project_id, status_id, status)
          .then(
              status => {
                  dispatch(success(status));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              }
          );
  };

  /**
   * Creates an action to indicate the request to enable a project status has started.
   *
   * @param {Object} status - The status being enabled.
   * @returns {Object} Action object with type `UPDATE_STATUS_REQUEST` and status data.
   */
  function request(status) {
      return { type: projectConstants.UPDATE_STATUS_REQUEST, status };
  }

  /**
   * Creates an action for successfully enabling the project status.
   *
   * @param {Object} status - The enabled project status data.
   * @returns {Object} Action object with type `UPDATE_STATUS_SUCCESS` and status data.
   */
  function success(status) {
      return { type: projectConstants.UPDATE_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed attempt to enable the project status.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `UPDATE_STATUS_FAILURE` and error data.
   */
  function failure(error) {
      return { type: projectConstants.UPDATE_STATUS_FAILURE, error };
  }
}

/**
 * Creates or updates the default status for a project.
 *
 * @param {number|string} project_id - The ID of the project.
 * @param {number|string} default_status_id - The ID of the default status to be set.
 * @param {Object} status - The status data to be assigned as default.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function createProjectDefaultStatus(project_id, default_status_id, status) {
  return dispatch => {
      dispatch(request(project_id, default_status_id, status));
      projectService.createProjectDefaultStatus(project_id, default_status_id, status)
          .then(
              status => {
                  dispatch(success(status));
                  dispatch(alertActions.success(`Successfully updated default status`));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(default_status_id, error.toString()));
              }
          );
  };

  /**
   * Creates an action to indicate the request to create/update a default project status has started.
   *
   * @param {Object} status - The status being set as default.
   * @returns {Object} Action object with type `CREATE_DEFAULT_STATUS_REQUEST` and status data.
   */
  function request(status) {
      return { type: projectConstants.CREATE_DEFAULT_STATUS_REQUEST, status };
  }

  /**
   * Creates an action for successfully creating/updating the default project status.
   *
   * @param {Object} status - The newly assigned default project status data.
   * @returns {Object} Action object with type `CREATE_DEFAULT_STATUS_SUCCESS` and status data.
   */
  function success(status) {
      return { type: projectConstants.CREATE_DEFAULT_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed attempt to create/update the default project status.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `CREATE_DEFAULT_STATUS_FAILURE` and error data.
   */
  function failure(error) {
      return { type: projectConstants.CREATE_DEFAULT_STATUS_FAILURE, error };
  }
}

/**
 * Creates or updates the conversion status for a project.
 *
 * @param {number|string} project_id - The ID of the project.
 * @param {number|string} default_status_id - The ID of the conversion status to be set.
 * @param {Object} status - The status data to be assigned as the conversion status.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function createProjectConversionStatus(project_id, default_status_id, status) {
  return dispatch => {
      dispatch(request(project_id, default_status_id, status));
      projectService.createProjectConversionStatus(project_id, default_status_id, status)
          .then(
              status => {
                  dispatch(success(status));
                  dispatch(alertActions.success(`Successfully set conversion status`));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(default_status_id, error.toString()));
              }
          );
  };

  /**
   * Creates an action to indicate the request to create/update a project conversion status has started.
   *
   * @param {Object} status - The status being set as the conversion status.
   * @returns {Object} Action object with type `CREATE_CONVERSION_STATUS_REQUEST` and status data.
   */
  function request(status) {
      return { type: projectConstants.CREATE_CONVERSION_STATUS_REQUEST, status };
  }

  /**
   * Creates an action for successfully creating/updating the project conversion status.
   *
   * @param {Object} status - The newly assigned conversion status data.
   * @returns {Object} Action object with type `CREATE_CONVERSION_STATUS_SUCCESS` and status data.
   */
  function success(status) {
      return { type: projectConstants.CREATE_CONVERSION_STATUS_SUCCESS, status };
  }

  /**
   * Creates an action for a failed attempt to create/update the project conversion status.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `CREATE_CONVERSION_STATUS_FAILURE` and error data.
   */
  function failure(error) {
      return { type: projectConstants.CREATE_CONVERSION_STATUS_FAILURE, error };
  }
}

/**
 * Retrieves the activity log for a specific project.
 *
 * @param {number|string} project_id - The ID of the project whose activity log is being requested.
 * @returns {Function} Thunk function for Redux dispatch.
 */
function getActivityLog(project_id) {
  return dispatch => {
      dispatch(request());
      projectService.getActivityLog(project_id)
          .then(
              activity_log => {
                  dispatch(success(activity_log));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              }
          );
  };

  /**
   * Creates an action to indicate the request to fetch the activity log has started.
   *
   * @param {number|string} project_id - The project ID for which logs are being fetched.
   * @returns {Object} Action object with type `GET_LOG_REQUEST` and project_id.
   */
  function request(project_id) {
      return { type: projectConstants.GET_LOG_REQUEST, project_id };
  }

  /**
   * Creates an action for successfully retrieving the activity log.
   *
   * @param {Object} activity_log - The retrieved activity log data.
   * @returns {Object} Action object with type `GET_LOG_SUCCESS` and activity log data.
   */
  function success(activity_log) {
      return { type: projectConstants.GET_LOG_SUCCESS, activity_log };
  }

  /**
   * Creates an action for a failed attempt to fetch the activity log.
   *
   * @param {string} error - The error message.
   * @returns {Object} Action object with type `GET_LOG_FAILURE` and error data.
   */
  function failure(error) {
      return { type: projectConstants.GET_LOG_FAILURE, error };
  }
}