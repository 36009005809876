import React from 'react';
import Button from '../Button';

const BasicInfo = (
    { 
        name, dateContact, email, phone, rateModalAdd, rateModalEdit, confirmationDelete

    }
) => {
  return (
    <>
        <div className='basic-info-box box-white-custom'>
            <div className='title-box'>
                 <span>
                    <i className='far fa-address-card'></i>
                    Basic Information
                 </span>
            </div>
            <div className='custom-scroll-box'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <div className='input-wrap p--sm width--full'>
                                <label>Influencer Name</label>
                                <span>
                                    { name }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <div className='input-wrap p--sm width--full'>
                                <label>Email</label>
                                <span>
                                    { email }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <div className='input-wrap p--sm width--full'>
                                <label>Phone Number</label>
                                <span>
                                    { phone }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <div className='input-wrap p--sm width--full'>
                                <label>Date Contacted</label>
                                <span>
                                    { dateContact }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='handler-details'>
                            <p>Handler Details</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <div className='input-wrap p--sm width--full'>
                                <label>Handler Name</label>
                                <span>
                                    Tier One
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <div className='input-wrap p--sm width--full'>
                                <label>Handler Email</label>
                                <span>
                                    tierone@gmail.com
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <div className='input-wrap p--sm width--full'>
                                <label>Handler Phone</label>
                                <span>
                                    0909123456
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='add-rates-box-seperate'>
                    <div className='title-box'>
                        <span>
                            <i className='fas fa-money-check'></i>
                            Influencer Rates
                        </span>
                        <Button
                            text='Add Rate'
                            icon='fa-plus'
                            onClick={rateModalAdd}
                        />
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <div className='input-wrap p--sm width--full influencer-rate-box'>
                                    <label>Platform Rate
                                    <button className='edit-record' onClick={rateModalEdit}><i className="fas fa-pen"></i>Edit</button>
                                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                                    </label>
                                    <span>
                                    1. Branded Artcard <br />
                                    - 1 Unit: PHP 111,111.11 <br />
                                    - 5+1 Units: PHP 388,888.85 <br />
                                    2. User-Generated Content <br />
                                    - 1 UGC Unit: PHP 188,888.88 <br />
                                    - 1 UGC With Album: PHP 277,777.77 <br />
                                    3.1. Native Headlined Video <br />
                                    - First 5-60 Seconds: PHP 133,333.33 <br /> 
                                    - Succeeding 1-60S: PHP 55,555.55 <br />
                                    3.2. Shared Branded Post <br />
                                    - 1 Unit: PHP 66,666.66 <br />
                                    ----------- <br />
                                    ADD-ON: Exclusivity Fee / Non-compete: <br /> 
                                    First Month: PHP 77,777.77 <br />
                                    Succeeding Month: PHP 55,555.55 <br />
                                    Annual Package: PHP 600,000.00 <br />
                                    Packages also available (please see rate card)
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <div className='input-wrap p--sm width--full influencer-rate-box'>
                                    <label>Platform Rate
                                    <button className='edit-record' onClick={rateModalEdit}><i className="fas fa-pen"></i>Edit</button>
                                    <button className='delete-record' onClick={confirmationDelete}><i className="fas fa-trash-alt"></i>Delete</button>
                                    </label>
                                    <span>
                                        ₱ 25,000 per video
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* loader */}
                <div className="loading-box">
                    <div className='loader-box'>
                        <div className="loader-custom">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BasicInfo