import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { leadActions } from '_actions';

import { MDBModal, MDBModalBody, MDBModalHeader, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button } from 'blackbox-react';
import InputForm from '_components/InputForm';
import { FormValidation } from '_components/FormValidation';
import { Loader } from '_components/Loader';

const AddLeadNoteModal = (props) => {
    const {
        modalOpen,
        toggleAddLeadNote,
        addLeadNote,
        creating,
        leadId,
        projectStatusId,
        addLocation,
        handleGetLead,
        handleGetLeads,
    } = props;

    const [note, setNote] = useState({
        noteTitle: '',
        noteBody: '',
    });

    const [formErrors, setFormErrors] = useState({
        noteTitle: 'Please enter a title.',
        noteBody: 'Please enter your notes.',
    });

    const [noteTitleValid, setNoteTitleValid] = useState(false);
    const [noteBodyValid, setNoteBodyValid] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (modalOpen) {
            resetForm();
        }
    }, [modalOpen]);

    const resetForm = () => {
        setNote({
            noteTitle: '',
            noteBody: '',
        });
        setFormErrors({
            noteTitle: 'Please enter a title.',
            noteBody: 'Please enter your notes.',
        });
        setNoteTitleValid(false);
        setNoteBodyValid(false);
        setFormValid(false);
        setSubmitted(false);
    };

    const validateField = (fieldName, value) => {
        let fieldValidationErrors = { ...formErrors };
        let isNoteTitleValid = noteTitleValid;
        let isNoteBodyValid = noteBodyValid;

        const isAllWhitespace = value.replace(/(?!^ +$)^.+$/gm, '') !== '';

        switch (fieldName) {
            case 'noteTitle':
                if (value === '' || isAllWhitespace) {
                    isNoteTitleValid = false;
                    fieldValidationErrors.noteTitle = 'Please enter a title.';
                } else {
                    isNoteTitleValid = value.length >= 0;
                    fieldValidationErrors.noteTitle = isNoteTitleValid ? '' : 'Please enter a title.';
                }
                break;
            case 'noteBody':
                if (value === '') {
                    isNoteBodyValid = false;
                    fieldValidationErrors.noteBody = 'Please enter your notes.';
                } else {
                    isNoteBodyValid = value.length >= 0;
                    fieldValidationErrors.noteBody = isNoteBodyValid ? '' : 'Please enter your notes.';
                }
                break;
            default:
                break;
        }

        setFormErrors(fieldValidationErrors);
        setNoteTitleValid(isNoteTitleValid);
        setNoteBodyValid(isNoteBodyValid);
        validateForm(isNoteTitleValid, isNoteBodyValid);
    };

    const validateForm = (isNoteTitleValid, isNoteBodyValid) => {
        setFormValid(isNoteTitleValid && isNoteBodyValid);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setNote((prevNote) => ({
            ...prevNote,
            [name]: value,
        }));
        validateField(name, value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);

        if (formValid) {
            const projectId = localStorage.getItem('projectId');
            const user = JSON.parse(localStorage.getItem('user'));
            const userEmail = user && user.data.email;

            const noteData = {
                name: note.noteTitle,
                description: note.noteBody,
                project_status_id: projectStatusId,
                sender: userEmail,
            };

            const noteType = 'note';

            addLeadNote(projectId, leadId, noteType, noteData).then(() => {
                if (modalOpen) {
                    toggleAddLeadNote();
                }

                resetForm();

                if (addLocation === 'leadInfo') {
                    handleGetLead();
                }
                if (addLocation === 'allLeads') {
                    handleGetLeads();
                }
            });
        }
    };

    const errorClass = (error) => {
        return error.length === 0 ? 'is-passed' : 'is-failed';
    };

    return (
        <MDBContainer>
            <form noValidate>
                <MDBModal isOpen={modalOpen} toggle={toggleAddLeadNote} className='create-form-modal' centered>
                    <MDBModalHeader
                        className='padding-left-18 padding-right-18 pb-3 pt-3'
                        toggle={toggleAddLeadNote}
                        onClick={resetForm}
                    >
                        <p className='mb-0 font-size-12 font-weight-600 letter-spacing-1'>ADD NOTE</p>
                    </MDBModalHeader>
                    <MDBModalBody className='p-t--0 padding-left-18 padding-right-18 padding-bottom-15'>
                        <MDBRow>
                            <MDBCol className={`form-group m--sm ${submitted && errorClass(formErrors.noteTitle)}`}>
                                <label className='mb-1 font-size-12 text-black'>
                                    Title <span className='color-red'>*</span>
                                </label>
                                <InputForm
                                    onChange={handleChange}
                                    type='text'
                                    name='noteTitle'
                                    value={note.noteTitle}
                                    id='FormName'
                                    hint='Enter note title here...'
                                />
                                <div
                                    className={`form-validation__container ${
                                        !formValid && submitted ? 'display-block' : 'display-none'
                                    }`}
                                >
                                    <FormValidation fieldName='noteTitle' formErrors={formErrors} />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className={`form-group m--sm ${submitted && errorClass(formErrors.noteBody)}`}>
                                <label className='mb-1 pt-2 font-size-12 text-black'>
                                    Body <span className='color-red'>*</span>
                                </label>
                                <textarea
                                    name='noteBody'
                                    value={note.noteBody}
                                    id='textarea-noteBody'
                                    className='form-control font-size-12 color-none'
                                    rows='7'
                                    placeholder='Enter notes here...'
                                    onChange={handleChange}
                                />
                                <div
                                    className={`form-validation__container ${
                                        !formValid && submitted ? 'display-block' : 'display-none'
                                    }`}
                                >
                                    <FormValidation fieldName='noteBody' formErrors={formErrors} />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='p-t--md'>
                            <MDBCol className='d-flex'>
                                <span
                                    className='mb-0 color-gray my-auto text-cursor-pointer border-bottom-gray line-height-15 font-size-12'
                                    onClick={toggleAddLeadNote}
                                >
                                    Cancel
                                </span>
                            </MDBCol>
                            <MDBCol className='text-right' id='nextButton'>
                                <Button flat='true' type='submit' size={-1} background='orange' onClick={handleSubmit}>
                                    Add Note {creating && <Loader />}
                                </Button>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </MDBModal>
            </form>
        </MDBContainer>
    );
};

const mapState = (state) => {
    const { creating } = state.leads;
    return { creating };
};

const actionCreators = {
    addLeadNote: leadActions.addLeadNote,
};

const connectedAddLeadNoteModal = connect(mapState, actionCreators)(AddLeadNoteModal);
export { connectedAddLeadNoteModal as AddLeadNoteModal };