import { httpManagerService as httpManager } from '_services';

export const templateService = {
    getTemplates,
    getTemplate,
    createTemplate,
    updateTemplate,
    removeTemplate,
    removeTemplates,
    shareTemplate,
    unshareTemplate,
    duplicateTemplate,
    getSharedTemplates,
    getSharedTemplatePaginated,
    getTemplatesDropdown,
    getAccountCategories,
    getSharedCategories
};

/**
 * Fetches a list of templates with optional filtering and pagination.
 * @param {number} projectId - The project ID.
 * @param {string} [query=null] - The search query for filtering templates.
 * @param {number} [page=1] - The current page number.
 * @param {number} [size=15] - The number of templates per page.
 * @param {string} [cat=''] - The category filter.
 * @returns {Promise<Object>} A promise resolving to the list of templates.
 */
function getTemplates(projectId, query = null, page = 1, size = 15, cat='') {
    let parts = [];
    if (query) parts.push(`query=${query}`);
    if (page > 0) parts.push(`page=${page}`);
    if (size > 0) parts.push(`size=${size}`);
    if (cat) parts.push(`cat=${cat}`);

    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template?` + parts.join('&'));
}

/**
 * Retrieves a specific template by its ID.
 * @param {number} projectId - The project ID.
 * @param {number} templateId - The template ID.
 * @returns {Promise<Object>} A promise resolving to the template data.
 */
function getTemplate(projectId, templateId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template/${templateId}`);
}

/**
 * Fetches the template dropdown list.
 * @param {number} projectId - The project ID.
 * @returns {Promise<Object>} A promise resolving to the dropdown options.
 */
function getTemplatesDropdown(projectId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template/dropdown`);
}

/**
 * Creates a new template.
 * @param {number} projectId - The project ID.
 * @param {Object} request - The template data.
 * @returns {Promise<Object>} A promise resolving to the created template.
 */
function createTemplate(projectId, request) {
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/template`, request);
}

/**
 * Updates an existing template.
 * @param {number} projectId - The project ID.
 * @param {number} templateId - The template ID.
 * @param {Object} request - The updated template data.
 * @returns {Promise<Object>} A promise resolving to the updated template.
 */
function updateTemplate(projectId, templateId, request) {
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/template/${templateId}`, request);
}

/**
 * Deletes a specific template.
 * @param {number} projectId - The project ID.
 * @param {number} templateId - The template ID.
 * @returns {Promise<Object>} A promise resolving to the deletion status.
 */
function removeTemplate(projectId, templateId) {
    return httpManager.deleteJsonOnce(`/api/v2/project/${projectId}/template/${templateId}`);
}

/**
 * Deletes multiple templates.
 * @param {number} projectId - The project ID.
 * @param {Object} request - The list of template IDs to remove.
 * @returns {Promise<Object>} A promise resolving to the deletion status.
 */
function removeTemplates(projectId, request) {
    return httpManager.deleteJsonOnce(`/api/v2/project/${projectId}/templates`, request);
}

/**
 * Shares a template with other users.
 * @param {number} projectId - The project ID.
 * @param {number} templateId - The template ID.
 * @returns {Promise<Object>} A promise resolving to the sharing status.
 */
function shareTemplate(projectId, templateId) {
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/template/${templateId}/shared`);
}

/**
 * Unshares a template.
 * @param {number} projectId - The project ID.
 * @param {number} templateId - The template ID.
 * @returns {Promise<Object>} A promise resolving to the unsharing status.
 */
function unshareTemplate(projectId, templateId) {
    return httpManager.putJsonOnce(`/api/v2/project/${projectId}/template/${templateId}/unshared`);
}

/**
 * Duplicates an existing template.
 * @param {number} projectId - The project ID.
 * @param {number} templateId - The template ID.
 * @param {Object} request - The duplication request data.
 * @returns {Promise<Object>} A promise resolving to the duplicated template.
 */
function duplicateTemplate(projectId, templateId, request) {
    return httpManager.postJsonOnce(`/api/v2/project/${projectId}/template/${templateId}`, request);
}

/**
 * Retrieves a list of shared templates.
 * @param {number} projectId - The project ID.
 * @returns {Promise<Object>} A promise resolving to the list of shared templates.
 */
function getSharedTemplates(projectId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/shared/template`);
}

/**
 * Fetches a paginated list of shared templates with optional filtering.
 * @param {number} projectId - The project ID.
 * @param {string} [query=null] - The search query.
 * @param {number} [page=1] - The page number.
 * @param {number} [size=15] - The number of items per page.
 * @param {string} [cat=''] - The category filter.
 * @returns {Promise<Object>} A promise resolving to the shared templates list.
 */
function getSharedTemplatePaginated(projectId, query = null, page = 1, size = 15, cat='') {
    let parts = [];
    if (query) parts.push(`query=${query}`);
    if (page > 0) parts.push(`page=${page}`);
    if (size > 0) parts.push(`size=${size}`);
    if (cat) parts.push(`cat=${cat}`);

    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/shared/template?` + parts.join('&'));
}

/**
 * Fetches account categories for templates.
 * @param {number} projectId - The project ID.
 * @returns {Promise<Object>} A promise resolving to the account categories.
 */
function getAccountCategories(projectId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template/category/account`);
}

/**
 * Fetches shared template categories.
 * @param {number} projectId - The project ID.
 * @returns {Promise<Object>} A promise resolving to the shared categories.
 */
function getSharedCategories(projectId) {
    return httpManager.getHttpWithRequestingToken(`/api/v2/project/${projectId}/template/category/shared`);
}