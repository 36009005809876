import { deckConstants } from "_constants";

/**
 * Reducer function to handle deck-related actions.
 *
 * @param {Object} state - The current state of the deck.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.decks] - The decks data (if applicable).
 * @param {Object} [action.error] - The error object (if applicable).
 * @returns {Object} The new state of the deck.
 */
export function deck(state = {}, action) {
  switch (action.type) {
    case deckConstants.GET_ALL_DECK_REQUEST:
      return {
        ...state,
        getdeckLoading: true,
        getdeckLoaded: false,
      };
    case deckConstants.GET_ALL_DECK_SUCCESS:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        decks: action.decks,
      };
    case deckConstants.GET_ALL_DECK_FAILURE:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        error: action.error,
      };

    case deckConstants.UPDATE_DECK_REQUEST:
      return {
        ...state,
        getdeckLoading: true,
        getdeckLoaded: false,
      };

    case deckConstants.UPDATE_DECK_SUCCESS:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        decks: action.decks,
      };
    case deckConstants.UPDATE_DECK_FAILURE:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        error: action.error,
      };
    case deckConstants.CREATE_DECK_REQUEST:
      return {
        ...state,
        getdeckLoading: true,
        getdeckLoaded: false,
      };

    case deckConstants.CREATE_DECK_SUCCESS:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        decks: action.decks,
      };
    case deckConstants.CREATE_DECK_FAILURE:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        error: action.error,
      };
    case deckConstants.DELETE_DECK_REQUEST:
      return {
        ...state,
        getdeckLoading: true,
        getdeckLoaded: false,
      };
    case deckConstants.DELETE_DECK_SUCCESS:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        decks: action.decks,
      };
    case deckConstants.DELETE_DECK_FAILURE:
      return {
        ...state,
        getdeckLoading: false,
        getdeckLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
