import { calendlyConstants } from "_constants";
import { calendlyService } from "_services";
import { alertActions } from "./alert.actions";

export const calendlyActions = {
    getCalendly,
    updateCalendly,
    createCalendly,
    deleteCalendly,
  };

  /**
   * Fetches Calendly data for a specific project and member.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} memberId - The ID of the member.
   * @returns {Function} A Redux thunk action that dispatches request, success, or failure actions based on the API response.
   */
  function getCalendly(projectId, memberId) {

    return (dispatch) => {
      dispatch(request());
      calendlyService.getCalendly(projectId, memberId).then(
        (calendly) => {
          dispatch(success(calendly));

        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: calendlyConstants.GET_CALENDLY_REQUEST };
    }
    function success(calendly) {
      return { type: calendlyConstants.GET_CALENDLY_SUCCESS, calendly };
    }
    function failure(error) {
      return { type: calendlyConstants.GET_CALENDLY_FAILURE, error };
    }
  }

  /**
   * Creates a new Calendly entry.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} memberId - The ID of the member.
   * @param {Object} calendlyRequest - The request payload for creating a Calendly entry.
   * @returns {Function} A thunk function that dispatches actions based on the API response.
   */
  function createCalendly(projectId, memberId, calendlyRequest) {
    return (dispatch) => {
      dispatch(request());
      calendlyService.createCalendly(projectId, memberId, calendlyRequest).then(
        (calendly) => {
          dispatch(success(calendly));
          dispatch(
            alertActions.success("Calendly successfully added.")
          );
    
        },
        (error) => {
            // console.log('error: ', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: calendlyConstants.CREATE_CALENDLY_REQUEST };
    }
    function success(calendly) {
      return { type: calendlyConstants.CREATE_CALENDLY_SUCCESS, calendly };
    }
    function failure(error) {
      return { type: calendlyConstants.CREATE_CALENDLY_FAILURE, error };
    }
  }
  
  /**
   * Updates a Calendly event.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} calendlyId - The ID of the Calendly event.
   * @param {Object} calendlyRequest - The request payload for updating the Calendly event.
   * @returns {Function} A Redux thunk action that dispatches the update process.
   */
  function updateCalendly(projectId, calendlyId, calendlyRequest) {
    return (dispatch) => {
      dispatch(request());
      calendlyService.updateCalendly(projectId, calendlyId, calendlyRequest).then(
        (calendly) => {
          dispatch(success(calendly));
          dispatch(
            alertActions.success("Calendly successfully updated.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: calendlyConstants.UPDATE_CALENDLY_REQUEST };
    }
    function success(calendly) {
      return { type: calendlyConstants.UPDATE_CALENDLY_SUCCESS, calendly };
    }
    function failure(error) {
      return { type: calendlyConstants.UPDATE_CALENDLY_FAILURE, error };
    }
  }


  /**
   * Deletes a Calendly event.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} calendlyId - The ID of the Calendly event to be deleted.
   * @returns {function} A Redux thunk action that dispatches the appropriate actions based on the result of the delete operation.
   */
  function deleteCalendly(projectId, calendlyId) {

    return (dispatch) => {
      dispatch(request());
      calendlyService.removeCalendly(projectId, calendlyId).then(
        (calendly) => {
          dispatch(success(calendly));
          dispatch(
            alertActions.success("Calendly successfully removed")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: calendlyConstants.DELETE_CALENDLY_REQUEST };
    }
    function success(calendly) {
      return { type: calendlyConstants.DELETE_CALENDLY_SUCCESS, calendly };
    }
    function failure(error) {
      return { type: calendlyConstants.DELETE_CALENDLY_FAILURE, error };
    }
  }