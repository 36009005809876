import React from 'react'

/**
 * ButtonGroupLoader is a functional component that renders a skeleton loader
 * for a button group. It displays three skeleton boxes to indicate loading state.
 *
 * @returns {JSX.Element} A div containing three skeleton boxes.
 */
const ButtonGroupLoader = () => {
  return (
    <div className="skeleton-loader skeleton-button-group active">
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
        <div className="skeleton skeleton-box"></div>
    </div>
  )
}

export default ButtonGroupLoader