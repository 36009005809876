import React from 'react';

/**
 * CircularLoader component renders a small circular loading spinner.
 *
 * @component
 * @example
 * return (
 *   <CircularLoader />
 * )
 */
export const CircularLoader = () => {
  return (
    <div className="loader-sm">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
  );
};
