import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import FormGroupTitle from "_pages/Propelrr/components/Form/FormGroupTitle";
import InputForm from "_pages/Propelrr/components/Form/InputForm";
import { utilitiesService } from "_services";
import { influencerActions, projectActions } from "_actions";

export const AddInfluencerDrawer = ({
  open,
  toggle,
  isAddInfluencerDrawer,
  onHandleAddInfluencerDrawer,
  onHandleClose,
}) => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state);
  const [state, setState] = useState({
    name: "",
    email: "",
    contact: "",
    contacted: "",
    handlerName: "",
    handlerEmail: "",
    handlerContact: "",
    toggleTabState: 1,
    isModalOpen: false,
    isSubmitting: false,
    nameError: "",
    emailError: "",
  });

  const handleChange = (field, value) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleValidEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email) === false;
  };

  const handleAddLead = () => {
    const {
      name,
      email,
      contact,
      contacted,
      handlerName,
      handlerContact,
      handlerEmail,
    } = state;

    // Reset the error messages
    setState((prevState) => ({
      ...prevState,
      nameError: "",
      emailError: "",
    }));

    let isValid = true;

    // Validate name field
    if (name.trim() === "") {
      setState((prevState) => ({ ...prevState, nameError: "Name is required." }));
      isValid = false;
    }

    // Validate email field
    if (email.trim() === "") {
      setState((prevState) => ({ ...prevState, emailError: "Email is required." }));
      isValid = false;
    } else if (handleValidEmail(email)) {
      setState((prevState) => ({ ...prevState, emailError: "Invalid email format." }));
      isValid = false;
    }

    if (isValid) {
      const influencer = {
        name: name,
        email: utilitiesService.encrypt(email),
        contacted: contacted,
        contact: utilitiesService.encrypt(contact),
        handler_name: utilitiesService.encrypt(handlerName),
        handler_contact: utilitiesService.encrypt(handlerContact),
        handler_email: utilitiesService.encrypt(handlerEmail),
      };
      dispatch(influencerActions.createInfluencer(projects.project.data.id, influencer));
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
    }
  };

  const toggleTab = (index) => {
    setState((prevState) => ({ ...prevState, toggleTabState: index }));
  };

  const handleCloseModal = () => {
    setState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const activeClass = isAddInfluencerDrawer ? "active" : "";

  return (
    <React.Fragment>
      <div
        className={`drawer-overlay ${activeClass}`}
        onClick={onHandleAddInfluencerDrawer}
      ></div>
      <div className={`side-drawer-main ${activeClass}`}>
        <div className="side-drawer-wrapper">
          <div className="side-drawer-title">
            <h3>Add New Influencer</h3>
            <span
              className="side-drawer-close"
              onClick={onHandleAddInfluencerDrawer}
            >
              <i className="icon-lms icon-close"></i>
            </span>
          </div>
          <div className="side-drawer-tabs">
            <div className="side-drawer-tabs-wrapper">
              <div className="control-tabs-box">
                <div
                  className={
                    state.toggleTabState === 1 ? "tabs-btn active" : "tabs-btn"
                  }
                  onClick={() => toggleTab(1)}
                >
                  <p>Influencer Information</p>
                </div>
              </div>
              <div className="content-tabs-box">
                {state.isSubmitting ? (
                  <div className="loading-box">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      state.toggleTabState === 1
                        ? "tabs-content active"
                        : "tabs-content"
                    }
                  >
                    <div className="forms-tab">
                      <MDBRow>
                        <MDBCol xl="12" lg="12" className="form-group-box">
                          <FormGroupTitle label="Influencer" />
                          <div className="input-group-box full-box-">
                            <InputForm
                              required={true}
                              label="Name"
                              type="text"
                              id="name"
                              name="name"
                              onChange={(e) => handleChange("name", e.target.value)}
                              error={state.nameError}
                            />
                          </div>
                          <FormGroupTitle label="Contact Details" />
                          <div className="input-group-box full-box-">
                            <InputForm
                              required={true}
                              label="Email Address"
                              type="email"
                              id="email"
                              name="email"
                              onChange={(e) => handleChange("email", e.target.value)}
                              error={state.emailError}
                            />
                            <InputForm
                              label="Phone Number"
                              type="tel"
                              id="contact"
                              name="contact"
                              onChange={(e) => handleChange("contact", e.target.value)}
                            />
                            <InputForm
                              label="Date Contacted"
                              type="date"
                              id="contacted"
                              name="contacted"
                              onChange={(e) =>
                                handleChange("contacted", e.target.value)
                              }
                            />
                          </div>
                          <FormGroupTitle label="Handler Details" />
                          <div className="input-group-box full-box-">
                            <InputForm
                              label="Handler Name"
                              type="text"
                              id="handler_name"
                              name="handler_name"
                              onChange={(e) =>
                                handleChange("handlerName", e.target.value)
                              }
                            />
                            <InputForm
                              label="Email Address"
                              type="email"
                              id="handler_email"
                              name="handler_email"
                              onChange={(e) =>
                                handleChange("handlerEmail", e.target.value)
                              }
                            />
                            <InputForm
                              label="Phone Number"
                              type="tel"
                              id="handler_contact"
                              name="handler_contact"
                              onChange={(e) =>
                                handleChange("handlerContact", e.target.value)
                              }
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-form-btn-group">
                <MDBRow>
                  <MDBCol className="text-right">
                    <span
                      className="drawer-btn cancel-btn"
                      onClick={onHandleAddInfluencerDrawer}
                    >
                      Cancel
                    </span>
                    {state.isSubmitting ? (
                      <span
                        className="drawer-btn cancel-btn c-btn-disabled"
                        onClick={handleAddLead}
                        disabled={true}
                      >
                        Adding Influencer ...
                      </span>
                    ) : (
                      <span
                        className="drawer-btn save-btn"
                        onClick={handleAddLead}
                      >
                        Add Influencer
                      </span>
                    )}
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MDBContainer className="modal-container confirmation-modal-container">
        <MDBRow>
          <MDBCol>
            <MDBModal
              className="confirmation-modal"
              isOpen={state.isModalOpen}
              centered
              tabIndex="-1"
            >
              <MDBModalBody>
                <MDBRow className="form-group-row">
                  <MDBCol className="col-12">
                    <h3>Influencer Exist Already</h3>
                    <p>
                      Email: Test@gmail.com <br />
                      Name: Test Account
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className="text-center">
                    <button
                      className="modal-btn delete-btn-modal"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </React.Fragment>
  );
};