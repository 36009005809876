import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { leadActions } from '_actions';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button } from "blackbox-react";
import { FormValidation } from '_components/FormValidation';
import { Loader } from '_components/Loader';

const EditLeadNoteModal = ({ leadNote, modalOpen, toggleEditLeadNote, updateLeadNote, projectId, leadId, creating, handleGetLead }) => {
  // Initialize state with hooks
  const user = JSON.parse(localStorage.getItem('user'));
  const userEmail = user?.data?.email;

  const [formState, setFormState] = useState({
    leadNote: {
      name: leadNote?.name || '',
      description: leadNote?.description || '',
      sender: userEmail,
    },
    formErrors: { name: '' },
    formNameValid: false,
    formValid: false,
    submitted: false,
    class: 'disabled',
  });

  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      leadNote: {
        sender: userEmail,
        name: leadNote?.name || '',
        description: leadNote?.description || '',
      }
    }));
  }, [leadNote]);

  // Handle change in form fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => {
      const updatedLeadNote = { ...prevState.leadNote, [name]: value };
      return { 
        ...prevState,
        leadNote: updatedLeadNote,
        class: 'orange',
      };
    });

    if (name === 'name') {
      validateField(name, value);
    } else if (name === 'description') {
      setFormState(prevState => ({
        ...prevState,
        formValid: true,
      }));
    }
  };

  // Validate form field
  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formState.formErrors;
    let formNameValid = formState.formNameValid;

    let isAllWhitespace = (value.replace(/(?!^ +$)^.+$/gm, "") !== '');

    switch(fieldName) {
      case 'name':
        if (value === "" || isAllWhitespace) { 
          formNameValid = false;
          fieldValidationErrors.name = 'Please enter a form name.';
        } else {
          formNameValid = (value.length >= 0); 
          fieldValidationErrors.name = formNameValid ? '' : 'Please enter a form name.';
        }
        break;
      default:
        break;
    }

    setFormState(prevState => ({
      ...prevState,
      formErrors: fieldValidationErrors,
      formNameValid,
    }), validateForm);
  };

  // Validate entire form
  const validateForm = () => {
    setFormState(prevState => ({
      ...prevState,
      formValid: prevState.formNameValid,
    }));
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const { leadNote } = formState;
    const contentId = leadNote.id;

    setFormState(prevState => ({ ...prevState, submitted: true }), () => {
      if (formState.formValid) {
        updateLeadNote(projectId, leadId, 'note', contentId, leadNote);
        if (modalOpen) {
          toggleEditLeadNote();
        }
        setFormState({
          ...formState,
          formNameValid: false,
          formValid: false,
          submitted: false,
          formErrors: { name: '' },
          class: 'disabled',
        });
        handleGetLead();
      }
    });
  };

  // Error class for form validation
  const errorClass = (error) => (error.length === 0 ? 'is-passed' : 'is-failed');

  return (
    <MDBContainer>
      <form noValidate>
        <MDBModal isOpen={modalOpen} toggle={toggleEditLeadNote} id="create-form-modal" centered>
          <MDBModalHeader className="padding-left-18 padding-right-18 pb-3 pt-3" toggle={toggleEditLeadNote}>
            <p className="mb-0 font-size-12 font-weight-600 letter-spacing-1">EDIT NOTE</p>
          </MDBModalHeader>
          <MDBModalBody className="pt-2 padding-left-18 padding-right-18 padding-bottom-15">
            <MDBRow>
              <MDBCol className={`form-group m--sm ${formState.submitted && errorClass(formState.formErrors.name)}`}>
                <label className="mb-1 font-size-12 text-black">Title</label>
                <input
                  onChange={handleChange}
                  name="name"
                  type="text"
                  autoFocus={true}
                  value={formState.leadNote.name}
                  id="FormName"
                  placeholder="Enter note title here..."
                />
                <div className={`form-validation__container ${(!formState.formValid && formState.submitted) ? 'display-block' : 'display-none'}`}>
                  <FormValidation fieldName="name" formErrors={formState.formErrors} />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label className="mb-1 pt-2 font-size-12 text-black">Body</label>
                <textarea
                  onChange={handleChange}
                  name="description"
                  value={formState.leadNote.description}
                  id="textarea-description"
                  className="form-control font-size-12 color-none"
                  rows="7"
                  placeholder="Enter notes here..."
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="pt-2 pb-0">
              <MDBCol className="d-flex">
                <span
                  className="mb-0 font-color-orange my-auto text-cursor-pointer border-bottom-orange line-height-15 font-size-12"
                  onClick={toggleEditLeadNote}
                >
                  Cancel
                </span>
              </MDBCol>
              <MDBCol className="text-right" id="nextButton">
                <Button
                  flat="true"
                  type="submit"
                  size={-1}
                  background={formState.class}
                  disabled={formState.class === 'disabled' ? 'disabled' : ''}
                  onClick={handleSubmit}
                >
                  UPDATE NOTE
                  {creating && <Loader />}
                </Button>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </form>
    </MDBContainer>
  );
};

const mapState = (state) => {
  const { creating, forms } = state;
  return { creating, forms };
};

const actionCreators = {
  updateLeadNote: leadActions.updateLeadNote,
};

export default connect(mapState, actionCreators)(EditLeadNoteModal);
