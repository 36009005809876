import { deckConstants } from "_constants";
import { deckService } from "_services";
import { alertActions } from "./alert.actions";


export const deckActions = {
    getDecks,
    updateDeck,
    createDeck,
    deleteDeck,
    deleteDecks
  };

  /**
   * Fetches all decks for a specific project.
   * Dispatches the appropriate actions based on whether the request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project for which decks are being fetched.
   * @returns {function} - A function that dispatches actions for request, success, and failure.
  */
  function getDecks(projectId) {

    return (dispatch) => {
      dispatch(request());
      deckService.getDecks(projectId).then(
        (decks) => {
          dispatch(success(decks));
          // dispatch(
          //   alertActions.success("Successfully resend verification to your email")
          // );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: deckConstants.GET_ALL_DECK_REQUEST };
    }
    function success(decks) {
      return { type: deckConstants.GET_ALL_DECK_SUCCESS, decks };
    }
    function failure(error) {
      return { type: deckConstants.GET_ALL_DECK_FAILURE, error };
    }
  }


  /**
   * Creates a new deck for a specific project.
   * Dispatches the appropriate actions based on whether the request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project where the deck will be created.
   * @param {Object} deckRequest - The request object containing the details for the new deck.
   * @returns {function} - A function that dispatches actions for request, success, and failure.
   */
  function createDeck(projectId, deckRequest) {
    return (dispatch) => {
      dispatch(request());
      deckService.createDeck(projectId, deckRequest).then(
        (decks) => {
          dispatch(success(decks));
          dispatch(
            alertActions.success("New deck successfully added.")
          );
        },
        (error) => {
            console.log('error: ', error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: deckConstants.CREATE_DECK_REQUEST };
    }
    function success(decks) {
      return { type: deckConstants.CREATE_DECK_SUCCESS, decks };
    }
    function failure(error) {
      return { type: deckConstants.CREATE_DECK_FAILURE, error };
    }
  }
  
  /**
   * Updates an existing deck for a specific project.
   * Dispatches the appropriate actions based on whether the request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project for which the deck will be updated.
   * @param {Object} deckRequest - The request object containing the updated details for the deck.
   * @returns {function} - A function that dispatches actions for request, success, and failure.
   */
  function updateDeck(projectId, deckRequest) {
    return (dispatch) => {
      dispatch(request());
      deckService.updateDeck(projectId, deckRequest).then(
        (decks) => {
          dispatch(success(decks));
          dispatch(
            alertActions.success("Deck successfully updated.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: deckConstants.UPDATE_DECK_REQUEST };
    }
    function success(decks) {
      return { type: deckConstants.UPDATE_DECK_SUCCESS, decks };
    }
    function failure(error) {
      return { type: deckConstants.UPDATE_DECK_FAILURE, error };
    }
  }

  /**
   * Deletes a batch of decks for a specific project.
   * Dispatches the appropriate actions based on whether the request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project from which the decks will be deleted.
   * @param {Object} batchRequest - The request object containing details of the decks to be deleted.
   * @returns {function} - A function that dispatches actions for request, success, and failure.
   */
  function deleteDecks(projectId, batchRequest) {

    return (dispatch) => {
      dispatch(request());
      deckService.removeDecks(projectId, batchRequest).then(
        (decks) => {
          dispatch(success(decks));
          dispatch(
            alertActions.success("Batch remove decks successful.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: deckConstants.DELETE_DECK_REQUEST };
    }
    function success(decks) {
      return { type: deckConstants.DELETE_DECK_SUCCESS, decks };
    }
    function failure(error) {
      return { type: deckConstants.DELETE_DECK_FAILURE, error };
    }
  }

  /**
   * Deletes a single deck for a specific project.
   * Dispatches the appropriate actions based on whether the request succeeds or fails.
   * 
   * @param {string} projectId - The ID of the project from which the deck will be deleted.
   * @param {string} deckId - The ID of the deck to be deleted.
   * @returns {function} - A function that dispatches actions for request, success, and failure.
   */
  function deleteDeck(projectId, deckId) {

    return (dispatch) => {
      dispatch(request());
      deckService.removeDeck(projectId, deckId).then(
        (decks) => {
          dispatch(success(decks));
          dispatch(
            alertActions.success("Deck successfully removed.")
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    };
  
    function request() {
      return { type: deckConstants.DELETE_DECK_REQUEST };
    }
    function success(decks) {
      return { type: deckConstants.DELETE_DECK_SUCCESS, decks };
    }
    function failure(error) {
      return { type: deckConstants.DELETE_DECK_FAILURE, error };
    }
  }