import React from "react";
import TextLoader from "../../../../_components/Loader/TextLoader";
import { LeadResponsibleDropdown } from "_pages/Leads/components/LeadInfo/LeadResponsibleDropdown";

const LeadOwnerResponsible = ({props, leadDetails}) => {
  const { leadLoaded, membersLoaded } = props;

  const handleUpdateLeadResponsible = (selectedResponsible) => {
    const { match, updateLead } = props;

    const projectId = match.params.id;
    const leadId = match.params.leadId;

    const user = JSON.parse(localStorage.getItem("user"));
    const userEmail = user && user.data.email;

    updateLead(projectId, leadId, {
      responsible: selectedResponsible,
      sender: userEmail,
    });

  };

  return (
    <>
      <div className="col-box">
        <div className="avatar-text-box">
          <div className="text">
            <label>Generated From</label>
            {leadLoaded ? <span>{leadDetails.origin}</span> : <TextLoader />}
          </div>
        </div>
      </div>
      <div className="col-box">
        <div className="avatar-text-box">
          <div className="text">
            <label>ASSIGNED TO</label>
            {leadLoaded && membersLoaded ? (
              <LeadResponsibleDropdown
                lead={leadDetails}
                onHandleUpdateLeadResponsible={handleUpdateLeadResponsible}
              />
            ) : (
              <TextLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadOwnerResponsible;
