import React from "react";

/**
 * MainInfoLoader component renders a skeleton loader for the main information section.
 * It displays a series of skeleton boxes to indicate loading state.
 *
 * @component
 * @example
 * return (
 *   <MainInfoLoader />
 * )
 */
const MainInfoLoader = () => {
  return (
    <div className="skeleton-loader skeleton-main-info active">
        <div className="row">
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box short"></div>
          </div>
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box short"></div>
          </div>
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box medium"></div>
          </div>
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box long"></div>
          </div>
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box medium"></div>
          </div>
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box short"></div>
          </div>
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box short"></div>
          </div>
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box medium"></div>
          </div>
          <div className="col-md-6">
            <div className="skeleton skeleton-box label"></div>
            <div className="skeleton skeleton-box long"></div>
          </div>
        </div>
    </div>
  );
};

export default MainInfoLoader;
