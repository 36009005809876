import React, { useState, useEffect, useRef } from "react";
import { leadActions } from "_actions";
import { connect } from "react-redux";
import { utilitiesService } from "_services";

const FilterDropdown = (props) => {
  const {
    page,
    summaryLoaded,
    projectLoaded,
    project,
    originLoaded,
    origin,
    onApplyFilter,
  } = props;

  const filterRef = useRef(null);
  const projectId = props.projectId;

  const [filterToggled, setFilterToggled] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedOrigins, setCheckedOrigins] = useState([]);
  const [checkedAssigned, setCheckedAssigned] = useState([]);
  const [openFilterGroupIndex, setOpenFilterGroupIndex] = useState(-1);
  const [filterGroupHeight, setFilterGroupHeight] = useState("500px");

  useEffect(() => {
    let status = localStorage.getItem(`lead_view_status_${projectId}`);
    let assigned = localStorage.getItem(`lead_view_assigned_${projectId}`);
    let origin = localStorage.getItem(`lead_view_origin_${projectId}`);

    if (status === "null" || status === null) {
      status = undefined;
    } else {
      status = status
        .split(",")
        .map((a) => (a === "unassigned" ? a : parseInt(a)))
        .filter((a) => a && !isNaN(a) && a !== "isNaN" && a !== "")
    }

    if (origin === "null" || origin === null) {
      origin = undefined;
    } else {
      origin = origin.split(",")
      .filter((a) => a && !isNaN(a) && a !== "isNaN" && a !== "")
    }

    if (assigned === "null" || assigned === null) {
      assigned = undefined;
    } else {
      assigned = assigned
        .split(",")
        .map((a) => (a === "unassigned" ? a : parseInt(a)))
        .filter((a) => a && !isNaN(a) && a !== "isNaN" && a !== "")
    }

    setCheckedItems(status ? status : []);
    setCheckedOrigins(origin ? origin : []);
    setCheckedAssigned(assigned ? assigned : []);
  }, [projectId]);

  useEffect(() => {
    const handleDropdown = (value) => {
      if (!filterRef.current.contains(value.target)) {
        setFilterToggled(false);
      }
    };

    document.addEventListener("click", handleDropdown, true);
    return () => {
      document.removeEventListener("click", handleDropdown, true);
    };
  }, []);

  useEffect(() => {
    if (props.project?.data?.id !== props.project?.data?.id) {
      handleUnSelectAllFilter();
    }
  }, [props.project]);

  const handleFilterToggle = () => {
    setFilterToggled(!filterToggled);
  };

  const handleOpenFilter = () => {
    setFilterToggled(true);
  };

  const handleCloseFilter = () => {
    setFilterToggled(false);
  };

  const handleChange = (e, index) => {
    const { value, checked } = e.target;
    let newCheckedItems = [...checkedItems];
    if (checked) {
      newCheckedItems.push(parseInt(value));
    } else {
      newCheckedItems = newCheckedItems.filter((x) => x !== parseInt(value));
    }
    setCheckedItems(newCheckedItems);
    setOpenFilterGroupIndex(index);
  };

  const handleAssignedChange = (e) => {
    const { value, checked } = e.target;
    let parsed = value;

    if (parsed !== "unassigned" || !isNaN(parsed)) {
      parsed = parseInt(parsed);
    }

    let newCheckedAssigned = [...checkedAssigned, parsed];
    if (!checked) {
      newCheckedAssigned = newCheckedAssigned.filter((x) => x !== parsed);
    }

    setCheckedAssigned(newCheckedAssigned);
  };

  const handleOriginChange = (e) => {
    const { value, checked } = e.target;
    let newCheckedOrigins = [...checkedOrigins, value];
    if (!checked) {
      newCheckedOrigins = newCheckedOrigins.filter((x) => x !== value);
    }
    setCheckedOrigins(newCheckedOrigins);
  };

  const handleApplyFilter = () => {
    onApplyFilter(checkedItems, checkedOrigins, checkedAssigned);
    setFilterToggled(false);
  };

  const handleSelectAllFilter = (items) => {
    let newCheckedItems = [...checkedItems];
    items.forEach((i) => {
      if (!newCheckedItems.includes(i.id)) {
        newCheckedItems.push(i.id);
      }
    });
    setCheckedItems(newCheckedItems);
  };

  const handleUnSelectAllFilter = () => {
    setCheckedItems([]);
    setCheckedOrigins([]);
    setCheckedAssigned([]);
    onApplyFilter([], [], []);
    setFilterToggled(false);
  };

  const handleToggleFilterGroup = (index) => {
    setOpenFilterGroupIndex(Number(openFilterGroupIndex === index ? -1 : index));
  };

  let members = [];
  let owner = [];

  if (projectLoaded) {
    members = project.data.members;
    owner = [project.data.owner];
  }

  let origins = [];
  if (originLoaded) {
    origins = origin;
  }

  let data = [];
  let categories = [];
  let total = 0;
  let applyClass = "show";
  let selectClass = "hide";
  let items = [];

  if (summaryLoaded) {
    data = page.data;
    items = data.reduce(function (collection, elem) {
      let key = elem["category"];
      if (!categories.includes(key) && key !== "Custom") {
        categories.push(key);
      }

      total = total + elem["count"];
      (collection[key] = collection[key] || []).push(elem);
      return collection;
    }, []);

    applyClass =
      checkedItems.length > 0 ||
      checkedOrigins.length > 0 ||
      checkedAssigned.length > 0
        ? "show"
        : "hide";

    selectClass = checkedItems.length === data.length ? "hide" : "show";
  }

  return (
    <>
      <div className="filter-box leads-filter" ref={filterRef}>
        <div className="filter-btn" onClick={handleFilterToggle}>
          <i className="icon-lms icon-filter"></i>
          Filters
          <span>
            {checkedItems.filter(item => typeof item !== 'number' || !isNaN(item)).length +
              checkedOrigins.filter(item => typeof item !== 'number' || !isNaN(item)).length +
              checkedAssigned.filter(item => typeof item !== 'number' || !isNaN(item)).length}
          </span>
        </div>
        <div
          className={
            filterToggled ? "filter-dropdown active" : "filter-dropdown"
          }
        >
          <div className="filter-title">
            <p>Filters</p>
            <i
              className="icon-lms icon-close"
              onClick={handleCloseFilter}
            ></i>
          </div>

          <div className="filter-list">
            <div
              onClick={() => handleToggleFilterGroup(0)}
              className={
                openFilterGroupIndex === 0
                  ? "filter-group active"
                  : "filter-group"
              }
            >
              <div
                className="filter-name"
              >
                <span>Point of Conversion ({origins.length})</span>
                <i className="icon-lms icon-arrow-down"></i>
                <span
                  className={
                    checkedOrigins.length > 0 ? "count active" : "count"
                  }
                >
                  {checkedOrigins.length}
                </span>
              </div>
              <div
                className="filter-check-list"
                style={
                  openFilterGroupIndex === 0
                    ? { maxHeight: filterGroupHeight }
                    : { maxHeight: "0" }
                }
              >
                {origins.map((ori, index) => {
                  if (ori !== null) {
                    return (
                      <div key={index} className="check-col">
                        <div
                          key={`filter-dropdown-${index}`}
                          className="checkbox-group"
                        >
                          <input
                            type="checkbox"
                            checked={checkedOrigins.includes(ori)}
                            id={`origin-${index}`}
                            value={ori}
                            name={`origin-${index}`}
                            onChange={handleOriginChange}
                          />
                          <label htmlFor={`origin-${index}`}>{ori}</label>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>

            <div
                onClick={() => handleToggleFilterGroup(2)}
              className={
                openFilterGroupIndex === 2
                  ? "filter-group active"
                  : "filter-group"
              }
            >
              <div
                className="filter-name"
              >
                <span className={`filter-name`}>
                  Lead Managers ({members.length})
                </span>
                <i className="icon-lms icon-arrow-down"></i>
                <span
                  className={
                    checkedAssigned.some((item) =>
                      members.some((x) => parseInt(x.account.id) === item)
                    )
                      ? "count active"
                      : "count"
                  }
                >
                  {
                    checkedAssigned.filter((item) =>
                      members.some((x) => parseInt(x.account.id) === item)
                    ).length
                  }
                </span>
              </div>
              <div
                className="filter-check-list"
                style={
                  openFilterGroupIndex === 2
                    ? { maxHeight: filterGroupHeight }
                    : { maxHeight: "0" }
                }
              >
                {members.map((member, index) => {
                  let id = parseInt(member.account.id);

                  let fname = utilitiesService.decrypt(
                    member.account.first_name
                  );
                  let lname = utilitiesService.decrypt(
                    member.account.last_name
                  );
                  return (
                    <div key={index} className="check-col">
                      <div
                        key={`filter-dropdown-${index}`}
                        className="checkbox-group"
                      >
                        <input
                          type="checkbox"
                          checked={checkedAssigned.includes(id)}
                          id={`member-${id}`}
                          value={id}
                          name={`member-${id}`}
                          onChange={handleAssignedChange}
                        />
                        <label htmlFor={`member-${id}`}>
                          {fname} {lname}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {categories.map((value, index) => {
              let count = index + 3;
              return (
                <div
                    onClick={() => handleToggleFilterGroup(count)}
                  className={
                    openFilterGroupIndex === count &&
                    items[value].some((item) =>
                      checkedItems.includes(item.id)
                    )
                      ? "filter-group active"
                      : "filter-group"
                  }
                  key={index}
                >
                  <div
                    className="filter-name"
                  >
                    <span className={`filter-name`}>
                      {value} ({items[value].length})
                    </span>
                    <i className="icon-lms icon-arrow-down"></i>
                    <span
                      className={
                        checkedItems.length > 0 &&
                        checkedItems.some((item) =>
                          items[value].some((x) => x.id === item)
                        )
                          ? "count active"
                          : "count"
                      }
                    >
                      {
                        checkedItems.filter((item) =>
                          items[value].some((x) => x.id === item)
                        ).length
                      }
                    </span>
                  </div>
                  <div
                    className="filter-check-list"
                    style={
                      openFilterGroupIndex === count
                        ? { maxHeight: filterGroupHeight }
                        : { maxHeight: "0" }
                    }
                  >
                    {items[value].map((item, index) => {
                      let id = item.id;
                      return (
                        <div
                          className="check-col"
                          key={`filter-dropdown-${index}`}
                        >
                          <div className="checkbox-group">
                            <input
                              type="checkbox"
                              checked={checkedItems.includes(id)}
                              id={`status-${id}`}
                              value={item.id}
                              name={item.label}
                              onChange={(e) => handleChange(e, count)}
                            />
                            <label htmlFor={`status-${id}`}>
                              {item.label}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="filter-control-box">
            <span
              className={`btn-reset`}
              onClick={handleUnSelectAllFilter}
            >
              Clear Filter
            </span>
            <span
              className={`btn-apply`}
              onClick={handleApplyFilter}
            >
              Apply Filter
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => {
  const { projectLoaded, loaded, project } = state.projects;
  const { page, origin, originLoaded, summaryLoaded } = state.summary;
  return {
    page,
    summaryLoaded,
    projectLoaded,
    loaded,
    project,
    originLoaded,
    origin,
  };
};

const actionCreators = {
  getLeadSummary: leadActions.getLeadSummary,
  getOrigin: leadActions.getOrigin,
};

const connectedViewFilterDropdown = connect(
  mapState,
  actionCreators
)(FilterDropdown);
export { connectedViewFilterDropdown as FilterDropdown };