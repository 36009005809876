import React, { useState, useEffect, useCallback, use } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "_actions";
import { httpManagerService as httpManager } from "_services";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import { Button } from "blackbox-react";
import { FormValidation } from "_components/FormValidation";

import logo from "_assets/images/lms-logo.png";
import loginBG from "_assets/images/login-bg.png";
import { environment } from "../../_environments";
import { leadService } from "_services";
import { alertActions } from "../../_actions/";

const Login = () => {
  const [checkingLogInRedirect, setIsCheckingLogInRedirect] = useState(true);
  const dispatch = useDispatch();
  const { loggingIn, error } = useSelector((state) => ({
    loggingIn: state.authentication.loggingIn,
    error: state.users.error,
  }));
  const history = useHistory();
  const isLoggedIn = httpManager.isLoginWithUserCredentials();

  // form state information
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    submitted: false,
    formErrors: {
      email: "Please enter your login email.",
      password: "Please enter your login password.",
      login: "",
    },
    emailValid: false,
    passwordValid: false,
    formValid: false,
    passwordShown: false,
    redirectTo: null,
  });

  // validate the each field, then update form state
  const validateField = useCallback(
    (fieldName, value) => {
      let fieldValidationErrors = { ...formState.formErrors };
      let emailValid = formState.emailValid;
      let passwordValid = formState.passwordValid;
      let isAllWhitespace = value.replace(/(?!^ +$)^.+$/gm, "") !== "";

      switch (fieldName) {
        case "password":
          if (value === "" || isAllWhitespace || value.length < 6) {
            passwordValid = false;
            fieldValidationErrors.password =
              "Please enter your login password.";
          } else {
            passwordValid = value.length >= 6;
            fieldValidationErrors.password = passwordValid ? "" : "";
          }
          break;

        case "email":
          if (value === "" || isAllWhitespace) {
            emailValid = false;
            fieldValidationErrors.email = "Please enter your login email.";
          } else {
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid
              ? ""
              : "Please enter your email address in valid format: sample@domain.com";
          }
          break;
        default:
          break;
      }

      setFormState((prevState) => {
        const formValid = emailValid && passwordValid;
        return {
          ...prevState,
          formErrors: fieldValidationErrors,
          emailValid,
          passwordValid,
          formValid,
        };
      });
    },
    [formState]
  ); // Dependency on formState

  // function to handle in put change, will call form state trigger
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Trigger validation after the state update
    validateField(name, value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setFormState((prevState) => ({
      ...prevState,
      submitted: true,
    }));

    // Validate email and password before submitting
    validateField("email", formState.email);
    validateField("password", formState.password);

    // If form is valid, proceed to submit
    if (formState.formValid) {
      localStorage.setItem("isLoggedIn", "true");
      dispatch(
        userActions.login(
          formState.email,
          formState.password,
          formState.redirectTo
        )
      );
    }
  };

  const togglePassword = () => {
    setFormState((prevState) => ({
      ...prevState,
      passwordShown: !prevState.passwordShown,
    }));
  };

  const handleForgotPassword = () => {
    dispatch(userActions.clearError());
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get("redirect");
    setFormState((prevState) => ({
      ...prevState,
      redirectTo: redirect || null,
    }));

    // not logged in or no user data, set the local storage data then proceed with log in
    const user = httpManager.getUser();
    const isValidToken = httpManager.hasValidAccessToken();
    if (!isLoggedIn || !user || !isValidToken) {
      localStorage.setItem("isLoggedIn", "false");
      userActions.logout();
      setIsCheckingLogInRedirect(false);
      return;
    }

    // logged in but no redirect prompt, redirect to dashboard
    if (!redirect) {
      history.replace("/login");
      history.push("/profile-settings");
      return;
    }

    const { projects, email } = user;
    let project = {};
    if (projects) {
      if (projects.shared.length > 0) {
        project = projects.shared[0];
      }

      if (projects.owned.length > 0) {
        project = projects.owned[0];
      }

      let module = "leads";
      if (project.influencer_enabled) {
        module = "influencers";
      }
      const moduleRemovedLastChar = module.endsWith("s")
        ? module.slice(0, -1)
        : module;
      let leadPath = redirect.split("/");
      const notAssignedLeadErrorMessage = `You are not assigned as Lead Manager for this ${moduleRemovedLastChar}`;
      leadService.find(leadPath[2], leadPath[4]).then(
        (lead) => {
          // console.log("authenticated", lead.data);
          if (lead.data.owner.email === email) {
            //  console.log("owner redirected..");
            history.push(redirect);
          } else if (lead.data.responsible) {
            if (lead.data.responsible.email === email) {
              //   console.log("responsible redirected..");
              history.push(redirect);
            } else {
              dispatch(
                alertActions.error(
                  {
                    error_code: "403",
                    error_message: notAssignedLeadErrorMessage,
                  },
                  6000
                )
              );
              history.push(`/project/${project.id}/${module}`);
            }
          } else {
            dispatch(
              alertActions.error(
                {
                  error_code: "403",
                  error_message: notAssignedLeadErrorMessage,
                },
                6000
              )
            );
            history.push(`/project/${project.id}/${module}`).then();
          }
        },
        (error) => {
          const { response } = error;
          const { data } = response;
        }
      );
    } else {
      history.replace("/login");
      setIsCheckingLogInRedirect(false);
      if (project.id) {
        history.push(`/project/${project.id}/${module}`);
      } else {
        history.push("/profile-settings");
      }
    }
  }, []);

  // Automatically validate fields when they change
  useEffect(() => {
    validateField("email", formState.email);
    validateField("password", formState.password);
  }, [formState.email, formState.password]);

  const { email, password, submitted, formValid, passwordShown, formErrors } =
    formState;
  formErrors.login = error ? error : "";

  const errorClass = (error) => {
    return error.length === 0 ? "is-passed" : "is-failed";
  };

  if(checkingLogInRedirect) return <></>;
  return (
    <MDBContainer fluid className="login-background">
      <div className="login-bg">
        <img src={loginBG} alt="Login Background" />
      </div>
      <div className="login-panel">
        <MDBRow>
          <MDBCol className="text-center">
            <img src={logo} alt="Propelrr Platform" className="login-logo" />
            <h1>
              Lead Management System
              {environment.apiUrl === "https://lms-api-staging.propelrr.io"
                ? " - Staging"
                : ""}
            </h1>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <form className="login-form" onSubmit={handleLogin} noValidate>
              <MDBRow>
                <MDBCol className="text-center">
                  <div className="form-inner-heading">
                    <h2>Login with your given email</h2>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div
                    className={`form-group ${
                      submitted && errorClass(formState.formErrors.email)
                    }`}
                  >
                    <label className="l-main">Email</label>
                    <input
                      className={
                        formState.class
                          ? "email-input"
                          : "input-error-placeholder"
                      }
                      name="email"
                      placeholder="yourgivenemail@company.com"
                      type="email"
                      required={true}
                      value={email}
                      autoFocus={true}
                      onChange={handleChange}
                    />
                    <div
                      className={`form-validation__container ${
                        !formValid && submitted
                          ? "display-block"
                          : "display-none"
                      }`}
                    >
                      <FormValidation
                        fieldName="email"
                        formErrors={formState.formErrors}
                      />
                    </div>
                  </div>
                  <div
                    className={`form-group ${
                      submitted && errorClass(formState.formErrors.password)
                    }`}
                  >
                    <div className="label-f-password">
                      <label className="l-main">Password</label>
                      <Link
                        to="/forgot-password"
                        onClick={handleForgotPassword}
                      >
                        <span className="text-login-here-blue">
                          {" "}
                          Forgot Password?
                        </span>
                      </Link>
                    </div>
                    <div className="password-group">
                      <input
                        className={
                          formState.class
                            ? "password-input-login"
                            : "input-error-placeholder"
                        }
                        name="password"
                        placeholder="Enter your password"
                        type={passwordShown ? "text" : "password"}
                        required={true}
                        value={password}
                        onChange={handleChange}
                      />
                      <i
                        onClick={togglePassword}
                        className={
                          passwordShown
                            ? "icon-lms icon-visibility-on"
                            : "icon-lms icon-visibility-off"
                        }
                      ></i>
                    </div>
                    <div
                      className={`form-validation__container ${
                        !formValid && submitted
                          ? "display-block"
                          : "display-none"
                      }`}
                    >
                      <FormValidation
                        fieldName="password"
                        formErrors={formState.formErrors}
                      />
                    </div>
                    <div
                      className={`form-validation__container ${
                        error ? "display-block" : "display-none"
                      }`}
                    >
                      <FormValidation
                        fieldName="login"
                        formErrors={formErrors}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <Button
                      type="submit"
                      className={
                        loggingIn || checkingLogInRedirect
                          ? "c-btn c-btn-lg c-btn-disabled"
                          : "c-btn c-btn-lg c-btn-blue1"
                      }
                    >
                      Login
                    </Button>
                    {(loggingIn || checkingLogInRedirect) && (
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
};

export { Login };
