import { httpManagerService as httpManager } from '_services';

export const publicService = {
    googleConnectOauth,
    googleConnectAuthorize,
    updateConnection,
    disconnectGmail,
    generateForm,
    addLead
};

/**
 * Initiates OAuth connection for Google integration.
 * @param {number} project_id - The project ID.
 * @param {number} form_id - The form ID.
 * @param {string} redirect - The redirect URL after authentication.
 * @returns {Promise<Object>} A promise resolving to the OAuth connection response.
 */
function googleConnectOauth(project_id, form_id, redirect) {
    return httpManager.getHttpWithRequestingToken(`/public/project/${project_id}/form/${form_id}/connection/oauth?redirect=${redirect}`);
}

/**
 * Authorizes the Google connection for a project form.
 * @param {number} project_id - The project ID.
 * @param {number} form_id - The form ID.
 * @param {string} state - The state parameter for authorization.
 * @returns {Promise<Object>} A promise resolving to the authorization response.
 */
function googleConnectAuthorize(project_id, form_id, state) {
    return httpManager.getHttpWithRequestingToken(`/public/project/${project_id}/form/${form_id}/connection/authorize${state}`);
}

/**
 * Disconnects an existing Gmail connection.
 * @param {number} project_id - The project ID.
 * @param {number} form_id - The form ID.
 * @param {number} connection_id - The connection ID.
 * @returns {Promise<Object>} A promise resolving to the disconnection response.
 */
function disconnectGmail(project_id, form_id, connection_id) {
    return httpManager.deleteJsonWithRequestingToken(`/public/project/${project_id}/form/${form_id}/connection/${connection_id}`, connection_id);
}

/**
 * Updates an existing connection for a project form.
 * @param {number} project_id - The project ID.
 * @param {number} form_id - The form ID.
 * @param {Object} connection - The updated connection data.
 * @returns {Promise<Object>} A promise resolving to the update response.
 */
function updateConnection(project_id, form_id, connection) {
    return httpManager.putJsonOnce(`/public/project/${project_id}/form/${form_id}/connection`, connection);
}

/**
 * Generates a form using an access key.
 * @param {string} access_key - The access key for form generation.
 * @returns {Promise<Object>} A promise resolving to the generated form data.
 */
function generateForm(access_key) {
    return httpManager.getHttpWithRequestingToken(`/public/form/generate?accesskey=${access_key}`);
}

/**
 * Adds a lead using an access key.
 * @param {string} access_key - The access key for lead creation.
 * @returns {Promise<Object>} A promise resolving to the lead creation response.
 */
function addLead(access_key) {
    return httpManager.postJsonOnce(`/public/form/generate?accesskey=${access_key}`);
}
