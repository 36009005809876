import Axios from "axios-observable";
import decode from "jwt-decode";

import { environment } from "_environments";

const API_URL = environment.apiUrl;
const API_CLIENT_ID = environment.clientId;
const API_CLIENT_SECRET = environment.clientSecret;
const API_GENERIC_USERNAME = environment.genericUsername;
const API_GENERIC_PASSWORD = environment.genericPassword;

export const httpManagerService = {
  getBasicAuthorization,
  getBasicHttpOptions,
  getJsonBearerHttpOptions,
  getMultiPartBearerHttpOptions,
  getJsonBearerHttpOptionsWithBody,

  storeUser,
  getUser,
  getEmail,
  getPassword,
  isLoginWithUserCredentials,
  isAuthenticated,
  hasValidAccessToken,
  setUserCredentials,
  removeCredentials,

  setToken,
  setRefreshToken,
  getToken,
  getRefreshToken,
  removeTokens,
  isTokenExpired,

  requestAccessToken,
  refreshAccessToken,

  getHttpOnce,
  fetchHttpOnce,
  getObservableHttp,
  getHttpWithRequestingToken,
  getObservableHttpWithRequestingToken,
  getHttpWithRefreshingToken,
  getObservableHttpWithRefreshingToken,

  postJsonOnce,
  postFormDataOnce,
  postObservableJsonOnce,
  postJsonDataWithRequestingToken,
  postObservableJsonDataWithRequestingToken,
  postJsonDataWithRefreshingToken,
  postObservableJsonDataWithRefreshingToken,

  putJsonOnce,
  putFormDataOnce,
  putHttpOnce,
  putObservableJsonOnce,
  putObservableJsonDataWithRefreshingToken,
  putJsonDataWithRequestingToken,
  putJsonDataWithRefreshingToken,

  deleteHTTPOnce,
  deleteObservableHttp,
  deleteHttpWithRequestingToken,
  deleteObservableHttpWithRequestingToken,
  deleteHttpWithRefreshingToken,
  deleteObservableHttpWithRefreshingToken,
  deleteJsonOnce,
  deleteJsonWithRequestingToken,
  deleteJsonWithRefreshingToken,

  isCurrentUserLeadManager,
  isCurrentUserProjectOwner,
  setProjectData,
  getProjectData
};

/**
 * Generates a Basic Authorization header value by encoding the API client ID and secret.
 *
 * @returns {string} The Base64 encoded string of the API client ID and secret in the format 'clientId:clientSecret'.
 */
function getBasicAuthorization() {
  return btoa(API_CLIENT_ID + ":" + API_CLIENT_SECRET);
}

/**
 * Generates the basic HTTP options for making requests.
 *
 * @returns {Object} An object containing the headers and credentials for the HTTP request.
 * @returns {Object.headers} The headers for the HTTP request.
 * @returns {string} headers.Content-Type The content type of the request, set to 'application/x-www-form-urlencoded'.
 * @returns {string} headers.Authorization The authorization header, containing a Basic authorization token.
 * @returns {boolean} withCredentials Indicates whether or not cross-site Access-Control requests should be made using credentials.
 */
function getBasicHttpOptions() {
  return {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${httpManagerService.getBasicAuthorization()}`,
    },
    withCredentials: true,
  };
}

/**
 * Generates HTTP options for a JSON request with Bearer token authorization.
 *
 * @returns {Object} An object containing HTTP headers and credentials settings.
 * @returns {Object.headers} HTTP headers for the request.
 * @returns {string} headers.Content-Type - The content type of the request, set to 'application/json'.
 * @returns {string} headers.Authorization - The authorization header containing the Bearer token.
 * @returns {boolean} withCredentials - Indicates whether or not cross-site Access-Control requests should be made using credentials.
 */
function getJsonBearerHttpOptions() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${httpManagerService.getToken()}`,
    },
    withCredentials: true,
  };
}

/**
 * Generates HTTP options for multipart/form-data requests with Bearer token authorization.
 *
 * @returns {Object} An object containing HTTP headers and credentials options.
 * @returns {Object.headers} HTTP headers for the request.
 * @returns {string} Object.headers.Content-Type - The content type of the request, set to 'multipart/form-data'.
 * @returns {string} Object.headers.Authorization - The authorization header containing the Bearer token.
 * @returns {boolean} Object.withCredentials - Indicates whether or not cross-site Access-Control requests should be made using credentials.
 */
function getMultiPartBearerHttpOptions() {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${httpManagerService.getToken()}`,
    },
    withCredentials: true,
  };
}

/**
 * Generates HTTP options for a JSON request with a Bearer token and a request body.
 *
 * @param {Object} body - The request body to be sent with the HTTP request.
 * @returns {Object} The HTTP options including headers, body, and credentials.
 */
function getJsonBearerHttpOptionsWithBody(body) {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${httpManagerService.getToken()}`,
    },
    body: body,
    withCredentials: true,
  };
}

/**
 * Stores the user object in the session storage.
 *
 * @param {Object} user - The user object to be stored.
 */
function storeUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

/**
 * Retrieves the user data from session storage.
 *
 * @returns {Object|null} The user data if available, otherwise null.
 */
function getUser() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    return user.data;
  }
  return null;
}

/**
 * Checks if the current user is the owner of the current project
 * @returns {boolean} True if the user owns the project, false otherwise
 * @throws {ReferenceError} If user or projectId variables are not defined in scope
 * @depends {user} Global or scope variable containing user data with projects.owned array
 * @depends {projectId} Global or scope variable containing target project ID
 */
function isCurrentUserProjectOwner (projectId) {
  const user = getUser();
  let admin = false;

  if (user?.projects?.owned.length > 0) {
    admin = user.projects.owned.some(
      (e) => String(e.id) === String(projectId)
    );
  }

  return admin;
};

/**
 * Checks if the current authenticated user is the manager responsible for a given lead
 * @param {Object} lead - The lead object to check
 * @param {Object} [lead.responsible] - The responsible manager object for the lead
 * @param {string} [lead.responsible.email] - Email of the responsible manager
 * @returns {boolean} True if current user is the lead manager, false otherwise
 */
function isCurrentUserLeadManager(lead, projectId) {
  const user = getUser();

  const isUserAdmin = () => {
    return user.admin; // TODO: always false while admin status on profile is not yet implemented
  };

  const isProjectOwner = isCurrentUserProjectOwner(projectId);
  const isLeadManager = lead && user &&  (lead.responsible && lead.responsible.email === user.email);
  return isLeadManager || isProjectOwner || isUserAdmin();
}

/**
 * Sets project data in local storage for a specific project
 * @param {string|number} projectId - The unique identifier for the project
 * @param {Object} projectData - The project data to be stored. If not provided, empty object string will be stored
 * @returns {void}
 */
function setProjectData(projectId, projectData){
  localStorage.setItem(`project-data-${projectId}`, JSON.stringify(projectData || "{}"));
}

/**
 * Retrieves project data from localStorage for a specific project
 * @param {string|number} projectId - The unique identifier of the project
 * @returns {Object|null} The parsed project data object from localStorage, or null if not found
 */
function getProjectData(projectId){
  return JSON.parse(localStorage.getItem(`project-data-${projectId}`) || "{}");
}

/**
 * Retrieves the email address stored in the session storage.
 *
 * @returns {string|null} The email address if it exists, otherwise null.
 */
function getEmail() {
  return localStorage.getItem("email");
}

/**
 * Retrieves the password from the session storage.
 *
 * @returns {string|null} The password stored in the session storage, or null if not found.
 */
function getPassword() {
  return localStorage.getItem("password");
}

/**
 * Checks if the user is logged in with credentials.
 *
 * This function verifies if both the password and email are present
 * in the httpManagerService, indicating that the user is logged in
 * with their credentials.
 *
 * @returns {boolean} True if both password and email are not null, otherwise false.
 */
function isLoginWithUserCredentials() {
  return (
    httpManagerService.getPassword() != null &&
    httpManagerService.getEmail() != null
  );
}

/**
 * Checks if the user is authenticated.
 *
 * This function retrieves the token from the httpManagerService, checks if the token is expired,
 * and verifies if the user is logged in with user credentials.
 *
 * @returns {boolean} True if the user is authenticated, false otherwise.
 */
function isAuthenticated() {
  const token = httpManagerService.getToken();
  const helper = httpManagerService.isTokenExpired(token);
  return helper && httpManagerService.isLoginWithUserCredentials();
}

/**
 * Checks if the user has an existing access token and token is not expired
 * This will trigger auto sign out whenever the access got expired
 * 
 * @returns {boolean} - Returns `true` if the token is not expired and the user is logged in with credentials, otherwise `false`.
 */
function hasValidAccessToken() {
  const token = httpManagerService.getToken();
  const notExpired = httpManagerService.isTokenExpired(token);
  return notExpired && httpManagerService.isLoginWithUserCredentials();
}

/**
 * Stores user credentials in session storage.
 *
 * @param {string} email - The user's email address.
 * @param {string} password - The user's password.
 */
function setUserCredentials(email, password) {
  localStorage.setItem("email", email);
  localStorage.setItem("password", password);
}

/**
 * Removes the user's email and password from the session storage.
 * This function clears the stored credentials to ensure they are not
 * accessible after the user logs out or the session ends.
 */
function removeCredentials() {
  localStorage.removeItem("email");
  localStorage.removeItem("password");
}

/**
 * Stores the provided access token in the session storage.
 *
 * @param {string} access_token - The access token to be stored.
 */
function setToken(access_token) {
  localStorage.setItem("access_token", access_token);
}

/**
 * Stores the provided access token in the session storage as a refresh token.
 *
 * @param {string} access_token - The access token to be stored as a refresh token.
 */
function setRefreshToken(access_token) {
  sessionStorage.setItem("refresh_token", access_token);
}

/**
 * Retrieves the access token from the session storage.
 *
 * @returns {string|null} The access token if it exists, otherwise null.
 */
function getToken() {
  return localStorage.getItem("access_token");
}

/**
 * Retrieves the refresh token from the session storage.
 *
 * @returns {string|null} The refresh token if it exists, otherwise null.
 */
function getRefreshToken() {
  return sessionStorage.getItem("refresh_token");
}

/**
 * Removes the access and refresh tokens from the session storage.
 * This function clears the user's authentication tokens, effectively logging them out.
 */
function removeTokens() {
  localStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
}

/**
 * Checks if a given token is expired.
 *
 * @param {string} token - The token to check for expiration.
 * @returns {boolean} - Returns true if the token is expired, otherwise false.
 */
function isTokenExpired(token) {
  try {
    const date = new Date(0);
    const decoded = decode(token);
    date.setUTCSeconds(decoded.exp);
    return date.valueOf() > new Date().valueOf();
  } catch (err) {
    return false;
  }
}

/* Request OAUTH Token */
/**
 * Requests an access token from the OAuth server.
 *
 * This function constructs a request with either user credentials or generic API credentials,
 * and sends it to the OAuth server to obtain an access token and a refresh token.
 *
 * @returns {Promise<Object>} A promise that resolves with the response data containing the access token and refresh token, or rejects with an error.
 */
function requestAccessToken() {
  return new Promise((resolve, reject) => {
    const data = new URLSearchParams();
    const username = httpManagerService.isLoginWithUserCredentials()
      ? httpManagerService.getEmail()
      : API_GENERIC_USERNAME;

    const password = httpManagerService.isLoginWithUserCredentials()
      ? httpManagerService.getPassword()
      : API_GENERIC_PASSWORD;

    data.append("username", username);
    data.append("password", password);
    data.append("grant_type", "password");

    Axios.post(
      API_URL + "/oauth/token",
      data.toString(),
      httpManagerService.getBasicHttpOptions()
    ).subscribe(
      (response) => {
        httpManagerService.setToken(response.data["access_token"]);
        httpManagerService.setRefreshToken(response.data["refresh_token"]);

        resolve(response.data);
      },
      (error) => reject(error)
    );
  });
}

/* Refresh OAUTH Token */
/**
 * Refreshes the access token using the refresh token.
 *
 * This function sends a POST request to the OAuth token endpoint with the refresh token
 * and grant type to obtain a new access token. If successful, it updates the stored tokens.
 * If the request fails, it removes the stored credentials and tokens.
 *
 * @returns {Promise<Object>} A promise that resolves with the response containing the new tokens,
 * or rejects with an error if the request fails.
 */
function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    const data = new URLSearchParams();
    data.append("refresh_token", httpManagerService.getRefreshToken());
    data.append("grant_type", "refresh_token");

    Axios.post(
      API_URL + "/oauth/token",
      data.toString(),
      httpManagerService.getBasicHttpOptions()
    ).subscribe(
      (response) => {
        httpManagerService.setToken(response["access_token"]);
        // httpManagerService.setTokenType(response['token_type']);
        httpManagerService.setRefreshToken(response["refresh_token"]);
        resolve(response);
      },
      (error) => {
        httpManagerService.removeCredentials();
        httpManagerService.removeTokens();
        reject(error);
      }
    );
  });
}

/**
 * Makes an HTTP GET request to the specified URL and returns a promise.
 *
 * @param {string} url - The URL to send the GET request to.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
function getHttpOnce(url) {
  return new Promise((resolve, reject) => {
    Axios.get(
      API_URL + url,
      httpManagerService.getJsonBearerHttpOptions()
    ).subscribe(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Fetches data from the specified URL with an authorization header.
 *
 * @param {string} url - The endpoint URL to fetch data from.
 * @returns {Promise<Response>} - A promise that resolves to the response of the fetch request.
 */
function fetchHttpOnce(url) {
  return fetch(API_URL + url, {
    headers: { Authorization: `Bearer ${httpManagerService.getToken()}` },
  });
}

/**
 * Makes an HTTP GET request to the specified URL and returns an observable.
 *
 * @param {string} url - The endpoint URL to make the GET request to.
 * @returns {Observable} - An observable that emits the HTTP response.
 */
function getObservableHttp(url) {
  return Axios.get(
    API_URL + url,
    httpManagerService.getJsonBearerHttpOptions()
  );
}

/**
 * Fetches an HTTP resource after requesting an access token.
 *
 * @param {string} url - The URL of the resource to fetch.
 * @returns {Promise} A promise that resolves with the HTTP response.
 */
function getHttpWithRequestingToken(url) {
  return httpManagerService.requestAccessToken().then(() => {
    return httpManagerService.getHttpOnce(url);
  });
}

/**
 * Retrieves an observable HTTP response after requesting an access token.
 *
 * @param {string} url - The URL to send the HTTP request to.
 * @returns {Promise} A promise that resolves to the observable HTTP response.
 */
function getObservableHttpWithRequestingToken(url) {
  httpManagerService.requestAccessToken().then(() => {
    return httpManagerService.getObservableHttp(url);
  });
}

/**
 * Makes an HTTP request to the specified URL, refreshing the access token if necessary.
 *
 * @param {string} url - The URL to make the HTTP request to.
 * @returns {Promise<any>} A promise that resolves with the HTTP response.
 */
function getHttpWithRefreshingToken(url) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.getHttpOnce(url);
    });
  } else {
    return httpManagerService.getHttpOnce(url);
  }
}

/**
 * Fetches an observable HTTP response from the given URL, refreshing the access token if necessary.
 *
 * @param {string} url - The URL to fetch the HTTP response from.
 * @returns {Promise} A promise that resolves to the HTTP response observable.
 */
function getObservableHttpWithRefreshingToken(url) {
  if (!httpManagerService.isAuthenticated()) {
    httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.getObservableHttp(url);
    });
  } else {
    return httpManagerService.getObservableHttp(url);
  }
}

/**
 * Sends a POST request with a JSON payload to the specified URL.
 *
 * @param {string} url - The endpoint URL to which the request is sent.
 * @param {Object} object - The JSON object to be sent as the request payload.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
function postJsonOnce(url, object) {
  return new Promise((resolve, reject) => {
    let params;
    if (object) {
      params = JSON.stringify(object);
    }
    Axios.post(
      API_URL + url,
      params,
      httpManagerService.getJsonBearerHttpOptions()
    ).subscribe(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Sends a POST request with form data to the specified URL.
 *
 * @param {string} url - The endpoint URL to which the form data will be sent.
 * @param {FormData} formData - The form data to be sent in the POST request.
 * @returns {Promise} A promise that resolves with the response data or rejects with an error.
 */
function postFormDataOnce(url, formData) {
  return new Promise((resolve, reject) => {
    Axios.post(
      API_URL + url,
      formData,
      httpManagerService.getMultiPartBearerHttpOptions()
    ).subscribe(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Sends a POST request with JSON payload to the specified URL.
 *
 * @param {string} url - The endpoint URL to which the request is sent.
 * @param {Object} object - The object to be sent as JSON payload.
 * @returns {Promise} - A Promise that resolves to the response of the POST request.
 */
function postObservableJsonOnce(url, object) {
  let params;
  if (object) {
    params = JSON.stringify([...object.entries()]);
  }

  return Axios.post(
    API_URL + url,
    params,
    httpManagerService.getJsonBearerHttpOptions()
  );
}

/**
 * Sends a POST request with JSON data to the specified URL after requesting an access token.
 *
 * @param {string} url - The URL to which the JSON data should be posted.
 * @param {Object} object - The JSON object to be sent in the POST request.
 * @returns {Promise} A promise that resolves when the POST request is completed.
 */
function postJsonDataWithRequestingToken(url, object) {
  return httpManagerService.requestAccessToken().then(() => {
    return httpManagerService.postJsonOnce(url, object);
  });
}

/**
 * Sends a POST request with JSON data to the specified URL after requesting an access token.
 *
 * @param {string} url - The URL to which the POST request is sent.
 * @param {Object} object - The JSON object to be sent in the POST request.
 * @returns {Promise} A promise that resolves when the POST request is completed.
 */
function postObservableJsonDataWithRequestingToken(url, object) {
  httpManagerService.requestAccessToken().then(() => {
    return httpManagerService.postObservableJsonOnce(url, object);
  });
}

/**
 * Sends a POST request with JSON data, refreshing the access token if needed.
 * @param {string} url - The endpoint URL.
 * @param {Object} object - The JSON payload to send.
 * @returns {Promise<Object>} A promise resolving to the server response.
 */
function postJsonDataWithRefreshingToken(url, object) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.postJsonOnce(url, object);
    });
  } else {
    return httpManagerService.postJsonOnce(url, object);
  }
}

/**
 * Sends a POST request with JSON data as an observable, refreshing the access token if needed.
 * @param {string} url - The endpoint URL.
 * @param {Object} object - The JSON payload to send.
 * @returns {Observable<Object>} An observable resolving to the server response.
 */
function postObservableJsonDataWithRefreshingToken(url, object) {
  if (!httpManagerService.isAuthenticated()) {
    httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.postObservableJsonOnce(url, object);
    });
  } else {
    return httpManagerService.postObservableJsonOnce(url, object);
  }
}

/**
 * Sends a PUT request with JSON data.
 * @param {string} url - The endpoint URL.
 * @param {Object} object - The JSON payload to send.
 * @returns {Promise<Object>} A promise resolving to the server response.
 */
function putJsonOnce(url, object) {
  return new Promise((resolve, reject) => {
    let params;
    if (object) {
      params = JSON.stringify(object);
    }

    Axios.put(
      API_URL + url,
      params,
      httpManagerService.getJsonBearerHttpOptions()
    ).subscribe(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Sends a PUT request with form data.
 * @param {string} url - The endpoint URL.
 * @param {FormData} formData - The form data payload to send.
 * @returns {Promise<Object>} A promise resolving to the server response.
 */
function putFormDataOnce(url, formData) {
  return new Promise((resolve, reject) => {
    Axios.put(
      API_URL + url,
      formData,
      httpManagerService.getJsonBearerHttpOptions()
    ).subscribe(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Sends a PUT request without a request body.
 * @param {string} url - The endpoint URL.
 * @returns {Promise<Object>} A promise resolving to the server response.
 */
function putHttpOnce(url) {
  return new Promise((resolve, reject) => {
    Axios.put(
      API_URL + url,
      null,
      httpManagerService.getJsonBearerHttpOptions()
    ).subscribe(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Sends a PUT request with JSON data as an observable.
 * @param {string} url - The endpoint URL.
 * @param {Object} object - The JSON payload to send.
 * @returns {Observable<Object>} An observable resolving to the server response.
 */
function putObservableJsonOnce(url, object) {
  let params;
  if (object) {
    params = JSON.stringify([...object.entries()]);
  }

  return Axios.put(
    API_URL + url,
    params,
    httpManagerService.getJsonBearerHttpOptions()
  );
}

/**
 * Sends a PUT request with JSON data as an observable, refreshing the access token if needed.
 * @param {string} url - The endpoint URL.
 * @param {Object} object - The JSON payload to send.
 * @returns {Observable<Object>|undefined} An observable resolving to the server response.
 */
function putObservableJsonDataWithRefreshingToken(url, object) {
  if (!httpManagerService.isAuthenticated()) {
    httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.putObservableJsonOnce(url, object);
    });
  } else {
    return httpManagerService.putObservableJsonOnce(url, object);
  }
}

/**
 * Sends a PUT request with JSON data after requesting an access token.
 * @param {string} url - The endpoint URL.
 * @param {Object} object - The JSON payload to send.
 * @returns {Promise<Object>} A promise resolving to the server response.
 */
function putJsonDataWithRequestingToken(url, object) {
  return httpManagerService.requestAccessToken().then(() => {
    return httpManagerService.putJsonOnce(url, object);
  });
}

/**
 * Sends a PUT request with JSON data, refreshing the access token if needed.
 * @param {string} url - The endpoint URL.
 * @param {Object} object - The JSON payload to send.
 * @returns {Promise<Object>} A promise resolving to the server response.
 */
function putJsonDataWithRefreshingToken(url, object) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.putJsonOnce(url, object);
    });
  } else {
    return httpManagerService.putJsonOnce(url, object);
  }
}

/**
 * Sends a DELETE request.
 * @param {string} url - The endpoint URL.
 * @returns {Promise<Object>} A promise resolving to the server response.
 */
function deleteHTTPOnce(url) {
  return new Promise((resolve, reject) => {
    Axios.delete(
      API_URL + url,
      httpManagerService.getJsonBearerHttpOptions()
    ).subscribe(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Sends a DELETE request and returns an observable.
 * @param {string} url - The endpoint URL.
 * @returns {Observable<Object>} An observable resolving to the server response.
 */
function deleteObservableHttp(url) {
  return Axios.delete(
    API_URL + url,
    httpManagerService.getJsonBearerHttpOptions()
  );
}

/**
 * Sends a DELETE request after requesting an access token.
 * @param {string} url - The endpoint URL.
 * @returns {Promise<Object>} A promise resolving to the server response.
 */
function deleteHttpWithRequestingToken(url) {
  return httpManagerService.requestAccessToken().then(() => {
    return httpManagerService.deleteHTTPOnce(url);
  });
}

/**
 * Sends a DELETE request as an observable after requesting an access token.
 * @param {string} url - The endpoint URL.
 * @returns {undefined}
 */
function deleteObservableHttpWithRequestingToken(url) {
  httpManagerService.requestAccessToken().then(() => {
    return httpManagerService.deleteObservableHttp(url);
  });
}


/**
 * Deletes a resource at the specified URL, refreshing the access token if necessary.
 *
 * This function checks if the user is authenticated. If not, it attempts to refresh the access token
 * before making the delete request. If the user is already authenticated, it directly makes the delete request.
 *
 * @param {string} url - The URL of the resource to delete.
 * @returns {Promise} - A promise that resolves with the result of the delete request.
 */
function deleteHttpWithRefreshingToken(url) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.deleteHTTPOnce(url);
    });
  } else {
    return httpManagerService.deleteHTTPOnce(url);
  }
}

/**
 * Deletes a resource at the given URL, refreshing the access token if necessary.
 *
 * This function checks if the user is authenticated. If not, it attempts to refresh the access token
 * before making the delete request. If the user is already authenticated, it directly makes the delete request.
 *
 * @param {string} url - The URL of the resource to delete.
 * @returns {Promise} - A promise that resolves with the result of the delete request.
 */
function deleteObservableHttpWithRefreshingToken(url) {
  if (!httpManagerService.isAuthenticated()) {
    httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.deleteObservableHttp(url);
    });
  } else {
    return httpManagerService.deleteObservableHttp(url);
  }
}

/**
 * Sends a DELETE request to the specified URL with the provided JSON object.
 *
 * @param {string} url - The endpoint URL to send the DELETE request to.
 * @param {Object} object - The JSON object to be sent as the request payload.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
function deleteJsonOnce(url, object) {
  return new Promise((resolve, reject) => {
    Axios.delete(API_URL + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${httpManagerService.getToken()}`,
      },
      data: object,
    }).subscribe(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Deletes a JSON resource at the specified URL with an access token.
 *
 * This function first requests an access token and then uses it to delete
 * the JSON resource at the given URL.
 *
 * @param {string} url - The URL of the resource to delete.
 * @param {Object} object - The JSON object to send with the delete request.
 * @returns {Promise} A promise that resolves when the delete operation is complete.
 */
function deleteJsonWithRequestingToken(url, object) {
  return httpManagerService.requestAccessToken().then(() => {
    return httpManagerService.deleteJsonOnce(url, object);
  });
}

/**
 * Deletes a JSON resource at the specified URL, refreshing the access token if necessary.
 *
 * @param {string} url - The URL of the resource to delete.
 * @param {Object} object - The object to be sent with the delete request.
 * @returns {Promise} - A promise that resolves when the delete operation is complete.
 */
function deleteJsonWithRefreshingToken(url, object) {
  if (!httpManagerService.isAuthenticated()) {
    return httpManagerService.refreshAccessToken().then((data) => {
      return httpManagerService.deleteJsonOnce(url, object);
    });
  } else {
    return httpManagerService.deleteHTTPOnce(url);
  }
}
