import { profileConstants } from "_constants";

/**
 * Reducer function to handle profile-related actions.
 *
 * @param {Object} state - The current state of the profile.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.profile] - The profile data (if available).
 * @param {Object} [action.profile.data] - The profile data object.
 * @param {string} [action.error] - The error message (if available).
 * @returns {Object} The new state of the profile.
 */
export function profile(state = {}, action) {
  
  switch (action.type) {
    case profileConstants.GET_PROFILE_REQUEST:
      return {
        ...state,
        getProfileLoading: true,
        getProfileLoaded: false,
      };
    case profileConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileLoading: false,
        getProfileLoaded: true,
        data: action.profile.data,
      };
    case profileConstants.GET_PROFILE_FAILURE:
      return {
        ...state,
        getProfileLoading: false,
        getProfileLoaded: true,
        error: action.error,
      };

    case profileConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        getProfileLoading: true,
        getProfileLoaded: false,
      };
    case profileConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileLoading: false,
        getProfileLoaded: true,
        data: action.profile.data,
      };
    case profileConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        getProfileLoading: false,
        getProfileLoaded: true,

        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
}
