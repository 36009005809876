import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fileActions } from "_actions";
import Button from "_pages/Influencer/components/General/Button";

const LeadFile = ({ classN, onHandleManageFileModal, onHandleOpenConfirmationModal }) => {
  const dispatch = useDispatch();
  
  const { getFileLoaded, getFileLoading, files } = useSelector(state => state.file);

  // Handle Add Record Modal
  const openAddRecordModal = () => {
    onHandleManageFileModal('add', null);
  };

  // Handle Edit Record Modal
  const openEditRecordModal = (records) => {
    onHandleManageFileModal('edit', records);
  };

  // Handle Delete Confirmation Modal
  const openDeleteConfirmationModal = (type, platform) => {
    onHandleOpenConfirmationModal(type, platform);
  };

  // Load file data if needed (optional)
  useEffect(() => {
    if (!getFileLoaded) {
      dispatch(fileActions.getFiles());
    }
  }, [dispatch, getFileLoaded]);

  let records = [];
  let hasRecords = false;

  if (getFileLoaded) {
    records = files.data;
    hasRecords = records.length ? true : false;
  }

  return (
    <div className={classN}>
      <div className='inner-title-box'>
        <p>Document Links</p>
        {getFileLoaded && hasRecords && (
          <Button
            text='Add Record'
            icon='icon-lms icon-add'
            onClick={openAddRecordModal}
            classN="plain-btn"
          />
        )}
      </div>

      {getFileLoaded && hasRecords && (
        <div className='records-list'>
          {records.map((record, index) => (
            <div className='record-col' key={index}>
              <div className='record'>
                <div className='record-details'>
                  <div className='record-info'>
                    <span className='description'>Title</span>
                    <p className='title'>{record.name}</p>
                  </div>
                  <div className='record-info'>
                    <span className='description'>Description</span>
                    <p className='title'>{record.description}</p>
                  </div>
                  <div className='record-info'>
                    <span className='description'>Document Link</span>
                    <p className='title'>
                      <a href={record.link} target='new'>{record.link}</a>
                    </p>
                  </div>
                </div>
                <div className='record-btns'>
                  <button
                    className='c-btn c-btn-sm c-btn-light edit-record'
                    onClick={() => openEditRecordModal(record)}
                  >
                    <i className="icon-lms icon-edit"></i>Edit
                  </button>
                  <button
                    className='c-btn c-btn-sm c-btn-light delete-record'
                    onClick={() => openDeleteConfirmationModal('file', record)}
                  >
                    <i className="icon-lms icon-delete"></i>Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {getFileLoaded && !hasRecords && (
        <div className='empty-tab-content'>
          <div className='empty-content'>
            <h7>Add Applicant File Here</h7>
            <p>Files will appear here</p>
            <span onClick={openAddRecordModal}>
              <i className='icon-lms icon-add'></i>
              Add File
            </span>
          </div>
        </div>
      )}

      {getFileLoading && (
        <div className='loading-box'>
          <div className="lds-ellipsis">
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
      )}
    </div>
  );
};

export { LeadFile };
