import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import {utilitiesService} from "_services";
import TableMainLoader from '../../../../_components/Loader/TableMainLoader';
import {managerActions} from "_actions";
import {useParams} from "react-router-dom";
import { utilitiesService as utility } from "_services";

const operatorIcon = {
    'EQUAL': '=',
    'NOT_EQUAL': '!=',
    'CONTAINS': '⊂',
    'NOT_CONTAINS': '!⊂',
    'LESS_THAN': '<',
    'GREATER_THAN': '>',
}

const MemberTable = ({onHandleOpenEditInterval, onHandleDeleteMember, setIsTabActive}) => {

    let color = '1';
    const [managers, setManagers] = useState([]);

    const managersLoaded = useSelector((state) => state.managers.getManagersLoaded);
    const dispatch = useDispatch();
    const params = useParams();

    const managersRaw = useSelector((state) => {
        const {managers} = state;
        if(managers.getManagersLoaded) {
            return managers.managers.content;
        }
        return [];
    });

    useEffect(() => {
        setManagers(managersRaw);
    }, [managersLoaded])

    console.log('manager load: ', managersLoaded);

    function handleStatusChange(checked, id, count, adminValue) {
        console.log(checked,id);

        let req = {
            status: checked,
            count: count,
            admin: adminValue
        }

        dispatch(managerActions.updateManagerConfig(params.id, id, req));
    }

    function handleAdminStatusChange(checked, id, count, statusValue) {
        console.log(checked,id);

        let req = {
            status: statusValue,
            count: count,
            admin: checked
        }

        dispatch(managerActions.updateManagerConfig(params.id, id, req));
    }

    const handleToggleCalendly = (id) => {
        setIsTabActive(true);
        onHandleOpenEditInterval(id);
    }

    const handleToggleEdit = (id) => {
        setIsTabActive(false);
        onHandleOpenEditInterval(id);
    }

    const renderMember = (memberAccount) => {
          if(memberAccount.photo){
            try {
              const decryptedPhoto = utility.decrypt(memberAccount.photo);
              return <div className='avatar-box'>
                <span>
                <img src={decryptedPhoto} />
                </span>
              </div>
            } catch (error) {
              console.error("Problem decrypting photo");
            }
          }
          const displayedLoggedInUser = utility.decrypt(memberAccount.first_name);
          const firstChar = displayedLoggedInUser.charAt(0);
          return <span>{firstChar}</span>;
      };

    return (
        <div className='table-view table-interval'>
            <div className='table-scroll'>
                {
                    !managersLoaded &&
                    <TableMainLoader />
                }
                {managersLoaded && managers.length === 0 &&
                        <div className='table-empty-box'>
                            <div>
                                <h3>No Members Found</h3>
                            </div>
                        </div>
                }
                <table>
                    <thead>
                    <tr>
                        <th width="25%"><span>Members</span></th>
                        { params.id != 4 &&
                            <th width="8%"><span>Admin Assign</span></th>
                        }
                        <th width="8%"><span>Auto Assign</span></th>
                        <th width="7%"><span>Heads</span></th>
                        <th width="35%"><span>Auto-Assign Criteria</span></th>
                        {params.id == 5 && <th width="10%"><span>Calendly Invite</span></th>}
                        <th width="20%" className='text-center'><span>Actions</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {managers.map((manager, index) => {
                        const {auto_assign_config, calendly_account} = manager;
                        let fname = utilitiesService.decrypt(manager.first_name);
                        let lname = utilitiesService.decrypt(manager.last_name);
                        let email = utilitiesService.decrypt(manager.email);
                        let initial = fname.charAt(0);
                        let {id, count,status, configs, admin}  = auto_assign_config;

                        return(<tr key={index}>
                            <td>
                                <div className='avatar-info'>
                                    <div className={`custom-avatar avatar--color-${color}`}>
                                        {renderMember(manager)}
                                    </div>
                                    <div className={'name-info w-100-b'}>
                                        <div className="main-name">
                                            <span>{fname} {lname}</span>
                                        </div>
                                        <div className="others-info">
                                            <span className='others-text'>{email}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            { params.id != 4 &&
                             <td>
                                <label className="switch-tggle">
                                    <input type="checkbox" defaultChecked={admin} onChange={(e) => handleAdminStatusChange(e.target.checked, id, count, status)} />
                                    <span className="slider">
                                        <i className="fas fa-check"></i>
                                        <i className="fas fa-times"></i>
                                    </span>
                                    <span className='tggle-txt'></span>
                                    </label>
                                </td>
                           }
                            <td>
                                <label className="switch-tggle">
                                    <input type="checkbox" defaultChecked={status} onChange={(e) => handleStatusChange(e.target.checked, id, count, admin)} />
                                    <span className="slider">
                                        <i className="fas fa-check"></i>
                                        <i className="fas fa-times"></i>
                                    </span>
                                    <span className='tggle-txt'></span>
                                </label>
                            </td>
                            <td>
                            <span>
                                {count}
                            </span>
                            </td>
                            <td>
                                <div className='ig-parameters'>
                                    {configs.map((config, index) => {
                                        return (
                                        <span key={index} className='param-text'>
                                            <strong className={`color-grey`}>{config.label}</strong> <i className='operator-icon'>{operatorIcon[config.operator]}</i> "<strong className={`color-grey`}>{config.value}</strong>"
                                            {/*<i className="fas fa-times-circle"></i>*/}
                                        </span>);
                                    })}
                                    {
                                        configs.length === 0 && <span>Any</span>
                                    }
                                </div>
                            </td>
                            {params.id == 5 && <td>
                                <span className='text-btn' onClick={ ()=> handleToggleCalendly(id)}>{calendly_account ? 'Check Calendly Invite Details' : 'No Calendly Invite'} </span>
                            </td>}

                            <td>
                                <div className='actions-btn'>
                                    <button className='c-btn c-btn-light' onClick={ ()=> handleToggleEdit(id)}>
                                        Edit
                                    </button>
                                    <button className='c-btn c-btn-red' onClick={() => onHandleDeleteMember(manager)}>
                                        Delete
                                    </button>
                                </div>
                            </td>
                        </tr>);
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MemberTable