import { chatConstants } from "_constants";

/**
 * Chat reducer function to handle chat-related actions and update the state accordingly.
 *
 * @param {Object} state - The current state of the chat.
 * @param {Object} action - The action dispatched to the reducer.
 * @param {string} action.type - The type of the action.
 * @param {Object} [action.chats] - The chat data payload (if any).
 * @param {Object} [action.error] - The error payload (if any).
 * @returns {Object} The updated state based on the action type.
 */
export function chat(state = {}, action) {
  switch (action.type) {
    case chatConstants.GET_ALL_CHATS_REQUEST:
      return {
        ...state,
        getChatsLoading: true,
        getChatsLoaded: false,
      };
    case chatConstants.GET_ALL_CHATS_SUCCESS:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        getChatNotification: false,
        chats: action.chats.data,
      };
    case chatConstants.GET_ALL_CHATS_FAILURE:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        error: action.error,
      };

    case chatConstants.CHAT_NOTIFICATION_REQUEST:
      return {
        ...state,
        getChatNotification: true,
      };

    case chatConstants.UPDATE_CHATS_UNREAD_REQUEST:
      return {
        ...state,
        getChatsLoading: true,
        getChatsLoaded: false,
      };
    case chatConstants.UPDATE_CHATS_UNREAD_SUCCESS:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        chats: action.chats.data,
      };
    case chatConstants.UPDATE_CHATS_UNREAD_FAILURE:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        error: action.error,
      };

    case chatConstants.CHAT_REPLY_REQUEST:
      return {
        ...state,
        getChatsLoading: true,
        getChatsLoaded: false,
      };
    case chatConstants.CHAT_REPLY_SUCCESS:
      return {
        ...state,
        //  getChatsLoading: false,
        getChatsLoaded: true,
        chats: action.chats.data,
      };
    case chatConstants.CHAT_REPLY_FAILURE:
      return {
        ...state,
        getChatsLoading: false,
        getChatsLoaded: true,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}
