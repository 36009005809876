import { duration } from 'moment';
import { alertConstants } from '../_constants';

/**
 * Reducer function to handle alert state changes based on action types.
 *
 * @param {Object} state - The current state of the alert.
 * @param {Object} action - The action dispatched to the reducer.
 * @param {string} action.type - The type of the action.
 * @param {Object} [action.message] - The message payload of the action.
 * @param {string} [action.message.error_message] - The error message payload of the action.
 * @returns {Object} The new state of the alert.
 */
export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        ...state,
        type: 'alert-success',
        message: action.message,
        duration: action.duration
      };

    case alertConstants.ERROR:
      return {
        ...state,
        type: 'alert-danger',
        message: action.message.error_message,
        duration: action.duration
      };
    case alertConstants.CLEAR:
      return {};

    default:
      return state
  }
}