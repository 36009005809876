import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { collection, setDoc, getDoc,  doc, getFirestore, updateDoc, arrayUnion  } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDA911i3K525QLNRMP54LflNHSUCvpfV4A",
    authDomain: "propelrr-analytics-automation.firebaseapp.com",
    databaseURL: "https://propelrr-analytics-automation-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "propelrr-analytics-automation",
    storageBucket: "propelrr-analytics-automation.appspot.com",
    messagingSenderId: "79051968318",
    appId: "1:79051968318:web:f590e502f97ed76774266f",
    measurementId: "G-XRVM4LKF5P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const fireStore = getFirestore(app);
const messaging = getMessaging(app);
export {db}

export const isTokenFound = (userId, setTokenFound) => {

    return getToken(messaging, {vapidKey: 'BOk8nredXaWsZle7nbvLDeToblNqhc8U5UnEGUehZEekUOC51_8rxo6oZzvds9bKWdYeQSHFfrieKgKomRY9aSs'}).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            addUserToken(userId,currentToken);
            setTokenFound(true);
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });

}

export const addUserToken = (userId, token) => {
    let id = parseInt(userId);
    let ref = doc(fireStore, "notification", userId);
    setDoc(ref, {userId: id}, {merge: true});
    updateDoc(ref, {
        tokens: arrayUnion(token)
    });
    // console.log("user token added", userId, token);
}

export const retrieveToken = async (userId) => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BOk8nredXaWsZle7nbvLDeToblNqhc8U5UnEGUehZEekUOC51_8rxo6oZzvds9bKWdYeQSHFfrieKgKomRY9aSs' });
        if (currentToken) {
            addUserToken(userId, currentToken);
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
}

export const onMessageListener = () => {
    console.log("messaging called", messaging)
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
          console.log("Foreground message received:", payload);
          resolve(payload);
        });
      });
}
    
  


