import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { influencerActions } from '_actions';
import Button from '_pages/Influencer/components/General/Button';
import Moment from 'react-moment';
import DOMPurify from 'dompurify';

const RateTab = ({ projectId, influencerId, onHandleManageRatesModal, onHandleOpenConfirmationModal, classN }) => {
    const dispatch = useDispatch();
    const { getInfluencerRateLoaded, influencerPlatformLoading, rates } = useSelector(state => state.influencers);

    useEffect(() => {
        dispatch(influencerActions.getInfluencerRates(projectId, influencerId));
    }, [dispatch, projectId, influencerId]);

    const openAddRatesModal = () => onHandleManageRatesModal('add', null);
    const openEditRatesModal = (rate) => onHandleManageRatesModal('edit', rate);
    const openDeleteConfirmationModal = (type, rate) => onHandleOpenConfirmationModal(type, rate);

    const hasRates = getInfluencerRateLoaded && rates.length > 0;

    return (
        <div className={classN}>
            <div className='inner-title-box'>
                <p>Rates</p>
                {hasRates && (
                    <Button
                        text='Add Rate'
                        icon='icon-lms icon-add'
                        onClick={openAddRatesModal}
                        classN='plain-btn'
                    />
                )}
            </div>

            {hasRates ? (
                <div className='rates-list'>
                    {rates.map((rate, index) => (
                        <div key={index} className='rate-col'>
                            <div className='rate'>
                                <div className='rate-details'>
                                    <div className='rate-currency'>
                                        <p className='title' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rate.currency.toUpperCase()) }} />
                                        <span className='description'>Currency</span>
                                    </div>
                                    <div className='rate-min'>
                                        <p className='title' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rate.minimum) }} />
                                        <span className='description'>Minimum Rate</span>
                                    </div>
                                    <div className='rate-max'>
                                        <p className='title' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rate.maximum) }} />
                                        <span className='description'>Maximum Rate</span>
                                    </div>
                                    <div className='rate-des'>
                                        <span className='description'>Description</span>
                                        <p className='title' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rate.description) }} />
                                    </div>
                                    <div className='rate-time'>
                                        <span className='timestamp'>
                                            Rate Added: <Moment date={rate.created} fromNow ago /> ago
                                        </span>
                                        {rate.updated && (
                                            <span>Last Updated: <Moment date={rate.updated} fromNow ago /> ago</span>
                                        )}
                                    </div>
                                </div>
                                <div className='rate-btns'>
                                    <button className='c-btn c-btn-sm c-btn-light edit-record' onClick={() => openEditRatesModal(rate)}>
                                        <i className='icon-lms icon-edit'></i>Edit
                                    </button>
                                    <button className='c-btn c-btn-sm c-btn-light delete-record' onClick={() => openDeleteConfirmationModal('rate', rate)}>
                                        <i className='icon-lms icon-delete'></i>Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className='empty-tab-content'>
                    <div className='empty-content'>
                        <h6>Add Your First Rate</h6>
                        <p>Rates will appear here</p>
                        <span onClick={openAddRatesModal}>
                            <i className='icon-lms icon-add'></i>
                            Add rate
                        </span>
                    </div>
                </div>
            )}

            {influencerPlatformLoading && (
                <div className='loading-box'>
                    <div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
                </div>
            )}
        </div>
    );
};

export { RateTab };
