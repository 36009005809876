import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { projectActions } from "_actions";
import { utilitiesService as utility } from "_services";

const LeadResponsibleDropdown = ({
  project,
  members,
  membersLoaded,
  projectLoaded,
  lead,
  user,
  onHandleUpdateLeadResponsible,
}) => {
  const [filterToggled, setFilterToggled] = useState(false);
  const filterRef = useRef(null);

  useEffect(() => {
    const handleDropdown = (value) => {
      if (!filterRef.current.contains(value.target)) {
        setFilterToggled(false);
      }
    };

    document.addEventListener("click", handleDropdown, true);

    return () => {
      document.removeEventListener("click", handleDropdown, true);
    };
  }, []);

  const handleOpenFilter = () => {
    setFilterToggled(true);
  };

  const handleCloseFilter = () => {
    setFilterToggled(false);
  };

  const handleUpdateLeadResponsible = (value) => {
    onHandleUpdateLeadResponsible(value);
    setFilterToggled(false);
  };

  const handleDisableAssigned = (project, user, responsible) => {
    let admin = false;
    if (
      user &&
      user.data &&
      user.data.projects &&
      user.data.projects.owned &&
      Array.isArray(user.data.projects.owned) &&
      user.data.projects.owned.length > 0 &&
      project &&
      project.data &&
      project.data.id
    ) {
      admin = user.data.projects.owned.some(
        (e) => e.id === parseInt(project.data.id)
      );
    }
    
    // ? removed lead manager able to assign
    // if (!admin) {
    //   admin = user?.data?.id === responsible?.id;
    // }

    // make as admin if user is an admin
    if(!admin){
      admin = user?.data?.admin || false;
    }

    return admin;
  };

  const leadResponsible = lead.responsible
    ? utility.decrypt(lead.responsible.first_name) +
      " " +
      utility.decrypt(lead.responsible.last_name)
    : "Unassigned";

  const [items, setItems] = useState([]);

  const uniqueUsers = useMemo(() => {
    const seenEmails = new Set();

    return items.filter((item) => {
        const email = item.account.email;
        const trimmedEmail = utility.decrypt(email).toLowerCase().trim();

        if (seenEmails.has(trimmedEmail)) {
            return false;
        }
        seenEmails.add(trimmedEmail);
        return true;
    });
}, [JSON.stringify(items)]);  // Ensures proper updates


  useEffect(() => {
    let newItems = [];
    

    if (membersLoaded && members && members.data) {
      members.data.forEach((mem) => {
        newItems.push(mem);
      });
    }

    if (projectLoaded && project && project.data && project.data.owner) {
      const projectOwnerEmail = project.data.owner.email;
      const isAlreadyExists = newItems.some((item) => {
        return item.account.email === projectOwnerEmail;
      });

      if(!isAlreadyExists){
        newItems.push(project.data.owner);
      }
    }

    setItems(newItems);
  }, [projectLoaded, membersLoaded, project, members]);

  const admin = handleDisableAssigned(project, user, lead.responsible);

  return (
    <div className="filter-box" ref={filterRef}>
      <div className={admin ? "filter-btn admin-btn" : "filter-box"}>
        {admin && (
          <span onClick={handleOpenFilter}>
            {leadResponsible}
          </span>
        )}
        {!admin && <span>{leadResponsible}</span>}
      </div>

      <div className={filterToggled ? "filter-dropdown active" : "filter-dropdown"}>
        <div className="filter-title">
          <p>Assign</p>
          <i
            className="icon-lms icon-close"
            onClick={handleCloseFilter}
          ></i>
        </div>
        <div className="filter-list">
          <div className="filter-group">
            {uniqueUsers.map((item, index) => {
              let name =
                utility.decrypt(item.account.first_name) +
                " " +
                utility.decrypt(item.account.last_name);
              return (
                <span
                  key={`filter-dropdown-${index}`}
                  className="filter-name"
                  onClick={() =>
                    handleUpdateLeadResponsible(item.account.email)
                  }
                >
                  {name}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  const { user } = state.authentication;
  const { project, members, membersLoaded, projectLoaded } = state.projects;
  return { project, members, membersLoaded, projectLoaded, user };
};

const actionCreators = {
  getProject: projectActions.getProject,
};

const connectedViewLeadResponsibleDropdown = connect(
  mapState,
  actionCreators
)(LeadResponsibleDropdown);

export { connectedViewLeadResponsibleDropdown as LeadResponsibleDropdown };
