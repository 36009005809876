import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { leadActions, projectActions } from "_actions";
import { getLeadIdentifier } from "_services/utilities.service";
import TextLoader from '_components/Loader/TextLoader';

// Functional BreadcrumbPage component
/**
 * BreadcrumbPage component renders breadcrumb navigation for projects and leads.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.projects - The projects data.
 * @param {Object} props.projects.project - The current project data.
 * @param {Object} props.leads - The leads data.
 * @param {boolean} props.leads.leadLoaded - Flag indicating if the lead data is loaded.
 * @param {Object} props.leads.lead - The current lead data.
 * @returns {JSX.Element} The rendered breadcrumb navigation.
 */
const BreadcrumbPage = ({ projects, leads }) => {
    const { leadLoaded } = leads;
    const project = projects.project;
    const lead = leads.lead;

    // Generate lead identifier if lead exists
    let leadIdentifier = '';
    if (lead) {
        leadIdentifier = getLeadIdentifier(lead.data.identifier, lead.data.identifier_type, lead.data.basic_info);
    }

    return (
        <div className='breadcrumbs'>
            {/* Back link to project leads if project exists */}
            {project && (
                <Link to={`/project/${project.data.id}/leads`} className='back'>
                    <span className='icon-s'><i className="icon-lms icon-arrow-left"></i></span>
                </Link>
            )}

            {/* Back link when project is not available */}
            {!project && (
                <div className='back'>
                    <span className='icon-s'><i className="icon-lms icon-arrow-left"></i></span>
                </div>
            )}

            {/* Breadcrumb for Leads */}
            {project && (
                <Link to={`/project/${project.data.id}/leads`} className='link'>
                    Leads<i>/</i>
                </Link>
            )}

            {/* Breadcrumb for Leads when project is not available */}
            {!project && (
                <div className='link'>
                    Leads<i>/</i>
                </div>
            )}

            {/* Show lead identifier if available and lead is loaded */}
            {lead && leadLoaded && <span>{leadIdentifier}</span>}

            {/* Show loader if lead is not yet loaded */}
            {!leadLoaded && <TextLoader />}
        </div>
    );
};

// Mapping state from Redux store to component props
const mapState = (state) => {
    const { projects, leads } = state;
    return { projects, leads };
};

// Action creators (optional, but could be used for dispatching actions)
const actionCreators = {
    getProject: projectActions.getProject,
    getLead: projectActions.getLead,
};

// Connect the functional component to Redux store
const connectedViewBreadcrumbPage = connect(mapState, actionCreators)(BreadcrumbPage);

export { connectedViewBreadcrumbPage as BreadcrumbPage };
