import { FormGroupUpdate } from "../../../../../_pages/Forms/FormGroupUpdate";
import React, { useState, useEffect, useRef } from "react";
export const renderFormGroups = (data, state, setState, handleUpdate, props, leadData, dispatch) => {
  let totalFormGroups = data.length || 0;
  let hasFormGroups = totalFormGroups > 0;

  const projectId = window.localStorage.getItem("projectId") || "5"; 

  if (typeof data !== "undefined") {
    data = data.sort((a, b) => (a.form_order < b.form_order ? -1 : 1));
  }

  return (
    <>
      <div
        className="row info-group"
        onClick={(e) => {
          e.stopPropagation();
          setState((prevState) => ({
            ...prevState,
            activeFormGroup: "",
            activeFormField: "",
            activeSideBar: "select",
          }));
        }}
      >
        {hasFormGroups &&
          data.map((value, index) => {
            return (
              <FormGroupUpdate
                dispatch={dispatch}
                leadData={leadData}
                key={index}
                formGroupData={value}
                formGroupId={value.group_id}
                formGroupKey={index}
                totalFormGroups={totalFormGroups}
                handleUpdate={handleUpdate}
                activeFormGroup={state.activeFormGroup}
                activeFormField={state.activeFormField}
                projectId={projectId}
                formId={props.match.params.id}
                selectedInput={state.selectedInput}
              />
            );
          })}
      </div>
    </>
  );
};
