import React from "react";

/**
 * KpiLoader component renders a skeleton loader for KPI elements.
 * 
 * This component displays a series of skeleton boxes to indicate loading state for KPI elements.
 * It uses the "skeleton-loader" and "kpi-loader" classes for styling the loader container,
 * and "skeleton", "skeleton-box", and "kpi-skeleton" classes for individual skeleton boxes.
 * 
 * @returns {JSX.Element} A div containing multiple skeleton boxes for KPI loading state.
 */
const KpiLoader = () => {
  return (
    <div className="skeleton-loader kpi-loader">
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
        <div className="skeleton skeleton-box kpi-skeleton"></div>
    </div>
  );
};

export default KpiLoader;
