import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { utilitiesService as utility } from '_services';
import Moment from 'react-moment';
import { projectActions, formActions, leadActions } from '_actions';
import { Button, ButtonGroup, Icon } from 'blackbox-react';

const ImportHistory = ({ getAllImportData, leadImportsLoaded }) => {
  const { id } = useParams();
  const [leadImports, setLeadImports] = useState([]);
  const projectId = localStorage.getItem('projectId');

  useEffect(() => {
    const fetchImports = async () => {
      const imports = await getAllImportData(id);
      setLeadImports(imports || JSON.parse(localStorage.getItem('AllLeadImport')) || []);
    };
    fetchImports();
  }, [getAllImportData, id]);

  return (
    <MDBContainer fluid className='p--md lead-import'>
      <MDBRow className='m--0'>
        <MDBCol lg='3' xl='2' md='4' className='p-t--0 p-l--0 p-r--sm p-b--sm'>
          <MDBRow className='p--0 m--0'>
            <MDBCol className='p-0 mt-2'>
              <p className='font-size-12 font-weight-600 font-color-gray mb-0'>LEAD MANAGEMENT</p>
              <p className='font-family-crimson font-size-30 line-height-7 color-orange'>Import Leads</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className='p-t--md p-l--md p-r--md p-b--0 back-button'>
            <MDBCol>
              <ButtonGroup>
                <Link to={`/project/${projectId}/leads`}>
                  <Button flat background='transparent' foreground='black' borderWidth={2} borderColor='dark' size={-1} styles={{ width: '100%', opacity: '0.8' }}>
                    <Icon color='black' name='chevron' rotate={180} />
                    <span className='color-black font-weight-bold'>View All Leads</span>
                  </Button>
                </Link>
              </ButtonGroup>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol lg='9' xl='10' md='8' className='px-0'>
          <MDBRow className='d-flex'>
            <MDBCol lg='3' className='my-auto'>
              <p className='mb-0 font-family-crimson font-size-22 my-auto pr-3'>Import History</p>
            </MDBCol>
            <MDBCol className='text-right'>
              <ButtonGroup>
                <Link to={`/project/${projectId}/leads/import`}>
                  <Button flat background='orange' foreground='white' borderWidth={2} borderColor='orange' size={-2} styles={{ minWidth: '150px' }}>
                    <MDBIcon icon='upload' className='mr-2' />
                    <span className='color-white font-weight-bold'>Import Leads</span>
                  </Button>
                </Link>
              </ButtonGroup>
            </MDBCol>
          </MDBRow>
          <MDBCol className='sub-body-container background-color-white background-rectangle m-t--md'>
            {leadImportsLoaded ? (
              leadImports.length ? (
                <MDBRow className='p--md'>
                  <MDBCol className='px-0 background-color-white border-radius-3'>
                    <table className='table-sm bordered' id='table-form'>
                      <thead>
                        <tr className='background-color-white'>
                          <th width='13%'>FILE NAME</th>
                          <th width='8%'>IMPORTED ON</th>
                          <th width='1%'>CREATED</th>
                          <th width='13%'>IMPORTED BY</th>
                          <th width='5%'>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leadImports.map((value, index) => (
                          <tr key={index}>
                            <td>{value.file_name}</td>
                            <td><Moment date={value.imported} format='LLL' /></td>
                            <td>{value.created}</td>
                            <td>{`${utility.decrypt(value.imported_by.first_name)} ${utility.decrypt(value.imported_by.last_name)}`}</td>
                            <td className={value.is_completed ? 'font-color-qualified' : 'font-color-red'}>{value.is_completed ? 'Completed' : 'Not Completed'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </MDBCol>
                </MDBRow>
              ) : (
                <div className='create-view text-center'>
                  <p className='font-size-32 font-weight-500 font-family-crimson m-b--0'>No Imports found</p>
                  <p>You can bulk add leads by importing a CSV file.</p>
                  <Link to={`/project/${projectId}/leads/import`}>
                    <Button flat background='orange' foreground='white' borderWidth={2} borderColor='orange'>
                      <Icon name='plus' />
                      <span className='color-white m-l--sm'>Import Leads</span>
                    </Button>
                  </Link>
                </div>
              )
            ) : (
              <div className='loader-container'>
                <div className='loader dark'>Loading...</div>
              </div>
            )}
          </MDBCol>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const mapState = (state) => {
  const { leadImportsLoaded } = state.leads;
  return { leadImportsLoaded };
};

const actionCreators = {
  getAllImportData: leadActions.getAllImportData,
};

const connectedImportHistory = connect(mapState, actionCreators)(ImportHistory);
export { connectedImportHistory as ImportHistory };
