import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { leadActions } from "_actions";
import { getLeadSubIdentifier, utilitiesService as utility } from "_services";
import { getLeadIdentifier } from "_services/utilities.service";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import PhiColumns from "_pages/Leads/components/Table/PrimaryHomesLeadColumns";
import ThamColumns from "_pages/Leads/components/Table/MaayoLeadColumns";
import PropelrrServiceColumns from "_pages/Leads/components/Table/PropelrrServiceLeadColumns";
import PropelrrCareersColumns from "_pages/Leads/components/Table/PropelrrCareersLeadColumns";
import DemoLeadColumns from "_pages/Leads/components/Table/DemoLeadColumns";
import DmciLeasingColumns from "./DmciLeasingColumns";
import {
  defaultConfig,
  propelrrServiceConfig,
  propelrrCareerConfig,
  maayoConfig,
  phiConfig,
  dmciLeasingConfig,
} from "_pages/Leads/constants/TableHeaderConfig";

const LeadViewList = ({ summary, leadsLoaded, leads, projectId, headerCheckboxChecked, onHandleOpenTableAction, isTableActionBox }) => {
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const history = useHistory();

  const handleTextEllipsis = (text, max) => text.length > max ? text.substr(0, max) + "..." : text;

  const handleRedirectLead = (leadId) => {
    history.push(`/project/${projectId}/leads/${leadId}`);
  };

  const handleCheckboxChange = (leadId) => {
    setSelectedLeadIds((prev) => {
      const updatedLeadIds = prev.includes(leadId)
        ? prev.filter((id) => id !== leadId)
        : [...prev, leadId];
      const allLeadsSelected = updatedLeadIds.length === leads?.data?.content?.length;
      onHandleOpenTableAction(updatedLeadIds, allLeadsSelected);
      return updatedLeadIds;
    });
  };

  const handleHeaderCheckboxChange = (isChecked) => {
    if (isChecked) {
      const allLeadIds = leads?.data?.content?.map((lead) => lead.id) || [];
      setSelectedLeadIds(allLeadIds);
      onHandleOpenTableAction(allLeadIds, true);
    } else {
      setSelectedLeadIds([]);
      onHandleOpenTableAction([], false);
    }
  };
  
  useEffect(() => {
    if (leads?.data?.content?.length > 0) {
      if (headerCheckboxChecked) {
        const allLeadIds = leads?.data?.content?.map((lead) => lead.id) || [];
        setSelectedLeadIds(allLeadIds);
        onHandleOpenTableAction(allLeadIds, true);
      } else {
        setSelectedLeadIds([]);
        onHandleOpenTableAction([], false);
      }
    }
  }, [headerCheckboxChecked, leads?.data?.content]);
  
  
  useEffect(() => {
    if (!isTableActionBox) {
      setSelectedLeadIds([]);
      onHandleOpenTableAction([], false);
    }
  }, [isTableActionBox]);
  
  

  let config;
  switch (parseInt(projectId)) {
    case 1:
      config = phiConfig;
      break;
    case 3:
      config = maayoConfig;
      break;
    case 5:
      config = propelrrServiceConfig;
      break;
    case 6:
      config = propelrrCareerConfig;
      break;
    case 8:
      config = dmciLeasingConfig;
      break;
    default:
      config = defaultConfig;
      break;
  }

  let categories = [];
  if (summary.summaryLoaded) {
    summary.page.data.forEach((elem) => {
      if (!categories.includes(elem.category)) {
        categories.push(elem.category);
      }
    });
  }

  if (leadsLoaded && leads.data.content.length > 0) {
    return (
      <>
        {leads.data.content.map((lead, index) => {
          const identifierValue = getLeadIdentifier(lead.identifier, lead.identifier_type, lead.basic_info);
          const subIdentifierValue = getLeadSubIdentifier(lead.sub_identifier, lead.sub_identifier_type, lead.basic_info);
          const color = categories.indexOf(lead.project_status.category) + 1;
          let leadChat = lead.lead_chat;
          let leadEmail = lead.lead_email;
          return (
            <tr key={index} className={selectedLeadIds.includes(lead.id) ? "active" : ""}>
              <td>
                  <div className="name-td">
                    {/* <div className={`custom-avatar avatar-${color}`}>
                                                <span>
                                                    {identifierValue.slice(0, 1)}
                                                </span>
                                            </div> */}
                    <div className="checkbox-group" key={index}>
                      <input
                        type="checkbox"
                        name={`table-checkbox-${index}`}
                        checked={
                          selectedLeadIds.includes(lead.id)
                        }
                        onChange={() =>{
                          handleCheckboxChange(lead.id);
                        }}
                        id={`table-checkbox-${index}`}
                        // onChange={onHandleOpenTableAction}
                      />
                      <label htmlFor={`table-checkbox-${index}`}></label>
                    </div>
                    <div
                      className="name-info"
                      onClick={() => handleRedirectLead(lead.id)}
                    >
                      <div className="main-name">
                        <span className="name">
                          {handleTextEllipsis(identifierValue || "", 25)}
                        </span>
                        <div className="notif-box">
                          {leadChat && (
                            <div>
                              <i className="fas fa-comment-alt"></i>
                              <span
                                className={lead.chat_unread == 0 ? "empty" : 0}
                              >
                                {
                                  lead.chat_unread > 9 ? "9+" : lead.chat_unread
                                }
                              </span>
                            </div>
                          )}
                          {leadEmail && (
                            <div>
                              <i className="fas fa-envelope"></i>
                              <span
                                className={lead.email_unread == 0 ? "empty" : 0}
                              >
                                {
                                lead.email_unread > 9 ? "9+" : lead.email_unread
                                }
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="others-info">
                        {subIdentifierValue.map((sub, index) => {
                          return (
                            <span key={index} className="others-text">
                              <i>
                                {handleTextEllipsis(sub.value || "", 30)}
                              </i>
                              {lead.verified && sub.verify_type === "email" && (
                                <i className="fas fa-check-circle"></i>
                              )}
                              {lead.phone_verified &&
                                sub.verify_type === "phone" && (
                                  <i className="fas fa-check-circle"></i>
                                )}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </td>
              <td className={`status--box g-status--color-${color}`}>
                <span className="status-text">{lead.project_status?.name}</span>
                <span className="time-text">Updated: <Moment date={lead.updated} fromNow ago /></span>
              </td>
              {parseInt(projectId) === 1 && <PhiColumns lead={lead} />}
              {parseInt(projectId) === 3 && <ThamColumns lead={lead} />}
              {parseInt(projectId) === 5 && <PropelrrServiceColumns lead={lead} />}
              {parseInt(projectId) === 6 && <PropelrrCareersColumns lead={lead} />}
              {parseInt(projectId) === 8 && <DmciLeasingColumns lead={lead} />}
              {parseInt(projectId) === 7 && <DemoLeadColumns lead={lead} />}
              <td><Moment date={lead.created} format="LLL" /></td>
            </tr>
          );
        })}
      </>
    );
  }

  return (
    <tr>
      <td colSpan={config.length} className="loading-notfound-box">
        {!leadsLoaded ? <div className="loading-text">Loading Leads</div> : <div className="loading-text">No Lead Found</div>}
      </td>
    </tr>
  );
};

const mapState = (state) => ({
  leads: state.leads.leads,
  leadsLoaded: state.leads.leadsLoaded,
  summary: state.summary,
});

const connectedLeadViewList = connect(mapState, { getLeads: leadActions.getLeadsPaginated, getLeadSummary: leadActions.getLeadSummary })(LeadViewList);
export { connectedLeadViewList as LeadViewList };
