import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear
};

/**
 * Creates an action to indicate a successful alert.
 *
 * @param {string} message - The success message to be displayed.
 * @returns {Object} The action object with type 'SUCCESS' and the provided message.
 */
function success(message, duration = 2000) {
    return { type: alertConstants.SUCCESS, message , duration};
}

/**
 * Creates an action to signal an error alert.
 *
 * @param {string} message - The error message to be displayed.
 * @returns {Object} The action object with type and message.
 */
function error(message, duration = 2000) {
    return { type: alertConstants.ERROR, message, duration };
}

/**
 * Creates an action to clear alerts.
 *
 * @returns {Object} The action object with type `alertConstants.CLEAR`.
 */
function clear() {
    return { type: alertConstants.CLEAR };
}