import React, {useState} from 'react'
import { Link } from 'react-router-dom'

// components
import Breadcrumbs from './components/Breadcrumbs'
import Button from './components/Button'
import SidebarForm from './components/Form/SidebarForm'
import FormAdd from './components/Form/FormAdd'

const AddInfluencer = () => {

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  const [email] = useState('');
  const [errorEmail, setErrorEmail] = useState('');

  const validateEmail = (e) => {

    if(emailRegex.test(email) === false) {
        setErrorEmail('Invalid Email Address');
    } else {
        setErrorEmail('');
        return true;
    }
  }
  return (
    <>
        <div className='page-title-ctas'>
            <div className='row align-items-center'>
                <div className='col'>
                    <Breadcrumbs />
                </div>
                <div className='col'>
                    <div className='button-group'>
                        <Link to='/propelrr'>
                            <i className='fas fa-chevron-left'></i>
                            Back To All Influencers
                        </Link>
                        <span className='custom-line'></span>
                        <Button text='Save Influencer' icon='fas fa-save' />
                        <span className='custom-line'></span>
                        <Link to='/propelrr'>
                            <i className='fas fa-ban'></i>
                            Cancel
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='form-box add-form-box'>
            <div className='form-content'>
                <div className='row'>
                    <div className='col-lg-3 col-xl-3'>
                        <div className='sidebar-form'>
                            <div className='icon-intro'>
                                <i className='fa fa-paper-plane'></i>
                            </div>
                            <SidebarForm />
                        </div>
                    </div>
                    <div className='col-lg-9 col-xl-9'>
                        <div className='main-form-box'>
                            <FormAdd 
                                onEmailChange={validateEmail}
                                emailError={errorEmail}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AddInfluencer