import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { influencerActions } from "_actions";
import ClientFilter from "_pages/Influencer/components/Info/ClientFilter";

const ClientInfo = ({
    projectId,
    influencerId,
    getInfluencerClients,
    getClients,
    updateInfluencerClients,
    clientList,
    getClientListLoaded,
    clients,
    getInfluencerClientLoaded,
    getInfluencerClientLoading,
    classN
}) => {
    const [isClientFilterOpen, setIsClientFilterOpen] = useState(false);

    useEffect(() => {
        getInfluencerClients(projectId, influencerId);
        getClients(projectId);
    }, [projectId, influencerId, getInfluencerClients, getClients]);

    const handleClientChange = (clients) => {
        updateInfluencerClients(projectId, influencerId, clients);
    };

    const ids = getInfluencerClientLoaded ? clients.map(d => d.id) : [];
    const hasData = getInfluencerClientLoaded && clients.length > 0;

    return (
        <div className={classN}>
            <div className='inner-title-box'>
                <p>Clients</p>
                <ClientFilter
                    clientIds={ids}
                    clientList={getClientListLoaded ? clientList : []}
                    onClientChanged={handleClientChange}
                    getInfluencerClientLoaded={getInfluencerClientLoaded}
                    hasData={hasData}
                    isClientFilterOpen={isClientFilterOpen}
                    onHandleClientFilterOpen={() => setIsClientFilterOpen(true)}
                    onHandleClientFilterClose={() => setIsClientFilterOpen(false)}
                />
            </div>
            {getInfluencerClientLoaded && hasData && (
                <div className='client-box-list'>
                    {clients.map((client, index) => (
                        <div key={index} className='client-box'>
                            <div className='client'>
                                <p>{client.name}</p>
                                <span>Client Name</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {getInfluencerClientLoaded && !hasData && (
                <div className='empty-tab-content'>
                    <div className='empty-content'>
                        <h6>Add Your First Client</h6>
                        <p>Clients will appear here</p>
                        <span onClick={() => setIsClientFilterOpen(true)}>
                            <i className='icon-lms icon-add'></i>
                            Add Client
                        </span>
                    </div>
                </div>
            )}
            {getInfluencerClientLoading && (
                <div className='loading-box'>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            )}
        </div>
    );
};

const mapState = (state) => {
    const { influencers } = state;
    const { clientList, getClientListLoaded, clients, getInfluencerClientLoaded, getInfluencerClientLoading } = influencers;
    return { clientList, getClientListLoaded, clients, getInfluencerClientLoaded, getInfluencerClientLoading };
};

const actionCreators = {
    getInfluencerClients: influencerActions.getInfluencerClients,
    getClients: influencerActions.getClients,
    updateInfluencerClients: influencerActions.updateInfluencerClients
};

const connectedClientInfo = connect(mapState, actionCreators)(ClientInfo);
export { connectedClientInfo as ClientInfo };