import { formConstants } from '_constants';

/**
 * Reducer function to handle form-related actions and update the state accordingly.
 *
 * @param {Object} state - The current state of the forms.
 * @param {Object} action - The action dispatched.
 * @param {string} action.type - The type of action.
 * @param {Object} [action.forms] - The forms data.
 * @param {Object} [action.form] - The specific form data.
 * @param {Object} [action.error] - The error data.
 * @param {Object} [action.status] - The status data.
 * @param {Object} [action.message] - The message data.
 * @param {Object} [action.sendTest] - The send test data.
 * @param {Object} [action.group] - The group data.
 * @param {Object} [action.fieldData] - The field data.
 * @param {Object} [action.field] - The specific field data.
 * @param {Object} [action.field_option] - The field option data.
 * @param {Object} [action.presets] - The presets data.
 * @param {Object} [action.templates] - The templates data.
 * @param {Object} [action.accessKey] - The access key data.
 * @param {Object} [action.integration] - The integration data.
 * @param {Object} [action.reorder] - The reorder data.
 * @returns {Object} The updated state based on the action type.
 */
export function forms(state = {}, action) {
  switch (action.type) {
    /*
    * FORMS
    * */

    // CREATE FORM
    case formConstants.CREATE_REQUEST:
      return {
        creating: true
      };
    case formConstants.CREATE_SUCCESS:
      return {};
    case formConstants.CREATE_FAILURE:
      return {};

    // GET ALL FORMS
    case formConstants.GET_ALL_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        allForms: action.forms,
        formsLoaded: true
      };
    case formConstants.GET_ALL_FAILURE:
      return {
        error: action.error
      };

    // GET SPECIFIC FORM
    case formConstants.GET_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        form: action.form,
        formLoaded: true
      };
    case formConstants.GET_FAILURE:
      return {
        error: action.error
      };

    // UPDATE FORM
    case formConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_SUCCESS:
      return {
        items: action.form,
        loaded: true
      };
    case formConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    // DELETE FORM
    case formConstants.DELETE_REQUEST:
      return {
        deleting: true
      };
    case formConstants.DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        formsLoaded: true
      };
    case formConstants.DELETE_FAILURE:
      return {
        error: action.error
      };

    // DELETE MULTIPLE FORM
    case formConstants.DELETE_MULTIPLE_REQUEST:
      return {
        deleting: true
      };
    case formConstants.DELETE_MULTIPLE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        items: action.form,
        formsLoaded: true
      };
    case formConstants.DELETE_MULTIPLE_FAILURE:
      return {
        error: action.error
      };

    // UPDATE STATUS
    case formConstants.UPDATE_FORM_STATUS_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_FORM_STATUS_SUCCESS:
      return {
        items: action.status
      };
    case formConstants.UPDATE_FORM_STATUS_FAILURE:
      return {
        error: action.error
      };


    /*
  * FORM MESSAGE
  * */

    // GET FORM MESSAGE
    case formConstants.GET_MESSAGE_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_MESSAGE_SUCCESS:
      return {
        items: action.message
      };
    case formConstants.GET_MESSAGE_FAILURE:
      return {
        error: action.error
      };

    // UPDATE FORM MESSAGE
    case formConstants.UPDATE_MESSAGE_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_MESSAGE_SUCCESS:
      return {
        items: action.message
      };
    case formConstants.UPDATE_MESSAGE_FAILURE:
      return {
        error: action.error
      };

    // SEND TEST EMAIL
    case formConstants.SEND_MESSAGE_REQUEST:
      return {
        loading: true
      };
    case formConstants.SEND_MESSAGE_SUCCESS:
      return {
        items: action.sendTest
      };
    case formConstants.SEND_MESSAGE_FAILURE:
      return {
        error: action.error
      };

    // FIND EMAIL TEMPLATE
    case formConstants.GET_LAYOUT_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_LAYOUT_SUCCESS:
      return {
        items: action.form
      };
    case formConstants.GET_LAYOUT_FAILURE:
      return {
        error: action.error
      };

    // SELECT EMAIL TEMPLATE -- UPDATE WHEN FINALIZED ON THE BACKEND
    /*case formConstants.GET_LAYOUT_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_LAYOUT_SUCCESS:
      return {
        items: action.form
      };
    case formConstants.GET_LAYOUT_FAILURE:
      return {
        error: action.error
      };*/


    /*
    * FORM GROUP
    * */

    // GET ALL FORM GROUP
    case formConstants.GET_ALL_GROUP_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_ALL_GROUP_SUCCESS:
      return {
        items: action.group
      };
    case formConstants.GET_ALL_GROUP_FAILURE:
      return {
        error: action.error
      };

    // CREATE FORM GROUP
    case formConstants.CREATE_FORM_GROUP_REQUEST:
      return {
        creating: true
      };
    case formConstants.CREATE_FORM_GROUP_SUCCESS:
      return {
        created: true
      };
    case formConstants.CREATE_FORM_GROUP_FAILURE:
      return {};

    // FIND FORM GROUP
    case formConstants.GET_GROUP_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_GROUP_SUCCESS:
      return {
        items: action.group
      };
    case formConstants.GET_GROUP_FAILURE:
      return {
        error: action.error
      };

    // UPDATE FORM GROUP
    case formConstants.UPDATE_GROUP_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_GROUP_SUCCESS:
      return {
        items: action.group
      };
    case formConstants.UPDATE_GROUP_FAILURE:
      return {
        error: action.error
      };

    // DELETE FORM GROUP
    case formConstants.DELETE_GROUP_REQUEST:
      return {
        deleting: true
      };
    case formConstants.DELETE_GROUP_SUCCESS:
      return {
        deleted: true
      };
    case formConstants.DELETE_GROUP_FAILURE:
      return {
        error: action.error
      };

    // ENABLE FORM GROUP
    case formConstants.UPDATE_GROUP_STATUS_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_GROUP_STATUS_SUCCESS:
      return {
        items: action.group
      };
    case formConstants.UPDATE_GROUP_STATUS_FAILURE:
      return {
        error: action.error
      };

    // REORDER FORM GROUP
    case formConstants.REORDER_GROUP_REQUEST:
      return {
        loading: true
      };
    case formConstants.REORDER_GROUP_SUCCESS:
      return {
        items: action.reorder
      };
    case formConstants.REORDER_GROUP_FAILURE:
      return {
        error: action.error
      };

    /*
    * GROUP FIELD
    * */

    // GET ALL GROUP FIELD
    case formConstants.GET_ALL_FIELD_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_ALL_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        fields: action.fieldData,
        formLoaded: true
      };
    case formConstants.GET_ALL_FIELD_FAILURE:
      return {
        error: action.error
      };

    // CREATE GROUP FIELD
    case formConstants.CREATE_GROUP_FIELD_REQUEST:
      return {
        creating: true
      };
    case formConstants.CREATE_GROUP_FIELD_SUCCESS:
      return {};
    case formConstants.CREATE_GROUP_FIELD_FAILURE:
      return {};

    // FIND GROUP FIELD
    case formConstants.GET_FIELD_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_FIELD_SUCCESS:
      return {
        items: action.field
      };
    case formConstants.GET_FIELD_FAILURE:
      return {
        error: action.error
      };

    // UPDATE GROUP FIELD
    case formConstants.UPDATE_FIELD_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_FIELD_SUCCESS:
      return {
        items: action.field
      };
    case formConstants.UPDATE_FIELD_FAILURE:
      return {
        error: action.error
      };

    // DELETE GROUP FIELD
    case formConstants.DELETE_FIELD_REQUEST:
      return {
        deleting: true
      };
    case formConstants.DELETE_FIELD_SUCCESS:
      return {
        deleted: true
      };
    case formConstants.DELETE_FIELD_FAILURE:
      return {
        error: action.error
      };

    // ENABLE GROUP FIELD
    case formConstants.UPDATE_FIELD_STATUS_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_FIELD_STATUS_SUCCESS:
      return {
        items: action.field
      };
    case formConstants.UPDATE_FIELD_STATUS_FAILURE:
      return {
        error: action.error
      };

    // REORDER GROUP FIELD
    case formConstants.REORDER_FIELD_REQUEST:
      return {
        loading: true
      };
    case formConstants.REORDER_FIELD_SUCCESS:
      return {};
    case formConstants.REORDER_FIELD_FAILURE:
      return {
        error: action.error
      };


    /*// FIND ALL FIELD
  case formConstants.GET_ALL_FIELD_REQUEST:
    return {
      loading: true
    };
  case formConstants.GET_ALL_FIELD_SUCCESS:
    return {
      items: action.field
    };
  case formConstants.GET_ALL_FIELD_FAILURE:
    return {
      error: action.error
    };*/


    // UPDATE FIELD IDENTIFIER
    case formConstants.UPDATE_FIELD_IDENTIFIER_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_FIELD_IDENTIFIER_SUCCESS:
      return {
        items: action.field
      };
    case formConstants.UPDATE_FIELD_IDENTIFIER_FAILURE:
      return {
        error: action.error
      };

    // UPDATE FIELD SUB IDENTIFIER
    case formConstants.UPDATE_FIELD_SUB_IDENTIFIER_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_FIELD_SUB_IDENTIFIER_SUCCESS:
      return {
        items: action.field
      };
    case formConstants.UPDATE_FIELD_SUB_IDENTIFIER_FAILURE:
      return {
        error: action.error
      };

    /*
  * FIELD OPTIONS
  * */

    //GET ALL FIELD OPTIONS
    case formConstants.GET_ALL_OPTION_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_ALL_OPTION_SUCCESS:
      return {
        items: action.field_option
      };
    case formConstants.GET_ALL_OPTION_FAILURE:
      return {
        error: action.error
      };

    // CREATE FIELD OPTION
    case formConstants.CREATE_FIELD_OPTION_REQUEST:
      return {
        creating: true
      };
    case formConstants.CREATE_FIELD_OPTION_SUCCESS:
      return {
        field_option: action.field_option
      };
    case formConstants.CREATE_FIELD_OPTION_FAILURE:
      return {};

    // FIND FIELD OPTION
    case formConstants.GET_OPTION_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_OPTION_SUCCESS:
      return {
        items: action.field_option
      };
    case formConstants.GET_OPTION_FAILURE:
      return {
        error: action.error
      };

    // UPDATE FIELD OPTION
    case formConstants.UPDATE_OPTION_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_OPTION_SUCCESS:
      return {
        items: action.field_option
      };
    case formConstants.UPDATE_OPTION_FAILURE:
      return {
        error: action.error
      };

    // DELETE FIELD OPTION
    case formConstants.DELETE_OPTION_REQUEST:
      return {
        deleting: true
      };
    case formConstants.DELETE_OPTION_SUCCESS:
      return {
        items: action.field
      };
    case formConstants.DELETE_OPTION_FAILURE:
      return {
        error: action.error
      };

    /*
  * PRESETS
  * */

    //GET ALL PRESETS
    case formConstants.GET_ALL_PRESET_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_ALL_PRESET_SUCCESS:
      return {
        items: action.presets,
        loaded: true
      };
    case formConstants.GET_ALL_PRESET_FAILURE:
      return {
        error: action.error
      };

    // CREATE PRESET
    case formConstants.CREATE_PRESET_REQUEST:
      return {
        creating: true
      };
    case formConstants.CREATE_PRESET_SUCCESS:
      return {
        items: action.presets,
        loaded: true
      };
    case formConstants.CREATE_PRESET_FAILURE:
      return {};

    // FIND PRESET
    case formConstants.GET_PRESET_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_PRESET_SUCCESS:
      return {
        items: action.presets
      };
    case formConstants.GET_PRESET_FAILURE:
      return {
        error: action.error
      };

    // UPDATE PRESET
    case formConstants.UPDATE_PRESET_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_PRESET_SUCCESS:
      return {
        items: action.presets
      };
    case formConstants.UPDATE_PRESET_FAILURE:
      return {
        error: action.error
      };

    /*
  * TEMPLATE
  * */

    //GET ALL TEMPLATES
    case formConstants.GET_ALL_TEMPLATE_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_ALL_TEMPLATE_SUCCESS:
      return {
        items: action.templates
      };
    case formConstants.GET_ALL_TEMPLATE_FAILURE:
      return {
        error: action.error
      };

    // CREATE TEMPLATE
    case formConstants.CREATE_TEMPLATE_REQUEST:
      return {
        creating: true
      };
    case formConstants.CREATE_TEMPLATE_SUCCESS:
      return {};
    case formConstants.CREATE_TEMPLATE_FAILURE:
      return {};

    // FIND PRESET
    case formConstants.GET_TEMPLATE_REQUEST:
      return {
        loading: true
      };
    case formConstants.GET_TEMPLATE_SUCCESS:
      return {
        items: action.templates
      };
    case formConstants.GET_TEMPLATE_FAILURE:
      return {
        error: action.error
      };

    // UPDATE TEMPLATE
    case formConstants.UPDATE_TEMPLATE_REQUEST:
      return {
        loading: true
      };
    case formConstants.UPDATE_TEMPLATE_SUCCESS:
      return {
        items: action.templates
      };
    case formConstants.UPDATE_TEMPLATE_FAILURE:
      return {
        error: action.error
      };


    // GET FORM ACCESS KEY
    case formConstants.GET_ACCESS_KEY_REQUEST:
      return {
        loading: true,
        loadedAccess: false
      };
    case formConstants.GET_ACCESS_KEY_SUCCESS:
      return {
        ...state,
        items: action.accessKey,
        loadedAccess: true
      };
    case formConstants.GET_ACCESS_KEY_FAILURE:
      return {
        error: action.error
      };


    /*
    * FORM INTEGRATIONS
    * */

    // GET FORM INTEGRATION
    case formConstants.GET_INTEGRATION_REQUEST:
      return {
        loading: true,
        loadedIntegration: false
      };
    case formConstants.GET_INTEGRATION_SUCCESS:
      return {
        ...state,
        items: action.integration,
        loadedIntegration: true
      };
    case formConstants.GET_INTEGRATION_FAILURE:
      return {
        error: action.error
      };

    // UPDATE FORM INTEGRATION
    case formConstants.UPDATE_INTEGRATION_REQUEST:
      return {
        loading: true,
        loadedIntegration: false
      };
    case formConstants.UPDATE_INTEGRATION_SUCCESS:
      return {
        items: action.integration,
        loadedIntegration: true
      };
    case formConstants.UPDATE_INTEGRATION_FAILURE:
      return {
        error: action.error
      };

    // VALIDATE EMBED CODE
    case formConstants.VALIDATE_EMBED_CODE_REQUEST:
      return {
        loading: true
      };
    case formConstants.VALIDATE_EMBED_CODE_SUCCESS:
      return {
        verified: true,
        loaded: true
      };
    case formConstants.VALIDATE_EMBED_CODE_FAILURE:
      return {
        error: action.error,
        verified: false,
        loaded: true
      };

    default:
      return state
  }
}
