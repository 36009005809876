import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";

import logo from "_assets/images/lms-logo.png";
import loginBG from "_assets/images/login-bg.png";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "_actions";

const TwoFactorSelection = () => {
  const [redirect, setRedirect] = useState(null);
  const [isEmail, setIsEmail] = useState(false);
  const [loadEmail, setLoadEmail] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const [loadApp, setLoadApp] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  let user = useSelector((state) => state.users);

  // console.log(user);

  useEffect(() => {
    if(user.loading && user.loading == true && isEmail) {
      setLoadEmail(true);
    }

    if(user.loading && user.loading == true && isApp) {
      setLoadApp(true);
    }

  }, [user]);

  // console.log('loademail: ', loadEmail);


  useEffect(() => {
    const handleBeforeUnload = () => {
      console.log("called here....");
      // Set isLoggedIn to 'false' in localStorage before the page unloads
      localStorage.setItem("isLoggedIn", "false");
      window.location.href = "/login"; // Replace with your actual login page URL
    };

    // Add an event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Check if the user is not logged in (isLoggedIn is not 'true')
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (isLoggedIn !== "true") {
      // Redirect to the login page
      window.location.href = "/login"; // Replace with your actual login page URL
    }
  }, [history]);

  useEffect(() => {
    // Create a new URLSearchParams object from the location search string
    const queryParams = new URLSearchParams(location.search);

    // Get the value of a specific query parameter, for example, 'redirect'
    const redirectParam = queryParams.get("redirect");

    // Now, you have the 'redirect' query parameter value
    if (redirectParam) {
      setRedirect(redirectParam);
    }
  }, [location.search]);

  const handleEmail2faLogin = () => {
    setIsEmail(true);
   
    if(!loadEmail) {
      dispatch(userActions.twoFactorEmailLogin(redirect));
    }
   
  };

  const handleApp2faLogin = () => {
    setIsApp(true);

    if(!loadApp) {
      dispatch(userActions.twoFactorAppLogin(redirect));
    }
  };

  return (
    <MDBContainer fluid className="login-background">
      <div className="login-bg">
        <img src={loginBG} alt="Login Background Image" />
      </div>
      <div className="login-panel">
        <MDBRow>
          <MDBCol className="text-center">
            <img src={logo} alt="Propelrr Platform" className="login-logo" />
            <h1>Lead Management System</h1>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <div className="two-way-selection-box">
              <MDBRow>
                <MDBCol className="text-center">
                  <div className="form-inner-heading">
                    <h2>2-step Verification</h2>
                    <p>
                      This extra step shows it's really you trying to login.{" "}
                      <br />
                      Choose a task from the list below
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div className="two-way-selection-list">
                      <a href="#" onClick={handleEmail2faLogin} className="authentication-item text-cursor-pointer">
                        <span className="label-" >
                          <i className="icon-lms icon-envelope-o"></i> Get an{" "}
                          <strong>email</strong> verification code
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="30"
                          viewBox="0 -960 960 960"
                          width="30"
                        >
                         {!loadEmail && <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" />}
                        </svg>
                        {loadEmail && (
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </a>
                      <a href="#" onClick={handleApp2faLogin} className="authentication-item">
                        <span className="label-" >
                          <i className="icon-lms icon-mobile"></i> Get a
                          verification code from the <br />
                          <strong>Google Authenticator</strong> app
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="30"
                          viewBox="0 -960 960 960"
                          width="30"
                        >
                         {!loadApp && <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" />}
                        </svg>
                        {loadApp && (
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        <div className="recommended-badge">
                          <span className="badge">
                            <i className="icon-lms icon-checkmark"></i>
                          </span>
                          <span className="badge-label">Recommended</span>
                        </div>
                      </a>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
};

export default TwoFactorSelection;
