import React, { useState, useEffect, useRef } from 'react';
import { influencerActions } from "_actions";
import { connect } from "react-redux";

const KpiControlAdvanceFilter = (props) => {
  const filterRef = useRef();
  
  const [filterToggled, setFilterToggled] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState([]);
  const [checkedPlatforms, setCheckedPlatforms] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [openFilterGroupIndex, setOpenFilterGroupIndex] = useState(null);
  const filterGroupHeight = 500;

  useEffect(() => {
    document.addEventListener('click', handleDropdown, true);
    const { projectId, getCategories, getPlatformTypes } = props;
    getCategories(projectId);
    getPlatformTypes(projectId);
    return () => {
      document.removeEventListener('click', handleDropdown, true);
    };
  }, []);

  useEffect(() => {
    if (props.resetFilterState !== props.resetFilterState && props.resetFilterState) {
      console.log('called here');
      setCheckedStatus([]);
      setCheckedPlatforms([]);
      setCheckedCategories([]);
      props.onHandleResetFilter();
    }
  }, [props.resetFilterState]);

  const handleFilterToggle = () => setFilterToggled(!filterToggled);

  const handleOpenFilter = () => setFilterToggled(true);

  const handleCloseFilter = () => setFilterToggled(false);

  const handleDropdown = (value) => {
    if (!filterRef.current.contains(value.target)) {
      setFilterToggled(false);
    }
  };

  const handleStatusChange = (e) => {
    const { value, checked } = e.target;
    let updatedCheckedStatus = [...checkedStatus];
    if (checked) {
      updatedCheckedStatus.push(parseInt(value));
    } else {
      updatedCheckedStatus = updatedCheckedStatus.filter(x => x !== parseInt(value));
    }
    setCheckedStatus(updatedCheckedStatus);
  };

  const handlePlatformChange = (e) => {
    const { value, checked } = e.target;
    let updatedCheckedPlatforms = [...checkedPlatforms];
    if (checked) {
      updatedCheckedPlatforms.push(parseInt(value));
    } else {
      updatedCheckedPlatforms = updatedCheckedPlatforms.filter(x => x !== parseInt(value));
    }
    setCheckedPlatforms(updatedCheckedPlatforms);
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    let updatedCheckedCategories = [...checkedCategories];
    if (checked) {
      updatedCheckedCategories.push(parseInt(value));
    } else {
      updatedCheckedCategories = updatedCheckedCategories.filter(x => x !== parseInt(value));
    }
    setCheckedCategories(updatedCheckedCategories);
  };

  const handleApplyFilter = () => {
    props.onApplyFilter(checkedStatus, checkedPlatforms, checkedCategories);
    handleCloseFilter();
  };

  const handleReset = () => {
    setCheckedStatus([]);
    setCheckedPlatforms([]);
    setCheckedCategories([]);
    props.onApplyFilter([], [], []);
  };

  const handleToggleFilterGroup = (index) => {
    setOpenFilterGroupIndex(prevState => (prevState === index ? null : index));
  };

  const { influencers, getPlatFormTypeLoaded, getCategoryListLoaded, influencerListLoaded } = props;
  const statusList = [
    { id: 1, name: "Onboarded" },
    { id: 2, name: "Inquired" },
    { id: 3, name: "Blacklisted" },
    { id: 4, name: "Collaborated" },
    { id: 5, name: "Ongoing Collaboration" },
    { id: 6, name: "Received Information" },
  ];

  const categoryList = getCategoryListLoaded ? influencers.categoryList : [];
  const platformTypes = getPlatFormTypeLoaded ? influencers.platformTypes : [];

  let applyClass = 'show';
  if (influencerListLoaded) {
    applyClass = (checkedCategories.length > 0 || checkedStatus.length > 0 || checkedPlatforms.length > 0) ? 'show' : 'hide';
  }

  return (
    <div className='filter-box influencers-filter' ref={filterRef}>
      <div className='filter-btn' onClick={handleOpenFilter}>
        <i className='icon-lms icon-filter'></i>
        Filters
        <span>{checkedCategories.length + checkedStatus.length + checkedPlatforms.length}</span>
      </div>
      <div className={filterToggled ? 'filter-dropdown active' : 'filter-dropdown'}>
        <div className='filter-title'>
          <p>Filters</p>
          <i className='icon-lms icon-close' onClick={handleCloseFilter}></i>
        </div>

        <div className='filter-list'>
          {/* Category Filter */}
          <div 
          onClick={() => handleToggleFilterGroup(0)}
          className={openFilterGroupIndex === 0 ? 'filter-group active' : 'filter-group'}>
            <div className='filter-name' >
              <span>Category ({categoryList.length})</span>
              <i className='icon-lms icon-arrow-down'></i>
              <span className={checkedCategories.length > 0 ? "count active" : "count"}>{checkedCategories.length}</span>
            </div>
            <div className='filter-check-list' style={openFilterGroupIndex === 0 ? { maxHeight: filterGroupHeight } : { maxHeight: "0" }}>
              {categoryList.map((category, index) => (
                <div className='check-col' key={index}>
                  <div className='checkbox-group'>
                    <input
                      type='checkbox'
                      checked={checkedCategories.includes(category.id)}
                      name={category.code}
                      id={category.code}
                      value={category.id}
                      onChange={handleCategoryChange}
                    />
                    <label htmlFor={category.code}>{category.name}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Platform Filter */}
          <div 
          onClick={() => handleToggleFilterGroup(1)}
          className={openFilterGroupIndex === 1 ? 'filter-group active' : 'filter-group'}>
            <div className='filter-name' >
              <span>Platform ({platformTypes.length})</span>
              <i className='icon-lms icon-arrow-down'></i>
              <span className={checkedPlatforms.length > 0 ? "count active" : "count"}>{checkedPlatforms.length}</span>
            </div>
            <div className='filter-check-list' style={openFilterGroupIndex === 1 ? { maxHeight: filterGroupHeight } : { maxHeight: "0" }}>
              {platformTypes.map((platform, index) => (
                <div className='check-col' key={index}>
                  <div className='checkbox-group'>
                    <input
                      type='checkbox'
                      checked={checkedPlatforms.includes(platform.id)}
                      name={platform.name}
                      id={platform.name}
                      value={platform.id}
                      onChange={handlePlatformChange}
                    />
                    <label htmlFor={platform.name}>{platform.name}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Status Filter */}
          <div 
          onClick={() => handleToggleFilterGroup(2)}
          className={openFilterGroupIndex === 2 ? 'filter-group active' : 'filter-group'}>
            <div className='filter-name' >
              <span>Status ({statusList.length})</span>
              <i className='icon-lms icon-arrow-down'></i>
              <span className={checkedStatus.length > 0 ? "count active" : "count"}>{checkedStatus.length}</span>
            </div>
            <div className='filter-check-list' style={openFilterGroupIndex === 2 ? { maxHeight: filterGroupHeight } : { maxHeight: "0" }}>
              {statusList.map((status, index) => (
                <div className='check-col' key={index}>
                  <div className='checkbox-group'>
                    <input
                      type='checkbox'
                      checked={checkedStatus.includes(status.id)}
                      name={status.name}
                      id={status.name}
                      value={status.id}
                      onChange={handleStatusChange}
                    />
                    <label htmlFor={status.name}>{status.name}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='filter-control-box'>
          <span className='btn-reset' onClick={handleReset}>Clear Filter</span>
          <span className='btn-apply' onClick={handleApplyFilter}>Apply Filter</span>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  const { influencers } = state;
  const { influencerListLoaded } = state.influencers;
  const { getPlatFormTypeLoaded, getCategoryListLoaded } = influencers;
  return { influencers, getPlatFormTypeLoaded, getCategoryListLoaded, influencerListLoaded };
};

const actionCreators = {
  getPlatformTypes: influencerActions.getPlatformTypes,
  getCategories: influencerActions.getCategories,
};
const connectedKpiControlAdvanceFilter = connect(mapState, actionCreators)(KpiControlAdvanceFilter);
export { connectedKpiControlAdvanceFilter as KpiControlAdvanceFilter };
